import React from "react";
import Sidebar from "../../comman_page/Sidebar";
import { HeaderDashboard } from "../../comman_page/HeaderDashboard";
import { Col, Container, Row } from "react-bootstrap";
import { Footer } from "../../../../../public/containers/footer/Footer";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { customer_payment_request } from "../../../../../Studio-Services/Studio_Services";
import { useState } from "react";

export const CustomerPaymentRequest = () => {
  const [value, setValue] = useState([]);

  const location = useLocation();
  const data = location.state;

  const getData = async (data) => {
    const resp = await customer_payment_request(data);
    if (resp) {
      setValue(resp.data);
    }
  };

  useEffect(() => {
    getData(data);
  }, [data]);

  return (
    <>
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
          <Sidebar />
            <Col>
              <div className="RightSide">
                <HeaderDashboard />
                <section className="addClient innerContent">
                  <div className="AddClientContent">
                    <Container fluid>
                      <h4>CUSTOMER PAYMENT REQUEST</h4>

                      <Row className="my-4">
                        <Col md={6} sm={12}>
                          <div className="paymentPanel">
                            <Row className="">
                              <h5 className="my-4">CUSTOMER DETAILS</h5>
                              <Col md={4} sm={12}>
                                <div className="customerDetails">
                                  <p>Name</p>
                                  <h5>
                                    {value.full_name ? value.full_name : ""}
                                  </h5>
                                </div>
                              </Col>
                              <Col md={4} sm={12}>
                                <div className="customerDetails">
                                  <p>Gender</p>
                                  <h5>{value.gender ? value.gender : ""}</h5>
                                </div>
                              </Col>
                              <Col md={4} sm={12}>
                                <div className="customerDetails">
                                  <p>Age</p>
                                  <h5>{value.age ? value.age : ""}</h5>
                                </div>
                              </Col>
                            </Row>
                            <Row className="my-4">
                              <h5 className="my-4">PASS DETAILS</h5>
                              <Col md={4} sm={12}>
                                <div className="customerDetails">
                                  <p>Pass Name</p>
                                  <h5>
                                    {value.service_name
                                      ? value.service_name
                                      : ""}
                                  </h5>
                                </div>
                              </Col>
                              <Col md={4} sm={12}>
                                <div className="customerDetails">
                                  <p>Pass Type</p>
                                  <h5>
                                    {value.pass_type ? value.pass_type : ""}
                                  </h5>
                                </div>
                              </Col>
                              <Col md={4} sm={12}>
                                <div className="customerDetails">
                                  <p>Is one time purchase</p>
                                  <h5>
                                    {value.is_one_time_purchase
                                      ? value.is_one_time_purchase
                                      : ""}
                                  </h5>
                                </div>
                              </Col>
                            </Row>
                            <Row className="my-4">
                              <Col md={12} sm={12}>
                                <div className="customerDetails">
                                  <p>Description</p>
                                  <h5>
                                    {value.description ? value.description : ""}
                                  </h5>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col md={6} sm={12}>
                          <div className="paymentPanel">
                            <Row className="">
                              <h5 className="my-4">PAYMENT DETAIL</h5>
                              <Col md={12} sm={12}>
                                <div className="paymentDetails">
                                  <p>Appointment Fee</p>
                                  <p>$ {value.amount ? value.amount : ""}</p>
                                </div>
                              </Col>
                              <Col md={12} sm={12}>
                                <div className="paymentDetails">
                                  <p>Tax</p>
                                  <p>$ {value.tax ? value.tax : ""}</p>
                                </div>
                              </Col>
                              <Col md={12} sm={12}>
                                <div className="paymentDetails border-top">
                                  <p>Amount Payable</p>
                                  <p>
                                    ${" "}
                                    {value.total_amount
                                      ? value.total_amount
                                      : value.total_amount}
                                  </p>
                                </div>
                              </Col>
                            </Row>
                            <Row className="mb-4">
                              <h5 className="my-4">TRANSACTION INFORMATION</h5>
                              <Col md={6} sm={12}>
                                <div className="transDetails">
                                  <p>Payment Mode</p>
                                  <h5>
                                    {value.payment_mode
                                      ? value.payment_mode
                                      : ""}
                                  </h5>
                                </div>
                              </Col>
                              <Col md={6} sm={12}>
                                <div className="transDetails">
                                  <p>Transaction ID</p>
                                  <h5>
                                    {value.transaction_id
                                      ? value.transaction_id
                                      : ""}
                                  </h5>
                                </div>
                              </Col>
                            </Row>
                            <Row className="my-4">
                              <Col md={12} sm={12}>
                                <div className="transDetails">
                                  <p>Payment Status</p>
                                  <h5>{value.status ? value.status : ""}</h5>
                                </div>
                              </Col>
                              <Col md={12} sm={12}>
                                <div className="transDetails">
                                  <p>Note</p>
                                  <h5>
                                    {value.payment_note
                                      ? value.payment_note
                                      : ""}
                                  </h5>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </section>
                <Footer />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
