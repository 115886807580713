import React from "react";
import Sidebar from "../../comman_page/Sidebar";
import { HeaderDashboard } from "../../comman_page/HeaderDashboard";
import { Container, Row, Col } from "react-bootstrap";
import { DataTable } from "../../../../../sharedComponents/DataTable";
import { Footer } from "../../../../../public/containers/footer/Footer";
import { transectionList } from "../../../../../Studio-Services/Studio_Services";
import { useState, useEffect } from "react";



export const Transactionlist = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [tlist, setTlist] = useState([]);

  const getTransectionList = async (currentPage) => {
    const data = { page_no: currentPage };
    const resp = await transectionList(data);
    if (resp) {
      setTlist(resp.data);
    }
  };

  const array = [];

  if (tlist) {
    tlist.forEach((e) => {
      array.push({
        Payment: (
          <>
            <img
              className="img-fluid table_user"
              src={e.profile_img ? e.profile_img : ""}
              alt=""
            />
            <span className="ps-4">{e.name}</span>
            <span className="ps-2">{e.lastname}</span>
          </>
        ),
        Reference_ID: e.reference_payment_id,
        Payment_Mode: e.payment_mode,
        Transaction_ID: e.trx_id,
        Order_Number: e.order_number,
        Total_Amount: e.sub_total,
      });
    });
  }

  useEffect(() => {
    getTransectionList(currentPage);
  }, [currentPage]);

  const columns = [
    { dataField: "Payment", text: "Payment For" },
    { dataField: "Reference_ID", text: "Reference ID" },
    { dataField: "Payment_Mode", text: "Payment Mode" },
    { dataField: "Transaction_ID", text: "Transaction ID" },
    { dataField: "Order_Number", text: "Order Number" },
    { dataField: "Total_Amount", text: "Total Amount" },
  ];

  // Pagination Code

  // const len = array.length;
  // const getPage = (event) => {
  //   setCurrentPage(event);
  // };
  return (
    <>
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col>
              <div className="RightSide">
                <HeaderDashboard />
                <section className="addClient innerContent">
                  <div className="AddClientContent">
                    <Container>
                      <h4>TRANSACTION LIST</h4>

                      <Row className="mt-3">
                        <Col>
                          <DataTable data={array} columns={columns} />
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </section>
                <Footer />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
