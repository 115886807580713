import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "bootstrap/dist/css/bootstrap.min.css";
import { LiveSearch } from "../../../../../sharedComponents/LiveSearch";
import { useEffect } from "react";
import { writeFile, utils } from "xlsx";

export const ClientListTable = ({ datas, columns, clientlist }) => {
    const [tabledata, setTabledata] = useState([]);
    const [iniData, setIniData] = useState([]);
    const [xcl, setXcl] = useState([]);
    const [arrow,setArrow] = useState(true);


    const reportPrepare = (requestData) => {
        let temparray = [];
        temparray.push(["name", "surname", "email", "status"]);
        requestData.forEach((value) => {
            temparray.push([
                value.first_name,
                value.surname,
                value.email,
                value.purchase_status,
            ]);
        });
        setXcl(temparray);
    };

    const handleDownloadClick = () => {
        const worksheet = utils.aoa_to_sheet(xcl);
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, "Sheet 1");

        writeFile(workbook, "Customer_export.xlsx");
    };

    const filterData = (data) => {
        if (typeof data !== "string") {
            return []; // or return an empty array or throw an error, based on your requirement
        }

        const fdata = iniData.filter((item) => {
            const myNameLower = item.myName.toLowerCase();
            const statusLower = item.Status.toLowerCase();
            const genderLower = item.Gender.toLowerCase();
            const startDateLower = item.dob.toLowerCase();

            const dataLower = data.toLowerCase();

            const statusMatch = statusLower.startsWith(dataLower);
            const genderMatch = genderLower.startsWith(dataLower);
            const startDateMatch = startDateLower.startsWith(dataLower);

            const myNameMatch = myNameLower.startsWith(dataLower);

            return statusMatch || genderMatch || startDateMatch || myNameMatch;
        });


        return fdata;
    };

    function sortByNameZA(filteredData) {
        return filteredData.slice().sort((a, b) => {
            const nameA = a.myName.toLowerCase();
            const nameB = b.myName.toLowerCase();
            if (nameA > nameB) return -1;
            if (nameA < nameB) return 1;
            return 0;
        });
    }
    
    function sortByNameAZ(filteredData) {
        return filteredData.slice().sort((a, b) => {
            const nameA = a.myName.toLowerCase();
            const nameB = b.myName.toLowerCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
        });
    }

    useEffect(() => {
        if(datas && datas.length > 0){
          const resp =  sortByNameAZ(datas)
            setIniData(resp);
            setTabledata(resp);
            reportPrepare(clientlist);
        }
       
    }, [datas])


    const searchHandler = (e) => {
        const value = e.target.value;
        if (value) {
            const resp = filterData(value);
            setTabledata(resp);
        } else {
            setTabledata(iniData);
        }
    }


    const shortHandler = (data) => {
        let resp = data;
        setArrow(!arrow);
        if(arrow){
           resp =  sortByNameZA(data);
          setTabledata(resp);
        }else{
             resp =  sortByNameAZ(data);
        }
      setTabledata(resp);
    }
    return (
        <>
            <Row>
                <Col>
                    <h4>CLIENT LIST</h4>
                </Col>
                <Col>
                </Col>
                <Col>
                    <LiveSearch
                        Placeholder={
                            "Search by Name, Status, gender, Date"
                        }
                        onChange={searchHandler}
                        className={"w-100 instructorSearch"}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="downloadPDf">
                        <button
                            className="export_csv"
                            onClick={handleDownloadClick}>
                            Export (CSV)
                        </button>
                    </div>
                </Col>
            </Row>
            <Row >
                <Col>
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th style={{    display: "flex",  justifyContent:" space-between"}} scope="col" onClick={()=>shortHandler(tabledata)}> Name{arrow ? <i class='fas fa-arrow-circle-up' style={{fontSize:"24px"}}></i> :<i class='fas fa-arrow-circle-down' style={{fontSize:"24px"}}></i>}</th>
                                <th scope="col">Date of Birth</th>
                                <th scope="col">Email</th>
                                <th scope="col">Age</th>
                                <th scope="col">Gender</th>
                                <th scope="col">Start Date</th>
                                <th scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tabledata && tabledata.map((e) => (
                                <tr key={e.id}>
                                    <td>{e.Name}</td>
                                    <td>{e.dob}</td>
                                    <td>{e.Email}</td>
                                    <td>{e.Age}</td>
                                    <td>{e.Gender}</td>
                                    <td>{e.Start_Date}</td>
                                    <td>{e.Status}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Col>
                {/* <Col> */}
                    {/* <BootstrapTable
                        keyField="id"
                        data={tabledata}
                        columns={columns}
                        striped
                        hover
                        wrapperClasses="table-responsive"
                        pagination={paginationFactory()}
                    /> */}
                {/* </Col> */}
                {iniData && iniData.length <= 0 && (
                    <p className="text-center">No Data Available</p>
                )}
            </Row>

        </>
    )
}
