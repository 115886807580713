import React, { useState, useEffect, useRef } from "react";
import moment from 'moment';

export const WorkTime = (props) => {
  const [count, setCount] = useState(null);
  const [start,setStart] = useState();

  const counter_timer = useRef();
  const [shDate,setShDate] = useState();
  const [toTime,setToTime] = useState();
  const [from_time,setFrom_time] = useState();

  useEffect(()=>{
    setShDate(props.shduleData);
    setToTime(props.toTime);
    setFrom_time(props.fromTime);
  },[props])

  

  const CounterChange = () => {

    
    // var x = setInterval(function () {
      const currentDate = moment().format('DD/MM/YYYY');
      const now = moment().format('HH:mm:ss');
      const then = moment.unix(from_time).format('YYYY-MM-DD HH:mm:ss');

      // Validation End Time
      const exthen = moment.unix(toTime).format('YYYY-MM-DD HH:mm:ss');
      const exTime = moment(exthen);
   
      var endTime = moment(then);
      const currentTime = moment();
      let counterData = '00:00:00';
      const cur = moment().format('Y-MM-DD');
      const cDate = new Date(cur);
      const scheduleCreate = new Date(shDate);
      if (scheduleCreate >= cDate) {
        if(exTime.isAfter(currentTime)){
        if (endTime.isAfter(currentTime)) {
          const startTime = currentDate + ' ' + now;
          //const endTime = currentDate + ' ' + then;
          var counter = moment(endTime, "DD/MM/YYYY HH:mm:ss").diff(moment(startTime, "DD/MM/YYYY HH:mm:ss"));
          var d = moment.duration(counter);
          var days = (Math.floor(d.asDays()) < 10 ? '0' + Math.floor(d.asDays()) : Math.floor(d.asDays()));
          var hours = Math.floor(d.asHours()) - Math.floor(d.asDays()) * 24;
          var hours1 = (hours < 10 ? '0' + hours : hours);
          var s = days + ":" + hours1 + moment.utc(counter).format(":mm");
          counterData = s.toString();
          setCount(counterData);
          // counter_timer=(counterData);
        }else{
          setCount(null);
          setStart('Workshop Started');
        }
      }else{
        setCount(null);
        setStart('Completed');
      }
      }else{
        setCount(null);
        setStart('Completed');
      }

    //   if (counterData == '00:00:00') {
    //     clearInterval(x);
    //   }
    // }, 6000);
    // return toTime;

    // print_r('Test')
  }


  useEffect(()=>{
    CounterChange();
  },[shDate,toTime,from_time]);


  return (
    <>
      <small>Starts in (DD:HH:MM):</small>    
      {count ?   
    <span className="dateTime"><b><h5>{'          '+count ? count : "" }</h5></b></span>
    :
    <span ><b><p style={{color:"#77787B"}}>{'          '+start ? start : "Completed"}</p></b></span>
    }
    </>
  );
};
