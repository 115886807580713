import React from "react";
import { Tab, Tabs } from "react-bootstrap";

const SharedTabs = ({ tabs }) => {
  return (
    <Tabs defaultActiveKey={tabs[0].eventKey} id="shared-tabs" className="mb-3" justify>
      {tabs.map((tab) => (
        <Tab key={tab.eventKey} eventKey={tab.eventKey} title={tab.title} disabled={tab.disabled}>
          {tab.content}
        </Tab>
      ))}
    </Tabs>
  );
};

export default SharedTabs;
