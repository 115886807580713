import React from "react";
import { InnerHeader } from "../../Components/inner-header/InnerHeader1";
import { Innerfooter } from "../../Components/inner-footer/Innerfooter";
import { useState } from "react";
import { Modal, Form, Spinner } from "react-bootstrap";
import {
  API_user_dashboard,
  favourite,
  staff_get_business,
  staff_get_business_search,
  staff_get_categories,
} from "../../../Static_Services/Staff_Services";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { errorAlert, successAlert } from "../../../Static_Services/Alertmsg";
import { DashboardSlider } from "./DashboardSlider";

export const DashboardClient = () => {

  // New Code
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState();
  const [dashboardDetail, setDashboardDetail] = useState([]);
  const [loder, setLoder] = useState(false);
  const [distanceMode, setDistanceMode] = useState(false);
  const [categoryText, setCategoryText] = useState();
  const [categoryList, setCategoryList] = useState([]);
  const [subcategoryList, setSubcategoryList] = useState([]);
  const [searchText, setSearchText] = useState();
  const [selectedItemsList, setSelectedItemsList] = useState();
  const [modal, setModal] = useState(false);
  const [isChecked, setIsChecked] = useState();



  useEffect(() => {
    if (localStorage.getItem('w_loginDetail')) {
      setUserInfo(JSON.parse(localStorage.getItem('w_loginDetail')))
    }
  }, []);


//   useEffect(()=>{
// console.log('==============>',userInfo);
//   },[userInfo])


  // 

  const [rangeValue, setRangeValue] = useState(250); // Set an initial value (e.g., 250 km)
  const [isSwitchOn, setIsSwitchOn] = useState(false);

  const handleChange = (event) => {
    setRangeValue(event.target.value);
  };

  const handleSwitchChange = () => {
    setIsSwitchOn(!isSwitchOn);
  };

  // **************** New Code ***************


  const getDashboardDetail = async () => {
    const detail = { "pageid": "1", "lat": userInfo.lat, "lang": userInfo.lang };
    const resp = await API_user_dashboard(detail);
    if (resp) {
      const response = resp.data;
      setDashboardDetail(response.data);
      setLoder(false);
    } else {
      setLoder(false);
    }
  }

  const redirectUrl = (businessId) => {
    localStorage.setItem('w_detailBack', '/my-home');
    this.route.navigate('/detail', { state: { Id: businessId } });
  }

  const addFav = async (id, status) => {
    setLoder(true);
    const detail = { "service_type": "1", "service_id": id, "status": status };
    const resp = await favourite(detail);
    if (resp) {
      successAlert(resp.data.message);
      getDashboardDetail();
      setLoder(false);
    } else {
      setLoder(false);
    }
  }

  const redirecrUrl1 = (detail) => {
    navigate('/allStudio', { state: { Studio: detail } });
  }

  const searchHome = async () => {
    setLoder(true);
    const detail = {
      "pageid": "1", "search_text": searchText, "category": '', "subcategory": '', "lat": userInfo.lat,
      "lang": userInfo.lang, "distance": '', "business_id": ""
    };
    const resp = await staff_get_business(detail);
    if (resp) {
      const response = resp.data;
      response.data.total_count = response.total_count;
      navigate('/search', { state: { 'SerchData': response.data } });
      setLoder(false);
    } else {
      setLoder(false);
    }
  }

  const filterHome = async () => {
    let lat = ''
    let lang = '';
    let sliderhighValue = '';
    setLoder(true);
    if (distanceMode != true) {
      sliderhighValue = '';
      lat = '';
      lang = '';
    } else {
      lat = userInfo.lat;
      lang = userInfo.lang;
    }
    const detail = {
      "pageid": "1", "search_text": '', "category": categoryText, "subcategory": selectedItemsList, "lat": lat,
      "lang": lang, "distance": sliderhighValue, "business_id": ''
    };
    const resp = await staff_get_business_search(detail);
    if (resp.data.status == 1) {
      let response = resp.data;
      response.data.total_count = response.total_count;
      navigate('/search', { state: { 'SerchData': response.data } });
      setLoder(false);
    } else {
      errorAlert('Studio Not Found');
      setLoder(false);
    }
  }

  const getValue = (event) => {
    setDistanceMode(event.target.checked);
  }

  const getCategories = async () => {
    setLoder(true);
    const detail = { "type": "2", "subcat": categoryText, "business_id": "" };
    const resp = await staff_get_categories(detail);
    if (resp) {
      let response = resp.data;
      if (categoryText) {
        response.data.map(function (el) {
          el.isChecked = false;
        })
        setSubcategoryList(response.data);
        setLoder(false);
      } else {
        setCategoryList(response.data);
        setCategoryText(response.data[0].id);
        getCategories();
        setLoder(false);
      }
    } else {

    }
  }

  const changeSelection = () => {
    var subcatText;
    subcategoryList.map((value, index) => {
      if (value.isChecked) {
        if (subcatText) {
          subcatText = value.id + "," + subcatText;
        }
        else {
          subcatText = value.id;
        }
      }
    });
    setSelectedItemsList(subcatText);
  }


  useEffect(() => {
    if (userInfo) {
      getDashboardDetail();
      getCategories();
    }
  }, [])

  // const useEffect()=>{

  // },[])


  return (
    <>
      {loder ? <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div> : ""}
      <InnerHeader />
      <div class="body-grey">
        <div class="activity-section py-5">
          <div class="container">
            <div class="row">
              <div class="col-12 col-md-6 px-2">
                <div class="section-heading pt-3">
                  <h5 class="title black-color font-w-100 text-uppercase font-16"> Find a studio </h5>
                </div>
              </div>
              <div class="col-12 col-md-5 px-2">
                <div class="section-search">
                  <div class="form-group has-search pl-0 pl-md-3">
                    <a href="Javascript:void(0);" onClick={(e) => searchHome()}>
                      <span class="fas fa-search form-control-feedback" style={{ bottom: "7px", top: "unset", pointerEvents: "initial" }}></span>
                    </a>
                    <input type="text" class="form-control" placeholder="Search" value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault(); // Prevent the default form submission behavior if inside a form
                          searchHome();
                        }
                      }}
                    />
                  </div>

                </div>
              </div>
              <div class="col-12 col-md-1">
                <a href="Javascript:void(0);" data-toggle="modal" data-target="#addFilter"> <img
                  src="clientassets/img/ic_filter.png" class="img-fluid box-shadow1" style={{
                    height: "48px",
                    width: "56px"
                  }} /></a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 px-2">
        </div>
        {dashboardDetail.length != 0 &&
          <div>
            {dashboardDetail.map((dashDetail) => (
              <div class="activity-section pb-5" >
                <div class="container">
                  <div class="row">
                    <div class="col-6 pl-2">
                      <div class="section-heading pt-2 pb-4 text-left">
                        <a href="Javascript:void(0);"
                          onClick={() => redirecrUrl1(dashDetail)}
                          class="black-color heading-titlenew1">
                          {dashDetail.category_name} </a>
                      </div>
                    </div>
                    <div class="col-6 pr-2">
                      <div class="section-heading pt-2 pb-3 text-right pr-4">
                        <a href="Javascript:void(0);"
                          onClick={() => redirecrUrl1(dashDetail)}
                          class="brand-color3 font-w-100 font-14">
                          View All </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="container-fluid">
                  {dashDetail.business.length >= 3 &&
                    <div class="studio-cards studio-padding-left" >
                      <div class="new-slider-logo">
                        <DashboardSlider pass={dashDetail.business} addFav={addFav} redirectUrl={redirectUrl} />
                      </div>
                    </div>
                  }

                  {dashDetail.business.length < 3 &&
                    <div class="studio-cards studio-padding-left">
                      <div class="row">
                        {dashDetail.business.map((dashsubDetail) => (
                          <div class="col-12 col-lg-3 mb-2 px-2">
                            <div class="studio-position-heart">
                              <div class="studio-card-newcustom gradient-studio-overlay box-shadow1" onClick={() => redirectUrl(dashsubDetail.business_id)}>
                                <div class="img-box" style={{ backgroundImage: `url(${dashsubDetail.business_img})` }}>
                                </div>
                                <div class="studio-detail p-3">
                                  <div class="row mx-0 align-items-start">
                                    <div class="profile-image box-shadow1">
                                      <img src={dashsubDetail.logo} class="img-fluid" />
                                    </div>
                                    <div class="studio-profile-detail flex-fill pl-4">
                                      <h5 class="nametitle classname-title mb-1">{dashsubDetail.business_name}</h5>
                                      {dashsubDetail.address.length <= 25 &&
                                        <p class="brand-color3 paratitle mb-2">
                                          {dashsubDetail.address}</p>}
                                      {dashsubDetail.address.length > 25 &&
                                        <p class="brand-color3 paratitle mb-2">
                                          {dashsubDetail.address}</p>}
                                      <p class="paratitle"><i class="fas fa-map-marker-alt mr-2 mb-0"></i>
                                        {dashsubDetail.distance}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="studio-card-select">
                                {dashsubDetail.favourite == 0 &&
                                  <i class="fas fa-heart brand-color3 font-22"
                                    onClick={() => addFav(dashsubDetail.business_id, 1)}
                                  ></i>}
                                {dashsubDetail.favourite == 1 &&
                                  <i class="fas fa-heart brand-color2 font-22"
                                    onClick={() => addFav(dashsubDetail.business_id, 0)}
                                  ></i>
                                }
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  }
                </div>
              </div>
            ))}
          </div>
        }

        <div className="row  border-radius6 p-3"></div>

        {/* <div className="modal fade" id="addFilter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"> */}
        <Modal show={modal} className="modal fade">
          <div
            className="modal-dialog modal-dialog-centered"
            id="ownModalWidth"
            role="document"
          >
            <div class="modal-content">
              <div class="modal-body light-bg box-shadow1 border-radius6 px-4 pb-4 px-md-5 pb-md-5">
                <div class="mb-5">
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => setModal(false)}
                  >
                    <span aria-hidden="true">

                      <img src="clientassets/img/ic_close.png" class="img-fluid"
                      />
                    </span>
                  </button>
                </div>
                <div class="form-group relative mt-5">
                  <h5 class="title black-color"> Find your studio </h5>
                  <select
                    class="form-control-1 white-color"
                  // onChange={(e) => getSubCategories(e)}
                  >
                    {categoryList &&
                      categoryList.map((cd, i) => (
                        <option selected={i === 0} value={cd.id}>
                          {" "}
                          {cd.category_name}{" "}
                        </option>
                      ))}
                  </select>
                </div>
                <div class="form-group show-business mb-0 pt-3 mt-md-3">
                  <h5 class="title black-color"> Show business having </h5>
                  <div class="service-button">
                    {subcategoryList &&
                      subcategoryList.map((item) => (
                        <div
                          className="checkboxButton "
                          style={{ display: "inline" }}
                        >
                          <input
                            type="checkbox"
                            name="checkbox"
                            id={item.id}
                            value={item.id}
                            checked={item.id === isChecked}
                            onChange={(e) => changeSelection(e)}
                          />
                          {/* <input type="checkbox" name="checkbox" id="{{item.id}}"
                       [value]="item.id" [(ngModel)]="item.isChecked"
                       (change)="changeSelection()"
                      /> */}
                          <label for={item.id}> {item.category_name} </label>
                        </div>
                      ))}
                  </div>
                </div>
                <div class="form-group mb-0 pt-3 mt-md-3">
                  <div className="custom-controls-stacked d-block">
                    <label className="custom-control ios-switch text-right mb-0 p-0">
                      <h5 className="title black-color d-inline float-left">
                        Distance in KM
                      </h5>
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        checked={isSwitchOn}
                        onChange={handleSwitchChange}
                      />
                    </label>
                    {isSwitchOn && (
                      <div>
                        <Form>
                          <Form.Group controlId="rangeControl">
                            <div className="range-slider">
                              <div className="range-label">0 km</div>
                              <input
                                type="range"
                                id="customRange"
                                min={0}
                                max={500}
                                value={rangeValue}
                                onChange={handleChange}
                                step={1}
                                className="custom-range"
                              />
                              <div className="range-label">500 km</div>
                            </div>
                          </Form.Group>
                        </Form>
                        <div className="distance-result">
                          <p className="black-color d-inline">
                            Distance range: 0 Km - {rangeValue} km
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div class="form-group px-3">
                  <button
                    type="button"
                    data-dismiss="modal"
                    class="btn btn-blue-grad text-uppercase w-100 mt-3"
                  // (click)="filterHome()"
                  >
                    {" "}
                    Apply filter{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div >
      <Innerfooter />
    </>
  );
};
