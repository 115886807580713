import React, {useEffect, useState} from "react";
import { CalenderScheduler } from "../../../../../sharedComponents/CalenderScheduler";
import Sidebar from "../../comman_page/Sidebar";
import { HeaderDashboard } from "../../comman_page/HeaderDashboard";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { Footer } from "../../../../../public/containers/footer/Footer";
import { SharedSelect } from "../../../../../sharedComponents/SharedSelect";
import { SharedButton } from "../../../../../sharedComponents/Button";
import { BsSearch } from "react-icons/bs";
import { GrRefresh } from "react-icons/gr";
import { AiFillPlusCircle } from "react-icons/ai";
import { getInstructorList, get_room_location, get_shift_schedule_calendar } from "../../../../../Studio-Services/Studio_Services";
import moment from "moment";
import { ShiftSheduleClalendar } from "./ShiftSheduleClalendar";

export const Shift = () => {

  const [resps, setResps] = useState([]);
  const [instOption, setInstOption] = useState();
  const [finalInstruct,setFinalInstruct]= useState();
  const [locationOption, setLocationOption] = useState([]);
  const [finalLocation, setFinalLocation] = useState();
  const [loder,setLoder] = useState(false);

  const [visibleRange, setVisibleRange] = useState(null);
  const [calendardates, setCalendardates] = useState({ 'start': moment().startOf('year').format('YYYY-MM-DD'), 'end': moment().endOf('year').format('YYYY-MM-DD') });


  const InstList = async () => {
    const fData = {
      "pageid": 1
    }
    const resp = await getInstructorList(fData);
    if (resp) {
      const instOP = [];
      (resp.data.data).forEach(e => {
          instOP.push({
            value: e.id, label: `${e.name} ${e.lastname}`
          })
      })
      setInstOption(instOP);
    }
  }

  const getRoomLocation = async () => {
    const fData = {
      "business_id": localStorage.getItem("business_id")
    }
    const resp = await get_room_location(fData);
    if (resp) {
      const loc = [];
      (resp.data.data).map((e) => (
        loc.push({
          value: e.location_id, label: e.location_name
        })
      ))
      setLocationOption(loc);
    }
  }


  const classes = async () => {
    setLoder(true);
    const startOfMonth = moment().startOf('year').format('YYYY-MM-DD');
    const endOfMonth = moment().endOf('year').format('YYYY-MM-DD');
    const passData = {
      start: startOfMonth,
      end: endOfMonth,
      page :1
    };
    const resp = await get_shift_schedule_calendar(passData);
    if (resp) {
      setLoder(false);
      setResps(resp.data);
    }
    setLoder(false);
  };


  const calanderOnClhange = async (dateData) => {
    setLoder(true);
    const chDates = {...dateData};
    const startOfMonth = chDates.start;
    const endOfMonth = chDates.end;
    const passData = {
      start: startOfMonth,
      end: endOfMonth,
      page :1
    };
    const resp = await get_shift_schedule_calendar(passData);
    if (resp) {
      setLoder(false);
      setResps(resp.data);
    }
    setLoder(false);
  };



  useEffect(()=>{
    calanderOnClhange(calendardates);
  },[calendardates])
  
  useEffect(() => {
    classes();
    InstList();
    getRoomLocation();
  }, []);

  const setEventsHandler = async () =>{
    // const currentDates = moment();
    // const startOfMonths = currentDates.clone().startOf('month').format('YYYY-MM-DD');
    // const endOfMonths = currentDates.clone().endOf('month').format('YYYY-MM-DD');
    setLoder(true);
    const startOfMonths = moment().startOf('year').format('YYYY-MM-DD');
    const endOfMonths = moment().endOf('year').format('YYYY-MM-DD');

    let fData='';


    if(finalLocation && finalInstruct ){
     fData={
        "start": startOfMonths,
        "end": endOfMonths,
        "instructor": [finalInstruct],
        "location": [finalLocation]
      }
    }else if(finalLocation){
      fData={
        "start": startOfMonths,
        "end": endOfMonths,
        "location": [finalLocation]
      }
    }else if(finalInstruct){
      fData={
        "start": startOfMonths,
        "end": endOfMonths,
        "instructor": [finalInstruct],
      }
    }


    const resp = await get_shift_schedule_calendar(fData);
    if (resp!==undefined) {
      setLoder(false);
      setResps(resp.data);
    }else{
      setResps([]);
      setLoder(false);
    }
    setLoder(false);
  }
  const resetHandler = () =>{
    setFinalLocation("");
    setFinalInstruct("");
    classes();
  }

  return (
    <>
       {loder && <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div> }
    <div className="MainDashboard">
      <div className="MainLayout">
        <Row>
          <Sidebar />
          <Col>
            {/* <div className="RightSide"> */}
            <HeaderDashboard />
            <div className="RightSide">
              <div className="innerContent">
                <div className="ListYourService">
                  <Container>
                    <Row className="pt-0 pb-0">
                      <Col>
                        <h4>SHIFT SCHEDULE</h4>
                      </Col>
                      <Col>
                          <SharedSelect
                          value={finalInstruct}
                          onChange={(e)=>setFinalInstruct(e.target.value)}
                            options={instOption}
                          />
                        </Col>
                        <Col>
                          <SharedSelect
                          value={finalLocation}
                            onChange={(e) => setFinalLocation(e.target.value)}
                            options={locationOption}
                          />
                        </Col>
                      <Col>
                        <div className="appointmentPlus">
                          <div className="appointIcons">
                            <SharedButton
                            onClick={setEventsHandler}
                              variant={"outline"}
                              className={"AppointmentIcons bg-white"}
                              iconNode={<BsSearch />}
                            />
                            <SharedButton
                              variant={"outline"}
                              onClick={resetHandler}
                              className={"AppointmentIcons bg-white"}
                              iconNode={<GrRefresh />}
                            />
                          </div>
                          <div className="appointIcons">
                            <SharedButton
                              variant={"danger"}
                              className={"PlusIcon"}
                              iconNode={<AiFillPlusCircle />}
                              href={"/list-shifts"}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <ShiftSheduleClalendar resp={resps} setVisibleRange={setVisibleRange} setCalendardates={setCalendardates} calendardates ={calendardates} />
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>

              <Footer />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  </>
  )
}
