import moment from "moment";
import React from "react";
import { Carousel, Card, Row, Col } from "react-bootstrap";
import { FaLongArrowAltRight, FaLongArrowAltLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export const Other_Products_Available_Slider = ({ pass, getProductList,businessDetail}) => {
    const navigate = useNavigate();
    const cardDetail = pass ? pass : [];
    const chunks = [];
    for (let i = 0; i < cardDetail.length; i += 2) {
        chunks.push(cardDetail.slice(i, i + 2));
    }

    const settings = {
        prevIcon: <FaLongArrowAltLeft />,
        nextIcon: <FaLongArrowAltRight />,
        interval: 3000,
        pauseOnHover: true,
        wrap: true,
        touch: true,
        keyboard: true,
        slidesToShow: 2,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <>
            {chunks.length > 0 && (
                <Carousel {...settings} className="staffSlider">
                    {chunks.map((chunk, index) => (
                        <Carousel.Item key={index}>
                            <Row className="mb-5 staffSlider">
                                {chunk.map((similarProduct, cardIndex) => (
                                    <Col lg={6} md={6} xs={12} key={cardIndex}>
                                        <a href="Javascript:void(0);"  onClick ={()=>getProductList(similarProduct.product_id)}>
                                        <div class="text-center">
                                            <div class="white-bg border-radius6 box-shadow1 p-3 mb-3">
                                                <img src={similarProduct && similarProduct.product_images[0] && similarProduct.product_images[0].image_name ? similarProduct.product_images[0].image_name :businessDetail.business_img} style={{height: "221px"}} />
                                            </div>
                                            <h5 class="heading-titlenew black-color"> { similarProduct.product_name }</h5>
                                        </div>
                                    </a>
                                    </Col>
                                ))}
                        </Row>
                        </Carousel.Item>
            ))}
        </Carousel >
            )}
        </>
    );
};
