import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import { SharedButton } from "./Button";
import { Link } from "react-router-dom";
import { BsCartPlusFill } from "react-icons/bs";
import { useEffect } from "react";
import { useState } from "react";
import { Button } from "bootstrap";

export const ProductCardList = ({data,onClick}) => {
const [productCardDetail, setProductCardDetail] = useState();

  const cart=()=>{
    const mp = [];
    data.map((e)=>(
      mp.push({
      id:e.id,
      memberShipImg: e.pro_image ? e.pro_image : "https://warriorsms.com/superadmin/uploads/products/1652214051WarriorMan_icon2_CMYK.png",
      cardTitle: e.product_name,
      amount: e.price,
      })
    ))
    setProductCardDetail(mp);
  }
useEffect(()=>{
if(data){
  cart();
}
},[data])


  // const productCardDetail = [
  //   {
  //     memberShipImg:
  //       "https://warriorsms.com/superadmin/uploads/products/1652214051WarriorMan_icon2_CMYK.png",
  //     cardTitle: "Basic Plan",
  //     text: "Visible",
  //     amount: 9.99,
  //   },
  // ];

  return (
    <>
      <Row>
        {productCardDetail
          ? productCardDetail.map((value, index) => (
              <Col md={3} key={index}>
                <button onClick={()=>onClick('3', value, '1')} className="productCardsAnchor">
                  <span className="AddCartHover">
                    <BsCartPlusFill />
                  </span>
                  <Card className="ProductCardList" key={index}>
                    <div className="cardImg">
                      <Card.Img variant="top" src={value.memberShipImg} />
                    </div>
                    <Card.Body>
                      <Card.Text>
                        <Card.Title>{value.cardTitle}</Card.Title>
                        <p className="m-0">
                          {" "}
                          <small>{value.text}</small>
                        </p>
                      </Card.Text>
                      <SharedButton title={value.amount} />
                    </Card.Body>
                  </Card>
                </button>
              </Col>
            ))
          : ""}
      </Row>
    </>
  );
};
