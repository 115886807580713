import React from 'react'
import StaticHeader from '../../StaticHeader'
import StaticFooter from '../../StaticFooter'
import { PrivacyPolicy } from '../../static-service/StaticServices'
import { useEffect } from 'react'
import { useState } from 'react'

export const StaticPolicy = () => {
  const [content, setContent] = useState();

  const getPolicy = async () => {
    const resp = await PrivacyPolicy();
    setContent(resp.content);
  }

  useEffect(() => {
    getPolicy();
  }, [])

  return (
 <>
 <StaticHeader />
 {/* <!-- Privacy Policy Content Section --> */}
  <section className="">
    <div className="background-before">
      <div className="py-4 mt-2">
        <div className="container privacy-content">
          <div className="text-left wow animated slideInLeft">
            <h5 className="dark-color font-w-800 pb-4"> Privacy Policy </h5>
            <div dangerouslySetInnerHTML={{ __html:content}}  className='AboutSection'></div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* <!-- End of Privacy Policy Content Section --> */}
 <StaticFooter />
 </>
  )
}
