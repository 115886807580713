import React from 'react'
import { SubHeader } from '../../Components/sub-header/SubHeader'
import { Innerfooter } from '../../Components/inner-footer/Innerfooter'
import { InnerHeader } from '../../Components/inner-header/InnerHeader1'
import { DashboardToSectionDetails } from '../../Components/CommanPages/DashboardToSectionDetails'
import { Spinner } from 'react-bootstrap'
import { useState } from 'react'
import { useEffect } from 'react'
import { API_my_purchase_list, favourite } from '../../../Static_Services/Staff_Services'
import moment from 'moment'
import { successAlert } from '../../../Static_Services/Alertmsg'
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

export const ClientMyPurchase = () => {
  const [loder, setLoder] = useState(false);
  const navigate = useNavigate();
  const [userDetail, setUserDetail] = useState();
  const [businessDetail, setBusinessDetail] = useState();
  const [serviceDetail, setServiceDetail] = useState([]);
  const [passDetail, setPassDetail] = useState([]);
  const [activePassDetail, setActivePassDetail] = useState([]);
  const [expirePassDetail, setExpirePassDetail] = useState([]);
  const [productDetail, setProductDetail] = useState([]);
  const [myproductDetail, setMyproductDetail] = useState([]);
  const [purchaseDetail, setPurchaseDetail] = useState([]);
  const [dataCount, setDataCount] = useState();
  const [pagecount, setPagecount] = useState(1);
  const [currentStatus, setCurrentStatus] = useState('');
  const [active, setActive] = useState(1);
  const [stage, setStage] = useState('');

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('w_loginDetail'))) {
      setUserDetail(JSON.parse(localStorage.getItem('w_loginDetail')))
    }
    if (JSON.parse(localStorage.getItem('w_BusinessDetail'))) {
      setBusinessDetail(JSON.parse(localStorage.getItem('w_BusinessDetail')))
    }
  }, [])

  const puchaseList = async (status, count, active) => {
    setActive(active);
    let detail;
    let currentStatus = status;
    setStage(status);
    setLoder(true);
    if (status == '') {
      detail = { "pageid": count, "business_id": businessDetail.business_id };
    } else {
      detail = { "pageid": count, "business_id": businessDetail.business_id, "purchase_status": status };
    }
    const resp = await API_my_purchase_list(detail);
    if (resp) {
      let response = resp.data;
      if (currentStatus === '3') {
        if (response.data.length != 0) {
          response.data.map((el) => {
            el.purchase_date_new = moment.unix(el.shift_date).format('MMM DD YYYY');
            el.start_time_new = moment.unix(el.start_time).format('hh mm A');
            el.end_time_new = moment.unix(el.end_time).format('hh mm A');
            el.Name = el.name + ' ' + el.lastname;
            if (el.family_user_id != 0) {
              el.Name = el.family_member_name;
            }
          })
        }
        if (pagecount == 1) {
          setServiceDetail(response.data);
        } else {
          setServiceDetail((serviceDetail) => serviceDetail.concat(response.data));
        }
        // this.ServiceDetail = response.data;
      }
      else if (currentStatus === '1') {
        if (response.data.length != 0) {
          var self = this;
          let actvPassDetail = [];
          let expPassDetail = [];
          response.data.map((el) => {
            el.start_date_new = moment.unix(el.start_date).format('MMM DD YYYY');
            el.end_date_new = moment.unix(el.end_date).format('MMM DD YYYY');
            if (el.pass_status_text == 'Active') {
              actvPassDetail.push(el);
            } else if (el.pass_status_text == 'Paused') {
              actvPassDetail.push(el);
            } else {
              expPassDetail.push(el);
            }
          })
          setActivePassDetail(actvPassDetail);
          setExpirePassDetail(expPassDetail);
        }
        if (pagecount == 1) {
          setPassDetail(response.data);
        } else {
          setPassDetail((passDetail) => passDetail.concat(response.data));
          setActivePassDetail([]);
          setExpirePassDetail([]);
          let actvPassDetail = [];
          let expPassDetail = [];
          passDetail.map((el) => {
            if (el.pass_status_text == 'Active') {
              activePassDetail.push(el);
            } else if (el.pass_status_text == 'Paused') {
              activePassDetail.push(el);
            } else {
              expirePassDetail.push(el);
            }
          })
          setActivePassDetail(actvPassDetail);
          setExpirePassDetail(expPassDetail);
        }
        // this.PassDetail = response.data;
      }
      else if (currentStatus === '2') {
        if (response.data.length != 0) {
          response.data.map((el) => {
            el.purchase_date_new = moment.unix(el.purchase_date).format('MMM DD YYYY');
          })
        }
        if (pagecount == 1) {
          setMyproductDetail(response.data);
          setProductDetail(response.data);
        } else {
          setProductDetail((productDetail) => productDetail.concat(response.data));

        }
        //  this.ProductDetail = response.data;
      } else {
        if (response.data.length != 0) {

          response.data.map((el) => {
            if (el.pass_id) {
              el.start_date_new = moment.unix(el.start_date).format('MMM DD YYYY');
              el.end_date_new = moment.unix(el.end_date).format('MMM DD YYYY');
            }
            if (el.product_id) {
              el.purchase_date_new = moment.unix(el.purchase_date).format('hh mm A');
            }
            if (el.service_id) {
              el.purchase_date_new = moment.unix(el.shift_date).format('MMM DD YYYY');
              el.start_time_new = moment.unix(el.start_time).format('hh mm A');
              el.end_time_new = moment.unix(el.end_time).format('hh mm A');
              el.Name = el.name + ' ' + el.lastname;
              if (el.family_user_id != 0) {
                el.Name = el.family_member_name;
              }
            }
          })
        }
        if (pagecount == 1) {
          setPurchaseDetail(response.data);
        } else {
          setPurchaseDetail((purchaseDetail) => purchaseDetail.concat(response.data));
          // this.purchaseDetail = this.purchaseDetail.concat(response.data);
        }
        // this.purchaseDetail = response.data;
      }
      setLoder(false);
    } else {
      setDataCount(0);
      // if (pagecount == 1) {
      //   if (currentStatus === 3) {
      //     setServiceDetail([]);
      //   }
      //   if (currentStatus === 1) {
      //     setPassDetail([])
      //     setActivePassDetail([]);
      //     setExpirePassDetail([]);
      //   }
      //   if (currentStatus === 2) {
      //     setProductDetail([]);
      //   }
      //   if (currentStatus === '') {
      //     setPurchaseDetail([]);
      //   }
      // }
      setLoder(false);
    }
  }


  const addFav = async (id, status) => {
    setLoder(true);
    const detail = { "service_type": "2", "service_id": id, "status": status };
    const resp = await favourite(detail);
    if (resp) {
      const response = resp.data;
      successAlert(response.message);
      puchaseList(1, 1);
    } else {
      setLoder(false);
    }
  }

  const reset = () => {
    navigate('/studio-detail');
  }

  const redirectUrl = (id, booking_id, url, status) => {
    if (status == 'Expired') {
      Swal.fire({
        text: 'Would you like to purchase this pass again.',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Ok',
      }).then((result) => {
        if (result.value) {
          var detail = { 'id': id, 'url': '/my-purchase', 'businessId': this.businessDetail.business_id, 'user_booking_id': booking_id };
          this.route.navigate(url, { state: { Id: detail } });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
      })
    } else {
      var detail = { 'id': id, 'url': '/my-purchase', 'businessId': this.businessDetail.business_id, 'user_booking_id': booking_id };
      this.route.navigate(url, { state: { Id: detail } });
    }
  }

  const redirectUrl1 = (businessId, url) => {
    // var detail ={'id':businessId ,'url':url};
    navigate(url, { state: { Id: businessId } });
  }

  const onScroll = () => {
    // if(this.dataCount !=0 ) {
    //   if(this.currentStatus == 1){
    //     if(this.PassDetail.length == 10){
    //       this.pagecount++;
    //       this.puchaseList(this.currentStatus,this.pagecount);    
    //     }
    //   } else if(this.currentStatus == 2){
    //     if(this.ProductDetail.length == 10){
    //       this.pagecount++;
    //       this.puchaseList(this.currentStatus,this.pagecount);    
    //     }
    //   } else if(this.currentStatus == 3){
    //     if(this.ServiceDetail.length == 10){
    //       this.pagecount++;
    //       this.puchaseList(this.currentStatus,this.pagecount);    
    //     }
    //   } else {
    //     if(this.purchaseDetail.length == 10){
    //       this.pagecount++;
    //       this.puchaseList(this.currentStatus,this.pagecount);    
    //     }
    //   }
    // }
  }

  useEffect(() => {
    if (businessDetail) {
      puchaseList('', 1, 1)
      // puchaseList(currentStatus, pagecount);
    }
  }, [businessDetail])



  return (
    <>
 
      {loder ? <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div> : ""}
      <InnerHeader />

      <div className="body-grey">
        <div class="studio-details">
          <div class="container">
            <DashboardToSectionDetails />
            <div class="studio-details-lower pb-5">
              <div class="row mt-3">
                <div class="col-sm-4 col-lg-8">
                  <h4 class="black-color font-w-400 font-18 mb-0 text-uppercase font-arialrouded">My Purchases </h4>
                </div>
                <div class="col-md-4 col-sm-6 text-right mb-3">
                  <button type="button" class="btn btn-blue-grad px-2 text-uppercase"
                    onClick={() => reset()}
                  >Back </button>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="white-bg border-radius6 box-shadow1 p-3">
                    <div class="centered-cover">
                      <div class="flex-break">
                        <div class="box my-purchase">
                          <div class="row">
                            <div class="col-12">
                              <div class="my-purchase-top-right">
                                <div class="my-purchase-right-buttons">
                                  <div id="exTab1" class="">
                                    <ul class="nav nav-pills" role="tablist">
                                      <li class="nav-item">
                                        <Link class={active === 1 ? "nav-link active" : "nav-link"} data-toggle="pill" to="#all"
                                          onClick={() => puchaseList('', 1, 1)}
                                        >All</Link>
                                      </li>
                                      <li class="nav-item">
                                        <Link class={active === 2 ? "nav-link active" : "nav-link"} data-toggle="pill" to="#products"
                                          onClick={() => puchaseList('2', 1, 2)}
                                        >Products</Link>
                                      </li>
                                      <li class="nav-item">
                                        <a class={active === 3 ? "nav-link active" : "nav-link"} data-toggle="pill" href="#passes"
                                          onClick={() => puchaseList('1', 1, 3)}
                                        >Passes</a>
                                      </li>
                                      <li class="nav-item">
                                        <a class={active === 4 ? "nav-link active" : "nav-link"} data-toggle="pill" href="#services"
                                          onClick={() => puchaseList('3', 1, 4)}
                                        >Services</a>
                                      </li>
                                    </ul>
                                    <div class="tab-content">

                                      {/* All */}

                                      {stage == '' &&

                                        <div class="tab-pane active" id="all">
                                          <div infinite-scroll
                                            //  [infiniteScrollDistance]="scrollDistance"  [infiniteScrollUpDistance]="scrollUpDistance"  [infiniteScrollThrottle]="throttle"  (scrolled)="onScroll()" 
                                            alwaysCallback="true">
                                            {purchaseDetail.length != 0 &&
                                              <div class="row std-detail-purchase pt-0 pt-md-4 mt-2 mb-3" >
                                                {purchaseDetail.map((items) => (
                                                  <div class="col-12 col-md-6 mb-3" >
                                                    {items.service_id &&
                                                      <div>
                                                        <div class="std-purchase-card box-shadow1 border-radius6 white-bg px-2 py-3">
                                                          <div class="row" style={{ height: "239px" }}>
                                                            <div class="col-3 col-md-12 col-lg-3">
                                                              <div class="text-center">
                                                                <img src={businessDetail && businessDetail.logo} class="box-shadow1 border-radius50 border-radius100 mb-4 mb-lg-0" />
                                                              </div>
                                                            </div>

                                                            <div class="col-9 col-md-12 col-lg-9 pl-0 pl-md-3 pl-lg-0">
                                                              <div class="std-purchase-card-details">
                                                                <h5 class="title black-color mb-0">  {items.service_name}  </h5>
                                                                <p class="brand-color3 font-12 my-2">Booking For:  {items.Name}  </p>
                                                                <p class="brand-color3 font-12 my-2">Amount:  {businessDetail.currency}  {items.amount}  </p>
                                                                <p class="brand-color3 font-12 my-2">Purchase Date:  {items.purchase_date_new}  </p>
                                                                <p class="brand-color3 font-12 my-2">Booking Time:  {items.start_time_new} - {items.end_time_new} </p>
                                                                <p class="brand-color3 font-12 my-2">Duration: {items.duration}  Min.</p>
                                                                <p class="brand-color3 font-12 my-2">Location:  {items.location_name}  ,  {items.location_address}  </p>

                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    }
                                                    {items.pass_id &&
                                                      <div class="std-purchase-bottom" >
                                                        <a href="Javascript:void(0);"
                                                          onClick={() => redirectUrl(items.pass_id, items.user_booking_id, '/pass-detail', items.pass_status_text)}
                                                        >
                                                          <div class="purchase-blue-card p-3 p-sm-4">
                                                            <div class="row mx-0">
                                                              <div class="col-md-3">
                                                                <div className="pass-logo">

                                                                  <img src={items.business_logo} class="img-fluid" />
                                                                </div>
                                                              </div>
                                                              <div class="col-md-9">
                                                                <div class="flex-fill pr-4 pl-5 passTextwidth">
                                                                  <h5 class="white-color font-14 mb-2 mt-0 "> {items.pass_name} </h5>
                                                                  <h5 class="white-color font-14 mb-3 mt-0 text-capitalize">Member:  {userDetail.name}   {userDetail.lastname} </h5>
                                                                  <h5 class="white-color font-14 mb-2 mt-0 "> {businessDetail.currency}  {items.amount}  <span class="font-12"> Plus Tax </span></h5>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div class="row mt-3 align-items-end">

                                                              <div class="col-6 text-left pr-0" >
                                                                {items.pass_type_category == 'PunchCard' &&
                                                                  <p class="mb-2 white-color paratitle"> Remaining Classes :  {items.remaining_count}  of  {items.total_count}  </p>}
                                                                {(items.pass_type_category == 'TimeFrame' || items.pass_type_category == 'Other' || items.pass_type_category == 'Recurring Membership') &&
                                                                  <p class="mb-2 white-color paratitle" > Remaining Days :  {items.remaining_count}  of  {items.total_count} </p>}
                                                                <p class="mb-2 white-color paratitle"> Start Date :  {items.start_date_new}  </p>
                                                                {items.pass_mark == 'no expiration' &&
                                                                  <p class="mb-2 white-color paratitle">No Expiration </p>}
                                                                {items.pass_mark == '' &&
                                                                  <p class="mb-2 white-color paratitle"> Expiration Date :  {items.end_date_new} </p>}

                                                              </div>
                                                              <div class="col-6 text-right pl-0">
                                                                <p class="mb-0 white-color paratitle">  {items.pass_status_text} </p>

                                                                <div class="">
                                                                </div></div>
                                                            </div>
                                                          </div>
                                                        </a>
                                                        <div class="studio-card-select">
                                                          {items.favourite == 0 &&
                                                            <i class="fas fa-heart brand-color3 font-22" onClick={() => addFav(items.pass_id, 1)} ></i>}
                                                          {items.favourite == 1 &&
                                                            <i class="fas fa-heart brand-color2 font-22" onClick={() => addFav(items.pass_id, 0)}></i>}
                                                        </div>
                                                      </div>
                                                    }
                                                    {items.product_id &&
                                                      <div>
                                                        <a href="Javascript:void(0);" onClick={() => redirectUrl1(items.product_id, '/product')}>
                                                          <div class="white-bg border-radius6 box-shadow1 p-4">
                                                            <div class="row" style={{ height: "194px" }}>
                                                              <div class="col-4 pr-0">
                                                                <div class="border-radius6 box-shadow1 p-3">
                                                                  <img src={items.product_images[0].image_name} class="img-fluid w-10" />
                                                                </div>
                                                              </div>
                                                              <div class="col-8">
                                                                <div class="">
                                                                  <h5 class="title black-color mb-0">  {items.product_name}  </h5>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div class="row">
                                                              <div class="col-6 text-left pr-0">
                                                                <p class="brand-color3 font-12 my-2" >Quantity :  {items.quantity} </p>
                                                              </div>
                                                              <div class="col-6 text-right pl-0">
                                                                <p class="brand-color3 font-12 my-2">Amount :  {businessDetail.currency}  {items.product_price}  </p>
                                                              </div>
                                                              <div class="col-6 text-left pr-0">
                                                                <p class="brand-color3 font-12 my-2" >Purchase Date :  {items.purchase_date_new} </p>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </a>
                                                      </div>
                                                    }
                                                  </div>
                                                ))}
                                              </div>
                                            }
                                          </div>
                                          {purchaseDetail.length == '0' &&
                                            <div class="row">
                                              <div class="col-4">
                                              </div>
                                              <div class="col-4">
                                                <div class="text-center">
                                                  <p class="font-awesome black-color font-18 d-inline mx-2 "> No Item Purchased</p>
                                                </div>
                                              </div>
                                              <div class="col-4">
                                              </div>
                                            </div>
                                          }
                                        </div>
                                      }


                                      {/* Services */}

                                      {stage == 3 &&
                                        <div class="tab-pane pt-0 pt-md-4 mt-2 mb-3" id="services" style={{
                                          display:'block'
                                        }}>
                                          <div class="std-detail-purchase pt-0 pt-md-4 mt-2 mb-3">
                                            <div
                                            // infinite-scroll  [infiniteScrollDistance]="scrollDistance"  [infiniteScrollUpDistance]="scrollUpDistance"  [infiniteScrollThrottle]="throttle"  (scrolled)="onScroll()" alwaysCallback="true"
                                            >
                                              {serviceDetail.length != 0 &&
                                                <div class="row">
                                                  {serviceDetail.map((serv) => (
                                                    <div class="col-12 col-md-4 mt-3 mb-2" >
                                                      <div class="std-purchase-card box-shadow1 border-radius6 white-bg px-2 py-3">
                                                        <div class="row ">
                                                          <div class="col-3 col-md-12 col-lg-3">
                                                            <div class="text-center">
                                                              <img src={businessDetail.logo} class="box-shadow1 border-radius50 border-radius100 mb-4 mb-lg-0" />
                                                            </div>
                                                          </div>
                                                          <div class="col-9 col-md-12 col-lg-9 pl-0 pl-md-3 pl-lg-0">
                                                            <div class="std-purchase-card-details">
                                                              <h5 class="title black-color mb-0">  {serv.service_name}  </h5>
                                                              <p class="brand-color3 font-12 my-2">Booking For:  {serv.Name} </p>
                                                              <p class="brand-color3 font-12 my-2">Amount:  {businessDetail.currency}  {serv.amount}  </p>
                                                              <p class="brand-color3 font-12 my-2">Purchase Date:  {serv.purchase_date_new} </p>
                                                              <p class="brand-color3 font-12 my-2">Booking Time:  {serv.start_time_new} -  {serv.end_time_new}</p>
                                                              <p class="brand-color3 font-12 my-2">Duration:  {serv.duration}  Min.</p>
                                                              <p class="brand-color3 font-12 my-2">Location:  {serv.location_name}  </p>                                                </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ))}
                                                </div>
                                              }
                                            </div>
                                            {serviceDetail.length == 0 &&
                                              <div class="row">
                                                <div class="col-4">
                                                </div>
                                                <div class="col-4">
                                                  <div class="text-center">
                                                    <p class="font-awesome black-color font-18 d-inline mx-2 "> No Service Purchased</p>
                                                  </div>
                                                </div>
                                                <div class="col-4">
                                                </div>
                                              </div>
                                            }
                                          </div>
                                        </div>
                                      }

                                      {/* Passess */}
                                      {stage == 1 &&
                                      <>
                                        <div class="tab-pane pt-0 pt-md-4 mt-2 mb-3" id="passes" style={{
                                          display:'block'
                                        }}>
                                          <div
                                            //  infinite-scroll  [infiniteScrollDistance]="scrollDistance"  [infiniteScrollUpDistance]="scrollUpDistance"  [infiniteScrollThrottle]="throttle"  (scrolled)="onScroll()"
                                            // alwaysCallback="true"
                                          >
                                            {activePassDetail.length != 0 &&
                                              <div class="row" >
                                                <div class="col-12 std-purchase-bottom mt-4">
                                                  <p class="black-color font-w-100 text-uppercase font-20"> Active Passes </p>
                                                </div>
                                                {activePassDetail.map((pas) => (
                                                  <div class="col-12 col-md-6 std-purchase-bottom mt-4" >
                                                    <a href="Javascript:void(0);" onClick={() => redirectUrl(pas.pass_id, pas.user_booking_id, '/pass-detail', pas.pass_status_text)}>
                                                      <div class="purchase-blue-card p-3 p-sm-4">
                                                        <div class="row mx-0 ">
                                                          <div class="col-4 pass-logo">
                                                            <img src={pas.business_logo} class="img-fluid" />
                                                          </div>
                                                          <div class="col-8 flex-fill pr-4 pl-5 passTextwidth">
                                                            <h5 class="white-color font-14 mb-2 mt-0 "> {pas.pass_name} </h5>
                                                            <h5 class="white-color font-14 mb-3 mt-0 text-capitalize">Member:  {userDetail.name}   {userDetail.lastname}</h5>
                                                            <h5 class="white-color font-14 mb-2 mt-0 "> {businessDetail.currency}  {pas.amount}   <span class="font-12"> Plus Tax </span></h5>
                                                          </div>
                                                        </div>
                                                        <div class="row mt-3 align-items-end">
                                                          <div class="col-6 text-left pr-0" >
                                                            {pas.pass_type_category == 'PunchCard' &&
                                                              <p class="mb-0 white-color paratitle" > Remaining Classes :  {pas.remaining_count}  of  {pas.total_count} </p>}
                                                            {(pas.pass_type_category == 'TimeFrame' || pas.pass_type_category == 'Other' || pas.pass_type_category == 'Recurring Membership') &&
                                                              <p class="mb-0 white-color paratitle"> Remaining Days :  {pas.remaining_count}  of  {pas.total_count}</p>}
                                                            <p class="mb-0 white-color paratitle"> Start Date :  {pas.start_date_new} </p>
                                                            {pas.pass_mark == 'no expiration' &&
                                                              <p class="mb-0 white-color paratitle">No Expiration </p>}
                                                            {pas.pass_mark == '' &&
                                                              <p class="mb-0 white-color paratitle" > Expiration Date :  {pas.end_date_new} </p>}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </a>
                                                    <div class="studio-card-select">
                                                      {pas.favourite == 0 &&
                                                        <i class="fas fa-heart brand-color3 font-22" onClick={() => addFav(pas.pass_id, 1)}></i>}
                                                      {pas.favourite == 1 &&
                                                        <i class="fas fa-heart brand-color2 font-22" onClick={() => addFav(pas.pass_id, 0)} ></i>}
                                                    </div>
                                                  </div>
                                                ))}
                                                <div class="col-12 std-purchase-bottom mt-4">
                                                  <p class="black-color font-w-100 text-uppercase font-20"> Expired Passes </p>
                                                </div>
                                                {expirePassDetail.map((pas) => (
                                                  <div class="col-12 col-md-6 std-purchase-bottom mt-4"  >
                                                    <a href="Javascript:void(0);" onClick={() => redirectUrl(pas.pass_id, pas.user_booking_id, '/pass-detail', pas.pass_status_text)}>
                                                      <div class="purchase-blue-card p-3 p-sm-4">
                                                        <div class="row mx-0 ">
                                                          <div class="pass-logo">
                                                            <img src={pas.business_logo} class="img-fluid" />
                                                          </div>
                                                          <div class="flex-fill pr-4 pl-5 passTextwidth">
                                                            <h5 class="white-color font-14 mb-2 mt-0 ">{pas.pass_name} </h5>
                                                            <h5 class="white-color font-14 mb-3 mt-0 text-capitalize">Member:  {userDetail.name}   {userDetail.lastname}</h5>
                                                            <h5 class="white-color font-14 mb-2 mt-0 ">{businessDetail.currency}  {pas.amount}   <span class="font-12"> Plus Tax </span></h5>
                                                          </div>
                                                        </div>
                                                        <div class="row mt-3 align-items-end">
                                                          <div class="col-6 text-left pr-0" >
                                                            {pas.pass_type_category == 'PunchCard' &&
                                                              <p class="mb-0 white-color paratitle" > Remaining Classes : {pas.remaining_count}  of  {pas.total_count} </p>}
                                                            {(pas.pass_type_category == 'TimeFrame' || pas.pass_type_category == 'Other' || pas.pass_type_category == 'Recurring Membership') &&
                                                              <p class="mb-0 white-color paratitle" > Remaining Days : {pas.remaining_count}  of  {pas.total_count}</p>}
                                                            <p class="mb-0 white-color paratitle"> Start Date :  {pas.start_date_new}  </p>
                                                            {pas.pass_mark == 'no expiration' &&
                                                              <p class="mb-0 white-color paratitle" >No Expiration </p>}
                                                            {pas.pass_mark == '' &&
                                                              <p class="mb-0 white-color paratitle" > Expiration Date :  {pas.end_date_new}  </p>}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </a>
                                                    <div class="studio-card-select">
                                                      {pas.favourite == 0 &&
                                                        <i class="fas fa-heart brand-color3 font-22" onClick={() => addFav(pas.pass_id, 1)}
                                                        ></i>}
                                                      {pas.favourite == 1 &&
                                                        <i class="fas fa-heart brand-color2 font-22" onClick={() => addFav(pas.pass_id, 0)}
                                                        ></i>}

                                                    </div>
                                                  </div>
                                                ))}
                                              </div>
                                            }
                                          </div>
                                          {passDetail.length == '0' &&
                                            <div class="row">
                                              <div class="col-4">
                                              </div>
                                              <div class="col-4">
                                                <div class="text-center">
                                                  <p class="font-awesome black-color font-18 d-inline mx-2 "> No Pass Purchased</p>
                                                </div>
                                              </div>
                                              <div class="col-4">
                                              </div>
                                            </div>
                                          }
                                        </div>
                                        </>

                                      }

                                      {/*  Products */}
                                      {stage == 2 &&
                                        <div class="tab-pane  pt-0 pt-md-4 mt-2 mb-3 " id="products" style={{ display:'block' }}>
                                          <div
                                            // infinite-scroll  [infiniteScrollDistance]="scrollDistance"  [infiniteScrollUpDistance]="scrollUpDistance"  [infiniteScrollThrottle]="throttle"  (scrolled)="onScroll()" 
                                            // alwaysCallback="true"
                                            >
                                            {productDetail.length != 0 &&
                                              <div class="row">
                                                {productDetail.map((prod) => (
                                                  <div class="col-12 col-md-6 mb-4">
                                                    <a href="Javascript:void(0);" onClick={() => redirectUrl1(prod.product_id, '/product')}>
                                                      <div class="white-bg border-radius6 box-shadow1 p-4" style={{ minHeight: "245px" }}>
                                                        <div class="row">
                                                          <div class="col-4 pr-0">
                                                            <div class="border-radius6 box-shadow1 p-3">
                                                              <img src={prod.product_images[0].image_name} class="img-fluid w-10" />
                                                            </div>

                                                          </div>
                                                          <div class="col-8">
                                                            <div class="">
                                                              <h5 class="title black-color mb-0">  {prod.product_name}  </h5>
                                                              <div class="row">
                                                                <div class="col-6 text-left pr-0">
                                                                  <p class="brand-color3 font-12 my-2" >Quantity :  {prod.quantity}</p>

                                                                </div>
                                                                <div class="col-6 text-right pl-0">
                                                                  <p class="brand-color3 font-12 my-2">Amount : {businessDetail.currency}  {prod.product_price} </p>

                                                                </div>
                                                                <div class="col-6 text-left pr-0">
                                                                  <p class="brand-color3 font-12 my-2">Purchase Date :  {prod.purchase_date_new}  </p>

                                                                </div>
                                                              </div>

                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </a>
                                                  </div>
                                                ))}
                                              </div>
                                            }
                                          </div>
                                          {productDetail.length == 0 &&
                                            <div class="row">
                                              <div class="col-4">
                                              </div>
                                              <div class="col-4">
                                                <div class="text-center">
                                                  <p class="font-awesome black-color font-18 d-inline mx-2 "> No Product Purchased</p>
                                                </div>
                                              </div>
                                              <div class="col-4">
                                              </div>
                                            </div>
                                          }
                                        </div>
                                      }

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Innerfooter />

    </>
  )
}
