import React from "react";
import { SharedSelect } from "../../../../../sharedComponents/SharedSelect";
import { MyDatePicker } from "../../../../../sharedComponents/MyDatePicker";
import { InputField } from "../../../../../sharedComponents/InputField";
import { TextArea } from "../../../../../sharedComponents/TextArea";
import { SharedButton } from "../../../../../sharedComponents/Button";
import { Container, Row, Col, Form } from "react-bootstrap";
import ShiftStartTime from "../../../../../sharedComponents/ShiftStartTime";
import { useEffect } from "react";
import { InstructorList, add_shift, get_room_location, get_weekdays } from "../../../../../Studio-Services/Studio_Services";
import { useState } from "react";
import moment from "moment";
import { Date } from "../schedule_new_class/Date";
import Swal from "sweetalert2";
import logo from "../../../../../Images/logo_white_web.png"


export const Addshift = ({loders}) => {
  const [listInt, setListInt] = useState([]);
  const [locationOp, setLocationOp] = useState([]);

  const [startDate, setStartDate] = useState();
  const [wekList, setWekList] = useState([]);
  const [selectedDayId, setSelectedDayId] = useState();
  const [selectedDay, setSelectedDay] = useState();



  const getWeekdays = async () => {
    const resp = await get_weekdays();
    if (resp) {
      setWekList(resp.data.data);
    }
  };



  const getInstructor = async () => {
    const data = {
      "pageid": 1
    }
    const resp = await InstructorList(data);
    if (resp) {
      let op = [];
      (resp.data.data).forEach(e => {
        if (e.status === "Approve" && e.business_status === "Active") {
          op.push({
            value: e.user_id, label: `${e.name} ${e.lastname}`
          })
        }
      });
      setListInt(op);
    }
  }


  const getRoomLocation = async () => {
    const data = {
      "business_id": localStorage.getItem("business_id")
    }
    const resp = await get_room_location(data);
    if (resp) {
      const lo = [];
      (resp.data.data).map((e) => {
        lo.push({
          value: e.location_id, label: e.location_name
        })
      })
      setLocationOp(lo);
    }
  }


  useEffect(() => {
    getInstructor();
    getRoomLocation();
    getWeekdays();
  }, [])

  const DateChange = (e) => {
    setError((pre) => ({ ...pre, start_date: "" }));

  //  const formattedString = moment(e.target.value).format('ddd MMM DD YYYY HH:mm:ss');
   const formattedString = moment(e.target.value).format('ddd MMM DD YYYY HH:mm:ss');


    setStartDate(formattedString);
    
    const selectedDay = moment(formattedString).format("dddd");
  
    wekList.forEach((e) => {
      if (e.week_name === selectedDay) {
        setSelectedDay(e.week_name);
        setSelectedDayId(e.id);
      }
    });
  };


  // const DateChange = (e) => {
  //   setError((pre) => ({ ...pre, start_date: "" }));
  //   setStartDate(e);
  //   const selectedDay = moment(e, "ddd MMM DD YYYY HH:mm:ss ZZ").format("dddd");
  //   wekList.forEach((e) => {
  //     if (e.week_name === selectedDay) {
  //       setSelectedDay(e.week_name);
  //       setSelectedDayId(e.id);
  //     }
  //   });
  // };


  const startH = [
    { value: "01", label: <>01</> },
    { value: "02", label: <>02</> },
    { value: "03", label: <>03</> },
    { value: "04", label: <>04</> },
    { value: "05", label: <>05</> },
    { value: "06", label: <>06</> },
    { value: "07", label: <>07</> },
    { value: "08", label: <>08</> },
    { value: "09", label: <>09</> },
    { value: "10", label: <>10</> },
    { value: "11", label: <>11</> },
    { value: "12", label: <>12</> },
  ]
  const startM = [
    { value: "00", label: <>00 </> },
    { value: "05", label: <>05 </> },
    { value: "10", label: <>10 </> },
    { value: "15", label: <>15 </> },
    { value: "20", label: <>20 </> },
    { value: "25", label: <>25 </> },
    { value: "30", label: <>30 </> },
    { value: "35", label: <>35 </> },
    { value: "40", label: <>40 </> },
    { value: "45", label: <>45 </> },
    { value: "50", label: <>50 </> },
    { value: "55", label: <>55 </> },
  ]
  const startAM = [
    { value: "AM", label: <>AM</> },
    { value: "PM", label: <>PM</> },
  ]

  const payTypeOp = [
    { value: "Hourly", label: <>Hourly </> },
    { value: "Per Service", label: <>Per Service </> },
    { value: "Flat Rate", label: <>Flat Rate </> }
  ]

  const shiftOp = [
    { value: "1", label: <>Service Shift </> },
    { value: "2", label: <>Non-Service Shift </> },
  ]



  const [error, setError] = useState({
    shift_name: "",
    start_date: "",
    sHours: "",
    sMinuts: "",
    sAMPM: "",
    eHours: "",
    eMinuts: "",
    eAMPM: "",
    instructor: "",
    repeat: "",
    duration: "",
    description: "",
    pay_type: "",
    pay_rate: "",
    location: ""
  });

  const errorHandler = (e) => {
    const { name } = e.target;
    setError((pre) => ({ ...pre, [name]: "" }))
  }

  const submitHandler = async (e) => {
    e.preventDefault();
    const fData = new FormData(e.target);
    const fValue = Object.fromEntries(fData.entries());
    let isValid = 1;

    if (!fValue.sHours) { setError((pre) => ({ ...pre, sHours: "* Required" })); isValid = 2; }
    if (!fValue.sMinuts) { setError((pre) => ({ ...pre, sMinuts: "* Required" })); isValid = 3; }
    if (!fValue.sAMPM) { setError((pre) => ({ ...pre, sAMPM: "* Required" })); isValid = 4; }
    if (!fValue.eHours) { setError((pre) => ({ ...pre, eHours: "* Required" })); isValid = 5; }
    if (!fValue.eMinuts) { setError((pre) => ({ ...pre, eMinuts: "* Required" })); isValid = 6; }
    if (!fValue.eAMPM) { setError((pre) => ({ ...pre, eAMPM: "* Required" })); isValid = 7; }

    const ftime = `${fValue.sHours} : ${fValue.sMinuts} : ${fValue.sAMPM}`;
    const ttime = `${fValue.eHours} : ${fValue.eMinuts} : ${fValue.eAMPM}`;

    const classStartTime = moment(ftime, "hh:mm A").unix();
    const classEndTime = moment(ttime, "hh:mm A").unix();

    const moment1 = moment.unix(classStartTime);
    const moment2 = moment.unix(classEndTime);

    const durations = moment2.diff(moment1, 'minutes');

    const finalOnlyStartTime = `${fValue.sHours} : ${fValue.sMinuts} : 00 ${fValue.sAMPM}`;
    const finalOnlyEndTime = `${fValue.eHours} : ${fValue.eMinuts} : 00 ${fValue.eAMPM}`;
   

    const shift_name = fValue.shift_name;
    const day_id = selectedDayId;
    const start_date = startDate;
    const start_time = classStartTime;
    const end_time = classEndTime;
    const instructor = fValue.instructor;
    const repeat = fValue.repeat || 0;
    const duration = durations;
    const description = fValue.description;
    const pay_type = fValue.pay_type;
    const pay_rate = fValue.pay_rate;
    const location = fValue.location;


    if (!shift_name) { setError((pre) => ({ ...pre, shift_name: "* Required" })); isValid = 7; }
    if (!start_date) { setError((pre) => ({ ...pre, start_date: "* Required" })); isValid = 8; }
    if (!instructor) { setError((pre) => ({ ...pre, instructor: "* Required" })); isValid = 9; }
    if (!description) { setError((pre) => ({ ...pre, description: "* Required" })); isValid = 11; }
    if (!pay_type) { setError((pre) => ({ ...pre, pay_type: "* Required" })); isValid = 12; }
    if (!pay_rate) { setError((pre) => ({ ...pre, pay_rate: "* Required" })); isValid = 13; }
    if (!location) { setError((pre) => ({ ...pre, location: "* Required" })); isValid = 14; }

    let finalRepet =0;
    if(repeat != 0){finalRepet= parseInt(repeat)-1; }


    if (isValid === 1) {
      loders(true);
      const fData = {
        shift_name: fValue.shift_name,
        day_id: selectedDayId,
        start_date: moment(startDate, "ddd MMM DD YYYY HH:mm:ss").unix(),
        start_time: moment(moment(startDate).format("YYYY-MM-DD")+" "+finalOnlyStartTime, "YYYY-MM-DD hh:mm:ss A").unix(),
        end_time: moment(moment(startDate).format("YYYY-MM-DD")+" "+finalOnlyEndTime, "YYYY-MM-DD hh:mm:ss A").unix(),
        instructor: fValue.instructor,
        repeat: finalRepet,
        duration: durations,
        description: fValue.description,
        pay_type: fValue.pay_type,
        pay_rate: fValue.pay_rate,
        location: fValue.location,
      }
     
      const resp = await add_shift(fData);
      if (resp) {
        loders(false);
        // Reset the form after processing
        e.target.reset();
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
                Success
          </h4>
          <p class="text-center my-4 textcolor">
          <small class="textWhite">
          ${resp.data.message} </small></p>
          `,
          showClass: {
            popup: "custom-dialog-container",
          },
          customClass: {
            confirmButton: "custom-button custom-button-size modalbtn",
          },
        });
      }else{
        loders(false);
      }
    }
  }
  return (
    <>

      <div className="innercontent">
        <h4>ADD SHIFTS</h4>
        <Container>
          <Form onSubmit={submitHandler}>
            <Row className="mt-4">
              <Col lg={4} sm={12}>
                <SharedSelect
                  options={shiftOp}
                  name={"shift_name"}
                  error={error.shift_name}
                  onChange={errorHandler}
                  lable={"Service Shift"}
                />
              </Col>
              <Col lg={4} sm={12}>
                <InputField
                  type={"date"}
                  name="start_date"
                  onChange={(e) => DateChange(e)}
                  error={error.start_date}
                  lable={"Start Date"}
                />
                {/* <Date
                  selected={startDate}
                  name="start_date"
                  error={error.start_date}
                  onChange={(e) => DateChange(e)}
                  DatepickerLabel={"Start Date"}
                /> */}

              </Col>
              <Col lg={4} sm={12}>
                <InputField
                  lable={"Days"}
                  value={selectedDay}
                  readOnly
                  type={"test"} />
              </Col>
            </Row>
            <Row>
              <Col lg={4} sm={12}>
                <InputField
                  name={"repeat"}
                  minLength={"1"}
                  lable={"Number of Weeks Shift will repeat"}
                  type={"number"}
                  error={error.repeat}
                  onChange={errorHandler}
                />
              </Col>
              <Col lg={4} sm={12}>
                <Row>
                  <label className="form-label inputLable">Start Time</label>
                  <Col>
                    <SharedSelect
                      name={"sHours"}
                      options={startH}
                      error={error.sHours}
                      onChange={errorHandler}
                    // lable="Start Time"
                    />
                  </Col>
                  <Col>
                    <SharedSelect
                      name={"sMinuts"}
                      options={startM}
                      error={error.sMinuts}
                      onChange={errorHandler}
                    // lable={"Minutes"}
                    />
                  </Col>
                  <Col>
                    <SharedSelect
                      name={"sAMPM"}
                      options={startAM}
                      error={error.sAMPM}
                      onChange={errorHandler}

                    />
                  </Col>
                </Row>
              </Col>
              <Col lg={4} sm={12}>
                <Row>
                  <label className="form-label inputLable">End Time</label>
                  <Col>
                    <SharedSelect
                      name={"eHours"}
                      options={startH}
                      error={error.eHours}
                      onChange={errorHandler}
                    // lable="Start Time"
                    />
                  </Col>
                  <Col>
                    <SharedSelect
                      name={"eMinuts"}
                      options={startM}
                      error={error.eMinuts}
                      onChange={errorHandler}
                    // lable={"Minutes"}
                    />
                  </Col>
                  <Col>
                    <SharedSelect
                      name={"eAMPM"}
                      options={startAM}
                      error={error.eAMPM}
                      onChange={errorHandler}
                    // lable={"AM/PM"}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col lg={4} sm={12}>
                <SharedSelect
                  options={listInt}
                  name={"instructor"}
                  error={error.instructor}
                  onChange={errorHandler}
                  lable={"Select Instructor"} />
              </Col>
              <Col lg={4} sm={12}>
                <SharedSelect

                  options={locationOp}
                  name={"location"}
                  error={error.location}
                  onChange={errorHandler}
                  lable={"Select Room Location"} />
              </Col>
              <Col lg={4} sm={12}>
                <SharedSelect
                  name={"pay_type"}
                  options={payTypeOp}
                  error={error.pay_type}
                  onChange={errorHandler}
                  lable={"Pay Type"} />
              </Col>
            </Row>
            <Row>
              <Col lg={4} sm={12}>
                <InputField
                  name={"pay_rate"}
                  minLength={"0"}
                  lable={"Pay Rate"}
                  type={"number"}
                  error={error.pay_rate}
                  onChange={errorHandler}
                  placeholder={"$ 10"}
                />
              </Col>
              <Col lg={4} sm={12}></Col>
              <Col lg={4} sm={12}></Col>
            </Row>
            <Row>
              <Col lg={12} sm={12}>
                <TextArea
                  name={"description"}
                  lable={"Description"}
                  error={error.description}
                  onChange={errorHandler}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={4}>
                <SharedButton title={"SAVE"} type={"submit"} className={"btn btn-primary w-100"} />
              </Col>
              <Col lg={4}>
                <SharedButton title={"BACK"} type={"button"} href={"/list-shifts"} className={"btn btn-outline w-100"} />

              </Col>
              <Col lg={4}></Col>
            </Row>
          </Form>
        </Container>
      </div>
    </>
  );
};
