import React from 'react'
import { Container, Row, Col, Spinner } from 'react-bootstrap'
import Sidebar from '../../comman_page/Sidebar'
import { HeaderDashboard } from '../../comman_page/HeaderDashboard'
import { DataTable } from '../../../../../sharedComponents/DataTable'
import { SharedSelect } from '../../../../../sharedComponents/SharedSelect'
import { SharedButton } from '../../../../../sharedComponents/Button'
import MultiSelect from '../../../../../sharedComponents/MultiSelect'
import { transectionList, transectionList_new } from '../../../../../Studio-Services/Studio_Services'
import { useState } from 'react'
import { useEffect } from 'react'
import { TrTable } from './TrTable'
import moment from 'moment'
import BootstrapTable from '../../../../../sharedComponents/BootstrapTable'
import Pagination from '../../../../../sharedComponents/Pagination'
import { TrDateRange } from '../../../../../sharedComponents/TrDateRange'
import { errorAlert } from '../../../../../Static_Services/Alertmsg'
import { Footer } from '../../../../../public/containers/footer/Footer'
import { finalAMT, taxCalculator } from '../../../../../sharedComponents/MyHelperFunction'
import RefundBotstrapTable from '../../../../../sharedComponents/RefundBotstrapTable'

export const TransactionReport = () => {
  // ${parseFloat().toFixed(2)
  const [trData, setTrData] = useState([]);
  const [totalDetail, setTotalDetail] = useState();
  const [loder, setLoder] = useState(false);
  const [refund, setRefund] = useState(false);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [selDay, setSelDay] = useState('');
  const [fulldata, setFulldata] = useState();
  const [fullrefunddata, setFullrefunddata] = useState();

  const [totpage, setTotpage] = useState(0);
  const [cpage, setCpage] = useState(1);

  const [myvaluedate, setMyvaluedate] = useState('');

  const [selpaymethod, setSelpaymethod] = useState([]);

  const [myrefund, setMyrefund] = useState([]);



  const beforTaxCalculator = (total, tax1, tax2) => {
    return parseFloat((parseFloat(total) - tax1 - tax2)).toFixed(2);
  }

  const filtercardData = (data, name) => {
    let lb = 0; let lt1 = 0; let lt2 = 0; let tt = 0;
    let count = 0;
    data.forEach((e, index) => {
      count += 1;
      let ttx1 = 0;
      let ttx2 = 0;
      let fnl = 0;
      let tt_text = 0;
      let b_fore_tax = 0;
      let amt = 0;

      if (e.passes_total_count == "3650") {
        ttx1 = taxCalculator(e.amount, e.tax1_rate);
        ttx2 = taxCalculator(e.amount, e.tax2_rate);
        fnl = finalAMT(e.amount);
        tt_text = parseFloat(ttx1) + parseFloat(ttx2);

        b_fore_tax = parseFloat(e.amount);//- parseFloat(tt_text);
        amt = parseFloat(e.amount) + parseFloat(tt_text);
        lb += parseFloat(b_fore_tax);
        lt1 += ttx1;
        lt2 += ttx2;
        tt += amt;

      } else if (e.service_type == 3) {
        ttx1 = parseFloat(e.tax1_rate);
        ttx2 = parseFloat(e.tax2_rate);
        fnl = finalAMT(e.amount);
        tt_text = parseFloat(ttx1) + parseFloat(ttx2);

        b_fore_tax = parseFloat(e.amount) - parseFloat(tt_text);
        amt = parseFloat(e.amount);
        lb += parseFloat(b_fore_tax);
        lt1 += ttx1;
        lt2 += ttx2;
        tt += amt;
      } else if (e.service_type == 1) {
        ttx1 = parseFloat(e.tax1_rate);
        ttx2 = parseFloat(e.tax2_rate);
        fnl = finalAMT(e.sub_total);
        tt_text = parseFloat(ttx1) + parseFloat(ttx2);
        b_fore_tax = parseFloat(e.sub_total)
        //  - parseFloat(tt_text);
        amt = parseFloat(e.sub_total) + parseFloat(tt_text);
        lb += parseFloat(b_fore_tax);
        lt1 += ttx1;
        lt2 += ttx2;
        tt += amt;
      } else {
        ttx1 = taxCalculator(e.sub_total, e.tax1_rate);
        ttx2 = taxCalculator(e.sub_total, e.tax2_rate);
        fnl = finalAMT(e.sub_total);
        tt_text = parseFloat(ttx1) + parseFloat(ttx2);
        b_fore_tax = parseFloat(e.sub_total)
        //  - parseFloat(tt_text);
        amt = parseFloat(e.sub_total) + parseFloat(tt_text);
        lb += parseFloat(b_fore_tax);
        lt1 += ttx1;
        lt2 += ttx2;
        tt += amt;
      }
    })


    return { summary: name, transaction: count, before_tax: (lb).toFixed(2), tax_one: (lt1).toFixed(2), tax_two: (lt2).toFixed(2), total: (tt).toFixed(2), };
  }



  const getReport = async (day = '', slsd = '', sled = '', mode = '', pg) => {
    setMyrefund([]);
    // setRefund(false);

    let pymod = []
    if (selpaymethod && selpaymethod.length > 0) { selpaymethod.forEach((data) => { pymod.push(data.value); }) }

    const crt = pymod.find((e) => e === "credit");
    if (crt) {
      pymod.push('visa', 'mastercard', 'amEx', "mc");
    }

    let today = '';
    let start = '';
    let end = '';
    let page = 1;
    if (pg) { page = pg; }
    setLoder(true);
    if (day && day === 'today') { setStartDate(''); setEndDate(''); start = ''; end = ''; today = moment().format('YYYY-MM-DD'); }

    if (day && day === 'yesterday') { setStartDate(''); setEndDate(''); start = ''; end = ''; today = moment().subtract(1, 'day').format('YYYY-MM-DD'); }

    if (day && day === 'last7') { setStartDate(''); setEndDate(''); start = moment().subtract(6, 'days').startOf('day'); end = moment().endOf('day'); today = ''; }

    if (day && day === 'lastweek') {
      start = moment().subtract(1, 'week').startOf('isoWeek').format('YYYY-MM-DD');
      end = moment().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD');
      today = '';
      setStartDate(''); setEndDate('');
    }

    if (day && day === 'current_month') { setStartDate(''); setEndDate(''); start = moment().startOf('month').format('YYYY-MM-DD'); end = moment().endOf('month').format('YYYY-MM-DD'); today = '' }
    if (day && day === 'last_month') { setStartDate(''); setEndDate(''); start = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'); end = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'); today = '' }
    if (!day && slsd && sled) { start = slsd; end = sled; today = ''; setSelDay(''); }


    const newpaymode = pymod.filter((vi) => vi.replace(/\s/g, '').toLowerCase() != 'refunded');

    const rfpaymode = pymod.filter((vi) => vi.replace(/\s/g, '').toLowerCase() === 'refunded');
    let setrefundstatus = '';

    if (rfpaymode && rfpaymode.length > 0) {
      setrefundstatus = 1;
      setRefund(true);
    }

    const fdata = { "page_no": page, 'todayDate': today, 'startDate': start, 'endDate': end, "paymode": newpaymode, "refund": setrefundstatus }


    const resp = await transectionList_new(fdata);
    if (resp) {
      let array = [];
      let refundArray = []
      let pre = resp.data;
      const tabledata = ({ 'td': pre, 'paymode': pymod });

      setFulldata(tabledata);


      // pre.forEach(e => {
      //   if (e.responce_all) {
      //     const data = JSON.parse(e.responce_all);
      //  
      //     console.log("=======", data.source.brand);
      //   }
      // });

      // const visa = pre.filter((vi) => vi.payment_mode.replace(/\s/g, '').toLowerCase() === 'visa');
      // const mastercard = pre.filter((vi) => vi.payment_mode.replace(/\s/g, '').toLowerCase() === 'mastercard');
      // const amEx = pre.filter((vi) => vi.payment_mode.replace(/\s/g, '').toLowerCase() === 'amEx');

      // const visa = pre.filter((vi) => vi.payment_mode.replace(/\s/g, '').toLowerCase() === 'visa');
      // const mastercard = pre.filter((vi) => vi.payment_mode.replace(/\s/g, '').toLowerCase() === 'mastercard');
      // const amEx = pre.filter((vi) => vi.payment_mode.replace(/\s/g, '').toLowerCase() === 'amEx');


      // const recurring = pre.filter((vi) => vi.payment_mode.replace(/\s/g, '').toLowerCase() === 'cron');
      const cash = pre.filter((vi) => vi.payment_mode.replace(/\s/g, '').toLowerCase() === 'cash');
      const pos = pre.filter((vi) => vi.payment_mode.replace(/\s/g, '').toLowerCase() === 'pos');
      const pass = pre.filter((vi) => vi.payment_mode.replace(/\s/g, '').toLowerCase() === 'pass');
      const giftcard = pre.filter((vi) => vi.payment_mode.replace(/\s/g, '').toLowerCase() === 'giftcard');
      const check = pre.filter((vi) => vi.payment_mode.replace(/\s/g, '').toLowerCase() === 'check');
      const directdebit = pre.filter((vi) => vi.payment_mode.replace(/\s/g, '').toLowerCase() === 'directdebit');
      const other = pre.filter((vi) => vi.payment_mode.replace(/\s/g, '').toLowerCase() === '' || vi.payment_mode.replace(/\s/g, '').toLowerCase() === 'visa' || vi.payment_mode.replace(/\s/g, '').toLowerCase() === 'mastercard' || vi.payment_mode.replace(/\s/g, '').toLowerCase() === 'amex' || vi.payment_mode.replace(/\s/g, '').toLowerCase() === 'cron' || vi.payment_mode.replace(/\s/g, '').toLowerCase() === 'mc');


      console.log("giftcard-----------------", giftcard);

      const refunded = pre.filter((vi) => vi.refund_status.replace(/\s/g, '').toLowerCase() == '1');

      const fullrefundtabledata = ({ 'td': refunded, 'paymode': pymod });
      setFullrefunddata(fullrefundtabledata);


      // if (visa && visa.length > 0) { const res = filtercardData(visa, 'Visa'); array.push(res); }
      // if (mastercard && mastercard.length > 0) { const res = filtercardData(mastercard, 'Master Card'); array.push(res); };
      // if (amEx && amEx.length > 0) { const res = filtercardData(amEx, 'AmEx'); array.push(res); };
      // if (recurring && recurring.length > 0) { const res = filtercardData(recurring, 'cron'); array.push(res); };

      // console.log("--",pass);




      if (cash && cash.length > 0) { const res = filtercardData(cash, 'Cash'); array.push(res); };
      if (pos && pos.length > 0) { const res = filtercardData(pos, 'POS'); array.push(res); };
      if (pass && pass.length > 0) { const res = filtercardData(pass, 'Pass'); array.push(res); };
      if (giftcard && giftcard.length > 0) { const res = filtercardData(giftcard, 'Gift Card'); array.push(res); };
      if (check && check.length > 0) { const res = filtercardData(check, 'Check'); array.push(res); };
      if (directdebit && directdebit.length > 0) { const res = filtercardData(directdebit, 'Direct Debit'); array.push(res); };
      if (other && other.length > 0) { const res = filtercardData(other, 'Credit/Debit'); array.push(res); };

      if (refunded && refunded.length > 0) { const res = filtercardData(refunded, 'Refunded'); refundArray.push(res); };


      setMyrefund(refundArray);

      let tr = 0;
      let Before_Tax = 0;
      let Tax1 = 0;
      let Tax2 = 0;
      let total = 0;



      if (array && array.length > 0) {
        array.forEach((e) => {
          tr += e.transaction;
          Before_Tax += parseFloat(e.before_tax);
          Tax1 += parseFloat(e.tax_one);
          Tax2 += parseFloat(e.tax_two);
          total += parseFloat(e.total);
        })
      }



      const final = {
        Transection: tr,
        Before_Tax: parseFloat(Before_Tax).toFixed(2),
        total_Tax1: parseFloat(Tax1).toFixed(2),
        total_Tax2: parseFloat(Tax2).toFixed(2),
        finalTotal: parseFloat(total).toFixed(2)
      }
      setTotalDetail(final);
      setTrData(array);
      setLoder(false);
    } else {
      setTrData([]);
      setTotalDetail();
      setMyrefund([]);
      setLoder(false);
    }
    setLoder(false);
  }

  const columnsth = ["Summary", "Transaction", "Before Tax", "Tax 1", "Tax 2", "Total W Tax"];

  const payOption = [
    { value: "credit", label: "Credit/Debit" },
    { value: "cron", label: "Recurring" },
    // { value: "mastercard", label: "MasterCard" },
    // { value: "amEx", label: "AmEx" },
    { value: "cash", label: "Cash" },
    { value: "pos", label: "POS" },
    { value: "pass", label: "Pass" },
    { value: "giftcard", label: "Gift Card" },
    { value: "check", label: "Check" },
    { value: "directdebit", label: "Direct Debit" },
    { value: "refunded", label: "Refunded" },
  ];

  const dayOption = [
    { value: "", label: "Select" },
    { value: "today", label: "Today" },
    { value: "yesterday", label: "yesterday" },
    { value: "last7", label: "Last 7 Days" },
    { value: "lastweek", label: "Last Week" },
    { value: "current_month", label: "Current Month" },
    { value: "last_month", label: "Last Month" },
  ]

  const downloadCSV = async () => {
    setLoder(true);
    if (trData && trData.length > 0) {
      setLoder(false);
      const csvContent = "summary,transaction,before_tax,tax_one,tax_two,total\n" + trData.map((data, index) =>
        `${data.summary},${data.transaction},${data.before_tax},${data.tax_one},${data.tax_two},${data.total}`
      ).join("\n");
      const blob = new Blob([csvContent], { type: 'text/csv' });
      // Create a download link and trigger the download
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = 'Transection_list.csv';
      downloadLink.click();
    } else errorAlert('Please Select Data');
    setLoder(false);
  }


  const handleDayClick = (e) => {
    if (e.target.value) {
      setMyvaluedate(null); setSelpaymethod([]); setStartDate(''); setEndDate(''); setSelDay(e.target.value);
      let value = e.target.value;

      getReport(value, '', "", ""); setRefund(true);
    } else {
      setTrData([]);
      setTotalDetail();

    }
  }


  const runreportHandler = () => {
    if (!startDate || !endDate) { errorAlert("Please Select Date"); return; }
    setSelDay('');
    getReport('', startDate, endDate, selpaymethod,)
  }

  useEffect(() => { if (selDay && selDay != '') { getReport(selDay, '', '', selpaymethod,) } }, [selpaymethod])

  const clearHandler = () => {
    setMyvaluedate('');
    setSelpaymethod([]);
    setSelDay('');
    setTrData([]);
    setTotalDetail();
    setMyrefund([]);

  }

  useEffect(() => {
    if (myvaluedate) {
      setSelDay(''); setTrData([]);
      setTotalDetail();
    }
  }, [myvaluedate])

  return (
    <>
      {loder ? <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div> : ""}
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col>
              <div className="RightSide">
                <HeaderDashboard />
                <section className="addClient innerContent">
                  <div className="AddClientContent">
                    <Container>
                      <h4 className='mb-4'>Transaction Report</h4>

                      <Row>
                        <Col className='d-flex'>
                          <Col md={11}>
                            <SharedSelect lable={"Quick Date Range "} value={selDay} options={dayOption} onChange={handleDayClick} />
                          </Col>
                          <span className='mycls'>Or</span>
                        </Col>
                        <Col>
                          <TrDateRange
                            myvalye={myvaluedate}
                            setMyvalye={setMyvaluedate}
                            label={" Manual Date Range "}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                          />
                        </Col>
                        <Col>
                          <MultiSelect Label={"Payment Method"} options={payOption} setSelectedOptions={setSelpaymethod} selectedOptions={selpaymethod} />
                        </Col>
                      </Row>
                      <Row>
                        <Col className='d-flex justify-content-end' >
                          <button type="button" className="btn btn-success m-1" onClick={runreportHandler}>Run Report</button>
                          <button type="button" className="btn btn-success m-1" onClick={downloadCSV}>Export (CSV)</button>
                          <button type="button" className="btn btn-danger m-1" onClick={clearHandler}>Clear Report </button>
                        </Col>
                      </Row>

                      <Row className="mt-3">
                        <Col>
                          <div className="transactionReport">
                            <BootstrapTable th={columnsth} tr={trData} totalDetail={totalDetail} myrefund={myrefund} />
                          </div>
                        </Col>
                      </Row>
                      {totalDetail &&
                        <>
                          <Row>
                            <Col>
                              <SharedButton title={"View Details"} type={"button"} element={fulldata} href={"/transactiondetailreport"} variant={"secondary"} />
                            </Col>
                            <Col></Col>
                            <Col></Col>
                          </Row>
                        </>
                      }

                      {myrefund && myrefund.length > 0 && refund &&
                        <>
                          <Row className="mt-5">
                            <Row><h3>Refund Data Summary</h3></Row>
                            <Col>
                              <div className="transactionReport">
                                <RefundBotstrapTable th={columnsth} tr={myrefund} />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <SharedButton title={"View Details"} type={"button"} element={fullrefunddata} href={"/transactiondetailreport"} variant={"secondary"} />
                            </Col>
                            <Col></Col>
                            <Col></Col>
                          </Row>
                        </>
                      }
                    </Container>
                  </div>
                </section>
                <Footer />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}
