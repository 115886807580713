import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Modal, Spinner } from "react-bootstrap";
import Sidebar from "../../comman_page/Sidebar";
import { HeaderDashboard } from "../../comman_page/HeaderDashboard";
import { InputField } from "../../../../../sharedComponents/InputField";
import { SharedButton } from "../../../../../sharedComponents/Button";
import { SharedSelect } from "../../../../../sharedComponents/SharedSelect";
import { Footer } from "../../../../../public/containers/footer/Footer";
import { ClientBox } from "../../../../../sharedComponents/ClientBox";
import { useLocation, useNavigate } from "react-router-dom";
import { add_client, get_countries, new_client_signup, search_customer, waiver_customer, } from "../../../../../Studio-Services/Studio_Services";
import { useContext } from "react";
import { MyContext } from "../../../../../App";
import Swal from "sweetalert2";
import logo from "../../../../../Images/logo_white_web.png";
import moment from "moment";
import { ClinetSelect } from "../../../../../sharedComponents/ClinetSelect";

export const Clientlist = () => {
  const context = useContext(MyContext);
  const location = useLocation();
  const [passModal, setPassModal] = useState(false);
  const [passList, setPassList] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [passRadio, setPassRadio] = useState();
  const navigate = useNavigate();
  const [searchedKeyword, setSearchedKeyword] = useState();
  const [classID, setClassID] = useState();
  const [selectedParent, setSelectedParent] = useState();
  const [myscheduled_date, setMyscheduled_date] = useState();
  const [loder, setLoder] = useState(false);


  useEffect(() => {
    if (location && location.state && location.state.schedule_id) {
      localStorage.setItem("sch_id", location.state.schedule_id);
    }
    if (location && location.state && location.state.back === 'pre') {
      setClassID(localStorage.getItem('cid'));
      setMyscheduled_date(localStorage.getItem('scheduled_date'));
      // localStorage.setItem("cid", location.state.class_id);
      if (location.state.serchKey) {
        searchHandler(location.state.serchKey);
        setSearchedKeyword(location.state.serchKey);
      }

    } else if (location && location.state && location.state.class_id) {
      localStorage.removeItem('scheduled_date');
      localStorage.removeItem('cid');
      localStorage.setItem('scheduled_date', location.state.scheduled_date);
      setMyscheduled_date(location.state.scheduled_date)
      localStorage.setItem("cid", location.state.class_id);
      setClassID(location.state.class_id);
    }
  }, [location])



  const [clData, setClData] = useState([]);
  const [ctList, setCtList] = useState([]);
  const [error, setError] = useState({ name: "", lastname: "", email: "", mobile: "", country_code: "", referred_by: "", });

  // useEffect(() => {
  //   if (context) {

  //     setCtList(
  //       context && context.context && context.context.country
  //         ? context.context.country
  //         : ""
  //     );
  //   }
  // }, [context]);


  const getCountryCode = async () => {
    setLoder(true);
    const resp = await get_countries();
    let code = [];
    if (resp) {
      if (resp.data && resp.data.length > 0) {
        (resp.data).forEach(e => {
          code.push({ value: e.code, label: e.name });
        });
        setLoder(false);
        setCtList(code);
      } else { setCtList([]); setLoder(false); }
      setLoder(false);
    }
    setLoder(false);
  }


  useEffect(() => {
    getCountryCode();
  }, [])

  const [isAddClientVisible, setIsAddClientVisible] = useState(false);
  // const [isSearchPanelVisible, setIsSearchPanelVisible] = useState(false);

  const handleAddClientClick = () => {
    setIsAddClientVisible(true);
    // setIsSearchPanelVisible(false);
  };

  // const handleSearchFieldClick = () => {
  //   setIsSearchPanelVisible(!isSearchPanelVisible);
  //   setIsAddClientVisible(false);
  // };

  const searchHandler = async (data) => {

    const serchKey = data.replace(/\s+/g, '');
    const fData = {
      'pageid': "1",
      'business_id': localStorage.getItem("business_id"),
      'class_id': localStorage.getItem("cid"),
      'search_val': serchKey,
      'scheduled_date': myscheduled_date,
    };
    const resp = await search_customer(fData);
    if (resp) {
      const list = [];
      resp.data.data.map((e) => {
        list.push({
          data: e,
          profile_img: e.profile_img,
          name: e.name,
          gender: e.gender,
          email: e.email,
          my_passes_details: e.my_passes_details,
          family_member: e.family_member
        });
      });
      setClData(list);
    } else setClData([]);
  };

  const handlechange = (e) => {
    const { name, value } = e.target;
    if (value.trim() === "") {
      setError((prevError) => ({ ...prevError, [name]: "*Required" }));
    } else {
      setError((prevError) => ({ ...prevError, [name]: "" }));
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    let isValid = 1;
    const formData = new FormData(e.target);
    const formValue = Object.fromEntries(formData.entries());

    const name = formValue.name;
    const lastname = formValue.lastname;
    const email = formValue.email;
    const mobile = formValue.mobile;
    const country_code = formValue.country_code;
    const referred_by = formValue.referred_by;

    if (!name) {
      isValid = 2;
      setError((pre) => ({ ...pre, name: "*Required" }));
    }
    if (!lastname) {
      isValid = 3;
      setError((pre) => ({ ...pre, lastname: "*Required" }));
    }
    if (!email) {
      isValid = 4;
      setError((pre) => ({ ...pre, email: "*Required" }));
    }
    if (!mobile) {
      isValid = 5;
      setError((pre) => ({ ...pre, mobile: "*Required" }));
    }
    if (!country_code) {
      isValid = 6;
      setError((pre) => ({ ...pre, country_code: "*Required" }));
    }

    if (isValid === 1) {
      const finalData = new FormData();
      finalData.append("name", name);
      finalData.append("lastname", lastname);
      finalData.append("email", email);
      finalData.append("mobile", mobile);
      finalData.append("country_code", country_code);
      finalData.append("referred_by", referred_by);
      finalData.append("discount", "0");

      const resp = await add_client(finalData);
      if (resp) {
        Swal.fire({
          html: `
      <img src=${logo} class="img-fluid d-block mx-auto mt-5" alt=''/>
      <h4 class="title text-uppercase text-center mt-4 textWhite">
        SUCCESS
      </h4>
      <p class="text-center my-4 textcolor">
        <small class="textWhite">
          ${resp.data.message}
        </small>
      </p>
    `,
          showClass: {
            popup: "custom-dialog-container",
          },
          customClass: {
            confirmButton: "custom-button custom-button-size modalbtn",
          },
        });
      }
    }
  };

  const clientPasses = (data, value, parentData = "", type = "") => {

    let Waiver = 0;
    let client_id = "";
    if (type && type === 'family') {
      Waiver = parentData?.data?.waiver;
      client_id = parentData?.data?.client_id;
    } else {
      Waiver = value?.data?.waiver;
      client_id = value?.data?.client_id;
    }

    // if (Waiver != 1) {
    //   Swal.fire({
    //     // title: "Are you sure?",
    //     text: "Please make sure to agree with terms and conditions before proceeding. Are you agree with T & C ?",
    //     icon: "warning",
    //     showCancelButton: true,
    //     confirmButtonColor: "#3085d6",
    //     cancelButtonColor: "#d33",
    //     confirmButtonText: "Yes"
    //   }).then(async (result) => {
    //     if (result.isConfirmed) {
    //       const finalData = {
    //         'cust_id': client_id,
    //         'cust_status': 1,
    //       }
    //       setLoder(true);
    //       const resp = await waiver_customer(finalData);
    //       if (resp) {
    //         setClData([]);
    //         setSelectedParent();
    //         setLoder(false);
    //         Swal.fire({
    //           html: `
    //             <img src=${logo} class="img-fluid d-block mx-auto mt-5" />
    //             <h4 class="title text-uppercase text-center mt-4 textWhite">Success</h4>
    //             <p class="text-center my-4 textcolor">
    //               <small class="textWhite">${resp?.data?.message}</small>
    //             </p>
    //           `,
    //           showClass: {
    //             popup: 'custom-dialog-container',
    //           },
    //           customClass: {
    //             confirmButton: 'custom-button custom-button-size modalbtn',
    //           },
    //           confirmButtonText: 'OK',
    //         })
    //       }
    //     } else {
    //       return false;
    //     }
    //   });
    // }

    // if (Waiver != 1) { return };

    if (data && data.length != 0) {
      const filterCard = [];

      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        if (element.valid_class_id && element.valid_class_id.length !== 0) {
          for (let j = 0; j < element.valid_class_id.length; j++) {
            const ee = element.valid_class_id[j];
            if (ee === classID) {
              filterCard.push(element);
              break; // exit the inner loop
            }
          }
        }
      }

      if (filterCard && filterCard.length === 1) {
        let myIDS = '';
        if (value.myid) {
          setSelectedUser(value.myid);
          myIDS = value.myid;
        } else {
          setSelectedUser(value.data.id);
          myIDS = value.data.id;
        }
        const idsssss = filterCard[0].pass_id;
        signupClient(idsssss, myIDS);
      } else if (filterCard && filterCard.length > 1) {
        setPassList(filterCard);
        if (value.myid) {
          //     let data = { data: ({ id: value.myid }) }
          setSelectedUser(value.myid);
        } else {
          setSelectedUser(value.data.id);
        }
        setPassModal(true);
      }

    }

  };

  const passRadioHandler = (e) => {
    const { name, value } = e.target;
    setPassRadio(value);
  }

  const signupClient = async (id = '', UserId = '') => {
    let getpass_ids = '';
    let getuserID = '';
    if (id) { getpass_ids = id; } else { getpass_ids = passRadio; }
    if (UserId) { getuserID = UserId } else { getuserID = selectedUser }
    if (getpass_ids) {
      const detail = {
        user_id: getuserID,
        business_id: localStorage.getItem("business_id"),
        class_id: localStorage.getItem("cid"),
        schedule_id: localStorage.getItem('sheDuleId INT'),
        pass_id: getpass_ids
      };

      const resp = await new_client_signup(detail);

      if (resp) {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" alt='' />
              <h4 class="title text-uppercase text-center mt-4 textWhite">
              Success
            </h4>
            <p class="text-center my-4 textcolor">
            <small class="textWhite">
          ${resp.data.message}
            </small></p>
            `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            navigate('/ClassSchedule');
          }
        })
      }
    }
  }

  const searchChangeHandler = (data) => {
    setSelectedParent();
    setSearchedKeyword(data);
    searchHandler(data);
  }



  const radioHandler = (data) => {
    setSelectedParent(data);
  }

  return (
    <>
      {loder && <div className="MainLoader"> <Spinner animation="border" /> </div>}
      <div className="MainDashboard">

        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col>
              <HeaderDashboard />
              <div className="RightSide">
                <div className="innerContent">
                  <section className="ClassAttendance">
                    <Container>
                      <Row className="mt-0">
                        <Col onClick={() => setIsAddClientVisible(false)}>
                          <InputField
                            type="search"
                            placeholder="Search"
                            value={searchedKeyword}
                            onChange={(e) => searchChangeHandler(e.target.value)}
                            ownClass={'mb-1'}
                          />
                          {!selectedParent && clData && (
                            <div className="SearchClientPanel" id="SearchPanelBox">
                              <ClientBox data={clData} onClick={clientPasses} radioHandler={radioHandler} stateData={location.state} searchedKeyword={searchedKeyword} classID={classID} />
                            </div>
                          )}
                        </Col>
                        <Col>
                          <SharedButton
                            variant="Primary"
                            className="btn btn-primary"
                            type="button"
                            title="Add Client"
                            onClick={handleAddClientClick}
                          />
                        </Col>
                      </Row>
                      {isAddClientVisible ? (
                        <Form onSubmit={submitHandler}>
                          <div className="AddNewClient">
                            <Row>
                              <Col>
                                <InputField
                                  name={"name"}
                                  type="text"
                                  error={error.name}
                                  onChange={handlechange}
                                  lable="First Name"
                                />
                              </Col>
                              <Col>
                                <InputField
                                  name={"lastname"}
                                  type="text"
                                  error={error.lastname}
                                  onChange={handlechange}
                                  lable="Last Name"
                                />
                              </Col>
                              <Col>
                                <InputField
                                  name={"email"}
                                  type="email"
                                  error={error.email}
                                  onChange={handlechange}
                                  lable="Enter Email Address"
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <SharedSelect
                                  name={"country_code"}
                                  options={ctList}
                                  error={error.country_code}
                                  onChange={handlechange}
                                  lable="Country Code"
                                />
                              </Col>
                              <Col>
                                <InputField
                                  name={"mobile"}
                                  type="number"
                                  maxLength={"12"}
                                  error={error.mobile}
                                  onChange={handlechange}
                                  lable="Mobile/Cell"
                                />
                              </Col>
                              <Col>
                                <InputField
                                  name={"referred_by"}
                                  type="text"
                                  lable="Referred by"
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <SharedButton
                                  type="submit"
                                  title="Save"
                                  className="w-100 mt-4"
                                />
                              </Col>
                              <Col></Col>
                              <Col></Col>
                            </Row>
                          </div>
                        </Form>
                      ) : (
                        <>
                          {selectedParent &&
                            <ClinetSelect data={selectedParent} onClick={clientPasses} radioHandler={radioHandler} stateData={location.state} searchedKeyword={searchedKeyword} classID={classID} />
                          }

                          {!clData && (
                            <div className="SearchClientPanel mt-1">
                              No Record Found
                            </div>
                          )}
                        </>
                      )}

                      <div className="multiselector"></div>

                      <Modal
                        show={passModal}
                        className="modal fade statusUpdate-modal modalwidth PurchaseModalBG"
                      >
                        <div
                          className="modal-dialog modal-dialog-centered "
                          role="document"
                        >
                          <div className="modal-content ">
                            <div className="modal-body p-4">
                              <h5 className="title mb-4">
                                Purchased Passes List
                              </h5>
                              <div className="pb-5 change-yourstatus">
                                {passList.length > 0 &&
                                  passList.map((pass) => (
                                    <div
                                      className="prelist-pass p-3 blueback-pass mb-2"
                                      key={pass.pass_id}
                                    >
                                      <div className="mx-0 row align-items-center purchasepassList">
                                        <div className="col-lg-6">
                                          <div className="passnew_logo">
                                            <img
                                              src={logo}
                                              alt="Pass Logo"
                                              className="img-fluid"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-lg-6">
                                          <div className="row">
                                            <div className="col-lg-10">
                                              <div className="passdetail_new text-center flex-fill">
                                                <p className="mb-0 text-capitalize">
                                                  {pass.pass_name}
                                                </p>
                                                <h6 className="mb-2">
                                                  $ {pass.full_price}{" "}
                                                  <small>Plus Tax</small>
                                                </h6>
                                              </div>
                                            </div>
                                            <div className="col-lg-2">
                                              <input
                                                style={{ borderRadius: '10px', background: passRadio === pass.pass_id ? '#0075ff' : '' }}
                                                type="radio"
                                                name="pass_radio"
                                                id={pass.pass_id}
                                                className="pass_input_checkbox form-control"
                                                value={pass.pass_id}
                                                checked={passRadio === pass.pass_id}
                                                onChange={passRadioHandler}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row mt-4">
                                        <div className="col-12">
                                          <p>
                                            {pass.pass_type_category === "PunchCard" ? (
                                              <span>
                                                Remaining Classes: {`${pass.remaining_count ? pass.remaining_count + ' of ' : 'N/A'}`}
                                                {`${pass.total_count ? pass.total_count : ''}`}
                                              </span>
                                            ) : (
                                              <span>
                                                Remaining Days: {`${pass.remaining_count ? pass.remaining_count + ' of ' : 'N/A'}`}
                                                {`${pass.total_count ? pass.total_count : ''}`}
                                              </span>
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="row mt-1 align-items-end">
                                        <div className="col-12">
                                          <p className="font-12">
                                            Purchase Date: {moment.unix(pass.start_date_utc).format('D MMM YYYY')}
                                          </p>
                                        </div>
                                      </div>
                                      {/* <div className="row mt-1 align-items-end">
                                      <div className="col-12">
                                        <p className="font-12">
                                          Renewal Date:{" "}
                                          {pass.end_date_first_date}
                                        </p>
                                      </div>
                                    </div> */}
                                      <div className="row mt-1 align-items-end">
                                        <div className="col-12">
                                          <p className="font-12">
                                            {/* Expiration Date: {moment.unix(pass.pass_expired_date).format('MMMM D, YYYY')} */}
                                            Expiration Date:{pass.end_date}

                                          </p>
                                        </div>
                                      </div>
                                      <div className="row mt-1 align-items-end">
                                        <div className="col-12">
                                          <p className="font-12" style={{ textTransform: 'capitalize' }}>
                                            Status: {pass.card_status}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                              <SharedButton
                                type="button"
                                title={"Submit"}
                                data-dismiss="modal"
                                className="btn btn-brandblue text-uppercase w-100 mt-3"
                                onClick={() => signupClient()}
                              // disabled={staffSpinner}
                              />
                              <SharedButton
                                type="button"
                                data-dismiss="modal"
                                id="btn_close1"
                                onClick={() => setPassModal(false)}
                                className="btn btn-brandblue text-uppercase w-100 mt-3"
                                data-backdrop="static"
                                data-keyboard="false"
                                variant="primary"
                                title={"Close"}
                              />

                            </div>
                            <div></div>
                          </div>
                        </div>
                      </Modal>
                    </Container>
                  </section>
                </div>
                <Footer />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
