import React from 'react'
import './InstructorDetail.css'
import { InnerHeader } from '../../Components/inner-header/InnerHeader1'
import { Innerfooter } from '../../Components/inner-footer/Innerfooter'
import { DashboardToSectionDetails } from '../../Components/CommanPages/DashboardToSectionDetails'
import { staff_instructor_details } from '../../../Static_Services/Staff_Services'
import { Link, useLocation } from 'react-router-dom'
import { useState } from 'react'
import { useEffect } from 'react'

export const InstructorDetail = () => {
  const location = useLocation();
  const [businessDetail,setBusinessDetail] = useState();
  const [userdetail,setUserdetail] = useState([]);
  const [staffDetail, setStaffDetail] = useState();
  const [iD,setID] = useState();

  let navData =location.state;
 

  useEffect(()=>{
    if(JSON.parse(localStorage.getItem('w_BusinessDetail'))){
      setBusinessDetail(JSON.parse(localStorage.getItem('w_BusinessDetail')))
    }
    if(JSON.parse(localStorage.getItem('w_loginDetail'))){
      setUserdetail(JSON.parse(localStorage.getItem('w_loginDetail')))
    }
    if(navData && navData.Id){
      localStorage.setItem('w_StaffId', JSON.stringify(navData.Id));
      setID(navData.Id);
    }
    if(JSON.parse(localStorage.getItem('w_StaffId'))){
      setID(JSON.parse(localStorage.getItem('w_StaffId')));
    }
  },[])


  const getDetils = async () => {
    const fData = {
      "user_id": iD
    }
    const resp = await staff_instructor_details(fData);
    if (resp) {
      setStaffDetail(resp.data.data);
    }
  }

  useEffect(() => {
    if (iD) {
      getDetils();
    }
  }, [iD])
  return (
    <>
      <InnerHeader />
      <div className="body-grey">
        <div className="studio-details client-home-inner">
          <div className="container">
            <DashboardToSectionDetails />
        
            {staffDetail &&
            <div className="home-staff pb-4">
              <div className="row">
                <div className="col-12">
                  <div className="section-heading pt-2 pb-4 text-left">
                    <p href="Javascript:void(0);" className="black-color font-w-100 text-uppercase font-20"> staff / instructors  detail</p>
                  </div>
                </div>
              </div>

              <div className="home-staff-details white-bg box-shadow1 border-radius6 px-3 py-4 pb-4">
                <div className="row">
                  <div className="col-12 col-md-2 text-center">
                    <div className="home-staff-detail-img">
                      <img src={staffDetail.profile_img} className="img-fluid box-shadow1 border-radius100  staf-img" alt='' />
                    </div>
                  </div>

                  <div className="col-12 col-md-10 pl-3 pl-md-0 mt-4 mt-md-0">
                    <div className="home-staff-detail-text">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="">
                            <h5 className="heading-titlenew black-color font-w-100 mb-0">{staffDetail.name+'  '+staffDetail.lastname} </h5>
                            <h5 className="heading-titlenew black-color font-w-100 mb-0">{staffDetail.email} </h5>
                            <h5 className="heading-titlenew black-color font-w-100 mb-0">{staffDetail.country_code+'-'+staffDetail.mobile} </h5><br />
                            <p className="black-color font-w-100 paratitle mb-0"> Address : </p>
                            <p className="brand-color3 paratitle mb-2 paratitle"> </p>

                            <p className="brand-color3 paratitle mb-2 paratitle"> {`${staffDetail.address}, ${staffDetail.city}, ${staffDetail.state}, ${staffDetail.country}`}</p>

                            <p className="black-color font-w-100 paratitle mb-0" > Skills : </p>
                            <p className="brand-color3 font-12 mb-2 paratitle"  > {staffDetail.skill}  </p>

                            <p className="black-color font-w-100 paratitle mb-0"  > Experience : </p>
                            <p className="brand-color3 font-12 mb-2 paratitle"  >{staffDetail.experience} Years </p>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="pr-0 pr-lg-3">
                            <ul className="home-staff-detail-contact"  >
                              <li> 
                                <Link to={`tel:${staffDetail.country_code} ${staffDetail.mobile}`} target="blank" > <img src="clientassets/img/ic_call_web.png" alt='' /> 
                                </Link>
                              </li>
                              <li> 
                                <Link to={`https://mail.google.com/mail/u/0/?view=cm&to=${staffDetail.email}&:&bcc&cc&fs=1&tf=1`} target="blank" > <img src="clientassets/img/ic_email_web.png" /> 
                                </Link> 
                              </li>
                              <li > <button className="btn btn-green-grad" onClick={()=>window.history.back()}>Back</button> </li>

                            </ul>
                          </div>
                        </div>

                        <div className="col-12 mt-4 mt-md-0">
                          <div className="text-left pr-0 pr-lg-3"  >
                            <p className="black-color font-w-100 mb-0 paratitle"> About the Instructor </p>
                            <p className="brand-color3 font-12 mb-0 paratitle">  {staffDetail.about}  </p>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            }
          </div>
        </div>
      </div>
      <Innerfooter />
    </>
  )
}
