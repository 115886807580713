import React, { useState } from "react";
import { Row, Col, Container, Spinner } from "react-bootstrap";
import { Footer } from "../../../../../public/containers/footer/Footer";
import { HeaderDashboard } from "../../comman_page/HeaderDashboard";
import { TextArea } from "../../../../../sharedComponents/TextArea";
import { InputField } from "../../../../../sharedComponents/InputField";
import { SharedSelect } from "../../../../../sharedComponents/SharedSelect";
import { SharedButton } from "../../../../../sharedComponents/Button";
import Sidebar from "../../comman_page/Sidebar";
import Addroomform from "./Addroomform";
import AddRoomFormNew from "./AddRoomFormNew";

export const Addroom = () => {
  const [loder,setLoder] = useState(false);


  return (
    <>
       {loder && <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div> }
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col className="p-0 RightCol">
              <HeaderDashboard />
              <div className="RightSide">
                <section className="addClient innerContent">
                  <Container className="pt-5">
                    <h3>Complete Your Location Details</h3>
                    <p>
                      Location details include information about where you will hold
                      your classes or workshops such as the name of a room or
                      additional you may provide your activities.
                    </p>
                    <div className='AddClientForm AddClientContent'>
                      {/* <Addroomform /> */}
                      <AddRoomFormNew  setLoder={setLoder}/>
                    </div>
                  </Container>
                </section>
                <Footer />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
