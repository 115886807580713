import React from 'react'
import { InnerHeader } from '../../Components/inner-header/InnerHeader1'
import { Innerfooter } from '../../Components/inner-footer/Innerfooter'
import { useState } from 'react'
import { useEffect } from 'react'
import { staff_notification_on_off } from '../../../Static_Services/Staff_Services'
import { errorAlert, successAlert } from '../../../Static_Services/Alertmsg'

export const Prefrence = () => {
  const [noti, setNoti] = useState();

  const GetNotification = () => {
    const storedData = localStorage.getItem('w_loginDetail');
    if (storedData) {
      const data = JSON.parse(storedData);
      const notifications = data.notification;
      setNoti(notifications);
   
    }
  }
  useEffect(() => {
    GetNotification();
  }, [])

  const changePreferenceold = async (event, name) => {
    let status = '';
    let appStatus = noti.app_notification;
    let aStatus = noti.alerts;
    let prStatus = noti.make_profile_private;
    if (event.target.checked == true) {
      status = 1;
    } else {
      status = 0;
    }
    if (name == "app_notification") {
      appStatus = status;
    } else if (name == "alerts") {
      aStatus = status;
    } else if (name == "make_profile_private") {
      prStatus = status;
    } 
   
    var detail = { "app_notification": appStatus, "make_profile_private": prStatus, "alerts": aStatus }
    const resp = await staff_notification_on_off(detail);
    if(resp){
      const notifications = resp.data.data.notification;
      setNoti(notifications);
      localStorage.setItem('w_loginDetail', JSON.stringify(resp.data.data));
      localStorage.setItem("userDetail", JSON.stringify(resp.data.data));
      successAlert(resp.data.message);
    }else errorAlert(resp.data.message);
  }
  return (
    <>
      <InnerHeader />

      <div className="body-grey">

        {/* <!-- Profile Section --> */}

        <div className="client-refer mt-0 py-5 px-3 px-sm-0">
          <div className="container">
            <div className="row white-bg border-radius6 box-shadow1 mb-2">
              <div className="col-12">
                <div className="custom-controls-stacked d-block">
                  <label className="custom-control ios-switch text-right mb-0">
                    <p className="black-color font-w-400 font-18 d-inline-block float-left mb-0 mt-2"> App Notification </p>
                    <input type="checkbox" className="ios-switch-control-input" id="app_notification" checked={noti && noti.app_notification ? noti.app_notification : ''} onChange={(event) => changePreferenceold(event, 'app_notification')} />
                    <span className="ios-switch-control-indicator"></span>
                  </label>
                </div>
              </div>
            </div>

            <div className="row white-bg border-radius6 box-shadow1 mt-1 mb-2">
              <div className="col-12">
                <div className="custom-controls-stacked d-block">
                  <label className="custom-control ios-switch text-right mb-0">
                    <p className="black-color font-w-400 font-18 d-inline-block float-left mb-0 mt-2"> Alerts </p>
                    <input type="checkbox" className="ios-switch-control-input" id="alerts" checked={noti && noti.alerts ? noti.alerts : ""} onChange={(event) => changePreferenceold(event, 'alerts')} />
                    <span className="ios-switch-control-indicator"></span>
                  </label>
                </div>
              </div>
            </div>

            <div className="row white-bg border-radius6 box-shadow1 mt-1 mb-2">
              <div className="col-12">
                <div className="custom-controls-stacked d-block">
                  <label className="custom-control ios-switch text-right mb-0">
                    <p className="black-color font-w-400 font-18 d-inline-block float-left mb-0 mt-2"> Profile Privacy </p>
                    <input type="checkbox" className="ios-switch-control-input" id="make_profile_private" checked={noti && noti.make_profile_private ? noti.make_profile_private : ''} onChange={(event) => changePreferenceold(event, 'make_profile_private')} />
                    <span className="ios-switch-control-indicator"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- end of container --> */}
        </div>
        {/* <!-- End of Profile Section --> */}
      </div>
      <Innerfooter />
    </>
  )
}
