import React from "react";
import { Col, Form, FormControl, Row } from 'react-bootstrap';
import { search_customer_list } from "../Studio-Services/Studio_Services";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

export const HeaderLiveSearch = ({ value, onChange, onClick, type, placeholder, className }) => {
    const [searchData, setSearchData] = useState();
    const navigate = useNavigate();

    const getSearch = async (data) => {
        const serchKey = data.replace(/\s+/g, '');

        const finalData = {
            "pageid": 1,
            "search_val": serchKey
        }
        // if (data) {
            const resp = await search_customer_list(finalData);
            if (resp && (resp.data.message) === "Records Found") {
                setSearchData(resp.data.data);
            }else{
                setSearchData();
            }
        // }else{
        //     setSearchData();
        // }
    }


    //   getSearch();



    const clickHandler = (e) =>{
        setSearchData();
        navigate("/studio-customer-profile",{state:{id:e.id}});
        

    }
    return (
        <>
            <Form className="Search_form">
                <input
                    placeholder="Search"
                    className="live_Search"
                    onChange={(e) => getSearch(e.target.value)}
                />
                {searchData ?
                    <Row className="love-serch" style={{ marginLeft: "2px" }}>
                        <ul>
                            {searchData.map((e,index) => (
                                <li style={{marginTop:"3px",padding:'0px'}} key={index}>
                                    {/* <Link to='/studio-customer-profile' className="onclickclass"  state={{id:e.id}} style={{textDecoration:"none" , color:"#495057"}}>{e.name}</Link> */}
                                    <button type="button" onClick={()=>clickHandler(e)}  style={{background:"none" , color:"#495057",padding:'2px'}}>{e.name}</button>
                                </li>
                            ))
                            }
                        </ul>
                    </Row>
                    : ""}
            </Form>
        </>
    );
};
