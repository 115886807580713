import React, { useEffect, useState } from "react";
import Sidebar from "../comman_page/Sidebar";
import { HeaderDashboard } from "../comman_page/HeaderDashboard";
import { Footer } from "../../../../public/containers/footer/Footer";
import { Col, Container, Row } from "react-bootstrap";
import { SharedButton } from "../../../../sharedComponents/Button";
import { getUserProfileInfo } from "../../../../Studio-Services/Studio_Services";

export const UserProfile = () => {
  const [userinfo, setUserinfo] = useState([]);

  const getInfo = async () => {
    const resp = await getUserProfileInfo();
    if (resp) {
      setUserinfo(resp.data.data);
    }
  };

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <>
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
          <Sidebar />
            <Col>
              <div className="RightSide">
                <HeaderDashboard />
                <Container>
                  <Row>
                    <Col>
                     <div className="USerProfiles">
                     <div className="myProfile">
                        <img
                          src={userinfo.profile_img}
                          alt=""
                          className="profileImage"
                        />
                        <div className="profileName">
                          <h5>
                            {userinfo.name ? userinfo.name + "  " : ""}{" "}
                            {userinfo.lastname ? userinfo.lastname : ""} (Studio
                            Owner)
                          </h5>
                          <p>
                            <b>Mobile :- </b>
                            {userinfo.mobile}{" "}
                          </p>
                          <p>
                            <b>Email :- </b>
                            {userinfo.email}{" "}
                          </p>
                          <p>
                            <b>USA Merchant ID :- </b>
                            {userinfo.marchant_id}{" "}
                          </p>
                          <SharedButton
                            title={"Edit Profile"}
                            variant={"primary"}
                            className={"EditProfileBtn"}
                            href={"/EditProfile"}
                            element={userinfo}
                          />
                        </div>
                      </div>
                     </div>
                    </Col>
                  </Row>
                </Container>
                <Footer />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
