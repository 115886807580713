import React, { useState } from 'react';
import { useEffect } from 'react';

const MultiSelectDropdown = ({ multipleCategories, chieldHandler }) => {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);
  const [mainFinal, setmainFinale] = useState([]);
  const [innerFinal, setInnerFinal] = useState([]);
  const [parent, setParent] = useState([]);
  const [chield,setChield] = useState([]);

  useEffect(() => {
    chieldHandler(mainFinal, innerFinal);
  }, [mainFinal, innerFinal])




  const multipleCategoryChanged = (event, category) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedCategories([...selectedCategories, category]);
      setmainFinale((prev) => [...prev, { category_id: category.category_id }]);
      setParent((prev) => [...prev, category.category_id ]);
    } else {
      setSelectedCategories(selectedCategories.filter((item) => item !== category));
      setmainFinale(mainFinal.filter((item) => item.category_id !== category.category_id));
      setParent(parent.filter((item) => item != category.category_id));
    }
  };


  const multipleSubCategoryChanged = (event, subcategory) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      if(selectedSubcategories.filter((item)=>item.category_id != subcategory.category_id)){
        setSelectedSubcategories([...selectedSubcategories, subcategory]);
        setInnerFinal((prev) => [...prev, { id: subcategory.id, category_id: subcategory.category_id }]);
        setChield((pre)=>[...pre,subcategory.category_id]);
      }
    } else {
      setSelectedSubcategories(selectedSubcategories.filter((item) => item !== subcategory));
      setInnerFinal(innerFinal.filter((item) => item.category_id !== subcategory.category_id))
      setChield(chield.filter((item) => item !== subcategory.category_id));
    }
  };

  const selectmultiDataText = selectedCategories.concat(selectedSubcategories);



  return (
    <div className="multiselect-dropdown multiselectdrop dropdown">
      <label className='form-label inputLable'>Service Offers</label>
      <div className="form-control multiselectdiv dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"
      //  onClick={() => setHideShow(!hideShow)}
       >
        
        {selectmultiDataText.map((data) => (
          <span key={data.category_name} className="multiselectspan">
            {data.category_name}
          </span>
        ))}
      </div>
      {/* <input type="text" className="form-control" id="productCategory" /> */}
      {/* {hideShow && */}
        {/* <div className="dropdownlist p-4 "> */}
          <ul className="mainchecklist m-0 dropdown-menu w-100">

            {multipleCategories && multipleCategories.length > 0 && multipleCategories.map((category, index) => (
              <li className="py-2 dropdown-item" key={category.category_name}>
                <label>
                  <input
                    type="checkbox"
                    checked={parent.includes(category.category_id)}
                    onChange={(event) => multipleCategoryChanged(event, category)}
                  />
                  {category.category_name}
                </label>
                <ul className="pl-3 subchecklist m-0">
                  {category.subcategory.length !== 0 &&
                    category.subcategory.map((subcategory, innerindex) => (
                      <li key={subcategory.category_name} className="py-1 ">
                        <label>
                          {/* {console.log(selectedCategories.length > 0 && selectedCategories[innerindex] && selectedCategories[innerindex].category_id, "====", category.category_id, '--', index)} */}
                          <input
                            type="checkbox"
                            checked={chield.includes(subcategory.category_id)}
                            onChange={(event) =>
                              multipleSubCategoryChanged(event, subcategory)
                            }
                            disabled={!parent.includes(category.category_id)}
                          />
                          {subcategory.category_name}
                        </label>
                      </li>
                    ))}
                </ul>
              </li>
            ))}
          </ul>
        {/* </div> */}
      {/* } */}
    </div>
  );
};

export default MultiSelectDropdown;
