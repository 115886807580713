import React from "react";
import Sidebar from "../../comman_page/Sidebar";
import { HeaderDashboard } from "../../comman_page/HeaderDashboard";
import { Container, Row, Col, Spinner } from "react-bootstrap";

import SharedTabs from "../../../../../sharedComponents/SharedTabs";
import { Footer } from "../../../../../public/containers/footer/Footer";
import { UploadBulkData } from "../../../../../sharedComponents/UploadBulkData";
import { LiveSearch } from "../../../../../sharedComponents/LiveSearch";

import { SharedButton } from "../../../../../sharedComponents/Button";
import { InstructorTable } from "./InstructorTable";

import { useState } from "react";
import { useEffect } from "react";
import { Addinstructorform } from "./Addinstructorform";
import { AddInstructor } from "./AddInstructor";
import { getInstructorList } from "../../../../../Studio-Services/Studio_Services";

export default function Addstaff() {
  const [loder, setLoder] = useState(false);
  const [binfo, setBinfo] = useState([]);
  const [instructorlistdata, setInstructorlistdata] = useState([]);


  const staffList = async () => {
    const response = await getInstructorList({ "pageid": 1 });
  
    if (response.data.status == 1) {
        const instructorList = response.data.data;
        if (instructorList) {
            setInstructorlistdata(instructorList);
        }
        const businessInfo = response.data.businessInfo;
        if (businessInfo) {
            setBinfo(businessInfo);
        }
    }
}

const topandler = ()=>{
  window.scrollTo(0, 0);
}


  const tabs = [
    {
      eventKey: "home",
      title: "Instructor Profile",
      content: <Addinstructorform staffList={staffList} topandler={topandler} setLoder={setLoder}/>,
    },
    {
      eventKey: "profile",
      title: "Upload Bulk Data",
      content: <UploadBulkData />,
    },
    {
      eventKey: "instructor",
      title: "Add Instructor",
      content: <AddInstructor />,
    },
  ];

  const loderHandler = (data) =>{
    setLoder(data);
  }





useEffect(() => {
  staffList();
}, []);
  return (
    <>
      {loder ?
        <div className='MainLoader'>
          <Spinner animation="border" role="status" className='Loader'>
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
        : ""}
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col className="p-0 RightCol">
              <HeaderDashboard />
              <div className="RightSide">
                <section className="addClient innerContent">
                  <Container className="pt-5">
                    <h4>List Your Instructor/Staff</h4>
                    <p>
                      Your instructors or staff are listed and made active or
                      inactive here. Based on the package you have chosen you
                      can have a maximum number of active staff/instructor you
                      can add. Your staff/instructor have a dedicated mobile
                      app to help them manage attendance and set up their
                      availability for services.
                    </p>
                    <div className='AddClientForm AddClientContent'>

                      <Row className="mt-3">
                        <Col>
                          <InstructorTable setBinfo={setBinfo} staffList={staffList} binfo={binfo} setInstructorlistdata={setInstructorlistdata} instructorlistdata={instructorlistdata} loderHandler={loderHandler}/>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <SharedTabs tabs={tabs} />
                        </Col>
                      </Row>
                    </div>
                  </Container>
                </section>
                <Footer />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
