import React from 'react'
import Pagination from './Pagination'

export default function BootstrapTable({ th, tr, totpage, pageHandler, cpage, totalDetail,myrefund }) {



    return (
        <>
            <table className="table table-striped">
                <thead>
                    <tr>
                        {th && th.length > 0 && th.map((item, index) => <th key={index}>{item}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {tr && tr.length > 0 && tr.map((row, index) => <tr key={index}>
                        <td>{row.summary}</td>
                        <td>{row.transaction}</td>
                        <td>$ {row.before_tax}</td>
                        <td>$ {row.tax_one}</td>
                        <td>$ {row.tax_two}</td>
                        <td>$ {row.total}</td>
                    </tr>)}
                    {totalDetail &&                  
                    <tr className='highlighted highlighted-my'>
                        <td>Total</td>
                        <td style={{ width: 'auto', whiteSpace: 'nowrap' }}>{ totalDetail.Transection}</td>
                        <td style={{ width: 'auto', whiteSpace: 'nowrap' }}>$ { totalDetail.Before_Tax}</td>
                        <td style={{ width: 'auto', whiteSpace: 'nowrap' }}>$ { totalDetail.total_Tax1}</td>
                        <td style={{ width: 'auto', whiteSpace: 'nowrap' }}>$ { totalDetail.total_Tax2}</td>
                        <td style={{ width: 'auto', whiteSpace: 'nowrap' }}>$ { totalDetail.finalTotal}</td>
                    </tr>
                    }
                </tbody>
            </table>
        </>


    )
}
