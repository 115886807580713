import React from 'react'
import { InnerHeader } from '../../Components/inner-header/InnerHeader1'
import { Innerfooter } from '../../Components/inner-footer/Innerfooter1'
import { SubHeader } from '../../Components/sub-header/SubHeader'
import './StaffAvailability.css';
import { DashboardToSectionDetails } from '../../Components/CommanPages/DashboardToSectionDetails'
import { useState } from 'react'
import { useEffect } from 'react'
import { staff_get_weekdays_timeslot, staff_instructor_availabilityn } from '../../../Static_Services/Staff_Services'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import { Modal, Spinner } from 'react-bootstrap'
import { errorAlert, successAlert } from '../../../Static_Services/Alertmsg'

export const StaffAvailability = () => {
  
    const [availableList, setAvailableList] = useState();
    const [weekDay, setWeekDay] = useState([]);
    const [loder, setLoder] = useState(false);
    const [availability_status, setAvailability_status] = useState();
    const [showModal, setShowModal] = useState(false);

    const [businessDetail, setBusinessDetail] = useState();
    useEffect(() => {
        const bzDetails = JSON.parse(localStorage.getItem('bzDetails'));
        if (bzDetails) {
            setBusinessDetail(bzDetails);
        }
    }, []);


    const staffAvailabilityList = async () => {

        if (businessDetail.business_id) {
            setLoder(true);
            let detail = { "business_id": businessDetail.business_id };
            const resp = await staff_get_weekdays_timeslot(detail);
            if (resp) {
                const response = resp.data;
                response.data.week_data.filter(function (el) {
                    if (el.day_status == 1) {
                        el.day_status1 = true;
                    } else {
                        el.day_status1 = false;
                    }
                    el.time_slot.filter(function (ell) {
                        if (ell.time_slot_status == 1) {
                            ell.time_slot_status1 = true;
                        } else {
                            ell.time_slot_status1 = false;
                        }
                        ell.time_slote_from_new = moment.unix(ell.time_slote_from).format('hh mm A');
                        ell.time_slote_to_new = moment.unix(ell.time_slote_to).format('hh mm A');

                    })
                })
                if (response.data.availability_status == 'Available') {
                    response.data.availability_status = true;
                } else {
                    response.data.availability_status = false;
                }
                setAvailableList(response.data);
                setAvailability_status(response.data.availability_status);
                setWeekDay(response.data.week_data)
                setLoder(false);
            } else {
                setLoder(false);
            }
            setLoder(false);
        }

    }

    useEffect(() => {
        if (businessDetail) {
            staffAvailabilityList();
        }
    }, [businessDetail])

    const changeAvailability = (event) => {
        if (event.target.checked === true) {
            setAvailability_status(true);
        } else {
            setAvailability_status(false);
        }

    }




    const changeStatus1 = (event, data, id) => {
        // Initialize status based on event.target.checked
        const newStatus = event.target.checked ? 1 : 0;
        let currentstatus = '';
        let updatedData = weekDay; // Create a shallow copy of weekDay

        if (newStatus === 0 && !id) {
            updatedData = updatedData.map((item) => {
                if (item.id === data.id) {
                    return {
                        ...item,
                        time_slot: item.time_slot.map((el) => ({
                            ...el,
                            time_slot_status: newStatus,
                            time_slot_status1: false,
                        })),
                        day_status: newStatus,
                        day_status1: false,
                    };
                }
                return item;
            });
        }

        if (newStatus === 0 && id) {
            updatedData = updatedData.map((item) => {
                if (item.id === data.id) {
                    item.time_slot = item.time_slot.map((el) => {
                        if (el.id === id) {
                            el.time_slot_status = newStatus;
                            el.time_slot_status1 = false;
                        }
                        return el;
                    });

                    item.time_slot.forEach((el) => {
                        if (el.time_slot_status1 === true || el.time_slot_status === 1) {
                            currentstatus = 1;
                        }
                    });

                    if (currentstatus !== 1) {
                        item.day_status = newStatus;
                    }
                }
                return item;
            });
        }


        if (newStatus === 1 && !id) {
            updatedData = updatedData.map((item) => {
                if (item.id === data.id) {
                    return {
                        ...item,
                        day_status1: true,
                    };
                } else {
                    return item; // Return the original item if the condition is not met
                }
            });
            setWeekDay(updatedData);
            errorAlert('Please check at least 1 time slot to update availability');
            return;
        }

        if (newStatus === 1 && id) {
            updatedData = updatedData.map((items) => {
                if (items.id === data.id) {
                    if (items.day_status !== 1 && items.day_status1 !== true) {
                        errorAlert('Please check the day first to update availability');
                        event.target.checked = false;
                        return items; // Return the original items
                    } else {
                        items.day_status = newStatus;
                        items.day_status1 = true;
                        items.time_slot = items.time_slot.map((el) => {
                            if (el.id === id) {
                                el.time_slot_status = newStatus;
                                el.time_slot_status1 = true;
                            }
                            return el;
                        });
                    }
                }
                return items; // Return the modified or original items
            });
        }


        setWeekDay(updatedData);
    };




    const SaveChanges = async () => {
        setLoder(true);
        let updatedList = [];
        let timeslot = [];
        let availability = '';
        weekDay.filter(function (el) {
            el.time_slot.filter(function (ell) {
                timeslot.push({ 'time_slot_id': ell.id, 'time_slot_status': ell.time_slot_status });
            })
            updatedList.push({ "day_id": el.id, "day_status": el.day_status, "slot_time": timeslot });
            timeslot = [];
        })

        if (availability_status === true) {
            availability = 'Available';
        } else {
            availability = 'Unavailable';
        }

        var detail = { "availability_status": availability, "business_id": businessDetail.business_id, "days": updatedList };

        const resp = await staff_instructor_availabilityn(detail);
        if (resp) {
            setShowModal(false);
            successAlert(resp.data.message);
            staffAvailabilityList();
            setLoder(false);

        } else {
            setLoder(false);
        }
        setLoder(false);

    }

    return (
        <>
            {loder ? <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div> : ""}
            <InnerHeader />

            <div className="body-grey">

                {/* <!-- My Studio Card Detials Section --> */}
                <div className="studio-details client-home-inner">
                    <div className="container">
                        <DashboardToSectionDetails />

                        <div class="home-staff pb-5">
                            <div class="row staff-studio-details client-refer">
                                <div class="col-md-6">
                                    <div class="tabbox box-shadow1 border-radius6 px-4 py-3 d-flex align-items-center bg-white">
                                        <h4 class="black-color font-w-400 font-18 mb-0 text-uppercase">Availability</h4>
                                        <div class="custom-controls-stacked ml-auto">
                                            <label class="custom-control ios-switch text-right mb-0">
                                                <input type="checkbox" class="ios-switch-control-input" id={availability_status}
                                                    checked={availability_status}
                                                    onChange={(e) => changeAvailability(e)}
                                                />
                                                <span class="ios-switch-control-indicator"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 text-right">
                                    <button type="button" class="btn btn-blue-grad px-2 text-uppercase mb-0 mb-md-5" onClick={() => window.history.back()}>Back </button>
                                </div>
                            </div>
                            {availability_status &&
                                <div class="white-bg border-radius6 box-shadow1 p-4 mt-5 available-slots">

                                    <div class="row client-refer mt-4">
                                        {weekDay && weekDay.length > 0 && weekDay.map((items, i) => (
                                            <div class="col-md-6 col-lg-4 py-4">
                                                <div class="availibility-slot">
                                                    <div class="head d-flex align-items-center  border-bottom pb-2">
                                                        <h6 class="title black-color font-w-500"> {items.week_name} </h6>
                                                        <div class="custom-controls-stacked ml-auto">
                                                            <label class="custom-control ios-switch text-right mb-0">
                                                                <input type="checkbox" class="ios-switch-control-input"
                                                                    id={items.week_name}
                                                                    onChange={(e) => changeStatus1(e, items, '')}
                                                                    checked={items.day_status1}
                                                                />
                                                                <span class="ios-switch-control-indicator"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    {items.time_slot && items.time_slot.map((subitem) => (
                                                        <div class="row mt-4">
                                                            <div class="col pr-0">
                                                                <mat-form-field class="example-full-width">
                                                                    <mat-label>Start Time</mat-label>
                                                                    <input matInput
                                                                        value={subitem.time_slote_from_new}
                                                                        readOnly />
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col pr-0">
                                                                <mat-form-field class="example-full-width">
                                                                    <mat-label>End Time</mat-label>
                                                                    <input matInput
                                                                        value={subitem.time_slote_to_new}
                                                                        readOnly />
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col-3">
                                                                <div class="custom-controls-stacked ml-auto">
                                                                    <label class="custom-control ios-switch text-right mb-0">
                                                                        <input type="checkbox" class="ios-switch-control-input" id={`${subitem.id}${i}`}
                                                                            checked={subitem.time_slot_status1}
                                                                            onChange={(e) => changeStatus1(e, items, subitem.id)}
                                                                        />
                                                                        <span class="ios-switch-control-indicator"></span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    {availability_status &&
                                        <button class="btn btn-green-grad text-uppercase d-block mx-auto" type="button" data-toggle="modal" data-target="#successModal" onClick={() => setShowModal(true)}>Save</button>
                                    }
                                </div>
                            }
                        </div >
                        {/* < !--end of home - staff-- > */}
                        {/* <!-- end of home-staff --> */}
                    </div >
                    {/* <!-- end of container --> */}
                </div >
                {/* <!-- End of My Studio Card Detials Section --> */}
            </div >

            {/* <!-- modal --> */}

            {/* <!-- Modal Popup (Change - Password ) --> */}

            <Modal show={showModal}>
                <div className="" role="document">
                    <div className="modal-content">
                        <div className="modal-body white-bg box-shadow1 border-radius6 p-4 p-md-5">
                            <div className="modal-bottom-content blue-gradient border-radius6 py-3 py-sm-5 px-4">
                                <img src="clientassets/img/logo_white_web.png" className="img-fluid d-block mx-auto" />
                                <p className="text-white font-16 text-center my-4">Are you sure you want to Save changes.</p>
                                <button className="btn btn-white2 d-block mx-auto w-100" data-dismiss="modal" onClick={() => SaveChanges()} type="button">Proceed</button>
                                <button className="btn btn-white2 mx-auto w-100 mt-4" data-dismiss="modal" type="button" onClick={() => setShowModal(false)}>Cancel</button>

                            </div>
                            {/* <!-- modal-bottom-content --> */}

                        </div>
                        {/* <!-- end of modal-body --> */}

                    </div>
                </div>
            </Modal>

            <Innerfooter />

        </>
    )
}
