import React, { useEffect, useState } from 'react';
import { InputField } from '../../../../../sharedComponents/InputField';
import { Row, Col, Modal, Button } from 'react-bootstrap';
import { SharedButton } from '../../../../../sharedComponents/Button';
import { HiPlusCircle } from 'react-icons/hi';
import { AiFillMinusCircle } from 'react-icons/ai';
import { changeTimeStampToUTC, getTimeSlotList } from '../../../../../Studio-Services/Studio_Services';

export const Hoursoperation = () => {
  const [userDetail, setUserDetail] = useState();
  const [weeekArray, setWeeekArray] = useState();

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('st_userData'))) {
      setUserDetail(JSON.parse(localStorage.getItem('st_userData')));
    }
  }, [])

  useEffect(()=>{
    if(userDetail){
      timeSlotList();
    }
  },[])

  // const weeekArray = [
  //   {
  //     "name": 'Monday',
  //     "day_id": "1",
  //     "slot_time": [
  //       {
  //         "time_slote_from": '',
  //         "time_slote_to": ''
  //       }
  //     ]
  //   },
  //   {
  //     "name": 'Tuesday',
  //     "day_id": "2",
  //     "slot_time": [
  //       {
  //         "time_slote_from": '',
  //         "time_slote_to": ''
  //       }
  //     ]
  //   },
  //   {
  //     "name": 'Wednesday',
  //     "day_id": "3",
  //     "slot_time": [
  //       {
  //         "time_slote_from": '',
  //         "time_slote_to": ''
  //       }
  //     ]
  //   },
  //   {
  //     "name": 'Thursday',
  //     "day_id": "4",
  //     "slot_time": [
  //       {
  //         "time_slote_from": '',
  //         "time_slote_to": ''
  //       }
  //     ]
  //   },
  //   {
  //     "name": 'Friday',
  //     "day_id": "5",
  //     "slot_time": [
  //       {
  //         "time_slote_from": '',
  //         "time_slote_to": ''
  //       }
  //     ]
  //   },
  //   {
  //     "name": 'Saturday',
  //     "day_id": "6",
  //     "slot_time": [
  //       {
  //         "time_slote_from": '',
  //         "time_slote_to": ''
  //       }
  //     ]
  //   },
  //   {
  //     "name": 'Sunday',
  //     "day_id": "7",
  //     "slot_time": [
  //       {
  //         "time_slote_from": '',
  //         "time_slote_to": ''
  //       }
  //     ]
  //   }
  // ];

  // {
  //   "business_id": "S7MyslIytlCyBgA=",
  //   "slot_info": [
  //     {
  //       "day_id": "1",
  //       "name": "Monday",
  //       "slot_time": [
  //         {
  //           "time_slote_from": 1690309800,
  //           "time_slote_to": 1690309800
  //         }
  //       ]
  //     },
  //     {
  //       "day_id": "2",
  //       "name": "Tuesday",
  //       "slot_time": [
  //         {
  //           "time_slot_id": "441",
  //           "time_slote_from": 1690365600,
  //           "time_slote_to": 1690336800
  //         }
  //       ]
  //     },
  //     {
  //       "day_id": "3",
  //       "name": "Wednesday",
  //       "slot_time": [
  //         {
  //           "time_slot_id": "442",
  //           "time_slote_from": 1690365600,
  //           "time_slote_to": 1690336800
  //         }
  //       ]
  //     },
  //     {
  //       "day_id": "4",
  //       "name": "Thursday",
  //       "slot_time": [
  //         {
  //           "time_slot_id": "443",
  //           "time_slote_from": 1690365600,
  //           "time_slote_to": 1690336800
  //         }
  //       ]
  //     },
  //     {
  //       "day_id": "5",
  //       "name": "Friday",
  //       "slot_time": [
  //         {
  //           "time_slot_id": "444",
  //           "time_slote_from": 1690365600,
  //           "time_slote_to": 1690336800
  //         }
  //       ]
  //     },
  //     {
  //       "day_id": "6",
  //       "name": "Saturday",
  //       "slot_time": [
  //         {
  //           "time_slot_id": "445",
  //           "time_slote_from": 1690365600,
  //           "time_slote_to": 1690336800
  //         }
  //       ]
  //     },
  //     {
  //       "day_id": "7",
  //       "name": "Sunday",
  //       "slot_time": [
  //         {
  //           "time_slot_id": "446",
  //           "time_slote_from": 1690365600,
  //           "time_slote_to": 1690336800
  //         }
  //       ]
  //     }
  //   ],
  //   "insertStatus": false
  // }

  const initialRows = [
    {id: 1, day: 'Monday', openTime: '', closeTime: ''},
    {id: 2, day: 'Tuesday', openTime: '', closeTime: '' },
    {id: 3, day: 'Wednesday', openTime: '', closeTime: '' },
    {id: 4, day: 'Thursday', openTime: '', closeTime: '' },
    {id: 5, day: 'Friday', openTime: '', closeTime: '' },
    {id: 6, day: 'Saturday', openTime: '', closeTime: '' }
  ];


  const timeSlotList = async () => {
    const resp = await getTimeSlotList({ "business_id": userDetail.business_id });
    if (resp) {
      changeDateFormat(resp.data.data);
    }
  }

  const verifyWeekContent = (tempWeekArray, weekData) => {
    const week_name = weekData.name;
    for (var key in tempWeekArray) {
      const tempWeekName = tempWeekArray[key].name;
      if (week_name === tempWeekName) {
        return tempWeekArray[key];
      }
    }
    weekData.id = weekData.day_id;
    return weekData;
  }

  const changeDateFormat = (objectData, type = 1) => {
    let tempWeekArray = [];
    for (const key of Object.keys(objectData)) {
      const obj = objectData[key];
      let tempObj = {};
      tempObj.business_id = obj.business_id;
      tempObj.id = obj.id;
      tempObj.name = obj.name;

      const slot_time = obj.slot_time;
      let tempArray = [];
      for (const sol of Object.keys(slot_time)) {
        const objSlot = slot_time[sol];
        let tempObjChild = {};
        tempObjChild.business_id = objSlot.business_id;
        tempObjChild.time_slote_from = changeTimeStampToUTC(objSlot.time_slote_from);
        tempObjChild.time_slote_to = changeTimeStampToUTC(objSlot.time_slote_to);
        tempObjChild.time_slot_id = objSlot.time_slot_id;
        tempArray.push(tempObjChild);
      }
      tempObj.slot_time = tempArray;
      tempWeekArray.push(tempObj);
    }

    if (tempWeekArray.length !== 0) {
      const masterWeekArray = this.masterWeekArray;
      const tempArray = [];
      for (var key in masterWeekArray) {
        const weekData = masterWeekArray[key];
        const resp = verifyWeekContent(tempWeekArray, weekData);
        tempArray.push(resp);
      }
      setWeeekArray(tempArray);
    }
  }

  const [rows, setRows] = useState(initialRows);

  const handleAddRow = (id) => {
    const newRow = {
      id: rows.length + 1,
      day: 'Slots',
      openTime: '',
      closeTime: ''
    };
    const updatedRows = rows.map((row) => (row.id === id ? [row, newRow] : row));
    setRows(updatedRows.flat());
  };

  const handleDeleteRow = (id) => {
    // Check if the row is a duplicated row
    if (rows[id - 1].day === 'Slots') {
      const updatedRows = rows.filter((row) => row.id !== id);
      setRows(updatedRows);
    }
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        House Of Operation
      </Button>
      <div className="modal show" style={{ display: 'block', position: 'initial' }}>
        <Modal.Dialog className="HoursOperationModal">
          <Modal.Header closeButton>
            <Modal.Title>HOURS OF OPERATION</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {rows.map((row) => (
              <Row className='border-bottom mt-3' key={row.id}>
                <h6 className='sameAbove'>{row.day}<span >Same As Above</span></h6>
                <Col lg={2}>
                  <p>{row.day}</p>
                </Col>
                <Col lg={4}>
                  <InputField
                    label={'Open'}
                    type={'time'}
                    placeholder={'03:00'}
                    value={row.openTime}
                    onChange={(e) => {
                      const updatedRows = rows.map((r) =>
                        r.id === row.id ? { ...r, openTime: e.target.value } : r
                      );
                      setRows(updatedRows);
                    }}
                  />
                </Col>
                <Col lg={4}>
                  <InputField
                    label={'Close'}
                    type={'time'}
                    placeholder={'06:00'}
                    value={row.closeTime}
                    onChange={(e) => {
                      const updatedRows = rows.map((r) =>
                        r.id === row.id ? { ...r, closeTime: e.target.value } : r
                      );
                      setRows(updatedRows);
                    }}
                  />
                </Col>
                <Col lg={2}>
                  <SharedButton
                    iconNode={<HiPlusCircle />}
                    className={'HoursModalPlus'}
                    onClick={() => handleAddRow(row.id)}
                  />
                  {rows.length > 6 && row.id > 6 && (
                    <SharedButton
                      iconNode={<AiFillMinusCircle />}
                      className={'HoursModalMinus'}
                      onClick={() => handleDeleteRow(row.id)}
                    />
                  )}
                </Col>
              </Row>
            ))}
          </Modal.Body>

          <Modal.Footer>
            <Button variant="primary">Submit</Button>
            <Button variant="primary" onClick={handleClose}>Submit</Button>
          </Modal.Footer>
        </Modal.Dialog>
      </div>
    </>
  );
};
