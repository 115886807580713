import React from 'react'
import { InnerHeader } from '../../Components/inner-header/InnerHeader1'
import { SubHeader } from '../../Components/sub-header/SubHeader'
import { Innerfooter } from '../../Components/inner-footer/Innerfooter'
import { DashboardToSectionDetails } from '../../Components/CommanPages/DashboardToSectionDetails'
import { Modal, Spinner } from 'react-bootstrap'
import { staff_all_products_list } from '../../../Static_Services/Staff_Services'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

export const ProductAll = () => {
  const [businessDetail, setBusinessDetail] = useState([]);
  const navigate = useNavigate();
  const [loder, setLoder] = useState(false);
  const [search_text, setSearch_text] = useState();
  const [search_category, setSearch_category] = useState();
  const [sort_price, setSort_price] = useState();
  const [itemDetail, setItemDetail] = useState([]);
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('w_BusinessDetail')) {
      setBusinessDetail(JSON.parse(localStorage.getItem('w_BusinessDetail')));
    }
  }, [])

  const handleInputChange = (event) => {
    const updatedText = event.target.value.replace(/^\s+/g, '');
    setSearch_text(updatedText);
  };


  const gatAllProductList = async (search_text = '', search_category = '', sort_price = '') => {
    setLoder(true);
    const detail = { "pageid": "1", "business_id": businessDetail.business_id, "search_text": search_text, "search_category": search_category, "sort_price": sort_price }
    const resp = await staff_all_products_list(detail);
    if (resp) {
      setLoder(false);
      setItemDetail(resp.data.data);
    } else {
      setLoder(false);
      setItemDetail([]);
    }
  }

  useEffect(() => {
    if (businessDetail) {
      gatAllProductList();
    }
  }, [businessDetail])

  const getSerch = () => {
    gatAllProductList(search_text);
  }

  const clearAll = () => {
    setSearch_category('');
    setSearch_text('');
    setSort_price('');
    gatAllProductList();
  }
  const getPrice = (event) => {
    setSort_price(event.target.value);
  }
  const redirectUrl = (Id) => {
    navigate('/product', { state: { Id: Id } });
  }

  const filderHandler = () => {
    gatAllProductList(search_text, search_category, sort_price);
    setModalShow(false);
  }
  return (
    <>
      {loder ? <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div> : ""}
      <InnerHeader />
      <div className="body-grey">
        {/* <!-- Product All Section --> */}
        <div class="studio-details">
          <div class="container">
            <DashboardToSectionDetails />
            <div class="pb-2 pt-2">
              <div class="row">
                <div class="col-5 text-left">
                  <h4 class="title black-color text-uppercase"> products </h4>
                </div>
                <div class="col-4">
                  <div class="section-search">
                    <div class="form-group has-search pl-0 pl-md-3">
                      <a href="Javascript:void(0);">
                        <span class="fas fa-search form-control-feedback" onClick={() => getSerch()}
                          style={{ bottom: "7px", top: "unset", pointerEvents: "initial" }}></span>
                      </a>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        value={search_text}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div class="col-1 ">
                  <a href="Javascript:void(0);">
                    <button type="button" class="btn btn-green-grad w-100" onClick={() => clearAll()} >
                      Reset
                    </button></a>
                </div>
                <div class="col-1">
                  <a href="Javascript:void(0);" onClick={() => setModalShow(true)}> <img
                    src="clientassets/img/ic_filter.png" class="img-fluid box-shadow1" style={{
                      height: "46px",
                      width: "56px"
                    }} /></a>
                </div>
                <div class=" col-1 text-right">
                  <button type="button" class="btn btn-blue-grad px-2 text-uppercase" onClick={() => window.history.back()}
                  >Back
                  </button>
                </div>
              </div>
              {/* <!-- end of col-6 --> */}
            </div>
            {/* <!-- End of Heading --> */}
            {itemDetail.length != 0 &&
              <div class="row">
                {itemDetail.map((productlist, p) => (
                  <div class="col-12 col-md-3 mb-3" >
                    <a href="Javascript:void(0);" onClick={() => redirectUrl(productlist.product_id)} >

                      <div class="border-radius6 box-shadow1 white-bg px-3 pt-3 pb-2 detail-products">
                        <img src={productlist.product_images[0] && productlist.product_images[0].image_name ?productlist.product_images[0].image_name :businessDetail.business_img} class="img-fluid w-100" style={{ height: "221px" }} />
                        <div class="row pt-3 pb-1">
                          <div class="col-6 text-left pt-3">
                            <h5 class="black-color heading-titlenew productname"> {productlist.product_name} </h5>
                          </div>
                          <div class="col-6 text-right">
                            <button type="button" class="btn btn-green-grad">
                              {businessDetail.currency} {productlist.product_price} </button>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                ))}
              </div>
            }
          </div>

          {/* <!-- end of row --> */}
          <div class="pb-3">
            {itemDetail.length == 0 &&
              <div class="row  border-radius6 p-3" >
                <div class="col-md-4"></div>
                <div class="col-md-4">
                  <p class="font-awesome black-color font-18 d-inline mx-2 "> No Record Found</p>
                </div>
                <div class="col-md-4"></div>
              </div>
            }
          </div>
        </div>
        {/* <!-- end of container --> */}

        {/* <!-- End of Product All Section --> */}
      </div>
      <Innerfooter />

      <Modal show={modalShow} className="modal fade">
        <div class="modal-body light-bg box-shadow1 border-radius6 px-4 pb-4 px-md-5 pb-md-5">
          <div class="mb-5">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"> <img src="clientassets/img/ic_close.png" class="img-fluid" /> </span>
            </button>
          </div>

          <div class="form-group profile-gender mb-2">
            <p class="brand-color3 text-left font-14 mb-2"> Sort by price </p>
            <div class="profile-gender-button text-left">
              <input type='radio' id='Me'
                checked={sort_price == 'low'}
                value="low" onChange={(e) => setSort_price(e.target.value)} name='radio' />
              <label for='Me'>Low to high</label>
              <input type='radio' id='Family' name='radio' checked={sort_price == 'hign'}
                value="hign" onChange={(e) => setSort_price(e.target.value)}
              />
              <label for='Family'>High to low</label>
            </div>
          </div>
          <div class="form-group px-3">
            <button type="button" data-dismiss="modal" class="btn btn-blue-grad text-uppercase w-100 mt-3"
              onClick={() => filderHandler()}
            > Apply filter </button>
          </div>
        </div>
      </Modal >
    </>
  )
}
