import React from 'react'
import { Row, Col, Container, Spinner } from 'react-bootstrap'
import Sidebar from '../../comman_page/Sidebar'
import { HeaderDashboard } from '../../comman_page/HeaderDashboard'
import { DataTable } from '../../../../../sharedComponents/DataTable'
import { Footer } from '../../../../../public/containers/footer/Footer'
import { cancel_class_list } from '../../../../../Studio-Services/Studio_Services'
import { useEffect } from 'react'
import { useState } from 'react'
import moment from 'moment'



export const Cancel = () =>{

  
  const [loder, setLoder] = useState(true);
  const [tableData , setTableData] = useState([]);

  const columns = [
    { dataField: "Class", text : "Class"},
    { dataField: "Instructor", text: "Instructor" },
    { dataField: "Date", text: "Date" },
    { dataField: "Location", text: "Location" },
    { dataField: "Duration", text: "Duration" },
  ];

  const getList = async () =>{
    const resp = await cancel_class_list();
    if(resp){
      setLoder(false);
      const td = [];
      const tdData = resp.data.data;
    
      if(tdData){
        tdData.map((e,index)=>(
          td.push({
            Class:e.class_name,
            Instructor:e.instructor_name,
            Date:`${moment(e.scheduled_date).format('DD-MMM-YYYY')} -  [ ${moment.unix(e.from_time).format('h:mm A')} - ${moment.unix(e.to_time).format('h:mm A')}  ]`,
            Location:e.location,
            Duration:e.duration
          })
        ))
        setTableData(td);
      }else setTableData([]);
    }
    }
    useEffect(()=>{
      getList();
    },[])
  
  return (
    <>
       {loder ? <div className="MainLoader"><Spinner animation="border" /> </div> : ""}
       <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col>
              <div className="RightSide">
                <HeaderDashboard />
                <section className="addClient innerContent">
                  <div className="AddClientContent">
                    <Container>
                      <h4>CANCEL CLASS</h4>

                      <Row className="mt-3">
                        <Col>
                          <DataTable data={tableData} columns={columns} />
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </section>
                <Footer />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}
