import React from "react";
import { Form } from "react-bootstrap";

export const SharedCheckboxTrue = ({onClick,name,status,value}) => {
  return (
    <>
      <Form.Check 
      type="radio"
      aria-label="radio 1"
      name={name}
      value={value}
      checked={status === "Approve"} 
      onChange={onClick}
      className="CheckedTure"
      
      />
      </>
  );
};
