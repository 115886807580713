import React from 'react'
import './StaffMyAppointmentPayment.css'
import { InnerHeader } from '../../Components/inner-header/InnerHeader1'
import { SubHeader } from '../../Components/sub-header/SubHeader'
import { Innerfooter } from '../../Components/inner-footer/Innerfooter1'
import { useState } from 'react'
import { useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { API_book_services, API_cancel_policy, INST_book_services, INST_get_member_list, ageCalculation, staff_get_member_list } from '../../../Static_Services/Staff_Services'
import { errorAlert, successAlert } from '../../../Static_Services/Alertmsg'
import { DashboardToSectionDetails } from '../../Components/CommanPages/DashboardToSectionDetails'
import { Modal } from 'react-bootstrap'

export const StaffMyAppointmentPayment = () => {
  const [currentUrl, setCurrentUrl] = useState();
  const [businessDetail, setBusinessDetail] = useState();
  const [userInfo, setUserInfo] = useState();
  const [customerInfo, setCustomerInfo] = useState();
  const [completeBookingDetail, setCompleteBookingDetail] = useState();
  const [customerDetail, setCustomerDetail] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const [bookingFor, setBookingFor] = useState('self');
  const [loder, setLoder] = useState(false);
  const [memberDetail, setMemberDetail] = useState([]);
  const [policy_detail, setPolicy_detail] = useState();
  const [family_id, setFamily_id] = useState();
  const [policyModal, setPolicyModal] = useState(false);


  useEffect(() => {
    // let cstInfo = '';
    // let urInfo = '';
    if (location.pathname) {
      setCurrentUrl(location.pathname);
      localStorage.setItem('w_AppointUrl', location.pathname);
    }
    if (JSON.parse(localStorage.getItem('w_BusinessDetail'))) {
      setBusinessDetail(JSON.parse(localStorage.getItem('w_BusinessDetail')));
    }
    if (JSON.parse(localStorage.getItem('w_slectedCustomer'))) {
      setCustomerInfo(JSON.parse(localStorage.getItem('w_slectedCustomer')));
      // cstInfo = JSON.parse(localStorage.getItem('w_slectedCustomer'));
    }
    if (JSON.parse(localStorage.getItem('w_loginDetail'))) {
      setUserInfo(JSON.parse(localStorage.getItem('w_loginDetail')));
      // urInfo = JSON.parse(localStorage.getItem('w_loginDetail'));
     
    }

    if (JSON.parse(localStorage.getItem('w_BookedDeatil'))) {
      setCompleteBookingDetail(JSON.parse(localStorage.getItem('w_BookedDeatil')));
    }

    if (JSON.parse(localStorage.getItem('w_loginDetail')) && JSON.parse(localStorage.getItem('w_loginDetail')).role_id == 3) {
      setCustomerDetail(JSON.parse(localStorage.getItem('w_loginDetail')));
    } else {
      setCustomerDetail(JSON.parse(localStorage.getItem('w_slectedCustomer')));
    }
  }, [])

  const getBookingFor = (event) => {
    setBookingFor(event.target.value);
  }

  const getMemberList = async () => {
    if (memberDetail.length == 0) {
      let today = new Date();
      var currYear = today.getFullYear();
      setLoder(true);
      let detail;
      let resp = '';
      if (userInfo && userInfo.role_id == 3) {
        detail = { "pageid": "1" };
        resp = await staff_get_member_list(detail);
      } else {
        let ids = '';
        if (customerInfo && customerInfo.id) {
          ids = customerInfo.id;
        }
        detail = { "pageid": "1", "customer_id": ids }
        resp = await INST_get_member_list(detail);
      }
      if (resp) {
        let response = resp.data;
        response.data.map((el) => {
          ;
          el.age = ageCalculation(el.dob);
        })
        setMemberDetail(response.data);
        setLoder(false);
      } else {
        setLoder(false);
      }
    }
  }

  const confirmbooking = async () => {

    let familyIDS ='';
    if (bookingFor == 'family') {
      if (!family_id) {
        errorAlert('Please select family member');
        return false;
      }else{
        familyIDS=family_id;
      }
    } else {
      setFamily_id('');
      familyIDS='';
    }

    setLoder(true);
    let resp = '';
    let detail = '';
    if (userInfo && userInfo.role_id == 3) {
      detail = {
        "service_type": "2",
        "service_id": completeBookingDetail.service_id,
        "quantity": 1,
        "grand_total": completeBookingDetail.totalAmount,
        "slot_date": completeBookingDetail.slot_date,
        "slot_time_id": completeBookingDetail.slot_time_id,
        "instructor_id": completeBookingDetail.instructor_id,
        "shift_id": completeBookingDetail.shift_id,
        "start_time_unix": completeBookingDetail.start_time_unix,
        "end_time_unix": completeBookingDetail.end_time_unix,
        "shift_date": completeBookingDetail.shift_date,
        "family_user_id": familyIDS,
        "tax": completeBookingDetail.service_tax,
        "shift_scheduling_id": completeBookingDetail.shift_scheduling_id
      }
      resp = await API_book_services(detail);
    } else {

      detail = {
        "customer": customerInfo.id,
        "service_type": "2",
        "service_id": completeBookingDetail.service_id,
        "quantity": 1,
        "grand_total": completeBookingDetail.totalAmount,
        "slot_date": completeBookingDetail.slot_date,
        "slot_time_id": completeBookingDetail.slot_time_id,
        "instructor_id": completeBookingDetail.instructor_id,
        "shift_id": completeBookingDetail.shift_id,
        "start_time_unix": completeBookingDetail.start_time_unix,
        "end_time_unix": completeBookingDetail.end_time_unix,
        "shift_date": completeBookingDetail.shift_date,
        "family_user_id": familyIDS,
        "tax": completeBookingDetail.service_tax,
        "token": "123",
        "shift_scheduling_id": completeBookingDetail.shift_scheduling_id
      }
      resp = await INST_book_services(detail);
    }
    if (resp) {
      const response = resp.data;
      successAlert(response.message);
      setLoder(false);
      if (userInfo && userInfo.role_id == 3) {
        navigate('/appointments');
      } else {
        navigate('/my-appointment');
      }
    } else {
      setLoder(false);
    }
  }


  const cancelationPoliy = async () => {
    const resp = await API_cancel_policy();
    if (resp) {
      setPolicy_detail(resp.data.data[0]);
    }
  }

  const familydetail = (memeber_id) => {
setFamily_id(memeber_id);
  }

  useEffect(() => {
    if (userInfo) {
      getMemberList();
    }
    cancelationPoliy();
  }, [userInfo])




  return (
    <>
      <InnerHeader />
      <div className="body-grey">

        {/* <!-- My Studio Card Detials-Service Section --> */}
        <div class="studio-details">
          <div class="container">
            <DashboardToSectionDetails />
            <div class="s-d-appoint pb-5">

              <div class="s-d-appoint-block">
                <div class="row">
                  <div class="col-12 col-lg-6 mt-3 mt-lg-0">
                    <div class="s-d-appoint-block-left border-radius6 box-shadow1 white-bg">

                      <div class="px-3 pt-3 border-bottom">
                        <div class="row">
                          <div class="col-8 col-md-6">
                            <h5 class="title black-color font-w-100 text-uppercase font-16 mb-3 pb-2"> customer details </h5>
                          </div>
                        </div>
                        <div calss="row">
                          <div class="form-group profile-gender mb-0 mt-3" >
                            {userInfo && userInfo.role_id == 4 &&
                              <div class="profile-gender-button" >
                                <input type="radio" id="self" name="radio" value="self" checked={bookingFor == 'self'}
                                  onChange={(e) => getBookingFor(e)}
                                />
                                <label for="self"> Self </label>

                                <input type="radio" id="family" name="radio" value="family" checked={bookingFor == 'family'}
                                  onChange={(e) => getBookingFor(e)} onClick={() => getMemberList()}
                                />
                                <label for="family"> Family </label>
                              </div>
                            }
                          </div>
                        </div>
                        {bookingFor && bookingFor == 'self' &&
                          <div class="row" >
                            <div class="col-6 col-md-5 text-left mb-2">
                              <p class="black-color font-w-100 font-14 mb-0"> Name </p>
                              <p class="black-color font-w-100 font-18"> {customerDetail && customerDetail.name} {customerDetail && customerDetail.lastname} </p>
                            </div>

                            <div class="col-6 col-md-3 text-left mb-2 pl-2 pl-md-0">
                              <p class="black-color font-w-100 font-14 mb-0"> Gender </p>
                              <p class="black-color font-w-100 font-18"> {customerDetail && customerDetail.gender} </p>
                            </div>

                            <div class="col-6 col-md-3 text-left mb-2">
                              <p class="black-color font-w-100 font-14 mb-0"> Age </p>
                              <p class="black-color font-w-100 font-18"> {customerDetail && customerDetail.age} years </p>
                            </div>
                          </div>
                        }
                        {bookingFor && bookingFor == 'family' &&
                          <div >
                            {memberDetail.length == 0 &&
                            <div class="row">
                              <div class="text-center">
                                <p class="font-awesome black-color font-18 d-inline mx-2 "> No Members Available</p>
                              </div>
                            </div>
}
                            {memberDetail.length != 0 &&
                            <div>
                              <div class="table-responsive mt-2" style={{height: "180px" }}>
                                <table class="table">
                                  <tbody>
                                    <tr>
                                      <th></th>
                                      <th> Name </th>
                                      <th> Gender </th>
                                      <th> Age </th>
                                    </tr>
                                  </tbody>
                                  {memberDetail.map((members) => (
                                    <tr>
                                      <td>
                                        <input type="radio" name="radio1" checked={family_id == members.memeber_id}
                                          value={members.memeber_id} onChange={() => familydetail(members.memeber_id)}
                                        />
                                      </td>
                                      <td> {members.member_name} </td>
                                      <td>  {members.gender} </td>
                                      <td>  {members.age} years</td>
                                    </tr>
                                  ))}
                                </table>
                              </div>
                            </div>
}
                          </div>
                        }
                      </div>
                      <div class="px-3 pt-3 border-bottom">
                        <div class="row">
                          <div class="col-8 col-md-6">
                            <h5 class="title black-color font-w-100 text-uppercase font-16 mb-3 pb-2"> Appointments details </h5>
                          </div>
                          <div class="col-4 col-md-6 text-right">
                            <Link to="/book-appointment" class="brand-color1 font-14 font-w-600"> Change </Link>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6  text-left mb-2">
                            <p class="black-color font-w-100 font-14 mb-0"> Instructor </p>
                            <p class="black-color font-w-100 font-18"> {completeBookingDetail && completeBookingDetail.instrutor_name} {completeBookingDetail && completeBookingDetail.instrutor_lastname} </p>
                          </div>
                          <div class="col-6  text-left mb-2">
                            <p class="black-color font-w-100 font-14 mb-0"> Duration </p>
                            <p class="black-color font-w-100 font-18">{completeBookingDetail && completeBookingDetail.duration} Min. </p>
                          </div>

                          <div class="col-6  text-left mb-2">
                            <p class="black-color font-w-100 font-14 mb-0"> Date </p>
                            <p class="black-color font-w-100 font-18"> {completeBookingDetail && completeBookingDetail.Converted_date} </p>
                          </div>
                          <div class="col-6  text-left mb-2">
                            <p class="black-color font-w-100 font-14 mb-0"> Time </p>
                            <p class="black-color font-w-100 font-18"> {completeBookingDetail && completeBookingDetail.from_time} - {completeBookingDetail && completeBookingDetail.to_time} </p>
                          </div>
                        </div>
                      </div>

                      <div class="px-3 pt-3 border-bottom">
                        <div class="row">
                          <div class="col-12 text-left mb-2">
                            <h5 class="title black-color font-w-100 text-uppercase font-16 mb-3 pb-2"> service details </h5>
                            <p class="black-color font-w-100 font-14 mb-0"> Service Name :  </p>
                            <p class="black-color font-w-100 font-18"> {completeBookingDetail && completeBookingDetail.service_name} </p>
                          </div>
                          <div class="col-12 text-left mb-2">
                            <p class="black-color font-w-100 font-14 mb-0"> Location </p>
                            {completeBookingDetail && completeBookingDetail.location_url &&
                              <p class="black-color font-w-100 font-18"><Link to={completeBookingDetail && completeBookingDetail.location_url} target="_blank" title={completeBookingDetail && completeBookingDetail.location_url}> {completeBookingDetail && completeBookingDetail.service_location} </Link> </p>}
                            {completeBookingDetail && !completeBookingDetail.location_url &&
                              <p class="black-color font-w-100 font-18" > {completeBookingDetail && completeBookingDetail.service_location}  </p>}

                            <p class="black-color font-w-100 font-14 mb-0"> Web Link </p>
                            {completeBookingDetail && completeBookingDetail.web_link &&
                              <p class="black-color font-w-100 font-18" ><Link to={completeBookingDetail && completeBookingDetail.web_link} target="_blank">
                                <i class="fa fa-link" aria-hidden="true" title={completeBookingDetail && completeBookingDetail.web_link}></i>
                              </Link></p>}
                            {completeBookingDetail && !completeBookingDetail.web_link && <p class="black-color font-w-100 font-18"> N/A</p>}
                          </div>
                        </div >
                      <div class="row">
                        <div class="col-12 text-left mb-2">
                          <h5 class="title black-color font-w-100 text-uppercase font-16 mb-3 pb-2"> service details </h5>
                          <p class="black-color font-w-100 font-14 mb-0"> Type </p>
                          <p class="black-color font-w-100 font-18"> Spa, Foot Massage, Royal Thai Massage, Sore Spa </p>
                        </div>
                      </div>
                      </div >
                    </div >
                  </div >

                  <div class="col-12 col-lg-6 mt-5 mt-lg-0" >
                    <div class="s-d-appoint-block-right border-radius6 box-shadow1 white-bg">
                      <div class="px-3 py-3 text-left light-bg border-t-lr-6">
                        <h5 class="title black-color font-w-100 text-uppercase font-16 mb-0"> payment details </h5>
                      </div>

                      <div class="px-3 pt-4 pb-2 border-bottom">
                        <div class="row mb-2">
                          <div class="col-6 text-left">
                            <p class="black-color font-w-100 font-18"> Appointment Fees: </p>
                          </div>
                          <div class="col-6 text-right">
                            <p class="black-color font-w-100 font-18"> {businessDetail && businessDetail.currency}{completeBookingDetail && completeBookingDetail.service_charge}  </p>
                          </div>
                        </div>


                        <div class="row">
                          <div class="col-6 text-left">
                            <p class="black-color font-w-100 font-18"> Tax 1 </p>
                          </div>
                          <div class="col-6 text-right">
                            <p class="black-color font-w-100 font-18"> {businessDetail && businessDetail.currency}{completeBookingDetail && completeBookingDetail.tax1_rate} </p>
                          </div>
                          <div class="col-6 text-left">
                            <p class="black-color font-w-100 font-18"> Tax 2 </p>
                          </div>
                          <div class="col-6 text-right">
                            <p class="black-color font-w-100 font-18"> {businessDetail && businessDetail.currency}{completeBookingDetail && completeBookingDetail.tax2_rate} </p>
                          </div>
                        </div>
                      </div>

                      <div class="px-3 pt-4 pb-3 border-bottom">
                        <div class="row mb-2">
                          <div class="col-6 text-left">
                            <p class="black-color font-w-100 font-18 mb-0"> Amount Payable </p>
                          </div>
                          <div class="col-6 text-right">
                            <p class="black-color font-w-100 font-18 mb-0"> {businessDetail && businessDetail.currency}{completeBookingDetail && completeBookingDetail.totalAmount}  </p>
                          </div>
                        </div>
                      </div>

                      <div class="px-3 py-4 text-center">
                        <button type="button" class="btn btn-blue-grad w-75 text-uppercase mb-4"
                          onClick={() => confirmbooking()}
                        > Confirm Booking </button>
                        <Link to="/services" class="d-block red-color font-18 mb-0">  <button type="button" class="btn btn-blue-grad w-75 text-uppercase mb-4"> Cancel </button></Link>
                        <a href="javascript:void(0)" data-target="#busLocation" data-toggle="modal" data-backdrop="static" data-keyboard="false" class="d-block  font-18 mb-0" onClick={() => setPolicyModal(true)}> View Cancellation Policy </a>

                      </div>
                    </div>
                  </div >

                </div >
              </div >
            </div >

          </div >
        </div >
        {/* <!-- End of My Studio Card Detials-Service Section --> */}
        < Innerfooter />

        {/* <!-- CHECKOUT MODAL POPUP --> */}
        <Modal show={policyModal} class="modal fade" id="busLocation" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <button type="button" class="close text-right mr-2" data-dismiss="modal" aria-label="Close" >
            <span aria-hidden="true" onClick={() => setPolicyModal(false)}>&times;</span>
          </button>
          <div class="modal-body border-radius6 box-shadow1 p-3 light-bg">
            <div class="text-center">

              <h4 class="title black-color text-uppercase"> cancel policy </h4>
            </div>
            <p>  {policy_detail && policy_detail.discription} </p>
          </div>
        </Modal>

        {/* <!-- END OF CHECKOUT MODAL POPUP --> */}
      </div >
    </>
  )
}
