import React, { useState } from "react";
import { FormControl, InputGroup, ListGroup } from "react-bootstrap";
import { search_customer_list } from "../../../../../Studio-Services/Studio_Services";





const NotificationSearch = ({setIndividual}) => {

  const [options ,setOptions] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleSearchChange = async (e) => {
    const value = e.target.value;
    setSelectedOption([]);
    setSearchValue(value);
    if(value){
    const fData = {
      pageid: "1",
      search_val: value,
    };
    const resp = await search_customer_list(fData);
    if (resp) {
      const op = [];
      resp.data.data.map((e) => {
        op.push({
          id: e.id,
          label: e.name,
        });
      });
      setSearchResults(op);
    }
  }
   
  };

  const handleItemClick = (result) => {
    setIndividual(result.id)
    // When the user clicks on a search result, set it as the selected option
    setSelectedOption(result);
    setSearchValue("");
    setSearchResults([]);
  };

  const handleClearSelection = () => {
    // Clear the selected option and search field
    setSelectedOption(null);
    setSearchValue("");
  };

  return (
    <div>
      <InputGroup>
        <FormControl
          type="text"
          placeholder="Search"
          value={selectedOption ? selectedOption.label : searchValue}
          onChange={handleSearchChange}
          className="SearchSelect"
        />

        {selectedOption && (
          <InputGroup.Text onClick={handleClearSelection}>X</InputGroup.Text>
        )}
      </InputGroup>

      {searchResults.length > 0 && (
        <ListGroup>
          {searchResults.map((result, index) => (
            <ListGroup.Item
              key={index}
              onClick={() => handleItemClick(result)}
              action
            >
              {result.label}
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
    </div>
  );
};

export default NotificationSearch;
