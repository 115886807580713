import React from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { InputField } from './InputField'
import { SharedSelect } from './SharedSelect'
import { MyDatePicker } from './MyDatePicker'
import { SharedButton } from './Button'
import { useEffect } from 'react'
import { useState } from 'react'
import { add_client, get_countries } from '../Studio-Services/Studio_Services'
import logo from "../Images/logo_white_web.png"
import Swal from 'sweetalert2'
import { MyContext } from '../App'
import { useContext } from 'react'
import moment from 'moment'


export const AddClientform = ({ get_clinet_List, setLoder }) => {
  const constext = useContext(MyContext);
  const [country, setCountry] = useState([]);



  const getCountryCode = async () => {
    setLoder(true);
    const resp = await get_countries();
    let code = [];
    if (resp) {
      if (resp.data && resp.data.length > 0) {

        (resp.data).forEach(e => {
          code.push({ value: e.code, label: e.name });
        });
        setLoder(false);
        setCountry(code);
      } else {setCountry([]) ; setLoder(false);}
      setLoder(false);
    }
    setLoder(false);
  }

  
  useEffect(() => {
    getCountryCode();
  }, [])

  const [error, setError] = useState({ fname: "", lname: "", email: "", mobile: "", countycode: "", gender: "", dob: "", });
  const changeHandler = (e) => { const { name } = e.target; setError((pre) => ({ ...pre, [name]: "" })); }

  const gender = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "other", label: "Other" }
  ];

  const submit = async (event) => {
    event.preventDefault();


    const formData = new FormData(event.target); // Create a FormData object from the form
    const formValues = Object.fromEntries(formData.entries());
    let isValid = 1;

    const fname = formValues.fname;
    const lname = formValues.lname;
    const email = formValues.email;
    const mobile = formValues.mobile;
    const country = formValues.countycode;
    const gender = formValues.gender;

    const ref = formValues.refrence;
    if (!fname) { setError((pre) => ({ ...pre, fname: "First Name is required" })); isValid = 0 };
    if (!lname) { setError((pre) => ({ ...pre, lname: "Last name is required" })); isValid = 0 };
    if (!email) { setError((pre) => ({ ...pre, email: "Email is required" })); isValid = 0 };
    if (!mobile) { setError((pre) => ({ ...pre, mobile: "Mobile Number is required" })); isValid = 0 };
    if (!country) { setError((pre) => ({ ...pre, countycode: "Country Code is required" })); isValid = 0 };

    if (isValid === 1) {
      setLoder(true);
      const finalData = new FormData();
      finalData.append('name', fname);
      finalData.append('lastname', lname);
      finalData.append('email', email);
      finalData.append('mobile', mobile);
      finalData.append('country_code', country);
      finalData.append('gender', gender);
      // finalData.append('date_of_birth', dob);
      finalData.append('referred_by', ref);
      finalData.append('discount', '0');


      const resp = await add_client(finalData);
      if (resp) {
        setLoder(false);
        event.target.reset();
        get_clinet_List();
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" alt='' />
        <h4 class="title text-uppercase text-center mt-4 textWhite">
        CONGRATULATIONS
      </h4>
      <p class="text-center my-4 textcolor">
      <small class="textWhite">
     ${resp.data.message} </small></p>
      `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
      setLoder(false);
    }
    setLoder(false);

  }

  return (
    <>
      <Form onSubmit={submit}>
        <Row className='mt-5'>
          <Col>
            <InputField type={"text"} lable={"First Name"} onChange={changeHandler} value="" name={"fname"} error={error.fname} />
          </Col>
          <Col>
            <InputField type={"text"} lable={"Last Name"} onChange={changeHandler} value="" name={"lname"} error={error.lname} />
          </Col>
          <Col>
            <InputField type={"email"} lable={"Email Address"} onChange={changeHandler} value="" name={"email"} error={error.email} />
          </Col>
        </Row>
        <Row>
          <Col>
            <SharedSelect lable={"Country Code"} onChange={() => setError((prev) => ({ ...prev, countycode: "" }))} options={country ? country : ""} name={"countycode"} error={error.countycode} />
          </Col>
          <Col>
            <InputField type="tel" lable="Mobile/Cell" onChange={changeHandler} value="" maxLength={10} name={"mobile"} error={error.mobile} />
          </Col>
          {/* <Col>
          <InputField type="date" lable="Date of birth" max={moment().format('YYYY-MM-DD')} onChange={changeHandler} name={"dob"} error={error.dob} />
          </Col> */}
        </Row>
        <Row>
          <Col>
            <SharedSelect lable={"Gender"} onChange={changeHandler} options={gender} name={"gender"} error={error.gender} />
            {/* <InputField type={"text"} lable="Gender"/> */}
          </Col>
          <Col>
            <InputField type={"test"} onChange={changeHandler} lable="Referred by" value="" name={"refrence"} />
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col>
            <SharedButton type={"submit"} variant={"primary"} title={"Save"} className={"btnform w-100"} />
          </Col>
          <Col>
            <SharedButton variant={"Outline"} title={"Done With New User"} className={"btnform w-100"} href={"/add-people"} />
          </Col>
          <Col></Col>
        </Row>
      </Form>
    </>
  )
}
