import React, { useState } from 'react'
import { InputField } from '../../../sharedComponents/InputField'
import Swal from 'sweetalert2';
import logo from "../../../Images/logo_white_web.png"
import { help_contact } from '../../static-service/StaticServices';

export const HelpForm = () => {

    const [error, setError] = useState(
        {
            name: "",
            phone: "",
            email: "",
            msg: "",
        }
    );

    const subHandler = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const formValue = Object.fromEntries(formData.entries());

        const name = formValue.name;
        const phone = "8888888888";
        const email = formValue.email;
        const msg = formValue.msg;

        let isValid = 1;

        if (!name) { setError((pre) => ({ ...pre, name: "*Required" })); isValid = 2; }


        if (!msg) { setError((pre) => ({ ...pre, msg: "*Required" })); isValid = 5; }

        // if (!phone) {
        //     setError((pre) => ({ ...pre, phone: "*Required" })); isValid = 3;
        // } else if (!/^\d{10}$/.test(formValue.phone)) {
        //     setError((pre) => ({ ...pre, phone: "Phone number must be 10 digits" }));
        //     isValid = 6;
        // }

        // Validate Email
        if (!email) {
            setError((pre) => ({ ...pre, email: "*Required" })); isValid = 4;
        } else if (!/^\S+@\S+\.\S+$/.test(formValue.email)) {
            setError((pre) => ({ ...pre, email: "Invalid email address" }));
            isValid = 7;
        }
  
        if (isValid === 1) {
            const finalData = {
                "name": name,
                "email": email,
                "phone": phone,
                "message": msg
            }
            const resp = await help_contact(finalData);
            e.target.reset();
            if (resp) {
                Swal.fire({
                    html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
                        <h4 class="title text-uppercase text-center mt-4 textWhite">
                    SUCCESS
                    </h4>
                    <p class="text-center my-4 textcolor">
                    <small class="textWhite">
                    ${resp.data.message} </small></p>
                    `,
                    showClass: {
                        popup: 'custom-dialog-container',
                    },
                    customClass: {
                        confirmButton: 'custom-button custom-button-size modalbtn',
                    },
                });
            }
        }



    }
    const errorHanler = (e) => {
        const { name, value } = e.target;
        setError((pre) => ({ ...pre, [name]: "" }));
    }
    return (
        <>
            <form onSubmit={subHandler}>
                <div className="row pb-5">
                    <div className="col-12 col-sm-6">
                        <InputField
                            type={"text"}
                            lable={"Name"}
                            name={"name"}
                            className={"form-control help-form"}
                            error={error.name}
                            onChange={errorHanler}
                        />
                    </div>
                    {/* <!-- Name --> */}
                    {/* <div className="col-12 col-sm-6">
                        <InputField
                            type={"text"}
                            lable={"Phone"}
                            name={"phone"}
                            minLength={10}
                            maxLength={10}
                            className={"form-control help-form"}
                            error={error.phone}
                            onChange={errorHanler}
                        />
                    </div> */}
                    {/* <!-- Phone No. --> */}
                    <div className="col-12 col-sm-6">
                        <InputField
                            type={"email"}
                            lable={"Email"}
                            name={"email"}
                            className={"form-control help-form"}
                            error={error.email}
                            onChange={errorHanler}
                        />
                    </div>
                    {/* <!-- Email --> */}
                    <div className="col-12 col-sm-12 mt-2 mt-md-12">
                        <InputField
                            type={"text"}
                            name={"msg"}
                            lable={"Message"}
                            className={"form-control help-form"}
                            error={error.msg}
                            onChange={errorHanler}
                        />
                    </div>
                    <div className="col-12 col-sm-6 mt-2">
                        <button className="btn btn-blue text-uppercase" type="submit">
                            send message
                        </button>
                    </div>
                </div>
            </form>
        </>
    )
}
