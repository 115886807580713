import React, { useState, useRef, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import * as bootstrap from "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
// import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import moment from 'moment-timezone';

export const ShiftSheduleClalendar = ({ resp,setVisibleRange, setCalendardates,calendardates }) => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const calendarRef = useRef(null);
    const [selectedEvent, setSelectedEvent] = useState([]);
    const navigate = useNavigate();

    const list = () => {
  
        if (resp.length > 0) {
            let Ents = [];
            Ents = resp.map((e, index) => ({
                    id: index,
                    myid: [{ id: e.id }],
                    title: `Instructor: ${e.instructor_name}, | Location: ${e.location_name}`,
                    description: `${moment.unix(e.start_time).format("hh:mm A")} - ${moment.unix(e.end_time).format("hh:mm A")}, || Instructor: ${e.instructor_name}, || Location: ${e.location_name}`,
                    start: moment.unix(e.start_time).format("YYYY-MM-DDTHH:mm:ss"),
                    end: moment.unix(e.end_time).format("YYYY-MM-DDTHH:mm:ss"),
                }));
            setSelectedEvent(Ents);
        } else {
            setSelectedEvent([]);
        }
    };

    useEffect(() => {
        setSelectedEvent([]);
        list();
    }, [resp]);

    const eventMouseEnter = (info) => {
        const description = info.event.extendedProps.description;
        const eventElement = info.el;
        const popover = new bootstrap.Popover(eventElement, {
            //   title: "Description",
            content: description,
            trigger: "hover",
            placement: "top",
        });
        popover.show();
    };

    const eventMouseLeave = (info) => {
        const eventElement = info.el;
        const popover = bootstrap.Popover.getInstance(eventElement);
        if (popover) {
            popover.dispose();
        }
    };

      
  const datesSet = (info) => {
    let cld = { ...calendardates };
    cld.start=moment(info.startStr).format('YYYY-MM-DD');
    cld.end=moment(info.endStr).format('YYYY-MM-DD');
    setCalendardates(cld);

    setVisibleRange({
      start: info.startStr,
      end: info.endStr,
    });
  };

    const eventDidMount = (info) => {
        const description = info.event.extendedProps.description;
        const eventElement = info.el;
        const descriptionElement = document.createElement("div");
        descriptionElement.classList.add("fc-description");
        descriptionElement.textContent = `Description: ${description} `;
        descriptionElement.style.display = "none"; // Set initial display to "none"
        eventElement.appendChild(descriptionElement);

        eventElement.addEventListener("click", () => {
            if (descriptionElement.style.display === "none") {
                descriptionElement.style.display = "block";
            } else {
                descriptionElement.style.display = "none";
            }
        });
    };


    const eventClick = (info) => {
        // Redirect to another page when an event is clicked
        const eventId = info.event.id;

        // Close the popover if it's currently open
        const eventElement = info.el;
        const popover = bootstrap.Popover.getInstance(eventElement);
        if (popover) {
            popover.dispose();
        }
        // const eventDescription = info.event.extendedProps.myid;
        navigate("/book-appointment");
        // history.push(`/ event - details / ${ eventId } `); // Replace with the desired URL to redirect to event details page
    };

    const calendarOptions = {
        plugins: [
            dayGridPlugin,
            timeGridPlugin,
            resourceTimeGridPlugin,
            interactionPlugin,
            listPlugin,
        ],
        schedulerLicenseKey: "GPL-My-Project-Is-Open-Source",
        headerToolbar: {
            left: "prev,today,next",
            center: "title",
            right: "timeGridDay,timeGridWeek,dayGridMonth,listWeek",
        },
        buttonText: {
            prev: "Previous",
            next: "Next",
            today: "Today",
            listWeek: "List",
        },
        eventClick: eventClick,
  
        slotMinTime: "00:00:00",
        // slotMaxTime: "24:00:00",
        slotLabelInterval: { hours: 1 },
        initialView: "listWeek",
        expandRows: true,
        events: selectedEvent,
        height: "80vh",

        datesSet: datesSet,
        // Set the scrollTime and scrollTimeReset options
        scrollTime: "06:00:00",
        scrollTimeReset: ["06:00:00", "18:00:00"],

        eventDidMount: eventDidMount,
        eventMouseEnter: eventMouseEnter, // Add eventMouseEnter callback
        eventMouseLeave: eventMouseLeave, // Add eventMouseLeave callback
    };

    return (
        <>
            <FullCalendar ref={calendarRef} {...calendarOptions} />
        </>
    );
};
