import React from 'react'
import "./NotFound.css"

export const PageNoteFound = () => {

    const goBack = () =>{
        window.history.back();
    }

  return (
    <main className="notfound d-flex align-items-center">

    <div className="container py-5">
        <div className="col-sm-6 col-lg-4">
            <h3 className="title">Page not found</h3>
            <p>Oops! Looks like you followed a bad link. If you think this is a problem with us, please tell us.</p>
            <button className="btn btn-brandbluegrad" type="button" onClick={()=>goBack()}>Go Back</button>
        </div>
    </div>
</main>
  )
}
