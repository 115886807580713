import React from "react";
import { useEffect, useState } from "react";
// import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from "react-bootstrap";
import { SharedDropDown } from "../../../../../sharedComponents/SharedDropDown";
import { SharedSelect } from "../../../../../sharedComponents/SharedSelect";
import { MyDatePicker } from "../../../../../sharedComponents/MyDatePicker";
import { StateBox } from "../../../../../sharedComponents/StateBox";
import { CurrentDate } from "../../../../../sharedComponents/CurrentDate";
import { CurrentTime } from "../../../../../sharedComponents/CurrentTime";
import { TimestampToTime } from "../../../../../sharedComponents/TimestampToTime";
import { getProfile, getClass_list, get_workshop_schedule_calendar, get_service_schedule_calendar } from "../../../../../Studio-Services/Studio_Services";
import { Link } from "react-router-dom";

import { RiContrastDrop2Line } from "react-icons/ri";
import { InputField } from "../../../../../sharedComponents/InputField";
import ReactDatePicker from "react-datepicker";
// import moment from "moment";
import { WorkTime } from "./WorkTime";
// import momentTimezone from "moment-timezone";
import { ServiceTime } from "./ServiceTime";

import moment from 'moment-timezone';


export const HomePageContent = () => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [classRoom, setClassRoom] = useState([]);
  const [workshopList, setWorkshopList] = useState([]);
  const [option, setOption] = useState('1');
  // const [selectedDate, setSelectedDate] = useState(moment().tz(timeZone).format('YYYY-MM-DD'));
  const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));
  const [sectionTitle, setSectionTitle] = useState('');
  const [serviceList, setServiceList] = useState();


  const dropDownOptions = [
    {
      label: "Schedule an Appointment",
      to: '/book-appointment',
      className: "dropDownOption dropDownLink",
    },
    {
      label: "Schedule a New Class",
      to: '/add-schedule-class',
      className: "dropDownOption dropDownLink",
    },
    {
      label: "Schedule a Workshop",
      to: '/add-schedule-workshop',
      className: "dropDownOption dropDownLink",
    },
    {
      label: "Add a Client",
      to: '/list-clients',
      className: "dropDownOption dropDownLink",
    },
    {
      label: "Add a Staff Member",
      to: '/list-instructor',
      className: "dropDownOption dropDownLink",
    },
    {
      label: "Add a Service Type",
      to: '/list-services',
      className: "dropDownOption dropDownLink",
    },
    {
      label: "Add a New Class Type",
      to: '/list-class',
      className: "dropDownOption dropDownLink",
    },
    {
      label: "Add a New Pass Type",
      to: '/list-pass',
      className: "dropDownOption dropDownLink",
    },
    {
      label: "Add a Staff Shift",
      to: '/list-shifts',
      className: "dropDownOption dropDownLink",
    },
  ];

  if (selectedDate === undefined) {
    // const date = moment().tz(timeZone);
    const date = moment();
    // const formattedDate = date.toISOString().substring(0, 10);
    setSelectedDate(date);
  }

  const handleDateChange = async (e) => {
    let rowObject = [];
    if (option === "1") {
      const sDate = moment(selectedDate, "YYYY-MM-DD").unix();
      let setdate = "";
      if (sDate) {
        setdate = sDate;
      } else {
        //   const formattedDate = moment().tz(timeZone).format('YYYY-MM-DD'); // Format the date
        const formattedDate = moment().format('YYYY-MM-DD');
        const unixTimestamp = moment(formattedDate, 'YYYY-MM-DD').unix();
        setdate = unixTimestamp;
      }
      const dates = { scheduled_date: setdate };
      const responce = await getClass_list(dates);
      if (responce) {
        setClassRoom(responce.data.data);
      } else {
        setClassRoom('');
      }
    } else if (option === "2") {
      const opt2date = { start: selectedDate, end: selectedDate }
      const resp = await get_workshop_schedule_calendar(opt2date);
      if (resp) {
        const resp2 = resp.data.data;
        resp2.map((e) => {
          rowObject.push({
            business_id: localStorage.getItem("business_id"),
            data: e,
            from_time: e.start ? e.start : "",
            to_time: e.end ? e.end : "",
            class_name: e.name ? e.name : "",
            location: e.location_name ? e.location_name : "",
            instructor_image: e.instructor_image ? e.instructor_image : "",
            instructor_name: e.instructor_name ? e.instructor_name : "",
            attendence: e.attendence ? e.attendence : 0,
            capacity: e.capacity ? e.capacity : 0,
            attendence_check_in: e.attendence_check_in ? e.attendence_check_in : 0,
            scheduled_date: moment.unix(e.schedule_date).format('YYYY-MM-DD'),
            current_status: e.status,
          })
        })
      }
      setWorkshopList(rowObject);
    } else if (option === "3") {
      const fData = { start: selectedDate, end: selectedDate };
      const resp = await get_service_schedule_calendar(fData);
      if (resp) {
        const resp2 = resp.data.data;
        resp2.map((e) => (
          rowObject.push({
            business_id: localStorage.getItem("business_id"),
            data: e,
            from_time: e.start_time ? e.start_time : "",
            to_time: e.end_time ? e.end_time : "",
            class_name: e.service_name ? e.service_name : "",
            location: e.location_name ? e.location_name : "",
            instructor_image: e.instructor_image ? e.instructor_image : "",
            instructor_name: e.instructor_name ? e.instructor_name : "",
            scheduled_date: moment.unix(e.shift_date).format('DD-MM-YYYY'),
          })
        ))
         rowObject.sort((a, b) => {
        const timeA = a.from_time;
        const timeB = b.from_time;

        if (timeA < timeB) return -1;
        if (timeA > timeB) return 1;
        return 0;
    });
      }
      setServiceList(rowObject);
    }
  };

  useEffect(() => {
    handleDateChange();
  }, [selectedDate, option]);

  const options = [
    { value: "1", label: "Classes" },
    { value: "2", label: "Workshop" },
    { value: "3", label: "Appointment" },
  ];

  const bID = localStorage.getItem('business_id');


  const workshopHandler = (data)=>{
    let num= 0;
    if(data){
      const customer = data.customer_detail;
      num = customer.length;
    }
    return num;
  }


  return (
    <>
      <section className="innerContent">
        <Container>
          <div className="stateBoxPanel">
            <div className="classContent">
              <Row>
                <Col lg={4} md={4} sm={12} >
                  <SharedSelect
                    options={options}
                    value='1'
                    onChange={(e) => setOption(e.target.value)}
                  />
                </Col>
                <Col lg={4} md={4} sm={12} >
                  <InputField
                    type="date"
                    onChange={(e) => { setSelectedDate(e.target.value); }}
                    value={selectedDate}
                    required
                  />
                  {/* <MyDatePicker handleDateChange={handleDateChange} selectedDate={selectedDate} /> */}
                </Col>
                <Col lg={4} md={4} sm={12} >
                  <SharedDropDown
                    variant={"primary"}
                    className={"PlusDropdown"}
                    dropDownOptions={dropDownOptions}
                  />
                </Col>
              </Row>
            </div>
            {option === "1" ?
              <div className="userClassesContent">
                <Row>
                  {classRoom ? classRoom.map((value, index) => (
                    <Col xl={6} lg={6} md={6} sm={12} key={index} className="my-2">
                      <Link className="StateBoxLink" to='/ClassSchedule' state={{ class_id: value.class_id, scheduled_date: value.scheduled_date, schedule_id: value.schedule_id, business_id: bID,fullData:value }} >
                        <StateBox >
                          <Row>
                            <Col xl={4} md={4} xm={12}>
                              <p>{value.scheduled_date}</p>
                            </Col>
                            <Col xl={8} md={8} xm={12}>
                              <p className="text-right">
                                <CurrentTime shduleData={value.scheduled_date} toTime={value.to_time} fromTime={value.from_time} />
                              </p>
                            </Col>
                          </Row>
                          <Row className="mt-2 mb-2">
                            <Col>
                              <p className="classesName">{value.class_name}</p>
                              <p className="roomNo">{value.location}</p>
                            </Col>
                            <Col>
                              <p className="classesTiming"><span>{moment.unix(value.from_time).format('hh:mm A')}-{moment.unix(value.to_time).format('hh:mm A')}</span>
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                              <div className="classesImg">
                                <img src={value.instructor_image} alt="" />
                                <h5 className="DemoName">{value.instructor_name}</h5>
                              </div>
                            </Col>
                            <Col md={8}>
                              <div className="BookingTiming">
                                <Row>
                                  <Col>
                                    <h5 className="Booking">Booking</h5>
                                    <p className="Booking">{value && value.attendence ? value.attendence : "0"}/{value && value.capacity ? value.capacity : ""}</p>
                                  </Col>
                                  <Col>
                                    <h5 className="Booking">Attendance</h5>
                                    <p className="Attendence">{value && value.attendence_check_in ? value.attendence_check_in : "0"}/{value && value.capacity ? value.capacity : "0"}</p>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </StateBox>
                      </Link>
                    </Col>
                  )) : ""}
                </Row>
              </div>
              : option === "2" ?
                <div className="userClassesContent">
                  <Row>
                    {workshopList ? workshopList.map((value, index) => (
                      <Col xl={6} lg={6} md={6} sm={12} key={index} className="my-2">
                        <Link className="StateBoxLink" to='/WorkShopEdit' state={{ data: value, selectedDate: selectedDate }} >
                          <StateBox>
                            <Row>
                              <Col xl={4} md={4} xm={12}>
                                <p>{value.scheduled_date}</p>
                              </Col>
                              {value.current_status != 'Cancel' ?
                                <Col xl={8} md={8} xm={12}>
                                  <p className="text-right">
                                    <WorkTime shduleData={value.scheduled_date} toTime={value.to_time} fromTime={value.from_time} />
                                  </p>
                                </Col>
                                :
                                <Col xl={8} md={8} xm={12}>
                                  <p className="text-right">
                                    <small>Starts in (DD:HH:MM):</small>
                                    <span ><b><p style={{
                                      color: "rgb(255 255 255)",
                                      background: '#ff8e8e',
                                      padding: '5px 10px',
                                    }}>{value.current_status}</p></b></span>
                                  </p>
                                </Col>
                              }
                            </Row>
                            <Row className="mt-2 mb-2">
                              <Col>
                                <p className="classesName">{value.class_name}</p>
                                <p className="roomNo">{value.location}</p>
                              </Col>
                              <Col>
                                <p className="classesTiming"><span>{moment.unix(value.from_time).format('hh:mm A')}-{moment.unix(value.to_time).format('hh:mm A')}</span>
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={4}>
                                <div className="classesImg">
                                  <img src={value.instructor_image} alt="" />
                                  <h5 className="DemoName">{value.instructor_name}</h5>
                                </div>
                              </Col>
                              <Col md={8}>
                              <div className="BookingTiming">
                                {console.log('---------',value)}
                                <Row>
                                  <Col>
                                    <h5 className="Booking">Booking</h5>
                                    <p className="Booking">{ workshopHandler(value.data)} / { value.capacity ? value.capacity : 0}</p>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                            </Row>
                          </StateBox>
                        </Link>
                      </Col>
                    )) : ""}
                  </Row>
                </div>

                // Appointment
                : option === "3" ?
                  <div className="userClassesContent">
                    <Row>
                      {serviceList ? serviceList.map((value, index) => (
                        <Col xl={6} lg={6} md={6} sm={12} key={index} className="my-2">
                          <Link className="StateBoxLink" to='/service-appointment-detail' state={{ data: value }} >
                            <StateBox>
                              <Row>
                                <Col xl={4} md={4} xm={12}>
                                  <p>{value.scheduled_date}</p>
                                </Col>
                                <Col xl={8} md={8} xm={12}>
                                  {value && value.data && value.data.booking_status == 'Cancel' ?
                                    <h4 style={{ color: 'red', textAlign:'right' }}>Cancelled</h4>
                                    :
                                    <p className="text-right" style={{display:'block'}}>
                                      <ServiceTime shduleData={value.scheduled_date} toTime={value.to_time} fromTime={value.from_time} currentStatus={value.data.booking_status}/>
                                    </p>
                                  }



                                </Col>
                              </Row>
                              <Row className="mt-2 mb-2">
                                <Col>
                                  <p className="classesName">{value.class_name}</p>
                                  <p className="roomNo">{value.location}</p>
                                </Col>
                                <Col>
                                  <p className="classesTiming"><span>{moment.unix(value.from_time).format('hh:mm A')}-{moment.unix(value.to_time).format('hh:mm A')}</span>
                                  </p>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={4}>
                                  <div className="classesImg">
                                    <img src={value.instructor_image} alt="" />
                                    <h5 className="DemoName">{value.instructor_name}</h5>
                                  </div>
                                </Col>
                                <Col md={8}>
                                  {/* <div className="BookingTiming"> */}
                                  {/* <Row>
                                  <Col>
                                    <h5 className="Booking">Booking</h5>
                                    <p className="Booking">{value && value.attendence ? value.attendence : "0"}/{value && value.capacity ? value.capacity :""}</p>
                                  </Col>
                                  <Col>
                                    <h5 className="Booking">Attendance</h5>
                                    <p className="Attendence">{value && value.attendence_check_in ? value.attendence_check_in : "0"}/{value && value.capacity ? value.capacity : ""}</p>
                                  </Col>
                                </Row> */}
                                  {/* </div> */}
                                </Col>
                              </Row>
                            </StateBox>
                          </Link>
                        </Col>
                      )) : ""}
                    </Row>
                  </div>
                  : ""}
          </div>
        </Container>
      </section>
    </>
  );
};
