import React from "react";
import { CalenderScheduler } from "../../../../../sharedComponents/CalenderScheduler";
import Sidebar from "../../comman_page/Sidebar";
import { HeaderDashboard } from "../../comman_page/HeaderDashboard";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { Footer } from "../../../../../public/containers/footer/Footer";
import { SharedSelect } from "../../../../../sharedComponents/SharedSelect";
import { SharedButton } from "../../../../../sharedComponents/Button";
import { BsSearch } from "react-icons/bs";
import { GrRefresh } from "react-icons/gr";
import { AiFillPlusCircle } from "react-icons/ai";
import { useState, useEffect } from "react";
import { book_appointment, getInstructorList, get_class_schedule_calendar, get_service_schedule_calendar } from "../../../../../Studio-Services/Studio_Services";

import moment from "moment";
import { AppointmentCalendar } from "./AppointmentCalendar";

export const Appointment = () => {
  const [resp, setResp] = useState([]);
  const [servOption, setServOption] = useState();
  const [instOption, setInstOption] = useState();

  const [finalService, setFinalService] = useState();
  const [finalInstruct, setFinalInstruct] = useState();
  const [loder,setLoder] = useState(false);

  const [visibleRange, setVisibleRange] = useState(null);
  const [calendardates, setCalendardates] = useState({ 'start': moment().startOf('year').format('YYYY-MM-DD'), 'end': moment().endOf('year').format('YYYY-MM-DD') });



  const InstList = async () => {
    const fData = {
      "pageid": 1
    }
    const resp = await getInstructorList(fData);
    if (resp) {
      const instOP = [];
      (resp.data.data).forEach(e => {
        // if (e.instructor_status === "Active" && e.availability_status === "Available") {
        instOP.push({
          value: e.id, label: `${e.name} ${e.lastname}`
        })
        // }
      })
      setInstOption(instOP);

    }
  }

  const bookAppointment = async () => {
    const fData = {
      "pageid": 1
    }
    const resp = await book_appointment(fData);
    if (resp) {
      const serv = [];
      (resp.data).forEach(e => {
        serv.push({
          value: e.id, label: e.service_name
        })
      });

      setServOption(serv);
    }
  }


  const services = async () => {
    // const currentDate = moment();
    // const startOfMonth = currentDate.clone().startOf('month').format('YYYY-MM-DD');
    // const endOfMonth = currentDate.clone().endOf('month').format('YYYY-MM-DD');
    const startOfMonth = moment().startOf('year').format('YYYY-MM-DD');
    const endOfMonth = moment().endOf('year').format('YYYY-MM-DD');
    const passData = {
      start: startOfMonth,
      end: endOfMonth,
    };
    setLoder(true);
    const resp = await get_service_schedule_calendar(passData);
    if (resp) {
      setLoder(false);
      setResp(resp.data.data);
    } else {
      setResp([]);
      setLoder(false);
    }
    setLoder(false);
  };


  const calanderOnClhange = async (dateData) => {
    const chDates = {...dateData};
    const startOfMonth = chDates.start;
    const endOfMonth = chDates.end;
    const passData = {'start': startOfMonth, 'end': endOfMonth,};
    setLoder(true);
    const resp = await get_service_schedule_calendar(passData);
    if (resp) {
      setLoder(false);
      setResp(resp.data.data);
    } else {
      setResp([]);
      setLoder(false);
    }
    setLoder(false);
  };



  useEffect(()=>{
    calanderOnClhange(calendardates);
  },[calendardates])


  useEffect(() => {
    services();
    bookAppointment();
    InstList();
  }, []);

  const setHandler = async () => {
    // const currentDates = moment();
    // const startOfMonthss = currentDates.clone().startOf('month').format('YYYY-MM-DD');
    // const endOfMonthss = currentDates.clone().endOf('month').format('YYYY-MM-DD');

    const startOfMonthss = moment().startOf('year').format('YYYY-MM-DD');
    const endOfMonthss = moment().endOf('year').format('YYYY-MM-DD');

    let fData = '';
    if (finalInstruct && finalService) {
      fData = {
        "start": startOfMonthss,
        "end": endOfMonthss,
        "instructor": [finalInstruct],
        "service": [finalService]
      }
    } else if (finalInstruct) {
      fData = {
        "start": startOfMonthss,
        "end": endOfMonthss,
        "instructor": [finalInstruct],
      }
    } else if (finalService) {
      fData = {
        "start": startOfMonthss,
        "end": endOfMonthss,
        "service": [finalService]
      }
    }


    const resp = await get_service_schedule_calendar(fData);
    if (resp) {
      setResp(resp.data.data);
    } else {
      setResp([]);
    }
  }

  const resetHandler = () => {
    setFinalService();
    setFinalInstruct();
    services();
  }

  return (
    <>
        {loder && <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div> }
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col>
              {/* <div className="RightSide"> */}
              <HeaderDashboard />
              <div className="RightSide">
                <div className="innerContent">
                  <div className="ListYourService">
                    <Container>
                      <Row className="pt-0 pb-0">
                        <Col>
                          <h4>SERVICE APPOINTMENT</h4>
                        </Col>
                        <Col>
                          <SharedSelect
                            onChange={(e) => setFinalService(e.target.value)}
                            value={finalService}
                            options={servOption}
                          />
                        </Col>
                        <Col>
                          <SharedSelect
                            value={finalInstruct}
                            onChange={(e) => setFinalInstruct(e.target.value)}
                            options={instOption}
                          />
                        </Col>
                        <Col>
                          <div className="appointmentPlus">
                            <div className="appointIcons">
                              <SharedButton
                                onClick={setHandler}
                                variant={"outline"}
                                className={"AppointmentIcons bg-white"}
                                iconNode={<BsSearch />}
                              />
                              <SharedButton
                                variant={"outline"}
                                onClick={resetHandler}
                                className={"AppointmentIcons bg-white"}
                                iconNode={<GrRefresh />}
                              />
                            </div>
                            <div className="appointIcons">
                              <SharedButton
                                variant={"danger"}
                                className={"PlusIcon"}
                                iconNode={<AiFillPlusCircle />}
                                href={"/book-appointment"}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <AppointmentCalendar resp={resp} setVisibleRange={setVisibleRange} setCalendardates={setCalendardates} calendardates ={calendardates} />
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </div>

                <Footer />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
