import React, { useEffect } from 'react'
import Swal from 'sweetalert2';
import { SharedButton } from "../../../../sharedComponents/Button";
import { HiPlusCircle } from 'react-icons/hi';
import { AiFillMinusCircle } from 'react-icons/ai';
import { InputField } from "../../../../sharedComponents/InputField";

import logo from "../../../../Images/logo_white_web.png"
import { useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { InputTypeTime } from './InputTypeTime';
import moment from 'moment/moment';
import { business_opening_closing_time, changeTimeStampToUTC, changeTimeStampToUTC24, getTimeSlotList } from '../../../../Studio-Services/Studio_Services';
import { errorAlert } from '../../../../Static_Services/Alertmsg';


export const HoursModal = ({ show, handleClose }) => {


  const initialRows = [
    { "name": 'Monday', "id": "1", "slot_time": [{ "time_slote_from": '', "time_slote_to": '' }] },
    { "name": 'Tuesday', "id": "2", "slot_time": [{ "time_slote_from": '', "time_slote_to": '' }] },
    { "name": 'Wednesday', "id": "3", "slot_time": [{ "time_slote_from": '', "time_slote_to": '' }] },
    { "name": 'Thursday', "id": "4", "slot_time": [{ "time_slote_from": '', "time_slote_to": '' }] },
    { "name": 'Friday', "id": "5", "slot_time": [{ "time_slote_from": '', "time_slote_to": '' }] },
    { "name": 'Saturday', "id": "6", "slot_time": [{ "time_slote_from": '', "time_slote_to": '' }] },
    { "name": 'Sunday', "id": "7", "slot_time": [{ "time_slote_from": '', "time_slote_to": '' }] }
  ];

  const [loder, setLoder] = useState(false);
  const [userDetail, setUserDetail] = useState();
  const [weeekArray, setWeeekArray] = useState(initialRows);
  const [masterWeekArray, setMasterWeekArray] = useState([
    { "name": 'Monday', "day_id": "1", "slot_time": [{ "time_slote_from": '', "time_slote_to": '' }] },
    { "name": 'Tuesday', "day_id": "2", "slot_time": [{ "time_slote_from": '', "time_slote_to": '' }] },
    { "name": 'Wednesday', "day_id": "3", "slot_time": [{ "time_slote_from": '', "time_slote_to": '' }] },
    { "name": 'Thursday', "day_id": "4", "slot_time": [{ "time_slote_from": '', "time_slote_to": '' }] },
    { "name": 'Friday', "day_id": "5", "slot_time": [{ "time_slote_from": '', "time_slote_to": '' }] },
    { "name": 'Saturday', "day_id": "6", "slot_time": [{ "time_slote_from": '', "time_slote_to": '' }] },
    { "name": 'Sunday', "day_id": "7", "slot_time": [{ "time_slote_from": '', "time_slote_to": '' }] }
  ])




  useEffect(() => {
    if (JSON.parse(localStorage.getItem('st_userData'))) {
      setUserDetail(JSON.parse(localStorage.getItem('st_userData')));
    }
  }, [])


  const initialRowsModals = [
    {
      id: 1,
      day: 'Monday',
      hours: [
        {
          openTime: "06:00",
          closeTime: "22:00"
        }
      ]
    },
    {
      id: 2,
      day: 'Tuesday',
      hours: [
        {
          openTime: "06:00",
          closeTime: "22:00"
        }
      ]
    },
    {
      id: 3,
      day: 'Wednesday',
      hours: [
        {
          openTime: "06:00",
          closeTime: "22:00"
        }
      ]
    },
    {
      id: 4,
      day: 'Thursday',
      hours: [
        {
          openTime: "06:00",
          closeTime: "22:00"
        }
      ]
    },
    {
      id: 5,
      day: 'Friday',
      hours: [
        {
          openTime: "06:00",
          closeTime: "22:00"
        }
      ]
    },
    {
      id: 6,
      day: 'Saturday',
      hours: [
        {
          openTime: "06:00",
          closeTime: "22:00"
        }
      ]
    },
    {
      id: 7,
      day: 'Sunday',
      hours: [
        {
          openTime: "06:00",
          closeTime: "22:00"
        }
      ]
    }

  ];

  const [rows, setRows] = useState(initialRows);
  // const [finalValue, setFinalValue] = useState([]);

  const handleAddRow = (index) => {
    const newArray = [...weeekArray];
    newArray[index].slot_time.push({
      time_slote_from: "",
      time_slote_to: ""
    });
    setWeeekArray(newArray);
  };

  const handleDeleteRow = (index, hourIndex) => {
    const newArray = [...weeekArray];
    newArray[index].slot_time.splice(hourIndex, 1);
    setMasterWeekArray(newArray);
  };

  // Time Set Funtion

  const [error, setError] = useState(0);

  const handleTimeChange = (event, index, hourIndex, field) => {
    const { value } = event.target;
    const newArray = [...weeekArray];
    newArray[index].slot_time[hourIndex][field] = value;
    if (field === "time_slote_to") {
      const starTime = newArray[index].slot_time[hourIndex]["time_slote_from"];
      const endTime = newArray[index].slot_time[hourIndex][field];
      // Create moment objects
      const starMoment = moment(starTime, "HH:mm");
      const endMoment = moment(endTime, "HH:mm");
      // Compare the moments
      if (endMoment.isSameOrBefore(starMoment)) {
        errorAlert("Please Select Correct Date");
        newArray[index].slot_time[hourIndex]['time_slote_from']='';
        newArray[index].slot_time[hourIndex][field]='';
        setWeeekArray(newArray);
        setError(1);
        return;
      } else {
        setError("0");
        setWeeekArray(newArray);
      }
    }
  };

  const sameasHandler = (i) => {
    const preIndex = i - 1;
    let newArray = [...weeekArray];
    if (newArray[preIndex]['slot_time'].length == newArray[i]['slot_time'].length) {

      weeekArray[preIndex]['slot_time'].forEach((e, indx) => {
        const newArray = [...weeekArray];
        newArray[i].slot_time[indx].time_slote_from = e.time_slote_from;
        newArray[i].slot_time[indx].time_slote_to = e.time_slote_to;
        setWeeekArray(newArray);
      })
    }
  }

  const submitHandler = async (e) => {
    e.preventDefault();
    const fData = new FormData(e.target);
    const dayIds = fData.getAll('day_id[]');
    const names = fData.getAll('name[]');
    const slot_info = [];
    const toTimeStamp = (timeStr) => {
      let currentDate = moment().format('MM/DD/YYYY');
      let dateTime = "";
      if (timeStr) {
        dateTime = moment(`${currentDate} ${timeStr}`, 'MM/DD/YYYY HH:mm') / 1000;
      } else {
        dateTime='';
      }
      const timestamp = dateTime.valueOf();
      return (timestamp.toString());
    }

    for (let i = 0; i < dayIds.length; i++) {
      const dayId = dayIds[i];
      const name = names[i];
      const dayIndex = weeekArray.findIndex((row) => parseFloat(row.id) === parseFloat(dayId));
   
    
      if (dayIndex !== -1) {
        const timeSlots = weeekArray[dayIndex].slot_time.map((hour) => {
          const timeSlotData = {
            time_slote_from: toTimeStamp(hour.time_slote_from),
            time_slote_to: toTimeStamp(hour.time_slote_to),
          };
          if (hour.time_slot_id) {
            timeSlotData.time_slot_id = hour.time_slot_id;
          }
          return timeSlotData;
        });
        slot_info.push({
          day_id: dayId,
          name: name,
          slot_time: timeSlots,
        });
      }
    }
    const finalData = {
      business_id: localStorage.getItem("business_id"),
      slot_info: slot_info,
      insertStatus: "false"
    }
    if (error == 0) {
      if (slot_info.length > 0) {
        setLoder(true);
        const resp = await business_opening_closing_time(finalData);
        if (resp) {
          setLoder(false);
          timeSlotList();
          Swal.fire({
            html: `
              <img src=${logo} class="img-fluid d-block mx-auto mt-5" />
              <h4 class="title text-uppercase text-center mt-4 textWhite">
                SUCCESS
              </h4>
              <p class="text-center my-4 textcolor">
                <small class="textWhite">
                  ${resp.data.message}
                </small>
              </p>
            `,
            showClass: {
              popup: 'custom-dialog-container',
            },
            customClass: {
              confirmButton: 'custom-button custom-button-size modalbtn',
            },
          })
        } else setLoder(false);
      }
    } else {
      Swal.fire({
        html: `
          <img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
            ERROR
          </h4>
          <p class="text-center my-4 textcolor">
            <small class="textWhite">
              Select Correct Time
            </small>
          </p>
        `,
        showClass: {
          popup: 'custom-dialog-container',
        },
        customClass: {
          confirmButton: 'custom-button custom-button-size modalbtn',
        },
      })
    }
  }


  useEffect(() => {
    if (userDetail && userDetail.business_id && show===true) {
      timeSlotList();
    }
  }, [show])



  const verifyWeekContent = (tempWeekArray, weekData) => {
    const week_name = weekData.name;
    for (var key in tempWeekArray) {
      const tempWeekName = tempWeekArray[key].name;
      if (week_name === tempWeekName) {
        return tempWeekArray[key];
      }
    }
    weekData.id = weekData.day_id;
    return weekData;
  }

  const changeDateFormat = (objectData, type = 1) => {
    var tempWeekArray = [];
    for (const key of Object.keys(objectData)) {
      const obj = objectData[key];
      let tempObj = {};
      tempObj.business_id = obj.business_id;
      tempObj.id = obj.id;
      tempObj.name = obj.name;

      const slot_time = obj.slot_time;
      let tempArray = [];
      for (const sol of Object.keys(slot_time)) {
        const objSlot = slot_time[sol];
        let tempObjChild = {};
        tempObjChild.business_id = objSlot.business_id;
        tempObjChild.time_slote_from = changeTimeStampToUTC24(objSlot.time_slote_from);
        tempObjChild.time_slote_to = changeTimeStampToUTC24(objSlot.time_slote_to);
        tempObjChild.time_slot_id = objSlot.time_slot_id;
        tempArray.push(tempObjChild);
      }
      tempObj.slot_time = tempArray;
      tempWeekArray.push(tempObj);
    }

    if (tempWeekArray.length !== 0) {
      const tempArray = [];
      for (var key in masterWeekArray) {
        const weekData = masterWeekArray[key];
        const resp = verifyWeekContent(tempWeekArray, weekData);
        tempArray.push(resp);
      }
      setWeeekArray(tempArray);
    }
  }

  const timeSlotList = async () => {
    setLoder(true);
    const data = {
      'business_id': userDetail.business_id,
    }
    const resp = await getTimeSlotList(data);
    if (resp) {
      setError(0);
      changeDateFormat(resp.data.data)
      setLoder(false);
    }
    setLoder(false);
  }

  const onOff = (e, ind) => {
    const value = e.target.checked;
    let newArray = [...weeekArray]
    if(value){
      newArray[ind].slot_time=[{time_slote_from:"06:00",time_slote_to:"22:00"}];
      setWeeekArray(newArray);
  }else{
    newArray[ind].slot_time=[{time_slote_from:'',time_slote_to:''}];
    setWeeekArray(newArray);
  }

  }

  return (
    <>

      {loder ?
        <div className='MainLoader'>
          <Spinner animation="border" role="status" className='Loader'>
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
        : ""}
      <Modal show={show} className="SubcriptionPlan custom-modal-payment">
        <Form autoComplete='off' onSubmit={submitHandler}>
          <Modal.Header onClick={handleClose} closeButton className="p-0 m-0">
            <Modal.Title className="p-0 m-0">HOURS OF OPERATION</Modal.Title>
          </Modal.Header>

          <Modal.Body className="subsriptionCardPlan custom-modal-body p-4 relative" >
            {weeekArray && weeekArray.length > 0 && weeekArray.map((row, index) => (
              <Row className='border-bottom mt-3' key={row.id}>
                <Row>
                  <Col lg={2}>
                    <p><b>{row.name}</b>
                    </p>
                  </Col>
                  <Col lg={3}>
                    <div class="form-check form-switch">
                      <input class="form-check-input"
                      type="checkbox" id="flexSwitchCheckChecked"
                      checked={row && row.slot_time && row.slot_time[0].time_slote_from || row.slot_time[0].time_slote_to}
                      onChange={(e) => onOff(e, index)} />
                      {row && row.slot_time && row.slot_time[0].time_slote_from || row.slot_time[0].time_slote_to ?
                        <label class="form-check-label" for="flexSwitchCheckChecked" style={{ color: 'green', fontWeight: '500' }}>Open</label>
                        :
                        <label class="form-check-label" for="flexSwitchCheckChecked" style={{ color: 'red', fontWeight: '500' }}>Close</label>
                      }
                    </div>
                  </Col>
                  <Col md={7}>
                    <input type='hidden' name='day_id[]' value={row.id} />
                    <input type='hidden' name="name[]" value={row.name} />
                  </Col>
                </Row>
                <Row>
                  {index != 0 ?
                    <h6 className='align-right' style={{ fontSize: "14px" }}><span onClick={() => sameasHandler(index)} style={{ cursor: "pointer" }}>Same As Above</span></h6>
                    : ""}
                </Row>
                {row && row.slot_time && row.slot_time.length > 0 && row.slot_time.map((e, ind) => (
                  <React.Fragment key={ind}> {/* Use React.Fragment to avoid adding additional div elements */}
                    <Row className='mb-2'>
                      <Col className='mt-4' md={2}>Slot {ind + 1}</Col>
                      <Col md={4}>
                        <InputTypeTime
                          label={'Open'}
                          className="form-control mycolor"
                          type={'time'}
                          name={`time_slote_from[${index}][${ind}]`} // Use array notation for the name
                          value={e.time_slote_from}
                          onChange={(event) => handleTimeChange(event, index, ind, 'time_slote_from')} // Add an onChange event handler
                        />
                      </Col>
                      <Col md={4} >

                        <InputTypeTime
                          label={'Close'}
                          type={'time'}
                          className="form-control mycolor"
                          error={error.closeTime}
                          name={`time_slote_to[${index}][${ind}]`} // Use array notation for the name
                          value={e.time_slote_to} // Use the closeTime value from the array
                          onChange={(event) => handleTimeChange(event, index, ind, 'time_slote_to')} // Add an onChange event handler
                        />
                      </Col>
                      <Col md={2} className='text-center'>
                        <Row style={{ justifyContent: "center", padding: "0px" }}>
                          {row.slot_time.length > 1 ?
                            <>
                              <Col md={6}>
                                <SharedButton
                                  type={"button"}
                                  iconNode={<AiFillMinusCircle />}
                                  className={'HoursModalMinus'}
                                  onClick={() => handleDeleteRow(index, ind)}
                                />
                              </Col>
                              <Col md={6}>
                                {ind > 0 && (
                                  <SharedButton
                                    type={"button"}
                                    iconNode={<HiPlusCircle />}
                                    className={'HoursModalPlus'}
                                    onClick={() => handleAddRow(index)}
                                  />
                                )}
                              </Col>
                            </>
                            :
                            <Col md={6}>
                              <SharedButton
                                type={"button"}
                                iconNode={<HiPlusCircle />}
                                className={'HoursModalPlus'}
                                onClick={() => handleAddRow(index)}
                              />
                            </Col>}
                          {/* {ind > 0 && (
                            <Col md={6}>
                              <SharedButton
                                type={"button"}
                                iconNode={<AiFillMinusCircle />}
                                className={'HoursModalMinus'}
                                onClick={() => handleDeleteRow(index, ind)}
                              />
                            </Col>
                          )} */}
                          {/* <Col md={6}>
                            <SharedButton
                              type={"button"}
                              iconNode={<HiPlusCircle />}
                              className={'HoursModalPlus'}
                              onClick={() => handleAddRow(index)}
                            />
                          </Col> */}
                        </Row>
                      </Col>
                    </Row>
                  </React.Fragment>
                ))}
              </Row>
            ))}
          </Modal.Body>
          <Modal.Footer>
            <Button style={{width:'45%' , background:'#218838'}} className='m-auto' type='submit' variant="primary">Update</Button>
            <Button style={{width:'45%', background:'#6C7573'}} onClick={timeSlotList} className='m-auto'  type='button' variant="primary">Reset</Button>
          </Modal.Footer>
          <Button style={{width:'100%'}} onClick={handleClose} className='m-auto btn btn-warning'  type='button' variant="primary" >Done With Hours of Operation</Button>
        </Form>
      </Modal>
    </>
  )
}
