import React from 'react'
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import { client_list } from '../../../../../Studio-Services/Studio_Services';
import { EditClientForm } from '../../../../../sharedComponents/EditClientForm';
import Sidebar from "../../comman_page/Sidebar";
import { HeaderDashboard } from '../../comman_page/HeaderDashboard';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { Footer } from '../../../../../public/containers/footer/Footer';

import { SharedButton } from '../../../../../sharedComponents/Button';


export const EditClinet = () => {
  const [value, setValue] = useState();

  const location = useLocation();

  const get_clinet_List = async () => {
    const data = { client_id: location.state.id, pageid: 1 }
    const resp = await client_list(data);
    if (resp) {
      setValue(resp.data);
      
    }
  }

  useEffect(() => {
    get_clinet_List();
  }, []);

  return (
    <>
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col className="p-0 RightCol">
              <div className="RightSide">
                <HeaderDashboard />
                <section className="addClient">
                  <Container className="pt-5">
                    <h4 className='mt-5 pt-4 pb-3'>Edit  Client Detail</h4>
                    <div className='AddClientForm AddClientContent'>
                      <EditClientForm value={value} />
                    </div>
                  </Container>
                </section>
                <Footer />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>

  )
}
