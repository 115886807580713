import React, { useEffect, useState } from "react";
import Sidebar from "../../comman_page/Sidebar";
import { HeaderDashboard } from "../../comman_page/HeaderDashboard";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { Footer } from "../../../../../public/containers/footer/Footer";
import { useLocation } from "react-router-dom";
import { SharedButton } from "../../../../../sharedComponents/Button";
import { AiFillEdit } from "react-icons/ai";
import moment from "moment";
import { taxCalculator } from "../../../../../sharedComponents/MyHelperFunction";

export const TransactionDetailReportFaile = () => {
    const location = useLocation();
    const [loder, setLoder] = useState(false);

    const [pretrData, setPretrData] = useState([]);

    const [trData, setTrData] = useState([]);
    const [fullAm, setFullAm] = useState();




    const getReport = async (data) => {
        setLoder(true);       
        let array = [];
        let full_amount = 0;
        if (data && data.length > 0) {
            data.forEach(e => {
                let bs = parseFloat(e.amount).toFixed(2);
                let ds = parseFloat(e.discount).toFixed(2);
                let tx1 = 0;
                let tx2 = 0;
                let tt_tex = 0;
                let fnl = 0;
                let pre = 0;

                tx1 = taxCalculator(e.amount, e.tax1_rate);
                tx2 = taxCalculator(e.amount, e.tax2_rate);
                tt_tex = parseFloat(tx1) + parseFloat(tx2);
                pre = parseFloat(e.amount);
                // + parseFloat(tt_tex);
                fnl = parseFloat(pre).toFixed(2);


                full_amount += parseFloat(fnl);
                array.push({
                    subtotal: fnl,
                    datetime: moment.unix(e.create_dt).format('DD-MMM-YYYY'),
                    client: e.name + ' ' + e.lastname,
                    method: "Credit/Debit",
                    transaction: e.transaction_id,
                    item: e.item_name,
                    amount: "$ " + parseFloat(fnl).toFixed(2) || 0,                  
                   
                })
            });
            setPretrData(array);
            setTrData(array);
            setFullAm(parseFloat(full_amount).toFixed(2));
            setLoder(false);
        } else { setTrData([]); }
        setLoder(false);
    }



    useEffect(() => {
        if (location && location.state) { 
            getReport(location.state)
         }
    }, [location.state])




    return (
        <>
            {loder && <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> </Spinner> </div>}
            <div className="MainDashboard">
                <div className="MainLayout">
                    <Row>
                        <Sidebar />
                        <Col>
                            <div className="RightSide">
                                <HeaderDashboard />
                                <section className="addClient innerContent">
                                    <div className="AddClientContent">
                                        <Container>
                                            <h4 className="mb-4">Failed Transaction Detail Report</h4>
                                            <Row>
                                                  <Col className="text-right">
                                                    <SharedButton
                                                        title={"Back"}
                                                        className={"backBtn"}
                                                        type={"button"}
                                                        onClick={() => {
                                                            window.history.back();
                                                        }}
                                                    />
                                                </Col></Row>
                                            <Row className="mt-3">
                                                <Col>
                                                    <div className="row transactionReport w-100">
                                                        <table className="table table-striped table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th>Date/Time</th>
                                                                    <th>Client Name</th>
                                                                    <th>Method</th>
                                                                    <th>Transaction</th>
                                                                    <th>Item</th>
                                                                    <th>Amount</th>
                                                                    {/* <th>Details</th> */}
                                                                </tr>
                                                            </thead>
                                                            <tbody style={{ fontSize: '14px' }}>
                                                                {trData && trData.length > 0 && trData.map((row, index) => <tr key={index}>
                                                                    <td>{row.datetime}</td>
                                                                    <td>{row.client}</td>
                                                                    <td>{row.method}</td>
                                                                    <td>{row.transaction}</td>
                                                                    <td>{row.item}</td>
                                                                    <td>{row.amount}</td>
                                                                    {/* <td>{row.detail}</td> */}
                                                                </tr>)}
                                                                {trData && trData.length > 0 &&
                                                                    <tr className='highlighted highlighted-my'>
                                                                        <td>Total</td>
                                                                        <td colspan="4"></td>
                                                                        <td colspan="2" style={{ width: 'auto', whiteSpace: 'nowrap' }}>$ {fullAm}</td>
                                                                    </tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                        {trData && trData.length <= 0 && (<p className="text-center mt-4">No Data Available</p>)}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                </section>
                                <Footer />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
};
