import React from "react";
import { Card, Col, Row } from "react-bootstrap";

export const ProductCard = ({prodetail}) => {

  return (
    <>
      <Row>
        {prodetail ?
         prodetail.map((value, index) => (
          <Col md={4} key={index}>
            <Card className="ProductCardBox" key={index}>
              <Card.Body>
                <Card.Title>{value.ProductHeading}</Card.Title>
                <Card.Text>
                  <p className="m-0">{value.ProductPrice}</p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        )) : ""}
      </Row>
    </>
  );
};
