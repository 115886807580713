import React, { useState } from 'react'
import { StaticsHeader } from '../../Components/header/StaticsHeader11'
import { Link, useNavigate } from 'react-router-dom'
import { IoMdLogIn } from 'react-icons/io'
import { login } from '../../../Static_Services/StaticServices'
import { successAlert } from '../../../Static_Services/Alertmsg'
import { Spinner } from 'react-bootstrap'
import { useEffect } from 'react'
import CryptoJS from 'crypto-js';


export const ClientLogin = () => {

  const [value, setValue] = useState({
    email: localStorage.getItem('saveGmail'),
    password: localStorage.getItem('savePassword')
  })
  const [error, setError] = useState({
    email: '',
    password: ''
  })

  const [passShow, setPassShow] = useState(false);
  const [clientremembercheck, setClientremembercheck] = useState(false);
  const navigate = useNavigate();
  const [loder, setLoder] = useState(false);


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'email') {
      const emailValue = value.replace(/^\s+/g, '');
      setValue((pre) => ({ ...pre, [name]: value }));
      setError((pre) => ({ ...pre, email: '' }))
    }

    if (name === 'password') {
      const emailValue = value.replace(/^\s+/g, '');
      setValue((pre) => ({ ...pre, [name]: value }));
      setError((pre) => ({ ...pre, email: '' }))
    }
  };

  useEffect(() => {
    if (localStorage.getItem('checked')) {
      setClientremembercheck(localStorage.getItem('checked'));
    }
    if (localStorage.getItem('saveGmail')) { setValue((pre) => ({ ...pre, email: localStorage.getItem('saveGmail') })) }
    if (localStorage.getItem('savePassword')) { setValue((pre) => ({ ...pre, password: localStorage.getItem('savePassword') })) }

  }, []);


  useEffect(() => {
    if (window.location && window.location.search) {
      let secretKey = "warriorsms.com";
      var queryString = window.location.search;
      queryString = queryString.substring(1);
      var params = queryString.split('&');
      var dataReceived = {};

      params.forEach(function (param) {
        var pair = param.split('=');
        var key = decodeURIComponent(pair[0]);
        var value = decodeURIComponent(pair[1]);
        dataReceived[key] = value;
      });

      let bytes1 = CryptoJS.AES.decrypt(dataReceived.key1, secretKey);
      let bytes2 = CryptoJS.AES.decrypt(dataReceived.key2, secretKey);
      const gmail = bytes1.toString(CryptoJS.enc.Utf8);
      const password = bytes2.toString(CryptoJS.enc.Utf8);
      if (gmail && password) {
        webLogin(gmail, password);
      }
    } else return;
  }, [])





  const checkedHandler = (e) => {
    const isChacked = e.target.checked;
    if (isChacked === true) {
      setClientremembercheck(true);
    } else {
      setClientremembercheck(false);
    }
  }


  const webLogin = async (data1, data2) => {

    const gmail = data1;
    const pass = data2;

    setLoder(true);
    const detail = {
      email: gmail,
      password: pass,
      device_token: '',
      device_type: 'Browser',
      role: '3'
    };
    const resp = await login(detail);
    if (resp) {
      if (clientremembercheck) {
        localStorage.setItem('saveGmail', gmail);
        localStorage.setItem('savePassword', pass);
        localStorage.setItem('checked', true);
      } else {
        localStorage.setItem('saveGmail', '');
        localStorage.setItem('savePassword', '');
        localStorage.setItem('checked', false);
      }
      const response = resp.data;
      localStorage.setItem('w_loginDetail', JSON.stringify(response.data));
      localStorage.setItem("userDetail", JSON.stringify(response.data));
      localStorage.setItem('w_parentId', JSON.stringify(response.data.id));
      localStorage.setItem('w_MemberDetail', JSON.stringify(response.data.family_member));
      localStorage.setItem('w_ParentDetail', JSON.stringify(response.data));

      if (response.data.address) {
        if (response.data.isPurchased == 1) {
          navigate('/studio');
        } else {
          navigate('/my-home');
        }
      } else {
        navigate('/Profile');
      }
      setLoder(false);
      successAlert(response.message);
    } else setLoder(false);
    setLoder(false);
  }



  const onlogin = async (e) => {
    e.preventDefault();

    const gmail = value.email;
    const pass = value.password;
    let isValid = 1;
    // const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    // const passwordPattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (!gmail) {
      setError((pre) => ({ ...pre, email: 'Email is required' }));
      isValid = 2;
    } else if (gmail && !emailPattern.test(gmail)) {
      setError((pre) => ({ ...pre, email: 'Email must be a valid email address' }));
      isValid = 3;
    }

    if (!pass) {
      setError((pre) => ({ ...pre, password: 'Password is required' }));
      isValid = 4;
    }
    //  else if (pass && !passwordPattern.test(pass)) {
    //   setError((pre) => ({ ...pre, password: 'Password length must be  8 with at least one special character, one capital letter, one small letter and one number.' }));
    //   isValid = 5;
    // }



    if (isValid === 1) {
      setLoder(true);
      const detail = {
        email: gmail,
        password: pass,
        device_token: '',
        device_type: 'Browser',
        role: '3'
      };
      const resp = await login(detail);
      if (resp) {
        if (clientremembercheck) {
          localStorage.setItem('saveGmail', gmail);
          localStorage.setItem('savePassword', pass);
          localStorage.setItem('checked', true);
        } else {
          localStorage.setItem('saveGmail', '');
          localStorage.setItem('savePassword', '');
          localStorage.setItem('checked', false);
        }
        const response = resp.data;
        localStorage.setItem('w_loginDetail', JSON.stringify(response.data));
        localStorage.setItem("userDetail", JSON.stringify(response.data));
        localStorage.setItem('w_parentId', JSON.stringify(response.data.id));
        localStorage.setItem('w_MemberDetail', JSON.stringify(response.data.family_member));
        localStorage.setItem('w_ParentDetail', JSON.stringify(response.data));

        if (response.data.address) {
          if (response.data.isPurchased == 1) {
            navigate('/studio');
          } else {
            navigate('/my-home');
          }
        } else {
          navigate('/Profile');
        }
        setLoder(false);
        successAlert(response.message);
      } else setLoder(false);
      setLoder(false);

    }
  }
  return (
    <>
      {loder ? <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div> : ""}
      <StaticsHeader />
      <div className="light-bg2">

        <div class="background-before">
          <div class="d-flex align-items-center justify-content-center login-height p-4 p-sm-0">
            <div class="align-item-center text-center wow animated slideInDown">
              <div class="login-box-owner white-bg box-shadow1 border-radius10 py-4 px-3">

                <div class="login-header text-center mt-4 px-5">
                  <h3 class="title dark-color mb-4"> Welcome Back!! </h3>
                  <p class="brand-color3 px-4 font-18"> Please login to check your <br /> details... </p>
                </div>

                <div class="logn-form mb-2">
                  <form onSubmit={onlogin}>
                    <div class="form-group">
                      <p class="brand-color3 text-left font-14 mb-2"> Email </p>
                      <input class="form-control-1 w-100" placeholder="carl.oilver@gmail.com" formControlName="email" name='email' value={value.email} onChange={handleInputChange} />
                      <small className='text-danger text-left'>{error.email}</small>
                    </div>
                    <div class="form-group mt-3 mb-1">
                      <p class="brand-color3 text-left font-14 mb-2"> Password </p>
                      <input id="password-field" type={passShow === true ? 'text' : 'password'} value={value.password} class="form-control-1 w-100" onChange={handleInputChange} name='password' />
                      <span
                        onClick={() => setPassShow(!passShow)}
                      ><img src="clientassets/img/input_eye.png" class="fa fa-fw fa-eye field-icon toggle-password" toggle="#password-field" /></span>
                      <small className='text-danger text-left'>{error.password}</small>
                    </div>
                    <div class="d-flex my-3" style={{ justifyContent: 'space-between' }}>
                      <div>
                        <input type="checkbox" checked={clientremembercheck} onChange={(e) => checkedHandler(e)} /> <span class="ml-2 mr-5">Remember Me </span>
                      </div>
                      <div>
                        <Link to="/forgot" class="brand-color1 font-12 ml-5 header-css"> Forgot Password?</Link>
                      </div>
                    </div>
                    <div class="py-3 m-3">
                      <button class="btn btn-blue-grad text-uppercase w-100" type="submit">  login </button>
                    </div>
                    <div class="text-center">
                      <p class="font-18 brand-color3"> Don't have an account? <span> <Link to="/client-registration" class="brand-color1 header-css"> Sign up </Link></span></p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
