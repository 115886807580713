import React, { useEffect, useState } from "react";
import Sidebar from "../../comman_page/Sidebar";
import { HeaderDashboard } from "../../comman_page/HeaderDashboard";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { Footer } from "antd/es/layout/layout";
import { Cart } from "../../../../../client-staff/Pages/cart/MyCart";
import SharedTabs from "../../../../../sharedComponents/SharedTabs";
import SaveCardDetail from "../../../../../sharedComponents/SaveCardDetail";
import { PaymentDetailForm } from "../../../../../sharedComponents/PaymentDetailForm";
import { DebitCreditCard } from "../../../../../sharedComponents/DebitCreditCard";
import { useLocation } from "react-router";

export const Payment = () => {

  const location = useLocation();
  const [allData, setAllData] = useState();
  const [clientId,setClientId] = useState();
  const [loder,setLoder] = useState(false);

  useEffect(() => {
    if (location && location.state && location.state.data) {
      setAllData(location.state.data);
      setClientId(location.state.client_id);
    }
  }, [location])

  

  const tabs = [
    {
      eventKey: "CardOnFile",
      title: "Card On File",
      content: <SaveCardDetail data={allData} clientId={clientId} setLoder={setLoder}/>,
    },

    {
      eventKey: "Others",
      title: "Others",
      content: <PaymentDetailForm data={allData} clientIdsss={clientId} setLoder={setLoder}/>,
    },
    {
      eventKey: "DebitCreditCard",
      title: "Debit/Credit Card",
      content: <DebitCreditCard data={allData} clientId={clientId} setLoder={setLoder} />,
    },
  ];
  

  return (
    <>
     {loder && <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> </Spinner> </div>}
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col>
              <div className="RightSide">
                <HeaderDashboard />
                <section className="addClient innerContent">
                  <h3 className="pl-4 m-2">Payment</h3>
                  <div className="AddClientContent">
                    <Container>
                      <Row className="mt-3">
                        <Col></Col>
                        <Col lg={8}>
                          <div className="s-d-appoint-block-right border-radius6 box-shadow1 white-bg">
                            <div className="px-3 py-3 text-left light-bg border-t-lr-6">
                              <h5 className="title black-color font-w-100 text-uppercase font-16 mb-0">
                                payment details
                              </h5>
                            </div>
                            {/* <!-- top heading --> */}
                            <div className="AmountPayable">
                              <Row className="mb-2">
                                <Col>
                                  <h6 className="black-color font-w-100 font-18">
                                    Amount
                                  </h6>
                                </Col>
                                <Col>
                                  <h6 className="black-color font-w-100 font-18 text-right">
                                    $ {parseFloat(allData && allData.total_amount ? allData.total_amount : "0").toFixed(2)}
                                  </h6>
                                </Col>
                              </Row>
                              <Row className="mb-2">
                                <Col>
                                  <h6 className="black-color font-w-100 font-18">
                                    Total Discount
                                  </h6>
                                </Col>
                                <Col>
                                  <h6 className="black-color font-w-100 font-18 text-right">
                                    - $ {parseFloat(allData && allData.total_discount ? allData.total_discount :"0").toFixed(2)}
                                  </h6>
                                </Col>
                              </Row>
                              <Row className="mb-2">
                                <Col>
                                  <h6 className="black-color font-w-100 font-18">
                                    Tax
                                  </h6>
                                </Col>
                                <Col>
                                  <h6 className="black-color font-w-100 font-18 text-right">
                                    $ {parseFloat(allData && allData.tax ? allData.tax : "0").toFixed(2)}
                                  </h6>
                                </Col>
                              </Row>
                              {/* <!-- end of Appointment Fees: --> */}

                              <div className="px-3 pt-4 pb-3 border-top border-bottom">
                                <Row className="mb-2">
                                  <Col className="text-left">
                                    <h6 className="title black-color font-w-100 font-18 mb-0">
                                      Amount Payable
                                    </h6>
                                  </Col>
                                  <Col className="text-right">
                                    <h6 className="title black-color font-w-100 font-18 mb-0">
                                      $ {parseFloat(allData && allData.grand_total ? allData.grand_total : "0").toFixed(2)}
                                    </h6>
                                  </Col>
                                </Row>
                              </div>
                              <div className="PaymentTabs">
                                <Row className="my-4">
                                  <Col>
                                    <SharedTabs tabs={tabs} />
                                  </Col>
                                </Row>
                              </div>
                            </div>
                            {/* <!-- end of Amount Payable --> */}
                          </div>
                        </Col>
                        <Col></Col>
                      </Row>
                    </Container>
                  </div>
                </section>
                <Footer />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
