import React from "react";
import { Link } from "react-router-dom";

export const Footer = () => {
    return (
        <>
            <footer className="footer py-3 bg-white clearfix signin-footer">
                <div className="container">
                    <div className="d-flex flex-wrap align-items-center footer-inner pb-1">
                        <div className="col-lg-7 pl-0">
                            <ul className="d-flex footerlinks justify-content-center justify-content-lg-start flex-wrap">
                                <li>
                                    <Link to="/about-us" className="header-css" style={{ textDecoration: 'none' }}> About us </Link>
                                </li>
                                <li>
                                    <Link to="/privacy-policy" className="header-css" style={{ textDecoration: 'none' }}> Privacy Policy </Link>
                                </li>
                                <li>
                                    <Link to="/terms-conditions" className="header-css" style={{ textDecoration: 'none' }}> Terms & Conditions </Link>
                                </li>
                                <li>
                                    {/* <!-- <Link to="/faq" className="header-css"> FAQ </Link> --> */}
                                    <Link to="https://www.youtube.com/channel/UCEKl3ls1vXLmOZXCiAYsq-g" target="_blank" style={{ textDecoration: 'none' }} className="header-css"> FAQ </Link>
                                </li>
                                {/* <!-- <li> <Link to="/help"> Help </Link> </li> --> */}
                                {/* <li>
                                    <Link to="/contact-us" className="header-css" style={{ textDecoration: 'none' }}> Help </Link>
                                </li> */}
                                <li>
                                    <Link to="/contact-us" className="header-css" style={{ textDecoration: 'none' }}> Contact us </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-5 pr-lg-0 mt-3 mt-lg-0">
                            <ul className="d-flex footer-social justify-content-center justify-content-lg-end">
                                <li>
                                    <Link
                                        to="https://www.facebook.com/WarriorStudioManagementSoftware"
                                        target="_blank" className="SocialIcon-Facebook"
                                    >
                                        <i className="fab fa-facebook-f"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="https://www.instagram.com/warriorsms/?hl=en"
                                        target="_blank" className="SocialIcon-Insta"
                                    >
                                        <i className="fab fa-instagram"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="https://twitter.com/warrior_sms?lang=en"
                                        target="_blank" className="SocialIcon-Twitter"
                                    >
                                        <i className="fab fa-twitter"></i>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="copyright-text py-2">
                        <p className="m-0 text-center text-lg-left font-18 font-w-500">
                            Copyright &copy; <span id="copyrightYear"></span> Warrior SMS. All
                            rights reserved.
                        </p>
                    </div>
                </div>
            </footer>
        </>
    );
};
