import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Row, Col, Card, InputGroup } from "react-bootstrap";

export const UpdateCard = ({ pass, setValue,handleCheckboxChange }) => {
   
  const [cardDetail,setCardDetail] = useState();

  useEffect(()=>{
    setCardDetail(pass);
  },[pass])



  return (
    <>
      {cardDetail && cardDetail.length > 0 ? (
        <Row>
          {cardDetail.map((value, index) => (
            <Col lg={4} md={4} sm={12} key={index}>
              <Card className="blueback-pass" key={index}>
                <div className="cardImg">
                  <Card.Img variant="top" src={value.memberShipImg}  alt=""/>
                  <p>Valid For {value.validText} Days</p>
                </div>
                <Card.Body>
                  <Card.Title> {value.cardTitle} </Card.Title>
                  <Card.Text>
                   $ {parseFloat(value.price).toFixed(2)} <small>{value.tax}</small>
                  </Card.Text>
                </Card.Body>
                <InputGroup.Checkbox
                  name="pass[]"
                  defaultChecked={value.is_added === 1}
                  className="membercheckbox"
                  value={value.pass_id}
                  aria-label="Checkbox for following text input"
                  onChange={(event) =>
                    handleCheckboxChange(event, value.pass_id)
                  }
                />
              </Card>
            </Col>
          ))}
        </Row>
      ) : (
        ""
      )}
    </>
  );
};
