import axios from "axios";
const moment = require('moment-timezone');
const currentTimeZone = moment.tz.guess();


// Create a new Axios instance
const http = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  headers: {
    // "Content-type": "application/json", // You can include this if needed for specific requests
    "version": "1.0.0",
    "device_type": "browser",
    "Timezone": currentTimeZone,
    "language": "en"
  }
});

// Add request interceptor to update headers before sending a request
http.interceptors.request.use((config) => {
  // Get the dynamic values from localStorage and update the headers
  config.headers.Authorization = localStorage.getItem('Authorization');
  config.headers.id = localStorage.getItem('id') || "";
  config.headers.businessid = localStorage.getItem('business_id') || "";
  return config;
}, (error) => {
  return Promise.reject(error);
});

export default http;
