import axios from "axios";
const moment = require('moment-timezone');
const currentTimeZone = moment.tz.guess();

export default axios.create({

  baseURL: process.env.REACT_APP_API_HOST,
  headers: {
    // "Content-type": "application/json",
    "version": "1.0.0",
    "Authorization":  localStorage.getItem('Authorization'),
    "id": (localStorage.getItem('id')) ? (localStorage.getItem('id')) : "",
    "device_type": "browser",
    "Timezone": currentTimeZone,
    "language": "en"
  }
});