import React, { useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useEffect } from "react";



export const EditInstructorCheck = ({ labelText, options, name, setSkillsdata, id, value,className }) => {
    const [selectedOptions, setSelectedOptions] = useState([]);


    useEffect(() => {
        setSkillsdata(selectedOptions);
    }, [selectedOptions]);

    const handleChange = (selected) => {
        setSelectedOptions(selected);
    };

 
   
    useEffect(() => {
        if(value){
            setSelectedOptions(value);
        }
    },[value]);

    const handleSelectAll = () => {
        if (selectedOptions.length === options.length) {
            setSelectedOptions([]);
        } else {
            setSelectedOptions([...options]);
        }
    };

    const handleClearAll = () => {
        setSelectedOptions([]);
    };

    const handleOptionToggle = (option) => {
        if (selectedOptions.includes(option)) {
            setSelectedOptions(selectedOptions.filter((item) => item !== option));
        } else {
            setSelectedOptions([...selectedOptions, option]);
        }
    };

    const renderMenu = (results, menuProps) => (
        <div className="MultiSelected" {...menuProps}>
            <div>
                <input
                    type="checkbox"
                    checked={selectedOptions.length === options.length}
                    onChange={handleSelectAll}
                />
                <span>Select All</span>
                <hr className="mt-1 mb-0" />
            </div>
            <div className="MultiSelectedDrop">
                {results.map((result, index) => (
                    <div key={index}>
                        <input
                            type="checkbox"
                            checked={selectedOptions.includes(result)}
                            onChange={() => handleOptionToggle(result)}
                        />
                        <span>{result.label}</span>
                    </div>
                ))}
            </div>
        </div>
    );

    return (
        <>
            <label className={className}>{labelText}</label>

            <Typeahead
                id="my-typeahead-id2"
                multiple
                name={name}
                options={options}
                selected={selectedOptions}
                onChange={handleChange}
                placeholder="Select options"
                labelKey="label"
                dropdownProps={{
                    style: { maxHeight: "200px", overflow: "auto" },
                }}
                renderMenu={renderMenu}
            />

            {selectedOptions.length > 0 && (
                <button className="btn btn-sm btn-link mt-2" onClick={handleClearAll}>
                    X
                </button>
            )}
        </>
    );
};

