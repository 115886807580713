import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Row, Col, Card, InputGroup } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

export const PassList = ({ pass, setValue }) => {
    const cardDetail = pass;
    const navigate = useNavigate();

    const clickHandler = (data) =>{
        navigate("/pass-update", { state: {data} });
    }

    return (
        <>
            {cardDetail && cardDetail.length > 0 ? (
                <Row>
                    {cardDetail.map((value, index) => (
                        <Col lg={4} md={4} sm={12} style={{cursor:"pointer"}} key={index}>
                            <Card className="blueback-pass" key={index} onClick={()=>clickHandler(value.value)}>
                                <div className="cardImg">
                                    <Card.Img variant="top" src={value.memberShipImg} />
                                    <p>{value.validText}</p>
                                </div>
                                <Card.Body>
                                    <Card.Title>{value.cardTitle} </Card.Title>
                                    <Card.Text>
                                        {value.price} <small>{value.tax}</small>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            ) : (
                ""
            )}
        </>
    );
};
