import React from 'react'
import { StaticsHeader } from '../../Components/header/StaticsHeader'
import { Link, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useState } from 'react'
import { api_resend_otp, api_verify_otp } from '../../../Static_Services/Staff_Services'
import { successAlert } from '../../../Static_Services/Alertmsg'
import { Spinner } from 'react-bootstrap'

export const ClientValidation = () => {
  const [userDetail, setUserDetail] = useState();
  const [otp, setOtp] = useState();
  const [error, setError] = useState();
  const [loder, setLoder] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem('w_UserID')) {
      setUserDetail(JSON.parse(localStorage.getItem('w_UserID')));
    }
  }, [])


  const otpVerification = async () => {
    if (!otp) {
      setError('OTP is required');
      return;
    } else {
      setLoder(true);
      let detail = { "userid": userDetail, "otp": otp }
      const response = await api_verify_otp(detail);
      if (response) {
        setLoder(false);
        successAlert(response.data.message);
        localStorage.setItem('w_loginDetail', JSON.stringify(response.data.data));
        if (response.data.data.address) {
          if (response.data.data.isPurchased == 1) {
            navigate('/studio');
          } else {
            navigate('/home');
          }
        } else {
          navigate('/Profile');
        }
      }
    }
    setLoder(false);
  }

  const resendOtp = async () => {
    const response = await api_resend_otp();
    if (response) {
      successAlert(response.data.message);
    }
  }
  return (
    <>
      {loder ? <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div> : ""}
      < StaticsHeader />
      <div className="light-bg2">
        {/* <!-- Login Content Section --> */}
        <div className="background-before">
          <div className="d-flex align-items-center justify-content-center staff-login-height p-sm-0">
            <div className="align-item-center text-center wow animated slideInDown">
              <div className="login-box-owner white-bg box-shadow1 border-radius10 py-4 px-3">

                <div className="login-header text-center px-5 mb-5">
                  <h3 className="title dark-color mb-4"> Verification </h3>
                  <p className="brand-color3 font-18"> Enter the OTP sent to your registered phone number </p>
                </div>
                {/* <!-- end of login-header --> */}

                <div className="logn-form mb-2">
                  <form >
                    <div className="form-group">
                      <p className="brand-color3 text-left font-14 mb-2"> Enter OTP </p>
                      <input type="text" onChange={(e) => setOtp(e.target.value)} className="form-control-1 w-100" placeholder="" formControlName="otpcode" maxlength="4" />
                      <small className='text-danger'>{error}</small>
                    </div>
                    <div className="py-3">
                      <button className="btn btn-blue-grad text-uppercase w-100" type="button" onClick={otpVerification} >
                        {/* <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> */}
                        verify </button>
                      <button data-target="#Client_forgotPopup" data-toggle="modal" type="button" id="sucess_btn" data-backdrop="static" data-keyboard="false"></button>
                    </div>
                    <div className="text-center">
                      <p className="font-18 brand-color3 mb-2"> Did not receive any OTP? <span> <button type='button' style={{ background: 'none' }} onClick={() => resendOtp()} className="brand-color2"> Resend </button></span></p>
                    </div>
                  </form>
                </div>
                {/* <!-- end of logn-form --> */}

              </div>
              {/* <!-- end of login-box --> */}
            </div>
          </div>
          {/* <!-- end of d-flex --> */}
        </div>
        {/* <!-- End of About Content Section --> */}



        {/* <!-- CHECKOUT MODAL POPUP --> */}
        <div className="modal fade" id="Client_forgotPopup" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content w-75 mx-auto blue-gradient border-0">
              <div className="modal-body border-radius6 box-shadow1 p-0">
                <div className="border-radius6 box-shadow-blue blue-gradient p-4 text-center">
                  <img src="clientassets/img/logo_white.png" className="img-fluid mt-5 mb-3" />
                  <h3 className="title white-color text-uppercase my-4 font-w-600"> Success </h3>
                  <p className="white-color my-4 font-w-100 px-4 line-height25 font-18"> Thank you for registering with Warrior SMS! </p>
                  <button routerLink="/signin" type="button" className="btn btn-white3 text-uppercase w-100" data-dismiss="modal"> Login </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- END OF CHECKOUT MODAL POPUP --> */}
      </div>

    </>
  )
}
