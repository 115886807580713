import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import Sidebar from "../../comman_page/Sidebar";
import { HeaderDashboard } from "../../comman_page/HeaderDashboard";
import { Footer } from "../../../../../public/containers/footer/Footer";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import moment from 'moment';
import momentTimezone from "moment-timezone";
import { InstructorList, book_appointment, get_service_schedule_calendar, send_appointment_mail, service_appointment_cancel, service_status_change } from '../../../../../Studio-Services/Studio_Services';
import Swal from 'sweetalert2';
import logo from "../../../../../Images/logo_white_web.png"
import { SharedPayModal } from '../../../../../sharedComponents/SharedPayModal';
import { message } from 'antd';
import Apcancel from './Apcancel';

export const AppointmentDetail = () => {
  const [serviceData, setServiceData] = useState([]);
  const location = useLocation();
  const [loder, setLoder] = useState(false);
  const [payNow, setPayNow] = useState(false);
  const navigate = useNavigate();

  const [currentMonth, setCurrentMonth] = useState(moment().format('MM'));
  const [currentYear, setCurrentYear] = useState(moment().format('YYYY'));
  const [currentDate, setCurrentDate] = useState(moment().format('MM-DD-YYYY'));
  const [custSelect, setCustSelect] = useState(0);

  const [serviceListArray, setServiceListArray] = useState([]);
  const [dropdownServiceSettings, setDropdownServiceSettings] = useState();
  const [instructorList, setInstructorList] = useState([]);
  const [dropdownInstructorSettings, setDropdownInstructorSettings] = useState();
  const [isCloverPurchase, setIsCloverPurchase] = useState();
  const [calendarScheduleList, setCalendarScheduleList] = useState([]);
  const [scheduleStatus, setScheduleStatus] = useState(false);
  const [scheduleArray, setScheduleArray] = useState();
  const [selectedInstructor,setSelectedInstructor] = useState([]);
  const [selectedService,setSelectedService] = useState([]);

  useEffect(() => {
    if (location.state.data && location.state.data.data) {
      setServiceData(location.state.data.data);
    }
    if (location.state.data && location.state.data.detail) {
      setServiceData(location.state.data.detail);
    }
  }, [location])


  useEffect(() => {
    getService();
    getInstructor();
    getScheduleInfo();
  }, [])


  const getService = async () => {
    const value = { "pageid": 1 }
    const response = await book_appointment(value);
    if (response) {
      const resp = response.data;
      let serviceListArr = [];
      for (const key of Object.keys(resp)) {
        const currentRow = resp[key];
        serviceListArr.push({
          id: currentRow.id,
          itemName: `${currentRow.service_name}`
        });
        setServiceListArray(serviceListArr)
      }
    } else {
      setServiceListArray([])
    }
    let dropdownServi = {
      singleSelection: true,
      text: 'Select Service',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableFilterSelectAll: false,
      showCheckbox: false,
      enableSearchFilter: true,
      classes: 'myselect form-control'
    };
    setDropdownServiceSettings(dropdownServi)
  }

  const getInstructor = async () => {
    const data = { "pageid": 1 }
    const response = await InstructorList(data)
    if (response) {
      const resp = response.data.data;
      const instList = [];
      for (const key of Object.keys(resp)) {
        const currentRow = resp[key];
        if (currentRow.status == 'Approve') {
          instList.push({
            id: currentRow.user_id,
            itemName: `${currentRow.name} ${currentRow.lastname}`
          });
        }
      }
      setInstructorList(instList);
    } else {
      setInstructorList();
    }
    let dropdownInstructor = {
      singleSelection: true,
      text: 'Select Instructor',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableFilterSelectAll: false,
      showCheckbox: false,
      enableSearchFilter: true,
      classes: 'myselect form-control'
    };
    setDropdownInstructorSettings(dropdownInstructor);
  }

  const changeCalendarStatus = () => {
    // this.spinner.show();
    setTimeout(() => {
      setScheduleStatus(true);
      // this.spinner.hide();    
    }, 2);
  }

  const changeLocalFormat = (timeData) =>{
    return moment.unix(timeData).format("hh:mm A");
  }

  const scheduleDataPrepare = () => {
    const resp = calendarScheduleList;
    if (resp.length > 0) {
      let tempObj = [];
      for (const key of Object.keys(resp)) {
        const rowObject = resp[key];
        let start = moment.unix(rowObject.start_time).format('MM-DD-YYYY HH:mm:ss');
        let end = moment.unix(rowObject.end_time).format('MM-DD-YYYY HH:mm:ss');

        let start_date = moment.unix(rowObject.start_time).format('MM-DD-YYYY');
        let start_time = moment.unix(rowObject.start_time).format('HH:mm:ss');

        const str_date_split = start_date.split('-');
        const str_time_split = start_time.split(':');

        const start_day = Number(str_date_split[1]);
        const start_mon = Number(str_date_split[0]) - 1;
        const start_yea = Number(str_date_split[2]);

        const start_time_hour = Number(str_time_split[0]);
        const start_time_minu = Number(str_time_split[1]);
        const start_time_seco = Number(str_time_split[2]);

        let end_date = moment.unix(rowObject.end_time).format('MM-DD-YYYY');
        let end_time = moment.unix(rowObject.end_time).format('HH:mm:ss');

        const end_date_split = end_date.split('-');
        const end_time_split = end_time.split(':');

        const end_day = Number(end_date_split[1]);
        const end_mon = Number(end_date_split[0]) - 1;
        const end_yea = Number(end_date_split[2]);

        const end_time_hour = Number(end_time_split[0]);
        const end_time_minu = Number(end_time_split[1]);
        const end_time_seco = Number(end_time_split[2]);

        // , Status: ${this.status(rowObject.booking_status)}

        const bgColor = rowObject.booking_status == 'Cancel' ? 'cancel-schdule-status' : 'normal-schedule-status';

        tempObj.push({
          object: rowObject,
          start: new Date(start_yea, start_mon, start_day, start_time_hour, start_time_minu, start_time_seco, 0),
          end: new Date(end_yea, end_mon, end_day, end_time_hour, end_time_minu, end_time_seco, 0),
          title: `${changeLocalFormat(rowObject.start_time)} to ${changeLocalFormat(rowObject.end_time)} - ${rowObject.service_name}, Instructor: ${rowObject.instructor_name} ${rowObject.instructor_lastname}`,
          color: {
            primary: '#1d96d3',
            secondary: '#FFF',
          },
          cssClass: bgColor,
          actions: [],
          allDay: false,
          resizable: {
            beforeStart: false,
            afterEnd: false,
          },
          draggable: false
        });
      }
      setScheduleStatus(false);
      setScheduleArray(tempObj);

      changeCalendarStatus();
    } else {
      setScheduleStatus(false);
      setScheduleArray([]);
      changeCalendarStatus();
    }
  }


  useEffect(()=>{
    getScheduleInfo();
  },[])

  const getScheduleInfo = async (setDateRecord = '', st = '', ed = '') => {
    // Api Call for get information
    let currentDate_s = ''
    let dateFrom = `${currentYear}-${currentMonth}-01`;
    let dateTo = moment(`${dateFrom}`, 'YYYY-MM-DD').subtract(0, 'months').endOf('month').format('YYYY-MM-DD');

    if (custSelect == 0) {
      const curDate = moment().format('YYYY-MM-01');
      if (curDate == dateFrom) {
        currentDate_s = moment().format('MM-DD-YYYY')
        setCurrentDate(currentDate_s)
      } else {
        currentDate_s = `${currentMonth}-01-${currentYear}`;
        setCurrentDate(currentDate_s)
      }
    } else {
      const dateCreate = (custSelect > 9) ? custSelect : '0' + custSelect;
      currentDate_s = `${currentMonth}-${dateCreate}-${currentYear}`;
      setCurrentDate(currentDate_s)
    }

    if (setDateRecord != '') {
      currentDate_s = setDateRecord;
      setCurrentDate(currentDate_s)
    }

    const request = {};
    request.start = dateFrom;
    request.end = dateTo;

    if (st.length > 0 && ed.length > 0) {
      request.start = st;
      request.end = ed;
    }

    const instructor = selectedInstructor;
    const service = selectedService;

    if (instructor.length > 0) {
      let instructorIds = [];
      for (const key of Object.keys(instructor)) {
        const rowObject = instructor[key];
        instructorIds.push(rowObject.id);
      }
      request.instructor = instructorIds;
    }

    if (service.length > 0) {
      let serviceIds = [];
      for (const key of Object.keys(service)) {
        const rowObject = service[key];
        serviceIds.push(rowObject.id);
      }

      request.service = serviceIds;
    }
    // this.spinner.show();
    const response = await get_service_schedule_calendar(request);
    if (response) {
      setIsCloverPurchase(response.data.isCloverPurchase);
      const resp = response.data.data;
      setCalendarScheduleList(resp);
      scheduleDataPrepare();
    } else {
      setScheduleStatus(false);
      setScheduleArray([]);
      changeCalendarStatus();
    }
  }


  const sendEmail = async () => {
    setLoder(true);
    if (serviceData.booking_status != 'Confirm') {
      const fData = {
        "transaction_id": serviceData.transaction_id,
      }
      const resp = await send_appointment_mail(fData);
      if (resp == undefined) {
        setLoder(false);
      }
      if (resp) {
        setLoder(false);
        Swal.fire({
          html: `
            <img src=${logo} class="img-fluid d-block mx-auto mt-5" />
            <h4 class="title text-uppercase text-center mt-4 textWhite">Success</h4>
            <p class="text-center my-4 textcolor">
              <small class="textWhite">${resp.data.message}</small>
            </p>
          `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
          confirmButtonText: 'Continue',
        })
      }

    }
  }


  const ageCalculator = (dob) => {
    if (dob) {
      const birthdate = new Date(dob);
      const currentDate = new Date();

      const ageInMillis = currentDate - birthdate; // Difference in milliseconds
      const ageInYears = ageInMillis / (1000 * 60 * 60 * 24 * 365.25); //
      return Math.floor(ageInYears);
    }
    return;
  }

  const completed = async (data) => {
    const fData = {
      transaction_id: data.transaction_id,
    }
    const resp = await service_status_change(fData);
 
    if (resp) {
      Swal.fire({
        html: `
          <img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">Success</h4>
          <p class="text-center my-4 textcolor">
            <small class="textWhite">${resp.data.message}</small>
          </p>
        `,
        showClass: {
          popup: 'custom-dialog-container',
        },
        customClass: {
          confirmButton: 'custom-button custom-button-size modalbtn',
        },
        confirmButtonText: 'Continue',
      }).then((result) => {
        if (result.isConfirmed) {
          let myArrayData = {...serviceData};
          // console.log('myArrayData1', myArrayData.booking_status);
          myArrayData.booking_status = 'Completed';
          // console.log('myArrayData2', myArrayData.booking_status);
          setServiceData(myArrayData);
            // navigate('/service-appointment');
          }
      });
    }
  }

  const cancel_appointment = async (data) => {
    Swal.fire({
      html: `
        <img src=${logo} class="img-fluid d-block mx-auto mt-5" />
        <h4 class="title text-uppercase text-center mt-4 textWhite">CONFIRMATION</h4>
        <p class="text-center my-4 textcolor">
          <small class="textWhite">Are you sure to change status ?</small>
        </p>
      `,
      showClass: {
        popup: 'custom-dialog-container',
      },
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "YES",
      cancelButtonText: "NO"
    }).then(async (result) => {
      if (result.isConfirmed) {
        const fData = {
          transaction_id: data.transaction_id,
        }
        const resp = await service_appointment_cancel(fData);
        if (resp) {
          Swal.fire({
            html: `
          <img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">Success</h4>
          <p class="text-center my-4 textcolor">
            <small class="textWhite">${resp.data.message}</small>
          </p>
        `,
            showClass: {
              popup: 'custom-dialog-container',
            },
            customClass: {
              confirmButton: 'custom-button custom-button-size modalbtn',
            },
            confirmButtonText: 'Continue',
          }).then((result) => {
            if (result.isConfirmed) {
              navigate('/service-appointment');
            }
          })
        }
      }
    })
  }

  const upDate = (date,payNow) =>{
    setPayNow(payNow);
    setServiceData(date);
  }

  return (
    <>

    <div className="MainDashboard">
      {loder ?
        <div className='MainLoader'>
          <Spinner animation="border" role="status" className='Loader'>
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
        : ""}
      <div className="MainLayout">
        <Row>
          <Sidebar />
          <Col>
            <HeaderDashboard />
            <div className="RightSide">
              <div className="innerContent">
                <Container>
                  <section className="ClassesDetails">
                    <div className="ClassesDetails">
                      <div className="ScheduleHeading">
                        <Row>
                          <Col>
                            <h5 className="classesHeading">
                              <Link to="/service-appointment"><IoIosArrowBack />SERVICE APPOINTMENT</Link>
                            </h5>
                          </Col>
                          <Col>
                            <div style={{ float: "right" }} >
                              {serviceData && serviceData.booking_status != 'Confirm' ?
                                <a href="javascript:void(0)" onClick={sendEmail} class="btn btn-blue-grad px-2 text-uppercase float-right" alt="Send Email Receipt">
                                  <i class="fa fa-envelope"></i> Email Receipt
                                </a>
                                : ""}
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className="ScheduleHeading">
                        <h4 className="classesHeading">
                          <small>CUSTOMER DETAILS</small>
                        </h4>
                        <Row className='p-4' style={{ justifyContent: "center" }}>
                          <Row className="mt-3">
                            <Col>
                              <div className="TextDetail">
                                <h5>Name</h5>
                                <p>{serviceData && serviceData.name ? serviceData.name + " " + serviceData.lastname : ""}</p>
                              </div>
                            </Col>
                            <Col>
                              <div className="TextDetail">
                                <h5>Age</h5>
                                <p>{serviceData && serviceData.date_of_birth ? ageCalculator(serviceData.date_of_birth) + " Years" : ""}</p>
                              </div>
                            </Col>
                            <Col>
                              <div className="TextDetail">
                                <h5>Gender</h5>
                                <p>{serviceData && serviceData.gender ? serviceData.gender : ""}</p>
                              </div>
                            </Col>
                          </Row>

                          <h4 className="classesHeading mt-3 mb-3">
                            <small>APPOINTMENTS DETAILS</small>
                          </h4>
                          <Row className="mt-3">
                            <Col>
                              <div className="TextDetail">
                                <h5>Instructor</h5>
                                <p>{serviceData && serviceData.instructor_name ? serviceData.instructor_name : ""}</p>
                              </div>
                            </Col>
                            <Col>
                              <div className="TextDetail">
                                <h5>Duration</h5>
                                <p>{serviceData && serviceData.duration ? serviceData.duration : 0}</p>
                              </div>
                            </Col>
                            <Col>
                              <div className="TextDetail">
                                <h5>Shift Date</h5>
                                <p>{serviceData && serviceData.shift_date ? momentTimezone.unix(serviceData.shift_date).format('DD-MMM-YYYY') : ""}</p>
                              </div>
                            </Col>
                            <Col>
                              <div className="TextDetail">
                                <h5>Shift Time</h5>
                                <p>{serviceData && serviceData.start_time ? moment.unix(serviceData.start_time).format("hh:mm A") : ""}- {serviceData && serviceData.end_time ? moment.unix(serviceData.end_time).format("hh:mm A") : ""}</p>
                              </div>
                            </Col>
                          </Row>

                          <h4 className="classesHeading mt-3 mb-3">
                            <small>SERVICE DETAILS</small>
                          </h4>
                          <Row className="mt-3">
                            <Col>
                              <div className="TextDetail">
                                <h5>Name</h5>
                                <p>{serviceData && serviceData.service_name ? serviceData.service_name : ""}</p>
                              </div>
                            </Col>
                            <Col>
                              <div className="TextDetail">
                                <h5>Location</h5>
                                <p>{serviceData && serviceData.location_name ? serviceData.location_name : ""}</p>
                              </div>
                            </Col>
                            <Col>
                              <div className="TextDetail">
                                <h5>Location Address</h5>
                                <p>{serviceData && serviceData.location_address ? serviceData.location_address : ""}</p>
                              </div>
                            </Col>
                            <Col>
                            </Col>

                          </Row>
                          <Row className='mt-2'>
                            <Col>
                              <div className="TextDetail">
                                <h5>Cancel Policy</h5>
                                <p>{serviceData && serviceData.cancel_policy ? serviceData.cancel_policy : ""}</p>
                              </div>
                            </Col>
                            <Col>
                              <div className="TextDetail">
                                <h5>Description</h5>
                                <p>{serviceData && serviceData.description ? serviceData.description : ""}</p>
                              </div>
                            </Col>
                          </Row>

                          <h4 className="classesHeading mt-3 mb-3">
                            <small>PAYMENT DETAILS</small>
                          </h4>
                          <Row>
                            <Col>
                              <div className="TextDetail">
                                <h5>Appointment Fees</h5>
                                <p>$ {serviceData && serviceData.amount ? serviceData.amount : ""}</p>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <div class="row mt-3">
                              <div class="form-group col-md-6">
                                <button onClick={() => window.history.back()} class="btn btn-blue-grad px-2 text-uppercase">Back</button>
                                {serviceData && serviceData.booking_status === 'Cancel' &&
                                <>
                                <Apcancel serviceData={serviceData} />
                                      {/* <button class="px-2  ms-3" style={{color:'red'}}>Appointment Cancelled - {
                                        const update = serviceData.update_date;
                                      moment(update).format('D MMM, YYYY h:mm A')}</button> */}
                                     
                                </>
                            
                                }
                                {serviceData && serviceData.booking_status === 'Confirm' &&
                                  <>
                                    <button onClick={() => completed(serviceData)} class="btn btn-blue-grad px-2 text-uppercase ms-3">Mark as Complete</button>
                                    <button class="btn btn-blue-grad px-2 text-uppercase ms-3" onClick={() => cancel_appointment(serviceData)}>Cancel Appointment</button>
                                  </>
                                }
                                {serviceData && serviceData.booking_status === 'Completed' &&
                                  <button class="btn btn-blue-grad px-2 text-uppercase ms-3" onClick={() => setPayNow(true)}>Pay Now</button>
                                }

                                {serviceData && serviceData.booking_status === 'Success' &&
                                  <button class="btn btn-blue-grad px-2 text-uppercase ms-3" >Payment Completed</button>
                                }
                              </div>
                            </div>
                          </Row>
                          {payNow &&
                            <SharedPayModal rowObject={serviceData} getScheduleInfo={getScheduleInfo} upDate ={upDate} setLoder={setLoder}/>
                          }
                        </Row>
                      </div>
                    </div>
                  </section>
                </Container>
                <Footer />
              </div>
            </div>
          </Col >
        </Row >
      </div >
    </div >
    </>
  )
}
