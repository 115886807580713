import React from 'react'
import { InnerHeader } from '../../Components/inner-header/InnerHeader1'
import { Innerfooter } from '../../Components/inner-footer/Innerfooter'
import { staff_add_cart, staff_search_customer_details } from '../../../Static_Services/Staff_Services'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useState } from 'react'
import { StaffPurchaseCard } from '../../Components/CommanPages/StaffPurchaseCard'
import { StaffProductCarousal } from '../../Components/CommanPages/StaffProductCarousal'
import { Spinner } from 'react-bootstrap'
import { successAlert } from '../../../Static_Services/Alertmsg'

export const CostomerDetails = () => {
    const location = useLocation();
    const [locationData, setLocationData] = useState();
    const [profileDetail, setProfileDetail] = useState();
    const [passDetail, setPassDetail] = useState([]);
    const [productDetail, setProductDetail] = useState([]);
    const [availablePassDetail, setAvailablePassDetail] = useState([]);
    const [availableProductDetail, setAvailableProductDetail] = useState();
    const [loder, setLoder] = useState(false);
    const [cartval,setCartval]=useState();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.state) {
            setLocationData(location.state);
        }
    }, [location])

    const getDetails = async () => {
        setLoder(true);
        const fData = {
            "business_id": localStorage.getItem('bzid'),
            'client_id': locationData.client_id,
            'class_id': locationData.stateData.class_id
        };
        const resp = await staff_search_customer_details(fData);
        if (resp) {
            setProfileDetail(resp.data.data);
            if (resp.data.data.purchase_passes_data) {
                setPassDetail(resp.data.data.purchase_passes_data);
            } else setPassDetail([]);
            if (resp.data.data.purchase_product_data) {
                setProductDetail(resp.data.data.purchase_product_data);
            } else setProductDetail([]);
            if (resp.data.data.avaliable_passes_data) {
                setAvailablePassDetail(resp.data.data.avaliable_passes_data);
            } else setAvailablePassDetail([]);
            if (resp.data.data.avaliable_product_data) {
                setAvailableProductDetail(resp.data.data.avaliable_product_data);
            } else setAvailableProductDetail([]);
            setCartval(resp.data.data.total_item);
            setLoder(false);

        } else {setProfileDetail();  setLoder(false);}
    }

    useEffect(() => {
        if (locationData) {
            getDetails();
        }
    }, [locationData])


    const AddToCart = async (type, item) => {
        setLoder(true);
        var amount;
        if (type == 1) {
            amount = item.amount;
        } else {
            amount = item.price;
        }
        var detail = {
            "service_type": type, "service_id": item.id, "amount": amount, "quantity": "1",
            "client_id": profileDetail.id, "business_id": localStorage.getItem('bzid')
        };
        const resp = await staff_add_cart(detail);
        if(resp){
            successAlert(resp.data.message);
            getDetails();
            setLoder(false);
        }else setLoder(false);

        // this.commonservice.PostApi(detail,url).subscribe(((response : any ) => {
        //   if(response.status == 1){
        //     this.commonservice.successAlert(response.message);
        //     this.getDetail();
        //     this.loading = false;      
        //   } else{
        //     this.commonservice.dangerAlert(response.message);
        //     this.loading = false;
        //   }

        // })) 
    }
    return (
        <>
            {loder ?
                <div className='MainLoader'>
                    <Spinner animation="border" role="status" className='Loader'>
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
                : ""}
            <InnerHeader />
            {profileDetail &&
                <div className="profile-section py-5">
                    <div className="container">
                        <div className="white-bg p-4 p-md-5 border-radius6 box-shadow1">
                            <div className="row">
                                <div className="col-10 pb-4">
                                    <div className="profile-details">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-xl-3 col-md-4">
                                                <div className="profile-detail-image ">
                                                    <div className="userprofile_img box-shadow1"><img src={profileDetail.profile_img} className="img-fluid border-radius100 w-100"/>
                                                        <img src="imgURL" className="img-fluid border-radius100"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-xl-9 col-md-8">
                                                <div className="profile-detail-text">
                                                    <h2 className="title dark-color font-24 mb-2 text-capitalize">{profileDetail.name}</h2>
                                                    <p className="brand-color3"> {profileDetail.email}</p>
                                                    <p className="brand-color3"> {profileDetail.mobile1}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2 pb-4 text-right">
                                    <button className="btn btn-blue-grad mb-4 text-uppercase font-w-600 d-block ml-auto" onClick={() => window.history.back()} >Back</button>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="right-productdetail-sec p-4 relative">
                                        <h4 className="title text-uppercase mt-5">Purchased Passes</h4>
                                        {passDetail && passDetail.length > 0 ?
                                            <div className="row pt-4">
                                                <StaffPurchaseCard pass={passDetail} />
                                            </div>
                                            :
                                            <div className="row pt-4" >
                                                <div className="col-4"></div>
                                                <div className="col-4">
                                                    <div className="text-center">
                                                        <p className="font-awesome black-color font-18 d-inline mx-2 "> No Pass Purchased</p>
                                                    </div>
                                                </div>
                                                <div className="col-4"></div>
                                            </div>
                                        }
                                    </div>

                                    <div className="right-productdetail-sec p-4 relative mt-4">
                                        <h4 className="title text-uppercase mt-5">Purchased Products</h4>
                                        {productDetail && productDetail.length > 0 ?
                                            <div className="row pt-4">
                                                <StaffProductCarousal pass={productDetail} />
                                            </div>
                                            :
                                            <div className="row pt-4">
                                                <div className="col-4"></div>
                                                <div className="col-4">
                                                    <div className="text-center">
                                                        <p className="font-awesome black-color font-18 d-inline mx-2 "> No Products Purchased</p>
                                                    </div>
                                                </div>
                                                <div className="col-4"></div>
                                            </div>
                                        }
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-8">
                                            <h4 className="title text-uppercase">Current Availablity </h4>
                                        </div>
                                        <div className="col-4 d-flex align-items-center justify-content-end">
                                            <button className="btn btn-green-grad" onClick={()=>navigate('/customer-cart',{state : {id:profileDetail.id}})}>
                                                <i className="fa fa-shopping-cart"></i>
                                                <sup> <span className="notification-count">{cartval} </span></sup> </button>
                                        </div>
                                    </div>
                                    <div className="right-productdetail-sec p-4 relative">
                                        <div className="row">
                                            <div className="col-8">
                                                <h4 className="title text-uppercase">Available Passes</h4>
                                            </div>
                                        </div>
                                        {availablePassDetail.length > 0 ?
                                            <div className="row pt-4" >
                                                <StaffPurchaseCard pass={availablePassDetail} addCard={AddToCart} />
                                            </div>
                                            :
                                            <div className="row pt-4">
                                                <div className="col-4"></div>
                                                <div className="col-4">
                                                    <div className="text-center">
                                                        <p className="font-awesome black-color font-18 d-inline mx-2 "> No Item Available</p>
                                                    </div>
                                                </div>
                                                <div className="col-4"></div>
                                            </div>
                                        }
                                    </div>
                                    <div className="right-productdetail-sec p-4 relative">
                                        <div className="row">
                                            <div className="col-8">
                                                <h4 className="title text-uppercase">Available Products</h4>
                                            </div>
                                        </div>
                                        {availableProductDetail && availableProductDetail.length > 0 ?
                                            <div className="row pt-4" >
                                                <StaffProductCarousal pass={availableProductDetail} />
                                            </div>
                                            :
                                            <div className="row pt-4">
                                                <div className="col-4"></div>
                                                <div className="col-4">
                                                    <div className="text-center">
                                                        <p className="font-awesome black-color font-18 d-inline mx-2 "> No Product Available</p>
                                                    </div>
                                                </div>
                                                <div className="col-4"></div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <Innerfooter />
        </>
    )
}
