import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
export const SharedButton = ({
  title,
  type,
  variant,
  className,
  iconNode,
  href,
  element,
  onClick,

}) => {
  const buttonColor = () => {
    switch (variant) {
      default:
      case "primary":
        return "secondary";
    }
  };
  let click = '';
  if (onClick !== '') {
    click = onClick;
  }

  return (
    <>
     {href ? <Link to={href} state={element} style={{ textDecoration: "none" }}>
        <Button
          className={className}
          title={title}
          type={type}
          variant={variant}
          onClick={click}
        >
          {iconNode ? iconNode && <span className="py-4 pt-0">{iconNode}</span> : ""}
          <span>{title}</span>
        </Button>
      </Link>
        :
        <Button
          className={className}
          title={title}
          type={type}
          variant={variant}
          onClick={click}
        >
          {iconNode ? iconNode && <span className="py-4 pt-0">{iconNode}</span> : ""}
         <span>{title}</span>
        </Button>
}
    </>
  );
};
