import React from 'react'
import StaticNav from '../../../signup/StaticNav'
import StaticFooter from '../../../../staticpagis/StaticFooter'
import { useState } from 'react'
import { studio_forgot_password } from '../../../../Studio-Services/Studio_Services';
import { successAlert } from '../../../../Static_Services/Alertmsg';
import { useNavigate } from 'react-router-dom';

export default function Studio_Forget_Pass() {
    const [inemail, setInemail] = useState();
    const [error, setError] = useState();
    const navigate = useNavigate();
    const [spiner,setSpiner] = useState(false)

    const inputChanget = (e) =>{
        let {value}=e.target;
        setInemail(value);
        setError('');

    }

    const onLogin = async () => {
        let isValid = 1;
        const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

        if (!inemail) { isValid = 2; setError('Email is required'); };
        if (inemail && !inemail.match(emailPattern)) { isValid = 2; setError('Email must be a valid email address') };
        if (isValid === 1) {
            setSpiner(true);
            let data = { "email": inemail,'role':"2" };
            const resp = await studio_forgot_password(data);
            if (resp) {
                setSpiner(false);
                successAlert(resp.data.message);
                navigate('/studiologin');
            }
            setSpiner(false);
        }
    }

    return (
        <>
            <StaticNav />
            <div className="middle-content-wrp">
                {/* image layer */}
                <div className="signwrapp-back-layer"><img src="assets/images/pictures/watermark_bg.png" alt='' /></div>
                {/* img layer */}
                <div className="container py-5 relative setminheight d-flex align-items-center">
                    <div className="row justify-content-center flex-fill">
                        <div className="col-md-6 col-lg-5 col-xl-4">
                            <div className="middle-innerbox py-4 px-4">
                                <h3 className="title text-center mt-4">Forgot Password!!</h3>
                                <p className="text-center brand-light mb-4 font-16">
                                    Please enter email address to reset password...
                                </p>
                                <div className="form-group">
                                    <label>Email</label>
                                    <input type="text" className="form-control" formcontrolname="email" name='email' onChange={inputChanget} />
                                    <small className='text-danger'>{error}</small>
                                </div>
                                <button className="btn btn-brandbluegrad mt-4 w-100" type="button" onClick={() => onLogin()} disabled={spiner}  >
                                    {spiner &&
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" />
                                }
                                    Continue</button>
                           
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <StaticFooter />
        </>
    )
}
