import React, { useState } from 'react';
import { Container, InputGroup, FormControl, ListGroup } from 'react-bootstrap';
import { ageCalculation, staff_search_customer_for_services } from './Static_Services/Staff_Services';

const LiveSearch = ({ businessDetail }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);
  const [customerList, setCustomerList] = useState();

  const handleSearch = async (values) => {
    let detail = { "pageid": 1, "business_id": businessDetail.business_id, "search_val": values };
    const resp = await staff_search_customer_for_services(detail);
    if (resp) {
      let response = resp.data;
      let rsss = response.data.map((items) => ({
        name: items.name,
        id: items.id
      }));
      response.data.map( (el) => {
        if (el.date_of_birth) {
          el.age = ageCalculation(el.date_of_birth);
        }
      })
      setCustomerList(response.data);
      setResults(rsss);
    } else {
      setResults([]); // Clear results when there are no matches
      setCustomerList([]);
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
    handleSearch(value);
  };

  const handleSelectItem = (item) => {

    customerList.map((el)=>{
      if(el.id == item.id){
        localStorage.setItem('w_slectedCustomer',JSON.stringify(el));
      }})
    setSearchTerm(item.name); // Set the input value to the selected item
    setResults([]); // Clear results when an item is selected
  };

  return (
    <Container>
      <h5 className='font-20'>Choose Client</h5>
      <InputGroup className="mb-0">
        <FormControl
          placeholder="Search..."
          value={searchTerm}
          onChange={handleChange}
        />
      </InputGroup>
      {results.length > 0 && (
        <ListGroup>
          {results.map((result, index) => (
            <ListGroup.Item
              key={index}
              onClick={() => handleSelectItem(result)}
            >
              {result.name}
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
    </Container>
  );
};

export default LiveSearch;
