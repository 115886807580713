import React from 'react'
export default function Test() {

  const handleClick = () => {
    window.print(); // This will open the print dialog
  }

  return (
    <>
        <button type='button' onClick={handleClick}>Print</button>
    </>
  )
}
