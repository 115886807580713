import React from 'react'
import StaticHeader from '../../StaticHeader'
import StaticFooter from '../../StaticFooter'
import "./StaticDemo.css"
import { Link } from 'react-router-dom'
import { useState } from 'react'
import { demoContect } from '../../../Static_Services/StaticServices'
import Swal from 'sweetalert2'
import logo from "../../../Images/logo_white_web.png"
import { Spinner } from 'react-bootstrap'

export const StaticDemo = () => {
  const [loder, setLoder] = useState(false);
  const trailDays = process.env.REACT_APP_trailDays;
  const [form, setForm] = useState({
    fname: "",
    efname: "",
    lname: "",
    elname: "",
    email: "",
    eemail: "",
    bname: "",
    ebname: "",
    phone: "",
    ephone: "",
    comment: "",
    ecomment: "",
  })

  const validatePhone = (phone) => {
    // Remove any non-digit characters from the phone number
    const cleanedPhone = phone.replace(/\D/g, "");

    // Validate the cleaned phone number
    const regex = /^\d{10}$/; // Validates a 10-digit phone number
    return regex.test(cleanedPhone);
  };
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const fnamehandleChange = (event) => {
    const { name, value } = event.target;

    setForm((prevState) => ({
      ...prevState,
      efname: "",
      [name]: value,
    }));
  };
  const lnamehandleChange = (event) => {
    const { name, value } = event.target;
    setForm((prevState) => ({
      ...prevState,
      elname: "",
      [name]: value,
    }));
  };
  const emailhandleChange = (event) => {
    const { name, value } = event.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
      [`e${name}`]: validateEmail(value) ? "" : "Invalid email format",
    }));
  };
  const bnamehandleChange = (event) => {
    const { name, value } = event.target;
    setForm((prevState) => ({
      ...prevState,
      ebname: "",
      [name]: value,
    }));
  };
  const phonehandleChange = (event) => {
    const { name, value } = event.target;
    setForm((prevState) => ({
      ...prevState,
      [`e${name}`]: validatePhone(value) ? "" : "Invalid phone number",
      [name]: value,
    }));
  };
  const commenthandleChange = (event) => {
    const { name, value } = event.target;
    setForm((prevState) => ({
      ...prevState,
      ecomment: "",
      [name]: value,
    }));
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    let valid = 1;

    if (form.fname === "") {
      valid = 0;
      setForm((prevState) => ({
        ...prevState,
        efname: "* First Name is required",
      }));
    }
    if (form.lname === "") {
      valid = 0;
      setForm((prevState) => ({
        ...prevState,
        elname: "* Last Name is required",
      }));
    }
    if (form.email === "") {
      valid = 0;
      setForm((prevState) => ({
        ...prevState,
        eemail: "* Email is required",
      }));
    }
    if (form.bname === "") {
      valid = 0;
      setForm((prevState) => ({
        ...prevState,
        ebname: "* Business Name is required",
      }));
    }
    if (form.phone === "") {
      valid = 0;
      setForm((prevState) => ({
        ...prevState,
        ephone: "* Phone is required",
      }));
    }
    if (form.comment === "") {
      valid = 0;
      setForm((prevState) => ({
        ...prevState,
        ecomment: "* Comment is required",
      }));
    }

    if (valid === 1) {
      setLoder(true);
      const finalData = {
        "first_name": form.fname,
        "last_name": form.lname,
        "email": form.email,
        "business_name": form.bname,
        "phone": form.phone,
        "comment": form.comment,
      }
      const resp = await demoContect(finalData);
      if (resp) {
        setForm((prevState) => ({
          ...prevState,
          fname: "",
          efname: "",
          lname: "",
          elname: "",
          email: "",
          eemail: "",
          bname: "",
          ebname: "",
          phone: "",
          ephone: "",
          comment: "",
          ecomment: "",
        }));
        setLoder(false);
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Success
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${resp.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }
    }
  }


  return (
    <>
      {loder ?
        <div className='MainLoader'>
          <Spinner animation="border" role="status" className='Loader'>
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
        : ""}
      <StaticHeader />

      {/* <!-- Demo Content Section --> */}
      <div className="background-before">
        <div className="">
          <div className="container">
            <div className="text-center wow animated slideInLeft mt-5 pt-2">
              <div className="demo-top-section">
                <h2 className="dark-color font-w-900 pb-4"> Demo </h2>
                <p className="pb-3 brand-color3 font-16 line-height25 font-w-500 px-0 px-md-5"> Take a look at Warrior Studio Management Software for yourself. Click on the video below to see a pre-recorded demo. If you like what you see, start your  <Link to="/choose-plan" style={{ textDecoration: "none" }}> <span className="text-uppercase black-color font-w-900 header-css"> {trailDays} Day Free Trial</span></Link> </p>
                <div className="demo-video mb-4">
                  <img src="clientassets/img/demo_img.png" className="img-fluid w-100" alt="" />
                  <div className="demo-video-btn" style={{cursor:"pointer"}}>
                    <Link target='blank'  to={"https://youtu.be/T_qAJEOc4eo"}>
                    <i className="fas fa-play-circle white-color font-36"></i>
                    </Link>
                  </div>
                </div>
              </div>
              {/* <!-- end of demo-top-section --> */}

              <div className="demo-bottom-section">
                <h2 className="dark-color font-w-900 pb-4 pt-4 pt-lg-5"> Request A Personal Demo </h2>
                <p className="pb-3 brand-color3 font-16 line-height25 font-w-500 px-0 px-md-5"> If you would like a personal walk through of the software, we would like to schedule at time that works for you. Fill in your information and we will get in touch to step up a time that works. </p>

                <form onSubmit={submitHandler}>
                  <div className="row pb-5">

                    <div className="col-12 col-sm-6">
                      <div className="form-group pr-0 pr-md-5">
                        <p className="black-color text-left font-18 font-w-900 mb-0 ml-2 pl-1"> First Name </p>
                        <input
                          name="fname"
                          value={form.fname}
                          onChange={fnamehandleChange}
                          className="form-control form-control-own"
                        />
                        <small className='text-danger'>{form.efname}</small>
                      </div>
                    </div>
                    {/* <!-- First Name --> */}

                    <div className="col-12 col-sm-6">
                      <div className="form-group pl-0 pl-md-5">
                        <p className="black-color text-left font-18 font-w-900 mb-0 ml-2 pl-1"> Last Name </p>
                        <input className="form-control form-control-own"
                          name="lname"
                          value={form.lname}
                          onChange={lnamehandleChange}
                        />
                        <small className='text-danger'>{form.elname}</small>
                      </div>
                    </div>
                    {/* <!-- Last Name --> */}

                    <div className="col-12 col-sm-6 mt-2 mt-md-3">
                      <div className="form-group pr-0 pr-md-5">
                        <p className="black-color text-left font-18 font-w-900 mb-0 ml-2 pl-1"> Email </p>
                        <input type="email" className="form-control form-control-own"
                          name="email"
                          value={form.email}
                          onChange={emailhandleChange}
                        />
                        <small className='text-danger'>{form.eemail}</small>
                      </div>
                    </div>
                    {/* <!-- Email --> */}

                    <div className="col-12 col-sm-6 mt-2 mt-md-3">
                      <div className="form-group pl-0 pl-md-5">
                        <p className="black-color text-left font-18 font-w-900 mb-0 ml-2 pl-1"> Business Name </p>
                        <input className="form-control form-control-own"
                          name="bname"
                          value={form.bname}
                          onChange={bnamehandleChange}
                        />
                        <small className='text-danger'>{form.ebname}</small>
                      </div>
                    </div>
                    {/* <!-- Business Name --> */}

                    <div className="col-12 col-sm-6 mt-2 mt-md-3">
                      <div className="form-group pr-0 pr-md-5">
                        <p className="black-color text-left font-18 font-w-900 mb-0 ml-2 pl-1"> Phone No. </p>
                        <input type="tel" className="form-control form-control-own"
                          name="phone"
                          minLength={10}
                          maxLength={10}
                          value={form.phone}
                          onChange={phonehandleChange}
                        />
                        <small className='text-danger'>{form.ephone}</small>
                      </div>
                    </div>
                    {/* <!-- Phone No. --> */}

                    <div className="col-12 mt-2 mt-md-3">
                      <div className="form-group">
                        <p className="black-color text-left font-18 font-w-900 mb-0 ml-2 pl-1"> Comments </p>
                        <input className="form-control form-control-own"
                          name="comment"
                          value={form.comment}
                          onChange={commenthandleChange}
                        />
                        <small className='text-danger'>{form.ecomment}</small>
                      </div>
                    </div>
                    {/* <!-- Comments --> */}
                    <div className="col-12 text-center mt-2 mt-md-3">
                      <button className="btn btn-blue text-uppercase px-5" type="submit">
                        <span className="spinner-grow-sm" ></span> send </button>
                    </div>
                    {/* <!-- Button --> */}
                  </div>

                </form>
              </div>

            </div>
          </div>
        </div>
      </div>
      {/* <!-- End of Demo Content Section --> */}
      <StaticFooter />

    </>
  )
}
