
import React, { useState } from "react"

import { DatePicker } from "antd"
import moment from "moment";
import { useEffect } from "react";
const {RangePicker} = DatePicker;

export const NewDateRange = ({label,setStartDate,setEndDate,error,setDvalue,dvalue}) => {

  // const [sdate, setSDate] = useState(null);
  // const [edate, setEdate] = useState(null);

  const handleDateChange = (value) => {
    setDvalue(value);
    if (value && value.length === 2) {
      const formattedStartDate = moment(value[0].$d).format("YYYY-MM-DD");
      const formattedEndDate = moment(value[1].$d).format("YYYY-MM-DD");
      setStartDate(formattedStartDate);
      setEndDate(formattedEndDate);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

 

  return (
    <>
   <div className="mb-3 daterange_parent">
    {label ? <label className="form-label inputLable">{label}</label> : ''}
    <RangePicker 
    value={dvalue}
    format="DD-MM-YYYY" 
    onChange={handleDateChange}
    />
    <small className="text-danger">{error}</small>
    </div>
    </>
  )
}
