import React, { useEffect, useState } from "react";
import Sidebar from "../../comman_page/Sidebar";
import { HeaderDashboard } from "../../comman_page/HeaderDashboard";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { DataTable } from "../../../../../sharedComponents/DataTable";
import { Footer } from "../../../../../public/containers/footer/Footer";
import { failed_transaction_list_new_API, transectionList_new } from "../../../../../Studio-Services/Studio_Services";
import { useLocation } from "react-router-dom";
import { SharedButton } from "../../../../../sharedComponents/Button";
import { AiFillEdit } from "react-icons/ai";
import moment from "moment";

import { errorAlert } from "../../../../../Static_Services/Alertmsg";
import { finalAMT, taxCalculator } from "../../../../../sharedComponents/MyHelperFunction";

import FRT_Report_Detail_Pre from "./FRT_Report_Detail_Pre";
import { TrDateRange } from "../../../../../sharedComponents/TrDateRange";
import RefundBotstrapTable from "../../../../../sharedComponents/RefundBotstrapTable";
import BootstrapTable from "../../../../../sharedComponents/BootstrapTable";
import { BiSearchAlt } from "react-icons/bi";
import { DateRange } from "../../../../../sharedComponents/DateRange";

export const FRT_Report = () => {

    const [loder, setLoder] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [trData, setTrData] = useState([]);


    const [myvaluedate, setMyvaluedate] = useState('');


    const [fulldata, setFulldata] = useState();
    const [totalDetail, setTotalDetail] = useState();


    const columnsth = ["Summary", "Transaction", "Before Tax", "Tax 1", "Tax 2", "Total W Tax"];


    const filterRefunddData = (data, name) => {
        let lb = 0; let lt1 = 0; let lt2 = 0; let tt = 0;
        let count = 0;
        data.forEach((e, index) => {
            count += 1;
            let ttx1 = taxCalculator(e.amount, e.tax1_rate);
            let ttx2 = taxCalculator(e.amount, e.tax2_rate);
            let tt_text = parseFloat(ttx1) + parseFloat(ttx2);
            let b_fore_tax = parseFloat(e.amount) - parseFloat(tt_text);
            let amt = parseFloat(e.amount);

            lb += parseFloat(b_fore_tax);
            lt1 += ttx1;
            lt2 += ttx2;
            tt += amt;
        })
        return { summary: name, transaction: count, before_tax: (lb).toFixed(2), tax_one: (lt1).toFixed(2), tax_two: (lt2).toFixed(2), total: (tt).toFixed(2), };
    }

    const getReport = async () => {
        let fData = { "page_no": 1, 'startDate': "", 'endDate': "", };
        if (startDate && endDate) {
            fData = { "page_no": 1, 'startDate': startDate, 'endDate': endDate, }
        }
        const resp = await failed_transaction_list_new_API(fData);
        if (resp) {
            let array = [];
            let pre = resp.data;
            setFulldata(pre);
            if (pre && pre.length > 0) { const res = filterRefunddData(pre, 'Failed'); array.push(res); };

            let tr = 0;
            let Before_Tax = 0;
            let Tax1 = 0;
            let Tax2 = 0;
            let total = 0;

            if (array && array.length > 0) {
                array.forEach((e) => {
                    tr += e.transaction;
                    Before_Tax += parseFloat(e.before_tax);
                    Tax1 += parseFloat(e.tax_one);
                    Tax2 += parseFloat(e.tax_two);
                    total += parseFloat(e.total);
                })
            }
            const final = {
                Transection: tr,
                Before_Tax: parseFloat(Before_Tax).toFixed(2),
                total_Tax1: parseFloat(Tax1).toFixed(2),
                total_Tax2: parseFloat(Tax2).toFixed(2),
                finalTotal: parseFloat(total).toFixed(2)
            }
            setTotalDetail(final);
            setTrData(array);
            setLoder(false);
        } else {
            setTrData([]);
            setTotalDetail();

            setLoder(false);
        }
        setLoder(false);
    }


    useEffect(() => {
        getReport();
    }, [])



    return (
        <>
            {loder && <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> </Spinner> </div>}
            <div className="MainDashboard">
                <div className="MainLayout">
                    <Row>
                        <Sidebar />
                        <Col>
                            <div className="RightSide">
                                <HeaderDashboard />
                                <section className="addClient innerContent">
                                    <div className="AddClientContent">
                                        <Container>
                                            <h4 className="mb-4">Failed Recurring transaction Report</h4>
                                            <Row>
                                                <Col>
                                                    <DateRange
                                                        setStartDate={setStartDate}
                                                        setEndDate={setEndDate}
                                                    />
                                                </Col>
                                                <Col>
                                                    <SharedButton
                                                        type={"submit"}
                                                        onClick={() => getReport()}
                                                        iconNode={<BiSearchAlt />}
                                                        className={"btn btn-outline"}
                                                    />
                                                </Col>
                                                <Col className="text-right">
                                                    <SharedButton
                                                        title={"Back"}
                                                        className={"backBtn"}
                                                        type={"button"}
                                                        onClick={() => {
                                                            window.history.back();
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                            <BootstrapTable th={columnsth} tr={trData} totalDetail={totalDetail} />
                                            {totalDetail &&
                                                <>
                                                    <Row>
                                                        <Col>
                                                            <SharedButton title={"View Details"} type={"button"} element={fulldata} href={"/t_d_r_f"} variant={"secondary"} />
                                                        </Col>
                                                        <Col></Col>
                                                        <Col></Col>
                                                    </Row>
                                                </>
                                            }
                                        </Container>
                                    </div>
                                </section>
                                <Footer />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
};
