import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Sidebar from "../../comman_page/Sidebar";
import { HeaderDashboard } from "../../comman_page/HeaderDashboard";
import { Footer } from "../../../../../public/containers/footer/Footer";
import { CardCarousel } from "../../../../../sharedComponents/CardCarousel";
import { useLocation } from "react-router-dom";
import { get_passes_according_to_category } from "../../../../../Studio-Services/Studio_Services";
import { MemberShipCardWithSlider } from "../../../../../sharedComponents/MemberShipCardWithSlider";
import { CalenderPassEdit } from "./CalenderPassEdit";

export const CalenderPassUpdate = (props) => {
  // const classDetail = props.pass ? props.pass : [];
  const [classDetail , setClassDetail] = useState([]);

  const location = useLocation();
  const prD = location.state || {};

  
  const getData= async ()=>{

    const fdata = {
      "service_id": prD,
      "service_type": "1"
    }
   const resp = await get_passes_according_to_category(fdata);
   if(resp){
    setClassDetail(resp.data.data);
   }
  }

  useEffect(()=>{
    getData();
  },[prD])

  const [timepass, setTimepass] = useState([]);
  const [recurrigpass, setRecurrigpass] = useState([]);
  const [punchpass, setPunchpass] = useState();


  useEffect(() => {
      const timep = [];
      const recuring = [];
      const punch = [];
    
      if (classDetail.length > 0) {
        const logo = localStorage.getItem("logo");
        classDetail.forEach((value) => {
          if (value.pass_type === "Time Frame") {
            timep.push({
              memberShipImg: logo ? logo : "https://staging.warriorsms.com/superadmin/uploads/business/1664736345WarriorMan_icon2_CMYK.png",
              validText: value.pass_days,
              cardTitle: value.pass_name,
              price: `$ ${value.amount}`,
              tax: "Plus Tax",
              is_added:value.is_added,
              passId:value.pass_id
              
            })
          } else if (value.pass_type === "Recurring Membership") {
            recuring.push({
              memberShipImg: logo ? logo : "https://staging.warriorsms.com/superadmin/uploads/business/1664736345WarriorMan_icon2_CMYK.png",
              validText: value.pass_days,
              cardTitle: value.pass_name,
              price: `$ ${value.amount}`,
              tax: "Plus Tax",
              is_added:value.is_added,
              passId:value.pass_id
            })
          } else if (value.pass_type === "Punch Card") {
            punch.push({
              memberShipImg: logo ? logo : "https://staging.warriorsms.com/superadmin/uploads/business/1664736345WarriorMan_icon2_CMYK.png",
              validText: value.pass_days,
              cardTitle: value.pass_name,
              price: `$ ${value.amount}`,
              tax: "Plus Tax",
              is_added:value.is_added,
              passId:value.pass_id
            })
          }
        });
        if (timep.length > 0) {
          setTimepass(timep);
        }
        if (recuring.length > 0) {
          setRecurrigpass(recuring);
        }
        if (punch.length > 0) {
          setPunchpass(punch);
        }
      }
  },[classDetail]);



  return (
    <>
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col className="p-0 RightCol">
              <HeaderDashboard />
              <div className="RightSide">
                <div className="innerContent">
                  <section className="ClassAttendance">
                    <Container>
                      <h3>UPDATE SCHEDULE CLASS PASS</h3>
                      <p>Select Passes</p>
                      <Row>
                        <Col>
                          <div className="RecurringMembership">
                            <div className="CardCarousel mt-5">
                              <h5>Recurring Membership</h5>
                              <CalenderPassEdit pass={recurrigpass} />
                            </div>
                            <div className="TimeCard mt-5">
                              <h5>Time Frame</h5>
                              <CalenderPassEdit pass={timepass} />
                            </div>
                            <div className="PunchCard my-5">
                              <h5>Punch Card</h5>
                              <CalenderPassEdit pass={punchpass} />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </section>
                  <Footer />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
