import React from 'react'
import StaticHeader from '../../StaticHeader'
import StaticFooter from '../../StaticFooter'
import { Link, NavLink } from 'react-router-dom'

export const StaticLogin = () => {
  return (
   <>
   <StaticHeader />

<div className="light-bg2">
{/* <!-- Login Content Section --> */}
  <div className="background-before login-form-section">
          <div className="d-flex align-items-center justify-content-center login-form-height">

            <div className="align-item-start text-center wow animated slideInDown w-100">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-4 mb-5 mb-md-0">
                    <div className="login-box border-radius10 p-4">
                      <div className="login-header text-center mt-4">
                        <img src="clientassets/img/logo_green_web.png" className="img-color img-fluid mx-auto" /> 
                        <img src="clientassets/img/logo_white_web.png" className="img-white img-fluid mx-auto" />
                        <h3 className="title text-uppercase my-3 mb-3"> studio owner </h3>
                        <p className="px-4"> Please login to check your details... </p>
                      </div>
                      {/* <!-- end of login-header --> */}

                      <div className="logn-form pt-3 mb-2">
                        {/* <!-- <form> --> */}
                        <NavLink  to ="/studiologin" className="theme-color"> <button type="button" className="btn login blue-text-gradient text-uppercase w-100 mb-1"> login </button>  </NavLink>
                        <NavLink to = "/studio-home" > <button type="button" className="btn register text-uppercase w-100 mt-3"> register </button></NavLink>
                        {/* <!-- </form> --> */}
                      </div>
                      {/* <!-- end of logn-form --> */}
                    </div>
                    {/* <!-- end of login-box --> */}
                  </div>
                  {/* <!-- end of Studio Owner --> */}

                  <div className="col-12 col-md-4 mb-5 mb-md-0">
                    <div className="login-box border-radius10 p-4">
                      <div className="login-header text-center mt-4">
                        <img src="clientassets/img/logo_green_web.png" className="img-color img-fluid mx-auto" />
                        <img src="clientassets/img/logo_white_web.png" className="img-white img-fluid mx-auto" />
                        <h3 className="title text-uppercase my-3 mb-3"> studio staff </h3>
                        <p className="px-4"> Please login to check your details... </p>
                      </div>
                      {/* <!-- end of login-header --> */}

                      <div className="logn-form pt-3 mb-2">
                        {/* <!-- <form> --> */}
                        <Link to='/staff-signin'><button type="button" className="btn login blue-text-gradient text-uppercase w-100 mb-1 theme-color"> login </button></Link>
                        <Link to='/staff-registration' ><button  type="button" className="btn register text-uppercase w-100 mt-3"> register </button></Link>
                        {/* <!-- </form> --> */}
                      </div>
                      {/* <!-- end of logn-form --> */}
                    </div>
                    {/* <!-- end of login-box --> */}
                  </div>
                  {/* <!-- end of Studio Staff --> */}

                  <div className="col-12 col-md-4 mb-5 mb-md-0">
                    <div className="login-box border-radius10 p-4">
                      <div className="login-header text-center mt-4">
                        <img src="clientassets/img/logo_green_web.png" className="img-color img-fluid mx-auto" />
                        <img src="clientassets/img/logo_white_web.png" className="img-white img-fluid mx-auto" />
                        <h3 className="title text-uppercase my-3 mb-3"> studio client </h3>
                        <p className="px-4"> Please login to check your details... </p>
                      </div>
                      {/* <!-- end of login-header --> */}

                      <div className="logn-form pt-3 mb-2">
                        {/* <!-- <form> --> */}
                       <Link to='/signin'><button type="button" className="btn login blue-text-gradient text-uppercase w-100 mb-1 theme-color"> login </button></Link>
                       <Link to='/client-registration'><button type="button" className="btn register text-uppercase w-100 mt-3"> register </button></Link>
                        {/* <!-- </form> --> */}
                      </div>
                      {/* <!-- end of logn-form --> */}
                    </div>
                    {/* <!-- end of login-box --> */}
                  </div>
                  {/* <!-- end of Studio Client --> */}
                </div>
                {/* <!-- end of row --> */}
              </div>
              {/* <!-- end of container --> */}
            </div>

          </div>
          {/* <!-- end of d-flex --> */}
  </div>
{/* <!-- End of About Content Section --> */}
</div>

   <StaticFooter />
   </>
  )
}
