import React from 'react'
import { InnerHeader } from '../../Components/inner-header/InnerHeader1'
import { SubHeader } from '../../Components/sub-header/SubHeader'
import { Innerfooter } from '../../Components/inner-footer/Innerfooter1'
import { DashboardToSectionDetails } from '../../Components/CommanPages/DashboardToSectionDetails'
import { staff_products_details } from '../../../Static_Services/Staff_Services'
import { useLocation } from 'react-router-dom'
import { useState } from 'react'
import { useEffect } from 'react'

export const StaffProductDetail = () => {
  const location = useLocation();
  const [productDetail, setProductDetail] = useState();


  const getPRData = async () => {
    const fData = {
      "product_id": location.state.id
    }
    const resp = await staff_products_details(fData);
    if (resp) {
      setProductDetail(resp.data.data);
  
    }
  }

  useEffect(() => {
    if (location.state.id) {
      getPRData();
    }
  }, [location])
  return (
    <>
      <InnerHeader />

      <div className="body-grey">
        <div className="studio-details product-page pb-5">
          <div className="container">
            <DashboardToSectionDetails />

            <div className="text-right mb-3">
              <button type="button" className="btn btn-blue-grad px-2 text-uppercase" onClick={() => window.history.back()}>Back </button>
            </div>
            <div className="row">
              <div className="col-10">
                <div className="section-heading">
                  <h5 className="title black-color font-w-100 text-uppercase font-16"> product image </h5>
                </div>
              </div>
              {productDetail && productDetail.product_images && productDetail.product_images.map((e)=>(
              <div className="col-12 col-md-3">
                <div className="border-radius6 box-shadow1 white-bg p-3" >
                  <img src={e.image_name} className="img-fluid w-100" style={{ height: "221px" }} />
                </div>
              </div>
                ))}
            </div>
          </div>
          {productDetail &&
            <div className="container-fluid studio-padding-left py-5 product-detail-similar">
              <div className="row px-3">
                <div className="col-12 col-lg-5 respon-product">
                  <h4 className="title black-color font-w-100 font-16"> Product Detail </h4>
                  <div className="product-detail-section white-bg border-radius6 box-shadow1 pt-3 mx-0">
                    <div className="row px-3">
                      <div className="col-8 text-left">
                        <h5 className="heading-titlenew black-color font-w-100 font-16">{productDetail.product_name} </h5>
                      </div>
                      <div className="col-4 text-right">
                        <button type="button" className="btn btn-green-grad px-4">{productDetail.product_price} </button>
                      </div>
                    </div>
                    <div className="text-left pb-5 px-3">
                      <p className="black-color font-w-100 paratitle mb-2"> Product details </p>
                      <p className="brand-color3 text-justify paratitle pb-3">{productDetail.product_description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
      <Innerfooter />

    </>
  )
}
