import { useEffect, useRef, useState } from 'react';
import { Form } from "react-bootstrap";



export const TextArea = (
    {
        value,
        className,
        onChange,
        rows,
        lable,
        name,
        error,
        placeHolder,
        readOnly
    }
) => {
    const inputRef = useRef(null);
    useEffect(() => {
        if (value !== undefined) {
            inputRef.current.value = value;
        }
    }, [value]);


    return (
        <>
            <div className="mb-3">
                {lable ? <label className="form-label inputLable">{lable}</label> : ''}
                <textarea
                  readOnly={readOnly ? true : false}
                    placeholder={placeHolder ? placeHolder : ""}
                    name={name}
                    className={className ? className : "form-control"}
                    rows={rows ? rows : '2'}
                    ref={inputRef}
                    onChange={onChange}
                >{value}</textarea>
                <small className='text-danger'>{error}</small>
            </div>
        </>
    );
};
