import React, { useEffect, useState } from "react";
import Sidebar from "../../comman_page/Sidebar";
import { HeaderDashboard } from "../../comman_page/HeaderDashboard";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { Footer } from "../../../../../public/containers/footer/Footer";
import {
  book_appointment,
  getserviceClient,
  service_detail_new,
} from "../../../../../Studio-Services/Studio_Services";
import MultiSelect from "../../../../../sharedComponents/MultiSelect";
import moment from "moment";
import { NewDateRange } from "../../../../../sharedComponents/NewDateRange";
import { finalAMT, monthMap, taxCalculator } from "../../../../../sharedComponents/MyHelperFunction";

export const ServiceReport = () => {
  const [loder, setLoder] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [allservices, setAllservices] = useState([]);
  const [serviceoption, setServiceoption] = useState([]);
  const [sltSrOption, setSltSrOption] = useState([]);
  const [insoption, setInsoption] = useState([]);
  const [sltinsoption, setSltinsoption] = useState([]);
  const [status, setStatus] = useState([]);

  const [dvalue, setDvalue] = useState();

  const [clientop, setClientop] = useState([]);
  const [selectedClient, setSelectedClient] = useState([]);

  const [tableData, setTableData] = useState([]);


  const [error, setError] = useState({ 'service': '', 'instructor': '', 'status': '', 'client': '', 'daterange': '' })

  const statusop = [
    { value: "Confirm", label: "Mark as Complete" },
    { value: "Completed", label: "Pay Now" },  
    { value: "Success", label: "Payment Completed" },
  
    { value: "Cancel", label: "Cancel" },

  ];

  const getService = async () => {
    const fDate = { pageid: 1, order: 1 };
    const resp = await book_appointment(fDate);
    if (resp) {
      setLoder(false);
      setAllservices(resp.data);
      const ser = [];
      resp.data.map((e) => ser.push({ value: e.id, label: e.service_name }));
      setServiceoption(ser);
    } else {
      setServiceoption([]);
    }
  };

  useEffect(() => {
    if (sltSrOption && sltSrOption.length > 0 && sltSrOption[0].value) {
      const opt = [...insoption];
      const inst = [];
      sltSrOption.forEach(e => {
        const resp = allservices.find((al) => al.id === e.value); // Use `find` instead of `filter`
        if (resp) {
          if (resp.instructor) {
            resp.instructor.forEach((e) => // Use `forEach` instead of `map` since you're not returning a new array
            {
              let preopt = opt.find((pr) => pr.value === e.id);
              let preins = inst.find((pin) => pin.value === e.id);
              if (!preopt && !preins) {
                inst.push({ value: e.id, label: `${e.name}  ${e.lastname}` });
                opt.push({ value: e.id, label: `${e.name}  ${e.lastname}` });
              }
            });
          }
        }
      });
      setInsoption(opt);
    } else setInsoption([]);
  }, [sltSrOption, allservices]);


  const getclients = async (abc) => {
    const fdata = { 'serviceid': abc };
    setLoder(true);
    const resp = await getserviceClient(fdata);
    if (resp) {
      const preData = resp.data;
      setLoder(false);
      const ser = [];
      preData.data.map((e) => ser.push({ value: e.id, label: e.name + ' ' + e.lastname }));
      setClientop(ser);
    } else {
      setClientop([]);
    }
    setLoder(false);
  }


  useEffect(() => {
    const abc = []
    if (sltSrOption && sltSrOption.length > 0) { sltSrOption.forEach((data) => { abc.push(data.value); }) }
    getclients(abc);
  }, [sltSrOption])

  useEffect(() => {
    getService();
  }, []);

  useEffect(() => {
    if (sltSrOption && sltSrOption.length > 0) { setError((pre) => ({ ...pre, 'service': '' })) }
    if (sltinsoption && sltinsoption.length > 0) { setError((pre) => ({ ...pre, 'instructor': '' })) }
    if (status && status.length > 0) { setError((pre) => ({ ...pre, 'status': '' })) }
    if (selectedClient && selectedClient.length > 0) { setError((pre) => ({ ...pre, 'client': '' })) }
    if (startDate) { setError((pre) => ({ ...pre, 'daterange': '' })) }
  }, [sltSrOption, sltinsoption, status, selectedClient, startDate]);

  const handleSubmit = async (e) => {
    let isValid = 1;
    let serviceids = [];
    let instids = [];
    let stids = [];
    let usrids = [];
    if (sltSrOption && sltSrOption.length > 0) { sltSrOption.forEach((data) => { serviceids.push(data.value); }) }
    if (sltinsoption && sltinsoption.length > 0) { sltinsoption.forEach((data) => { instids.push(data.value); }) }
    if (status && status.length > 0) { status.forEach((data) => { stids.push(data.value); }) }
    if (selectedClient && selectedClient.length > 0) { selectedClient.forEach((data) => { usrids.push(data.value); }) }


    if (!serviceids || serviceids.length <= 0) { setError((pre) => ({ ...pre, 'service': '* Required' })); isValid = 2; };
    if (!sltinsoption || sltinsoption.length <= 0) { setError((pre) => ({ ...pre, 'instructor': '* Required' })); isValid = 2; };
    if (!status || status.length <= 0) { setError((pre) => ({ ...pre, 'status': '* Required' })); isValid = 2; };
    if (!selectedClient || selectedClient.length <= 0) { setError((pre) => ({ ...pre, 'client': '* Required' })); isValid = 2; };
    if (!startDate || !endDate) { setError((pre) => ({ ...pre, 'daterange': '* Required' })); isValid = 2; };


    if (isValid !== 1) { return; }

    setLoder(true);

    const fdata = {
      'start_date': startDate,
      'end_date': endDate,
      'service': serviceids,
      'instructor': instids,
      'status': stids,
      'clients': usrids
    }

    const resp = await service_detail_new(fdata);
    let cos = [];
    let amt = 0;
    let tx1 = 0;
    let tx2 = 0;
    let tot = 0;
    let totaltp = 0;

    if (resp) {
      setLoder(false);
      // const pre = resp.data;
      const pre = resp.data.sort((a, b) => moment(b.service_date) - moment(a.service_date));

      pre.forEach(e => {

        let bs = parseFloat(e.amount).toFixed(2);
        let tx_1 = taxCalculator(e.amount, e.tax1_rate);
        let tx_2 = taxCalculator(e.amount, e.tax2_rate);
        let preTip = finalAMT(e.amount, tx_1, tx_2);
        let tp = 0;

        if (e.tip_comment != '' && !isNaN(parseInt(e.tip_comment))) { tp = parseInt(e.tip_comment); }
       

        let fnl = parseFloat(preTip) + parseFloat(tp);

        amt += parseFloat(bs);
        tx1 += parseFloat(tx_1);
        tx2 += parseFloat(tx_2);
        totaltp += parseFloat(tp);
        tot += parseFloat(fnl);

        cos.push({
          Service: e.service,
          clientName: e.customer,
          Staff: e.staf_name,
          Date: moment(e.service_date).format('DD-MMM-YYYY'),
          Status: e.status == 'Success' ? 'Payment Completed' : e.status == 'Completed' ? 'Pay Now' : e.status == 'Confirm' ? 'Mark as Complete' : 'Cancel Appointment',
          Amount: bs,
          Tax_one: tx_1,
          Tax_two: tx_2,
          tip: tp,
          Total: fnl,
        })
      });

      cos.sort((a, b) => monthMap[a.Date] - monthMap[b.Date]);
      // cos.reverse();
      cos.push({
        Service: 'Total',
        clientName: '',
        Staff: '',
        Date: '',
        Status: '',
        Amount: parseFloat(amt).toFixed(2),
        Tax_one: parseFloat(tx1).toFixed(2),
        Tax_two: parseFloat(tx2).toFixed(2),
        tip: parseFloat(totaltp).toFixed(2),
        Total: parseFloat(tot).toFixed(2),
      })
      setTableData(cos);
    } else setTableData([]);
    setLoder(false);
  };


  const clearHandler = () => {
    setError({ 'service': '', 'instructor': '', 'status': '', 'client': '', 'daterange': '' });
    setSltSrOption([]);
    setSltinsoption([]);
    setStatus([]);
    setSelectedClient([]);
    setDvalue('');
    setTableData([]);
  }

  return (
    <>
      {loder && <div className="MainLoader"> <Spinner animation="border" /> </div>}
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col>
              <div className="RightSide">
                <HeaderDashboard />
                <section className="addClient innerContent">
                  <div className="AddClientContent">
                    <Container>
                      <h4 className='mb-4'>Service Report</h4>
                      <Row className="mt-3">
                        <Col>
                          <MultiSelect options={serviceoption} Label={"Service"} setSelectedOptions={setSltSrOption} selectedOptions={sltSrOption} error={error.service} />
                        </Col>
                        <Col>
                          <MultiSelect Label={"Instructor"} options={insoption} setSelectedOptions={setSltinsoption} selectedOptions={sltinsoption} error={error.instructor} />
                        </Col>
                        <Col>
                          <MultiSelect Label={"Status"} options={statusop} setSelectedOptions={setStatus} selectedOptions={status} error={error.status} />
                        </Col>
                        <Col>
                          <MultiSelect Label={"Client"} options={clientop} setSelectedOptions={setSelectedClient} selectedOptions={selectedClient} error={error.client} />
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <h5>Date Range</h5>

                        <Col className="d-flex" style={{ justifyContent: 'space-between' }}>
                          <span>
                            <NewDateRange
                              setDvalue={setDvalue}
                              dvalue={dvalue}
                              setStartDate={setStartDate}
                              setEndDate={setEndDate}
                              error={error.daterange}
                            />
                          </span>
                          <span>
                            <button type="button" className="btn btn-success m-1" onClick={handleSubmit}>Run Report</button>
                            <button type="button" className="btn btn-danger m-1" onClick={clearHandler}>Clear Report </button>                            
                          </span>
                        </Col>

                      </Row>
                      <Row className="mt-3">
                        <Col>
                          <div className="row transactionReport w-100">
                            <table className="table table-striped table-bordered">
                              <thead>
                                <tr>
                                  <th>Service</th>
                                  <th>Client Name</th>
                                  <th>Staff</th>
                                  <th>Date</th>
                                  <th>Status</th>
                                  <th>Amount</th>
                                  <th>Tax 1</th>
                                  <th>Tax 2</th>
                                  <th>Tip</th>
                                  <th>Total</th>
                                </tr>
                              </thead>
                              <tbody style={{ fontSize: '14px' }}>
                                {tableData && tableData.length > 0 && tableData.map((row, index) =>
                                  <tr key={index} className={index + 1 == tableData.length ? 'highlighted highlighted-my' : ''}>
                                    <td>{row.Service}</td>
                                    <td>{row.clientName}</td>
                                    <td>{row.Staff}</td>
                                    <td >{row.Date}</td>
                                    <td>{row.Status}</td>
                                    <td style={{ width: 'auto', whiteSpace: 'nowrap' }}>$ {parseFloat(row.Amount).toFixed(2)}</td>
                                    <td style={{ width: 'auto', whiteSpace: 'nowrap' }}>$ {parseFloat(row.Tax_one).toFixed(2)}</td>
                                    <td style={{ width: 'auto', whiteSpace: 'nowrap' }}>$ {parseFloat(row.Tax_two).toFixed(2)}</td>
                                    <td style={{ width: 'auto', whiteSpace: 'nowrap' }}>$ {parseFloat(row.tip).toFixed(2)}</td>
                                    <td style={{ width: 'auto', whiteSpace: 'nowrap' }}>$ {parseFloat(row.Total).toFixed(2)}</td>
                                  </tr>)}                                
                              </tbody>
                            </table>
                            {tableData && tableData.length <= 0 && (<p className="text-center mt-4">No Data Available</p>)}
                          </div>
                        </Col>
                      </Row>                     
                    </Container>
                  </div>
                </section>
                <Footer />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
