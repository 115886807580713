import React, { useEffect, useState } from 'react'
import StaticHeader from '../../StaticHeader'
import StaticFooter from '../../StaticFooter'
import { PlanList } from "../../../Static_Services/StaticServices"
import { Link } from 'react-router-dom'

export const StaticPrising = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

 const trailDays = process.env.REACT_APP_trailDays;
    const [plist, setPlist] = useState([]);

    const get_plan_list = async () => {
        const resp = await PlanList();
        if (resp) {
            setPlist(resp.data.data);
        }
    }
    useEffect(() => {
        get_plan_list();
    }, [])
    // const trailDays = 30;
    return (
        <>

            <StaticHeader />

            {/* <!-- Pricing Content Section --> */}
            <section className="pricing-container">
                <div className="background-before">
                    <div className="py-5">
                        <div className="container">
                            <div className="pricing-studio text-center wow animated slideInLeft px-0 px-md-5 pb-4">
                                <h2 className="dark-color font-w-900 pb-3"> Warrior Studio Management Software </h2>
                                <p className="pb-3 brand-color3 font-20 line-height25 px-0 px-lg-5"> Our pricing is based on the size of your staff. Request a demo of our solution or start an account for free and try it for {trailDays} days. </p>
                                <Link to="/choose-plan" className="btn btn-blue text-uppercase px-5 header-css"> Start {trailDays} Day Free Trial </Link>
                            </div>
                            {/* <!-- end of pricing-studio --> */}


                            <div className="pricing-studio-cards pt-5 pb-0 pb-lg-5 mt-4 text-center wow animated zoomIn"  >
                                <div className='studioPricingCard'>
                                    {plist.length > 0 ?
                                        plist.map((item, index) => (
                                            <div className="PricingDetailCard mt-2" key={index}>
                                                <div className="pricing-card text-center px-4 pt-4 pb-5 border-radius6 white-bg">
                                                    <img src="clientassets/img/logo_green_small.png" />
                                                    <p className="black-color mt-3 font-w-600 font-20 mb-3">{item.plan_name} </p>
                                                    {/* <h5 className="black-color mb-3 font-w-400"> ${item.amount}/Month* </h5> */}
                                                    {/* <h5 className="black-color mb-3 font-w-400"> * plus applicable taxes </h5> */}
                                                    <Link to="/choose-plan" state={{planId:item.plan_id}}
                                                     className="text-uppercase header-css" > sign up today </Link>
                                                </div>
                                            </div>
                                        ))

                                        : ""}
                                </div>
                            </div>

                        </div>
                        {/* <!-- end of container --> */}

                        <div className="pricing-payment green-gradient mt-5 py-4 px-0 px-md-5">
                            <div className="container text-center wow animated slideInRight py-3">
                                <h2 className="white-color pb-3"><ins> Accepting Payments </ins> </h2>
                                <p className="pb-3 white-color font-20 font-w-100 line-height25 px-0 px-lg-5 pb-4"> Accepting Credit and debit card processing services will help your business grow and the Warrior solution to work its best.  We offer competitive rates for existing or new businesses.  Ask us for details! </p>
                            </div>
                        </div>
                        {/* <!-- end of pricing-payment --> */}

                        <div className="container">
                            <div className="pricing-email text-center wow animated slideInUp mt-5 pt-2 px-0 px-md-5">
                                <h2 className="dark-color font-w-900 pb-1"><ins> Email and Social Marketing for Your Studio </ins> </h2>
                                <p className="brand-color3 font-20 line-height25 px-0 px-lg-5 mb-3"> We have partnered with Constant Contact to allow your clients and staff database flow into Constant so you can manage marketing emails and social media. We can offer new accounts free trial for 6 months to try it out. </p>
                            </div>
                            {/* <!-- end of container --> */}
                        </div>
                        {/* <!-- end of pricing-email --> */}
                    </div>
                </div>
                {/* <!-- end of background-before --> */}
            </section>
            {/* <!-- End of Pricing Content Section --> */}
            <StaticFooter />
        </>
    )
}
