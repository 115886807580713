import React, { useState } from 'react'

import { Link, useNavigate } from 'react-router-dom'
import StaticHeader from '../../../staticpagis/StaticHeader'
import { login } from '../../../Static_Services/StaticServices';
import Swal from 'sweetalert2';
import logo from "../../../Images/logo_white_web.png"
import { useContext } from 'react';
import { MyContext } from '../../../App';

export const StaffLogin = () => {
  const navigate = useNavigate();
  const { setStaff } = useContext(MyContext);
  const [showpass, setShowpass] = useState(false);
  const [isChecked, setIsChecked] = useState(localStorage.getItem('ichecked'));

  const [userData, setUserData] = useState({
    email: localStorage.getItem('StaffEmailID'),
    password: localStorage.getItem('StaffPassword')
  })
  const [errors, setErrors] = useState({
    email: '',
    password: '',
  });

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));

  }

  const loginHandler = async () => {
    let isValid = 1;

    if (isChecked) {
      localStorage.setItem('StaffEmailID', userData.email);
      localStorage.setItem('StaffPassword', userData.password);
      localStorage.setItem('ichecked', true);
    } else {
      localStorage.setItem('StaffEmailID', '');
      localStorage.setItem('StaffPassword', '');
      localStorage.setItem('ichecked', false);
    }

    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (!userData.email) {
      setErrors((pre) => ({ ...pre, email: 'Email is required' })); isValid = 2;
    } else if (userData.email && !emailPattern.test(userData.email)) {
      setErrors((pre) => ({ ...pre, email: 'Email must be a valid email address' })); isValid = 3;
    }
    if (!userData.password) {
      setErrors((pre) => ({ ...pre, password: 'Password is required' })); isValid = 4;
    } else if (userData.password && userData.password.length < 8) {
      setErrors((pre) => ({ ...pre, password: 'Password length must be  8 with at least one special character, one capital letter, one small letter and one number.' })); isValid = 4;
    }

    if (isValid === 1) {
      const fData = {
        "email": userData.email,
        "password": userData.password,
        "device_token": "",
        "device_type": "Browser",
        "role": "4"
      }
      const resp = await login(fData);
      if (resp) {
        setStaff(resp.data.data);
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" alt=''/>
        <h4 class="title text-uppercase text-center mt-4 textWhite">
        SUCCESS
      </h4>
      <p class="text-center my-4 textcolor">
      <small class="textWhite">
     ${resp.data.message} </small></p>
      `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        }).then((result) => {
          localStorage.setItem("role_id", 4);
          if (result.isConfirmed) {
            navigate("/studio");
          }
        });
      }
    }
  }
  const checkHandler = (e) => {
    setIsChecked((prev) => !prev);
  }


  return (
    <>

      <StaticHeader />
      <div className="light-bg2">
        {/* <!-- Login Content Section --> */}
        <div className="background-before">
          <div className="d-flex align-items-center justify-content-center login-height p-4 p-sm-0">
            <div className="align-item-center text-center wow animated slideInDown">
              <div className="login-box-owner white-bg box-shadow1 border-radius10 py-4 px-3">

                <div className="login-header text-center mt-4 px-5">
                  <h3 className="title dark-color mb-4"> Welcome Back!! </h3>
                  <p className="brand-color3 px-4 font-18"> Please login to check your <br /> details... </p>
                </div>
                {/* <!-- end of login-header --> */}

                <div className="logn-form mb-2">
                  <form >
                    <div className="form-group">
                      <p className="brand-color3 text-left font-14 mb-2"> Email </p>
                      <input className="form-control-1 w-100" placeholder="carl.oilver@gmail.com" value={userData.email} name='email' onChange={(e) => onChangeHandler(e)} formControlName="email" />
                      <small className='text-danger'>{errors.email}</small>
                    </div>
                    <div className="form-group mt-3 mb-1">
                      <p className="brand-color3 text-left font-14 mb-2"> Password </p>
                      <input id="password-field" type={showpass === true ? 'text' : 'password'} className="form-control-1 w-100"
                        value={userData.password}
                        onChange={(e) => onChangeHandler(e)}
                        name="password" />
                      <span onClick={() => setShowpass(!showpass)}><img src="clientassets/img/input_eye.png" className="fa fa-fw fa-eye field-icon toggle-password" alt='' toggle="#password-field" /></span>
                      <small className='text-danger'>{errors.password}</small>
                    </div>
                    <div className="d-flex my-3" style={{justifyContent:"space-between"}}>
                      <div>
                        <input type="checkbox" onChange={checkHandler} checked={isChecked}/> 
                        <span className="ml-2 mr-5">Remember Me </span>
                      </div>
                      <div>
                        <Link to="/staff-forgot" className="brand-color2 font-12 ml-5 header-css"> Forgot Password?</Link>
                      </div>
                    </div>

                    <div className="py-3">
                      <button className="btn btn-green-grad text-uppercase w-100" type="button" onClick={loginHandler} >
                        {/* <span className="spinner-grow spinner-grow-sm"  role="status" aria-hidden="true"></span>  */}
                        login </button>
                    </div>
                    <div className="text-center">
                      <p className="font-18 brand-color3"> Don't have an account? <span> <Link to="/staff-registration" className="brand-color2 header-css"> Sign up </Link></span></p>
                    </div>
                  </form>
                </div>
                {/* <!-- end of logn-form --> */}

              </div>
              {/* <!-- end of login-box --> */}
            </div>
          </div>
          {/* <!-- end of d-flex --> */}
        </div>
        {/* <!-- End of About Content Section --> */}
      </div>
    </>
  )
}
