import React, { useEffect, useState } from 'react'
import { Col, Container,  Row, Spinner } from 'react-bootstrap'
import { HeaderDashboard } from '../../comman_page/HeaderDashboard'
import MultiSelect from '../../../../../sharedComponents/MultiSelect'
import Sidebar from '../../comman_page/Sidebar'
import { get_pass_name, get_pass_type, pass_sold_new } from '../../../../../Studio-Services/Studio_Services'
import { errorAlert } from '../../../../../Static_Services/Alertmsg'
import { taxCalculator } from '../../../../../sharedComponents/MyHelperFunction'

import { Footer } from '../../../../../public/containers/footer/Footer'
import { NewDateRange } from '../../../../../sharedComponents/NewDateRange'

const moment = require('moment-timezone');
const timeZone = moment.tz.guess();

export const PassPurchase = () => {
  const [passOp, setPassOp] = useState([]);
  const [passtype, setPasstype] = useState([]);
  const [passtypeIDS, setPasstypeIDS] = useState([]);

  const [tbdata, setTbdata] = useState([]);

  const [loder, setLoder] = useState(false);

  const [passnameOP, setPassnameOP] = useState([]);
  const [passname, setPassname] = useState([]);
  const [passnameIDS, setPassnameIDS] = useState([]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [visible, setVisible] = useState([]);
  const [visibleIDS, setVisibleIDS] = useState([]);

  const [dvalue, setDvalue] = useState();

  const statusOption = [
    { value: "yes", label: "Visible" },
    { value: "no", label: "Not Visible" },
  ];

  const columns = [
    { dataField: "Type", text: "Type" },
    { dataField: "Name", text: " Name" },
    { dataField: "Amount", text: "Amount" },
    { dataField: "Tax_one", text: "Tax 01" },
    { dataField: "Tax_two", text: "Tax 02" },
    { dataField: "Total", text: "Total" },
    { dataField: "Client_Name", text: "Client Name" },
    { dataField: "Date", text: "Date" },
    { dataField: "Exp_Date", text: "Exp Date" },
    { dataField: "Active", text: "Active" },
  ];

  const array = [
    {
      Type: "Punch",
      Name: "05 Class",
      Amount: "$xx.xx",
      Tax_one: "$xx.xx",
      Tax_two: "$xx.xx",
      Total: "$xx.xx",
      Client_Name: "Rinku Sharma",
      Date: "10/10/2022",
      Exp_Date: "20/10/2022",
      Active: "Yes",
    },
  ];


  const getpass_name = async (passtypeid, visible) => {
    setLoder(true);
    let cls = [];
    const fdata = { 'passtype': passtypeid, 'visible': visible }
    const resp = await get_pass_name(fdata);
    if (resp) {
      resp.data.data.map((e) =>
        cls.push({
          value: e.id,
          label: e.pass_name,
        })
      );
      setPassnameOP(cls);
      setLoder(false);
    } else { setPassnameOP([]); }
    setLoder(false);
  }


  useEffect(() => {
    if (passtypeIDS && passtypeIDS.length > 0 && visibleIDS && visibleIDS.length > 0) {
      getpass_name(passtypeIDS, visibleIDS);
    } else setPassnameOP([]);
  }, [passtypeIDS, visibleIDS])

  useEffect(() => {
    const abc = []
    if (visible && visible.length > 0) { visible.forEach((data) => { abc.push(data.value); }) }
    setVisibleIDS(abc);
  }, [visible]);


  useEffect(() => {
    const abc = []
    if (passname && passname.length > 0) { passname.forEach((data) => { abc.push(data.value); }) }
    setPassnameIDS(abc);
  }, [passname]);

  useEffect(() => {
    const abc = []
    if (passtype && passtype.length > 0) { passtype.forEach((data) => { abc.push(data.value); }) }
    setPasstypeIDS(abc);
  }, [passtype]);


  const getPasstype = async () => {
    const fdata = { "pass_subcat": "" }
    const resp = await get_pass_type(fdata);
    if (resp) {
      let pty = [];
      (resp.data.data).map((e) => {
        pty.push({
          value: e.pass_type_id, label: e.pass_type
        })
      })
      setPassOp(pty);
    }
  }

  useEffect(() => { getPasstype(); }, [])


  const searchHandler = async () => {
    if (!startDate) { errorAlert('Please Select Start Date'); return; }
    if (!endDate) { errorAlert('Please Select End Date'); return; }
    if (!passnameIDS || passnameIDS.length <= 0) { errorAlert('Please Select Pass Name'); return; }
    const fData = {
      'startDate': startDate,
      'endDate': endDate,
      'passIDS': passnameIDS,
      'page_no': 1,
    }
    setLoder(true);
    let cos = [];
    let tc = 0; let Before_Tax = 0; let Tax1 = 0; let Tax2 = 0; let total = 0;
    const resp = await pass_sold_new(fData);
    if (resp) {
      setLoder(false);
      let tx1 = 0;
      let tx2 = 0;
      let tt_tex = 0;
      let bs = 0;
      let fnl = 0;
      resp.data.forEach(e => {
        // 
       
        if (e.passes_total_count == "3650") {
          tx1 = taxCalculator(e.amount, e.tax1_rate);
          tx2 = taxCalculator(e.amount, e.tax2_rate);
          tt_tex = parseFloat(tx1) + parseFloat(tx2);
          bs = parseFloat(e.amount);
          // -parseFloat(tt_tex); 
          let pre = parseFloat(e.amount) + parseFloat(tt_tex);
          fnl = parseFloat(pre).toFixed(2);
          //  fnl = finalAMT(e.amount, tx1, tx2);

        } else {
          tx1 = taxCalculator(e.sub_total, e.tax1_rate);
          tx2 = taxCalculator(e.sub_total, e.tax2_rate);
          tt_tex = parseFloat(tx1) + parseFloat(tx2);        
          bs = parseFloat(e.sub_total);
          // -parseFloat(tt_tex); 
          let pre = parseFloat(e.sub_total) + parseFloat(tt_tex);
          fnl = parseFloat(pre).toFixed(2);
          //  fnl = finalAMT(e.amount, tx1, tx2);
        }

        tc += 1;
        Before_Tax += parseFloat(e.amount);
        Tax1 += tx1;
        Tax2 += tx2;
        total += fnl;

        // let Exp = e.pass_expired_date ? moment.unix(e.pass_expired_date).tz(timeZone) : moment.unix(e.passes_end_date).tz(timeZone);
        // let Today = moment().tz(timeZone);

        let Exp = e.pass_expired_date ? moment.unix(e.pass_expired_date) : moment.unix(e.passes_end_date);
        let Today = moment();
        let std = '';
        if (Exp.isAfter(Today)) { std = "Active" } else { std = "Expire" }
        if (parseInt(e.passes_status) === 0) { std = "Expire" };


        cos.push({
          type: e.pass_type,
          item_name: e.item_name,

          baseamt: parseFloat(bs).toFixed(2),
          tax1: parseFloat(tx1).toFixed(2),
          tax2: parseFloat(tx2).toFixed(2),
          total: parseFloat(fnl).toFixed(2),
          client_name: e.name + ' ' + e.lastname,
          start_date: moment.unix(e.passes_start_date).format('DD MMM YYYY'),
          end_date: e.pass_expired_date ? moment.unix(e.pass_expired_date).format('DD MMM YYYY') : moment.unix(e.passes_end_date).format('DD MMM YYYY'),
          // start_date: moment.unix(e.passes_start_date).tz(timeZone).format('DD MMM YYYY'),
          // end_date: e.pass_expired_date ?  moment.unix(e.pass_expired_date).tz(timeZone).format('DD MMM YYYY') : moment.unix(e.passes_end_date).tz(timeZone).format('DD MMM YYYY'),
          mode: std,//moment.unix(e.passes_end_date).format('DD-MM-YYYY') > moment().format('DD-MM-YYYY') ? "Active" : "Expire"
        });
      });
    }
    cos.push({ type: 'Total', myclass: parseInt(tc), baseamt: parseFloat(Before_Tax).toFixed(2), tax1: parseFloat(Tax1).toFixed(2), tax2: parseFloat(Tax2).toFixed(2), total: parseFloat(total).toFixed(2), })

    setTbdata(cos);
    setLoder(false);
  }




  const exportToCsv = (data, fileName) => {
    if (data.length <= 0) { errorAlert('Please Select  Data To Be Download'); return; }
    const csv = [];
    const headers = Object.keys(data[0]);
    csv.push(headers.join(','));
    data.forEach((row) => {
      const values = headers.map(header => row[header]);
      csv.push(values.join(','));
    });
    const csvString = csv.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const clearHandler = () => {
    setLoder(true);
    setPasstype([]);
    setPasstypeIDS([])
    setTbdata([])


    setPassname([]);
    setPassnameIDS([]);
    setStartDate(null);
    setEndDate(null);

    setVisible([]);
    setVisibleIDS([]);
    setDvalue('');
    setLoder(false);

  }
  return (
    <>
      {loder && <div className="MainLoader"> <Spinner animation="border" /> </div>}
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col xl={11} lg={11} md={11}>
              <div className="RightSide">
                <HeaderDashboard />
                <section className="addClient innerContent">
                  <div className="AddClientContent">
                    <Container>
                      <h3 className='mb-4'>Pass Purchase Details</h3>

                      <Row className="mt-3">
                        <Col>
                          <MultiSelect Label={"Passes Sold By Type"} options={passOp} setSelectedOptions={setPasstype} selectedOptions={passtype} />
                        </Col>
                        <Col>
                          <MultiSelect Label={"Visibility"} options={statusOption} setSelectedOptions={setVisible} selectedOptions={visible} />
                        </Col>
                        <Col>
                          <MultiSelect Label={"Pass Name"} options={passnameOP} setSelectedOptions={setPassname} selectedOptions={passname} />
                        </Col>
                        <Col>
                          <NewDateRange setStartDate={setStartDate} setEndDate={setEndDate} label={"Date Range"} setDvalue={setDvalue} dvalue={dvalue} />
                        </Col>
                      </Row>
                      <Row className='justify-content-end'>
                        <Col className='d-flex mt-1 justify-content-end'>
                          <button type="button" className="btn btn-success m-1" onClick={searchHandler}> Run Report </button>
                          <button type="button" className="btn btn-success m-1" onClick={() => exportToCsv(tbdata, 'Pass_Purchase_Details.csv')} >
                            Export (CSV)
                          </button>
                          <button type="button" className="btn btn-danger m-1" onClick={clearHandler}>Clear Report </button>
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col>
                          <div className='table-responsive'>
                            <table className="table table-striped table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">Type</th>
                                  <th scope="col">Pass Name</th>
                                  <th scope="col">Amount</th>
                                  <th scope="col">Tax 1</th>
                                  <th scope="col">Tax 2</th>
                                  <th scope="col">Total</th>
                                  <th scope="col">Client Name</th>
                                  <th scope="col">Start Date</th>
                                  <th scope="col">Exp Date</th>
                                  <th scope="col">Active</th>
                                </tr>
                              </thead>
                              <tbody>
                                {tbdata && tbdata.length > 0 && tbdata.map((e, index) => (
                                  <tr key={index} className={index + 1 == tbdata.length ? 'highlighted highlighted-my' : ''}>
                                    <td>{e.type}</td>
                                    <td  >{e.item_name}</td>
                                    <td style={{ width: 'auto', whiteSpace: 'nowrap' }}> $ {e.baseamt}</td>
                                    <td style={{ width: 'auto', whiteSpace: 'nowrap' }}> $ {e.tax1}</td>
                                    <td style={{ width: 'auto', whiteSpace: 'nowrap' }}> $ {e.tax2}</td>
                                    <td style={{ width: 'auto', whiteSpace: 'nowrap' }}> $ {e.total}</td>
                                    <td>{e.client_name}</td>
                                    <td>{e.start_date}</td>
                                    <td>{e.end_date}</td>
                                    <td>{e.mode}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </section>
                <Footer />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}
