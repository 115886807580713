import React from 'react';

export const Innerfooter = () => {
  return (
    <footer className="footer-customer">
      <div className="container">
        <div className="footer-top py-3">
          <div className="row">
            <div className="col-12 col-md-8 col-lg-8">
              <div className="footer-left">
                <ul>
                  <li>
                    <a href="/about-us" className="header-css">
                      About us
                    </a>
                  </li>
                  <li>
                    <a href="/privacy-policy" className="header-css">
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a href="/terms-conditions" className="header-css">
                      Terms &amp; Conditions
                    </a>
                  </li>
                  <li>
                    <a href="{youtubeUrl}" target="_blank" className="header-css">
                      FAQ
                    </a>
                  </li>
                  <li>
                    <a href="/help" className="header-css">
                      Help
                    </a>
                  </li>
                  <li>
                    <a href="/contact-us" className="header-css">
                      Contact us
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-12 col-md-4 col-lg-4">
              <div className="footer-right">
                <ul>
                  <li>
                    <a href="https://www.instagram.com/warriorsms/?hl=en" target="_blank">
                      <div className="clearfix bshadow0 pbs">
                        <span className="icon-instagram font-12"></span>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/WarriorStudioManagementSoftware" target="_blank">
                      <div className="clearfix bshadow0 pbs">
                        <span className="icon-facebook font-12"></span>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/warrior_sms?lang=en" target="_blank">
                      <div className="clearfix bshadow0 pbs">
                        <span className="icon-twitter font-12"></span>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom pt-3">
          <div className="row">
            <div className="col-12">
              <div className="footer-copyright">
                <p className="brand-color3 mb-2">
                  Copyright &#169; 2020 Warrior SMS. All rights reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};


