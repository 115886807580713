import React from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom';


export const SharedDropDown = ({
  img,
  variant,
  title,
  onSelect,
  className,
  userName,
  userDetail,
  dropDownOptions,
  dropdownDivider,
}) => {

  return (
    <>
      <DropdownButton
        variant={"info"}
        className={className}
        title={title?title:''}
        onSelect={onSelect}
        style={{backgroundImage:`url(${img})`, backgroundPosition:"center", backgroundSize:"contain"}}
        // img={<img src={img}  alt="User Profile" className="profileImage" />}
      >
         
        {userName ?
          (<Dropdown.Item className={className} eventKey="4">
            <h4>{userName}</h4>
          </Dropdown.Item>)
          : ''}

        {userDetail ?
          (<Dropdown.Item className={className} eventKey="4">
            <p>{userDetail}</p>
          </Dropdown.Item>)
          : ''}

        {(dropdownDivider===true) ? <Dropdown.Divider /> : ''}

        {dropDownOptions ? dropDownOptions.map((option, index) => (
          <Dropdown.Item
            className={option.className}
            key={index}
            onClick={option.onClick} // Add onClick handler here
          >
            {option.to
              ?
              (<Link to={option.to} className={option.linkClass} style={{ textDecoration: 'none', color:'black' }}>{option.label}</Link>)
              :
              (option.label)
            }

          </Dropdown.Item>
        )) : ''}
      </DropdownButton>
    </>
  );
};
