import React from "react";
import Sidebar from "../../comman_page/Sidebar";
import { HeaderDashboard } from "../../comman_page/HeaderDashboard";
import { Container, Row, Col, Form } from "react-bootstrap";

import { Footer } from "../../../../../public/containers/footer/Footer";
import { useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { useEffect } from "react";
import { InstructorList, class_customer, class_customer_detail, get_instructor_class, get_pass_list_by_class, } from "../../../../../Studio-Services/Studio_Services";
import { Link } from "react-router-dom/dist";
import MultiSelect from "../../../../../sharedComponents/MultiSelect";
import { errorAlert } from "../../../../../Static_Services/Alertmsg";
import moment from "moment";
import { capitalizeFirstLetter, monthMap, sortByKeyAndRemoveDuplicates } from "../../../../../sharedComponents/MyHelperFunction";
import { NewDateRange } from "../../../../../sharedComponents/NewDateRange";

export const AttendanceClasses = () => {
  const [loder, setLoder] = useState(true);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [exterror, setExterror] = useState({ 'instructor': '', 'class': '', 'status': '', "client": '', 'daterange': '', 'pass_list': '' });

  const [instruct, setInstruct] = useState([]);
  const [selInst, setSelInst] = useState([]);
  const [selInstIDS, setSelInstIDS] = useState([]);
  const [instsName, setInstsName] = useState([]);

  const [dvalue, setDvalue] = useState();


  const [passList, setPassList] = useState([]);
  const [selpass, setSelpass] = useState([]);
  const [selPassIDS, setSelPassIDS] = useState([]);

  const [clsList, setClsList] = useState([]);
  const [selClass, setSelClass] = useState([]);
  const [selClassIDS, setSelClassIDS] = useState([]);

  const [costmorList, setCostmorList] = useState([]);
  const [selcostmorList, setSelcostmorList] = useState([]);
  const [selcostmorListIDS, setSelcostmorListIDS] = useState([]);

  const [selStatus, setSelStatus] = useState([]);
  const [selStatusIDS, setSelStatusIDS] = useState([]);

  const [tbdata, setTbdata] = useState([]);

  const OptionStatus = [
    { value: "singup", label: "Signed up" },
    { value: "checkin", label: "Checked In" },
    { value: "absence", label: "No Show" },
    { value: "cancel", label: "Cancelled" },
  ];

  const [statusCount, setStatusCount] = useState({ checkIn: 0, noshow: 0, canceled: 0 })

  const [monthlist, setMonthlist] = useState([]);

  const [finalChart, setFinalChart] = useState([]);





  //  ************* Get Instructor List ************* //
  const inList = async () => {
    const fdata = { pageid: 1 };
    const resp = await InstructorList(fdata);
    if (resp) {
      const inst = []; setLoder(false);
      resp.data.data.forEach((e) => {
        if (e.status === "Approve" && e.business_status === "Active") {
          inst.push({ value: e.user_id, label: `${e.name}  ${e.lastname}`, });
        }
      });
      setInstruct(inst);
    }
  };


  const getClassList = async (urid) => {
    const cls = [];
    const fdata = { userId: urid };
    const resp = await get_instructor_class(fdata);
    if (resp) {
      resp.data.data.map((e) =>
        cls.push({
          value: e.class_id,
          label: e.class_name,
        })
      );
      const uniqueArray = cls.filter((obj, index, self) =>
      index === self.findIndex((t) => t.value === obj.value)
    );
      setClsList(uniqueArray);
    } else {
      setClsList([]);
    }
  };

  const getPassList = async (cid) => {
    const cls = [];
    const fdata = { 'class_id': cid };
    const resp = await get_pass_list_by_class(fdata);
    if (resp) {
      resp.data.data.map((e) =>
        cls.push({
          value: e.pass_id,
          label: e.pass_name,
        })
      );
      setPassList(cls);
    } else {
      setPassList([]);
    }
  };

  //  *** Selected Instructor IDS *** //
  useEffect(() => {
    const abc = []
    const instName = [];
    if (selInst && selInst.length > 0) {
      selInst.forEach((data) => { abc.push(data.value); });
      selInst.forEach((data) => { instName.push(data.label); })
    }
    setInstsName(instName);
    setSelInstIDS(abc);
    getClassList(abc);
  }, [selInst]);

  //  *** Selected Classes IDS *** //
  useEffect(() => {
    const abc = []
    if (selClass && selClass.length > 0) { selClass.forEach((data) => { abc.push(data.value); }) }
    setSelClassIDS(abc);
    classHandler(abc);
    getPassList(abc);
  }, [selClass]);

  //  *** Selected Attendance Status IDS *** //
  useEffect(() => {
    const abc = []
    if (selStatus && selStatus.length > 0) { selStatus.forEach((data) => { abc.push(data.value); }) }
    setSelStatusIDS(abc);
  }, [selStatus]);

  // Pass IDS
  useEffect(() => {
    const abc = []
    if (selpass && selpass.length > 0) { selpass.forEach((data) => { abc.push(data.value); }) }
    setSelPassIDS(abc);
  }, [selpass]);

  //  *** Selected SelcostmorList  IDS *** //
  useEffect(() => {
    const abc = []
    if (selcostmorList && selcostmorList.length > 0) { selcostmorList.forEach((data) => { abc.push(data.value); }) }
    setSelcostmorListIDS(abc);
  }, [selcostmorList]);


  const classHandler = async (abc) => {
    const cos = [];
    const data = { classid: abc };
    const resp = await class_customer(data);
    if (resp) {
      const preFilterdata = resp.data.data;
      console.log('----preFilterdata---',preFilterdata.length);
      const sortedAndUniqueData = sortByKeyAndRemoveDuplicates(preFilterdata);
      sortedAndUniqueData.map((e) =>
        cos.push({
          value: e.id,
          label: `${e.name}  ${e.lastname}`,
        })
      );
      setCostmorList(cos);
    } else {
      setCostmorList([]);
    }
  };

  useEffect(() => { inList(); }, []);

  const searchHandler = async () => {
    let isValid = 1;
    if (!startDate) { setExterror((pre) => ({ ...pre, daterange: 'Required *' })); isValid = 2; }
    if (!endDate) { setExterror((pre) => ({ ...pre, daterange: 'Required *' })); isValid = 2; }
    if (startDate && endDate) { setExterror((pre) => ({ ...pre, daterange: '' })) }
    if (!selInstIDS || selInstIDS.length === 0) { setExterror((pre) => ({ ...pre, instructor: 'Required *' })); isValid = 2; } else setExterror((pre) => ({ ...pre, instructor: '' }));
    if (!selClassIDS || selClassIDS.length === 0) { setExterror((pre) => ({ ...pre, class: 'Required *' })); isValid = 2; } else setExterror((pre) => ({ ...pre, class: '' }));
    if (!selStatusIDS || selStatusIDS.length === 0) { setExterror((pre) => ({ ...pre, status: 'Required *' })); isValid = 2; } else setExterror((pre) => ({ ...pre, status: '' }));
    if (!selcostmorListIDS || selcostmorListIDS.length === 0) { setExterror((pre) => ({ ...pre, client: 'Required *' })); isValid = 2; } else setExterror((pre) => ({ ...pre, client: '' }));

    if (isValid === 1) {
      setLoder(true);

      const up = selStatusIDS.filter((item) => item == "singup");
      if(up && up.length > 0){
        selStatusIDS.push("waiting");
      }
    

      const fData = {
        start_date: startDate,
        end_date: endDate,
        instructorId: selInstIDS,
        class: selClassIDS,
        customer: selcostmorListIDS,
        attendanceStatus: selStatusIDS,
        passids: selPassIDS,
      };
      const resp = await class_customer_detail(fData);

      if (resp) {
        setLoder(false);
        let cos = [];
        const stCount = { ...statusCount };
        const rsdata = [];

        const prersdata = resp.data.data;

        prersdata.forEach(e => {
          instsName.forEach((i) => {
            const instname = e.instrcutor.replace(/\s/g, '');
            const preitname = i.replace(/\s/g, '');
            if (instname === preitname) { rsdata.push(e); }
          })
        })

        let premonthlist = [];

        rsdata.forEach((e) => {
          const month = moment(e.scheduled_date).format('YYYY-MMM');
          if (!premonthlist.includes(month)) {
            premonthlist.push(month);
          }
        });
        setMonthlist(premonthlist);


        const finalchatData = [];
        let inn = 0;
        let out = 0;
        let abc = 0;
        let upp = 0;

        if (premonthlist.length > 0) {
          let mydays = [];
          if (premonthlist.length < 2) {
            rsdata.forEach(e => {
              const ddds = moment(e.scheduled_date).format('YYYY-MMM-DD');
              if (!mydays.includes(ddds)) { mydays.push(ddds); }
            })
            mydays.forEach(d => {
              inn = 0;
              out = 0;
              abc = 0;
              upp = 0;

              rsdata.forEach(e => {
                const dddys = moment(e.scheduled_date).format('YYYY-MMM-DD');
                if (d.includes(dddys)) {
                  if (e.status == 'checkin') {
                    inn += 1;
                  } else if (e.status == 'absence') {
                    out += 1;
                  } else if (e.status == 'cancel') {
                    abc += 1;
                  } else if (e.status == 'singup' || e.status == "waiting") {
                    upp += 1;
                  }
                }
              });
              let mydata = [{ month: moment(d).format('MMM-DD'), checkIn: inn }, { month: moment(d).format('MMM-DD'), absent: out }, { month: moment(d).format('MMM-DD'), cancel: abc }, { month: moment(d).format('MMM-DD'), singup: upp }]
              finalchatData.push(mydata);
            })
          } else {
            premonthlist.forEach(m => {
              inn = 0;
              out = 0;
              abc = 0;
              upp = 0;

              rsdata.forEach(e => {
                const month = moment(e.scheduled_date).format('YYYY-MMM');
                if (m.includes(month)) {
                  if (e.status == 'checkin') {
                    inn += 1;
                  } else if (e.status == 'absence') {
                    out += 1;
                  } else if (e.status == 'cancel') {
                    abc += 1;
                  } else if (e.status == 'singup' || e.status == "waiting") {
                    upp += 1;
                  }
                }
              });
              let mydata = [{ month: m, checkIn: inn }, { month: m, absent: out }, { month: m, cancel: abc }, { month: m, singup: upp }]
              finalchatData.push(mydata);
            })
          }
        }

        setFinalChart(finalchatData);

        rsdata.forEach(e => {
          if (e.status === 'checkin') {
            stCount.checkIn += 1;
          } else if (e.status === 'absence') {
            stCount.noshow += 1;
          } else if (e.status === 'cancel') {
            stCount.canceled += 1;
          }
        });
        setStatusCount(stCount);

        rsdata.forEach((e, index) =>
          cos.push({
            hash: index,
            name: e.name,
            lastName: e.lastname,
            scheduleClass: moment(e.scheduled_date).format("DD-MMM-YYYY"),
            class: e.class,
            passname: e.passname,
            instrcutor: capitalizeFirstLetter(e.instrcutor),
            status: e.status == 'singup' || e.status == "waiting" ? 'Signed up' : e.status == 'checkin' ? 'Checked In' : e.status == 'absence' ? 'No Show' : e.status == 'cancel' ? 'Cancelled' : '',
          }));

          cos.sort((a, b) => monthMap[a] - monthMap[b]);
          cos.reverse();
        setTbdata(cos);
      } else setTbdata([]);
      setLoder(false);
    }
    setLoder(false);
  };


  //  ****************** ***  Export CSV File  *** *************************  //
  const exportToCsv = (data, fileName) => {
    if (data.length <= 0) { errorAlert('Please Select  Data To Be Download'); return; }
    const csv = [];

    // Add headers to CSV
    const headers = Object.keys(data[0]);
    csv.push(headers.join(','));

    // Add data rows to CSV
    data.forEach((row) => {
      const values = headers.map(header => row[header]);
      csv.push(values.join(','));
    });

    // Convert CSV array to string
    const csvString = csv.join('\n');

    // Create a blob object and create a URL for the blob
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    // Create a link element, set its attributes, and trigger a download
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  useEffect(() => {
    if (selInst.length > 0) { setExterror((pre) => ({ ...pre, instructor: '' })) };
    if (selClass.length > 0) { setExterror((pre) => ({ ...pre, class: '' })) };
    if (selStatus.length > 0) { setExterror((pre) => ({ ...pre, status: '' })) };
    if (selcostmorList.length > 0) { setExterror((pre) => ({ ...pre, client: '' })) };
  }, [selInst, selClass, selStatus, selcostmorList])

  const clearHandler = () => {
    setSelInst([]);
    setSelClass([]);
    setSelpass([]);
    setSelStatus([]);
    setSelcostmorList([]);
    setDvalue('');
    setTbdata([]);
  }



  return (
    <>
      {loder && <div className="MainLoader"> <Spinner animation="border" /> </div>}

      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col>
              <HeaderDashboard />
              <div className="RightSide">
                <div className="innerContent">
                  <section className="ClassAttendance">
                    <Container>
                      <Form >
                        <Row className="mt-3">
                          <Col md={4}>
                            <MultiSelect options={instruct} Label={"Instructor"} setSelectedOptions={setSelInst} selectedOptions={selInst} error={exterror.instructor} />
                          </Col>
                          <Col md={4}>
                            <MultiSelect Label={"Class"} options={clsList} setSelectedOptions={setSelClass} selectedOptions={selClass} error={exterror.class} />
                          </Col>
                          <Col md={4}>
                            <MultiSelect Label={"Pass List"} options={passList} setSelectedOptions={setSelpass} selectedOptions={selpass} error={exterror.pass_list} />
                          </Col>

                        </Row>
                        <Row className="mt-3">
                          <Col md={4}>
                            <MultiSelect Label={"Status"} options={OptionStatus} setSelectedOptions={setSelStatus} selectedOptions={selStatus} error={exterror.status} />
                          </Col>
                          <Col md={4}>
                            <MultiSelect Label={"Client"} options={costmorList} setSelectedOptions={setSelcostmorList} selectedOptions={selcostmorList} error={exterror.client} />
                          </Col>
                          <Col md={4}>
                            <NewDateRange
                              setDvalue={setDvalue}
                              dvalue={dvalue}
                              setStartDate={setStartDate}
                              setEndDate={setEndDate}
                              label={"Date Range"}
                              error={exterror.daterange}
                            />
                          </Col>
                          {/* <Col md={1}>
                            <SharedButton
                              type={"button"}
                              onClick={searchHandler}
                              iconNode={<BiSearchAlt />}
                              className={"btn btn-outline SearchButtonIcon"}
                            />
                          </Col> */}
                        </Row>
                        <Row className="mt-4">
                          <Col className="d-flex justify-content-end">
                            <button type="button" className="btn btn-success m-1" onClick={searchHandler}>Run Report</button>
                            <button type="button" className="btn btn-success m-1" onClick={() => exportToCsv(tbdata, 'attendance_data.csv')} > Export (CSV) </button>
                            <button type="button" className="btn btn-danger m-1" onClick={clearHandler}>Clear Report </button>
                            <Link to="/AttendanceChart" state={{ mlist: monthlist, atdCount: finalChart }}>
                              <button className="btn btn-info m-1" style={{ color: '#fff' }}> Status Chart  </button>
                            </Link>
                          </Col>
                        </Row>
                      </Form>
                      <Row className="mt-3">
                        <Col>
                          <Row >
                            <Col>
                              <table class="table table-bordered table-striped">
                                <thead>
                                  <tr>
                                    <th scope="col">Date</th>
                                    <th scope="col">Class</th>
                                    <th scope="col">Instructor</th>
                                    <th scope="col">Client</th>
                                    <th scope="col">Pass Name</th>
                                    <th scope="col">Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {tbdata && tbdata.length > 0 && tbdata.map((e, index) => (
                                    <tr key={index}>
                                      <td>{e.scheduleClass}</td>
                                      <td >{e.class}</td>
                                      <td>{e.instrcutor}</td>
                                      <td>{e.name + ' ' + e.lastName}</td>
                                      <td>{e.passname}</td>
                                      <td>{e.status}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Container>
                  </section>
                </div>
                <Footer />
              </div>
            </Col>
          </Row >
        </div >
      </div >
    </>
  );
};
