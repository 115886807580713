import React from 'react'
import {Row, Col } from "react-bootstrap";
import { InputField } from '../../../../../sharedComponents/InputField';
import { useState } from 'react';
import { useEffect } from 'react';
import { SharedButton } from '../../../../../sharedComponents/Button';
import { DataTable } from '../../../../../sharedComponents/DataTable';
import { list_shift } from '../../../../../Studio-Services/Studio_Services';
import moment from 'moment/moment';
import { Link } from 'react-router-dom';
import { DateRange } from '../../../../../sharedComponents/DateRange';

export const Shifttable = () => {

  
  const [startDate , setStartDate ] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startValue,setStartValue] = useState([]);
  const [endValue,setEndValue] = useState([]);
    const [shiftList, setShiftList] = useState();

    const [shift_status,setShift_status] = useState(0);
    const array = [];

    const ShiftList = async () => {
      let start = "";
      let edate = "";
      if (startDate != null && endDate != null) {
        start = startDate;
        edate = endDate;
      } else {
        start = moment().format("YYYY-MM-DD");
        edate = moment().add(3, "year").format("YYYY-MM-DD");
      }
      setStartValue(start);
      setEndValue(edate);
      const request = {
        page_no: 1,
        shift_status: shift_status ? shift_status : 0,
        start_date: start,
        end_date: edate,
      };
        const resp = await list_shift(request);
        if (resp) {
          setShiftList(resp.data);
        }
      };
    
      
      useEffect(() => {
        ShiftList();
      }, []);
      useEffect(()=>{
        ShiftList();
      },[shift_status])
    
      const Time = (timestamp) => {
        const date = new Date(timestamp * 1000); // Multiply by 1000 if the timestamp is in seconds
        const time = date.toLocaleTimeString(undefined, {
          hour: "2-digit",
          minute: "2-digit",
        });
        return time;
      };

    const columns = [
        { dataField: "Shift", text: "Shift" },
        { dataField: "Date", text: "Date" },
        { dataField: "day", text: "Day" },
        { dataField: "Instructor", text: "Instructor" },
        { dataField: "Time_Slot", text: "Time Slot" },
        { dataField: "Pay_Type", text: "Pay Type" },
        { dataField: "Pay_Rate", text: "Pay Rate" },
        { dataField: "Location", text: "Location" },
        { dataField: "Status", text: "Status" },
        { dataField: "Action", text: "Action" },
      ];


      if (shiftList) {
        shiftList.forEach((element) => {
          array.push({
            Shift: element.shift_name,
            Date: element.shift_date_str,
            day: element.week_name,
            Instructor: element.instructor,
            Time_Slot: moment.unix(element.start_time).format('hh:mm A') + " to " + moment.unix(element.end_time).format('hh:mm A'),
            Pay_Type: element.pay_type,
            Pay_Rate: element.pay_rate,
            Location: element.location_name,
            Status: element.shift_schedule_status_name,
            Action:<Link to="/list-shift" state={{data:element}} ><i _ngcontent-gft-c168="" className="fa fa-eye"></i></Link>,
          });
        });
      }
      
      const searchHandler =()=>{
        return;
      }
   
    return (
        <>
            <Row className="mt-3">
                <Col>
                    <Row className="my-4 mx-3">
                        <Col>
                            <h5>SHIFTS LIST</h5>
                        </Col>
                        <Col>
                          <DateRange
                          startValue={startValue}
                          endValue={endValue}
                          setStartDate={setStartDate}
                          setEndDate={setEndDate}
                           />
                        </Col>
                        <Col>
                            <SharedButton
                            onClick={searchHandler}
                             title={"Shift schedule"} />
                        </Col>
                        <Col>
                        <Link to={"/add-shifts"}>
                            <SharedButton title={"Add new"} />
                        </Link>
                        </Col>
                    </Row>
                    <Row>
                      <Col>
                      <SharedButton title={"Active"} onClick={()=>setShift_status(0)} className={shift_status === 0 ?"btn btn-secondary ActiveBtn m-1" :"btn btn-outline m-1"}/>
                      <SharedButton title={"Cancel"} onClick={()=>setShift_status(2)} className={shift_status===2 ? "btn btn-secondary ActiveBtn m-1" :"btn btn-outline m-1"}/>
                      </Col>
                    </Row>
                    <DataTable data={array} columns={columns} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <SharedButton
                    href="/add-items"
                     title={"Done With Shifts"} />
                </Col>
            </Row>

        </>
    )
}
