import React, { useEffect, useState } from 'react'
import HeaderChousePlan from '../header/HeaderChousePlan'
import StaticFooter from '../../../staticpagis/StaticFooter'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'
import { MyContext } from '../../../App'
import { useContext } from 'react'
import { InputField } from '../../../sharedComponents/InputField'
import { ExpiryCheck } from '../../../Static_Services/Staff_Services'
import { purchasePlan, purchasePlan_New } from '../../../Studio-Services/Studio_Services'
import logo from '../../../Images/logo_white_web.png'
import Swal from 'sweetalert2'
import { Spinner } from 'react-bootstrap'

export const Paycardsetup = () => {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const planId = queryParams.get('plid');
    const [plans_id, setPlans_id] = useState();
    const [selectedPlandata, setSelectedPlandata] = useState();
    const [signupData,setSignupData] = useState();
    const navigate = useNavigate();
    const [loder,setLoder] = useState(false);



    const { context } = useContext(MyContext);
    // console.log(context);

    useEffect(() => {
        if (location && location.state && location.state.plan_id) {
            setPlans_id(location.state.plan_id);
        }
        if (localStorage.getItem('st_selected-plan-info')) {
            setSelectedPlandata(JSON.parse(localStorage.getItem('st_selected-plan-info')));
        }
         setSignupData(JSON.parse(localStorage.getItem('st_userRegisterData')));
    }, [location])


    const planDays = process.env.REACT_APP_planDays;
    const selectPlan = "";
    const cardSaveMessage = "";
    const paymentErrorMessage = "";

    const [userPaymentInfo, setUserPaymentInfo] = useState({
        firstname: '',
        lastname: '',
        cardnumber: '',
        expirydate: '',
        cvv: '',
        policyagreement: false,
    });
    const [submitted, setSubmitted] = useState(false);
    const [paycardSpinner, setPaycardSpinner] = useState(false);
    //const selectedPlandata = {}; // Add the necessary data for the selected plan

    const handleChange = (e) => {
        const { name, value, checked } = e.target;
        setUserPaymentInfo((prevState) => ({
            ...prevState,
            [name]: name === 'policyagreement' ? checked : value,
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitted(true);
        let isValid = 1;
        let expiry_month = '';
        let expiry_year = '';
        let expired = '';
    
        if (expiryDate) {
            expired = ExpiryCheck(expiryDate);
        }
        if (expired) {
            expiry_month = expired[0];
            expiry_year = expired[1];
        } else { setCarderror((pre) => ({ ...pre, dates: "* Valid Till is required" }));  isValid = 2; }
        if (!cardData.fname) { setCarderror((pre) => ({ ...pre, fname: "* First Name is required" })); isValid = 3; };
        if (!cardData.lname) { setCarderror((pre) => ({ ...pre, lname: "* Last Name is required" })); isValid = 3; };
        if (!cardData.crnumber) { setCarderror((pre) => ({ ...pre, crnumber: "* Card Number is required" }));  isValid = 4; };
        if (!cardData.cvv) { setCarderror((pre) => ({ ...pre, cvv: "* CVV is required" })); isValid = 5; };

        if (!cardData.radio) { setCarderror((pre) => ({ ...pre, radio: "* Please Select County Code" })); isValid = 6; };
        if (!cardData.agree) { setCarderror((pre) => ({ ...pre, agree: "* You must agree to the terms" })); isValid = 6; };
       
        if (isValid===1) {
            setLoder(true);
            var finalData = {
                "name": cardData.fname,
                "lastname": cardData.lname,
                "user_id":signupData.id,
                "plan_id":selectedPlandata.plan_id,
                "amount":selectedPlandata.amount,
                // "amount":1,
                "card_number": cardData.crnumber,
                "cvv_no": cardData.cvv,
                "expiry_month":expiry_month,
                "expiry_year":expiry_year,
                "save_card_check":1,
                "token" :'',
                "country_code" :cardData.radio,
                "pre_plan_id" : 0,
              }
              const resp = await purchasePlan_New(finalData);
              if(resp){   
                setLoder(false);    
                localStorage.setItem('st_userData', JSON.stringify(signupData));   
              Swal.fire({
                html: `
                  <img src=${logo} class="img-fluid d-block mx-auto mt-5" alt='' />
                  <h4 class="title text-uppercase text-center mt-4 textWhite">Success</h4>
                  <p class="text-center my-4 textcolor">
                    <small class="textWhite">${resp.data.message}</small>
                  </p>
                `,
                showClass: {
                  popup: 'custom-dialog-container',
                },
                customClass: {
                  confirmButton: 'custom-button custom-button-size modalbtn',
                },
                confirmButtonText: 'Continue',
              }).then((result) => {
                if (result.isConfirmed) {
                  navigate('/business-profile');
                }
              });
            }
            setLoder(false);  
        }
    };


    const onchangePlan = () => {
        navigate('/choose-plan', { state: { backURL: '/paycard-setup' } })
    }


    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        if (e.nativeEvent.inputType === "deleteContentBackward") {
            if (inputValue.endsWith('/')) {
                setExpiryDate(inputValue.slice(0, -1));
            } else { setExpiryDate(inputValue); }
        } else {
            if (inputValue.length === 2 && inputValue.charAt(2) !== '/') {
                setExpiryDate(inputValue + '/');
            } else if (inputValue.length === 8) { return; } else { setExpiryDate(inputValue); }
        }
    };

    const [expiryDate, setExpiryDate] = useState();
    const [carderror, setCarderror] = useState({ agree: '', radio: '', fname: '', lname: "", crnumber: "", dates: "", cvv: "" });
    const [cardData, setCardData] = useState({ agree: '', radio: '', fname: '', lname: "", crnumber: "", dates: "", cvv: "" });
    const CardInputChange = (e) => {
        const { name, value } = e.target;
        const cleanedValue = value.replace(/^\s+/g, '');
        setCardData((pre) => ({ ...pre, [name]: cleanedValue }));
        setCarderror((pre) => ({ ...pre, [name]: "" }));
    }

    return (
        <>
          {loder ? <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div> : ""}
            <HeaderChousePlan />
            <div className="middle-content-wrp" style={{ paddingTop: "120px" }}>
                {/* image layer */}
                <div className="signwrapp-back-layer">
                    <img src="assets/images/pictures/watermark_bg.png" alt="Watermark" />
                </div>
                {/* img layer */}
                <div className="container py-5 relative">
                    <div className="stepsinfo-fill">
                        <ul className="steps allstep_complete">
                            <li className="completed-step"></li>
                            <li className="completed-step"></li>
                            <li className="completed-step"></li>
                        </ul>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-6 col-lg-5 col-xl-5">
                            <div className="middle-innerbox py-4 px-4 setupcard-sec">
                                <span className="brand-light text-uppercase d-block mt-2">Step 3 of 3</span>
                                <h4 className="font-20 mt-2">Setup your credit or debit card.</h4>
                                <div className="d-flex align-items-center mb-4">
                                    <img src="assets/images/icons/ic_visacard.png" className="ml-1" alt="Visa Card" />
                                    <img src="assets/images/icons/ic_mastercard.png" className="ml-1" alt="Mastercard" />
                                    {/* <img src="assets/images/icons/ic_dinersclubcard.png" className="ml-1" alt="Diners Club Card" />
                                    <img src="assets/images/icons/ic_americancard.png" className="ml-1" alt="American Card" /> */}
                                </div>
                                <form autoComplete="off" onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label>First Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            name='fname'
                                            placeholder=""
                                            value={cardData.fname}
                                            onChange={CardInputChange}
                                        />
                                        <small className="text-danger">{carderror.fname}</small>
                                    </div>
                                    <div className="form-group">
                                        <label>Last Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            name='lname'
                                            placeholder=""
                                            value={cardData.lname}
                                            onChange={CardInputChange}
                                        />
                                        <small className="text-danger">{carderror.lname}</small>

                                    </div>
                                    <div className="form-group">
                                        <label>Card Number</label>
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            className="form-control"
                                            placeholder="4121 4411 3131 8554"
                                            name='crnumber'
                                            value={cardData.crnumber}
                                            onChange={CardInputChange}
                                            maxLength={19}
                                        />
                                        <small className="text-danger">{carderror.crnumber}</small>
                                    </div>
                                    <div className="form-group">
                                        <label>Expiry Date (MM/YYYY)</label>
                                        <input
                                            type="text"
                                            name={'dates'}
                                            className="form-control"
                                            placeholder="MM/YYYY"
                                            value={expiryDate}
                                            onChange={handleInputChange}
                                            pattern="^[0-9]{2}/[0-9]{4}"
                                            autoComplete="new-password"
                                            lable={"Valid Through"}
                                        />
                                        <small className="text-danger">{carderror.dates}</small>
                                    </div>
                                    <div className="form-group">
                                        <label>Security Code (CVV)</label>
                                        <input
                                            type="password"
                                            autoComplete="off"
                                            className="form-control"
                                            placeholder="eg. 201"
                                            name='cvv'
                                            value={cardData.cvv}
                                            onChange={CardInputChange}
                                            maxLength={3}
                                        />
                                        <small className="text-danger">{carderror.cvv}</small>
                                    </div>
                                    <div className="form-group">
                                        <div className="default-tab d-flex p-3">
                                            <p className="font-w-500 m-0 font-13">
                                                {selectedPlandata && selectedPlandata.plan_name} <br />{' '}
                                                <span className="brand-light">
                                                    $ {selectedPlandata && selectedPlandata.amount}/{selectedPlandata && selectedPlandata.plan_type}
                                                </span>
                                            </p>
                                            <button onClick={onchangePlan} className="d-block brand-blue ml-auto">
                                                Change
                                            </button>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="d-flex flex-wrap">
                                            <label className="mr-5">Country Code</label>
                                            <input
                                                type="radio"
                                                name="radio"
                                                className="radio-custom mr-3 ml-3"
                                                value="1"
                                                checked={cardData.radio === '1'}
                                                onChange={CardInputChange}
                                            />
                                            <label htmlFor="1">US</label>
                                            <input
                                                type="radio"
                                                name="radio"
                                                className="radio-custom mr-3 ml-3"
                                                value="2"
                                                checked={cardData.radio === '2'}
                                                onChange={CardInputChange}
                                            />
                                            <label htmlFor="2">Canada</label>
                                            <small className="text-danger">{carderror.radio}</small>
                                        </div>
                                    </div>
                                    <p>
                                        By Ticking the below box, you agree to our{' '}
                                        <Link href="/terms-conditions" target="_blank" className="brand-blue">
                                            Terms of use
                                        </Link>{' '}
                                        and{' '}
                                        <Link href="/privacy-policy" target="_blank" className="brand-blue">
                                            Privacy Statement
                                        </Link>{' '}
                                        and that you are over 18. Your {planDays} day free trials starts immediately, after which you will be
                                        billed for your first monthly payment based on the package you have selected. Warrior SMS will
                                        automatically continue your membership and charge the applicable membership fee to your payment method on
                                        a monthly basis until you cancel.
                                    </p>
                                    <div className="custom-control custom-checkbox mb-3">
                                        <input
                                            type="checkbox"
                                            className={`custom-control-input ${submitted && !userPaymentInfo.policyagreement ? 'is-invalid' : ''
                                                }`}
                                            id="agreeTerms"
                                            name="agree"
                                            value="Agree"
                                            checked={cardData.agree === 'Agree'}
                                            onChange={CardInputChange}
                                        />
                                        <label className="custom-control-label" htmlFor="agreeTerms">
                                            Agree
                                        </label>
                                        <small className="text-danger">{carderror.agree}</small>
                                    </div>
                                    <button
                                        className="btn btn-brandbluegrad px-5 d-block mx-auto mt-4"
                                        type="submit"
                                        disabled={paycardSpinner}
                                    >
                                        {paycardSpinner && <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" />}
                                        Start Membership
                                    </button>
                                    <button
                                        className="btn"
                                        type="button"
                                        id="opensuccessModal"
                                        data-toggle="modal"
                                        data-target="#paymentInfosuccessModal"
                                        style={{ display: 'none' }}
                                        data-backdrop="static"
                                        data-keyboard="false"
                                    />
                                    <button
                                        className="btn"
                                        type="button"
                                        id="openerrorModal"
                                        data-toggle="modal"
                                        data-target="#paymentInfoerrorModal"
                                        style={{ display: 'none' }}
                                        data-backdrop="static"
                                        data-keyboard="false"
                                    />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* </div> */}
            {/* // </div> */}

            {/* payement info success modal */}
            <div className="modal fade successModal" id="paymentInfosuccessModal" tabIndex="-1" role="dialog" aria-labelledby="" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content relative">
                        <div className="modal-body p-4">
                            <img src="assets/images/logo_white_web.png" className="img-fluid d-block mx-auto mt-5" alt="Logo" />
                            <h4 className="title text-uppercase text-white text-center mt-4">Success</h4>
                            <p className="text-center text-white my-4">{cardSaveMessage}</p>
                            <Link to="/business-profile" className="btn-brandwhite text-uppercase w-100 mt-4">
                                Go to your Business Profile
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            {/* payement info error modal */}
            <div className="modal fade errorModal" id="paymentInfoerrorModal" tabIndex="-1" role="dialog" aria-labelledby="" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content relative">
                        <div className="modal-body p-4">
                            <img src="assets/images/logo_white_web.png" className="img-fluid d-block mx-auto mt-5" alt="Logo" />
                            <h4 className="title text-uppercase text-white text-center mt-4">Payment Failed</h4>
                            <p className="text-center text-white my-4">{paymentErrorMessage}</p>
                            <button type="button" className="btn-brandwhite text-uppercase w-100 mt-4" data-dismiss="modal" aria-label="Close">
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* </div > */}


            <StaticFooter />
        </>
    )
}
