import React from 'react'
import { Link } from 'react-router-dom';
const userDetail ="";

export default function HeaderChousePlan() {
  return (
   <>
<>
  {/* /////////////// --- HEADER AND NAVIGATION END --- ////////////// */}
  <header className="navigationheader pl-0 box-shadow-greenlight bg-white lessminheight">
    <div className="container">
      <nav className="navbar navbar-expand px-0">
        {userDetail ? 
        <Link to="/" className="navbar-brand">
          <img src="assets/images/logo_top_new.png" className="img-fluid" />
        </Link>
         :
        <Link to="/" className="navbar-brand">
          <img src="assets/images/logo_top_new.png" className="img-fluid" />
        </Link>
        }
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarTogglerDemo03"
        >
          <ul className="navbar-nav ml-auto">
            {userDetail ?
            <li className="nav-item dropdown">
              <a style={{ cursor: "pointer" }} className="nav-link font-w-500">
                Logout
              </a>
            </li>
            :
            <li className="nav-item dropdown">
              <Link to="/login" className="nav-link font-w-500">
                Sign In
              </Link>
            </li>
            }
          </ul>
        </div>
      </nav>
    </div>
  </header>
</>


   </>
  )
}
