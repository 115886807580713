import React, { useContext } from 'react'
import './Studio.css';
import { InnerHeader } from '../../Components/inner-header/InnerHeader1'
import { Innerfooter } from '../../Components/inner-footer/Innerfooter1'
import { useState } from 'react';
import { API_my_studio_list, my_studio_list } from '../../../Static_Services/StaticServices';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MyContext } from '../../../App';
import { StudioCart } from '../../Components/CommanPages/StudioCart';
import { Spinner } from 'react-bootstrap';


export const Studio = () => {
  const [userDetail, setUserDetail] = useState([]);
  const [studiolist, setStudiolist] = useState([]);
  const navigate = useNavigate();
  const [loder, setLoder] = useState(false);


  useEffect(() => {
    if (localStorage.getItem("userDetail")) {
      setUserDetail(JSON.parse(localStorage.getItem("userDetail")));
    }
  }, [])



  const getStudio = async () => {
    if (userDetail) {
      var detail = { "pageid": "1", 'lat': userDetail.lat, 'lang': userDetail.lang };
      if (userDetail.role_id == 3) {
        const resp = await API_my_studio_list(detail);
        if (resp) {
          setStudiolist(resp.data.data)
          setLoder(false);
        } else {
          setLoder(false);
        }

      } else {
        const resp = await my_studio_list(detail);
        if (resp) {
          setStudiolist(resp.data.data);
          setLoder(false);
        } else {
          setLoder(false);
        }
      }
    }
  }

  // const getData = async () => {
  //   const fData = {
  //     "pageid": "1",
  //     "lat": localStorage.getItem('lat'),
  //     "lang": localStorage.getItem('lang')
  //   }
  //   const resp = await my_studio_list(fData);
  //   if (resp) {
  //     setStudiolist(resp.data.data);
  //   }
  // }

  useEffect(() => {
    if (userDetail) {
      getStudio();
      localStorage.setItem("bzid", '');
    }
  }, [userDetail])

  const ClickHandler = (businessId) => {

    localStorage.setItem('w_detailBack', '/my-home');
    localStorage.setItem("bzid", businessId);
    navigate('/detail', { state: { Id: businessId }});
  }

  return (
    <>
      {loder ? <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div> : ""}

      <InnerHeader />
      <div className="body-grey">
        {/* <!-- My Studios Section --> */}
        <div className="my-studio py-5 internal-studio">
          <div className="studio-cards">
            <div className="container">
              <div className="row">
                <div className="col-12 pb-3">
                  <div className="section-heading">
                    <h5 className="title black-color font-w-100 text-uppercase font-16"> my studios </h5>
                  </div>
                </div>
                  < StudioCart studiolist={studiolist} onClick={ClickHandler} getData={getStudio} />
                {/* <!-- End of studio-cards --> */}
              </div>
              <div className="row  border-radius6 p-3" >
              </div>
            </div>
          </div>
        </div>
      </div>
      <Innerfooter />

    </>
  )
}
