import React from 'react'
import StaticHeader from '../../StaticHeader'
import StaticFooter from '../../StaticFooter'
import { Aboutus } from '../../static-service/StaticServices'
import { useEffect } from 'react'
import { useState } from 'react'
import './StaticAbout.css'




export const StaticAbout = () => {
  const [content, setContent] = useState();

  const AboutData = async () => {
    const resp = await Aboutus();
    setContent(resp.content);

  }

  useEffect(() => {
    AboutData();
  }, [])

  return (
    <>
      <StaticHeader />
      
      {/* <!-- About Content Section --> */}

      <section className="about-content-height">
      <div className="background-before">
        <div className="py-4 mt-2">
          <div className="container">
            <div className="text-left wow animated slideInLeft py-3">
              <h5 className="dark-color font-w-800 pb-4"> About Us </h5>
              <div dangerouslySetInnerHTML={{ __html:content}}  className='AboutSection'></div>
            </div>
          </div>
        </div>
      </div>
    </section>


      {/* <!-- End of About Content Section -->  */}

      <StaticFooter />
    </>

  )
}
