import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { CurrentDate } from "../../../../../sharedComponents/CurrentDate";
import { SharedButton } from "../../../../../sharedComponents/Button";
import { IoIosArrowBack } from "react-icons/io";
import { RiEditFill } from "react-icons/ri";
import { CardCarousel } from "../../../../../sharedComponents/CardCarousel";
import { MemberShipCard } from "../../../../../sharedComponents/MemberShipCard";
import { ClassesCard } from "../../../../../sharedComponents/ClassesCard";
import { ClassScheduleModal } from "../../../../../sharedComponents/ClassScheduleModal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { InputField } from "../../../../../sharedComponents/InputField";
import { InstructorList, getClassDetails, update_multiple_business_status, cancel_single_class } from "../../../../../Studio-Services/Studio_Services";
import { useRef } from "react";
import moment from "moment";
import Sidebar from "../../comman_page/Sidebar";
import { HeaderDashboard } from "../../comman_page/HeaderDashboard";
import { Footer } from "../../../../../public/containers/footer/Footer";


export const CalenderShedul = (props) => {
   const location = useLocation();
    const preFillData = location.state.eventDescription || {}
    const [preclass_id , setPreclass_id] = useState();
    const [preschedule_id , setPreschedule_id] = useState();
    const [prescheduled_date , setPrescheduled_date] = useState();

    const [preinf,setPreinf] = useState();

 

  const preinfo = props.preinfo ? props.preinfo : '';
  const [classDetail, setClassDetail] = useState([]);
  const [insructor, setInstructor] = useState([]);
  const [timepass, setTimepass] = useState([]);
  const [recurrigpass, setRecurrigpass] = useState([]);
  const [punchpass, setPunchpass] = useState();
  const [num, setNum] = useState();
  const inputRef = useRef();
  const navigate = useNavigate();
  const getInstructorList = async () => {
    const pageId = { "pageid": 1 }
    const resp = await InstructorList(pageId);
    if (resp) {
      setInstructor(resp.data.data);
    }
  }

  const classDetails = async () => {
    const fData ={
        "business_id": localStorage.getItem("business_id"),
        "class_id": preFillData[0].class_id,
        "scheduled_date": preFillData[2].scheduled_date,
        "schedule_id": preFillData[1].schedule_id
      }

      setPreinf({
        business_id: localStorage.getItem("business_id"),
        class_id: preFillData[0].class_id,
        scheduled_date: preFillData[2].scheduled_date,
        schedule_id: preFillData[1].schedule_id
      })
    const responce = await getClassDetails(fData);
    if (responce) {
      setClassDetail(responce.data.data);

    }

  }
  const numChangeHandler = (event) => {
    setNum(event.target.value);
  }

  
  useEffect(()=>{
    if(preFillData.length>0){
        classDetails();
        setPreclass_id(preFillData[0].class_id);
        setPreclass_id(preFillData[1].schedule_id);
        setPreclass_id(preFillData[2].scheduled_date);
    }
},[preFillData])

 
  useEffect(() => {
    if (classDetail.upcomming_count != "") {
      inputRef.current.value = classDetail.upcomming_count;
      setNum(inputRef.current.value);
    }
  }, [classDetail.upcomming_count]);

  useEffect(() => {
    getInstructorList();
  }, []);

  useEffect(() => {
    if (classDetail.passes_details) {
      let passlist = [];
      passlist = classDetail.passes_details;

      const timep = [];
      const recuring = [];
      const punch = [];

      if (passlist.length > 0) {
        const logo = localStorage.getItem("logo");
        passlist.forEach((value) => {
          if (value.pass_type === "Time Frame") {
            timep.push({
              memberShipImg: logo ? logo : "https://staging.warriorsms.com/superadmin/uploads/business/1664736345WarriorMan_icon2_CMYK.png",
              validText: value.pass_days,
              cardTitle: value.pass_name,
              price: `$ ${value.amount}`,
              tax: "Plus Tax"
            })
          } else if (value.pass_type === "Recurring Membership") {
            recuring.push({
              memberShipImg: logo ? logo : "https://staging.warriorsms.com/superadmin/uploads/business/1664736345WarriorMan_icon2_CMYK.png",
              validText: value.pass_days,
              cardTitle: value.pass_name,
              price: `$ ${value.amount}`,
              tax: "Plus Tax"
            })
          } else if (value.pass_type === "Punch Card") {
            punch.push({
              memberShipImg: logo ? logo : "https://staging.warriorsms.com/superadmin/uploads/business/1664736345WarriorMan_icon2_CMYK.png",
              validText: value.pass_days,
              cardTitle: value.pass_name,
              price: `$ ${value.amount}`,
              tax: "Plus Tax"
            })
          }
        });
        if (timep.length > 0) {
          setTimepass(timep);
        }
        if (recuring.length > 0) {
          setRecurrigpass(recuring);
        }
        if (punch.length > 0) {
          setPunchpass(punch);
        }
      }

    }
  }, [classDetail.passes_details]);

  const updateClass = async () => {
    let formData = "";
    if (classDetail.upcomming_count > num) {
      const dif = (classDetail.upcomming_count) - num;
      formData = {
        "business_id": classDetail.business_id,
        "class_id": classDetail.class_id,
        "schedule_id": classDetail.schedule_id,
        "cancel": dif,
        "from_time": classDetail.from_time,
        "to_time": classDetail.to_time,
        "location_id": classDetail.location_id,
      }

    } else {
      formData =
      {
        "business_id": classDetail.business_id,
        "class_id": classDetail.class_id,
        "schedule_id": classDetail.schedule_id,
        "cancel": num,
        "from_time": classDetail.from_time,
        "to_time": classDetail.to_time,
        "location_id": classDetail.location_id,
        "upcomming_count": classDetail.upcomming_count
      }
    }
    if (formData) {
      const resp = await update_multiple_business_status(formData);
      if (resp.data.status === 1) {
        classDetails();
      }
    }
  }

  const cancelClass = async () => {
    const cancelFormData = {
      "business_id": classDetail.business_id,
      "class_id": classDetail.class_id,
      "schedule_id": classDetail.schedule_id,
      "from_time": classDetail.from_time,
      "to_time": classDetail.to_time,
      "location_id": classDetail.location_id
    }
    const resp = await cancel_single_class(cancelFormData);
    if (resp.data.status === 1) {
      navigate("/classes");
    }
  }
  

  return (
    <>
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col>
                <HeaderDashboard />
              <div className="RightSide">
                <div className="innerContent">

            {classDetail ?
                <Container>
                <section className="ClassesDetails">
                    <div className="ClassesDetails">
                    <div className="ScheduleHeading">
                        <h5 className="classesHeading">
                        <Link to="/Home"><IoIosArrowBack />Classes Schedule Details</Link>
                        </h5>
                    </div>
                    
                    <div className="DetailsBox">
                        <div className="HeadDetail">
                        <h5>CLASS DETAILS</h5>
                        <SharedButton
                            title={"Cancel"}
                            className={"cancelBtn"}
                            type={"butoon"}
                            onClick={cancelClass}
                            variant={"outline"}
                        />
                        </div>
                        <Row className="mt-3">
                        <Col>
                            <div className="TextDetail">
                            <span>Class Name</span>
                            <p>{classDetail.class_name}</p>
                            <h6>Cancel Class</h6>
                            </div>
                        </Col>
                        <Col>
                            <div className="TextDetail">
                            <span>Date</span>
                            <p>{classDetail.scheduled_date}</p>
                            {/* <h6>
                            <CurrentDate />
                            </h6> */}
                            </div>
                        </Col>
                        <Col>
                            <div className="TextDetail">
                            <p>Attendance</p>
                            <h6>{classDetail.attendence}</h6>
                            </div>
                        </Col>
                        <Col>
                            <div className="TextDetail">
                            <span>Day</span>
                            <p>{classDetail.day_name}</p>
                            </div>
                        </Col>
                        </Row>
                        <Row className="mt-3">
                        <Col>
                            <div className="TextDetail">
                            <p>Start Time</p>
                            <h6>{moment.unix(classDetail.from_time).format('hh:mm A')}</h6>
                            </div>
                        </Col>
                        <Col>
                            <div className="TextDetail">
                            <p>End Time</p>
                            <h6>{moment.unix(classDetail.to_time).format('hh:mm A')}</h6>
                            </div>
                        </Col>
                        <Col>
                            <div className="TextDetail">
                            <p>Duration Time</p>
                            <h6>{classDetail.duration}</h6>
                            </div>
                        </Col>
                        <Col>
                            <div className="TextDetail">
                            <p>Location</p>
                            <Link to={classDetail.location_url}>
                                <h6>{classDetail.location}</h6>
                            </Link>
                            </div>
                        </Col>
                        </Row>
                        <Row className="mt-3">
                        <Col>
                            <div className="TextDetail">
                            <p>Status</p>
                            <h6>{classDetail.scheduling_status}</h6>
                            </div>
                        </Col>
                        <Col>
                            <div className="TextDetail">
                            <p>Capacity</p>
                            <h6>{classDetail.total_capacity}</h6>
                            </div>
                        </Col>
                        </Row>
                        <Row className="mt-4 mb-4">
                        <Col>
                            <div className="TextDetail">
                            <p>Description</p>
                            <h6>{classDetail.description}</h6>
                            </div>
                        </Col>
                        </Row>
                        <div className="TopBorderBox">
                        <Row>
                            <Col className="text-left">
                            <h5 className="InstrDetail">Instractor Details</h5>
                            </Col>
                            <Col className="text-right">
                            <ClassScheduleModal
                                updateList={classDetails}
                                list={insructor}
                                classDetail={classDetail}
                                preinfo={preinf ? preinf : ""} />
                            </Col>
                        </Row>
                        </div>
                        <div className="TopBorderBox">
                        <Row>
                            <Col className="text-left">
                            <img
                                src={classDetail.instructor_image}
                                className="DashboardLogo"
                                height="100%"
                            />
                            <span className="InstrDetail">
                                {classDetail.instructor_name}
                            </span>
                            </Col>
                        </Row>
                        </div>

                        <div className="TopBorderBox">
                        <Row>
                            <Col className="text-left">
                            <h6 className="InstrDetail">Clients</h6>
                            </Col>
                            <Col className="text-right">

                            <SharedButton
                                type={"button"}
                                className={"NewClient w-50"}
                                variant={"btn btn-primary "}
                                element={preinf && preinf.class_id ? preinf.class_id : ""}
                                href={"/client-search"}
                                title={"Add New Client"}

                            />
                            </Col>
                        </Row>
                        </div>
                        <div className="TopBorderBox">
                        <Row>
                            <Col className="text-left">
                            <h6 className="InstrDetail">Available Passes</h6>
                            </Col>
                            {classDetail.upcomming ?
                            <Col className="text-right">
                                <SharedButton
                                type={"submit"}
                                title={"Edit"}
                                element={preinf && preinf.schedule_id ? preinf.schedule_id : ""}
                                href="/CalenderPassUpdate"
                                variant={"outline"}
                                iconNode={<RiEditFill size={20} />}
                                className={"FontIconGreen"}
                                />
                            </Col>
                            : ""}
                        </Row>
                        
                        </div>
                        
                        <div className="MemberShipCard">
                        <div className="CardCarousel mt-5">
                            <h5>Recurring Membership</h5>

                            <CardCarousel pass={recurrigpass} />
                        </div>
                        <div className="TimeCard mt-5">
                            <h5>Time Frame</h5>
                            <CardCarousel pass={timepass} />
                        </div>
                        <div className="PunchCard my-5">
                            <h5>Punch Card</h5>
                            <CardCarousel pass={punchpass} />
                        </div>

                        <Row className="mt-5 pt-5">
                            <Col>
                            <h5 className="title p-3 font-arialrouded mb-0">
                                Remaining Classes
                            </h5>
                            </Col>
                            <Col>
                            <input type="number"
                                className="form-control"
                                min="1"
                                max="260"
                                onChange={numChangeHandler}
                                ref={inputRef}
                            />
                            </Col>
                            <Col>
                            <SharedButton
                                type="button"
                                onClick={updateClass}
                                title="Update"
                                variant={'primary'}
                            />
                            </Col>
                        </Row>

                        <div className="classesTimingCard">
                            <ClassesCard data={classDetail.upcomming}
                            fromTime={classDetail.from_time_utc}
                            toTime={classDetail.to_time_utc}
                            />
                        </div>
                        </div>
                    </div>

                    </div>

                </section>
                </Container>
                : ""}
             <Footer />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};