import React from 'react'
import { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { SharedSelect } from './SharedSelect';
import { InputField } from './InputField';
import { SharedButton } from './Button';
import { ExpiryCheck } from '../Static_Services/Staff_Services';
import { useEffect } from 'react';
import { buy_now_services_cash, clover_buy_now_services, generateToken } from '../Studio-Services/Studio_Services';
import Swal from 'sweetalert2';
import logo from '../Images/logo_white_web.png'
import { useNavigate } from 'react-router-dom';
export const SharedPayModal = ({rowObject,getScheduleInfo,upDate,setLoder}) => {
  const [userDetail, setUserDetail] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('st_userData'))) {
      setUserDetail(JSON.parse(localStorage.getItem('st_userData')));
    }
  }, [])


  const [error, setError] = useState({ mode: "", transactionid: "",  notes: "",});


  const paymodeOP = [
    { value: "Cash", label: "Cash" },
    { value: "POS", label: "POS" },
    { value: "Pass", label: "Pass" },
    { value: "GiftCard", label: "GiftCard" },
    { value: "Check", label: "Check" },
    { value: "Direct Debit", label: "Direct Debit" }
  ]
  const [paytype, setPaytype] = useState();
  const modeHandler = (e) => {
    const value = e.target.value;
    setError((pre) => ({
      ...pre,
      mode: "",
    }));
  }
  const errorHandler = (e) => {
    const { name, value } = e.target;
    setError((pre) => ({
      ...pre,
      [name]: "",
    }));
  };



  const submitHandler = async (e) => {
    e.preventDefault();
    let isValid = 1;
    const fData = new FormData(e.target);
    const fValue = Object.fromEntries(fData.entries());

    const mode = fValue.mode ? fValue.mode : "";
    const transactionid = fValue.transactionid ? fValue.transactionid : "";
    const notes = fValue.notes ? fValue.notes : "none";
    const tips = fValue.tip ? fValue.tip : "none";
    if (!mode) { setError((pre) => ({ ...pre, mode: "*Required" })); isValid = 2 }
    if (!transactionid) { setError((pre) => ({ ...pre, transactionid: "*Required" })); isValid = 2 }
    if (!notes) { setError((pre) => ({ ...pre, notes: "*Required" })); isValid = 2 }
    if(isValid===1){
      setLoder(true);
      const tempObj = {};
      tempObj.payment_transaction_id = transactionid;
      tempObj.comment = notes;
      tempObj.service_id = rowObject.service_id;
      tempObj.transaction_id = rowObject.transaction_id;
      tempObj.amount = +rowObject.amount + +rowObject.tax1_rate + +rowObject.tax2_rate;
      tempObj.tip_comment = tips;
      tempObj.payment_mode = mode;
      const resp = await buy_now_services_cash(tempObj);
      if(resp){
        setLoder(false);
        let myObjet = rowObject;
        e.target.reset();
        myObjet.booking_status = 'Success';
        upDate(myObjet,false);
        Swal.fire({
          html: `
        <img src=${logo} class="img-fluid d-block mx-auto mt-5" />
        <h4 class="title text-uppercase text-center mt-4 textWhite">Success</h4>
        <p class="text-center my-4 textcolor">
          <small class="textWhite">${resp.data.message}</small>
        </p>
      `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
          confirmButtonText: 'Continue',
        })
        // .then((result) => {
          // if (result.isConfirmed) {
            // getScheduleInfo();
            // navigate('/service-appointment');
          // }
        // })
      }
    }
  }

  const [expiryDate, setExpiryDate] = useState();
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    if (e.nativeEvent.inputType === "deleteContentBackward") {
      if (inputValue.endsWith('/')) {
        setExpiryDate(inputValue.slice(0, -1));
      } else { setExpiryDate(inputValue); }
    } else {
      if (inputValue.length === 2 && inputValue.charAt(2) !== '/') {
        setExpiryDate(inputValue + '/');
      } else if (inputValue.length === 8) { return; } else { setExpiryDate(inputValue); }
    }
  };

  const [carderror, setCarderror] = useState({ name: "", crnumber: "", dates: "", cvv: "", tip: '' });
  const [cardData, setCardData] = useState({ name: "", crnumber: "", dates: "", cvv: "", tip: '' });
  const CardInputChange = (e) => {
    const { name, value } = e.target;
    const cleanedValue = value.replace(/^\s+/g, '');
    setCardData((pre) => ({ ...pre, [name]: cleanedValue }));
    setCarderror((pre) => ({ ...pre, [name]: "" }));
  }

  const payBycard = async (e) => {
    e.preventDefault();
    let isValid = 1;
    let expiry_month = '';
    let expiry_year = '';
    let expired = '';

    if (expiryDate) {
      expired = ExpiryCheck(expiryDate);
    }
    if (expired) {
      expiry_month = expired[0];
      expiry_year = expired[1];
    } else {
      setCarderror((pre) => ({ ...pre, dates: "* Valid Till is required" }));
      isValid = 2;
    }

    if (!cardData.name) { setCarderror((pre) => ({ ...pre, name: "* Card Holder Name is required" })); isValid = 3; };
    if (!cardData.crnumber) {
      setCarderror((pre) => ({ ...pre, crnumber: "* Card Number is required" }))
      isValid = 4;
    };
    if (!cardData.cvv) {
      setCarderror((pre) => ({ ...pre, cvv: "* CVV is required" }));
      isValid = 5;
    };
    if (isValid === 1) {
      setLoder(true);
      let paymentData = {
        "number": cardData.name,
        "expiry_month": expiry_month,
        "expiry_year": expiry_year,
        "cvd": cardData.cvv,
        "business_id": userDetail.business_id,
        "country_code": '',
      }

    const resp = await generateToken(paymentData);
    if(resp){
      setLoder(false);
      const tempObj = {};
      tempObj.service_id = rowObject.service_id;
      tempObj.transaction_id = rowObject.transaction_id;
      tempObj.user_id = rowObject.user_id;
      tempObj.savecard = 0;
      tempObj.card_id = "";
      tempObj.token = resp.data.data.token;
      tempObj.tip_comment = cardData.tip;
      tempObj.business_id = "";
      tempObj.country_code ='';
      tempObj.amount = +rowObject.amount + +rowObject.tax1_rate + +rowObject.tax2_rate;
     const responce = await clover_buy_now_services(tempObj);
     if(responce){
      let myObjet = rowObject;
      e.target.reset();
      myObjet.booking_status = 'Success';
      upDate(myObjet,false)
     }
    }else setLoder(false);
    }
  }

  return (
    <>
      <Row className='mt-4 mb-4'>
        <h4>Credit card / Debit card</h4>
        <Row>
          <p>Payment Mode</p>
          <Row style={{ justifyContent: "center" }}>
            <Col md={3}>
              <button type='button' onClick={() => setPaytype(1)} className='ownCartBtn'>Other</button>
            </Col>
            <Col md={3}>
              <button type='button' onClick={() => setPaytype(2)} className='ownCartBtn'>Card</button>
            </Col>
          </Row>
        </Row>
        <Row className='mt-5' style={{ justifyContent: "center" }}>

          {paytype === 1 &&
            <>
              <Form autoComplete='off' onSubmit={submitHandler}>
                <Row>
                  <Col>
                    <SharedSelect
                      name={"mode"}
                      lable={"Payment Mode"}
                      error={error.mode}
                      onChange={modeHandler}
                      options={paymodeOP}
                    />
                  </Col>
                  <Col>
                    <InputField
                      type={"text"}
                      name={"transactionid"}
                      error={error.transactionid}
                      lable={"Transaction ID"}
                      onChange={errorHandler}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <InputField
                      type={"text"}
                      name={"notes"}
                      error={error.notes}
                      lable={"Notes"}
                      onChange={errorHandler}
                    />
                  </Col>
                  <Col>
                    <InputField
                      type={"number"}
                      name={"tip"}
                      lable={"Tip"}
                    />
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col>
                    <SharedButton
                      className={"w-100"}
                      type={"submit"}
                      title={"Proceed To Pay"}
                      variant={"primary"}
                    />
                  </Col>
                  <Col></Col>
                </Row>
              </Form>
            </>
          }
          {paytype === 2 && // Pay By Card
            <>
              <Form autoComplete='off' onSubmit={payBycard}>
                <Row>
                  <Col>
                    <div className="form-group">
                      <p className="brand-color3 text-left font-14 mb-2"> Name On Card </p>
                      <input
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        name='name'
                        placeholder="Sophie Joe"
                        value={cardData.name}
                        onChange={CardInputChange}
                      />
                      <small className="text-danger">{carderror.name}</small>
                    </div>
                  </Col>
                  <Col>
                    <div className="form-group client-buy-input-cardno">
                      <p className="brand-color3 text-left font-14 mb-2"> Card Number </p>
                      <input
                        type="text"
                        autoComplete="off"
                        className="form-control"
                        placeholder="4121 4411 3131 8554"
                        name='crnumber'
                        value={cardData.crnumber}
                        onChange={CardInputChange}
                        maxLength={19}
                      />
                      <small className="text-danger">{carderror.crnumber}</small>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="form-group">
                      <p className="brand-color3 text-left font-14 mb-2"> Valid Till </p>
                      <input
                        type="text"
                        name={'dates'}
                        className="form-control"
                        placeholder="MM/YYYY"
                        value={expiryDate}
                        onChange={handleInputChange}
                        pattern="^[0-9]{2}/[0-9]{4}"
                        autoComplete="new-password"
                        lable={"Valid Through"}
                      />
                      <small className="text-danger">{carderror.dates}</small>
                    </div>
                  </Col>
                  <Col>
                    <div className="form-group">
                      <p className="brand-color3 text-left font-14 mb-2"> CVV </p>
                      <input
                        type="password"
                        autoComplete="off"
                        className="form-control"
                        placeholder="eg. 201"
                        name='cvv'
                        value={cardData.cvv}
                        onChange={CardInputChange}
                        maxLength={3}
                      />
                      <small className="text-danger">{carderror.cvv}</small>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="form-group">
                      <p className="brand-color3 text-left font-14 mb-2">Tip </p>
                      <input
                        type="number"
                        className="form-control"
                        autoComplete="off"
                        name='tip'
                        value={cardData.tip}
                        onChange={CardInputChange}
                      />
                    </div>
                  </Col>
                  <Col></Col>
                </Row>
                <Row className='mt-3'>
                  <Col>
                    <SharedButton
                      className={"w-100"}
                      type={"submit"}
                      title={"Proceed To Pay"}
                      variant={"primary"}
                    />
                  </Col>
                  <Col></Col>
                </Row>
              </Form>
            </>
          }
        </Row>
      </Row>
    </>
  )
}
