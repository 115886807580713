import React from 'react'
import { InnerHeader } from '../../Components/inner-header/InnerHeader1'
import { SubHeader } from '../../Components/sub-header/SubHeader'
import { Innerfooter } from '../../Components/inner-footer/Innerfooter'
import { DashboardToSectionDetails } from '../../Components/CommanPages/DashboardToSectionDetails'
import { ClinetProductDetailSlider } from './ClinetProductDetailSlider'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useState } from 'react'
import { API_add_cart, API_cart_list, API_remove_cart, staff_products_details } from '../../../Static_Services/Staff_Services'
import { successAlert } from '../../../Static_Services/Alertmsg'
import Swal from 'sweetalert2'
import { Spinner } from 'react-bootstrap'
import { Other_Products_Available_Slider } from './Other_Products_Available_Slider'

export const ProductDetail = () => {
    const location = useLocation();
    const [iD, setID] = useState();
    const [businessDetail, setBusinessDetail] = useState([]);
    const [productDetail, setProductDetail] = useState([]);
    const [productImages, setProductImages] = useState([]);
    const [similarProductDetail, setSimilarProductDetail] = useState([]);
    const navigate = useNavigate();
    const [cartData, setCartData] = useState([])
    const [subjectData, setSubjectData] = useState({ cartdata: null });
    const [loder, setLoder] = useState(false);
    useEffect(() => {
        if (JSON.parse(localStorage.getItem('w_BusinessDetail'))) {
            setBusinessDetail(JSON.parse(localStorage.getItem('w_BusinessDetail')));
        }
        const navData = location.state;
        if (navData && navData.Id) {
            setID(navData.Id);
            localStorage.setItem('w_ProductId', JSON.stringify(navData.Id));
        }
        if (localStorage.getItem('w_ProductId')) {
            setID(JSON.parse(localStorage.getItem('w_ProductId')));
        }
    }, [])

    const getProductList = async (id) => {
      
        setLoder(true)
        const detail = { "product_id": id };
        const resp = await staff_products_details(detail);
    
        if (resp) {
            const response = resp.data;
            setProductDetail(response.data);
            setProductImages(response.data.product_images);
            setSimilarProductDetail(response.data.similar_products);
            setLoder(false);
            window.scroll(0, 0);
        } else {
            setLoder(false);
        }
    }
    const redirectUrl = (detail) => {
        navigate('/product-buy', { state: { Detail: detail } });
    }
    const redirectUrl1 = (url, Id) => {
        navigate(url, { state: { Id: Id } });
    }

    const AddCartData = async (Detail) => {
        setLoder(true);
        const detail = { "service_type": "3", "service_id": Detail.product_id, "amount": Detail.product_price, "quantity": "1" };
        const resp = await API_add_cart(detail);
        if (resp) {
            const response = resp.data;
            successAlert(response.message);
            getCartDetail();
            getProductList(iD);
            setLoder(false);
        } else {
            setLoder(false);
        }
    }

    const AddCart = (Detail) => {
        if (cartData.length != 0) {
            if (businessDetail.business_id == cartData.business_id) {
                AddCartData(Detail);
            }
            else {
                Swal.fire({
                    title: 'Replace cart item?',
                    text: 'Your cart contains items from another studio. Do you want to discard the selection and add product from new studio?.',
                    icon: 'warning',
                    showCancelButton: true,
                    cancelButtonText: 'No',
                    confirmButtonText: 'Yes',
                }).then((result) => {
                    if (result.value) {
                        RemoveCartDetail(Detail);
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                    }
                })
            }
        } else {
            AddCartData(Detail);
        }
    }

    const getCartDetail = async () => {
        const detail = { "pageid": "1" };
        const resp = await API_cart_list(detail);
        if (resp) {
            const response = resp.data;
            localStorage.setItem('w_CartValues', JSON.stringify(response.data.total_item));
            setSubjectData({ cartdata: response.data.total_item });
            setCartData(response.data.business_details[0]);
        } else {
        }
    }

    const RemoveCartDetail = async (Detail) => {
        setLoder(true);
        const detail = { "remove_cart_type": "1" };
        const resp = await API_remove_cart(detail);
        if (resp) {
            AddCartData(Detail);
            setLoder(false);
        } else {
            setLoder(false);
        }
    }

    useEffect(() => {
        if (iD) {
            getCartDetail();
            getProductList(iD);
        }
    }, [iD])

    return (
        <>
            {loder ? <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div> : ""}
            <InnerHeader />
            <div className="body-grey">
                {/* <!-- My Studio Card Detials Section --> */}
                <div class="studio-details product-page pb-5">
                    <div class="container">
                        <DashboardToSectionDetails />
                        <div >
                            <div class="row">
                                <div class="col-6 section-heading">
                                    <h5 class="title black-color font-w-100 text-uppercase font-16"> product image </h5>
                                </div>
                                <div class="col-6 text-right mb-3">
                                    <button type="button" class="btn btn-blue-grad px-2 text-uppercase" onClick={()=>window.history.back()} >Back </button>
                                </div>
                            </div>
                            {/* <!-- end of col-12 --> */}

                            {productImages.length > 4 &&
                                <ClinetProductDetailSlider pass={productImages} />
                            }
                            {/* <owl-carousel-o [options]="Image_Options"  *ngIf=""> 
    <ng-template carouselSlide *ngFor="let images of ProductImages"  [id]="images.product_image_id">
      <div class="border-radius6 box-shadow1 white-bg p-3" >
        <img src="{{images.image_name}}" class="img-fluid w-100" style="height: 221px;">
      </div>
    
    </ng-template>                   
  </owl-carousel-o>  */}
                            {productImages.length <= 4 &&

                                <div class="row">
                                    {productImages.map((images) => (
                                        <div class="col-md-3">
                                            <div class="border-radius6 box-shadow1 white-bg p-3" >
                                                <img src={images && images.image_name ? images.image_name :''} class="img-fluid w-100" style={{height: "221px"}} />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            }
                            {productImages.length == 0 &&
                                <div>

                                </div>
                            }

                        </div>
                        {/* <!-- end of Product image and Heading --> */}
                    </div>
                    {/* <!-- end of container --> */}


                    <div class="container-fluid studio-padding-left py-5 product-detail-similar">
                        <div class="row px-3">
                            <div class="col-12 col-lg-5 respon-product">
                                <h4 class="title black-color font-w-100 font-16"> Product Detail </h4>
                                <div class="product-detail-section white-bg border-radius6 box-shadow1 pt-3 mx-0">
                                    <div class="row px-3">
                                        <div class="col-8 text-left">
                                            <h5 class="heading-titlenew black-color font-w-100 font-16">{productDetail.product_name} </h5>
                                        </div>
                                        <div class="col-4 text-right">
                                            <button type="button" class="btn btn-green-grad px-4">{businessDetail.currency}  {productDetail.product_price} </button>
                                        </div>
                                    </div>
                                    {/* <!-- end of row -->  */}

                                    <div class="text-left pb-5 px-3">
                                        <p class="black-color font-w-100 paratitle mb-2"> Product details </p>
                                        <p class="brand-color3 text-justify paratitle pb-3">{productDetail.product_description}
                                        </p>
                                    </div>
                                    {productDetail.product_quantity == 0 &&
                                        <div class="product-detail-section-btn" >
                                            <a href="Javascript:void(0);" class="btn new-blue btn-blue-grad font-w-600 text-uppercase mx-0 py-3"> Out of Stock </a>

                                        </div>
                                    }
                                    {/* <!-- End of Button -->  */}
                                    {productDetail.product_quantity != 0 &&
                                        <div class="d-flex flex-wrap product-detail-section-btn" >
                                            <div class="col-6 px-0">
                                                {productDetail.is_cart == 0 &&
                                                    <a href="Javascript:void(0);" onClick={() => AddCart(productDetail)}
                                                        class="btn new-blue btn-blue-grad font-w-600 text-uppercase mx-0 py-3" > Add to cart </a>}
                                                {productDetail.is_cart == 1 &&
                                                    <Link to="/my-cart" class="btn new-blue btn-blue-grad font-w-600 text-uppercase mx-0 py-3"
                                                    > Go to cart </Link>}
                                            </div>
                                            <div class="col-6 px-0">
                                                <a href="Javascript:void(0);"
                                                    onClick={() => redirectUrl(productDetail)}
                                                    class="btn new-green btn-green-grad font-w-600 text-uppercase mx-0 py-3"> buy now </a>
                                            </div>
                                        </div>
                                    }
                                    {/* <!-- End of Button -->  */}
                                </div>
                            </div>
                            {/* <!-- end of col-md-5 --> */}



                            <div class="col-12 col-lg-7 pl-2 pl-md-4 pl-lg-0 pr-sm-2 pr-md-0 mt-5 mt-md-4 mt-lg-0 client-similar-products">
                                <h4 class="title black-color font-w-100 font-16"> Other Products Available </h4>
                            
                                {similarProductDetail.length  >2 && 
                          
                                 <Other_Products_Available_Slider pass={similarProductDetail} businessDetail={businessDetail} getProductList={getProductList} />
                                 
                                }
                                {/* <owl-carousel-o [options]="Similar_Options" class="new-slider-logo" *ngIf=""> 
            <ng-template carouselSlide *ngFor="let similarProduct of similarProductDetail"  [id]="similarProduct.product_id">
                  <a href="Javascript:void(0);"  (click) ="getProductList(similarProduct.product_id);">
                    <div class="text-center">
                      <div class="white-bg border-radius6 box-shadow1 p-3 mb-3">
                        <img src="{{similarProduct.product_images[0].image_name}}" style="height: 221px;">
                      </div>
                      <h5 class="heading-titlenew black-color"> {{similarProduct.product_name}}</h5>
                    </div>
                  </a><!-- end of similar card -->
                </ng-template>                   
              </owl-carousel-o>   */}
                                {similarProductDetail.length <= 2 &&
                                    <div class="row">
                                        {similarProductDetail.map((similarProduct, p) => (
                                            <div class="col-6 mb-3" >
                                                <a href="Javascript:void(0);"
                                                    onClick={() => getProductList(similarProduct.product_id)} >
                                                    <div class="text-center">
                                                        <div class="white-bg border-radius6 box-shadow1 p-3 mb-3">
                                                            <img src={similarProduct && similarProduct.product_images[0] ? similarProduct.product_images[0].image_name : ''} style={{ height: "221px" }} />
                                                        </div>
                                                        <h5 class="heading-titlenew black-color"> {similarProduct.product_name}</h5>
                                                    </div>
                                                </a>
                                            </div>
                                        ))}
                                    </div>
                                }
                                {similarProductDetail.length == 0 &&
                                    <div class="black-color">
                                        <div class="text-center pt-7 text-justify-center">
                                            <p class="font-awesome black-color font-18 d-inline mx-2 paratitle"> No Similar Product Available </p>
                                        </div>
                                    </div>
                                }
                            </div>

                            {/* <!-- end of col-lg-7 -->   */}

                        </div>
                        {/* <!-- end of row --> */}
                    </div>
                    {/* <!-- end of container-fluid --> */}
                </div>
                {/* <!-- End of My Studio Card Detials Section --> */}
            </div>
            <Innerfooter />
        </>
    )
}
