import React, { useState } from 'react'
import { InputField } from '../../../../sharedComponents/InputField';
import moment from 'moment';
import { changeTimeStampToUTCDate, checkCurrentDateFilter, getClass_list, get_service_schedule_calendar, get_workshop_schedule_calendar, newUserRedirect } from '../../../../Studio-Services/Studio_Services';
import { errorAlert } from '../../../../Static_Services/Alertmsg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
// import { MonthYearPicker } from './MonthYearPicker';


export const MonthSchedule = () => {

  const [date, setDate] = useState(moment())
  // const [selectMonth, setSelectMonth] = useState(moment().format("MM"));
  // const [selectYear, setSelectYear] = useState(moment().format("YYYY"));
  const [param1, setParam1] = useState();
  const [param2, setParam2] = useState();
  const [sectionTitle, setSectionTitle] = useState('Classes')

  const [todayclassList, setTodayclassList] = useState([]);
  const [currentDateSelect, setCurrentDateSelect] = useState('');
  const [messageContent, setMessageContent] = useState("Loading...please wait")
  const location = useLocation();
  const [loder, setLoder] = useState(false);
  const [monthYearCombo, setMonthYearCombo] = useState(moment().format('YYYY-MM'));

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    const type = params.get('type');
    if (token) { setParam1(token); }
    if (type) { setParam2(type); }
    if (!params.get('type')) {
      window.location.href = '/';
    }
  }, [location.search]);

  useEffect(() => {
    if (param2 && param1) {
      getInfo();
    }
  }, [param2, param1, monthYearCombo])

  const getInfo = async () => {
    let dateFrom = `${monthYearCombo}-01`;
    // console.log('monthYearCombo',monthYearCombo,'======',dateFrom,)
    let currentMonth = moment().format('MM');
    let currentYear = moment().format('YYYY');
    let splitDate = dateFrom.split('-');

    let dateTo = moment(`${dateFrom}`, "YYYY-MM-DD").subtract(0, "months").endOf("month").format("YYYY-MM-DD");
    // console.log('dateTo', dateFrom);
    let resp = '';
    let tempObj = {};
    let type = '';
    if (param2 == "1") {
      type = 1;
    } else if (param2 == "2") {
      type = 2;
    } else {
      type = 3;
    }

    if (currentMonth == splitDate[1] && currentYear == splitDate[0]) {
      dateFrom = moment().format("YYYY-MM-DD");
    }
    tempObj.business_token = param1;
    tempObj.start = dateFrom;
    tempObj.end = dateTo;

    if (type == 1) {
      setLoder(true);
      resp = await getClass_list(tempObj);
    } else if (type == 2) {
      setLoder(true);
      setSectionTitle("Workshop");
      resp = await get_workshop_schedule_calendar(tempObj);
    } else if (type == 3) {
      setLoder(true);
      setSectionTitle("Appointment");
      resp = await get_service_schedule_calendar(tempObj);
    } else {
      setLoder(false);
      return false;
    }
    if (resp) {
      let respData = resp.data.data;
      // setTodayclassList(respData);
      for (let i = 0; i < respData.length; i++) {
        let rowObject = respData[i];
        const currentDate = moment().format("DD/MM/YYYY");
        const now = moment().format("HH:mm:ss");

        if (param2 == "2") {
          rowObject.from_time = rowObject.start;
          rowObject.to_time = rowObject.end;
          rowObject.location = rowObject.location_name;
          rowObject.class_name = rowObject.name;
          rowObject.scheduled_date = moment.unix(rowObject.start).format("YYYY-MM-DD");
        }
        if (param2 == "3") {
          rowObject.from_time = rowObject.start_time;
          rowObject.to_time = rowObject.end_time;
          rowObject.location = rowObject.location_name;
          rowObject.class_name = rowObject.service_name;
          rowObject.scheduled_date = moment.unix(rowObject.start_time).format("YYYY-MM-DD");
        }
        const then = moment.unix(rowObject.from_time).format("YYYY-MM-DD HH:mm:ss");
        var endTime = moment(then, "YYYY-MM-DD HH:mm a");
        const currentTime = moment();
        let remainingCounter = "00:00:00";
        let remainingCounter11;
        const cur = moment().format("Y-MM-DD");
        const cDate = new Date(cur);
        const scheduleCreate = new Date(rowObject.scheduled_date);
        if (scheduleCreate >= cDate) {
          if (endTime.isAfter(currentTime)) {
            const startTime = currentDate + " " + now;
            const endTime = moment.unix(rowObject.from_time).format("DD/MM/YYYY HH:mm:ss");
            let ms = moment(endTime, "DD/MM/YYYY HH:mm:ss").diff(
              moment(startTime, "DD/MM/YYYY HH:mm:ss")
            );
            let d = moment.duration(ms);
            let days = Math.floor(d.asDays()) < 10 ? "0" + Math.floor(d.asDays()) : Math.floor(d.asDays());
            let hours = Math.floor(d.asHours()) - Math.floor(d.asDays()) * 24;
            let hours1 = hours < 10 ? "0" + hours : hours;
            let s = days + ":" + hours1 + moment.utc(ms).format(":mm");
            let s1 = Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss");
            remainingCounter11 = s1;
            remainingCounter = s.toString();
          }
        }
        respData[i].time_start_now = remainingCounter;
        respData[i].time_start_now_new = remainingCounter11;
      }
      setLoder(false);
      setTodayclassList(respData);
      setMessageContent('');
      //   this.listStatus = true;
    } else {
      setLoder(false);
      errorAlert('No Record Found');
      setMessageContent("No Record Found");
      setTodayclassList([]);
    }
  }

  const checkDate = (currentdate) => {
    if (currentDateSelect == currentdate) {
      return false;
    } else {
      // setCurrentDateSelect(currentdate);
      return true;
    }
  }

  const getDuration = (start, end) => {
    let start_date = moment.unix(start).format("MM-DD-YYYY");
    let start_time = moment.unix(start).format("HH:mm:ss");

    const str_date_split = start_date.split("-");
    const str_time_split = start_time.split(":");

    const start_day = Number(str_date_split[1]);
    const start_mon = Number(str_date_split[0]) - 1;
    const start_yea = Number(str_date_split[2]);

    const start_time_hour = Number(str_time_split[0]);
    const start_time_minu = Number(str_time_split[1]);
    const start_time_seco = Number(str_time_split[2]);

    let end_date = moment.unix(end).format("MM-DD-YYYY");
    let end_time = moment.unix(end).format("HH:mm:ss");

    const end_date_split = end_date.split("-");
    const end_time_split = end_time.split(":");

    const end_day = Number(end_date_split[1]);
    const end_mon = Number(end_date_split[0]) - 1;
    const end_yea = Number(end_date_split[2]);

    const end_time_hour = Number(end_time_split[0]);
    const end_time_minu = Number(end_time_split[1]);
    const end_time_seco = Number(end_time_split[2]);

    const st = new Date(
      start_yea,
      start_mon,
      start_day,
      start_time_hour,
      start_time_minu,
      start_time_seco,
      0
    );
    const en = new Date(
      end_yea,
      end_mon,
      end_day,
      end_time_hour,
      end_time_minu,
      end_time_seco,
      0
    );
    const diffMs = +en - +st;
    const diffMins = Math.floor(diffMs / 1000 / 60);
    return diffMins;
  }

  return (
    <>
      {loder ? <div className='MainLoader' style={{ position: 'absolute', width: '100%', height: '100vh', zIndex: '1' }}> <Spinner animation="border" role="status" className='Loader' style={{ position: 'absolute', top: '50%', left: '50%', zIndex: '999', }}> <span className="visually-hidden">Loading...</span>  </Spinner> </div> : ""}
      <div className="container-fluid">
        <div className="bw-widget__sessions bw-widget__sessions--hide-empty-days">
          <div className="bw-widget__day">
            <div className="bw-widget__date" style={{ padding: '30px 15px', background: 'rgba(0, 0, 0, 0.03)' }}>
              <div className="col-xl-3 col-lg-6 col-md-4">
                <InputField
                  type="month"
                  lable={sectionTitle}
                  onChange={(e) => setMonthYearCombo(e.target.value)}
                  value={monthYearCombo}
                  required
                />
              </div>
            </div>
            <ul className="list-groups mt-3" id='MonthlyListGroup' style={{boxShadow: "0px 1px 1px #00000000" , padding:"10px"}}>
              {todayclassList.length != 0 ?
                <div>
                  {todayclassList.map((classes) => (
                    <div>

                      <li className="list-group-item text-center">
                        {checkDate(classes.scheduled_date) &&
                          <h4 className="text-left" style={{
                            backgroundColor: "darkgray", color: "white", padding: "4px 10px",
                            borderRadius: "10px", textAlign: "left"
                          }}>{classes.scheduled_date}</h4>
                        }
                        <div className="card" style={{ textAlign: "left" }}>
                          <div className="card-body">
                            <h5 className="card-title">
                              {classes.class_name}
                            </h5>
                            <div className="row">
                              <div className="col-sm-4 mt-2">
                                <strong>Time: </strong>
                                <div className="text-right">
                                  {changeTimeStampToUTCDate(classes.from_time)}
                                </div>
                              </div>
                              <div className="col-sm-4 mt-2">
                                <strong>Instructor: </strong>
                                <div className="text-right">{classes.instructor_name}</div>
                              </div>
                              {/* {(param2 == '1' || param2 == '2') &&
                                <div className="col-sm-4 mt-2" >
                                  {param2 == '1' &&
                                    <div>
                                      <strong>Booked: </strong>
                                      <div className="text-right"> {classes.attendence} / {classes.capacity}</div>
                                    </div>
                                  }
                                  {param2 == '2' &&
                                    <div>
                                      <strong>Capacity: </strong>
                                      <div className="text-right"> {classes.capacity} </div>
                                    </div>
                                  }
                                </div>
                              } */}
                              <div className="col-sm-4 mt-2">
                                <strong>Duration [Minutes]: </strong>
                                <div className="text-right">
                                  {param2 == '1' && <div> {classes.duration}</div>}
                                  {param2 == '2' && <div > {getDuration(classes.start, classes.end)}</div>}
                                  {param2 == '3' && <div> {getDuration(classes.start_time, classes.end_time)}</div>}
                                </div>
                              </div>
                              <div className='d-flex' style={{ justifyContent: 'space-between' }}>
                                <div className="col-sm-4 mt-2 card-title">
                                  <strong>Location: </strong>
                                  <div className="text-right">
                                    {classes.location_url.length > 0 ?
                                      <div >
                                        <Link to={classes.location_url} target="_blank"
                                          style={{ color: "#007bff !important", textDecoration: "underline !important" }}>
                                          {classes.location}</Link>
                                      </div>
                                      : <div > {classes.location} </div>
                                    }
                                  </div>
                                </div>
                                {(param2 == '1' || param2 == '2') &&
                                  <div className="col-sm-4 mt-2">
                                    {checkCurrentDateFilter(classes.scheduled_date, classes.from_time, classes.to_time) ?
                                      <div>
                                        <button  className="btn btn-outline-success btn-sm" style={{marginLeft:'1rem'}}
                                          onClick={() => newUserRedirect(param2, classes)}>Sign Up
                                        </button>
                                      </div>
                                      : <div >
                                        <a href="javascript:void(0)" className="btn btn-outline-secondary btn-sm disabled">Sign Up</a>
                                      </div>
                                    }
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>

                    </div>
                  ))}
                </div>
                : <div>
                  <li className="list-group-item text-center">{messageContent}</li>
                </div>
              }
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}
