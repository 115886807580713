import React, { useState } from "react";
import { SharedSelect } from "./SharedSelect";
import { InputField } from "./InputField";
import { TextArea } from "./TextArea";
import { SharedButton } from "./Button";
import { Row, Col, Form } from "react-bootstrap";
import { clover_pay_checkout_single } from "../Studio-Services/Studio_Services";
import { useEffect } from "react";
import Swal from "sweetalert2";
import logo from '../Images/logo_white_web.png'
import { useNavigate } from "react-router-dom";


export const DebitCreditCard = ({ data, clientId, setLoder }) => {
  const [expirydate, setExpiryDate] = useState('');
  const navigate = useNavigate();
  const [crtData, setCrtData] = useState();
  const [cardData, setCardData] = useState();

  const [error, setError] = useState({ 'name': "", 'crnumber': "", 'dates': "", 'cvv': "", 'mode': '' })


  const paymodeOP = [
    { value: "visa", label: "Visa" },
    { value: "mastercard", label: "MasterCard" },
    { value: "amEx", label: "AmEx" },
  ]

  useEffect(() => {
    if (data) {
      setCrtData(data);
    }
  }, [data])

  const handleInputChange = (e) => {
    const inputValue = e.target.value;

    // Check if the user pressed backspace
    if (e.nativeEvent.inputType === "deleteContentBackward") {
      // If the last character is a '/', remove it
      if (inputValue.endsWith('/')) {
        setExpiryDate(inputValue.slice(0, -1));
      } else {
        // Otherwise, update the state with the modified input
        setExpiryDate(inputValue);
      }
    } else {
      // Check for the condition to add '/'
      if (inputValue.length === 2 && inputValue.charAt(2) !== '/') {
        setExpiryDate(inputValue + '/');
      } else if (inputValue.length === 8) {
        return;
      } else {
        // Update the state with the user's input
        setExpiryDate(inputValue);
      }
    }
  };

  // const handleInputChange = (e) => {
  //   const inputValue = e.target.value;
  //   if (inputValue.length === 2 && inputValue.charAt(2) !== '/') {
  //     setExpiryDate(inputValue + '/');
  //   } else if (inputValue.length === 8) {
  //     return;
  //   } else {
  //     // Update the state with the user's input
  //     setExpiryDate(inputValue);
  //   }
  // };

  const setDataCart = () => {
    if (crtData && crtData.business_details && crtData.business_details.cart_details) {
      const abc = [];
      const bsID = crtData.business_details.business_id;
      crtData.business_details.cart_details.map((e) => (
        abc.push({
          "service_id": e.service_id,
          "service_type": e.service_type,
          "business_id": bsID,
          "quantity": e.quantity,
          "amount": e.sub_total,
          "tax": e.tax,
          "discount": e.discount
        })
      ))
      setCardData(abc);
    }
  }

  useEffect(() => {
    setDataCart();
  }, [crtData]);

  const submitHandler = async (e) => {
    e.preventDefault();

    const fData = new FormData(e.target);
    const fValue = Object.fromEntries(fData.entries());

    const name = fValue.name;
    const crnumber = fValue.crnumber.trim();
    const dates = fValue.dates;
    const cvv = fValue.cvv;
    const card_type = "VISA";

    let isValid = 1;

    if (!name) { setError((pre) => ({ ...pre, name: "* Name is required" })); isValid = 2; }
    if (!crnumber) { setError((pre) => ({ ...pre, crnumber: "* Card number is required" })); isValid = 2; }
    if (!dates) { setError((pre) => ({ ...pre, dates: "* Expire Date is required" })); isValid = 2; }
    if (!cvv) { setError((pre) => ({ ...pre, cvv: "*Required" })); isValid = 2; }
    if (!card_type) { setError((pre) => ({ ...pre, mode: "*Required" })); isValid = 2; }

    if (isValid === 1) {
      setLoder(true);

      const parts = dates.split('/');
      const month = parts[0]; // '10'
      const year = parts[1];  // '2222'

      const fData = {
        "member_id": "",
        "client_id": clientId,
        "token": "",
        "data": cardData,
        "savecard": 1,
        "card_id": "",
        "number": crnumber,
        "expiry_month": month,
        "expiry_year": year,
        "cvd": cvv,
        "business_id": "",
        "country_code": "",
        "customer_name": name,
        "payment_mode": card_type
      }
      const resp = await clover_pay_checkout_single(fData);
      if (resp) {
        setLoder(false);
        e.target.reset();
        Swal.fire({
          html: `
            <img src=${logo} class="img-fluid d-block mx-auto mt-5" alt='' />
            <h4 class="title text-uppercase text-center mt-4 textWhite">Success</h4>
            <p class="text-center my-4 textcolor">
              <small class="textWhite">${resp.data.message}</small>
            </p>
          `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
          confirmButtonText: 'Continue',
        }).then((result) => {
          if (result.isConfirmed) {
            if (JSON.parse(localStorage.getItem('st_isPassPurchaseFlow'))) {
              let getFinale = JSON.parse(localStorage.getItem('allsetData'));
              navigate('/client-search', { state: { back: 'pre', class_id: getFinale.class_id, schedule_id: getFinale.schedule_id, serchKey: JSON.parse(localStorage.getItem('st_searchedClients')) } });
            } else {
              navigate("/make-a-purchase");
            }
          }
        });
      }
      setLoder(false);
    }
    setLoder(false);

  }
  const errorHandler = (e) => {
    const { name, value } = e.target;
    setError((pre) => ({ ...pre, [name]: "" }));
  }

  return (
    <>
      <Form autoComplete="off" onSubmit={submitHandler}>
        <Row className="my-4">
          {/* <Col></Col> */}
          <Col lg={12}>
            <div className="s-d-appoint-block-right border-radius6 box-shadow1 white-bg">
              <div className="px-3 py-3 text-left light-bg border-t-lr-6" style={{display: "flex", justifyContent: "space-between"}}>
                <h5 className="title black-color font-w-100 text-uppercase font-16 mb-0" >
                  Debit/Credit Card Details
                </h5>
                {/* <input type="checkbox" name="agree" className="ownCheck" /> */}
              </div>
              <div className="p-3 ">
                <Row>
                  <Col>
                    <InputField
                      name={"name"}
                      error={error.name}
                      lable={"Name On Card"}
                      type={"text"}
                      onChange={errorHandler}
                    />
                  </Col>
                  <Col>
                    <InputField
                      error={error.crnumber}
                      name={"crnumber"}
                      type={"number"}
                      onChange={errorHandler}
                      lable={"Card Number"} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label className="form-label inputLable">Valid Through</label>
                    <input
                      type="text"
                      name={'dates'}
                      className="form-control"
                      placeholder="MM/YYYY"
                      value={expirydate}
                      onChange={handleInputChange}
                      pattern="^[0-9]{2}/[0-9]{4}"
                      autoComplete="new-password"
                      lable={"Valid Through"}
                    />
                    <small className="text-danger">{error.dates}</small>
                  </Col>
                  <Col>
                    <Row>
                      <Col>
                        <InputField
                          name={"cvv"}
                          type={"password"}
                          error={error.cvv}
                          onChange={errorHandler}
                          maxLength={3}
                          lable={"Cvv"} />
                      </Col>
                      {/* <Col>
                        <SharedSelect
                          options={paymodeOP}
                          name={"mode"}
                          className="form-control myborder"
                          error={error.mode}
                          onChange={errorHandler}
                          lable={"Card Type"} />
                      </Col> */}


                    </Row>

                  </Col>
                </Row>
                <Row>
                  <Col>
                    <SharedButton
                      title={"Save"}
                      type={"submit"}
                      className={"w-100"}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          {/* <Col></Col> */}
        </Row>
      </Form>
    </>
  );
};
