import React, { useEffect } from 'react'
import StaticHeader from '../../StaticHeader'
import StaticFooter from '../../StaticFooter'
import { Link, useNavigate } from 'react-router-dom';


export const StaticSolution = () => {
   const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    const trailDays = 30;
    return (
        <>
            <StaticHeader />
            {/* <!-- Solution Content Section --> */}
            <section className="solution-container">
                <div className="solution-top pt-3 mt-4 pb-5">
                    <div className="container border-new">
                        <div className="text-center wow animated slideInLeft px-0 px-md-5 pb-3">
                            {/* <!-- <h2 className="title new-title dark-color font-w-900 pb-4 header-css"> How It Works </h2> --> */}
                            <h2 className="title dark-color font-w-900 pb-4 header-css"> How It Works </h2>

                            <p className="pb-4 brand-color3 font-20 line-height25 px-0 px-lg-5"> Below you will see all of the key features of the <br /> Warrior studio management software. </p>
                            <div className="d-flex flex-wrap solution-button justify-content-center">
                               <button type="button" onClick={()=>navigate("/demo")} className="btn btn-blue-grad black-color text-uppercase mb-4 mb-sm-0 header-css"> Request a demo </button>
                                <Link to='/choose-plan' className="btn btn-green-grad text-uppercase ml-0 ml-md-3 header-css"> Start {trailDays} Day Free Trial</Link>
                            </div>
                        </div>
                    </div>
                    {/* <!-- end of container --> */}
                </div>
                {/* <!-- end of solution-top --> */}

                <div className="solution-timeline">
                    <div className="container-fluid">

                        <div className="row solution-text-image">
                            <div className="col-12 col-md-6 order-2 order-md-1">
                                <div className="solution-text-left solution-4 wow animated slideInLeft">
                                    <h4 className="dark-color text-uppercase mt-4"> YOUR STUDIO, YOUR RULES </h4>
                                    <p className="font-16 brand-color3 text-justify"> Warrior studio management software wants you to be in control of how your business is run.  We provide you the options so that the software helps run the business the way you want. </p>
                                </div>
                            </div>
                            {/* <!-- end of col-6 --> */}
                            <div className="col-12 col-md-6 order-1 order-md-2">
                                <div className="solution-image-right wow animated slideInRight">
                                    <img src="clientassets/img/solution_img4.png" alt=""className="img-fluid w-100" />
                                </div>
                            </div>
                            {/* <!-- end of col-6 --> */}
                        </div>
                        {/* <!-- end of row (Section-1) --> */}

                        <div className="row solution-image-text">
                            <div className="col-12 col-md-6">
                                <div className="solution-image-left wow animated slideInLeft text-center">
                                    <img src="clientassets/img/solution_img1.png" alt=""className="img-fluid" />
                                </div>
                            </div>
                            {/* <!-- end of col-6 --> */}
                            <div className="col-12 col-md-6">
                                <div className="solution-text-right solution-5 wow animated slideInRight">
                                    <h4 className="dark-color text-uppercase mt-4"> ENGAGE YOUR CLIENTS AND EMPOWER YOUR STAFF </h4>
                                    <p className="font-16 brand-color3 text-justify"> The Warrior Staff and Client apps are designed to simplify and engage your clients and staff.  classes and workshops link with membership passes, staff and clients can easily connect to book appointments for services, purchasing a product is easy and won’t cause delays at during check in time. </p>
                                </div>
                            </div>
                            {/* <!-- end of col-6 --> */}
                        </div>
                        {/* <!-- end of row (Section-2) --> */}

                        <div className="row solution-text-image">
                            <div className="col-12 col-md-6 order-1 order-md-2">
                                <div className="solution-image-right wow animated slideInRight text-center">
                                    <img src="clientassets/img/solution_img5.png" alt=""className="img-fluid w-100" />
                                </div>
                            </div>
                            {/* <!-- end of col-6 --> */}
                            <div className="col-12 col-md-6 order-2 order-md-1">
                                <div className="solution-text-left solution-1 wow animated slideInLeft">
                                    <h4 className="dark-color text-uppercase mt-4"> CLASSES &  WORKSHOPS </h4>
                                    <p className="font-16 brand-color3 text-justify"> Classes and/or workshops are the foundation of most studios.  We have put a tremendous amount of thought and care into how classes and workshops are created and managed.  Details and the appropriate pass selection are intuitive and easy for your customers to find and sign up for the classes or workshops they want. </p>
                                    <p className="font-16 brand-color3 text-justify mt-4"> Checking into classes no longer requires long line ups or multiple tablets at your front desk.  Warrior’s desktop software and mobile apps empower staff, instructors, and clients to check in quickly easily.  </p>
                                </div>
                            </div>
                            {/* <!-- end of col-6 --> */}
                        </div>
                        {/* <!-- end of row (Section-3) --> */}

                        <div className="row solution-image-text">
                            <div className="col-12 col-md-6">
                                <div className="solution-image-left wow animated slideInLeft">
                                    <img src="clientassets/img/ic_new_pass.png" alt='' className="img-fluid w-100" />
                                </div>
                            </div>
                            {/* <!-- end of col-6 --> */}
                            <div className="col-12 col-md-6">
                                <div className="solution-text-right solution-7 wow animated slideInRight">
                                    <h4 className="dark-color text-uppercase mt-4"> passes </h4>
                                    <p className="font-16 brand-color3 text-justify"> Passes are a studio’s main source of revenue.  Warrior comes with a number of passes ready to have a price and make them available.  Choose from punch passes or time based passes and publish them in minutes for your clients to purchase. </p>
                                </div>
                            </div>
                            {/* <!-- end of col-6 --> */}
                        </div>
                        {/* <!-- end of row (Section-4) --> */}

                        <div className="row solution-text-image">
                            <div className="col-12 col-md-6 order-1 order-md-2">
                                <div className="solution-image-right wow animated slideInRight">
                                    <img src="clientassets/img/solution_img7.png" alt=""className="img-fluid w-100" />
                                </div>
                            </div>
                            {/* <!-- end of col-6 --> */}
                            <div className="col-12 col-md-6 order-2 order-md-1">
                                <div className="solution-text-left solution-6 wow animated slideInLeft">
                                    <h4 className="dark-color text-uppercase mt-4"> APPOINTMENTS & SERVICES </h4>
                                    <p className="font-16 brand-color3 text-justify"> Warrior allows clients to book an appointment for a service with an available staff member on their mobile app or on the web.  Booking and paying for a personal training, spa treatment, nutritional consultation, or physiotherapy appointment is easy with our appointment offering.  Our notifications will remind your clients so they don’t forget about their appointment.  Late cancellation policies are easily enforced and paid for through the app. </p>
                                </div>
                            </div>
                            {/* <!-- end of col-6 --> */}
                        </div>
                        {/* <!-- end of row (Section-5) --> */}

                        <div className="row solution-image-text">
                            <div className="col-12 col-md-6">
                                <div className="solution-image-left wow animated slideInLeft">
                                    <img src="clientassets/img/solution_img10.png" alt="" className="img-fluid w-100" />
                                </div>
                            </div>
                            {/* <!-- end of col-6 --> */}
                            <div className="col-12 col-md-6">
                                <div className="solution-text-right solution-2 wow animated slideInRight">
                                    <h4 className="dark-color text-uppercase mt-4"> MANAGE STAFF & INSTRUCTORS </h4>
                                    <p className="font-16 brand-color3 text-justify"> You will save time and effort managing your staff and instructors with Warrior software.  Create and fill shifts, manage staff hours, and manage pay rates per shift or className.  </p>
                                </div>
                            </div>
                            {/* <!-- end of col-6 --> */}
                        </div>
                        {/* <!-- end of row (Section-6) --> */}

                        <div className="row solution-text-image">
                            <div className="col-12 col-md-6 order-2 order-md-1">
                                <div className="solution-text-left solution-10 wow animated slideInLeft">
                                    <h4 className="dark-color text-uppercase mt-4"> KEEP YOUR MEMBERS AND STAFF UP TO DATE </h4>
                                    <p className="font-16 brand-color3 text-justify"> The Warrior notification service gives your customers and staff the information they need about classes, memberships, appointments, reminders, and urgent news. </p>
                                </div>
                            </div>
                            {/* <!-- end of col-6 --> */}
                            <div className="col-12 col-md-6 order-1 order-md-2">
                                <div className="solution-image-right wow animated slideInRight">
                                    <img src="clientassets/img/solution_img3.png" alt=""className="img-fluid w-100" />
                                </div>
                            </div>

                        </div>
                        {/* <!-- end of row (Section-7) --> */}

                        <div className="row solution-image-text">
                            <div className="col-12 col-md-6">
                                <div className="solution-image-left wow animated slideInLeft text-center">
                                    <img src="clientassets/img/solution_img13.png" alt="" className="img-fluid" />
                                </div>
                            </div>
                            {/* <!-- end of col-6 --> */}
                            <div className="col-12 col-md-6">
                                <div className="solution-text-right solution-3 wow animated slideInRight">
                                    {/* <h4 className="dark-color text-uppercase mt-4"> MARKETING YOUR BUSINESS </h4> */}
                                    <h4 className="dark-color text-uppercase mt-4"> Hosted Integrated Website </h4>
                                    {/* <p className="font-16 brand-color3 text-justify"> Email and Social promotions will help grow your business. Warrior has partnered with Constant Contact saving you time and effort. This service is optional and a free trial is available so you can determine if it’s right for you. If you already have a constant contact account, then we can connect it to your Warrior account. </p> */}
                                    <p className="font-16 brand-color3 text-justify">We will help you build and host your website so you can easily include your staff, classes, passes, services, workshops etc on your site right out of what you create in Warrior.  Build your client list with a contact form that allows you to send marketing information like newsletter, blog posts, and studio updates. </p>
                                </div>
                            </div>
                            {/* <!-- end of col-6 --> */}
                        </div>
                        {/* <!-- end of row (Section-8) --> */}

                        <div className="row solution-text-image">
                            <div className="col-12 col-md-6 order-1 order-md-2">
                                <div className="solution-image-right wow animated slideInRight">
                                    <img src="clientassets/img/solution_img11.png" alt="" className="img-fluid w-100" />
                                </div>
                            </div>
                            {/* <!-- end of col-6 --> */}
                            <div className="col-12 col-md-6 order-2 order-md-1">
                                <div className="solution-text-left solution-14 wow animated slideInLeft">
                                    <h4 className="dark-color text-uppercase mt-4"> PAYMENTS  </h4>
                                    <p className="font-16 brand-color3 text-justify"> Grow your revenue by making the purchase of your service seamless. Get the most out of Warrior’s solution by adding our integrated payments that works in the mobile app or online. Customers can purchase passes, services, products or pay cancelation fees with the tap of a button. Learn more about our competitive rates once you have logged in to your account. </p>
                                </div>
                            </div>
                            {/* <!-- end of col-6 --> */}
                        </div>
                        {/* <!-- end of row (Section-9) --> */}

                        <div className="row solution-image-text">
                            <div className="col-12 col-md-6">
                                <div className="solution-image-left wow animated slideInLeft">
                                    <img src="clientassets/img/solution_img12.png" alt="" className="img-fluid w-100" />
                                </div>
                            </div>
                            {/* <!-- end of col-6 --> */}
                            <div className="col-12 col-md-6">
                                <div className="solution-text-right solution-11 wow animated slideInRight">
                                    <h4 className="dark-color text-uppercase mt-4"> POINT OF SALE EQUIPEMENT </h4>
                                    <p className="font-16 brand-color3 text-justify"> Warrior Payments offers the latest in payment point of sale equipment.  Customers love using the Clover terminal, you can accept debit and credit cards in person at your front desk and the information will sync automatically with your Warrior software. </p>
                                </div>
                            </div>
                            {/* <!-- end of col-6 --> */}
                        </div>
                        {/* <!-- end of row (Section-10) --> */}

                        <div className="row solution-text-image">
                            <div className="col-12 col-md-6 order-2 order-md-1">
                                <div className="solution-text-left solution-12 wow animated slideInLeft">
                                    <h4 className="dark-color text-uppercase mt-4"> ACCOUNTING SYNC </h4>
                                    <p className="font-16 brand-color3 text-justify"> Warrior has partnered with Quickbooks Online to help you manage your business easier and with greater accuracy.  By using Warrior with your Quickbooks Online account sales, refunds, payroll, and instructor contractor payments can be managed with ease.  This feature is optional.  Contact us to learn more. </p>
                                </div>
                            </div>
                            {/* <!-- end of col-6 --> */}
                            <div className="col-12 col-md-6 order-1 order-md-2">
                                <div className="solution-image-right wow animated slideInRight">
                                    <img src="clientassets/img/solution_img14.png" alt="" className="img-fluid w-100" />
                                </div>
                            </div>
                            {/* <!-- end of col-6 --> */}
                        </div>
                      

                    </div>
                    {/* <!-- end of container-fluid --> */}
                </div>
                {/* <!-- end of solution-timeline --> */}
            </section>
            {/* <!-- End of Solution Content Section --> */}

            <StaticFooter />

        </>
    )
}
