import React from 'react'
import { Form } from "react-bootstrap";

export default function SharedCheckboxFalse({onClick,value,name,status}) {
  return (
    <>
    <Form.Check 
    type="radio"
    aria-label="radio 1"
    name={name}
    value={value}
    checked={status === "Reject"} 
    onChange={onClick}
    className="CheckedTure false"
    />
    </>
  )
}
