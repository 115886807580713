import React, { useContext, useState, useEffect } from "react";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { staff_get_notification_list, staff_read_notification } from "../../../Static_Services/Staff_Services";
import moment from "moment";

export const InnerHeader = () => {
  const [userDetail, setUserDetail] = useState([]);
  const [manuTogal, setManuTogal] = useState(false);
  const [unreadCount, setUnreadCount] = useState();
  const [notificationDetail, setNotificationDetail] = useState([]);
  const [cartCount, setCartCount] = useState();
  const location = useLocation();

  useEffect(() => {
    if (localStorage.getItem("userDetail")) {
      setUserDetail(JSON.parse(localStorage.getItem("userDetail")));
    }
  }, [location]);

  useEffect(() => {
    if (localStorage.getItem('w_CartValues')) {
      setCartCount(JSON.parse(localStorage.getItem('w_CartValues')));
    } else {
      setCartCount();
    }
  }, [localStorage.getItem('w_CartValues')]);

  // const logOut = () => {
  //   alert("HEllo");
  // };

  const getNotification = async () => {
    const fData = {
      "pageid": 1,
      "role_type": userDetail.role_id
    };
    const resp = await staff_get_notification_list(fData);
    if (resp) {
      if (resp.data.data.unread_count) {
        setUnreadCount(resp.data.data.unread_count);
      }
      if (resp.data.data.notification) {
        setNotificationDetail(resp.data.data.notification);
      }
    }
  }

  useEffect(() => {
    if (userDetail) {
      getNotification();
    }
  }, [])

  useEffect(() => {
    if (userDetail) {
      getNotification();
    }
  }, [userDetail])

  const markRead = async (id) => {
    let detail = { "notification_id": id };
    const resp = await staff_read_notification(detail);
    if (resp) {
      getNotification();
    }

  }


  const getMarkRead = (data) => {
    if (data.read == 0) {
      markRead(data.id);
    }
  }
  const navigate = useNavigate();

  const logOut = () => {
    localStorage.clear();
    localStorage.setItem('id', '');
    localStorage.removeItem('id');
    localStorage.setItem('Staffid', '');
    localStorage.removeItem('Staffid');
    navigate('/');
  }

  return (
    <>
      {/* <!-- Header Section --> */}
      <header
        className="header-customer box-shadow1 py-2 px-0 px-sm-3"
        id="myHeader"
      >
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light white-bg p-0">
            <Link className="navbar-brand" to="/my-home">
              <img src="clientassets/img/logo_colored.png" className="img-fluid img-size" />
            </Link>
            <button
              className="navbar-toggler ml-auto my-3 my-lg-0"
              type="button"
              data-toggle="collapse"
              aria-controls="navbarNavDropdown"
              aria-expanded="true"
              aria-label="Toggle navigation"
              onClick={() => setManuTogal(!manuTogal)}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            {/* {userDetail.address && ( */}
            {userDetail && (
              <div className="collapse navbar-collapse">
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item" routerLinkActive="active">
                    <Link className="nav-link header-css" to="/my-home">Home</Link>
                  </li>
                  <li className="nav-item" routerLinkActive="active">
                    <Link className="nav-link header-css" to="/studio"> Studios</Link>
                  </li>
                  {userDetail && userDetail.role_id === "3" &&
                    <li className="nav-item" routerLinkActive="active">
                      <Link className="nav-link header-css" to="/family"> Family </Link>
                    </li>
                  }
                  {userDetail && userDetail.role_id === "3" &&
                    <li className="nav-item" routerLinkActive="active">
                      <Link className="nav-link header-css" to="/my_payment"> Payment </Link>
                    </li>
                  }
                  {userDetail && userDetail.role_id === "3" &&
                    <li className="nav-item" routerLinkActive="active">
                      <Link className="nav-link header-css" to="/my-transaction"> Transaction</Link>
                    </li>
                  }
                  <li className="nav-item" routerLinkActive="active">
                    <Link className="nav-link header-css" to="/myfavourites"> Favourites</Link>
                  </li>
                  <li className="nav-item" routerLinkActive="active">
                    <Link className="nav-link header-css" to="/preference"> Preference</Link>
                  </li>
                  {userDetail && userDetail.role_id === '4' &&
                    <li className="nav-item" routerLinkActive="active">
                      <Link className="nav-link header-css" to="/my-profile"> Profile </Link>
                    </li>
                  }
                  <li className="nav-item" onClick={() => logOut()}>
                    <Link className="nav-link header-css"> Logout </Link>
                  </li>
                  {userDetail.role_id === '3' && (
                    <li className="nav-item" routerLinkActive="active">
                      <Link className="nav-link" to="/my-cart">
                        <i className="fa fa-shopping-cart fa-lg"></i>
                        <sup>
                          <span className="notification-count" style={{ fontSize: "20px", color: "#1B96D3" }} >
                            {cartCount}
                          </span>
                        </sup>
                      </Link>
                    </li>
                  )}

                  <li>
                    <Dropdown>
                      <Dropdown.Toggle className="notification" variant="success" id="dropdown-basic">
                        <img src="clientassets/img/ic_notifications.png" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="notification_panel">
                        <div className="row">
                          <div className="col-12">
                            <div className="nav-upper px-3 pt-4">
                              <div className="row">
                                <div className="col-12 col-md-3 text-center">
                                  <img src="clientassets/img/ic_notification.png" className="img-fluid" />
                                </div>
                                <div className="col-12 col-md-9">
                                  <div className="notification-text">
                                    <h4 className="title dark-color mb-0 mt-1"> Notifications  </h4>
                                    {unreadCount != 0 &&
                                      <p className="brand-color3 font-16" >
                                        {unreadCount} new notifications
                                      </p>
                                    }
                                  </div>
                                </div>
                                <div className="col-12 text-right">
                                  <div className="notification-mark pb-2">
                                    {/* <!-- <Link href="Javascript:void(0);" className="brand-color1 font-12"> Mark all as read </Link> --> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <!-- end of nav-upper --> */}

                          <div className="col-12 nav-lower">
                            <div className="notify-content">
                              {notificationDetail.length > 0 && notificationDetail.map((notfy) => (
                                <div className="notifi-block border-bottom" >
                                  <div>
                                    {notfy.read == 1 &&
                                      <div className="row p-3" onClick={() => getMarkRead(notfy)} >
                                        <div className="col-6 text-left">
                                          <p className="dark-color font-16 font-w-600">{notfy.title}  </p>
                                        </div>
                                        <div className="col-6 text-right">
                                          <p className="brand-color3 font-14 font-w-100">{moment.unix(notfy.create_dt_utc).format('MMM DD YYYY')}</p>
                                        </div>
                                        <div className="col-12">
                                          <p className="dark-color font-14 font-w-600" dangerouslySetInnerHTML={{ __html: notfy.message }}></p>
                                        </div>
                                      </div>
                                    }
                                    {notfy.read == 0 &&
                                      <div className="row light-bg p-3" onClick={() => getMarkRead(notfy)} >
                                        <div className="col-6 text-left">
                                          <p className="dark-color font-16 font-w-600">{notfy.title}  </p>
                                        </div>
                                        <div className="col-6 text-right">
                                          <p className="brand-color3 font-14 font-w-100">{moment.unix(notfy.create_dt_utc).format('MMM DD YYYY')}</p>
                                        </div>
                                        <div className="col-12">
                                          <p className="dark-color font-14 font-w-600" dangerouslySetInnerHTML={{ __html: notfy.message }}></p>
                                          {/* <p className="dark-color font-14 font-w-600"
                                          [innerHtml]="notfy.message"
                                          dangerouslySetInnerHTML={{ __html: notfy.message }}
                                          >
                                            {notfy.message} 
                                            </p> */}
                                        </div>
                                      </div>
                                    }
                                  </div>
                                  {/* <!-- Notification - 2 --> */}
                                </div>
                              ))}

                            </div>
                            {/* <!-- end of notify-content --> */}
                          </div>
                          {/* <!-- end of nav-lower --> */}
                        </div>
                      </Dropdown.Menu >
                    </Dropdown >
                  </li >

                  {/* <!-- Bell --> */}
                  {
                    userDetail.role_id === "3" &&
                    <li className="nav-item" routerLinkActive="active">
                      <Link className="nav-link" to="/profile">
                        <div className="header-profile-menu">
                          <img src={userDetail.profile_img} className="img-fluid" />
                        </div>
                      </Link>
                    </li>
                  }
                  {/* <!-- Profile --> */}

                  <li className="nav-item dropdown d-lg-none">
                    <Link
                      className="nav-link dropdown-toggle"
                      href="Javascript:void(0);"
                      id="navbarDropdownMenuLink"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {" "}
                      <img src="clientassets/img/ic_menu.png" />{" "}
                    </Link>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdownMenuLink"
                    >
                      <div className="row">
                        <div className="col-12">
                          <div className="menu-list">
                            <ul>
                              {userDetail && userDetail.role_id === "4" &&
                                <li routerLinkActive="active"><Link to="/my-profile">My Profile</Link></li>
                              }

                              {userDetail && userDetail.role_id === "3" &&
                                <li routerLinkActive="active"><Link to="/profile"> My Profile </Link></li>
                              }
                              <li routerLinkActive="active"><Link to="/studio"> My Studios </Link></li>
                              <li routerLinkActive="active"><Link to="/myfavourites"> My Favourites</Link></li>
                              {userDetail && userDetail.role_id === "3" &&
                                <li routerLinkActive="active"><Link to="/my_payment"> My Payments </Link></li>
                              }
                              {userDetail && userDetail.role_id === "3" &&
                                <li routerLinkActive="active"><Link to="/family"> My Family </Link></li>
                              }
                              <li routerLinkActive="active"><Link to="/preference">My Preference</Link></li>
                              <li routerLinkActive="active" onClick={() => logOut()}><Link > Logout </Link></li>
                            </ul>
                          </div>
                          <div className="menu-list-bottom light-bg">
                            <ul>
                              <li routerLinkActive="active"><Link to="/apps"> Apps </Link> </li>
                              <li routerLinkActive="active"><Link to="/allproduct"> Products </Link></li>
                              <li routerLinkActive="active"><Link to={"youtubeUrl"}> FAQ </Link></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul >
              </div >
            )}


            {manuTogal && (
              <div className="collapsed navbar-collapse">
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <Link className="nav-link header-css"> Home </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link header-css"> Studios </Link>
                  </li>
                  {userDetail && userDetail.role_id === "3" &&
                    <li className="nav-item"> <Link className="nav-link header-css"> Family </Link> </li>
                  }
                  {userDetail && userDetail.role_id === "3" &&
                    <li className="nav-item"> <Link className="nav-link header-css" to={'/my_payment'}> Payment </Link> </li>
                  }
                  <li className="nav-item"> <Link className="nav-link header-css"> Favourites </Link> </li>
                  {/* <li className="nav-item"> <Link className="nav-link header-css"> Preference </Link> </li> */}
                  {userDetail && userDetail.role_id === "4" &&
                    <li className="nav-item"> <Link className="nav-link header-css" to="/my-Profile">Profile</Link> </li>
                  }
                  <li className="nav-item" onClick={() => logOut()}>
                    <Link className="nav-link header-css" > Logout </Link>
                  </li>
                  {userDetail && userDetail.role_id === "3" &&
                    <li className="nav-item">
                      <Link className="nav-link" to={'/my-cart'}> <i className="fa fa-shopping-cart"></i>
                        <sup>
                          <span className="notification-count"> {cartCount} </span>
                        </sup>
                      </Link>
                    </li>
                  }
                  {/* <!-- Cart --> */}
                  {/* (click)="getNotifiction()" */}
                  <li className="nav-item dropdown">
                    <Dropdown>
                      <Dropdown.Toggle className="notification" variant="success" id="dropdown-basic02">
                        <img src="clientassets/img/ic_notifications.png" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="notification_panel">
                        <div className="row">
                          <div className="col-12">
                            <div className="nav-upper px-3 pt-4">
                              <div className="row">
                                <div className="col-12 col-md-3 text-center">
                                  <img src="clientassets/img/ic_notification.png" className="img-fluid" />
                                </div>
                                <div className="col-12 col-md-9">
                                  <div className="notification-text">
                                    <h4 className="title dark-color mb-0 mt-1"> Notifications</h4>
                                    {unreadCount != 0 &&
                                      <p className="brand-color3 font-16" >
                                        {unreadCount} new notifications
                                      </p>
                                    }
                                  </div>
                                </div>
                                <div className="col-12 text-right">
                                  <div className="notification-mark pb-2">
                                    {/* <!-- <Link href="Javascript:void(0);" className="brand-color1 font-12"> Mark all as read </Link> --> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <!-- end of nav-upper --> */}

                          <div className="col-12 nav-lower">
                            <div className="notify-content">
                              {notificationDetail.length > 0 && notificationDetail.map((notfy) => (
                                <div className="notifi-block border-bottom" >
                                  <div>
                                    {notfy.read == 1 &&
                                      <div className="row p-3" onClick={() => getMarkRead(notfy)} >
                                        <div className="col-6 text-left">
                                          <p className="dark-color font-16 font-w-600">{notfy.title}  </p>
                                        </div>
                                        <div className="col-6 text-right">
                                          <p className="brand-color3 font-14 font-w-100">{moment.unix(notfy.create_dt_utc).format('MMM DD YYYY')}</p>
                                        </div>
                                        <div className="col-12">
                                          <p className="dark-color font-14 font-w-600" dangerouslySetInnerHTML={{ __html: notfy.message }} > </p>
                                        </div>
                                      </div>
                                    }
                                    {notfy.read == 0 &&
                                      <div className="row light-bg p-3" onClick={() => getMarkRead(notfy)} >
                                        <div className="col-6 text-left">
                                          <p className="dark-color font-16 font-w-600">{notfy.title}  </p>
                                        </div>
                                        <div className="col-6 text-right">
                                          <p className="brand-color3 font-14 font-w-100">{moment.unix(notfy.create_dt_utc).format('MMM DD YYYY')}</p>
                                        </div>
                                        <div className="col-12">
                                          <p className="dark-color font-14 font-w-600" dangerouslySetInnerHTML={{ __html: notfy.message }}>
                                            {notfy.message}
                                          </p>
                                        </div>
                                      </div>
                                    }
                                  </div>
                                  {/* <!-- Notification - 2 --> */}
                                </div>
                              ))}

                            </div>
                            {/* <!-- end of notify-content --> */}
                          </div>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                  {/* <!-- Bell --> */}
                  {/*ngIf=""*/}
                  {userDetail && userDetail.role_id == 3 && (
                    <li className="nav-item">
                      <Link className="nav-link" to="/profile">
                        <div className="header-profile-menu">
                          <img src={userDetail.profile_img} className="img-fluid" />
                        </div>
                      </Link>
                    </li>
                  )}
                  <li className="nav-item dropdown  d-lg-none">
                    <Dropdown>
                      <Dropdown.Toggle className="notification" variant="success" id="dropdown-basic03">
                        <img src="clientassets/img/ic_menu.png" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="notification_panel">
                        <div className="row">
                          <div className="col-12">
                            <div className="menu-list">
                              <ul>
                                {userDetail.role_id === "4" && (
                                  <li><Link to="/my-profile">My Profile </Link></li>
                                )}
                                {userDetail.role_id === "3" && (
                                  <li><Link to="/profile">My Profile</Link></li>
                                )}
                                <li><Link> My Studios </Link></li>
                                {userDetail.role_id === "4" && (
                                  <li><Link> My Schedule </Link></li>
                                )}
                                <li><Link> My Favourites </Link></li>
                                {userDetail.role_id === "3" && (
                                  <li><Link to="/my_payment"> My Payments </Link></li>
                                )}
                                {userDetail.role_id === "3" && (
                                  <li><Link> My Transaction </Link></li>
                                )}
                                {userDetail.role_id === "3" && (
                                  <li><Link> My Family </Link></li>
                                )}
                                <li><Link> My Preference </Link></li>
                                <li onClick={() => logOut()}><Link >Logout</Link></li>
                              </ul>
                            </div>
                            <div className="menu-list-bottom light-bg">
                              <ul>
                                <li><Link> Apps </Link></li>
                                <li><Link> Products </Link></li>
                                <li><Link> FAQs </Link></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                  {/* <!-- Menu --> */}
                </ul>
              </div>
            )
            }

          </nav >
        </div >
        {/* <!-- end of container --> */}
      </header >
      {/* <!-- Header Section Ends Here --> */}
    </>
  );
};
