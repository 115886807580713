import React, { useContext } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import Sidebar from "../../comman_page/Sidebar";
import { HeaderDashboard } from "../../comman_page/HeaderDashboard";
import { SharedSelect } from "../../../../../sharedComponents/SharedSelect";
import { InputField } from "../../../../../sharedComponents/InputField";
import { Footer } from "../../../../../public/containers/footer/Footer";
import { TextArea } from "../../../../../sharedComponents/TextArea";
import { CardCarousel } from "../../../../../sharedComponents/CardCarousel";
import { MemberShipCard } from "../../../../../sharedComponents/MemberShipCard";
import { CardClassList } from "../../../../../sharedComponents/CardClassList";
import { passes_list, class_list, get_business_categories, add_class } from "../../../../../Studio-Services/Studio_Services";
import { useEffect } from "react";
import { useState } from "react";
import { MyContext } from "../../../../../App";
// import { MemberShipCardWithSlider } from "../../../../../sharedComponents/MemberShipCardWithSlider";
import { SharedButton } from "../../../../../sharedComponents/Button";
import Swal from "sweetalert2";
import logo from "../../../../../Images/logo_white_web.png"
import { errorAlert } from "../../../../../Static_Services/Alertmsg";

export const Addnewclass = () => {
  const business_id = localStorage.getItem("business_id");
  const [classlist, setClasslist] = useState([]);
  const [timePass, setTimePass] = useState([]);
  const [punchPass, setPunchPass] = useState([]);
  const [recurringPass, setRecurringPass] = useState([]);
  const [userData, setUserData] = useState([]);
  const [category, setCategory] = useState([]);

  const [passValueRecurring, setPassValueRecurring] = useState([]);
  const [passValueTimeFram, setPassValueTimeFram] = useState([]);
  const [passValuePunch, setassValuePunch] = useState([]);
  const context = useContext(MyContext);

  const recuringHandler = (data) => {
    setPassValueRecurring(data);
  }

  const timeFramHandler = (data) => {
    setPassValueTimeFram(data);
  }

  const punchHandler = (data) => {
    setassValuePunch(data)
  }

  const get_class_list = async () => {
    const data = {
      "pageid": "1",
      "business_id": business_id
    }


    const resp = await class_list(data);
    if (resp) {
      const finalList = [];
      const list = resp.data.data;
      list.map((e) => (
        finalList.push({
          memberShipImg: userData.logo ? userData.logo : "",
          class_name: e.class_name ? e.class_name : "",
          date: e.start_date ? e.start_date : "",
          product: e.capacity ? e.capacity : "",
          class_type: e.class_type ? e.class_type : "",
          duration: e.duration ? e.duration : "",
          description: e.description ? e.description : "",
          class_id: e.class_id ? e.class_id : ""
        })
      ))

      setClasslist(finalList);
    }
  }
  const getBusinessCategories = async () => {
    const fData = { "type": "1", "subcat": "" }
    const type = [];
    const resp = await get_business_categories(fData);
    if (resp) {
      const fresp = resp.data.data;
      fresp.map((e) => (
        type.push({
          value: e.category_id, label: e.category_name
        })
      ))
      setCategory(type);
    }
  }


  const passList = async () => {
    const timefram = [];
    const punchfram = [];
    const recuring = [];
    const data = { "page_no": "1" };
    const resp = await passes_list(data);
    if (resp && resp.data.data) {
      const fData = resp.data.data;
      fData.map((e) => (
        e.pass_type === "Time Frame" ?
          timefram.push({
            pass_id: e.pass_id,
            memberShipImg: userData.business_img ? userData.business_img : "",
            cardTitle: e.pass_name,
            price: e.amount,
            validText: `Valid For ${e.pass_days} Days`,
            tax: "Plus Tax"
          })
          : e.pass_type === "Recurring Membership" ?
            recuring.push({
              pass_id: e.pass_id,
              memberShipImg: userData.business_img ? userData.business_img : "",
              cardTitle: e.pass_name,
              price: e.amount,
              validText: `Valid For ${e.pass_days} Days`,
              tax: "Plus Tax"
            })
            : e.pass_type === "Punch Card" ?
              punchfram.push({
                pass_id: e.pass_id,
                memberShipImg: userData.business_img ? userData.business_img : "",
                cardTitle: e.pass_name ? e.pass_name : "",
                price: e.amount ? e.amount : "",
                validText: `Valid For ${e.pass_days} Classes`,
                tax: "Plus Tax"
              })
              : ""
      ))
      setTimePass(timefram);
      setPunchPass(punchfram);
      setRecurringPass(recuring);
    }
  }

  useEffect(() => {
    if (context.context !== undefined && context.context !== null) {
      const udata = context.context.uData;
      setUserData({
        business_img: udata.business_img,
        logo: udata.logo
      });
    }
    passList();
    getBusinessCategories();
    get_class_list();
  }, [context])

  const [formData, setFormData] = useState({
    name: "",
    ename: '',
    category: "",
    ecategory: "",
    duration: "",
    eduration: "",
    description: "",
    edescription: ""
  });


  const nameHandler = (data) => {
    setFormData((pre) => ({
      ...pre,
      name: data,
      ename: ""

    }))
  }
  const categoryHandler = (data) => {
    setFormData((pre) => ({
      ...pre,
      category: data,
      ecategory: ""
    }))
  }
  const durationHandler = (data) => {
    setFormData((pre) => ({
      ...pre,
      duration: data,
      eduration: ""
    }))
  }
  const descriptionHandler = (data) => {
    setFormData((pre) => ({
      ...pre,
      description: data,
      edescription: ""
    }))

  }


  const submitHandler = async (e) => {
    e.preventDefault();
    let isValid = 1;

    const pass_ids = [];
    if (passValueRecurring) {
      passValueRecurring.map((e) => (
        pass_ids.push(e)
      ))
    }
    if (passValueTimeFram) {
      passValueTimeFram.map((e) => (
        pass_ids.push(e)
      ))
    }
    if (passValuePunch) {
      passValuePunch.map((e) => (
        pass_ids.push(e)
      ))
    }
    if (!formData.name) { isValid = 0; setFormData((pre) => ({ ...pre, ename: "*Required" })) }
    if (!formData.category) { isValid = 0; setFormData((pre) => ({ ...pre, ecategory: "*Required" })) }
    if (!formData.duration) { isValid = 0; setFormData((pre) => ({ ...pre, eduration: "*Required" })) }
    if (!formData.description) { isValid = 0; setFormData((pre) => ({ ...pre, edescription: "*Required" })) }
    // if(pass_ids.length === 0){isValid = 0; errorAlert('Plese Select Pass')}

    if (isValid === 1) {
      const finalFormData = {
        "class_name": formData.name,
        "class_type": formData.category,
        "capacity": null,
        "duration": formData.duration,
        "description": formData.description,
        "status": null,
        "pass_id": pass_ids
      }
      
      const resp = await add_class(finalFormData);
      if (resp) {
        Swal.fire({
          html: `
          <img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
            SUCCESS
          </h4>
          <p class="text-center my-4 textcolor">
            <small class="textWhite">
              ${resp.data.message}
            </small>
          </p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        }).then((result) => {
          get_class_list();
          if (result.isConfirmed) {
            setFormData((pre) => ({
              name: "",
              ename: '',
              category: "",
              ecategory: "",
              duration: "",
              eduration: "",
              description: "",
              edescription: ""
            }))
          }
        });
      }
    }

  }

  return (
    <>
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col className="p-0 RightCol">
              <HeaderDashboard />
              <div className="RightSide">
                <section className="addClient innerContent">
                  <Container className="pt-5">
                    <h4>List Your Class</h4>
                    <p>
                      Set up your list of classes
                    </p>
                    <div className='AddClientForm AddClientContent'>
                      <div className="CardCarousel my-5">
                        <h5>Class List</h5>
                        <CardClassList pass={classlist} />
                      </div>
                      <Form onSubmit={submitHandler} >
                        <Row className="mt-3">
                          <Col lg={4} md={4} sm={12}>
                            <InputField
                              type={"text"}
                              value={formData.name}
                              name="name"
                              error={formData.ename}
                              lable={"Name"}
                              placeholder="Name"
                              onChange={(e) => nameHandler(e.target.value)}
                            />
                          </Col>
                          <Col lg={4} sm={4}>
                            <SharedSelect
                              value={formData.category}
                              onChange={(e) => categoryHandler(e.target.value)}
                              name="category"
                              error={formData.ecategory}
                              options={category}
                              lable={"Type"}
                            />
                          </Col>
                          <Col lg={4} md={4} sm={4}>
                            <InputField
                              name="duration"
                              error={formData.eduration}
                              onChange={(e) => durationHandler(e.target.value)}
                              type={"number"}
                              lable={"Duration (in Minutes)"}
                              placeholder="Minutes"
                              value={formData.duration}
                            />
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col lg={12} sm={12}>
                            <TextArea
                              value={formData.description}
                              error={formData.edescription}
                              placeHolder="Description....."
                              onChange={(e) => descriptionHandler(e.target.value)}
                              name="description"
                              lable={"Description"}
                            />
                          </Col>
                          <Col></Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="MemberShipCard">
                              <div className="CardCarousel mt-5">
                                <h5 className="grropCard">Recurring Membership</h5>
                                <Row>
                                  <MemberShipCard pass={recurringPass} setValue={recuringHandler} />
                                </Row>
                              </div>
                              <div className="TimeCard mt-5">
                                <h5 className="grropCard">Time Frame</h5>
                                <Row>
                                  <MemberShipCard pass={timePass} setValue={timeFramHandler} />
                                </Row>
                              </div>
                              <div className="PunchCard mt-5">
                                <h5 className="grropCard">Punch Card</h5>
                                <Row>
                                  <MemberShipCard pass={punchPass} setValue={punchHandler} />
                                </Row>
                              </div>
                              {/* <CardCarousel/> */}
                              <div className="classesTimingCard">
                                {/* <ClassesCard/> */}
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row className="mt-5">
                          <Col lg={4} sm={12}>
                            <SharedButton title={"SAVE"} type={"submit"} variant={"primary"} className={"btn btn-primary w-100"} />
                          </Col>
                          <Col lg={4} sm={12}>
                            <SharedButton title={"DONE WITH CLASSES"} type={"button"} href={"/add-items"} variant={"outline"} className={"btn btn-outline w-100"} />
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </Container>
                </section>
                <Footer />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
