import React from 'react'
import { InnerHeader } from '../../Components/inner-header/InnerHeader1'
import { SubHeader } from '../../Components/sub-header/SubHeader'
import { Innerfooter } from '../../Components/inner-footer/Innerfooter'
import { Spinner } from 'react-bootstrap'
import { useState } from 'react'
import { DashboardToSectionDetails } from '../../Components/CommanPages/DashboardToSectionDetails'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { API_my_book_workshop_list, API_my_class_list } from '../../../Static_Services/Staff_Services'
import moment from 'moment'
import { useEffect } from 'react'

export const ClientMyWaitlist = () => {
  const [loder, setLoder] = useState(false);
  const [businessDetail, setBusinessDetail] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [currentUrl, setCurrentUrl] = useState();
  const [waitingDetail, setWaitingDetail] = useState([]);
  const [classDetail, setClassDetail] = useState([]);
  const [workshopDetail, setWorkshopDetail] = useState([]);

  useEffect(() => {
    if (localStorage.getItem('w_BusinessDetail')) {
      setBusinessDetail(JSON.parse(localStorage.getItem('w_BusinessDetail')));
    }
    if (location.pathname) {
      setCurrentUrl(location.pathname);
    }
  }, [location])


  const getClassDetail = async (status, classStatus) => {
    setLoder(true)
    const detail = { "pageid": "1", "business_id": businessDetail.business_id, "status": status, 'class_status': classStatus };
    const resp = await API_my_class_list(detail);
    if (resp) {
      let response = resp.data;
      if (response.data.length != 0) {
        response.data.map((el) => {
          // Date Conversion UTC
          el.start_date_utc_new = moment.unix(el.start_date_utc).format('MMM DD YYYY');

          el.end_date_utc_new = moment.unix(el.end_date_utc).format('MMM DD YYYY');
          //time conversion
          el.from_time_utc_new = moment.unix(el.from_time_utc).format('hh mm A');
        })
      }
      if (status == 1) {
        setWaitingDetail(response.data);
      } else {
        setClassDetail(response.data);
      }
      setLoder(false);
    } else {
      setLoder(false);
      setClassDetail([]);
      setWaitingDetail([]);
    }
  }

  const getworkshop = async () => {
    setLoder(true);
    const detail = { 'pageid': 1 };
    const resp = await API_my_book_workshop_list(detail);
    if (resp) {
      let response = resp.data;
      if (response.data.length != 0) {
        response.data.map((el) => {
          el.start_date_utc_new = moment.unix(el.schedule_date).format('MMM DD YYYY');
          el.from_time_utc_new = moment.unix(el.start_time).format('hh mm A');
          el.to_time_utc_new = moment.unix(el.end_time).format('hh mm A');
        })
      }
      setWorkshopDetail(response.data);
      setLoder(false);

    } else {
      setWorkshopDetail([]);
      setClassDetail([]);
      setWaitingDetail([]);
      setLoder(false);
    }

  }

  const reset = () =>{
    navigate('/studio-detail');
  }
  const redirectUrl = (url,Id,scheId,current)=>{
    let detail = {'id' : Id ,'currentdate': current, 'schedule_id' : scheId ,  'backurl' :currentUrl};
    navigate(url,{state:{Id : detail}});
  }

  const showHide = (event)=>{   
    getClassDetail(0,event.target.value);
  }

  useEffect(()=>{
    if(location.pathname && businessDetail){
      if(currentUrl == "/my-classlist"){
        getClassDetail('0',0);
      } else{
        getClassDetail('1',0);
      }
    }
  },[businessDetail,location.pathname])

  return (
    <>
      {loder ? <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div> : ""}
      <InnerHeader />

      <div className="body-grey">
        <div class="studio-details">
          <div class="container">
            <DashboardToSectionDetails />
            <div class="studio-details-lower pb-5">
              <div class="row mt-3">
                <div class="col-sm-4 col-lg-8">
                  {currentUrl == '/my-waitlist' &&
                    <h4 class="black-color font-w-400 font-18 mb-0 text-uppercase font-arialrouded">My Waitlists </h4>}
                  {currentUrl == '/my-classlist' &&
                    <h4 class="black-color font-w-400 font-18 mb-0 text-uppercase font-arialrouded">My Classes </h4>}
                </div>
                <div class="col-md-4 col-sm-6 text-right mb-3">
                  <button type="button" class="btn btn-blue-grad px-2 text-uppercase"
                    onClick={() => reset()}
                  >Back </button>

                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="white-bg border-radius6 box-shadow1 p-3">
                    {currentUrl == '/my-classlist' &&
                      <div class="col-4 std-detail-select" >
                        <select class="form form-control brand-color1 border-radius6" onChange={(e) => showHide(e)} >
                          <option value="0"> My Future Classes </option>
                          <option value="1" > My Past Classes </option>
                        </select>
                      </div>}
                    <div class="centered-cover">
                      <div class="flex-break">
                        {currentUrl == '/my-classlist' &&
                          <div class="box my-classes" >
                            <div class="row">
                              <div class="col-12">
                                <div class="detail-top-right pt-2">
                                  <div class="text-right">
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="activity-card-section mt-4 pt-2">
                              {classDetail.length != 0 &&
                                <div class="row" >
                                  {classDetail.map((classlist) => (
                                    <div class="col-12 col-md-4 mb-4" >
                                      <div class="activity-card box-shadow1 border-radius6 white-bg p-2">
                                        <div class="row">
                                          <div class="col-3 col-sm-3 col-md-12 col-lg-3">
                                            <a href="Javascript:void(0);"
                                              onClick={() => redirectUrl('/classes-detail', classlist.class_id, classlist.scheduled_id, classlist.start_date_utc)} >
                                              <div class="activity-card-logo">
                                                <img src={businessDetail.logo} class="box-shadow1 new-log-img" />
                                              </div>
                                            </a>
                                          </div>
                                          {/* <!-- end of col-3 --> */}
                                          <div class="col-9 col-sm-9 col-md-12 col-lg-9 pl-0 pl-sm-0 pl-md-3 pl-lg-0">
                                            <a href="Javascript:void(0);"
                                              onClick={() => redirectUrl('/classes-detail', classlist.class_id, classlist.scheduled_id, classlist.start_date_utc)} >
                                              <div class="row">
                                                <div class="col-7 col-md-12 col-lg-7 mt-0 mt-md-4 mt-lg-0 pl-0 pl-sm-0 pl-md-3 pl-lg-0">
                                                  <div class="activity-card-details text-left">
                                                    <h5 class="classname-title black-color mb-0"> classlist.class_name</h5>
                                                    {classlist.instructor_details[0] &&
                                                      <ul class="client-inner-align">
                                                        <li><div class="subinst-profileimg">
                                                          <img src={classlist.instructor_details[0].profile_img} class="img-fluid" />
                                                        </div>
                                                        </li>
                                                        <li> <h5 class="black-color mb-0 classname-title pl-2"> {classlist.instructor_details[0].name + '  '} {classlist.instructor_details[0].lastname} </h5> </li>
                                                      </ul>
                                                    }
                                                  </div>
                                                </div>
                                                {/* <!-- end of col-7 --> */}
                                                <div class="col-5 col-md-12 col-lg-5 pr-1">
                                                  <div class="text-right pt-1">
                                                    <p class="black-color font-w-100 font-14 mr-1 mb-1"> {classlist.start_date_utc_new} </p>
                                                    <div class="card-green-box text-center pt-2">
                                                      <p class="white-color mb-0 mt-1"> {classlist.capacity_used}  / {classlist.total_capacity} </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </a>
                                            <div class="row pt-3">
                                              <p class="black-color font-w-100 paratitle mb-0">Location :
                                              </p>
                                              {classlist.location_url &&
                                                <h5 class="heading-titlenew ml-2 mb-0 black-color" >
                                                  <Link to={classlist.location_url} target="_blank">classlist.location</Link>
                                                </h5>
                                              }
                                              {!classlist.location_url &&
                                                <h5 class="heading-titlenew ml-2 mb-0 black-color">classlist.location</h5>
                                              }
                                            </div>
                                            <a href="Javascript:void(0);" onClick={() => redirectUrl('/classes-detail', classlist.class_id, classlist.scheduled_id, classlist.start_date_utc)} >
                                              <div class="row pt-2">
                                                <div class="col-6 col-sm-6 col-md-6 col-lg-6 px-0 border-right">
                                                  <div class="text-left">
                                                    <p class="paratitle black-color mb-1"> Start Time </p>
                                                    <h5 class="classname-title black-color mb-0"> {classlist.from_time_utc_new} </h5>
                                                  </div>
                                                </div>
                                                <div class="col-6 col-sm-6 col-md-6 col-lg-6 d-flex justify-content-end">
                                                  <div class="text-left">
                                                    <p class="paratitle black-color mb-1"> Duration </p>
                                                    <h5 class="classname-title black-color mb-0"> {classlist.duration} </h5>
                                                  </div>
                                                </div>
                                              </div>
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              }
                              {classDetail.length == 0 &&
                                <div >
                                  <div class="text-center">
                                    <p class="font-awesome black-color font-18 d-inline mx-2 "> No Classes Are scheduled</p>
                                  </div>
                                </div>
                              }
                            </div>
                          </div>
                        }
                        {currentUrl == '/my-waitlist' &&
                          <div class="box my-waitlist pt-4">
                            <div class="activity-card-section pt-5">
                              {waitingDetail.length != 0 &&
                                <div class="row">
                                  {waitingDetail.map((classlist) => (
                                    <div class="col-12 col-md-4 mb-4" >
                                      <div class="activity-card box-shadow1 border-radius6 white-bg p-2">
                                        <div class="row">
                                          <div class="col-3 col-sm-3 col-md-12 col-lg-3">
                                            <div class="activity-card-logo">
                                              <img src={businessDetail.logo} class="box-shadow1 new-log-img" />
                                            </div>
                                          </div>
                                          {/* <!-- end of col-3 --> */}

                                          <div class="col-9 col-sm-9 col-md-12 col-lg-9 pl-0 pl-sm-0 pl-md-3 pl-lg-0">
                                            <div class="row">
                                              <div class="col-7 col-md-12 col-lg-7 mt-0 mt-md-4 mt-lg-0 pl-0 pl-sm-0 pl-md-3 pl-lg-0">
                                                <div class="activity-card-details text-left">
                                                  <h5 class="classname-title black-color mb-0"> {classlist.class_name}</h5>
                                                  {classlist.instructor_details[0] &&
                                                    <ul class="client-inner-align" >
                                                      <li>
                                                        <div class="subinst-profileimg">
                                                          <img src={classlist.instructor_details[0].profile_img} class="img-fluid" />
                                                        </div>
                                                      </li>
                                                      <li> <h5 class="black-color mb-0 classname-title pl-2"> {classlist.instructor_details[0].name + ' '}  {classlist.instructor_details[0].lastname} </h5> </li>
                                                    </ul>
                                                  }
                                                </div>
                                              </div>
                                              <div class="col-5 col-md-12 col-lg-5 pr-1">
                                                <div class="text-right pt-1">
                                                  <p class="black-color font-w-100 font-14 mr-1 mb-1">  {classlist.start_date_utc_new}  </p>
                                                  <div class="card-green-box text-center pt-2">
                                                    <p class="white-color mb-0 mt-1">  {classlist.capacity_used}  /  {classlist.total_capacity}  </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="row pt-3">
                                              <p class="black-color font-w-100 paratitle mb-0">Location :
                                              </p>
                                              {classlist.location_url &&
                                                <h5 class="heading-titlenew ml-2 mb-0 black-color" >
                                                  <Link to={classlist.location_url} target="_blank"> {classlist.location} </Link>
                                                </h5>
                                              }
                                              {!classlist.location_url &&
                                                <h5 class="heading-titlenew ml-2 mb-0 black-color"> {classlist.location} </h5>
                                              }
                                            </div>
                                            <div class="row pt-2">
                                              <div class="col-6 col-sm-6 col-md-6 col-lg-6 px-0 border-right">
                                                <div class="text-left">
                                                  <p class="paratitle black-color mb-1"> Start Time </p>
                                                  <h5 class="classname-title black-color mb-0">  {classlist.from_time_utc_new}  </h5>
                                                </div>
                                              </div>
                                              <div class="col-6 col-sm-6 col-md-6 col-lg-6 d-flex justify-content-end">
                                                <div class="text-left">
                                                  <p class="paratitle black-color mb-1"> Duration </p>
                                                  <h5 class="classname-title black-color mb-0">  {classlist.duration}  </h5>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              }
                              {waitingDetail.length == 0 &&
                                <div >
                                  <div class="text-center">
                                    <p class="font-awesome black-color font-18 d-inline mx-2 "> No Waitlist Available</p>
                                  </div>
                                </div>
                              }
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- end of row --> */}
            </div>
            {/* <!-- end of studio-details-lower --> */}
          </div >
          {/* < !--end of container-- > */}
        </div >
      </div >
      <Innerfooter />

    </>
  )
}
