import React from 'react'
import "./Favourite.css"
import { InnerHeader } from '../../Components/inner-header/InnerHeader1'
import { Innerfooter } from '../../Components/inner-footer/Innerfooter'
import { favourite, staff_favouritelist } from '../../../Static_Services/Staff_Services'
import { useState } from 'react'
import { useEffect } from 'react'
import { successAlert } from '../../../Static_Services/Alertmsg'
import { useNavigate } from 'react-router-dom'

export const Favourite = () => {
    const [favDetail, setFavDetail] = useState([]);
    const navigate = useNavigate();
    const[userInfo,setUserInfo] = useState();

    useEffect(()=>{
        if(JSON.parse(localStorage.getItem('w_loginDetail'))){
            setUserInfo(JSON.parse(localStorage.getItem('w_loginDetail')));
        }

    },[]) 

    const getData = async () => {
        const fData = {
            "pageid": 1
        }
        const resp = await staff_favouritelist(fData);
        if (resp) {
            setFavDetail(resp.data.data);
        } else setFavDetail([]);
    }

    useEffect(() => {
        getData();
    }, [])

    const  addFav = async (id,type) =>{
        var detail ={  "service_type":type,   "service_id":id,   "status":"0"};
      const resp = await favourite(detail);
      if(resp){
        successAlert(resp.data.message);
        getData();
      }
    }


    const redirectUrl = (businessId,url) =>{
        if(url == '/pass-detail'){
          var detail ={'id':businessId ,'url':'/myfavourites'};
          navigate(url,{state:{Id : detail}});
        } else{
          localStorage.setItem('w_detailBack', '/myfavourites');
          navigate(url,{state:{Id : businessId}});
        }
      }
    return (
        <>
            <InnerHeader />
            <div className="body-grey">
                <div className="favourite py-5 internal-studio">
                    <div className="studio-cards">
                        <div className="container">
                            <div className="col-12 pb-3">
                                <div className="section-heading">
                                    <h5 className="title black-color font-w-100 text-uppercase font-16"> favourites </h5>
                                </div>
                            </div>
                            {favDetail ?
                                <div>
                                    <div class="row">
                                    {favDetail && favDetail.map((Fav) => (
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4 px-2">
                                        
                                                <div class="studio-position-heart">
                                                    {Fav.service_type == '1' &&
                                                     <a href="Javascript:void(0);" >
                                                        <div class="studio-card-newcustom gradient-studio-overlay box-shadow1"  onClick={()=>redirectUrl(Fav.business_id,'/detail')}
                                                        >
                                                          <div className="img-box" style={{ backgroundImage: `url(${Fav.logo})` }}>
                                                            </div>

                                                            <div class="studio-detail p-3">
                                                                <div class="row mx-0 align-items-start">
                                                                    <div class="col-3 profile-image box-shadow1">
                                                                        <img src={Fav.logo} class="img-fluid" />
                                                                    </div>
                                                                    <div class="col-9 studio-profile-detail flex-fill pl-4">
                                                                        <h5 class="nametitle classname-title mb-1">{Fav.business_name}</h5>
                                                                        {Fav.business_address.length <= 25 ? <p class="brand-color3 paratitle mb-2">
                                                                            {Fav.business_address}</p> : ''}
                                                                        {Fav.business_address.length > 25 ? <p class="brand-color3 paratitle mb-2">
                                                                            {Fav.business_address} </p> : ""}

                                                                        <p class="brand-color3 paratitle mb-2">
                                                                            {Fav.distance}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="studio-card-select">
                                                            <i class="fas fa-heart brand-color2 font-22"
                                                            onClick={()=>addFav(Fav.business_id,Fav.service_type)}
                                                            ></i>
                                                        </div>
                                                    </a> }
                                               

                                                    {Fav.service_type == '2' ?
                                                        <a href="Javascript:void(0);" >
                                                            <div class="studio-card-newcustom gradient-studio-overlay box-shadow1"
                                                            onClick={()=>redirectUrl(Fav.pass_id,'/pass-detail')}
                                                            >
                                                                <div className="img-box" style={{ backgroundImage: "url(clientassets/img/logo_green_web.png)" }}>
                                                                </div>

                                                                <div class="studio-detail pl-3 pr-3 pb-0 pt-3">
                                                                    <div class="row mx-0 align-items-start">
                                                                        <div class="col-3 profile-image box-shadow1">
                                                                            <img src="clientassets/img/logo_green_web.png" class="img-fluid" />
                                                                        </div>
                                                                        <div class="col-9 studio-profile-detail flex-fill pl-4">
                                                                            <h5 class="nametitle classname-title mb-1">{Fav.pass_name}</h5>
                                                                            <p class="brand-color3 paratitle mb-1">
                                                                                {Fav.pass_type}</p>
                                                                            <p class="brand-color3 paratitle">
                                                                                Date : {Fav.start_date}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="studio-card-select">
                                                                <i class="fas fa-heart brand-color2 font-22"
                                                                onClick={()=>addFav(Fav.pass_id,Fav.service_type)}
                                                                ></i>
                                                            </div>
                                                        </a>
                                                        : ''}
                                                </div>                                           
                                            </div>
                                    ))}
                                    </div>

                                </div>
                                :
                                <div className="row  border-radius6 p-3">
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4">
                                        <p className="font-awesome black-color font-18 d-inline mx-2 "> Not available</p>
                                    </div>
                                    <div className="col-md-4"></div>
                                </div>
                            }
                        </div>

                    </div>
                </div>
            </div>
            <Innerfooter />
        </>
    )
}
