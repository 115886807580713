import React, { useEffect } from 'react'
import { InnerHeader } from '../../Components/inner-header/InnerHeader1'
import { SubHeader } from '../../Components/sub-header/SubHeader'
import { Innerfooter } from '../../Components/inner-footer/Innerfooter1'
import { DashboardToSectionDetails } from '../../Components/CommanPages/DashboardToSectionDetails'
import { useState } from 'react'
import { dateConvert, staff_shift_cancel_request, staff_shift_list, timeConvert } from '../../../Static_Services/Staff_Services'
import { Modal, Spinner } from 'react-bootstrap'
import moment from 'moment'
import { InputField } from '../../../sharedComponents/InputField'
import { errorAlert, successAlert } from '../../../Static_Services/Alertmsg'

export const StaffShift = () => {
  const [currentStatus, setCurrentStatus] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [shiftDetail, setShiftDetail] = useState([]);
  const [loder, setLoder] = useState(false);
  const [dataCount, setDataCount] = useState();
  const [businessDetail, setBusinessDetail] = useState();
  const [schdule_id, setSchdule_id] = useState();
  const [requestDetail, setRequestDetail] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const [timestamp, setTimstamp] = useState();
  const [modalshowone, setModalshowone] = useState(false);
  const [shiftID, setShiftID] = useState();
  const [changerequest, setChangeRequest] = useState('');
  const [errorREQ, setErrorREQ] = useState();

  const handleChange = (event) => {
    // Remove leading white spaces
    const newValue = event.target.value.replace(/^\s+/g, '');
    setChangeRequest(newValue);
  };

  useEffect(() => {
    const bzDetails = JSON.parse(localStorage.getItem('bzDetails'));
    if (bzDetails) {
      setBusinessDetail(bzDetails);
    }
  }, []);


  const getShiftDetail = async (count = 1, status = 1, timestamp = '') => {

    setLoder(true);
    let detail = { "pageid": count, "business_id": businessDetail.business_id, 'shift_status': status, "upcoming_date": timestamp };

    const resp = await staff_shift_list(detail);

    if (resp) {
      const response = resp.data;
      if (response.data.length != 0) {
        response.data.map((el) => {
          el.shift_date_new = moment.unix(el.shift_date).format('MMM D ,YYYY');
          el.start_time_new = timeConvert(el.start_time);
          el.end_time_new = timeConvert(el.end_time);
          if (schdule_id) {
            if (el.id == schdule_id) {
              setRequestDetail(el.comment);
            }
          }
          if (el.comment.length != 0) {

            el.comment.map((ell) => {
              // Date Conversion UTC
              ell.date_new = moment.unix(ell.create_dt).format('MMM D, YYYY');
              ell.time = timeConvert(ell.create_dt);
            })
          }
        })
      }
      if (pageCount == 1) {
        setLoder(false);
        setShiftDetail(response.data);
      } else {
        setShiftDetail((shiftDetail) => shiftDetail.concat(response.data));
      }
      setLoder(false);

    } else {
      setDataCount(0)
      setShiftDetail([])
      setLoder(false);
    }
  }

  useEffect(() => {
    if (businessDetail) {

      getShiftDetail(pageCount, currentStatus, timestamp);
    }
  }, [timestamp])

  useEffect(() => {
    if (businessDetail) {
      getShiftDetail(pageCount, currentStatus, timestamp);
    }
  }, [businessDetail])

  const getval = (event) => {
    setCurrentStatus(event.target.value);
    getShiftDetail(1, event.target.value, timestamp);
  }

  const dateUpdate = (e) => {
    const formattedDate = moment(e.target.value, "YYYY-MM-DD hh:mm:ss A").unix()
    setTimstamp(formattedDate);
    setSelectedDate(e.target.value);
  }
  const reset = () => {
    setCurrentStatus(1);
    getShiftDetail(1, 1, '');
  }

  const changeSelection = (item) => {
    setRequestDetail([]);
    setShiftID(item.shift_id);
    setSchdule_id(item.id)
    setRequestDetail(item.comment);
  }

  const shiftChangeRequest = async () => {
    setLoder(true);
    let isValid=1;
    const bzID = businessDetail.business_id;
    const sftID = shiftID;
    const changerReq = changerequest;
    const schduleid = schdule_id;

    if(!schduleid){
      errorAlert('schdule_id Required');
      isValid=4;
    }

    if(!bzID){
      errorAlert('Business Id  Required');
      isValid=4;
    }
    if(!sftID){
      errorAlert('Shift Id Required');
      isValid=4;
    }

    if (!changerReq) {
      setErrorREQ('Change Request is required');
      isValid=2;
    } else if (changerReq && changerReq.length > 200) {
      setErrorREQ("Reason's max length is 200");
      isValid=3;
    }
    if (isValid==1) {
      
    const detail = { 'business_id': bzID, 'shift_id': sftID, 'reason': changerReq, 'shift_schedule_id': schduleid };
    const resp = await staff_shift_cancel_request(detail);
        if (resp) {
         successAlert(resp.data.message);
          setLoder(false)
          setChangeRequest();
          getShiftDetail(pageCount,currentStatus);
        
        } else {
          setLoder(false);
        }
    } else {
     setLoder(false);
      return false;
    }
  }

  return (
    <>
      {loder ? <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div> : ""}
      <InnerHeader />

      <div className="body-grey">
        <div className="studio-details">
          <div className="container">
            <DashboardToSectionDetails />

            <div className="text-right mb-3">
              <button type="button" className="btn btn-blue-grad px-2 text-uppercase" onClick={()=>window.history.back()}>Back </button>
            </div>
            <div class="studio-details-lower pb-5">
              <div class="row">
                <div class="col-12">
                  <div class="white-bg border-radius6 box-shadow1 p-3">
                    <div class="row" >
                      <div class="col-12 col-md-5">
                        <div class="centered-cover w-100">
                          <div class="flex-break">
                            <div class="std-detail-select position-relative w-100">
                              <select class="form-control brand-color1 border-radius6" onChange={(e) => getval(e)}>
                                <option value="1" selected={currentStatus === 1}> My Future Shift </option>
                                <option value="3" selected={currentStatus === 2}> My Past Shift </option>
                                <option value="2" selected={currentStatus === 3}> My Cancelled Shift </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-5">
                        <div class="text-right w-100">
                          <div class="flex-break">
                            <div class="std-detail-select position-relative w-100" >
                              <InputField
                                type="date"
                                onChange={(e) => dateUpdate(e)}
                                value={selectedDate}
                                required
                              />
                            </div>
                          </div>

                        </div>
                      </div>
                      <div class="col-12 col-md-2" >
                        <div class="pt-3">
                          <button type="button" class="btn btn-blue-grad px-4 text-uppercase mb-0 mb-md-5"
                            onClick={() => reset()}
                          >Reset </button>
                        </div>
                      </div>
                    </div>

                    <div class="box all-class">
                      {shiftDetail.length != 0 &&
                        <div class="activity-card-section mt-4 pt-2">
                          <div 
                          // infinite-scroll
                            //  [infiniteScrollDistance]="scrollDistance"  [infiniteScrollUpDistance]="scrollUpDistance"  [infiniteScrollThrottle]="throttle"  (scrolled)="onScroll()"
                            alwaysCallback="true">
                            {shiftDetail.length != 0 &&
                              <div class="row">
                                {shiftDetail.map((items) => (
                                  <div class="col-12 col-md-4 mb-4">
                                    <a href="Javascript:void(0);" data-toggle="modal" data-target="#shiftChange" data-backdrop="static" data-keyboard="false"
                                      onClick={() => changeSelection(items)}
                                    >
                                      <div class="activity-card box-shadow1 border-radius6 white-bg p-2">
                                        <div class="row">
                                          <div class="col-3 col-sm-3 col-md-12 col-lg-3">
                                            <div class="activity-card-logo">
                                              <img src={items.business_image} class="box-shadow1 new-log-img" />
                                            </div>
                                          </div>

                                          <div class="col-9 col-sm-9 col-md-12 col-lg-9 pl-0 pl-sm-0 pl-md-3 pl-lg-0">
                                            <div class="row">
                                              <div class="col-7 col-md-12 col-lg-7 mt-0 mt-md-4 mt-lg-0 pl-0 pl-sm-0 pl-md-3 pl-lg-0">
                                                <div class="activity-card-details text-left">
                                                  <h5 class="classname-title black-color mb-0">{items.business_name} </h5>
                                                  <ul class="d-flex align-items-center py-2">
                                                    <li> <h5 class="black-color mb-0 classname-title">{items.shift_name}</h5> </li>
                                                  </ul>

                                                </div>
                                              </div>
                                              <div class="col-5 col-md-12 col-lg-5 pr-1">
                                                <div class="text-right pt-1">
                                                  <p class="black-color font-w-100  mr-2 mb-1 paratitle">{items.week_name} </p>
                                                </div>
                                                <div class="text-right pt-1">
                                                  <p class="black-color font-w-100  mr-2 mb-1 paratitle">{items.shift_date_new} </p>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="row">
                                              <p class="black-color font-w-100 paratitle mb-0">Location :
                                              </p>
                                              <h5 class="heading-titlenew ml-2 mb-0 black-color" >{items.location_name}</h5>

                                            </div>
                                            <div class="row pt-2">
                                              <div class="col-5 col-sm-5 col-md-5 col-lg-5 px-0 ">
                                                <div class="text-left">
                                                  <p class="paratitle black-color mb-0"> Start Time </p>
                                                  <h5 class="classname-title black-color mb-0"> {items.start_time_new} </h5>
                                                </div>
                                              </div>
                                              <div class="col-5 col-sm-5 col-md-5 col-lg-5 px-3 border-left">
                                                <div class="text-left">
                                                  <p class="paratitle black-color mb-0"> End Time </p>
                                                  <h5 class="classname-title black-color mb-0">{items.end_time_new} </h5>
                                                </div>
                                              </div>
                                              {currentStatus == 1 &&
                                                <div class="col-2 col-sm-2 col-md-2 col-lg-2 d-flex justify-content-end border-left" >
                                                  <div class="text-left" onClick={() => setModalshowone(true)} >
                                                    <i class="fas fa-pencil-alt"></i>
                                                  </div>
                                                </div>
                                              }
                                            </div>

                                          </div>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                ))}

                              </div>
                            }

                          </div>
                        </div>
                      }
                      {shiftDetail.length == 0 &&
                        <div class="client-buy-process border-radius6" >
                          <h3 class="text-center">No Shift Schedule</h3>
                        </div>
                      }

                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <Innerfooter />

      {/* <!-- Modal Popup (Change - Password ) --> */}

      <Modal show={modalshowone} id="StaffEdit">
            <button type="button" class="close text-right mr-2" data-dismiss="modal" aria-label="Close" onClick={() => setModalshowone(false)}>
              <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body white-bg box-shadow1 border-radius6 p-4 p-md-5">

              <div class="col-12 col-lg-12 mt-5 mb-3 mt-lg-0 px-0">
                <p class="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1 mb-2">Previous Requset Details</p>
                {requestDetail.length != 0 &&
                  <div class="client-buy-process border-radius6 box-shadow1 white-bg">
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <th>Comment </th>
                          <th width="20%">Comment By</th>
                          <th width="30%" >Date</th>
                        </thead>
                        {requestDetail.map((items) => (
                          <tr>
                            <td > {items.comment} </td>
                            {items.type == 1 && <td class="text-capitalize" width="20%"> {items.name + ' '}  {items.lastname} </td>}
                            {items.type == 2 && <td class="text-capitalize" width="20%"> items.name   items.lastname  (Admin)</td>}
                            <td width="30%"> {items.date_new} , {items.time}</td>
                          </tr>
                        ))}
                      </table>
                    </div>
                  </div>
                }
                {requestDetail.length == 0 &&
                  <div class="client-buy-process border-radius6">
                    <h3 class="text-center">No Privious Request</h3>
                  </div>
                }
              </div>
              {currentStatus == 1 &&
                <form>
                  <div class="row mt-2">
                    <div class="col-12" >
                      <div class="form-group">
                        <p class="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1"> Change Request </p>
                        <textarea
                          className={`form-control`}
                          placeholder="Reason for shift Change Request"
                          onChange={handleChange}
                          value={changerequest}
                          maxLength={200}
                        ></textarea>
                        <small>{errorREQ}</small>
                      </div>
                    </div>
                    <div class="col-4"></div>
                    <div class="col-4 d-flex">
                      <button type="button" class="btn btn-blue-grad text-uppercase mt-3"  onClick={()=>shiftChangeRequest()}> 
                       {/* <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>  */}
                        Submit </button>
                      <button type="button" data-dismiss="modal" id="btn_close" class="btn btn-blue-grad text-uppercase mt-3 ml-5" data-backdrop="static" data-keyboard="false" onClick={() => setModalshowone(false)}> close </button>
                    </div>
                    {/* <div class="col-4"></div> */}
                  </div>
                </form>
              }
            </div>
            {/* <!-- end of modal-body --> */}

      </Modal>
      {/* <!-- End of Modal Popup (Change - Password ) --> */}

      {/* <!-- Modal Popup (Change - Password ) --> */}
      <div className="modal fade modalwidth" id="shiftDetail" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button type="button" className="close text-right mr-2" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="modal-body white-bg box-shadow1 border-radius6 p-4 p-md-5">

              <div className="col-12 col-lg-12 mt-5 mb-3 mt-lg-0 px-0">
                <p className="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1 mb-2">Previous Requset Details</p>

                <div className="client-buy-process border-radius6 box-shadow1 white-bg">

                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <th>Comment </th>
                        <th width="20%">Comment By</th>
                        <th width="30%">Date</th>
                      </thead>
                      <tr>
                        <td> items.comment</td>
                        <td className="text-capitalize" width="20%" >items.name items.lastname</td>
                        <td className="text-capitalize" width="20%" >items.name items.lastname
                          (Admin)</td>

                        <td width="30%">items.date_new , items.time</td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div className="client-buy-process border-radius6" >
                  <h3 className="text-center">No Privious Request</h3>
                </div>
              </div>

            </div>
            {/* <!-- end of modal-body --> */}

          </div>
        </div>
      </div>
      {/* <!-- End of Modal Popup (Change - Password ) --> */}
    </>
  )
}
