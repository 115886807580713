import { Link } from 'react-router-dom';

export const StaticsHeader = () => {
  return (
    <div className="header box-shadow1" id="myHeader">
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-light white-bg px-0">
          <Link className="nav-link px-0" to="/Home">
            <img src="clientassets/img/logo_colored.png" className="img-fluid img-size" alt="Logo" />
          </Link>
          <button
            className="navbar-toggler ml-auto my-3 my-lg-0"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link className="nav-link link header-css" to="/Home">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link link header-css" to="/solution">
                  The Solution
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link link header-css" to="/apps">
                  The Apps
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link link header-css" to="/pricing">
                  Pricing
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link link header-css" to="/contact-us">
                  Contact us
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link btn btn-blue-grad px-4 text-uppercase header-css" to="/demo">
                  Demo
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link btn btn-green-grad px-4 text-uppercase header-css" to="/login">
                  Login
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};


