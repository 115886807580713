import React from 'react'
import { Button, Modal, Row, Col } from "react-bootstrap";
import { InputField } from '../../../../../sharedComponents/InputField';

export default function CapacityModal({ show, handleClose, capacityHandler, value,nameUpdateHandler }) {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Update Capacity</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={6}>
                        <InputField
                            type="number"
                            onChange={(e) => { capacityHandler(e.target.value); }}
                            value={value}
                            required
                        />
                    </Col>
                    <Col md={6}>
                        <Button variant="primary" className='w-100' onClick={nameUpdateHandler}>
                            Update
                        </Button>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={handleClose}>
                    Close
                </Button>

            </Modal.Footer>
        </Modal>
    )
}
