import React from "react";
import { Row, Col, Card } from "react-bootstrap";

import { Link, useNavigate } from "react-router-dom";
import { BsCartPlusFill } from "react-icons/bs";
import { useEffect,useState } from "react";
import { SharedButton } from "../../../../../sharedComponents/Button";

import { Button } from "bootstrap";

export const ProductCrt = ({data}) => {
   const navigate = useNavigate();
    const [productCardDetail, setProductCardDetail] = useState();
  const cart=()=>{
    const mp = [];
    data.map((e)=>(
      mp.push({
        id:e.productId,
        data:e,
      memberShipImg: e.product_images[0] ? e.product_images[0]['image_name'] : "https://warriorsms.com/superadmin/uploads/products/1652214051WarriorMan_icon2_CMYK.png",
      cardTitle: e.product_name,
      amount: e.product_price,
      })
    ))
    setProductCardDetail(mp);
  }
useEffect(()=>{
if(data){
  cart();
}
},[data])

  return (
   <>
      <Row>
        {productCardDetail
          ? productCardDetail.map((value, index) => (
              <Col md={3}>
                <button
                onClick={() => { navigate('/product-update', { state: { data: value.data } });}}
                  className="productCardsAnchor">
                  <span className="AddCartHover">
                    <BsCartPlusFill />
                  </span>
                  <Card className="ProductCardList" key={index}>
                    <div className="cardImg">
                      <Card.Img variant="top" src={value.memberShipImg} />
                    </div>
                    <Card.Body>
                      <Card.Text>
                        <Card.Title>{value.cardTitle}</Card.Title>
                        <p className="m-0">
                          {" "}
                          <small>{value.text}</small>
                        </p>
                      </Card.Text>
                      <SharedButton title={value.amount} />
                    </Card.Body>
                  </Card>
                </button>
              </Col>
            ))
          : ""}
      </Row>
   </>
  )
}
