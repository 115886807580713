import React, { useState } from "react";
import Sidebar from "../../comman_page/Sidebar";
import { HeaderDashboard } from "../../comman_page/HeaderDashboard";
import { Container, Row, Col } from "react-bootstrap";
import { DataTable } from "../../../../../sharedComponents/DataTable";
import { Footer } from "../../../../../public/containers/footer/Footer";
import { transectionDetail } from "../../../../../Studio-Services/Studio_Services";
import { useEffect } from "react";
import { Link } from "react-router-dom";

export const Transaction = () => {
  const [data, setData] = useState();

  const id = localStorage.getItem("business_id");

  const getTransectionDeatil = async () => {
    const data = {
      business_id: localStorage.getItem("business_id"),
    };
    const resp = await transectionDetail(data);
    if (resp) {
      setData(resp.data);
    }
  };

  useEffect(() => {
    getTransectionDeatil();
  }, []);

  const array = [];

  if (data) {
    data.forEach((element) => {
      array.push({
        customer_name: (
          <>
            <img className="img-fluid table_user" src={element.profile_img} alt="" />
            <span className="ps-4">{element.name}</span>
            <span className="ps-2">{element.lastname}</span>
          </>
        ),
        service_name: element.service_name,
        reference_payment_id: element.reference_payment_id,
        amount: element.total_amount,
        status: element.status,
        action: (
          <>
            <Link
              to="/customer-payment-request"
              state={{
                business_id: id,
                service_id: element.service_id,
                service_type: element.service_type_id,
                customer_id: element.user_id,
                request_id: element.id,
              }}
              className="brand-blue font-w-500"
            >
              View
            </Link>
          </>
        ),
        // Add more data objects as needed
      });
    });
  }

  const columns = [
    { dataField: "customer_name", text: "Customer Name" },
    { dataField: "service_name", text: "Service Name" },
    { dataField: "reference_payment_id", text: "Reference Payment ID" },
    { dataField: "amount", text: "Amount" },
    { dataField: "status", text: "Status" },
    { dataField: "action", text: "Action" },
  ];
  return (
    <>
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col>
              <div className="RightSide">
                <HeaderDashboard />
                <section className="addClient innerContent">
                  <div className="AddClientContent">
                    <Container>
                      <h4>CUSTOMER PAYMENT REQUEST LIST</h4>

                      <Row className="mt-3">
                        <Col>
                          <DataTable data={array} columns={columns} />
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </section>
                <Footer />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
