import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../../comman_page/Sidebar";
import { HeaderDashboard } from "../../comman_page/HeaderDashboard";
import { Footer } from "../../../../../public/containers/footer/Footer";
import { Row, Col, Container, Form } from "react-bootstrap";
import { InputField } from "../../../../../sharedComponents/InputField";
import { SharedSelect } from "../../../../../sharedComponents/SharedSelect";
import { MyDatePicker } from "../../../../../sharedComponents/MyDatePicker";
import { TextArea } from "../../../../../sharedComponents/TextArea";
import { SharedButton } from "../../../../../sharedComponents/Button";
import { get_pass_details, get_pass_type, update_passes } from "../../../../../Studio-Services/Studio_Services";
import moment from "moment";
import { SharedMultiSelect } from "../../../../../sharedComponents/SharedMultiSelect";
import Swal from "sweetalert2";
import logo from "../../../../../Images/logo_white_web.png"
import { InputTypeTax } from "../../../../../sharedComponents/InputTypeTax";

export const PassUpdate = () => {
  const [preData, setPreData] = useState([]);
  const [passType, setPassType] = useState([]);
  const location = useLocation();
  const [showtaxtone, setShowtactone] = useState(false);
  const [showtactTwo, setShowtactTwo] = useState(false);
  const [classes, setClasses] = useState();
  const [classOption, setClassOption] = useState([]);
  const [selectedclassdata, setSelectedclassdata] = useState();
  const [pass_type_id, setPass_type_id] = useState();
  const [finalPass_sub_type_id, setFinalPass_sub_type_id] = useState();
  const [passtypOP,setpasstypOP] = useState([]);

  const [tax1op,setTax1op] = useState();
  const [tax2op,setTax2op] = useState();

  const navigate = useNavigate();


  const get_pass = async () => {
    const fData = {
      "pass_subcat": ""
    }
    const resp = await get_pass_type(fData);
    if (resp) {
      const pst=[];
      (resp.data.data).map((e)=>{
        pst.push({
          value: e.pass_type_id, label:e.pass_type
        })
      })
      setpasstypOP(pst);    
    }
  }

  const get_pass_subType = async () => {
    const fData = {
      "pass_subcat": pass_type_id
    }
    const resp = await get_pass_type(fData);
    if (resp) {
      if (resp.data.data) {
        (resp.data.data).forEach(element => {
          if (element.pass_type === preData.pass_sub_type) {
            setFinalPass_sub_type_id(element.pass_type_id);
          }

        })
      }
    }
  }


  const getPassDetails = async () => {
    const finalData = {
      pass_id: preData.pass_id
    }
    const resp = await get_pass_details(finalData);
    if (resp) {
      setClasses(resp.data.data);
    }
  }

  useEffect(()=>{
    get_pass_subType();
  },[pass_type_id])
  useEffect(() => {
    getPassDetails();
    if(preData){
      setTax1op(preData.tax1);
      setTax2op(preData.tax2);
      setPass_type_id(preData && preData.pass_id ? preData.pass_id : "");
    }
  }, [preData])

  useEffect(() => {
    if (location && location.state && location.state) {
      setPreData(location.state.data);
      get_pass();
    }
  }, [location]);

  const Taxopt = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" }
  ]

  const VisibleOpt = [
    { value: "no", label: "No" },
    { value: "yes", label: "Yes" }
  ]
  const AgeOpt = [
    { value: "Over", label: "Over" },
    { value: "Under", label: "Under" },
    { value: "None", label: "None" }
  ]

  const tax1Handler = (e) => {
    const value = e.target.value;
    setTax1op(value);
  }
  const tax2Handler = (e) => {
    const value = e.target.value;
    setTax2op(value);
  }

  const optClasses = () => {
    const abc = [];
    if (classes && classes.class) {
      (classes.class).map((e) => {
        abc.push({
          value: e.id, label: e.class_name
        })
      })
    }
    setClassOption(abc);
  }


  useEffect(() => {
    if (classes && classes.class) {
      optClasses();
    }
  }, [classes])

  const [error,setError] = useState({
    pass_name:"",
    pass_type_name :"",
    pass_sub_type :"",
    purchase_date : "",
    amount :"",
    tax1:"",
    tax1_rate:"",
    tax2:"",
    tax2_rate:"",
    is_client_visible:"",
    description:"",
    notes:"",
    age_over_under:"",
    class_details:"",
  });



  const submtHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const formValue = Object.fromEntries(formData.entries());

    let isValid=1;

    const pass_id = formValue.pass_id ;
    const pass_name = formValue.pass_name ;
    const class_type = formValue.classtype ;
    const pass_sub_type = finalPass_sub_type_id ;
    const pass_type = pass_type_id ;
    const purchase_date = preData.purchase_date_utc ;
    const passexpiry = formValue.passexpiry ;
    const amount = formValue.amount ;
    const tax1 = formValue.tax1 ;
    const tax1_rate = formValue.tax1_rate ? formValue.tax1_rate  : preData.tax1_rate ;
    const tax2 = formValue.tax2 ;
    const tax2_rate = formValue.tax2_rate ? formValue.tax2_rate  : preData.tax2_rate ;
    const service_type = '1' ;
    const service_id = '' ;
    const is_client_visible = formValue.is_client_visible ;
    const is_one_time_purchase = formValue.is_one_time_purchase ;
    const description = formValue.description ;
    const notes = formValue.notes || 'none' ;
    const is_recurring_billing = formValue.is_recurring_billing ;
    const billing_start_from = formValue.billingstartfrom ;
    const age_restriction = formValue.age_restriction ;
    const age_over_under = formValue.age_over_under ;
    const class_details = formValue.class_details ? formValue.class_details : [];

    if(!pass_name){setError((pre)=>({...pre,pass_name:"* Required"})); isValid=2}
    if(!purchase_date){setError((pre)=>({...pre,purchase_date:"* Required"})); isValid=3}
    if(!amount){setError((pre)=>({...pre,amount:"* Required"})); isValid=4}
    if(!tax1){setError((pre)=>({...pre,tax1:"* Required"})); isValid=5}
    if(tax1==="yes"){
      if(!tax1_rate){setError((pre)=>({...pre,tax1_rate:"* Required"})); isValid=6}
    }
    if(!tax2){setError((pre)=>({...pre,tax2:"* Required"})); isValid=7}
    if(tax2==="yes"){
      if(!tax2_rate){setError((pre)=>({...pre,tax2_rate:"* Required"})); isValid=8}
    }
    if(!is_client_visible){setError((pre)=>({...pre,is_client_visible:"* Required"})); isValid=9}
    if(!description){setError((pre)=>({...pre,description:"* Required"})); isValid=10}
    if(!notes){setError((pre)=>({...pre,notes:"* Required"})); isValid=11}
    if(!age_over_under){setError((pre)=>({...pre,age_over_under:"* Required"})); isValid=12}
  

if(isValid===1){
    var detail = {
      pass_id: formValue.pass_id,
      pass_name: formValue.pass_name,
      class_type: formValue.classtype,
      pass_sub_type: finalPass_sub_type_id,
      pass_type: pass_type_id,
      purchase_date: preData.purchase_date_utc,
      passexpiry: formValue.passexpiry,
      amount: formValue.amount,
      tax1: formValue.tax1,
      tax1_rate: formValue.tax1_rate ? formValue.tax1_rate : preData.tax1_rate,
      tax2: formValue.tax2,
      tax2_rate: formValue.tax2_rate ? formValue.tax2_rate : preData.tax2_rate,
      service_type: '1',
      service_id: '',
      is_client_visible: formValue.is_client_visible,
      is_one_time_purchase: formValue.is_one_time_purchase,
      description: formValue.description,
      notes: formValue.notes,
      is_recurring_billing: formValue.is_recurring_billing,
      billing_start_from: formValue.billingstartfrom,
      age_restriction: formValue.age_restriction,
      age_over_under: formValue.age_over_under,
      class_details: formValue.class_details ? formValue.class_details : []
    }
      const resp = await update_passes(detail);
      if (resp) {
        Swal.fire({
          html: `
            <img src=${logo} class="img-fluid d-block mx-auto mt-5" />
            <h4 class="title text-uppercase text-center mt-4 textWhite">
              SUCCESS
            </h4>
            <p class="text-center my-4 textcolor">
              <small class="textWhite">
                ${resp.data.message}
              </small>
            </p>
          `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/list-pass");
          }
        });
      }
    }

  }

  return (
    <>
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col className="p-0 RightCol">
              <div className="RightSide">
                <HeaderDashboard />
                <div className="innerContent">
                  <div className="AddPassContent">
                    <Container>
                      <Form onSubmit={submtHandler}>
                        <h3 className="mb-5 mt-3">EDIT PASS</h3>

                        <InputField type={"hidden"} name="pass_id" value={classes && classes.pass_id ? classes.pass_id : ""} />
                        <InputField type={"hidden"} name="is_recurring_billing" value={preData.is_recurring_billing} />
                        <InputField type={"hidden"} name="passexpiry" value={preData.pass_validity} />

                        <InputField type={"hidden"} name="is_one_time_purchase" value={(preData.is_one_time_purchase) === "no" ? "0" : "1"} />
                        <Row>
                          <Col>
                            <InputField 
                            name="pass_name" type={"text"} 
                            value={preData.pass_name} 
                            lable={"Pass Name"} 
                            placeholder={"Pass Test"}
                            error={error.pass_name} 
                             />
                          </Col>
                          <Col>
                          <SharedSelect 
                            name="pass_type" 
                            options={passtypOP} 
                            value={preData && preData.pass_id ? preData.pass_id : ""} 
                            readOnly 
                            lable={"Type Of Pass"}
                             />
                          </Col>
                          <Col>
                            <InputField 
                            type={"text"} 
                            name="pass_sub_type" 
                            value={preData.pass_sub_type} 
                            readOnly 
                            lable={"Monthly Recurring"} 
                            placeholder={"Type Of Pass"} />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <MyDatePicker 
                            name="purchase_date" 
                            value={preData && preData.purchase_date_utc ? moment.unix(preData.purchase_date_utc).format('YYYY-MM-DD') : ""} DatepickerLabel={"Date available for purchase"} readOnly />
                          </Col>
                          <Col>
                            <InputTypeTax 
                            name="amount" 
                            readOnly
                            value={preData && preData.amount ? preData.amount : ""} 
                            lable={"Amount (In $)"} 
                            type={"number"} 
                            error={error.amount}
                            />
                          </Col>
                          <Col>
                            <SharedSelect 
                            name="tax1" 
                            onChange={tax1Handler} 
                            options={Taxopt} 
                            value={preData.tax1} 
                            lable={"Tax 1 Applicable"}
                            error={error.tax1}
                             />
                          </Col>
                        </Row>
                        <Row>
                          {tax1op === "Yes" ?
                            <Col>
                              <InputTypeTax 
                              name="tax1_rate" 
                              value={preData && preData.tax1_rate ? preData.tax1_rate : ""} 
                              lable={"Tax 1 Rate (In %)"} 
                              type={"number"} 
                              error={error.tax1_rate}
                              />
                            </Col>
                            : ""}
                          <Col>
                            <SharedSelect 
                            name="tax2" 
                            onChange={tax2Handler} 
                            options={Taxopt} 
                            value={preData.tax2} 
                            lable={"Tax 2 Applicable"}
                            error={error.tax2}
                             />
                          </Col>

                          {tax2op === "Yes" ?
                            <Col>
                              <InputTypeTax
                               type={"number"}
                              name="tax2_rate" 
                              value={preData && preData.tax2_rate ? preData.tax2_rate : ""} 
                              lable={"Tax 2 Applicable"} 
                             error={error.tax2_rate}
                               />
                            </Col>
                            : ""}
                          <Col>
                            <SharedSelect 
                            name="is_client_visible" 
                            options={VisibleOpt} 
                            value={preData && preData.is_client_visible ? preData.is_client_visible : ""} 
                            lable={"Visible to client"} 
                            error={error.is_client_visible}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <TextArea 
                            name="description" 
                            value={preData && preData.description ? preData.description : ""} 
                            lable={"Description"} 
                            error={error.description}
                            />
                          </Col>
                          <Col>
                            <TextArea
                            name={"notes"}
                            value={preData && preData.notes ? preData.notes : ""}
                            lable={"Notes"} 
                            error={error.notes}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <SharedSelect 
                            name="age_restriction" 
                            options={AgeOpt} 
                            value={preData && preData.age_restriction ? preData.age_restriction : ""} 
                            readOnly 
                            lable={"Age Restriction"}
                             />
                          </Col>
                          <Col><InputField name="age_over_under" value={preData && preData.age_over_under ? preData.age_over_under : ""} lable={"What age over/under"} readOnly type={"number"} /></Col>
                          <Col></Col>
                        </Row>
                        <Row className="my-4">
                          <h3>Existing Class </h3>
                          <hr />

                          <div className="existingClassesText">
                            <ul>
                              <Row>
                                {classes && classes.class_id ? (classes.class_id).map((e,index) => (
                                  <Col lg={3} md={3} key={index}>
                                    <li>{e.class_name}</li>
                                  </Col>
                                )) : ""}
                              </Row>
                            </ul>
                          </div>

                        </Row>
                        <Row>
                          <Col>
                            <SharedMultiSelect
                              labelText="Add More Class"
                              setSkillsdata={setSelectedclassdata}
                              name="class_details"
                              options={classOption ? classOption : ""}
                            />

                          </Col>
                          <Col></Col>
                          <Col></Col>
                        </Row>
                        <Row className="my-4">
                          <Col>

                            <SharedButton title={"Save"} type={"submit"} />
                          </Col>
                          <Col></Col>
                          <Col></Col>
                        </Row>
                      </Form>
                    </Container>
                  </div>
                </div>
                <Footer />
              </div>``
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
