import React, { useContext, useEffect, useState } from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import { SharedDropDown } from "../../../../sharedComponents/SharedDropDown";
import { Link, useNavigate } from 'react-router-dom';

import { MyContext } from "../../../../App";
import { HeaderLiveSearch } from "../../../../sharedComponents/HeaderLiveSearch";
import "./CSSHeaderDashboard.css";

export const HeaderDashboard = () => {
  const navigate = useNavigate();
  


  const [pdata, setPdata] = useState([]);

  const [dataHide, setDataHide] = useState();
  const context = useContext(MyContext);
  const {todos} = useContext(MyContext);
  const [hide,setHide] = useState();
  // let hide = '';
  // let udata = '';

  useEffect(()=>{
    if (context && context.context) {
      if (context.context.local) {
        // hide = 
        setHide(context.context.local.sideHeadre);
      }
      // udata = context.context.uData;
    }
  },[context])



  useEffect(() => {
    if (JSON.parse(localStorage.getItem('st_userData'))) {
      setPdata(JSON.parse(localStorage.getItem('st_userData')));
      
    }else if(todos){ setPdata(todos);}

  }, [todos])


  useEffect(() => {
    // if (udata !== "") {
    //   setPdata(udata);
    // }
    setDataHide(hide);
  }, [hide])


  const logOut = () => {
    localStorage.setItem('id', '');
    localStorage.removeItem('id');
    localStorage.removeItem('st_userData');
    localStorage.removeItem('Authorization');
    localStorage.removeItem('business_id');
    localStorage.removeItem('marchant_id');
    localStorage.removeItem('cl_token_expire');
    localStorage.removeItem('business_token');
    localStorage.removeItem('list_token');
    localStorage.removeItem('month_token');
    localStorage.clear();
    navigate('/studiologin');
  }



  const dropDownOptions = [
    {
      label: "My Profile",
      to: "/userProfile",
      linkClass: 'option-list ',
      className: "dropDownOption dropDownLink UserProfileItem",
    },
    {
      label: "Business Profile",
      to: "/BusinessProfile",
      linkClass: 'option-list',
      className: "dropDownOption dropDownLink UserProfileItem",
    },
    {
      label: "Change Password",
      to: "/ChangePassword",
      linkClass: 'option-list',
      className: "dropDownOption dropDownLink UserProfileItem",
    },
    {
      label: "Logout",
      className: "dropDownOption dropDownLink",
      onClick: () => logOut("Logout"),
    },
  ];


  return (
    <>
      <div className="StickyNavbar">
        <Navbar bg="light" expand="lg">
          <Container>
            <Navbar.Brand className={dataHide ? "padHead" : ""}>
              <Link to="/Home">
                <img
                  src={pdata.business_img}
                  alt=""
                  className="DashboardLogo"
                  height="100%"
                />
              </Link>
              {pdata.business_name}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                <div className="mr-3" >
                  <HeaderLiveSearch />
                </div>
                <SharedDropDown
                  className={"myProfileDropDown"}
                  userName={`${pdata.name} ${pdata.lastname}`}
                  userDetail={pdata.role_id === "1" ? "Super Admin" : pdata.role_id === "2" ? "Studio Owner" : pdata.role_id === "3" ? "User" : pdata.role_id === "4" ? "Instructor" : ""}
                  dropdownDivider={true}
                  dropDownOptions={dropDownOptions}
                  img={pdata.profile_img}
                />

              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  );
};
