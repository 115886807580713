import React, { useEffect, useState } from "react";
import Sidebar from "../comman_page/Sidebar";
import { HeaderDashboard } from "../comman_page/HeaderDashboard";
import { Footer } from "../../../../public/containers/footer/Footer";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Button, Row, Col } from "react-bootstrap";
import Swal from "sweetalert2";
import logo from "../../../../Images/logo_white_web.png"
import { webinfovalidation } from "../../../../Studio-Services/Studio_Services";


export const AdditionalServices = () => {
  const navigate = useNavigate();


  const [isWeb, setIsWeb] = useState(false);
  const [webdata, setWebdata] = useState();

  const webinformaition = async () => {
    const resp = await webinfovalidation({ "id": 123 });
    if (resp.status === 1) { setIsWeb(true); setWebdata(resp.data); } else { setIsWeb(false);  }
  }
  useEffect(() => { webinformaition(); }, []);




    const handleShow = () =>{
        Swal.fire({
            html: `<img src=${logo} className="img-fluid d-block mx-auto mt-5" />
            <h4 className="title text-uppercase text-center mt-4 textWhite">
            Success
          </h4>
          <p className="text-center my-4 textcolor">
          <small className="textWhite">
          Thank you for registering </small></p>
          <p className="text-center">
         <small className="textWhite"> with Warrior SMS!" </small>
      </p>
          `,
            showClass: {
              popup: 'custom-dialog-container',
            },
            customClass: {
                confirmButton: 'custom-button custom-button-size modalbtn',
              },
      
            }).then((result) => {
              if (result.isConfirmed) {
                // Redirect to a new page
                navigate(`/Home`);
              }
            });
         
    }
  return (
    <>
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col>
              <div className="RightSide">
                <HeaderDashboard />
              <section className="innerContent">
              <div id="mainpageWrapper" className="page-content-wrapper">
                  {/* <!-- =========== CONTENT ============ --> */}

                  <div className="page-content-inner bg-brand-dark py-3 px-5 relative">
                    {/* <!-- image layer --> */}
                    <div className="wrapp-back-layer">
                      <img src="assets/images/pictures/watermark_bg.png" />
                    </div>
                    {/* <!-- img layer --> */}

                    {/* <!-- step fill --> */}
                    <div className="stepsinfo-fill">
                      <ul className="steps allstep_complete">
                        <li className="completed-step"></li>
                        <li className="completed-step"></li>
                        <li className="completed-step"></li>
                      </ul>
                    </div>
                    <section className="contentSections pl-5">
                      <div className="businessinner-sec bg-white border-radius10 py-5 relative">
                        <div className="container">
                          <h3 className="text-uppercase text-center title">
                            Time to set up your business details
                          </h3>
                          <p className="brand-light text-center">
                            Welcome to Studio Details. Click on a section to
                            add/update your company details, <br /> set up
                            scheduled activities such as classes and workshops,
                            add or manage staff,
                            <br /> clients, shifts, products, payments and more.
                          </p>
                          <div className="skip-head relative py-4">
                            <h3 className="title text-uppercase text-center">
                              Step 4 of 4: Add Additional Services
                            </h3>
                            {/* <!-- <Link href="" className="btn btn-brandwhite box-shadow-greenlight text-uppercase">Skip</Link> --> */}
                          </div>
                          <div className="row justify-content-center py-2">
                            <div className="col-md-6 col-lg-4">
                            <Link to={isWeb ? "/businessweb" : "/create_web"}>
                              {/* <Link to="/marketing"> */}
                                <div className="business-box p-3">
                                  <h5 className="title text-center">
                                    {/* Marketing */}
                                    Hosted Website
                                  </h5>
                                  <p className="text-center">
                                  Once you have added all of your Staff, Classes, Services, Workshops, and Passes in the previous 3 steps, you can easily build your custom site. Warrior will use the content you have created to fill out the different sections of your site.  You will be able to add images, provide content about your studio, pick fonts and colors.  If you have questions reach out to Help@warriorsms.com and we can work with you to host your site and integrate details from the Warrior Platform.
                                    {/* Warrior has partnered with Constant Contact
                                    to offer you everything a businesses needs
                                    to grow your client base. Website builder,
                                    Social Media, Email marketing, and contact
                                    list management in one place. Our
                                    partnership allows you to try it for 6
                                    months free. Click to learn more. */}
                                  </p>
                                </div>
                              </Link>
                            </div>
                            <div className="col-md-6 col-lg-4">
                              {/* <!-- href="https://partners.na.bambora.com/warrior-studio-management-software/" --> */}
                              <Link to="/merchant-service">
                                <div className="business-box p-3">
                                  <h5 className="title text-center">
                                    Merchant Services
                                  </h5>
                                  <p className="text-center" style={{minHeight:'165px'}}>
                                    Accepting credit and debit cards will help
                                    grow your business. We will need to have an
                                    account set up for you so that the service
                                    is seamless for you and your clients. We
                                    offer a variety of options and great
                                    pricing.
                                  </p>
                                </div>
                              </Link>
                            </div>
                            <div className="col-md-6 col-lg-4">
                              <Link to="/account-sync">
                                <div className="business-box p-3">
                                  <h5 className="title text-center">
                                    Accounting Sync
                                  </h5>
                                  <p className="text-center" style={{minHeight:'165px'}}>
                                    Leverage our Clover / Quickbooks sync
                                    functionality to update your accounting
                                    software with all of your transactions. By
                                    setting up your merchant services with
                                    Clover (see Merchant Services) you will be
                                    able access the Quickbooks app which will
                                    reduce time and effort managing your books.
                                  </p>
                                </div>
                              </Link>
                            </div>
                          </div>
                          <Link
                            href="javascript:void(0);"
                            className="btn btn-brandbluegrad mx-auto mt-4"
                            data-toggle="modal"
                            data-target="#successModal"
                            onClick={handleShow}
                          >
                            Finished!
                          </Link>
                        </div>
                      </div>
                    </section>
                    {/* <!-- CONTENT SECTION END --> */}
                  </div>

                  <Footer />
                </div>
              </section>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
