import React from "react";
import Sidebar from "../comman_page/Sidebar";
import { HeaderDashboard } from "../comman_page/HeaderDashboard";
import { Footer } from "../../../../public/containers/footer/Footer";
import { Link } from "react-router-dom";
import "./AddPeople.css";
import { Row, Col } from "react-bootstrap";

export const AddPeople = () => {
  return (
    <>
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
          <Sidebar />
            <Col xl={11}>
              <div className="RightSide">
                <HeaderDashboard />
                <section className="innerContent">
                <div id="mainpageWrapper" className="page-content-wrapper">
                  <div className="page-content-inner bg-brand-dark py-3 px-5 relative">
                    <div className="wrapp-back-layer">
                      <img src="assets/images/pictures/watermark_bg.png" />
                    </div>
                    <div className="stepsinfo-fill">
                      <ul className="steps">
                        <li className="completed-step" />
                        <li />
                        <li />
                      </ul>
                    </div>
                    <section className="contentSections pl-5">
                      <div className="businessinner-sec bg-white border-radius10 py-5 relative my-4">
                        <div className="container">
                          <h3 className="text-uppercase text-center title">
                            Time to set up your business details
                          </h3>
                          <p className="brand-light text-center">
                            Welcome to Studio Details. Click on a section to
                            add/update your company details, <br /> set up
                            scheduled activities such as classes and workshops,
                            add or manage staff,
                            <br /> clients, shifts, products, payments and more.
                          </p>
                          <div className="skip-head relative py-4">
                            <h3 className="title text-uppercase text-center">
                              Step 1 of 4: Add your people
                            </h3>
                            <Link
                              to="/Home"
                              className="btn btn-brandwhite box-shadow-greenlight text-uppercase"
                            >
                              Leave Set Up
                            </Link>
                          </div>
                          <div className="row justify-content-center py-2">
                            <div className="col-md-6 col-lg-5">
                              <Link to="/list-clients">
                                <div className="business-box p-3">
                                  <h5 className="title text-center">Clients</h5>
                                  <p className="text-center mt-4">
                                    Add your current clients here. You can add
                                    them one at a time or by bulk upload using
                                    our template. When you add a client, they
                                    will receive a welcome email which will
                                    allow them to confirm their account and
                                    complete their profile.
                                  </p>
                                </div>
                              </Link>
                            </div>
                            <div className="col-md-6 col-lg-5">
                              <Link to="/list-instructor">
                                <div className="business-box p-3">
                                  <h5 className="title text-center">
                                    Instructor/Staff
                                  </h5>
                                  <p className="text-center mt-4">
                                    Add your staff and instructors here. Based
                                    on the package you purchased you will have
                                    the ability to activate up to that maximum
                                    staff. The admin will automatically have a
                                    staff profile started using the same log in
                                    details and count as 1 of your staff. An
                                    email will be sent to staff you add so they
                                    can activate and complete their profile.
                                  </p>
                                </div>
                              </Link>
                            </div>
                          </div>
                          <Link
                            to="/add-items"
                            className="btn btn-brandbluegrad mx-auto mt-4"
                          >
                            Next
                          </Link>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
                </section>
                <Footer />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
