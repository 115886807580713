import { useEffect, useRef, useState } from 'react';

export const InputTypeTax = (
  { type,
    value,
    className,
    onChange,
    placeholder,
    name,
    id,
    lable,
    error,
    maxLength,
    accept,
    readOnly,
    minLength,
    required,
    step
  }
) => {
  const inputRef = useRef(null);
  useEffect(() => {
    if (value !== undefined) {
      inputRef.current.value = value;
    }
  }, [value]);


  return (
    <>
      <div className="mb-3">
        {lable ? <label htmlFor={id ? id : ''} className="form-label inputLable">{lable}</label> : ''}
        <input
          required={required ? true : false}
          readOnly={readOnly ? true : false}
          accept={accept}
          type={type}
          id={id}
          step={step ? step : "0.001"}
          min={minLength ? minLength : ""}
          max={maxLength ? maxLength : ""}
          name={name}
          className={className ? className : "form-control"}
          placeholder={placeholder ? placeholder : ""}
          ref={inputRef}
          onChange={onChange}
        />
        <small className="text-danger">{error}</small>
      </div>
    </>
  );
};
