import React from 'react'
import { InnerHeader } from '../../Components/inner-header/InnerHeader1';
import { Innerfooter } from '../../Components/inner-footer/Innerfooter1';
import './StaffAppointment.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { DashboardToSectionDetails } from '../../Components/CommanPages/DashboardToSectionDetails';
import { ageCalculation, client_my_book_services_list, staff_my_book_services_list } from '../../../Static_Services/Staff_Services';
import { useState } from 'react';
import { useEffect } from 'react';
import { InputField } from '../../../sharedComponents/InputField';
import moment from 'moment';
import { Spinner } from 'react-bootstrap';

export const StaffAppointment = () => {
  const location = useLocation();
  const [appointmentDetail, setAppointmentDetail] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const navigate = useNavigate();
  const [loder, setLoder] = useState(false);

  const [businessDetail, setBusinessDetail] = useState();
  // const bzDetails = localStorage.getItem('bzDetails');
  const [currentUrl, setCurrentUrl] = useState();
  const [currentStatus, setCurrentStatus] = useState('upcoming')
  const [pageCount, setPageCount] = useState(1);
  const [dataCount, setDataCount] = useState();


  useEffect(() => {
    if (JSON.parse(localStorage.getItem('w_BusinessDetail'))) {
      setBusinessDetail(JSON.parse(localStorage.getItem('w_BusinessDetail')));
    }
    if (location.pathname) {
      setCurrentUrl(location.pathname);
      localStorage.setItem('w_AppointUrl', location.pathname);
    }
  }, []);

 const getappointment = async (page, status,sDates='') =>{

    setLoder(true);
    let pgCount = '';
    if(page){
      pgCount=page;
    }else{
      pgCount=1;
    }
    // if (currentStatus != status) {
    //   pgCount = page;
    // } else {
    //   pgCount = 1;
    // }
    // currentStatus = status;

    let detail ='';
    let searchdate = '';

    if (sDates) {
      searchdate = moment(sDates, 'YYYY-MM-DD').unix();
    } else {
      searchdate='';
    }
    detail = { 'pageid': pgCount, 'search_dt': searchdate, "business_id": businessDetail.business_id, 'transaction_status': status };

    let resp = await staff_my_book_services_list(detail);
    if (resp) {
      let response = resp.data;
      if (response.data.length != 0) {
        response.data.map((el) => {
          // Date Conversion UTC
          el.start_date_utc_new = moment.unix(el.shift_date).format('MMM DD YYYY');
          el.start_time_new = moment.unix(el.start_time).format('hh mm A');
          el.end_time_new = moment.unix(el.end_time).format('hh mm A');
          el.age = ageCalculation(el.date_of_birth);
          el.Name = el.name + ' ' + el.lastname;
          if (el.family_user_id != 0) {
            el.age = ageCalculation(el.family_dob);
            el.Name = el.family_member_name;
            el.gender = el.family_gender;
            el.profile_img = el.family_profile_img;
          }
        })
      }
      if (pgCount == 1) {
        setAppointmentDetail(response.data);
      } else {
        // setAppointmentDetail((appointmentDetail) => appointmentDetail.concat(response.data));
      }
      setLoder(false);
    } else {
      setDataCount(0);
      if (pgCount == 1) {
        setAppointmentDetail([]);
      }
      setLoder(false);
    }

  }

  const reset = () => {
    setSelectedDate('');
    setCurrentStatus('upcoming');
    getappointment(1,'upcoming');
  }

  const dateHandler= (e)=>{
    const value = e.target.value;
    setSelectedDate(value);
    getappointment(1,currentStatus,value);
    
  }

  useEffect(() => {
    if(businessDetail){
      getappointment(1,currentStatus);
    }
  }, [businessDetail])


 const getval = (event) =>{
    getappointment(1,event.target.value);
  }
  const changeHandler = (e) => {
    const value = e.target.value;
    getappointment(1,value);
    setCurrentStatus(value);
  }

  const redirectUrl = (datas) => {
    localStorage.setItem('w_Booking_detail', JSON.stringify(datas));
    navigate('/booking-detail', { state: { data: datas } });
  }
  return (
    <>
      {loder ? <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div> : ""}
      <InnerHeader />
      <div className="body-grey">
        <div className="studio-details">
          <div className="container">

            <DashboardToSectionDetails />

            <div className="home-staff pb-5">
              <div className="text-right mb-2 pb-1">

                <Link to="/services">
                  <button type="button" className="btn btn-green-grad"> Add Client Appointment </button>
                </Link>
                <button type="button" className="btn btn-blue-grad px-2 text-uppercase mb-0 ml-2" onClick={() => window.history.back()} >Back </button>

              </div>

              <div className="white-bg border-radius6 box-shadow1 p-4 mt-3 available-slots">
                <div className="row" >
                  <div className="col-12 col-md-5">
                    <div className="centered-cover w-100">
                      <div className="flex-break">
                        <div className="std-detail-select position-relative w-100">
                          <select className="form-control brand-color1 border-radius6" onChange={changeHandler} >
                            <option value="all" selected={currentStatus=='all'}> All Past Appointments </option>
                            <option value="upcoming" selected={currentStatus=='upcoming'}> My Future Appointments </option>
                            <option value="cancel" selected={currentStatus=='cancel'}> My Cancelled Appointment </option>
                            <option value="pending_payment" selected={currentStatus=='pending_payment'}  > My Pending Payment Appointment </option>
                            <option value="completed_appointment" selected={currentStatus=='completed_appointment'} > My Completed Appointment </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-md-5">
                    <div class="text-right w-100">
                      <div class="flex-break">
                        <div class="std-detail-select position-relative w-100">
                          <InputField
                            type="date"
                            minLength={moment().format('YYYY-MM-DD')} 
                            onChange={(e) => dateHandler(e)}
                            value={selectedDate}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-md-2">
                    <div class="pt-3">
                      <button type="button" class="btn btn-blue-grad px-4 text-uppercase mb-0 mb-md-5"
                        onClick={() => reset()}>Reset </button>
                    </div>
                  </div>
                </div>
                {/* <!-- end of row --> */}
                {appointmentDetail && appointmentDetail.length != 0 &&
                  <div class="activity-card-section mt-4 pt-2" >
                    <div class="row">
                      {appointmentDetail.map((items) => (
                        <div class="col-12 col-md-4 mb-4" >
                          <div class="activity-card box-shadow1 border-radius6 white-bg p-2">
                            <div class="row">
                              <div class="col-12 text-right">
                                {items.booking_status == 'Success' && <a class="black-color font-w-100  mr-1 mb-1 paratitle" >Paid </a>}
                                {items.booking_status == 'Completed' && <a class="black-color font-w-100  mr-1 mb-1 paratitle">Payment Pending </a>}
                                {items.booking_status == 'Cancel' && <a class="black-color font-w-100  mr-1 mb-1 paratitle" >Cancelled </a>}
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-3 col-sm-3 col-md-12 col-lg-3">
                                <a href="Javascript:void(0);" onClick={() => redirectUrl(items)} >
                                  <div class="activity-card-logo">
                                    <img src={items.profile_img} class="box-shadow1 new-log-img" />
                                  </div>
                                </a>
                              </div>

                              <div class="col-9 col-sm-9 col-md-12 col-lg-9 pl-0 pl-sm-0 pl-md-3 pl-lg-0">
                                <a href="Javascript:void(0);" onClick={() => redirectUrl(items)} >
                                  <div class="row">
                                    <div class="col-7 col-md-12 col-lg-7 mt-0 mt-md-4 mt-lg-0 pl-0 pl-sm-0 pl-md-3 pl-lg-0">
                                      <div class="activity-card-details text-left">
                                        <h5 class="classname-title black-color mb-0">{items.Name}</h5>
                                        <p class="brand-color3 font-w-500"> {ageCalculation(items.date_of_birth)} Years, {items.gender}</p>

                                        <ul class="d-flex align-items-center py-2">

                                          <li> <h5 class="black-color mb-0 classname-title">{items.service_name} </h5> </li>
                                        </ul>

                                      </div>
                                    </div>
                                    <div class="col-5 col-md-12 col-lg-5 pr-1">
                                      <div class="text-right">
                                        <p class="black-color font-w-100  mr-1 mb-4 paratitle">{items.start_date_utc_new} </p>
                                        <div class="card-green-box text-center">
                                          <p class="white-color mb-0 paratitle"> {items.duration} Min.</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                                <div class="row pt-2">
                                  <p class="black-color font-w-100 paratitle mb-0">Location :
                                  </p>
                                  {items.location_url &&
                                    <h5 class="heading-titlenew ml-2 mb-0 black-color" ><Link to={items.location_url} target="_blank">{items.location_name}</Link></h5>}
                                  {!items.location_url && <h5 class="heading-titlenew ml-2 mb-0 black-color">{items.location_name}</h5>}

                                </div>
                                <a href="Javascript:void(0);" onClick={() => redirectUrl(items)}
                                >
                                  <div class="row pt-2">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 px-0 border-right">
                                      <div class="text-left">
                                        <p class="paratitle black-color mb-0"> Start Time </p>
                                        <h5 class="classname-title black-color mb-0"> {moment.unix(items.start_time).format('hh:mm A')} </h5>
                                      </div>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 d-flex justify-content-end">
                                      <div class="text-left">
                                        <p class="paratitle black-color mb-0"> End Time </p>
                                        <h5 class="classname-title black-color mb-0"> {moment.unix(items.end_time).format('hh:mm A')} </h5>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                }
                {appointmentDetail.length == 0 &&
                  <div className="row staff-studio-details" >
                    <div className="text-center">
                      <p className="font-awesome black-color font-18 d-inline mx-2 "> No Appointments Are Scheduled</p>
                    </div>
                  </div>
                }
              </div>
            </div>
            {/* <!-- end of home-staff --> */}
          </div>
          {/* <!-- end of container --> */}
        </div>
        {/* <!-- End of My Studio Card Detials Section --> */}
      </div>
      <Innerfooter />


    </>
  )
}
