import moment from "moment";
import React, { useEffect, useState } from "react";
import { Carousel, Card, Row, Col } from "react-bootstrap";
import { FaLongArrowAltRight, FaLongArrowAltLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export const DashboardSlider = ({ pass, currentUrl, redirectUrl, businessDl, addFav }) => {

    const cardDetail = pass ? pass : [];
    // const isChecked = checkedPassId || "";
    const navigate = useNavigate();
    const [businessDetail, setBusinessDetail] = useState();

    useEffect(() => {
        if (businessDl) {
            setBusinessDetail(businessDl);
        }
    }, [businessDl])

    const chunks = [];
    for (let i = 0; i < cardDetail.length; i += 2) {
        chunks.push(cardDetail.slice(i, i + 2));
    }

    const settings = {
        prevIcon: <FaLongArrowAltLeft />,
        nextIcon: <FaLongArrowAltRight />,
        interval: 1000000,
        pauseOnHover: true,
        wrap: true,
        touch: true,
        keyboard: true,
        slidesToShow: 2,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    return (
        <>
            {chunks.length > 0 && (
                <Carousel {...settings} className="staffSlider">
                    {chunks.map((chunk, index) => (
                        <Carousel.Item key={index}>
                            <Row className="mb-5">
                                {chunk.map((dashsubDetail, cardIndex) => (
                                    <Col lg={6} md={6} xs={12} key={cardIndex}>
                                        <div class="studio-position-heart">
                                            <div class="studio-card-newcustom gradient-studio-overlay box-shadow1"
                                                onClick={() => redirectUrl(dashsubDetail.business_id)} >
                                                <div class="img-box" style={{ backgroundImage: `url(${dashsubDetail.business_img})` }}>
                                                </div>
                                                <div class="studio-detail p-3">
                                                    <div class="row mx-0 align-items-start">
                                                        <div class="profile-image box-shadow1">
                                                            <img src={dashsubDetail.logo} class="img-fluid" />
                                                        </div>
                                                        <div class="studio-profile-detail flex-fill pl-4">
                                                            <h5 class="nametitle classname-title mb-1">{dashsubDetail.business_name}</h5>
                                                            {dashsubDetail.address.length <= 25 &&
                                                                <p class="brand-color3 paratitle mb-2" >
                                                                    {dashsubDetail.address}</p>}
                                                            {dashsubDetail.address.length > 25 &&
                                                                <p class="brand-color3 paratitle mb-2" >
                                                                    {dashsubDetail.address}</p>}
                                                            <p class="paratitle"><i class="fas fa-map-marker-alt mr-2 mb-0"></i>
                                                                {dashsubDetail.distance}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="studio-card-select">
                                                {dashsubDetail.favourite == 0 &&
                                                    <i class="fas fa-heart brand-color3 font-22"
                                                        onClick={() => addFav(dashsubDetail.business_id, 1)}
                                                    ></i>}
                                                {dashsubDetail.favourite == 1 &&
                                                    <i class="fas fa-heart brand-color2 font-22"
                                                        onClick={() => addFav(dashsubDetail.business_id, 0)}
                                                    ></i>}
                                            </div>
                                        </div>
                                        {/* // <!-- end of purchase-blue-card --> */}

                                    </Col>
                                ))}
                            </Row>
                        </Carousel.Item>
                    ))}
                </Carousel>
            )}
        </>
    )
}
