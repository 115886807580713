import React from "react";
import StaticHeader from "../../StaticHeader";
import StaticFooter from "../../StaticFooter";
import { Link } from "react-router-dom";
import { InputField } from "../../../sharedComponents/InputField";
import { HelpForm } from "./HelpForm";

export const StaticHelp = () => {
  return (
    <>
      <StaticHeader />

      {/* <!-- Help Content Section --> */}
      <div className="background-before contact-content-section py-4 mt-2">
        <div className="container">
          <div className="text-left wow animated zoomIn">
            <h5 className="dark-color font-w-600 pb-5"> Help & Contact Us </h5>
          <HelpForm />

            <div className="row pt-0 pb-5">
              <div className="col-12 col-md-5 col-lg-4 col-xl-3 order-2 order-md-1">
                <div className="contact-content-left">
                  <div className="contact-client-detail">
                    <ul>
                      <li>
                        
                        <i className="fas fa-home"></i>
                      </li>
                      <li>
                        <h5 className="title brand-color1 font-w-400">
                          
                          Toronto, Canada
                        </h5>
                        <br />
                      </li>
                    </ul>
                  </div>
                  {/* <!-- Address --> */}

                  <div className="contact-client-detail">
                    <ul>
                      <li>
                        <i className="fas fa-phone"></i>
                      </li>
                      <li>
                        <h5 className="title brand-color1 font-w-400">
                        +1 416-358-4361
                        </h5>
                        <p className="brand-color3">
                        9:00 AM - 9:00 PM Monday through Friday EST
                        </p>
                      </li>
                    </ul>
                  </div>
                  {/* <!-- Phone Number --> */}

                  <div className="contact-client-detail">
                    <ul>
                      <li>
                        <i className="fas fa-envelope"></i>
                      </li>
                      <li>
                        <h5 className="title brand-color1 font-w-400">
                          <Link
                            to="https://mail.google.com/mail/u/0/?view=cm&to=sales@Warriorsms.com&:&bcc&cc&fs=1&tf=1"
                            target="blank"
                            className="brand-color1"
                          >
                            Sales@Warriorsms.com
                          </Link>
                        </h5>
                        <p className="brand-color3">
                          
                          Send us your query anytime!
                        </p>
                      </li>
                    </ul>
                  </div>
                  {/* <!-- Email --> */}

                  <div className="contact-social-account pt-3">
                    <ul>
                      <li>
                        
                        <Link
                          to="https://www.facebook.com/WarriorStudioManagementSoftware"
                          target="_blank"
                        >
                          <img src="clientassets/img/fb-icon.png" />
                        </Link>
                      </li>
                      <li>
                        
                        <Link
                          to="https://twitter.com/warrior_sms?lang=en"
                          target="_blank"
                        >
                          
                          <img src="clientassets/img/twitter-icon.png" />
                        </Link>
                      </li>
                      <li>
                        
                        <Link
                          to="https://www.instagram.com/warriorsms/?hl=en"
                          target="_blank"
                        >
                          
                          <img src="clientassets/img/insta-icon.png" />
                        </Link>
                      </li>
                      {/* <li>
                        <Link to="#;" target="_blank">
                          <img src="clientassets/img/linkedin-icon.png" />
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              {/* <!-- end of contact-content-left --> */}

              <div className="col-12 col-md-7 col-lg-8 col-xl-9 order-1 order-md-2">
                <div>
                  <p className="pb-3 mb-0 brand-color3 font-18 line-height25 text-justify">
                    
                    Our team at Warrior Studio Management Software is looking
                    forward to working with you. Please send us an email with
                    Name, Business Name, Contact information, and what you would
                    like to discuss. We can set up a time to talk or solve your
                    issue over email.
                  </p>
                  <p className="pb-3 mb-0 brand-color3 font-18 line-height25 text-justify">
                    
                    We will respond within 2-4 business hours to your enquiry.
                  </p>
                  <p className="pb-3 mb-0 brand-color3 font-18 line-height25 text-justify">
                    
                    Our support is available 9:00 AM to 9:00 PM Monday through Friday EST
                  </p>
                </div>
              </div>
              {/* <!-- end of contact-content-left --> */}
            </div>
            {/* <!-- end of row -->         */}
          </div>
        </div>
        {/* <!-- end of container --> */}
      </div>
      {/* <!-- End of Help Content Section --> */}

      <StaticFooter />
    </>
  );
};
