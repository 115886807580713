import React, { useEffect, useState } from 'react'
import { HeaderDashboard } from '../../comman_page/HeaderDashboard'
import { Footer } from '../../../../../public/containers/footer/Footer'
import Sidebar from '../../comman_page/Sidebar'
import { Container, Row, Col, Form } from 'react-bootstrap'
import { AddClientform } from '../../../../../sharedComponents/AddClientform'


export const Addclient = () => {

  return (
    <>
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col className="p-0 RightCol">
              <div className="RightSide">
                <HeaderDashboard />
                <section className="addClient">
                    <Container className="pt-5">
                      <h4 className='mt-5 pt-4 pb-3'>Add Client</h4>
                      <div className='AddClientForm AddClientContent'>
                      <AddClientform />
                      </div>
                    </Container>
                </section>
                <Footer />
              </div>
            </Col>
          </Row>
        </div>
      </div>

    </>
  )
}
