import React from 'react'
import HeaderChousePlan from '../header/HeaderChousePlan'
import StaticFooter from '../../../staticpagis/StaticFooter';
import { Link } from 'react-router-dom';

export const StudioHome = () => {
    const planDays = process.env.REACT_APP_planDays;
  return (
<>
<HeaderChousePlan />
   <div className="middle-content-wrp" style={{paddingTop:"120px"}}>
  {/* image layer */}
  <div className="signwrapp-back-layer">
    <img src="assets/images/pictures/watermark_bg.png" />
  </div>
  {/* img layer */}
  <div className="container py-5">
    <div className="row justify-content-center">
      <div className="col-md-6 col-lg-5 col-xl-4">
        <div className="middle-innerbox py-4 px-4">
          <div className="svg-checkico mx-auto mb-3">
            <i className="fas fa-check" />
          </div>
          <span className="brand-light text-uppercase d-block text-center mt-2">
            Step 1 of 3
          </span>
          <h3 className="title text-center mt-2">Choose your plan.</h3>
          <ul className="planlist mt-3 px-4">
            <li>
              <p className="brand-light">
                Your First {planDays} Days are Free
              </p>
            </li>
            <li>
              <p className="brand-light">Unlimited Customer Profiles</p>
            </li>
            <li>
              <p className="brand-light">
                Pick your package based on Staff/Admin Users
              </p>
            </li>
          </ul>
          <Link to="/choose-plan" className="btn btn-brandbluegrad mt-4">
            See the plan
          </Link>
        </div>
      </div>
    </div>
  </div>
</div>
<StaticFooter />
</>
  )
}
