import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col, Form, Spinner } from "react-bootstrap";
import { SharedButton } from "./Button";
import { InputField } from "./InputField";
import { Link } from "react-router-dom";
import { PlanList } from "../Static_Services/StaticServices";
import Swal from "sweetalert2";
import logo from "../Images/logo_white_web.png"
import { ExpiryCheck } from "../Static_Services/Staff_Services";
import { getPlanList, getSaveCard, purchasePlan } from "../Studio-Services/Studio_Services";


export const SubscriptionModal = ({ binfo, staffListss,active }) => {

  const [show, setShow] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showNewCardForm, setShowNewCardForm] = useState(false);
  const [cardsaveID, setCardsaveID] = useState();

  const [loder, setLoder] = useState(false);

  const [priceCard, setPriceCard] = useState([]);
  const [ind, setInd] = useState();
  const [planData, setPlanData] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [signupData, setSignupData] = useState();
  const [planListArray, setPlanListArray] = useState();
  const [prePlanId, setPrePlanId] = useState(null);
  const [businessInfo, setBusinessInfo] = useState([]);
  const [saveCards, setSaveCards] = useState([]);

  const [paycardSpinner, setPaycardSpinner] = useState(false);



  const clientSaveCardList = async () => {
    let fileData = {
      "userid": signupData.id,
      "types": "studio"
    }
    const resp = await getSaveCard(fileData);
    if (resp) {
      const data = resp.data.data;
      const updatedData = data.map((el) => ({
        ...el,
        checked: false,
      }));
      setSaveCards(updatedData);
    } else setSaveCards([]);
  }

  const getCardType = (event) => {
    const updatedSaveCards = saveCards.map((el) => ({
      ...el,
      checked: el.card_id === event.target.id ? event.target.checked : el.checked,
    }));

    setSaveCards(updatedSaveCards);
  };


  useEffect(() => {
    if (localStorage.getItem('st_userData')) {
      let signup = JSON.parse(localStorage.getItem('st_userData'));
      setSignupData(signup);
    } else {
      let signupDa = JSON.parse(localStorage.getItem('st_userRegisterData'));
      setSignupData(signupDa);
    }
  }, [])

  useEffect(() => {
    if (signupData && signupData.id != '') {
      clientSaveCardList();
    }
  }, [signupData]);

  useEffect(() => {
    setBusinessInfo(binfo);
  }, [binfo])

  const handleClosePaymentModal = () => {
    setShowPaymentModal(false);
    setShowNewCardForm(true);
  };

  const handleShowPaymentModal = () => {

    if (!planData) {
      Swal.fire({
        html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
        <h4 class="title text-uppercase text-center mt-4 textWhite">
       ERROR
      </h4>
    <p class="text-center my-4 textcolor">
    <small class="textWhite">
    Please Select Valid Plan </small></p>
    `,
        showClass: {
          popup: 'custom-dialog-container',
        },
        customClass: {
          confirmButton: 'custom-button custom-button-size modalbtn',
        },
      });
    } else if (parseInt(planData.max_users) < parseInt(active)) {
      Swal.fire({
        html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
        <h4 class="title text-uppercase text-center mt-4 textWhite">
       ERROR
      </h4>
    <p class="text-center my-4 textcolor">
    <small class="textWhite">
    Please choose upgraded plan </small></p>
    `,
        showClass: {
          popup: 'custom-dialog-container',
        },
        customClass: {
          confirmButton: 'custom-button custom-button-size modalbtn',
        },
      });
    } else {
      setShow(false);
      setShowPaymentModal(true);
      setShowNewCardForm(true);
    }
  };


  const getPlan = async () => {
    const resp = await getPlanList();
    if (resp) {
      setPlanListArray(resp.data.data);
      let crt = [];
      (resp.data.data).map((e) => {
        crt.push({
          data: e,
          planid: e.plan_id,
          planImg: "/clientassets/img/logo_green_small.png",
          planName: e.plan_name,
          amount: e.amount,
        })
      })
      setPriceCard(crt);
    }
  }

  const findPrePlanId = (bdata,idata) => {
    Object.keys(idata).forEach((key) => {
      const information = idata[key];
      if (information.max_users === bdata.package && information.amount === bdata.amount) {
        setPrePlanId(information.plan_id);
      }
    });
  };


  useEffect(() => {
    if (binfo && binfo.length != 0 && planListArray && planListArray.length != 0) {
      findPrePlanId(binfo,planListArray);
    }
  }, [planListArray, binfo])


  // planSelected() {
  //   const collection = this.planListArray;
  //   let i = 0;
  //   for (const key of Object.keys(collection)) {
  //     const information = collection[key];
  //     if (information.max_users == this.businessInfo.package && information.amount == this.businessInfo.amount) {
  //       this.pre_planId = information.plan_id;
  //     }
  //     i++;
  //   }
  // }


  useEffect(() => {
    getPlan();
  }, [])

  const selectHandler = (data, index) => {
    setInd(index);
    setPlanData(data);
  }



  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    if (e.nativeEvent.inputType === "deleteContentBackward") {
      if (inputValue.endsWith('/')) {
        setExpiryDate(inputValue.slice(0, -1));
      } else { setExpiryDate(inputValue); }
    } else {
      if (inputValue.length === 2 && inputValue.charAt(2) !== '/') {
        setExpiryDate(inputValue + '/');
      } else if (inputValue.length === 8) { return; } else { setExpiryDate(inputValue); }
    }
  };

  const [expiryDate, setExpiryDate] = useState();
  const [carderror, setCarderror] = useState({ agree: '', radio: '', fname: '', lname: "", crnumber: "", dates: "", cvv: "" });
  const [cardData, setCardData] = useState({ agree: '', radio: '', fname: '', lname: "", crnumber: "", dates: "", cvv: "" });
  const CardInputChange = (e) => {
    const { name, value } = e.target;
    const cleanedValue = value.replace(/^\s+/g, '');
    setCardData((pre) => ({ ...pre, [name]: cleanedValue }));
    setCarderror((pre) => ({ ...pre, [name]: "" }));
  }


  const SubMitHandler = async (e) => {
    e.preventDefault();
    let isValid = 1;
    let expiry_month = '';
    let expiry_year = '';
    let expired = '';

    // if (expiryDate) {
    //   expired = ExpiryCheck(expiryDate);
    // }
    // if (expired) {
    //   expiry_month = expired[0];
    //   expiry_year = expired[1];
    // } else { setCarderror((pre) => ({ ...pre, dates: "* Valid Till is required" })); isValid = 2; }
    // if (!cardData.fname) { setCarderror((pre) => ({ ...pre, fname: "* First Name is required" })); isValid = 3; };
    // if (!cardData.lname) { setCarderror((pre) => ({ ...pre, lname: "* Last Name is required" })); isValid = 3; };
    // if (!cardData.crnumber) { setCarderror((pre) => ({ ...pre, crnumber: "* Card Number is required" })); isValid = 4; };
    // if (!cardData.cvv) { setCarderror((pre) => ({ ...pre, cvv: "* CVV is required" })); isValid = 5; };
    if (!cardData.agree) { setCarderror((pre) => ({ ...pre, agree: "* You must agree to the terms" })); isValid = 6; };

    if (isValid === 1) {
      setLoder(true);
      var finalData = {
        "name": cardData.fname,
        "lastname": cardData.lname,
        "user_id": signupData.id,
        "plan_id": planData.plan_id,
        "amount": planData.amount,
        "card_number": cardData.crnumber,
        "cvv_no": cardData.cvv,
        "expiry_month": expiry_month,
        "expiry_year": expiry_year,
        "save_card_check": 1,
        "token": '',
        "country_code": '',
        "pre_plan_id": prePlanId,
      }

      const resp = await purchasePlan(finalData);
      if (resp) {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
        <h4 class="title text-uppercase text-center mt-4 textWhite">
        SUCCESS
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
    ${resp.data.message}
        </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
          confirmButtonText: 'Continue',
        }).then((result) => {
          if (result.isConfirmed) {
            setShowPaymentModal(false);
            staffListss();
            setLoder(false);
          }
        });
      }
      setLoder(false);

    }
  }


  const changePlan = () => {
    setShow(true);
    setShowPaymentModal(false);
    setShowNewCardForm(false);
  }


  const paymentViaSaveCard = async (card) => {
    setPaycardSpinner(true);
    var finalData = {
      "name": card.name,
      "lastname": '',
      "user_id": signupData.id,
      "plan_id": planData.plan_id,
      "amount": planData.amount,
      "card_number": card.number,
      "cvv_no": '',
      "expiry_month": card.expiry_month,
      "expiry_year": card.expiry_year,
      "save_card_check": 0,
      "token": card.card_token,
      "country_code": '',
      "pre_plan_id": prePlanId,

    }
    const resp = await purchasePlan(finalData);
    if (resp) {
      setPaycardSpinner(false);
      Swal.fire({
        html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
       <h4 class="title text-uppercase text-center mt-4 textWhite">
       SUCCESS
     </h4>
     <p class="text-center my-4 textcolor">
     <small class="textWhite">
    ${resp.data.message} </small></p>
     `,
        showClass: {
          popup: 'custom-dialog-container',
        },
        customClass: {
          confirmButton: 'custom-button custom-button-size modalbtn',
        },
        confirmButtonText: 'Continue',
      }).then((result) => {
        if (result.isConfirmed) {
          staffListss();
        }
      });
    }

    setPaycardSpinner(false);
  }

  return (
    <>
      {loder ?
        <div className='MainLoader'>
          <Spinner animation="border" role="status" className='Loader'>
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
        : ""}
      <SharedButton
        title={"Upgrade Subscription Plan"}
        className={"btn btn-primary"}
        type={"button"}
        variant={"primary"}
        onClick={handleShow}
      />

      <Modal show={show} onHide={handleClose} className="SubcriptionPlan custom-modal">

        <div className="custom-modal-content p-4 ng-star-inserted" >
          <Modal.Header closeButton className="p-0 m-0">
            <Modal.Title className="p-0 m-0"><small>SUBSCRIPTION PLANS</small></Modal.Title>
          </Modal.Header>

          <Modal.Body className="subsriptionCardPlan custom-modal-body p-4 relative">
            <div className="PlanCard">
              <Row>
                {priceCard.map((plan, index) => (
                  <Col lg={4} sm={12} key={index} onClick={() => selectHandler(plan.data, index)}>
                    <div className={ind === index ? "pricing-card text-center px-4 pt-4 pb-4 border-radius6 white-bg ownCartActive" : "pricing-card text-center px-4 pt-4 pb-4 border-radius6 white-bg"}>
                      <img src={plan.planImg} alt="Logo" />
                      <div className="customradio-card py-2">
                        <p className="black-color font-w-600 font-20 m-0 card-title">
                          {plan.planName}
                        </p>
                      </div>
                      <h5 className={ind === index ? "white-color mb-3 font-w-400" : "black-color mb-3 font-w-400"}>
                        $ {plan.amount} / Month*
                      </h5>
                    </div>
                  </Col>
                ))}
              </Row>
              <Button variant="primary" className="float-end" onClick={handleShowPaymentModal}>
                Continue
              </Button>
            </div>
            <p>Plus Applicable Taxes</p>

          </Modal.Body>
          <Modal.Footer>

          </Modal.Footer>
        </div>
      </Modal>


      {/* Payment Modal Start */}

      <Modal
        show={showPaymentModal}
        onHide={handleClosePaymentModal}
        className="PaymentPopUp custom-modal"
      >
        <div className="custom-modal-content p-4 ng-star-inserted" >
          <Modal.Header closeButton>
            {/* <Modal.Title>PAYMENT DETAILS</Modal.Title> */}
          </Modal.Header>
          <Modal.Body className="subsriptionCardPlan custom-modal-body p-4 relative">
            {/* <h5>Payment Mode</h5> */}
            <div className="PlanCard p-2">
              {/* <Row style={{ textAlign: "center" }}>
                <div className="paymentbtn">
                  <SharedButton
                    title={"Save Card"}
                    variant={"outline"}
                    className={showNewCardForm === true ? "btn btn-SaveCard mr-4" : "btn btn-SaveCard mr-4 active"}
                    onClick={() => setShowNewCardForm(false)}
                  />
                  <SharedButton
                    title={"New Card"}
                    variant={"outline"}
                    className={showNewCardForm === false ? "btn btn-SaveCard" : "btn btn-SaveCard active"}
                    onClick={() => setShowNewCardForm(true)}
                  />
                </div>
              </Row> */}
              {showNewCardForm === true && (
                <Form autoComplete="off" onSubmit={SubMitHandler}>
                  <div className="NewCardForm">
                    {/* <Row>
                      <div className="form-group">
                        <label>First Name</label>
                        <input
                          type="text"
                          className="form-control"
                          autoComplete="off"
                          name='fname'
                          placeholder=""
                          value={cardData.fname}
                          onChange={CardInputChange}
                        />
                        <small className="text-danger">{carderror.fname}</small>
                      </div>
                      <div className="form-group">
                        <label>Last Name</label>
                        <input
                          type="text"
                          className="form-control"
                          autoComplete="off"
                          name='lname'
                          placeholder=""
                          value={cardData.lname}
                          onChange={CardInputChange}
                        />
                        <small className="text-danger">{carderror.lname}</small>

                      </div>
                      <div className="form-group">
                        <label>Card Number</label>
                        <input
                          type="text"
                          autoComplete="off"
                          className="form-control"
                          placeholder="4121 4411 3131 8554"
                          name='crnumber'
                          value={cardData.crnumber}
                          onChange={CardInputChange}
                          maxLength={19}
                        />
                        <small className="text-danger">{carderror.crnumber}</small>
                      </div>
                      <div className="form-group">
                        <label>Expiry Date (MM/YYYY)</label>
                        <input
                          type="text"
                          name={'dates'}
                          className="form-control"
                          placeholder="MM/YYYY"
                          value={expiryDate}
                          onChange={handleInputChange}
                          pattern="^[0-9]{2}/[0-9]{4}"
                          autoComplete="new-password"
                          lable={"Valid Through"}
                        />
                        <small className="text-danger">{carderror.dates}</small>
                      </div>
                      <div className="form-group">
                        <label>Security Code (CVV)</label>
                        <input
                          type="password"
                          autoComplete="off"
                          className="form-control"
                          placeholder="eg. 201"
                          name='cvv'
                          value={cardData.cvv}
                          onChange={CardInputChange}
                          maxLength={3}
                        />
                        <small className="text-danger">{carderror.cvv}</small>
                      </div>
                    </Row> */}
                    <Row>
                      <Col>
                        <div className="plansdetails">
                          <div className="textdetails" style={{ padding: "0px 15px" }}>
                            <h6>{planData.plan_name}</h6>
                            <small style={{ color: "#77787B" }}>$ {parseFloat(planData.amount).toFixed(2)} /Monthly</small>
                          </div>
                          <div className="changeBtn">
                            <SharedButton
                              type={"button"}
                              title={"Change"}
                              onClick={changePlan}
                              className={"changeLink"}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p>
                          By Ticking the below box, you agree to our
                          <Link target="blank" to={"/terms-conditions"} style={{ textDecoration: "none", color: "#1d96d3", padding: "0px 10px" }}> <b>Terms of use</b></Link>
                          and
                          <Link target="blank" to={"/terms-conditions"} style={{ textDecoration: "none", color: "#1d96d3", padding: "0px 10px" }}> <b> Privacy Statement </b></Link>
                          and that you are over 18. Warrior
                          SMS will automatically continue your membership and charge
                          the applicable membership fee to your payment method on a
                          monthly basis until you cancel.
                        </p>
                      </Col>
                    </Row>
                    <Row className="pb-4">
                      <div className="custom-control custom-checkbox mb-3">
                        <input
                          type="checkbox"
                          className={"custom-control-input"}
                          id="agreeTerms"
                          name="agree"
                          value="Agree"
                          checked={cardData.agree === 'Agree'}
                          onChange={CardInputChange}
                        />
                        <label className="custom-control-label" htmlFor="agreeTerms">
                          Agree
                        </label>
                        <small className="text-danger">{carderror.agree}</small>
                      </div>
                    </Row>
                    <Button variant="primary" type="submit">
                      Upgrade Membership
                    </Button>
                  </div>
                </Form>
              )
                //  :
                //   (
                //     <div className="card-details mt-4">
                //       <div>
                //         {saveCards.length !== 0 ? (
                //           saveCards.map((cardsave) => (
                //             <div
                //               key={cardsave.card_id}
                //               className="payment-history-box white-bg border-radius6 box-shadow1 p-3 mb-3"
                //             >
                //               <div className="row mx-0">
                //                 <div className="col-2 pl-0">
                //                 </div>
                //                 <div className="col-8">
                //                   <p className="mb-0 font-14 font-w-500 dark-color">{cardsave.number}</p>
                //                   <h5 className="mb-0 font-12 dark-color mt-2">Valid Till(MM/YY) </h5>
                //                   <p className="mb-0 font-12 dark-color">{`${cardsave.expiry_month} / ${cardsave.expiry_year}`}</p>
                //                 </div>
                //                 <div className="col-2 text-right">
                //                   <input
                //                     type="radio"
                //                     name="pass_radio"
                //                     onChange={(event) => getCardType(event)}
                //                     id={cardsave.card_id}
                //                     className="pass_input_checkbox form-control"
                //                     value={cardsave.card_id}
                //                     checked={cardsaveID === cardsave.card_id}
                //                   />
                //                 </div>
                //                 <button
                //                   className={`btn btn-brandbluegrad d-block w-50 ${cardsave.checked && cardsaveID === cardsave.card_id ? 'disabled' : ''}`}
                //                   type="button"
                //                   onClick={() => paymentViaSaveCard(cardsave)}
                //                   disabled={cardsave.checked && cardsaveID === cardsave.card_id && paycardSpinner}
                //                 >
                //                   <span className="spinner-grow spinner-grow-sm" style={{ display: paycardSpinner ? 'inline-block' : 'none' }} role="status" aria-hidden="true"></span>
                //                   Submit
                //                 </button>
                //               </div>
                //             </div>
                //           ))
                //         ) : (
                //           <p className="text-center">No Record Found</p>
                //         )}
                //       </div>
                //     </div>
                //   )
              }
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};
