import React from 'react'
import Sidebar from '../../comman_page/Sidebar'
import { HeaderDashboard } from '../../comman_page/HeaderDashboard'
import { Container, Row, Col } from 'react-bootstrap'
import { Footer } from '../../../../../public/containers/footer/Footer'
import { InputField } from '../../../../../sharedComponents/InputField'
import { TextArea } from '../../../../../sharedComponents/TextArea'
import { SharedButton } from '../../../../../sharedComponents/Button'
import { useLocation, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useEffect } from 'react'
import moment from 'moment'
import Swal from 'sweetalert2'
import { shift_cancel } from '../../../../../Studio-Services/Studio_Services'
import logo from "../../../../../Images/logo_white_web.png"

export const Viewshift = () => {
  const [data, setData] = useState([]);
  const location = useLocation();
  const preData = location.state.data;

  const [shift, setShift] = useState(true);
  const navigate = useNavigate();

  const shiftHandler = () => {
    setShift(!shift);
  }

  const cancelHandlert = (type) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "Are you sure to cancel shift ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'YES'
    }).then(async (result) => {
      if (result.isConfirmed) {
          const fData = {
            "type": type,
            "schedule_id": data.id,
            "shift_id": data.shift_id
          }
             const resp = await shift_cancel(fData);
              if (resp) {
                Swal.fire({
                  html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
                    <h4 class="title text-uppercase text-center mt-4 textWhite">
                    SUCCESS
                  </h4>
                  <p class="text-center my-4 textcolor">
                  <small class="textWhite">
                  ${resp.data.message} </small></p>
                    `,
                  showClass: {
                    popup: 'custom-dialog-container',
                  },
                  customClass: {
                    confirmButton: 'custom-button custom-button-size modalbtn',
                  },
                }).then(() => {
                  navigate('/list-shifts');
                });
              }

        
      }
    })

  }
  useEffect(() => {
    setData(preData);
  }, [preData])
  return (
    <>

      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col className="p-0 RightCol">
              <HeaderDashboard />
              <div className="RightSide">
                <section className="addClient innerContent">
                  <Container className="pt-5">
                    <h4>List Your Shifts</h4>
                    <p>
                      Shifts are required for clients to be able to book a
                      service appointment. Staff can also be set up for a shift
                      to work without offering appointments (non service) such
                      as working the front desk.
                    </p>
                    <div className="AddClientContent ViewShift">
                      <h3 className='my-4'>VIEW SHIFT</h3>
                      <Row>
                        <Col>
                          <InputField type={"text"} readOnly value={data && data.shift_name ? data.shift_name : ""} lable={"Service Shift"} />
                        </Col>
                        <Col>
                          <InputField type={"date"} readOnly value={data && data.shift_date_str ? data.shift_date_str : ""} lable={"Shift Date"} />
                        </Col>
                        <Col>
                          <InputField type={"text"} readOnly value={data && data.week_name ? data.week_name : ""} lable={"Week Day"} />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <InputField type={"text"} readOnly value={data && data.start_time ? moment.unix(data.start_time).format('hh:mm A') : ""} lable={"Start Time"} />
                        </Col>
                        <Col>
                          <InputField type={"text"} readOnly value={data && data.end_time ? moment.unix(data.end_time).format('hh:mm A') : ""} lable={"End Time"} />
                        </Col>
                        <Col>
                          <InputField type={"text"} readOnly value={data && data.location_name ? data.location_name : ""} lable={"Location"} />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <InputField type={"text"} readOnly value={data && data.pay_type ? data.pay_type : ""} lable={"Pay Type"} />
                        </Col>
                        <Col>
                          <InputField type={"text"} readOnly value={data && data.pay_rate ? data.pay_rate : ""} lable={"Pay Rate"} />
                        </Col>
                        <Col>
                          {/* <InputField type={"text"} value={"Service Shift"} lable={"Service Shift"}/> */}
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <TextArea lable={"Description"}
                            readOnly
                            value={data && data.description ? data.description : ""}
                          />
                        </Col>
                      </Row>
                      {shift ?
                        <Row className='my-2'>
                          <Col>
                            <SharedButton title={"CANCEL SHIFT"} onClick={shiftHandler} type={"button"} className={"btn btn-primary"} />
                          </Col>
                        </Row>
                        : <Row className='my-2'>
                          <Col md={3}>
                            <SharedButton title={"FOR THE DAY"} onClick={() => cancelHandlert(1)} type={"button"} className={"btn btn-outline"} />
                          </Col>
                          <Col md={6}>
                            <SharedButton title={"ALL OF THE FUTURE SHIFT"} onClick={() => cancelHandlert(2)} type={"button"} className={"btn btn-outline"} />
                          </Col>

                        </Row>}
                    </div>
                    <div className="AddClientContent ViewShift">
                      <Row className='my-2'>
                        <Col>
                          <SharedButton title={"Done With Shift"} href={"/add-items"} type={"button"} className={"btn btn-outline"} />
                        </Col>
                      </Row>
                    </div>
                  </Container>
                </section>
                <Footer />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}
