import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { InputField } from '../../../../../sharedComponents/InputField';
import { SharedSelect } from '../../../../../sharedComponents/SharedSelect';
import { SharedButton } from '../../../../../sharedComponents/Button';
import { useState } from 'react';
import { useEffect } from 'react';
import { business_location_update, business_location_update_NEW, get_business_location_detail } from '../../../../../Studio-Services/Studio_Services';
import { AiFillMinusCircle, AiFillPlusCircle } from 'react-icons/ai';
import Swal from 'sweetalert2';
import logo from "../../../../../Images/logo_white_web.png"
import { useNavigate } from 'react-router-dom';
import PlaceAutoCompleted from '../../../../../client-staff/Components/CommanPages/PlaceAutoCompleted';

export default function AddRoomFormNew({ setLoder }) {
    const navigate = useNavigate();


    const business_id = localStorage.getItem('business_id');
    const [location_info, setLocation_info] = useState([]);


    const [bzDetail, setBzDetail] = useState({ "business_id": "", "user_id": "", "location_name": "", "latitude": "", "longitude": "", "address": "" });

    const getData = async () => {
        if (business_id) {
            const data = {
                business_id: business_id
            };
            const resp = await get_business_location_detail(data);
            if (resp) {
                const preBZ = resp.data.data;
                setBzDetail({
                    "business_id": preBZ.business_id,
                    "user_id": preBZ.user_id,
                    "location_name": preBZ.location_name,
                    "latitude": preBZ.latitude,
                    "longitude": preBZ.longitude,
                    "address": preBZ.address
                })
                const preLocation = resp.data.data.location_info;
                const preArray = [];
                preLocation.forEach((e) => {
                    preArray.push({
                        "address": e.address,
                        "capacity": e.capacity,
                        "city": e.city,
                        "country": e.country,
                        "is_address_same": e.is_address_same,
                        "lat": e.lat,
                        "location_id": e.location_id,
                        "location_name": e.location_name,
                        "location_url": e.location_url,
                        "longitude": e.longitude,
                        "online_class_link": e.online_class_link,
                        "state": e.state,
                        "zipcode": e.zipcode,
                    });
                });
                setLocation_info(preArray);
            }
        }
    };

    const locationHandler = (data, i) => {
        const { getAddress, getLocation_name, getCountry, getState, getCity, getZIP_Code, getLat, getLng, getStreet } = data;
        setLocation_info((prevInfo) => {
            const updatedInfo = [...prevInfo];
            updatedInfo[i] = {
                ...updatedInfo[i],
                "address": getAddress,
                "city": getCity,
                "state": getState,
                "country": getCountry,
                "lat": getLat,
                "longitude": getLng,
                "zipcode": getZIP_Code,
            };
            return updatedInfo;
        });

    }

    const options = [
        { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' },
    ];

    const handleAddRoom = () => {
        setLocation_info((pre) => [
            ...pre,
            {
                id: pre.length + 1,
                "address": "",
                "capacity": "",
                "city": "",
                "country": "",
                "is_address_same": "Yes",
                "lat": "",
                "location_id": null,
                "location_name": "",
                "location_url": "",
                "longitude": "",
                "online_class_link": "",
                "state": "",
                "zipcode": "",
            }
        ]);
    };

    const handleDeleteRoom = (i) => {
        setLocation_info((pre) => {
            const updatedArray = [...pre];
            updatedArray.splice(i, 1);
            return updatedArray;
        });
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        const finalData = {
            "user_id": bzDetail.user_id,
            "business_id": bzDetail.business_id,
            "business_address": bzDetail.address,
            "latitude": bzDetail.latitude,
            "longitude": bzDetail.longitude,
            "location_info": location_info
        }
        setLoder(true);
        const resp = await business_location_update_NEW(finalData);
        if (resp) {
            setLoder(false);
            getData();
            Swal.fire({
                html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
            <h4 class="title text-uppercase text-center mt-4 textWhite">
            SUCCESS
          </h4>
          <p class="text-center my-4 textcolor">
          <small class="textWhite">
          ${resp.data.message} </small></p>
          `,
                showClass: {
                    popup: 'custom-dialog-container',
                },
                customClass: {
                    confirmButton: 'custom-button custom-button-size modalbtn',
                },
            });
            setLoder(false);
        }
        setLoder(false);
    }

    const inputHandel = (e, i) => {
        const { name, value } = e.target;
        const updatedInfo = [...location_info];
        updatedInfo[i] = { ...updatedInfo[i], [name]: value };
        if ("is_address_same" == name && value == 'Yes') {
            updatedInfo[i] = { ...updatedInfo[i], ["lat"]: bzDetail.latitude };
            updatedInfo[i] = { ...updatedInfo[i], ['longitude']: bzDetail.longitude };
            updatedInfo[i] = { ...updatedInfo[i], ['address']: bzDetail.address };
            updatedInfo[i] = { ...updatedInfo[i], ['city']: "" };
            updatedInfo[i] = { ...updatedInfo[i], ['state']: "" };
            updatedInfo[i] = { ...updatedInfo[i], ['country']: "" };
        }
        setLocation_info(updatedInfo);
    };

    useEffect(() => {
        getData();
    }, [business_id]);

    return (
        <>
            <Form onSubmit={submitHandler}>
                <Row>
                    <Col>
                        <div className="RightSide">
                            <h6>ROOMS FOR YOUR ACTIVITIES</h6>
                            <Row>
                                <Col md={12} className='mb-3'>
                                    <div className="mb-3">
                                        <label className="form-label inputLable">Head Quarters Address</label>
                                        <textarea
                                            className="form-control"
                                            name='business_address'
                                            rows="2"
                                            readOnly
                                            value={bzDetail.address}
                                        />
                                    </div>
                                </Col>
                                <Col md={12} className='mt-2 mb-2'> <hr /></Col>
                                <Col className='mt-4'>
                                    {location_info.map((room, i) => (
                                        <>
                                            <Row className="AddRoomRow" key={i}>
                                                <Col md={12}>
                                                    <Row style={{ display: 'flex', justifyContent: 'end' }}>
                                                        <Col md={2} style={{ textAlign: "center" }}>
                                                            <Row>
                                                                {(room.location_id == null || room.location_id == "") && (
                                                                    <Col md={6}>
                                                                        <SharedButton type={"button"} className={"DeletedIcon"} iconNode={<AiFillMinusCircle />} onClick={() => handleDeleteRoom(i)} />
                                                                    </Col>
                                                                )
                                                                }
                                                                {location_info.length == (i + 1) &&
                                                                    <Col className={(room.location_id == null || room.location_id == "" ? "md-6" : "md-12")}>
                                                                        <SharedButton type={"button"} onClick={handleAddRoom} iconNode={<AiFillPlusCircle />} className={"AddPlusIcon"} />
                                                                    </Col>
                                                                }
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col md={12}>
                                                    <Row>
                                                        <Col md={6} className='mb-2'>
                                                            <InputField lable="Room Name" name="location_name" onChange={(e) => inputHandel(e, i)} value={room.location_name} type="text" required />
                                                        </Col>
                                                        <Col md={6} className='mb-2'>
                                                            <InputField lable="Map Link" name="location_url" onChange={(e) => inputHandel(e, i)} value={room.location_url} type="text" />
                                                        </Col>

                                                        <Col md={6} className='mb-2'>
                                                            <InputField lable="Capacity" name="capacity" onChange={(e) => inputHandel(e, i)} value={room.capacity} type="number" required />
                                                        </Col>
                                                        <Col md={6} className='mb-2'>
                                                            <SharedSelect className={"form-control"} lable="Address (Head Quarters)" name="is_address_same" onChange={(e) => inputHandel(e, i)} value={room.is_address_same} options={options} />
                                                        </Col>
                                                        <Col md={12} className='mb-2'>
                                                            <InputField lable="Online Class LInk" name="online_class_link" onChange={(e) => inputHandel(e, i)} value={room.online_class_link} placeholder="" type="text" />
                                                        </Col>
                                                        {room.is_address_same === "No" &&
                                                            <Col md={12} className='mb-2'>
                                                                <PlaceAutoCompleted
                                                                    myClassName={"form-control"}
                                                                    label={'Address'}
                                                                    value={room.address}
                                                                    setCordinates={(e) => locationHandler(e, i)}
                                                                />
                                                            </Col>
                                                        }
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Col md={12} className='mt-2 mb-2'> <hr /></Col>
                                        </>
                                    ))}
                                </Col>
                            </Row>
                            <Row className='mt-4'>
                                <Col md={6} className='mt-4'>
                                    <SharedButton title={"Save & Continue"} type={"submit"} className={"btn btn-primary w-100"} />
                                </Col>
                                <Col md={6} className='mt-4'>
                                    <SharedButton title={"Done With Room"} type={"button"} onClick={() => navigate('/add-items')} className={"btn btn-outline w-100"} />
                                </Col>
                                <Col></Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Form>
        </>
    );
}
