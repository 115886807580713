import React, { useState } from 'react'
import { LiveSearch } from '../../../../../sharedComponents/LiveSearch'
import { Container, Row, Col } from 'react-bootstrap'
import Sidebar from '../../comman_page/Sidebar'
import { HeaderDashboard } from '../../comman_page/HeaderDashboard'
import { DataTable } from '../../../../../sharedComponents/DataTable'
import { Footer } from '../../../../../public/containers/footer/Footer'
import { search_customer_list } from '../../../../../Studio-Services/Studio_Services'
import { Link } from 'react-router-dom'

// import SearchSelect from "./SearchSelect";

export const MakeApurchase = () => {

  const [tableData, setTableData] = useState([]);

  const columns = [
    { dataField: "Name", text: "Name" },
    { dataField: "Gender", text: "Gender" },
    { dataField: "Email", text: "Email" },
    { dataField: "Phone", text: "Phone Number" },
    { dataField: "Action", text: "Action" },
  ];



  const searchHandler = async (data) => {
    const td = [];
    const fdata = {
      "pageid": "1",
      "search_val": data
    }

    const resp = await search_customer_list(fdata);
    if (resp) {
      localStorage.setItem('st_customerArrayDetail',JSON.stringify(resp.data.data));
      (resp.data.data).map((e) => {
        td.push({
          Name: (<><div className='row'> <div className='col-2'><img  src={e.profile_img} alt='' className='img-fluid customimgcircle'/></div><div className='col-10'><p className='title ml-2 font-w-500 font-14 mb-0'>{e.name}</p></div></div> </>),
          Gender: e.gender, 
          Email: e.email,
          Phone: e.mobile, 
          Action: <Link to="/purchase-product" state={{data:e}}>Select</Link>
        })
      })
      setTableData(td);
    }else{
      setTableData([]);
    }
  }


  return (
    <>
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col>
              <div className="RightSide">
                <HeaderDashboard />
                <section className="addClient innerContent">
                  <div className="AddClientContent">
                    <Container>
                      <h4>SEARCH CLIENT</h4>
                      <Row>
                        <Col>
                          <LiveSearch className={"SearchClientPanel"} onChange={(e) => searchHandler(e.target.value)} Placeholder={"Search Client"} />
                        </Col>
                        <Col>
                          
                        </Col>
                      </Row>

                      <Row className="mt-3">
                        <Col>
                          <DataTable data={tableData} columns={columns} />
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </section>
                <Footer />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}
