import React from 'react'
import { InnerHeader } from '../../Components/inner-header/InnerHeader1'
import { SubHeader } from '../../Components/sub-header/SubHeader'
import { Innerfooter } from '../../Components/inner-footer/Innerfooter1'
import { DashboardToSectionDetails } from '../../Components/CommanPages/DashboardToSectionDetails'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useEffect } from 'react'
import { API_cardGet, API_clover_buy_now_services_single, API_service_appointment_cancel, ExpiryCheck, Instructor_clover_buy_now_services_single, ageCalculation, buy_now_services_cash, client_my_book_services_list, convertDate, convertTime, staff_my_book_services_list, staff_send_appointment_mail, staff_service_appointment_cancel, staff_service_status_change } from '../../../Static_Services/Staff_Services'
import { errorAlert, successAlert } from '../../../Static_Services/Alertmsg'
import moment from 'moment'
import { Col, Row, Spinner } from 'react-bootstrap'
import { cardGet } from '../../../Studio-Services/Studio_Services'
import { InputField } from '../../../sharedComponents/InputField'

export const StaffMyAppointmentBookingDetail = () => {
    const location = useLocation();
    const [booking_Detail, setBooking_Detail] = useState('');
    const navigate = useNavigate();

    const [loder, setLoder] = useState(false);
    const [backUrl, setBackUrl] = useState();
    const [showPayment, setShowPayment] = useState(true);
    const [paymentMode, setPaymentMode] = useState();
    const [cardval, setCardval] = useState();
    const [cardType, setCardType] = useState();
    const [cardCheckedId, setCardCheckedId] = useState();
    const [tipAmount, setTipAmount] = useState();
    const [customerData, setCustomerData] = useState([]);
    const [userInfo, setUserInfo] = useState();
    const [businessDetail, setBusinessDetail] = useState();
    const [savecard, setSavecard] = useState(0);




    const [refData, setRefData] = useState({ referenceid: '', payment_note: '' })
    const [referror, setReferror] = useState({ payment_note: '', referenceid: '' })


    useEffect(() => {
        if (localStorage.getItem('w_loginDetail')) {
            setUserInfo(JSON.parse(localStorage.getItem('w_loginDetail')));
        }
        if (localStorage.getItem('w_BusinessDetail')) {
            setBusinessDetail(JSON.parse(localStorage.getItem('w_BusinessDetail')));
        }
        if (localStorage.getItem('w_AppointUrl')) {
            setBackUrl(localStorage.getItem('w_AppointUrl'));
        }
        if (localStorage.getItem('w_Booking_detail')) {
            setBooking_Detail(JSON.parse(localStorage.getItem('w_Booking_detail')));
        }
        if (localStorage.getItem('bzDetails')) {
            setBusinessDetail(JSON.parse(localStorage.getItem('bzDetails')));
        }
    }, [])


    const getDetails = async () => {
        setLoder(true);
        let resp = '';
        let detail = { "pageid": "1", "transaction_id": booking_Detail.transaction_id };
        if (backUrl == '/appointment' || backUrl == '/my-appointment') {
            resp = await staff_my_book_services_list(detail);
        } else {
            resp = await client_my_book_services_list(detail);
        }
        if (resp) {
            let response = resp.data;
            response.data[0].start_date_utc_new = moment.unix(response.data[0].shift_date).format('MMM DD YYYY');
            response.data[0].start_time_new = moment.unix(response.data[0].start_time).format('hh mm A');
            response.data[0].end_time_new = moment.unix(response.data[0].end_time).format('hh mm A');
            response.data[0].age = ageCalculation(response.data[0].date_of_birth);
            response.data[0].Name = response.data[0].name + ' ' + response.data[0].lastname;
            if (response.data[0].family_user_id != 0) {
                alert('hello')
                response.data[0].age = ageCalculation(response.data[0].family_dob);
                response.data[0].Name = response.data[0].family_member_name;
                response.data[0].gender = response.data[0].family_gender;
                response.data[0].profile_img = response.data[0].family_profile_img;
            }
            setBooking_Detail(response.data[0]);
            localStorage.setItem('w_Booking_detail', JSON.stringify(response.data[0]));
            setLoder(false);
        } else {
            setLoder(false);
        }
    }

    const ChnageBooking = async () => {
        setLoder(true);
        let detail = { 'transaction_id': booking_Detail.transaction_id, 'business_id': businessDetail.business_id };
        const resp = await staff_service_status_change(detail);
        if (resp) {
            successAlert(resp.data.message)
            getDetails();
        } else {
            setLoder(false);
        }
    }

    const canclebooking = async () => {
        setLoder(true);
        let resp = '';
        const detail = { "transaction_id": booking_Detail.transaction_id };
        if (backUrl == '/appointment' || backUrl == '/my-appointment') {
            resp = await API_service_appointment_cancel(detail);
        } else {
            resp = await staff_service_appointment_cancel(detail);
        }
        if (resp) {
            successAlert(resp.data.message);
            getDetails();
            setLoder(false);
        } else {
            setLoder(false);
        }
    }

    const redirect = () => {
        navigate(backUrl);
    }

    const getpaymentMode = (event) => {
        setPaymentMode(event.target.id);
        setCardval(event.target.value)
    }

    const RefrenceHandler = (e) => {
        const { name, value } = e.target;
        setRefData((pre) => ({ ...pre, [name]: value }));
        setReferror((pre) => ({ ...pre, [name]: '' }));
    }

    const PayAtStudio = async () => {
        let isValid = 1;

        if (!refData.referenceid) { setReferror((pre) => ({ ...pre, referenceid: '*Required Transaction Number' })); isValid = 2; }
        if (!refData.payment_note) { setReferror((pre) => ({ ...pre, payment_note: '*Required' })); isValid = 2; }

        if (isValid === 1) {
            setLoder(true);
            const detail = {
                'service_id': booking_Detail.service_id,
                'payment_transaction_id': refData.referenceid,
                'comment': refData.payment_note,
                'transaction_id': booking_Detail.transaction_id,
                'amount': booking_Detail.sub_total,
                'tip_comment': tipAmount
            };
            const resp = await buy_now_services_cash(detail);
            if (resp) {
                successAlert(resp.data.message);
                setRefData({ referenceid: '', payment_note: '' });
                setLoder(false);
                setPaymentMode(2);
                setShowPayment(true);

                getDetails();
            } else {
                setLoder(false);
            }
            setLoder(false);
        } else {
            setLoder(false);
            return false;
        }
    }



    const savePayment = async (card) => {
        setLoder(true);
        let resp = '';
        const detail = {
            'tip_comment': tipAmount,
            "service_id": booking_Detail.service_id,
            "token": card.card_token,
            "savecard": "0",
            "transaction_id": booking_Detail.transaction_id,
            "card_id": card.card_id,
            "business_id": businessDetail.business_id,
            "country_code": "",
        }
        if (backUrl == '/appointment' || backUrl == '/my-appointment') {
            resp = await Instructor_clover_buy_now_services_single(detail);
            if (resp) {
                const response = resp.data;
                successAlert(response.message);
                setLoder(false);
                setPaymentMode(2);
                setShowPayment(true);
                getDetails();
            } else {
                setLoder(false);
            }
        } else {
            resp = await API_clover_buy_now_services_single(detail);
            if (resp) {
                let response = resp.data;
                successAlert(response.message);
                setLoder(false);
                setPaymentMode(2);
                setShowPayment(true);
                getDetails();
            } else {
                setLoder(false);
            }
        }
    }




    const getCardType = (event) => {
        setCardType(event.target.id);
        let crData = customerData;
        customerData.map((el) => {
            if (el.card_id == cardType) {
                el.checked = event.target.checked;
            }
        })
        setCustomerData(crData);
    }

    const sendEmail = async () => {
        const detail = { 'transaction_id': booking_Detail.transaction_id };
        const resp = await staff_send_appointment_mail(detail);
        if (resp) {
            successAlert(resp.data.message)
        }
    }

    const getSaveCardDetail = async () => {
        setLoder(true);
        const detail = { "userid": userInfo.id };
        const resp = await API_cardGet(detail);
        if (resp) {
            let response = resp.data;
            response.data.map((el) => {
                el.checked = true;
            })
            setCustomerData(response.data);
            setLoder(false);
        } else {
            setLoder(false);
        }
    }

    const SavecardHandeler = (event) => {
        if (event.target.checked == true) {
            setSavecard(1);
        }
        else { setSavecard(0); }
    }

    const passDateValidation = (detail) => {
        if (detail && detail.booking_status == 'Confirm') {
            const sel = convertDate(detail.shift_date, 2);
            const sTime = convertTime(detail.end_time);
            const timeCal = sel + ' ' + sTime;
            const currentDate = moment().format('MM/DD/YYYY h:mm A');
            const date1 = new Date(currentDate);
            const date2 = new Date(timeCal);
            if (date1 > date2) {
                return false;
            }
        }
        return true;
    }

    // ***********  PayMent  *********** //

    const tiphandleInputChange = (e) => {
        const cleanedValue = e.target.value.replace(/^\s+/g, '');
        if (/^\d{0,3}$/.test(cleanedValue)) {
            setTipAmount(cleanedValue);
        }
    }
    const [expiryDate, setExpiryDate] = useState();
    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        if (e.nativeEvent.inputType === "deleteContentBackward") {
            if (inputValue.endsWith('/')) {
                setExpiryDate(inputValue.slice(0, -1));
            } else {
                setExpiryDate(inputValue);
            }
        } else {
            if (inputValue.length === 2 && inputValue.charAt(2) !== '/') {
                setExpiryDate(inputValue + '/');
            } else if (inputValue.length === 8) {
                return;
            } else {
                setExpiryDate(inputValue);
            }
        }
    };

    const [carderror, setCarderror] = useState({ name: "", crnumber: "", dates: "", cvv: "" });
    const [cardData, setCardData] = useState({ name: "", crnumber: "", dates: "", cvv: "" });
    const CardInputChange = (e) => {
        const { name, value } = e.target;
        const cleanedValue = value.replace(/^\s+/g, '');
        setCardData((pre) => ({ ...pre, [name]: cleanedValue }));
        setCarderror((pre) => ({ ...pre, [name]: "" }));
    }

    const Payment = async () => {
        let isValid = 1;
        let expiry_month = '';
        let expiry_year = '';
        let expired ='';

        if (expiryDate) {
            expired = ExpiryCheck(expiryDate);
        }
        if (expired) {
            expiry_month = expired[0];
            expiry_year = expired[1];
        } else {
            setCarderror((pre) => ({ ...pre, dates: "* Valid Till is required" }));
            isValid = 2;
        }

        if (!cardData.name) { setCarderror((pre) => ({ ...pre, name: "* Card Holder Name is required" })); isValid = 3; };
        if (!cardData.crnumber) {
            setCarderror((pre) => ({ ...pre, crnumber: "* Card Number is required" }))
            isValid = 4;
        };
       
        if (!cardData.cvv) {
            setCarderror((pre) => ({ ...pre, cvv: "* CVV is required" }));
            isValid = 5;
        };
      
        if (isValid === 1) {
            setLoder(true);
            let detail = {
                "tip_comment": tipAmount,
                "service_id": booking_Detail.service_id,
                "token": '',
                "savecard": savecard,
                "card_id": "",
                "transaction_id": booking_Detail.transaction_id,
                "number": cardData.card_number,
                "expiry_month": expiry_month,
                "expiry_year": expiry_year,
                "cvd": cardData.cvv_no,
                "business_id": businessDetail.business_id,
                "country_code": "",
                "customer_name": cardData.name,
            }
            if (backUrl == '/appointment' || backUrl == '/my-appointment') {
                const resp = await Instructor_clover_buy_now_services_single(detail);
                if (resp) {
                    let response = resp.data;
                    successAlert(response.message);
                    setLoder(false);
                    setPaymentMode(2);
                    getDetails();
                    setCardData({ name: "", crnumber: "", dates: "", cvv: "" });
                } else {
                    setLoder(false);
                }
            } else {
                const resps = await API_clover_buy_now_services_single();
                if (resps) {
                    let response = resps.data;
                    successAlert(response.message);
                    setLoder(false);
                    setPaymentMode(2);
                    setShowPayment(true);
                    getDetails();
                    setCardData({ name: "", crnumber: "", dates: "", cvv: "" });
                } else {
                    setLoder(false);
                }
            }

        } else {
            setLoder(false);
            return false;
        }
    }




    useEffect(() => {
        if (userInfo && backUrl != '/appointment' && backUrl != '/my-appointment') {
            getSaveCardDetail();
        }
    }, [userInfo])

    return (
        <>

            {loder ? <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div> : ""}
            <InnerHeader />

            <div className="body-grey">
                <div className="studio-details">
                    <div className="container">
                        <DashboardToSectionDetails />
                        <div className="s-d-appoint pb-5">
                            <div className="row mb-3">
                                <div className="col-6 text-left">
                                    <h4 className="title brand-color2"> Appointment Scheduled </h4>
                                </div>
                                <div className="col-6 text-right ">
                                    {(backUrl == '/appointment' || backUrl == '/my-appointment') && (booking_Detail && booking_Detail.booking_status != 'Conf/irm') &&
                                        <button type="button" className="btn btn-blue-grad px-2 text-uppercase mr-2"
                                            onClick={() => sendEmail()}>Email Receipt</button>}
                                    {backUrl != '/booking-payment' &&
                                        <button type="button" className="btn btn-blue-grad px-2 text-uppercase"
                                            onClick={() => window.history.back()}>Back </button>}
                                </div>
                            </div>

                            <div className="s-d-appoint-block">
                                <div className="row">
                                    <div className="col-12 col-lg-6 mt-3 mt-lg-0">
                                        <div className="s-d-appoint-block-left border-radius6 box-shadow1 white-bg">

                                            <div className="px-3 pt-3 border-bottom">
                                                <div className="row">
                                                    <div className="col-8 col-md-6">
                                                        <h5 className="title black-color font-w-100 text-uppercase font-16 mb-3 pb-2"> customer details </h5>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6 col-md-5 text-left mb-2">
                                                        <p className="black-color font-w-100 font-14 mb-0"> Name </p>
                                                        <p className="black-color font-w-100 font-18"> {booking_Detail && booking_Detail.Name}</p>
                                                    </div>

                                                    <div className="col-6 col-md-3 text-left mb-2 pl-2 pl-md-0">
                                                        <p className="black-color font-w-100 font-14 mb-0"> Gender </p>
                                                        <p className="black-color font-w-100 font-18"> {booking_Detail && booking_Detail.gender} </p>
                                                    </div>

                                                    <div className="col-6 col-md-3 text-left mb-2">
                                                        <p className="black-color font-w-100 font-14 mb-0"> Age </p>
                                                        <p className="black-color font-w-100 font-18"> {booking_Detail && booking_Detail.age} years </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="px-3 pt-3 border-bottom">
                                                <div className="row">
                                                    <div className="col-8 col-md-6">
                                                        <h5 className="title black-color font-w-100 text-uppercase font-16 mb-3 pb-2"> Appointments details
                                                        </h5>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6 text-left mb-2">
                                                        <p className="black-color font-w-100 font-14 mb-0"> Instructor </p>
                                                        <p className="black-color font-w-100 font-18">{booking_Detail && booking_Detail.instructor_name}
                                                            {booking_Detail && booking_Detail.instructor_lastname} </p>
                                                    </div>

                                                    <div className="col-6 text-left mb-2">
                                                        <p className="black-color font-w-100 font-14 mb-0"> Duration </p>
                                                        <p className="black-color font-w-100 font-18">{booking_Detail && booking_Detail.duration} Min. </p>
                                                    </div>

                                                    <div className="col-6 text-left mb-2">
                                                        <p className="black-color font-w-100 font-14 mb-0"> Time </p>
                                                        <p className="black-color font-w-100 font-18"> {booking_Detail && booking_Detail.start_time_new} -
                                                            {booking_Detail && booking_Detail.end_time_new} </p>
                                                    </div>

                                                    <div className="col-6 text-left mb-2">
                                                        <p className="black-color font-w-100 font-14 mb-0"> Date </p>
                                                        <p className="black-color font-w-100 font-18">{booking_Detail && booking_Detail.start_date_utc_new} </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="px-3 pt-3 border-bottom">
                                                <div className="row">
                                                    <div className="col-12 text-left mb-2">
                                                        <h5 className="title black-color font-w-100 text-uppercase font-16 mb-3 pb-2"> service details </h5>
                                                        <p className="black-color font-w-100 font-14 mb-0"> Service Name </p>
                                                        <p className="black-color font-w-100 font-18"> {booking_Detail && booking_Detail.service_name} </p>
                                                    </div>
                                                    <div className="col-12 text-left mb-2">
                                                        <p className="black-color font-w-100 font-14 mb-0"> Location </p>
                                                        {booking_Detail && booking_Detail.location_url &&
                                                            <p className="black-color font-w-100 font-18">
                                                                <Link to={booking_Detail && booking_Detail.location_url} target="_blank"
                                                                    title={booking_Detail && booking_Detail.location_url}>{booking_Detail && booking_Detail.location_name},
                                                                    {booking_Detail && booking_Detail.location_address} </Link></p>}
                                                        {booking_Detail && !booking_Detail.location_url &&
                                                            <p className="black-color font-w-100 font-18" >
                                                                {booking_Detail && booking_Detail.location_name}, {booking_Detail && booking_Detail.location_address} </p>}
                                                        <p className="black-color font-w-100 font-14 mb-0"> Web Link </p>
                                                        {booking_Detail && booking_Detail.web_link &&
                                                            <p className="black-color font-w-100 font-18">
                                                                <Link to={booking_Detail && booking_Detail.web_link} target="_blank"><i className="fa fa-link" aria-hidden="true"
                                                                    title="{booking_Detail && booking_Detail.web_link}"></i>
                                                                </Link></p>}
                                                        {booking_Detail && !booking_Detail.web_link &&
                                                            <p className="black-color font-w-100 font-18"> N/A</p>}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-lg-6 mt-5 mt-lg-0">
                                        <div className="s-d-appoint-block-right border-radius6 box-shadow1 white-bg">
                                            <div className="px-3 py-3 text-left light-bg border-t-lr-6">
                                                <h5 className="title black-color font-w-100 text-uppercase font-16 mb-0"> payment details </h5>
                                            </div>

                                            <div className="px-3 pt-4 pb-2 border-bottom">
                                                <div className="row mb-2">
                                                    <div className="col-6 text-left">
                                                        <p className="black-color font-w-100 font-18"> Appointment Fees: </p>
                                                    </div>
                                                    <div className="col-6 text-right">
                                                        <p className="black-color font-w-100 font-18">
                                                            {businessDetail && businessDetail.currency}{booking_Detail && booking_Detail.service_amount} </p>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-6 text-left">
                                                        <p className="black-color font-w-100 font-18"> Tax 1 </p>
                                                    </div>
                                                    <div className="col-6 text-right">
                                                        <p className="black-color font-w-100 font-18"> {businessDetail && businessDetail.currency}{booking_Detail && booking_Detail.tax1_rate}
                                                        </p>
                                                    </div>
                                                    <div className="col-6 text-left">
                                                        <p className="black-color font-w-100 font-18"> Tax 2 </p>
                                                    </div>
                                                    <div className="col-6 text-right">
                                                        <p className="black-color font-w-100 font-18"> {businessDetail && businessDetail.currency}{booking_Detail && booking_Detail.tax2_rate}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="px-3 pt-4 pb-3 border-bottom">
                                                <div className="row mb-2">
                                                    <div className="col-6 text-left">
                                                        {booking_Detail && booking_Detail.booking_status != 'Success' &&
                                                            <p className="black-color font-w-100 font-18 mb-0">
                                                                Amount Payable </p>}
                                                        {booking_Detail && booking_Detail.booking_status == 'Success' &&
                                                            <p className="black-color font-w-100 font-18 mb-0">
                                                                Amount Paid </p>}

                                                    </div>
                                                    <div className="col-6 text-right">
                                                        <p className="black-color font-w-100 font-18 mb-0">
                                                            {businessDetail && businessDetail.currency}{booking_Detail && booking_Detail.service_total_price}</p>

                                                    </div>
                                                </div>
                                            </div>
                                            {booking_Detail && booking_Detail.booking_status == 'Success' &&
                                                <div className="px-3 pt-4 pb-3 border-bottom">
                                                    <div className="row mb-2">
                                                        <div className="col-6 text-left">
                                                            <p className="black-color font-w-100 font-18 mb-0"> Type Of Payment </p>
                                                        </div>
                                                        <div className="col-6 text-right">
                                                            <p className="black-color font-w-100 font-18 mb-0">{booking_Detail && booking_Detail.payment_type}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            <div className="px-3 py-4 text-center">
                                                {(backUrl == '/appointment' || backUrl == '/my-appointment') && booking_Detail.booking_status == 'Confirm' &&
                                                    <button type="button" className="btn btn-blue-grad w-75 text-uppercase mb-4"
                                                        onClick={() => ChnageBooking()}  >
                                                        Mark as complete </button>}
                                                {passDateValidation(booking_Detail) &&
                                                    <ng-container >
                                                        {booking_Detail && booking_Detail.booking_status == 'Confirm' &&
                                                            <button type="button" className="btn btn-red-grad w-75 text-uppercase mb-4" style={{ color: "red" }} onClick={() => canclebooking()} > Cancel Booking</button>}
                                                    </ng-container>
                                                }
                                                {(backUrl == '/appointment' || backUrl == '/my-appointment') && booking_Detail.booking_status == 'Completed' &&
                                                    <button type="button" className="btn btn-blue-grad w-75 text-uppercase mb-4"
                                                        onClick={() => setShowPayment(false)}>  Pay Now </button>}
                                                {(backUrl == '/appointments') && booking_Detail.booking_status == 'Completed' &&
                                                    <button type="button" className="btn btn-blue-grad w-75 text-uppercase mb-4"
                                                        onClick={() => setPaymentMode(3)}> Pay Now
                                                    </button>}
                                                {booking_Detail && booking_Detail.booking_status == 'Success' &&
                                                    <button type="button" className="btn btn-blue-grad w-75 text-uppercase mb-4"> Booking Completed </button>}
                                                {booking_Detail && booking_Detail.booking_status == 'Cancel' &&
                                                    <button type="button" className="btn btn-blue-grad w-75 text-uppercase mb-4" > Cancelled </button>}
                                            </div>
                                            {!showPayment &&
                                                <div className="mt-2 ml-3 pb-2" >
                                                    <p className="brand-color3 font-14 mb-2">Payment Mode</p>
                                                    <div className="d-flex flex-wrap">
                                                        <div className="radiotype-btn">
                                                            <input type="radio" className="customradio" id="1" value="cash" name="paymentMethod"
                                                                checked={paymentMode == 'cash'}
                                                                onChange={(e) => getpaymentMode(e)}
                                                            />
                                                            <label>Cash/Check</label>
                                                        </div>
                                                        {businessDetail && businessDetail.isCloverPurchase &&
                                                            <div className="radiotype-btn ml-3" >
                                                                <input type="radio" className="customradio" id="3" value="card" name="paymentMethod"
                                                                    checked={paymentMode == 'card'}
                                                                    onChange={(e) => getpaymentMode(e)}
                                                                />
                                                                <label>Card</label>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                            {paymentMode == '3' &&
                                                <div className="card-details">
                                                    <div className="client-payment-input border-radius6 box-shadow1 white-bg p-4">
                                                        <div className="row">
                                                            {customerData.length > 0 && customerData.map((cardsave) => (
                                                                <div className="col-12 mb-3" >
                                                                    {cardsave.length != 0 &&
                                                                        <div className="client-card-img white-bg border-radius6 box-shadow1">
                                                                            <label className="select-card select-card-img select-card-cvv mb-2">
                                                                                {cardsave.card_type == 'VISA' || cardsave.card_type == 'visa' &&
                                                                                    <img src="clientassets/img/visa_card.png"
                                                                                        className="img-fluid payment_img" />}
                                                                                {cardsave.card_type == 'MC' &&
                                                                                    <img src="clientassets/img/master_card.png" className="img-fluid payment_img"
                                                                                    />}
                                                                                {cardsave.card_type == 'AM' &&
                                                                                    <img src="clientassets/img/american_express.png" className="img-fluid payment_img"
                                                                                    />}

                                                                                <p className="mb-0 font-12 dark-color mt-2">{cardsave.number} </p>
                                                                                <h5 className="mb-0 font-12 dark-color mt-2">Valid Till(MM/YYYY) </h5>
                                                                                <p className="mb-0 font-12 dark-color mt-2">{cardsave.expiry_month} / {cardsave.expiry_year}
                                                                                </p>
                                                                                <input type="radio" name="radio1"
                                                                                    checked={cardType == cardsave.card_id}
                                                                                    id="cardsave.card_id"
                                                                                    value={cardsave.card_id}
                                                                                    onChange={(e) => getCardType(e)}
                                                                                />
                                                                                <span className="checkmark"></span>
                                                                            </label>
                                                                            {cardType == cardsave.card_id &&
                                                                                <form>
                                                                                    <div className="text-center d-flex ml-2">
                                                                                        {booking_Detail && booking_Detail.tip_option == 'yes' &&
                                                                                            <div className="form-group client-buy-input-cardno"  >
                                                                                                <p className="text-left font-12 dark-color"> Tip </p>
                                                                                                <input type="number" autocomplete="off" className="form-control"
                                                                                                    onkeyup="this.value=this.value.replace(/^\s+/g, '')"
                                                                                                    //    [(ngModel)]="TipAmount"
                                                                                                    //   [ngModelOptions]="{standalone: true}"
                                                                                                    onKeyPress="if(this.value.length >= 3) return false;"
                                                                                                />
                                                                                            </div>
                                                                                        }
                                                                                        <button type="button" className="btn btn-blue-grad px-4 w-50 text-uppercase mb-0 mb-md-3 ml-5"
                                                                                            onClick={() => savePayment(cardsave)}
                                                                                        > Proceed to pay </button>
                                                                                    </div>
                                                                                </form>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            ))}
                                                            <div className="col-12 mb-3">
                                                                <div className="white-bg border-radius6 box-shadow1">
                                                                    <label className="select-card"> New Card
                                                                        <input type="radio" checked={cardType == 'new'} name="radio1" id="new" value="new"
                                                                            onChange={(e) => getCardType(e)}
                                                                        />
                                                                        <span className="checkmark"></span>
                                                                    </label>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    {cardType == 'new' &&
                                                        <form>
                                                            <div className="row py-4 px-3">
                                                                <div className="col-12 col-sm-6">
                                                                    <div className="form-group">
                                                                        <p className="brand-color3 text-left font-14 mb-2"> Name On Card </p>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            autoComplete="off"
                                                                            name='name'
                                                                            placeholder="Sophie Joe"
                                                                            value={cardData.name}
                                                                            onChange={CardInputChange}
                                                                        />
                                                                        <small className="text-danger">{carderror.name}</small>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 col-sm-6">
                                                                    <div className="form-group client-buy-input-cardno">
                                                                        <p className="brand-color3 text-left font-14 mb-2"> Card Number </p>
                                                                        <input
                                                                            type="text"
                                                                            autoComplete="off"
                                                                            className="form-control"
                                                                            placeholder="4121 4411 3131 8554"
                                                                            name='crnumber'
                                                                            value={cardData.crnumber}
                                                                            onChange={CardInputChange}
                                                                            maxLength={19} 
                                                                        />
                                                                        <small className="text-danger">{carderror.crnumber}</small>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                                                    <div className="form-group">
                                                                        <p className="brand-color3 text-left font-14 mb-2"> Valid Till </p>
                                                                        <input
                                                                            type="text"
                                                                            name={'dates'}
                                                                            className="form-control"
                                                                            placeholder="MM/YYYY"
                                                                            value={expiryDate}
                                                                            onChange={handleInputChange}
                                                                            pattern="^[0-9]{2}/[0-9]{4}"
                                                                            autoComplete="new-password"
                                                                            lable={"Valid Through"}
                                                                        />
                                                                        <small className="text-danger">{carderror.dates}</small>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                                                    <div className="form-group">
                                                                        <p className="brand-color3 text-left font-14 mb-2"> CVV </p>
                                                                        <input
                                                                            type="password"
                                                                            autoComplete="off"
                                                                            className="form-control"
                                                                            placeholder="eg. 201"
                                                                            name='cvv'
                                                                            value={cardData.cvv}
                                                                            onChange={CardInputChange}
                                                                            maxLength={3} 
                                                                        />
                                                                        <small className="text-danger">{carderror.cvv}</small>
                                                                    </div>
                                                                </div>
                                                                {booking_Detail.tip_option == 'yes' &&
                                                                    <div className="col-12 col-sm-6" >
                                                                        <div className="form-group client-buy-input-cardno">
                                                                            <p className="brand-color3 text-left font-14 mb-2"> Tip </p>
                                                                            <input
                                                                                type="number"
                                                                                autoComplete="off"
                                                                                className="form-control"
                                                                                value={tipAmount}
                                                                                onChange={tiphandleInputChange}
                                                                                min="0"
                                                                                max="999"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {backUrl == '/appointments' &&
                                                                    <div className="col-12" >
                                                                        <input type="checkbox" checked={savecard === 1}
                                                                            onChange={(e) => SavecardHandeler(e)}
                                                                        /> Save Card
                                                                    </div>}
                                                                <div className="w-100 py-5 text-center">
                                                                    <button type="button" className="btn btn-blue-grad px-4 w-50 text-uppercase mb-0 mb-md-5"
                                                                        onClick={() => Payment()}
                                                                    > Proceed to pay </button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    }
                                                </div>
                                            }

                                            {paymentMode == '1' &&
                                                <div className="card-details" >
                                                    <form >
                                                        <div className="d-flex flex-wrap py-3">
                                                            <div className="col-md-12 form-group">
                                                                <label>Transaction Number</label>
                                                                <input type="text" className="form-control" formControlName="referenceid" name='referenceid' onChange={RefrenceHandler}
                                                                />
                                                                <small className='text-danger'>{referror.referenceid}</small>

                                                            </div>
                                                            <div className="col-md-12 form-group">
                                                                <label>Notes</label>
                                                                <input type="text" className="form-control" formControlName="payment_note" name='payment_note' onChange={RefrenceHandler}
                                                                />
                                                                <small className='text-danger'>{referror.payment_note}</small>
                                                            </div>
                                                        </div>
                                                        <div className="px-3 py-5 text-center">
                                                            <button type="button" className="btn btn-blue-grad px-4 w-50 text-uppercase mb-0 mb-md-5"
                                                                onClick={() => PayAtStudio()}
                                                            > Proceed to pay </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div >
            </div >
            <Innerfooter />

        </>
    )
}
