import React from 'react'
import { InputField } from './InputField'
import { SharedButton } from './Button'
import { FaDownload, FaEye } from 'react-icons/fa';
import { Row, Col, Form } from 'react-bootstrap';
import { useState } from 'react';
import logo from "../Images/logo_white_web.png"
import Swal from 'sweetalert2';
import { upload_instructor_csv } from '../Studio-Services/Studio_Services';


export const UploadBulkData = () => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleDownload = () => {
    // Create a sample file URL
    const fileUrl = "https://example.com/sample-file.pdf";

    // Trigger file download
    window.open(fileUrl, "_blank");
  };


  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const submit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target); // Create a FormData object from the form



    if (formData.get("file").size > 0) {
      const finalData = new FormData();
      finalData.append('csv_file', selectedFile);
      finalData.append('type', 1);
      const resp = await upload_instructor_csv(finalData);
      event.target.reset();
    } else {
      Swal.fire({
        html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
    <h4 class="title text-uppercase text-center mt-4 textWhite">
    Error !!!
  </h4>
  <p class="text-center my-4 textcolor">
  <small class="textWhite">
Please Select File </small></p>
  `,
        showClass: {
          popup: 'custom-dialog-container',
        },
        customClass: {
          confirmButton: 'custom-button custom-button-size modalbtn',
        },
      });
    }

  }

  return (
    <>
      <Row>
        <Col>
          <Form autoComplete='off' onSubmit={submit} encType="multipart/form-data">
            <Row>
              <Col>
                <InputField label="Upload CSV" type="file" onChange={handleFileChange} accept=".csv" name="file" />
              </Col>
              <Col>
                <SharedButton type="submit" className={"btn btn-primary mt-4"} title={"Upload"} />
              </Col>
            </Row>
          </Form>
        </Col>
        <Col>
          <Row>
            <Col>
              <a href="assets/instructor_csv_sample.csv" download><SharedButton type={"file"} className={" mt-4"} variant={"Outline"} title={"Sample File Download"} iconNode={<FaDownload />} /></a>
            </Col>
            <Col>
              <SharedButton type={"file"} onClick={handleDownload} className={" mt-4"} variant={"Outline"} title={"View Skills/Specialization"} iconNode={<FaEye />} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className='mt-4'>
        <h6 style={{color:"red",fontWeight: "bold"}}>Note: 1. Skills/Specialisation [Use Colon (:) separator for multiple], Note: 2. Date format [YYYY-MM-DD]</h6>
      </Row>
    </>
  )
}
