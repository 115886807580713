import React from 'react'
import { InnerHeader } from '../../Components/inner-header/InnerHeader1'
import { SubHeader } from '../../Components/sub-header/SubHeader'
import { Innerfooter } from '../../Components/inner-footer/Innerfooter'
import { DashboardToSectionDetails } from '../../Components/CommanPages/DashboardToSectionDetails'
import { useEffect } from 'react'
import { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { API_instructor_list, staff_get_skills } from '../../../Static_Services/Staff_Services'
import { useNavigate } from 'react-router-dom'

export const InstructorAll = () => {
    const [businessDetail, setBusinessDetail] = useState([]);
    const [loder, setLoder] = useState(false);
    const [pagecount, setPagecount] = useState(1);
    const [search_text, setSearch_text] = useState();
    const [staffDetail, setStaffDetail] = useState([]);
    const [skillList, setSkillList] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem('w_BusinessDetail')) {
            setBusinessDetail(JSON.parse(localStorage.getItem('w_BusinessDetail')));
        }
    }, [])


    const getStaffList = async (sert = '') => {
        setLoder(true);
        const detail = { "pageid": pagecount, "business_id": businessDetail.business_id, "search_val": sert }
        const resp = await API_instructor_list(detail);
        if (resp) {
            setLoder(false);
            setStaffDetail(resp.data.data);
        } else {
            setStaffDetail([]);
            setLoder(false);
        }
    }

    const handleInputChange = (event) => {
        const updatedText = event.target.value.replace(/^\s+/g, '');
        setSearch_text(updatedText);
        getStaffList(updatedText);
    };

    const getskills = async () => {
        const resp = await staff_get_skills();
        if (resp) {
            setSkillList(resp.data.data);
        }
    }
    const reset = () => {
        getStaffList();
    }

    const getSerch = () => {
        getStaffList(search_text);
    }

    const redirectUrl = (Id) => {
        navigate('/instructor-detail', { state: { Id: Id } });
    }
    useEffect(() => {
        if (businessDetail) {
            getStaffList();
        }
    }, [businessDetail])
    return (
        <>
            {loder ? <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div> : ""}
            <InnerHeader />
            <div className="body-grey">
                <div className="studio-details client-home-inner">
                    <div className="container">
                        <DashboardToSectionDetails />
                        <div class="home-staff pb-4">
                            <div class="row">
                                <div class="col-12 col-md-7">
                                    <div class="section-heading pt-2 pb-4 text-left">
                                        <a href="Javascript:void(0);" class="black-color font-w-100 text-uppercase font-20"> staff / instructors </a>
                                    </div>
                                </div>
                                {/* <!-- end of col-12 --> */}
                                <div class="col-12 col-sm-9 col-md-4">
                                    <div class="section-search">
                                        <div class="form-group has-search pl-0 pl-md-3" >
                                            <a href="Javascript:void(0);" >
                                                <span class="fas fa-search form-control-feedback" onClick={() => getSerch()}
                                                    style={{ bottom: "7px", top: "unset", pointerEvents: "initial" }}></span>
                                            </a>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search"
                                                value={search_text}
                                                onChange={handleInputChange}
                                            />
                                        </div>

                                    </div>
                                </div>
                                {/* <!-- end of col-6 --> */}
                                <div class="col-12 col-sm-3 col-md-1">
                                    <button type="button" class="btn btn-blue-grad px-2 text-uppercase mb-2 mb-md-5" onClick={() => reset()}
                                    >Reset </button>

                                </div>
                            </div>
                            {/* <!-- end of Heading(staff & instructors) --> */}
                            {staffDetail.length != 0 &&
                                <div>
                                    {staffDetail.map((staffdetail) => (
                                        <div class="home-staff-details white-bg box-shadow1 border-radius6 px-3 py-4 mb-4">
                                            <a href="Javascript:void(0);" onClick={() => redirectUrl(staffdetail.id)} >
                                                <div class="row align-items-start">
                                                    <div class="col-12 col-md-10 d-flex flex-wrap flex-fill align-items-center">
                                                        <div class="home-staff-detail-img box-shadow1">
                                                            <img src={staffdetail.profile_img} class="img-fluid" />
                                                        </div>
                                                        <div class="details pl-3 pt-1">
                                                            <h5 class="heading-titlenew black-color font-w-100 font-16 mb-2"> {staffdetail.name + ' '} {staffdetail.lastname} </h5>
                                                            <p class="black-color paratitle mb-0"> Skills : </p>
                                                            <p class="black-color paratitle mb-0 brand-color3"> {staffdetail.skill} </p>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-2 col-12">
                                                        {staffdetail.availability_status == 'Available' &&
                                                            <h5 class="heading-titlenew mb-2 text-right" style={{ color: "green" }}> {staffdetail.availability_status}</h5>}
                                                        {staffdetail.availability_status == 'Unavailable' &&
                                                            <h5 class="heading-titlenew mb-2 text-right" style={{ color: "red" }}> {staffdetail.availability_status}</h5>}
                                                    </div>
                                                </div>
                                                {/* <!-- end of row -->      */}
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            }
                            {staffDetail.length == 0 &&
                                <div >
                                    <div class="text-center">
                                        <p class="font-awesome black-color font-18 d-inline mx-2 "> No Instructors Available</p>
                                    </div>
                                </div>
                            }
                        </div>
                        {/* <!-- end of home-staff --> */}
                    </div>
                </div>
            </div>
            <Innerfooter />
        </>
    )
}
