import React from "react";
import Sidebar from "../../comman_page/Sidebar";
import { HeaderDashboard } from "../../comman_page/HeaderDashboard";
import { InputField } from "../../../../../sharedComponents/InputField";
import { TextArea } from "../../../../../sharedComponents/TextArea";
import { SharedButton } from "../../../../../sharedComponents/Button";
import { Row, Col, Container, Form } from "react-bootstrap";
import { Footer } from "../../../../../public/containers/footer/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { update_class } from "../../../../../Studio-Services/Studio_Services";
import Swal from "sweetalert2";
import logo from "../../../../../Images/logo_white_web.png"



export const Editclass = () => {
  const location = useLocation();
  const details = location.state.details;
  // const [edescription, setEdescription] = useState();
  const [error, setError] = useState({ "class_name": '', "class_type": '', "duration": '', "description": '' })
  const navigate = useNavigate();

  const submitHandler = async (e) => {
    e.preventDefault();
    let isValid = 1;
    const formData = new FormData(e.target);
    const formValues = Object.fromEntries(formData.entries());

    const class_id = formValues.class_id;
    const class_name = formValues.class_name;
    const class_type = formValues.class_type;
    const duration = formValues.duration;
    const description = formValues.description;
    if (!class_name) { setError((pre) => ({ ...pre, class_name: '*required' })); isValid = 0; }
    if (!class_type) { setError((pre) => ({ ...pre, class_type: '*required' })); isValid = 0; }
    if (!duration) { setError((pre) => ({ ...pre, duration: '*required' })); isValid = 0; }
    if (!description) { setError((pre) => ({ ...pre, description: '*required' })); isValid = 0; }

    if (isValid === 1) {
      const finalData = {
        "class_name": class_name,
        "class_type": class_type,
        "duration": duration,
        "description": description,
        "class_id": class_id
      }
      Swal.fire({
        html: `
        <img src=${logo} class="img-fluid d-block mx-auto mt-5" />
        <h4 class="title text-uppercase text-center mt-4 textWhite">Saving these changes will change details on all past and future records.</h4>
        <p class="text-center my-4 textcolor">
          <small class="textWhite">Are you sure to change status ?</small>
        </p>
      `,
    showClass: {
      popup: "custom-dialog-container",
    },
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText: "YES",
    cancelButtonText: "NO",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const resp = await update_class(finalData);
          if (resp) {
            Swal.fire({
              html: `
            <img src=${logo} class="img-fluid d-block mx-auto mt-5" />
            <h4 class="title text-uppercase text-center mt-4 textWhite">
              SUCCESS
            </h4>
            <p class="text-center my-4 textcolor">
              <small class="textWhite">
                ${resp.data.message}
              </small>
            </p>
          `,
              showClass: {
                popup: 'custom-dialog-container',
              },
              customClass: {
                confirmButton: 'custom-button custom-button-size modalbtn',
              },
            }).then((result) => {
              if (result.isConfirmed) {
                navigate("/list-class");
              }
            });

          }
        }
      })
    }
  }

  return (
    <div className="MainDashboard">
      <div className="MainLayout">
        <Row>
          <Sidebar />
          <Col className="p-0 RightCol">
            <div className="RightSide">
              <HeaderDashboard />
              <div className="innerContent">
                <Container>
                  <h3 className="my-2">Class's Detail</h3>
                  <section className="editClass">
                    <Form onSubmit={submitHandler}>
                      <Row>
                        <InputField name="class_id" value={details.class_id} type={"hidden"} />
                        <Col lg={4} md={4} sm={4}>
                          <InputField lable={"Name"} name="class_name" readOnly={'readOnly'} value={details && details.class_name ? details.class_name : ""} placeholder={"All day open gym"} type={"text"} error={error.class_name} />
                        </Col>
                        <Col lg={4} sm={4}>
                          <InputField lable={"Type"} name="class_type" readOnly={'readOnly'} value={details && details.class_type ? details.class_type : ""} placeholder={"Other"} type={"text"} error={error.class_type} />
                        </Col>
                        <Col lg={4} md={4} sm={4}>
                          <InputField lable={"Duration (In Minutes)"} name="duration" readOnly={'readOnly'} value={details && details.duration ? details.duration : ""} placeholder={"200"} type={"number"} error={error.duration} />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12} sm={12}>
                          <TextArea lable={"Description"} name="description" value={details && details.description ? details.description : ""} error={error.description} />
                        </Col>
                        <Col></Col>
                      </Row>
                      <Row>
                        <Col lg={4} sm={12}>
                          <SharedButton title={"UPDATE"} type={"submit"} variant={"primary"} className={"btn btn-primary w-100"} />
                        </Col>
                        <Col lg={4} sm={12}>
                          <SharedButton title={"CANCEL"} type={"submit"} variant={"outline"} className={"btn btn-outline w-100"} />
                        </Col>
                      </Row>
                    </Form>
                  </section>
                </Container>
              </div>
              <Footer />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
