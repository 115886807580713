import React from 'react';
import { Link, NavLink } from 'react-router-dom';

export default function StaticHeader() {
  return (
    <>
      {/* <!-- Navigation Section --> */}
      <div className="header box-shadow1" id="myHeader">
        <div className="container">
          <nav className="navbar navbar-expand-md navbar-light white-bg px-0">
            <Link className="nav-link px-0" to="/Home">
              <img src="clientassets/img/logo_colored.png" className="img-fluid img-size" alt="Logo" />
            </Link>
            <button
              className="navbar-toggler ml-auto my-3 my-lg-0"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            {/* <!-- buttons ends here --> */}

            <div className="collapse navbar-collapse" id="navbarNavDropdown">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <NavLink className="nav-link link header-css" to="/statichome">
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link link header-css" to="/solution">
                    The Solution
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link link header-css" to="/apps">
                    The Apps
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link link header-css" to="/pricing">
                    Pricing
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link link header-css" to="/contact-us">
                    Contact us
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link btn btn-blue-grad px-4 text-uppercase header-css" to="/demo">
                    Demo
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link btn btn-green-grad px-4 text-uppercase header-css" to="/login">
                    Login
                  </NavLink>
                </li>
              </ul>
            </div>
            {/* <!-- end of navbar-collapse --> */}
          </nav>
        </div>
        {/* <!-- end of container --> */}
      </div>
      {/* <!-- End of Navigation Section --> */}
    </>
  );
}
