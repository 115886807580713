import React from 'react'
import HeaderChousePlan from '../header/HeaderChousePlan'
import StaticFooter from '../../../staticpagis/StaticFooter'
import { Link, useLocation, useNavigate } from 'react-router-dom'

export const Paymentsetup = () => {
  
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search); 
  const planId = queryParams.get('id');

  const navigate = useNavigate();
  
  const clickChange =()=>{
    navigate(`/paycard-setup?plid=${planId}`);
  }

  return (
  <>
  <HeaderChousePlan />
  <div className="middle-content-wrp" style={{ paddingTop: "120px" }}>
  {/* image layer */}
  <div className="signwrapp-back-layer">
    <img src="assets/images/pictures/watermark_bg.png" />
  </div>
  {/* img layer */}
  <div className="container py-5 relative setminheight d-flex align-items-center">
    <div className="stepsinfo-fill">
      <ul className="steps">
        <li className="completed-step" />
        <li className="completed-step" />
        <li className="completed-step" />
      </ul>
    </div>
    <div className="row justify-content-center flex-fill">
      <div className="col-md-6 col-lg-5 col-xl-4">
        <div className="middle-innerbox py-4 px-4">
          <div className="my-2">
            <img
              src="assets/images/icons/ic_lock.png"
              className="img-fluid d-block mx-auto"
              alt=''
            />
          </div>
          <span className="brand-light text-uppercase d-block text-center mt-3">
            Step 3 of 3
          </span>
          <h3 className="title text-center mt-3">Set up your payment.</h3>
          <div
            // to="/paycard-setup"
            onClick={clickChange}
            className="setuppayment d-flex border-radius10 border py-3 px-2 align-items-center font-12 mt-5"
          >
            Credit or debit card{"     "}
<div style={{paddingLeft:"6px"}}>
            <img src="assets/images/icons/ic_visacard.png" className="ml-1" alt='' />
            <img src="assets/images/icons/ic_mastercard.png" className="ml-1" alt='' />
            {/* <img
              src="assets/images/icons/ic_dinersclubcard.png"
              className="ml-1"
              alt=''
            /> */}
            {/* <img
              src="assets/images/icons/ic_americancard.png"
              className="ml-1"
              alt=''
            /> */}
            </div>
            <i className="fas fa-chevron-right ml-auto" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<StaticFooter />
  </>
  )
}
