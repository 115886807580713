import moment from 'moment';
import React from 'react'

export default function Apcancel({serviceData}) {
 
    const update = serviceData.update_date;
  
  return (
   <>
    <button className="px-2 ms-3" style={{ color: 'red' }}>
      Appointment Cancelled - {moment.unix(update).format('D MMM, YYYY h:mm:A')}
    </button>
   </>
  )
}
