import React from "react";
import Sidebar from "../../comman_page/Sidebar";
import { HeaderDashboard } from "../../comman_page/HeaderDashboard";
import { Footer } from "antd/es/layout/layout";
import { Container, Row, Col, Form } from "react-bootstrap";
import { ProductCardList } from "../../../../../sharedComponents/ProductCardList";
import { InputField } from "../../../../../sharedComponents/InputField";
import { SharedSelect } from "../../../../../sharedComponents/SharedSelect";
import { SharedButton } from "../../../../../sharedComponents/Button";
import { TextArea } from "../../../../../sharedComponents/TextArea";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { products_details, update_product } from "../../../../../Studio-Services/Studio_Services";
import Swal from "sweetalert2";
import logo from "../../../../../Images/logo_white_web.png"

export const Productupdate = () => {
  const [pData, setPData] = useState();
  const [pd, setPd] = useState();
  const location = useLocation();
  const navigate = useNavigate();

  const productsDetails = async () => {
    if (pData && pData.product_id) {
      const fData = {
        "product_id": pData.product_id
      }
      const resp = await products_details(fData);
      if (resp) {
        setPd(resp.data.data);
      }
    }
  }

  useEffect(() => {
    if (pData) {
      productsDetails();
    }

  }, [pData])

  useEffect(() => {
    if (location) {
      setPData(location.state.data);
    }
  }, [location])

  const Stopt = [
    { value: "Active", label: "Visible" },
    { value: "Disable", label: "Not Visible" }
  ]


  const submitHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const formValue = Object.fromEntries(formData.entries());

    const fData = {
      "product_id": formValue.product_id,
      "product_price": formValue.product_price,
      "quantity": formValue.quantity,
      "status": formValue.status,
      "description": formValue.description
    }

    const resp = await update_product(fData);
    if (resp) {
      Swal.fire({
        html: `
      <img src=${logo} class="img-fluid d-block mx-auto mt-5" />
      <h4 class="title text-uppercase text-center mt-4 textWhite">Success</h4>
      <p class="text-center my-4 textcolor">
        <small class="textWhite">${resp.data.message}</small>
      </p>
    `,
        showClass: {
          popup: 'custom-dialog-container',
        },
        customClass: {
          confirmButton: 'custom-button custom-button-size modalbtn',
        },
        confirmButtonText: 'Continue',
      }).then((result) => {
        if (result.isConfirmed) {
          // Redirect to a new page
          navigate("/list-products");
        }
      });
    }

  }

  return (
    <>

      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col className="p-0 RightCol">
              <HeaderDashboard />
              <div className="RightSide">
                <section className="addClient innerContent">
                  <Container className="pt-5 my-3">
                    <div className="ProductUpdateBg ">
                      <Row>
                        <Col lg={3} xs={12}>
                          <div className="productimg">
                            <h3>PRODUCT'S IMAGE</h3>
                            <img
                              src={pd && pd.product_images[0] && pd.product_images[0].image_name ? pd.product_images[0].image_name : "https://warriorsms.com/superadmin/uploads/products/1652214051WarriorMan_icon2_CMYK.png"}


                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </Col>
                        <Col lg={9} xs={12}>
                          <div className="ListProductForm">
                            <h3 className="mb-5">PRODUCT DETAILS</h3>
                            <Form onSubmit={submitHandler}>
                              <Row>
                                <Col>
                                  <div className="ProductDetails">
                                    <h3>{pd && pd.product_name ? pd.product_name : ""}</h3>
                                    <h5>Product No.: {pd && pd.product_id ? pd.product_id : ""}</h5>
                                    <h3>Price : <span className="pricegreen">$ {pd && pd.product_price ? pd.product_price : ""}</span></h3>
                                  </div>
                                </Col>
                              </Row>
                              <Row className="mt-4">
                                <h3>UPDATE PRODUCT DETAILS</h3>
                                <Col>
                                  <InputField
                                    type={"text"}
                                    lable={"Product ID"}
                                    name="product_id"
                                    readOnly
                                    value={pd && pd.product_id ? pd.product_id : ""}
                                  />
                                </Col>
                                <Col>
                                  <InputField
                                    type={"number"}
                                    name={"quantity"}
                                    value={pd && pd.product_quantity ? pd.product_quantity : ""}
                                    lable={"Quantity"}
                                  />
                                </Col>
                              </Row>
                              <Row className="mt-4">
                                <Col>
                                  <InputField
                                    type={"text"}
                                    name={"product_price"}
                                    lable={"Total Price"}
                                    value={pd && pd.product_price ? pd.product_price : ""}
                                  />
                                </Col>
                                <Col>
                                  <SharedSelect
                                    name={"status"}
                                    lable={"Status"}
                                    value={pd && pd.product_status ? pd.product_status : ""}
                                    options={Stopt}
                                  />
                                </Col>
                              </Row>
                              <Row className="mt-3">
                                <Col>
                                  <TextArea
                                    name={"description"}
                                    value={pd && pd.product_description ? pd.product_description : ""}
                                    lable={"Description"} />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <SharedButton
                                    title={"Update"}
                                    className={"btn btn-primary w-100"}
                                    variant={"primary"}
                                  />
                                </Col>
                                <Col>
                                </Col>
                                <Col></Col>
                              </Row>
                            </Form>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Container>
                </section>
                <Footer />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
