import React, { useEffect, useState } from 'react'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';

export default function PlaceAutoCompleted({ label, setCordinates, value, myClassName = "" }) {
  const [address, setAddress] = useState();
  const [coordinates, setCoordinates] = useState({ getAddress: '', getCountry: '', getState: '', getCity: '', getZIP_Code: '', getLat: '', getLat: '', getLocation_name: '', getStreet: '' });

  useEffect(() => {
    setCordinates(coordinates)
  }, [coordinates])

  useEffect(() => {
    if (value) {
      setAddress(value);
    }
  }, [value])

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    if (results.length > 0) {
      const result = results[0];

      let setcountry = '';
      let setstate = '';
      let setcity = '';
      let setzipcode = '';
      let setlat = '';
      let setlong = '';
      let setStreet = '';
      let setFormatted_address = '';
      for (let i = 0; i < result.address_components.length; i++) {
        let state = result.address_components[i].types[0];
        if (state == "administrative_area_level_1") {
          setstate = result.address_components[i].long_name;
        }
        let city = result.address_components[i].types[0];
        if (city == "administrative_area_level_2") {
          setcity = result.address_components[i].long_name;
        }
        let country = result.address_components[i].types[0];
        if (country == "country") {
          setcountry = result.address_components[i].long_name;
        }
        let postal_code = result.address_components[i].types[0];
        if (postal_code == "postal_code") {
          setzipcode = result.address_components[i].long_name;
        }
        let route = result.address_components[i].types[0];
        if (route == "route") {
          setStreet = result.address_components[i].long_name;
        }
      }

      const location = result.geometry.location;
      if (result) {
        setFormatted_address = result.formatted_address;
        setlat = location.lat();
        setlong = location.lng();
      }

      setCoordinates({
        getAddress: value,
        getLocation_name: setFormatted_address,
        getCountry: setcountry,
        getState: setstate,
        getCity: setcity,
        getZIP_Code: setzipcode,
        getLat: setlat,
        getLng: setlong,
        getStreet: setStreet
      })
    } else {
      setCoordinates({
        getAddress: '',
        getLocation_name: '',
        getCountry: '',
        getState: '',
        getCity: '',
        getZIP_Code: '',
        getLat: '',
        getLng: '',
        getStreet: ''
      })
      console.log('No results found for the given address.');
    }
    setAddress(value);
  }
  return (
    <>
      <PlacesAutocomplete
        value={address}
        onChange={setAddress}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div style={{
            position: 'relative'
          }}>
            {label &&
              <label className='form-label inputLable'>{label}</label>
            }
            <input
              {...getInputProps({
                placeholder: 'Search Places ...',
                className: myClassName ? myClassName : 'location-search-Field',

              })}
            />
            <div className="autocomplete-dropdown-container">
              <div className='row'>
                <div className='col-md-12'>
                  {loading && <div>Loading...</div>}
                  {suggestions.map(suggestion => {
                    const className = suggestion.active
                      ? 'suggestion-item--active'
                      : 'suggestion-item';
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                      : { backgroundColor: '#ffffff', cursor: 'pointer' };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <span className='p-3'>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>


            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </>
  )
}
