import React from 'react'
import { Footer } from '../../../../../public/containers/footer/Footer'
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { HeaderDashboard } from '../../comman_page/HeaderDashboard'
import Sidebar from '../../comman_page/Sidebar'
import { EditInstructorform } from './EditInstructorform'
import { useLocation } from 'react-router-dom'
import { useState } from 'react'

export const EditInstructor = () => {
const location = useLocation();
const [loder, setLoder] = useState(false);

const loders = (data)=>{
    setLoder(data);
}

  return (
   <>
      {loder ? <div className="MainLoader"><Spinner animation="border" /> </div> : ""}
    <div className="MainDashboard">
                <div className="MainLayout">
                    <Row>
                        <Sidebar />
                        <Col className="p-0 RightCol">
                            <HeaderDashboard />
                            <div className="RightSide">
                                <section className="addClient innerContent">
                                    <Container className="pt-5">
                                        <h4>Edit Instructor/Staff</h4>
                                  
                                        <div className='AddClientForm AddClientContent'>
                                      <EditInstructorform loders={loders} instructorData={location.state.data} setLoder={setLoder} />
                                        </div>
                                    </Container>
                                </section>
                                <Footer />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div >
   </>
  )
}
     