import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import { SharedButton } from "../../../../../sharedComponents/Button";

export const SheduleappointmentCart = ({cardDetail ,onClick}) => {

  return (
    <>
      <Row>
        {cardDetail?cardDetail.map((value, index) => (
          <Col md={4} key={index}>
            <Card onClick={()=>onClick(value.fullData)} className="ServiceCardBox" key={index}>
              <div className="cardImg">
                <Card.Img variant="top" src={value.memberShipImg} />
              </div>
              <Card.Body>
                <Card.Title>{value.cardTitle?value.cardTitle:""}</Card.Title>
                <Card.Text>
                  <p className="m-0">{value.charge?value.charge:""} <small>{value.price?value.price:""}</small></p>
                  <p>{value.duration?value.duration:""} <small>{value.min?value.min:""}</small></p>
                </Card.Text>
                <SharedButton title={"Book Service"} variant={"secondary"} className={"btn btn-secondary purchasePassBtn"}/>
              </Card.Body>
            </Card>
          </Col>
        )):""}
      </Row>
    </>
  );
};
