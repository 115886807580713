import React, { useState } from "react";
import { Container, Row, Col, Table, Spinner } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "bootstrap/dist/css/bootstrap.min.css";
import { IncrementDecrementInput } from "../../../../../sharedComponents/IncrementDecrementInput";
import { cart_list, remove_cart, update_cart } from "../../../../../Studio-Services/Studio_Services";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import logo from "../../../../../Images/logo_white_web.png"
import { update } from "lodash";
import { async } from "q";
import { SharedButton } from "../../../../../sharedComponents/Button";
import "./CartTable.css"
import { errorAlert } from "../../../../../Static_Services/Alertmsg";

export const CartTable = ({ client_id, setLoder }) => {
  const [tableData, setTableData] = useState([]);
  const [tableDataCart, setTableDataCart] = useState([]);
  const [clientId, setClientId] = useState();
  const [cartNo, setCartNo] = useState(0);
  const [totalBaseAmount, setTotalBaseAmount] = useState(0);




  const adjAmount = (amount, discount, quantity) => {
    const calculate = amount * quantity;
    const adjAmount = calculate - parseFloat(discount);
    return parseFloat(adjAmount);
  };

  const adjAmountSubTotal = (amount, discount, tax, qty) => {
    const baseAmt = amount * qty;
    const amt = baseAmt - parseFloat(discount) + parseFloat(tax);
    return parseFloat(amt);
  };


  const getCartItem = async () => {
    setLoder(true);
    const fData = {
      pageid: "1",
      client_id: client_id,
    };
    const resp = await cart_list(fData);
    if (resp) {
      setLoder(false);
      setTableData(resp.data.data);
      setTableDataCart(resp.data.data.business_details.cart_details);

      if (resp && resp.data && resp.data.data && resp.data.data.business_details && resp.data.data.business_details.cart_details) {
        const cartArray = resp.data.data.business_details.cart_details;
        let cartno = 0;
        let totalAmount = 0;
        cartArray.forEach(e => {
          cartno += parseInt(e.quantity, 10);
          totalAmount += parseFloat(e.base_amount);
        });
        setCartNo(cartno);
        setTotalBaseAmount(totalAmount.toFixed(2));
      }


    } else {
      setTableData([]);
      setTableDataCart([]);
      setLoder(false);
    }
  };

  useEffect(() => {
    if (client_id) {
      getCartItem();
      setClientId(client_id);
    }
  }, [client_id]);

  const addQuintity = async (data, index) => {
    setLoder(true);
    const quint = data + 1;
    var Quantity = tableData.business_details.cart_details[index];
    const fData = {
      "product_id": Quantity.service_id,
      "quantity": quint,
      "client_id": clientId,
      "cart_id": Quantity.cart_id,
      "business_id": localStorage.getItem("business_id"),
      "discount": Quantity.discount,
    }
    const resp = await update_cart(fData);
    if (resp) {
      getCartItem();
    } else {
      setLoder(false);
    }
  }

  const remQuintity = async (data, index) => {
    setLoder(true);
    if (data == 1) {
      setLoder(false);
      return
    } else {
      const quint = data - 1;
      var Quantity = tableData.business_details.cart_details[index];

      const finalAmount = Quantity.amount * quint;
      const discount = Quantity.discount;

      if (discount >= finalAmount) {
        errorAlert('Discounted should be less than total amount.');
        setLoder(false);
        return;
      }

      const fData = {
        "product_id": Quantity.service_id,
        "quantity": quint,
        "client_id": clientId,
        "cart_id": Quantity.cart_id,
        "business_id": localStorage.getItem("business_id"),
        "discount": Quantity.discount,
      }
      const resp = await update_cart(fData);
      if (resp) {
        getCartItem();
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
        <h4 class="title text-uppercase text-center mt-4 textWhite">
      SUCCESS
      </h4>
      <p class="text-center my-4 textcolor">
      <small class="textWhite">
     ${resp.data.message} </small></p>
      `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      } else {
        setLoder(false);
      }
    }
  }

  const removeCart = async (cart_type, data) => {

    var request = {
      remove_cart_type: cart_type,
      cart_id: data.cart_id,
      client_id: clientId,
    };
    const resp = await remove_cart(request);
    if (resp) {
      getCartItem();
      Swal.fire({
        html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
      <h4 class="title text-uppercase text-center mt-4 textWhite">
      SUCCESS
    </h4>
    <p class="text-center my-4 textcolor">
    <small class="textWhite">
   ${resp.data.message} </small></p>
    `,
        showClass: {
          popup: 'custom-dialog-container',
        },
        customClass: {
          confirmButton: 'custom-button custom-button-size modalbtn',
        },
      });
    } else {
      getCartItem();
      setLoder(false);
    }
  }

  const updateItem = async (data) => {
    const final = (data.amount) * (data.quantity);
    const dis = data.discount;

    if (final <= dis) { errorAlert('Discounted should be less than total amount.'); return; }
    setLoder(true);
    var request = {
      product_id: data.service_id,
      quantity: data.quantity,
      client_id: clientId,
      cart_id: data.cart_id,
      business_id: localStorage.getItem("business_id"),
      discount: data.discount,
    };

    const resp = await update_cart(request);
    if (resp) {
      getCartItem();
      Swal.fire({
        html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
      <h4 class="title text-uppercase text-center mt-4 textWhite">
      SUCCESS
    </h4>
    <p class="text-center my-4 textcolor">
    <small class="textWhite">
   ${resp.data.message} </small></p>
    `,
        showClass: {
          popup: 'custom-dialog-container',
        },
        customClass: {
          confirmButton: 'custom-button custom-button-size modalbtn',
        },
      });
    } else {
      getCartItem();
      setLoder(false);
    }
  }

  const dischangeHandler = (data, ind) => {
    const disc = data.target.value;
    const sData = Array.from(tableDataCart);
    if (sData[ind].discount > sData[ind].amount) {
      sData[ind].discount = 0;
    } else {
      sData[ind].discount = disc;
      setTableDataCart(sData);
    }

  }

  return (
    <>
      {tableDataCart.length > 0 ?

        <div className="CardTable" style={{ marginTop: "20px" }}>
          <table className="table table-striped table-responsive p-2">
            <thead>
              <tr>
                <th scope="col">Product Name</th>
                <th scope="col">Base Amount</th>
                <th scope="col">Quantity</th>
                <th scope="col">Price</th>
                <th scope="col">Discount (In $)</th>
                <th scope="col">Adj Price (In $)</th>
                <th scope="col">Tax1</th>
                <th scope="col">Tax2</th>
                <th scope="col">Sub Total</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {tableDataCart ?
                (tableDataCart).map((e, index) => (

                  <tr key={index}>
                    <td>
                      <div className="tableimg">
                        <img src={e.item_image ? e.item_image : "assets/images/passes.png"} alt="" width="50" height="50" /><p>{e.item_name}</p>
                      </div>
                    </td>
                    <td>{`$ ${parseFloat(e.base_amount).toFixed(2)}`}</td>
                    {(e.service_type) === "3" ?
                      <td>
                        <div className="incrementdecrement">
                          <button className="incrementBtn" onClick={() => addQuintity(e.quantity, index)}>+</button><span>{e.quantity}</span><button className="incrementBtn" onClick={() => remQuintity(e.quantity, index)}>-</button>
                        </div>
                      </td>
                      :
                      <td>{e.quantity}</td>
                    }
                    <td>{`$ ${parseFloat((e.amount * e.quantity).toFixed(2))}`}</td>
                    {(e.service_type) === "3" ?
                      <td>
                        <div className="inputNumber">
                          <input type="number"
                            step=".01"
                            min="0"
                            max={e.quantity}
                            onChange={(e) => dischangeHandler(e, index)} value={e.discount} />
                        </div>
                      </td>
                      :
                      <td>{`$ ${e.discount}`}</td>
                    }
                    <td>{`$ ${adjAmount(e.amount, e.discount, e.quantity).toFixed(2)}`}</td>
                    <td>{`$ ${parseFloat(e.tax1_rate).toFixed(2)}`}</td>
                    <td>{`$ ${parseFloat(e.tax2_rate).toFixed(2)}`}</td>
                    <td>{`$ ${adjAmountSubTotal(e.amount, e.discount, e.tax, e.quantity).toFixed(2)}`}</td>
                    {(e.service_type) === "3" ?
                      <td>
                        <div className="updateRemove">
                          <button onClick={() => updateItem(e)}>Update</button> | <button onClick={() => removeCart(0, e)}>Remove</button>
                        </div>
                      </td>
                      :
                      <td>
                        <div className="updateRemove text-center">
                          <button onClick={() => removeCart(0, e)}>Remove</button>
                        </div>
                      </td>
                    }
                  </tr>
                ))
                : ""}
              <tr>

                <td>
                  <h6>Total</h6>
                </td>
                <td>{`$ ${totalBaseAmount}`}</td>
                <td>{cartNo}</td>
                <td>{`$ ${tableData && tableData.total_amount ? parseFloat(tableData.total_amount).toFixed(2) : ""}`}</td>
                <td>{`$ ${tableData ? parseFloat(tableData.total_discount).toFixed(2) : ""}`}</td>
                <td>{`$ ${tableData && tableData.total_amount ? parseFloat(parseFloat(tableData.total_amount) - parseFloat(tableData.total_discount)).toFixed(2) : ""}`}</td>
                <td>{`$ ${tableData ? parseFloat(tableData.tax1_rate).toFixed(2) : ""}`}</td>
                <td>{`$ ${tableData ? parseFloat(tableData.tax2_rate).toFixed(2) : ""}`}</td>
                <td>{`$ ${tableData && tableData.grand_total ? parseFloat(tableData.grand_total).toFixed(2) : ""}`}</td>

                <td>
                  <SharedButton
                    title={"Checkout"}
                    className={"btn w-100"}
                    type={"button"}
                    href={"/Payment"}
                    element={{ data: tableData, client_id: clientId }}
                    variant="primary"
                  />
                </td>

              </tr>
            </tbody>
          </table>
        </div>
        :
        <Row style={{ textAlign: "center" }}>
          <Col>
            <h2>No Record</h2>
          </Col>
        </Row>
      }

    </>
  );
};
