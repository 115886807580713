// import React, { useState, useRef, useEffect } from "react";
// import FullCalendar from "@fullcalendar/react";
// import dayGridPlugin from "@fullcalendar/daygrid";
// import timeGridPlugin from "@fullcalendar/timegrid";
// import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
// import interactionPlugin from "@fullcalendar/interaction";
// import listPlugin from "@fullcalendar/list";
// import * as bootstrap from "bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
// import moment from "moment";
// import { useLocation, useNavigate } from "react-router-dom";

// export const CalenderShedulsClass = ({ resp, setVisibleRange, setCalendardates,calendardates }) => {


//   const calendarRef = useRef(null);
//   const [selectedDate, setSelectedDate] = useState(null);
//   const [selectedEvent, setSelectedEvent] = useState([]);
//   const navigate = useNavigate();



//   const list = () => {
//     if (resp.length > 0) {
//       let evnt = [];
//       evnt = resp.map((e, index) => ({
//         id: index,
//         myid: [{ class_id: e.class_id }, { schedule_id: e.schedule_id }, { scheduled_date: e.scheduled_date }],
//         title: `${e.class_name} | ${e.instructor_name} | Booked ${e.attendence}  / ${e.capacity} | Attendance ${e.attendence_check_in ? e.attendence_check_in : 0} / ${e.capacity ? e.capacity : 0} | Waitlist : ${e.attendence && e.attendence > e.capacity ? (e.attendence - e.attendence_check_in) - e.capacity : 0} `,
//         description: ` 
//             ${moment.unix(e.from_time).format("hh:mm A")} 
//             - ${moment.unix(e.to_time).format("hh:mm A")},
//             || 
//             Class Name : ${e.class_name}
//             ||
//             Instructor: ${e.instructor_name}, || Location: ${e.location}
//             ||
//              Booked ${e.attendence}  / ${e.capacity}
//             ||
//             Attendance  : ${e.attendence_check_in ? e.attendence_check_in : 0} / ${e.capacity ? e.capacity : 0}
//             || 
//             Waitlist   : ${e.attendence && e.attendence > e.capacity ? (e.attendence - e.attendence_check_in) - e.capacity : 0}
//           `,
//         start: moment.unix(e.from_time).format("YYYY-MM-DDTHH:mm:ss"),
//         end: moment.unix(e.to_time).format("YYYY-MM-DDTHH:mm:ss"),
//       }));
//       setSelectedEvent(evnt);
//     } else {
//       setSelectedEvent([]);
//     }
//   };

//   useEffect(() => {
//     setSelectedEvent([]);
//     list();
//   }, [resp]);

//   const eventMouseEnter = (info) => {
//     const description = info.event.extendedProps.description;
//     const eventElement = info.el;
//     const popover = new bootstrap.Popover(eventElement, {
//       //   title: "Description",
//       content: description,
//       trigger: "hover",
//       placement: "top",
//     });
//     popover.show();
//   };

//   const eventMouseLeave = (info) => {
//     const eventElement = info.el;
//     const popover = bootstrap.Popover.getInstance(eventElement);
//     if (popover) {
//       popover.dispose();
//     }
//   };

//   const eventDidMount = (info) => {
//     const description = info.event.extendedProps.description;
//     const eventElement = info.el;
//     const descriptionElement = document.createElement("div");
//     descriptionElement.classList.add("fc-description");
//     descriptionElement.textContent = `Description: ${description}`;
//     descriptionElement.style.display = "none"; // Set initial display to "none"
//     eventElement.appendChild(descriptionElement);

//     eventElement.addEventListener("click", () => {
//       if (descriptionElement.style.display === "none") {
//         descriptionElement.style.display = "block";
//       } else {
//         descriptionElement.style.display = "none";
//       }
//     });
//   };


//   const eventClick = (info) => {
//     // Redirect to another page when an event is clicked
//     const eventId = info.event.id;

//     // Close the popover if it's currently open
//     const eventElement = info.el;
//     const popover = bootstrap.Popover.getInstance(eventElement);
//     if (popover) {
//       popover.dispose();
//     }
//     const myData = info.event.extendedProps.myid;
//     navigate("/ClassSchedule", {
//       state: {
//         class_id: myData[0].class_id,
//         scheduled_date: myData[2].scheduled_date,
//         schedule_id: myData[1].schedule_id,
//         business_id: localStorage.getItem("business_id"),
//       },
//     });
//     // history.push(`/event-details/${eventId}`); // Replace with the desired URL to redirect to event details page
//   };



//   const datesSet = (info) => {
//     let cld = { ...calendardates };

//     cld.start=moment(info.startStr).format('YYYY-MM-DD');
//     cld.end=moment(info.endStr).format('YYYY-MM-DD');
//     setCalendardates(cld);

//     setVisibleRange({
//       start: info.startStr,
//       end: info.endStr,
//     });
//   };

//   const calendarOptions = {
//     plugins: [
//       dayGridPlugin,
//       timeGridPlugin,
//       resourceTimeGridPlugin,
//       interactionPlugin,
//       listPlugin,
//     ],
//     schedulerLicenseKey: "GPL-My-Project-Is-Open-Source",
//     headerToolbar: {
//       left: "prev,today,next",
//       center: "title",
//       right: "timeGridDay,timeGridWeek,dayGridMonth,listWeek",
//     },
//     buttonText: {
//       prev: "Previous",
//       next: "Next",
//       today: "Today",
//       listWeek: "List",
//     },
//     eventClick: eventClick,
//     slotMinTime: "00:00:00",
//     // slotMaxTime: "24:00:00",
//     slotLabelInterval: { hours: 1 },
//     initialView: "listWeek",
//     expandRows: true,
//     events: selectedEvent,
//     height: "80vh",

//     datesSet: datesSet,

//     // Set the scrollTime and scrollTimeReset options
//     scrollTime: "06:00:00",
//     scrollTimeReset: ["06:00:00", "18:00:00"],

//     eventDidMount: eventDidMount,
//     eventMouseEnter: eventMouseEnter,
//     eventMouseLeave: eventMouseLeave,

//   };

//   return (
//     <>
//       <FullCalendar ref={calendarRef} {...calendarOptions} />
//     </>
//   );
// };


import React, { useState, useRef, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import * as bootstrap from "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";

export const CalenderShedulsClass = ({ resp, setVisibleRange, setCalendardates, calendardates }) => {
  const calendarRef = useRef(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState([]);
  const navigate = useNavigate();
  const popoverRef = useRef(null);

  const list = () => {
    if (resp.length > 0) {
      const evnt = resp.map((e, index) => ({
        id: index,
        myid: [{ class_id: e.class_id }, { schedule_id: e.schedule_id }, { scheduled_date: e.scheduled_date } ,{fValue:e}],
        title: `${e.class_name} | ${e.instructor_name} | Booked ${e.attendence}  / ${e.capacity} | Attendance ${e.attendence_check_in ? e.attendence_check_in : 0} / ${e.capacity ? e.capacity : 0} | Waitlist : ${e.attendence && e.attendence > e.capacity ? (e.attendence - e.attendence_check_in) - e.capacity : 0}`,
        description: ` 
          ${moment.unix(e.from_time).format("hh:mm A")} 
          - ${moment.unix(e.to_time).format("hh:mm A")},
          || 
          Class Name : ${e.class_name}
          ||
          Instructor: ${e.instructor_name}, || Location: ${e.location}
          ||
          Booked ${e.attendence}  / ${e.capacity}
          ||
          Attendance  : ${e.attendence_check_in ? e.attendence_check_in : 0} / ${e.capacity ? e.capacity : 0}
          || 
          Waitlist   : ${e.attendence && e.attendence > e.capacity ? (e.attendence - e.attendence_check_in) - e.capacity : 0}
        `,
        start: moment.unix(e.from_time).format("YYYY-MM-DDTHH:mm:ss"),
        end: moment.unix(e.to_time).format("YYYY-MM-DDTHH:mm:ss"),
      }));
      setSelectedEvent(evnt);
    } else {
      setSelectedEvent([]);
    }
  };

  useEffect(() => {
    setSelectedEvent([]);
    list();
  }, [resp]);

  const eventDidMount = (info) => {
    const description = info.event.extendedProps.description;
    const eventElement = info.el;

    eventElement.addEventListener("mouseenter", () => {
      if (popoverRef.current) {
        bootstrap.Popover.getInstance(popoverRef.current)?.dispose();
      }
      popoverRef.current = eventElement;
      new bootstrap.Popover(eventElement, {
        content: description,
        trigger: "manual",
        placement: "top",
      }).show();
    });

    eventElement.addEventListener("mouseleave", () => {
      if (popoverRef.current === eventElement) {
        bootstrap.Popover.getInstance(popoverRef.current)?.dispose();
        popoverRef.current = null;
      }
    });

    eventElement.addEventListener("click", (e) => {
      e.stopPropagation();
      if (popoverRef.current === eventElement) {
        bootstrap.Popover.getInstance(popoverRef.current)?.dispose();
        popoverRef.current = null;
      }

      const myData = info.event.extendedProps.myid;
      console.log("----",myData[3].fValue);
      navigate("/ClassSchedule", {
        state: {
          class_id: myData[0].class_id,
          scheduled_date: myData[2].scheduled_date,
          schedule_id: myData[1].schedule_id,
          business_id: localStorage.getItem("business_id"),
          fullData:myData[3].fValue,
        },
      });
    });
  };


  const datesSet = (info) => {
        let cld = { ...calendardates };
    
        cld.start=moment(info.startStr).format('YYYY-MM-DD');
        cld.end=moment(info.endStr).format('YYYY-MM-DD');
        setCalendardates(cld);
    
        setVisibleRange({
          start: info.startStr,
          end: info.endStr,
        });
      };


  const calendarOptions = {
    plugins: [
      dayGridPlugin,
      timeGridPlugin,
      resourceTimeGridPlugin,
      interactionPlugin,
      listPlugin,
    ],
    schedulerLicenseKey: "GPL-My-Project-Is-Open-Source",
    headerToolbar: {
      left: "prev,today,next",
      center: "title",
      right: "timeGridDay,timeGridWeek,dayGridMonth,listWeek",
    },
    buttonText: {
      prev: "Previous",
      next: "Next",
      today: "Today",
      listWeek: "List",
    },

    datesSet: datesSet,
    slotMinTime: "00:00:00",
    slotLabelInterval: { hours: 1 },
    initialView: "listWeek",
    expandRows: true,
    events: selectedEvent,
    height: "80vh",
    scrollTime: "06:00:00",
    scrollTimeReset: ["06:00:00", "18:00:00"],
    eventDidMount: eventDidMount,
  };

  return (
    <>
      <FullCalendar ref={calendarRef} {...calendarOptions} />
    </>
  );
};


