import { useEffect, useRef, useState } from 'react';

export const InputTypeTime = (
  { type,
    value,
    className,
    onChange,
    placeholder,
    name,
    id,
    lable,
   error,
   maxLength,
   accept,
   readOnly,
   minLength,
   required,
   timeFormat 
    }
) => {
  const inputRef = useRef(null);
  useEffect(() => {
    if(value!==undefined){
      inputRef.current.value = value;
    }
  },[value]);


  return (
    <>
      <div className="mb-3">
        {lable ? <label htmlFor={id?id:''} className="form-label inputLable">{lable}</label> : ''}
          <input 
          readOnly={readOnly ? true : false}
          type={type}
          name={name}
        
          className={className? className : "form-control"}
          placeholder={placeholder?placeholder:""}
          ref={inputRef}
          onChange={onChange}
        //   step={timeFormat === 'AMPM' ? 1 : 60}
          />
        <small className="text-danger">{error}</small>
        </div>
    </>
  );
};
