import React from 'react'
import { InnerHeader } from '../../Components/inner-header/InnerHeader1'
import { Innerfooter } from '../../Components/inner-footer/Innerfooter'
import { useLocation } from 'react-router-dom'
import { useState } from 'react'
import { useEffect } from 'react'
import { staff_get_user_profile } from '../../../Static_Services/Staff_Services'
import { Spinner } from 'react-bootstrap'

export const CustomerProfile = () => {
    const [customer_id, setCustomer_id] = useState();
    const location = useLocation();
    const [loder, setLoder] = useState(false);
    

    useEffect(() => {
        if (location.state.Id) {
            setCustomer_id(location.state.Id);
        }
    }, [location])

    const [dob, setDob] = useState();
    const [profileDetail, setProfileDetail] = useState([]);
    const [gender, setGender] = useState();



    const getProfile = async () => {
        setLoder(true);
        var detail = { "customer_id": customer_id };
        const resp = await staff_get_user_profile(detail);
        if (resp) {
            const response = resp.data;
            setDob(response.data.date_of_birth);
            let ProfileDetail = response.data;
            setGender(ProfileDetail.gender);
            ProfileDetail.mobile1 = ProfileDetail.country_code + '  ' + ProfileDetail.mobile;
            setProfileDetail(ProfileDetail);
            setLoder(false);
        } else {
            setLoder(false);
        }
        setLoder(false);

    }

    useEffect(() => {
        if (customer_id) {
            getProfile();
        }
    }, [customer_id])
    return (
        <>
            {loder ? <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div> : ""}
            <InnerHeader />
            <div className="body-grey">

                <div className="profile-section py-5">
                    <div className="container">
                        {profileDetail &&
                            <div className="white-bg p-4 p-md-5 border-radius6 box-shadow1">
                                <form action="#">
                                    <div className="row">
                                        <div className="col-12 pb-4">
                                            <div className="profile-details">
                                                <div className="row align-items-center">
                                                    <div className="col-12 col-md-3">
                                                        <div className="profile-detail-image ">
                                                            <div className="userprofile_img box-shadow1"><img src={profileDetail.profile_img} className="img-fluid border-radius100 w-100" style={{ height: '200px' }} />
                                                                <img src="imgURL" className="img-fluid border-radius100  w-100" style={{ height: '200px' }} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-md-9">
                                                        <div className="profile-detail-text">
                                                            <h2 className="title dark-color font-24 mb-2 text-capitalize">{profileDetail.name+'  '}
                                                               { profileDetail.lastname}</h2>
                                                            <p className="brand-color3"> {profileDetail.email}</p>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                            <div className="form-group">
                                                <p className="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1">First Name </p>
                                                <input id="" readOnly type="text" name="name" className="form-control colorchnage"
                                                    placeholder="Enter your name" value={profileDetail.name} />
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                            <div className="form-group">
                                                <p className="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1">Last Name </p>
                                                <input id="" readOnly type="text" name="lastname" className="form-control colorchnage"
                                                    placeholder="Enter your name"  value={profileDetail.lastname}/>
                                            </div>
                                        </div>


                                        <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                            <div className="form-group">
                                                <p className="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1"> Email </p>
                                                <input id="" type="email" name="email" className="form-control colorchnage"
                                                    placeholder="Enter your e-mail" value={profileDetail.email} readOnly />
                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                            <div className="form-group">
                                                <p className="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1"> Phone </p>
                                                <input id="" type="text" name="mobile1" className="form-control colorchnage"
                                                    placeholder="Enter your phone no." value={profileDetail.mobile1} readOnly />
                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                            <div className="form-group">
                                                <p className="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1"> DOB </p>
                                                <input className="form-control colorchnage" name="date_of_birth" value={dob}
                                                    style={{ color: "black" }} readOnly />
                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                            <div className="form-group">
                                                <p className="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1"> Address </p>
                                                <input id="" readOnly name="address" className="form-control colorchnage"
                                                    autocomplete="off" placeholder="Enter your address" value={profileDetail.address} />
                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                            <div className="form-group">
                                                <p className="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1"> Country </p>
                                                <input id="" readOnly name="country" className="form-control colorchnage" value={profileDetail.country}
                                                    placeholder="Enter your country" />
                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                            <div className="form-group">
                                                <p className="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1"> State/Province </p>
                                                <input id="" readOnly name="state" className="form-control colorchnage" placeholder="Enter your state/province" value={profileDetail.state} />
                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                            <div className="form-group">
                                                <p className="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1"> City </p>
                                                <input id="" readOnly name="city" className="form-control colorchnage" placeholder="Enter your city" value={profileDetail.city} />
                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                            <div className="form-group">
                                                <p className="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1"> Emergency Contact Person </p>
                                                <input id="" readOnly name="emergency_contact_person" className="form-control colorchnage" placeholder="Enter name of your emergency contact person" value={profileDetail.emergency_contact_person} />
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                            <div className="form-group">
                                                <p className="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1"> Emergency Country Code </p>
                                                <input className="form-control  colorchnage" name="emergency_country_code" value={profileDetail.emergency_country_code} readonly />
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                            <div className="form-group">
                                                <p className="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1"> Emergency Contact Number </p>
                                                <input id="" readOnly type="number" name="emergency_contact_no" className="form-control colorchnage" value={profileDetail.emergency_contact_no}
                                                    placeholder="Enter number of your emergency contact person" />
                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-8 mt-2 mt-md-3">
                                            <div className="form-group profile-gender mb-0">
                                                <p className="brand-color3 text-left font-14 mb-3"> Gender </p>
                                                <div className="profile-gender-button">
                                                    <input type='radio' id='male' value="male" name='radio' checked={gender=='male'} readonly  />
                                                    <label for='male'>Male</label>
                                                    <input type='radio' id='female' value="female" name='radio' checked={gender=='female'} readonly  />
                                                    <label for='female'>Female</label>

                                                    <input type='radio' id='other' value="other" name='radio' checked={gender=='other'} readonly  />
                                                    <label for='other'>Other</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-4 mt-2 mt-sm-4 mt-md-4 mt-lg-5 pt-0 pt-sm-4  password-align">
                                            <button type='button' className="brand-color1 font-14" onClick={() => window.history.back()}> Back </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <Innerfooter />

        </>
    )
}
