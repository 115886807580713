import moment from 'moment';
import React from 'react'
import { Link } from 'react-router-dom';
import NewPagination from './NewPagination';

export const ClassCart = ({ businessDetail, items,paginationData,changePage }) => {

    return (
        <>
            {businessDetail && items.length >0 ?
                <>
                    <div className="activity-card-section mt-4 pt-2">
                        <div className="row">
                            {items.map((items, index) => (
                                <div className="col-12 col-md-4 mb-4 ng-star-inserted" key={index}>
                                    <div className="activity-card box-shadow1 border-radius6 white-bg p-2 ng-star-inserted" >
                                        <div className="row">
                                            <div className="col-3 col-sm-3 col-md-12 col-lg-3">
                                                <Link to={'/class-detail'} state={{ class_id: items.class_id, scheduled_date: items.scheduled_date, id: items.id }}>
                                                    <div className="activity-card-logo">
                                                        <img src={businessDetail.logo} className="box-shadow1 new-log-img"  alt=''/>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="col-9 col-sm-9 col-md-12 col-lg-9 pl-0 pl-sm-0 pl-md-3 pl-lg-0">
                                                <Link to={'/class-detail'} state={{ class_id: items.class_id, scheduled_date: items.scheduled_date, id: items.id }}>
                                                    <div className="row">
                                                        <div className="col-7 col-md-12 col-lg-7 mt-0 mt-md-4 mt-lg-0 pl-0 pl-sm-0 pl-md-3 pl-lg-0">
                                                            <div className="activity-card-details text-left">
                                                                <h5 className="classname-title black-color mb-0">{items.class_name}</h5>
                                                                {items.instructor_details[0] &&
                                                                    <ul className="d-flex align-items-center py-2">
                                                                        <li><div className="subinst-profileimg">
                                                                            <img src={items.instructor_details[0].profile_img} className="img-fluid"  alt=''/>
                                                                        </div>
                                                                        </li>
                                                                        <li > <h5 className="black-color mb-0 classname-title pl-2">{items.instructor_details[0].name} {items.instructor_details[0].lastname} </h5> </li>
                                                                    </ul>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="col-5 col-md-12 col-lg-5 pr-1">
                                                            <div className="text-right pt-1">
                                                                <p className="black-color font-w-100 font-14 mr-1 mb-1 paratitle">{moment.unix(items.scheduled_date).format('MMM DD YYYY')} </p>
                                                                <div className="card-green-box text-center">
                                                                    <p className="white-color mb-0 paratitle">{items.capacity_used} / {items.total_capacity}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                                <div className="row pt-3">
                                                    <p className="black-color font-w-100 paratitle mb-0 d-flex">Location :

                                                        {items.location_url &&
                                                            <h5 className="heading-titlenew ml-2 mb-0 black-color">
                                                                <Link to={items.location_url} target="_blank">{items.location}</Link>
                                                            </h5>
                                                        }
                                                        {!items.location_url &&
                                                            <h5 className="heading-titlenew ml-2 mb-0 black-color">{items.location}</h5>
                                                        }
                                                    </p>
                                                </div>
                                                <Link to={'/class-detail'} state={{ class_id: items.class_id, scheduled_date: items.scheduled_date, id: items.id }}>
                                                    <div className="row pt-2">
                                                        <div className="col-6 col-sm-6 col-md-6 col-lg-6 px-0 border-right">
                                                            <div className="text-left">
                                                                <p className="paratitle black-color mb-0"> Start Time </p>
                                                                <h5 className="classname-title black-color mb-0">{moment.unix(items.from_time).format("hh:mm A")} </h5>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 col-sm-6 col-md-6 col-lg-6 d-flex justify-content-end">
                                                            <div className="text-left">
                                                                <p className="paratitle black-color mb-0">  Duration  </p>
                                                                <h5 className="classname-title black-color mb-0"> {items.duration}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <NewPagination data={paginationData} changePage={changePage}/>
                        </div>
                    </div>
                </>
                :
                <div className="activity-card-section mt-4 pt-2">
                    <div className="text-center">
                        <p className="font-awesome black-color font-18 d-inline mx-2 "> No
                            Classes Are scheduled</p>
                    </div>
                </div>
            }
        </>
    )
}
