import React, { useState } from 'react'
import { Col, Row, Form, Spinner } from 'react-bootstrap';

import { InputField } from '../../../../../sharedComponents/InputField';
import { SharedButton } from '../../../../../sharedComponents/Button';
import { SharedSelect } from '../../../../../sharedComponents/SharedSelect';
import { MyDatePicker } from '../../../../../sharedComponents/MyDatePicker';
import { TextArea } from '../../../../../sharedComponents/TextArea';
import logo from "../../../../../Images/logo_white_web.png"
import Swal from 'sweetalert2';
import { MyContext } from '../../../../../App';
import { useCol } from 'react-bootstrap/esm/Col';
import { useContext } from 'react';
import { useEffect } from 'react';
import { SharedMultiSelect } from '../../../../../sharedComponents/SharedMultiSelect';
// import { SharedMultiSelect } from '../../../../../sharedComponents/SharedMutiSelect';
import { Sharedmanyselectecheckbox } from '../../../../../sharedComponents/Sharedmanyselectecheckbox';
import { SharedMultiSelectorChekBox } from '../../../../../sharedComponents/SharedMultiSelectorChekBox';
import PlacesAutocomplete, { geocodeByAddress, getLatLng, } from 'react-places-autocomplete';

import { add_instructor, edit_instructor, instructor_details } from '../../../../../Studio-Services/Studio_Services';
import { get_skills } from '../../../../../Studio-Services/Studio_Services';
import { EditInstructorCheck } from './EditInstructorCheckBox';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import PlaceAutoCompleted from '../../../../../client-staff/Components/CommanPages/PlaceAutoCompleted';

export const EditInstructorform = ({ instructorData, loders, setLoder }) => {


    const [preData, setPreData] = useState();
    const [skillsdata, setSkillsdata] = useState();
    const [preskillsdata, setPreskillsdata] = useState([]);
    const [profileImg, setProfileImg] = useState();
    const [base64Image, setBase64Image] = useState('');
    const [checkid, setCheckid] = useState([]);
    const constext = useContext(MyContext);
    const [country, setCountry] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [preSkillsSelected, setPreSkillsSelected] = useState([]);
    const navigate = useNavigate();

    const [indata, setIndata] = useState({
        "fname": "", "lname": "", "email": "", "reg_number": "", "countycode": "",
        "mobile": "", "gender": "", "experience": "", "rate_hourly": "", "rate_flat_wage": "",
        "start_date": "", "dob": "", "address": "", "street": "", "city": "", "state": "",
        "country": "", "zip_code": "", "status": "", "employee_id": "", "employee_contractor": "",
        "about": "", "profile_img": "",
    });

    const [error, setError] = useState({
        "fname": "", "lname": "", "email": "", "reg_number": "", "countycode": "",
        "mobile": "", "gender": "", "experience": "", "rate_hourly": "", "rate_flat_wage": "",
        "start_date": "", "dob": "", "address": "", "street": "", "city": "", "state": "",
        "country": "", "zip_code": "", "status": "", "employee_id": "", "employee_contractor": "",
        "about": "", "profile_img": "",
    });

    const getInstructorData = async () => {
        const fData = { "instructor_id": instructorData }
        setLoder(true);
        const resp = await instructor_details(fData);
        if (resp) {
            setLoder(false);
            const d = resp.data.data;
            setPreData(resp.data.data);

            setIndata({
                "id": d.id, "fname": d.name, "lname": d.lastname, "email": d.email, "reg_number": d.registration, "countycode": "",
                "mobile": "", "gender": d.gender, "experience": d.total_experience, "rate_hourly": d.appointment_fees, "rate_flat_wage": d.appointment_fees_type,
                "start_date": moment.unix(d.start_date).format("YYYY-MM-DD"), "dob": d.date_of_birth, "address": d.address, "street": d.street, "city": d.city, "state": d.state,
                "country": d.country, "zip_code": d.zipcode, "status": d.business_status, "employee_id": d.employee_id, "employee_contractor": d.employee_contractor,
                "about": d.about, "profile_img": d.profile_img, "skill": d.skill
            })

        }
        setLoder(false);
    }


    const getskillsData = async () => {
        const preData = [];
        setLoder(true);
        const resp = await get_skills();
        if (resp) {
            setLoder(false);
            if (resp.data.data) {
                const data = resp.data.data;
                data.map((e) => (
                    preData.push({
                        id: e.id, label: e.name
                    })
                ))
                setPreskillsdata(preData);
            }
            setLoder(false);
        }
        setLoder(false);
    }

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64String = reader.result;
            setBase64Image(base64String);
        };
        reader.readAsDataURL(file);
    };

    // const handleMultiSelectChange = (selected) => {
    //     setSelectedOptions(selected);
    // };

    useEffect(() => {
        const finalSkils = [];
        if (skillsdata) {
            skillsdata.map((e) => (finalSkils.push(e.id)));
            setIndata((pre) => ({ ...pre, ["skill"]: finalSkils }));
        }
    }, [skillsdata]);

    const [state, setState] = useState({ "address": "", "eAddress": "" });

    const opgander = [
        { value: "male", label: "Male" },
        { value: "female", label: "Female" },
        { value: "other", label: "Other" },
    ];

    const statusops = [
        { value: "Active", label: "Active" },
        { value: "Inactive", label: "In-Active" }
    ]

    const employeeContractorOps = [
        { value: "Employee", label: "Employee" },
        { value: "Contractor", label: "Contractor" },
        { value: "Volunteer", label: "Volunteer" },
    ];


    const SelectedValue = (data, prdata) => {
        const preSkill = (data).split(',');
        const preSelect = [];
        prdata.forEach(op => {
            preSkill.forEach(sk => {
                if (op.id === sk) {
                    preSelect.push({
                        id: op.id, label: op.label
                    })
                }
            });
        });
        return preSelect;
    }

    useEffect(() => {
        if (preData) { }
        if (preData && preData.skill) {
            const skil = preData.skill;
            const resp = SelectedValue(skil, preskillsdata);
            if (resp.length > 0) {
                setPreSkillsSelected(resp);
            }
        }
        if (preData && preData.address) { setIndata((pre) => ({ ...pre, "address": preData.address })); }
    }, [preData])


    const [cordinates, setCordinates] = useState();
    useEffect(() => {
        if (cordinates) {
            setIndata((pre) =>({ ...pre, "address": cordinates.getAddress, "street": cordinates.getAddress, "city": cordinates.getCity, "state": cordinates.getState, "country": cordinates.getCountry, "zip_code": cordinates.getZIP_Code }));
        }
    }, [cordinates])


    useEffect(() => {
        if (instructorData) { getInstructorData(); }
    }, [instructorData, preskillsdata]);


    useEffect(() => {
        if (constext.context && constext.context.country != "") {
            setCountry(constext.context.country);
        }
    }, [constext.context])

    useEffect(() => {
        getskillsData();
    }, [])



    const handleChange = (address) => {
        setState({ address: address, eAddress: "", });
    };

    const handleSelect = async (address) => {
        console.log("address---", address);
        setState({ address: address });
    };



    const changeHandler = (e) => {
        const { name, value } = e.target;
        if (name === "fname") { setError((pre) => ({ ...pre, efname: "" })) }
        if (name === "lname") { setError((pre) => ({ ...pre, elname: "" })) }
    }

    const inputHandler = (e) => {
        let { name, value } = e.target;
        setIndata((pre) => ({ ...pre, [name]: value }));
        setError((pre) => ({ ...pre, [name]: "" }));
    }




    const isSubmit = async (event) => {
        event.preventDefault();
        let isValid = 1;

        // const finalSkils = [];
        // if (skillsdata) {
        //     skillsdata.map((e) => (finalSkils.push(e.id,)))
        // }

        const formData = new FormData(event.target); // Create a FormData object from the form
        const formValues = Object.fromEntries(formData.entries());


        //   const profile_img = formValues.profile_img;
        const fname = indata.fname //formValues.fname;
        const lname = indata.lname // formValues.lname;
        const email = indata.email;
        const reg_number = indata.reg_number;
        const countycode = indata.countycode;
        const mobile = indata.mobile;
        const gender = indata.gender;
        const experience = indata.experience;
        const rate_hourly = indata.rate_hourly;
        const rate_flat_wage = indata.rate_flat_wage;
        const mystart_date = moment(indata.start_date, 'DD-MM-YYYY').unix();
        const dob = indata.dob;
        const address = indata.address;
        const street = indata.street;
        const city = indata.city;
        const state = indata.state;
        const country = indata.country;
        const zip_code = indata.zip_code;
        const status = indata.status;
        const employee_id = indata.employee_id;
        const employee_contractor = indata.employee_contractor;
        const about = indata.about;
        const profile_img = event.target.profile_img.files[0];
        const instructor_id = formValues.instId;
        const sin_no = formValues.sin_no;
        const substitute_instructor_name = formValues.substitute_instructor_name;
        // const profile_img = formValues.profile_img.files[0];
        // const profile_img="";



        if (!fname) { setError((pre) => ({ ...pre, fname: "Required" })); isValid = 2 };
        if (!lname) { setError((pre) => ({ ...pre, lname: "Required" })); isValid = 3 };
        if (!email) { setError((pre) => ({ ...pre, email: "Required" })); isValid = 4 };
        // if (!gander) { setError((pre) => ({ ...pre, gander: "Required" })); isValid = 7 };
        // if (!experience) { setError((pre) => ({ ...pre, experience: "Required" })); isValid = 8 };
        // if (!rate_hourly) { setError((pre) => ({ ...pre, rate_hourly: "Required" })); isValid = 9 };
        // if (!rate_flat_wage) { setError((pre) => ({ ...pre, rate_flat_wage: "Required" })); isValid = 10 };
        if (!mystart_date) { setError((pre) => ({ ...pre, start_date: "Required" })); isValid = 11 };
        // if (!dob) { setError((pre) => ({ ...pre, dob: "Required" })); isValid = 0 };
        if (!address) { setError((pre) => ({ ...pre, address: "Required" })); isValid = 12 };
        if (!street) { setError((pre) => ({ ...pre, street: "Required" })); isValid = 13 };
        if (!city) { setError((pre) => ({ ...pre, city: "Required" })); isValid = 14 };
        if (!state) { setError((pre) => ({ ...pre, state: "Required" })); isValid = 15 };
        if (!country) { setError((pre) => ({ ...pre, country: "Required" })); isValid = 16 };
        if (!status) { setError((pre) => ({ ...pre, status: "Required" })); isValid = 17 };
        if (!employee_contractor) { setError((pre) => ({ ...pre, employee_contractor: "Required" })); isValid = 18 };
        // if (!about) { setError((pre) => ({ ...pre, about: "Required" })); isValid = 19 };

        if (isValid === 1) {
            // event.target.reset();

            loders(true);
            const finalData = new FormData();


            finalData.append("image", profile_img);
            finalData.append('name', fname);
            finalData.append('lastname', lname);
            finalData.append('email', email);
            finalData.append('role', '4');
            finalData.append('skills', indata.skill);
            finalData.append('experience', experience);
            finalData.append('appointment_fees', rate_hourly);
            finalData.append('appointment_fees_type', rate_flat_wage);
            finalData.append('start_date', mystart_date);
            finalData.append('subsitute_instructor_name', substitute_instructor_name);
            finalData.append('status', status);
            finalData.append('employee_contractor', employee_contractor);
            finalData.append('sin_no', sin_no);
            finalData.append("employee_id", employee_id);


            finalData.append('city', city);
            finalData.append('country', country);
            finalData.append('state', state);
            finalData.append('zipcode', zip_code);
            finalData.append('address', address);
            finalData.append('street', street);
            finalData.append('lattitude', "");
            finalData.append('longitude', "");
            finalData.append('instructor_id', instructor_id);
            finalData.append('gender', gender);


            finalData.append('about', about);
            finalData.append('registration', reg_number);
            finalData.append('dob', dob);
            finalData.append('instructor_status', status);

            const resp = await edit_instructor(finalData);


            if (resp) {
                loders(false);
                Swal.fire({
                    html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
                        <h4 class="title text-uppercase text-center mt-4 textWhite">
                        SUCCESS
                    </h4>
                    <p class="text-center my-4 textcolor">
                    <small class="textWhite">
                    ${resp.data.message} </small></p>
                    `,
                    showClass: {
                        popup: 'custom-dialog-container',
                    },
                    customClass: {
                        confirmButton: 'custom-button custom-button-size modalbtn',
                    },
                }).then(() => {

                    navigate('/list-instructor'); // Replace '/other-page' with the desired path

                });
            } else {
                loders(false);
            }
        }
    }

    return (
        <>
            <h4>STAFF/INSTRUCTOR PROFILE</h4>
            <Form autoComplete="off" onSubmit={isSubmit} encType="multipart/form-data">
                <Row>
                    <Col>
                        <InputField type={"hidden"} name="instId" value={preData && preData.id ? preData.id : ""} />
                        <InputField type={"hidden"} name="sin_no" value={preData && preData.sin_no ? preData.sin_no : ""} />
                        <InputField type={"hidden"} name="substitute_instructor_name" value={preData && preData.substitute_instructor_name ? preData.substitute_instructor_name : ""} />
                        <div className="ProfilePhoto">
                            <InputField
                                accept="image/x-png,image/gif,image/jpeg,image/png"
                                type="file"
                                name="profile_img"
                                onChange={handleImageUpload}

                                className={"FileTypeHide"} />
                            <img
                                src={base64Image ? base64Image : preData && preData.profile_img ? preData.profile_img : "assets/images/image_Holder.png"}
                                alt=""
                                className="img-fluid"
                            />
                            <p>Change Image</p>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <InputField
                            type={"text"}
                            lable={"First Name"}
                            error={error.fname}
                            onChange={inputHandler}
                            value={indata.fname}
                            placeholder={"Enter First Name"}
                            name="fname"
                        />
                    </Col>
                    <Col>
                        <InputField
                            type={"text"}
                            lable={"Last Name"}
                            name="lname"
                            onChange={inputHandler}
                            error={error.lname}
                            value={indata.lname}
                            placeholder={"Enter Last Name"}
                        />
                    </Col>
                    <Col>
                        <InputField
                            type={"text"}
                            lable={"Email"}
                            readOnly={true}
                            error={error.email}
                            name="email"
                            value={indata.email}
                            placeholder={"Enter Email"}
                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <InputField
                            type={"text"}
                            error={error.reg_number}
                            lable={"Registration Number"}
                            onChange={inputHandler}
                            name="reg_number"
                            value={indata.reg_number}
                            placeholder={"Registration Number"}
                        />
                    </Col>
                    <Col>
                        <EditInstructorCheck
                            labelText="Skills/Specialisation"
                            setSkillsdata={setSkillsdata}
                            name="skills"
                            className={"form-label inputLable"}
                            value={preSkillsSelected}
                            options={preskillsdata}
                            setLoder={setLoder}
                            selectedOptions={selectedOptions}
                        // onChange={handleMultiSelectChange}
                        />
                    </Col>

                    <Col>
                        <InputField
                            type={"number"}
                            name="experience"
                            error={error.experience}
                            onChange={inputHandler}
                            minLength={"0"}
                            lable={"Years of Experience"}
                            value={indata.experience}
                            placeholder={"Years of Experience"}
                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <InputField
                            type={"number"}
                            name="rate_hourly"
                            minLength={0}
                            error={error.rate_hourly}
                            lable={"Hourly Wage Rate"}
                            onChange={inputHandler}
                            value={indata.rate_hourly}
                            placeholder={"Enter Rate"}
                        />
                    </Col>
                    <Col>
                        <InputField
                            type={"number"}
                            name="rate_flat_wage"
                            minLength={0}
                            error={error.rate_flat_wage}
                            onChange={inputHandler}
                            lable={"Flat Wage Rate"}
                            value={indata.rate_flat_wage}
                            placeholder={"Enter Rate"}
                        />
                    </Col>
                    <Col>
                        <InputField type="date" lable="Start Date" max={moment().format('YYYY-MM-DD')} onChange={inputHandler} name={"start_date"} value={indata.start_date} />

                        {/* <MyDatePicker
                            name="start_date"
                            className={"form-label inputLable"}
                            error={error.start_date}
                            onChange={inputHandler}
                            value={indata.start_date}
                            DatepickerLabel={"Start Date"} /> */}
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <InputField type="date" lable="Date Of Birth" max={moment().format('YYYY-MM-DD')} onChange={inputHandler} name="dob" value={indata.dob} />
                        {/* <MyDatePicker
                            name="dob"
                            error={error.dob}
                            className={"form-label inputLable"}
                            onChange={inputHandler}
                            value={indata.dob}
                            DatepickerLabel={"Date Of Birth"} /> */}
                    </Col>
                    <Col>
                        <div className="form-group">
                            <label htmlFor="name" className='form-label inputLable'>Address</label>

                            <PlaceAutoCompleted
                                //   label={'Head Quarters Address'}
                                value={indata.address}
                                setCordinates={setCordinates}
                            />

                            {/* <PlacesAutocomplete
                                name="address"
                                value={state.address}
                                onChange={handleChange}
                                onSelect={handleSelect}
                              
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div>
                                        <input name="address" {...getInputProps({ placeholder: 'Search Places ...', className: ' form-control location-search-input', })} />
                                        <span className="text-danger"><small>{state.eAddress ? state.eAddress : ""}</small></span>
                                        <div className="autocomplete-dropdown-container m-0 position-absolute " style={{ zIndex: "2200", }}>
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map((suggestion, i) => {

                                                const className = suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item';
                                                const style = suggestion.active
                                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                return (
                                                    <div key={i}
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style,
                                                        })}
                                                    >
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete> */}
                            <small>{error.address}</small>
                        </div>
                    </Col>
                    <Col>
                        <InputField
                            type={"text"}
                            lable={"Street"}
                            name="street"
                            readOnly={true}
                            error={error.street}
                            value={indata.street}
                            placeholder={"Enter Street"}
                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <InputField
                            type={"text"}
                            lable={"City"}
                            readOnly={true}
                            name="city"
                            error={error.city}
                            value={indata.city}
                            placeholder={"Enter City"}
                        />
                    </Col>
                    <Col>
                        <InputField
                            type={"text"}
                            lable={"Province State"}
                            name="state"
                            readOnly={true}
                            error={error.state}
                            value={indata.state}
                            placeholder={"Enter State"}
                        />
                    </Col>
                    <Col>
                        <InputField
                            type={"text"}
                            lable={"Country"}
                            readOnly={true}
                            name="country"
                            error={error.country}
                            value={indata.country}
                            placeholder={"Country"}
                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <InputField
                            type={"text"}
                            name="zip_code"
                            lable={"Postal/Zip Code"}
                            readOnly={true}
                            error={error.zip_code}
                            value={indata.zip_code}
                            placeholder={"Enter Zip Code"}
                        />
                    </Col>
                    <Col>
                        <SharedSelect
                            lable={"Gender"}
                            name="gender"
                            onChange={inputHandler}
                            error={error.gender}
                            value={indata.gender}
                            options={opgander}
                        />
                    </Col>

                    <Col>
                        <InputField
                            type={"text"}
                            lable={"Employee Id"}
                            name="employee_id"
                            onChange={inputHandler}
                            error={error.employee_id}
                            value={indata.employee_id}
                            placeholder={"Enter Employee Id"}
                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <SharedSelect
                            name="employee_contractor"
                            onChange={inputHandler}
                            error={error.employee_contractor}
                            options={employeeContractorOps}
                            value={indata.employee_contractor}
                            lable={"Employee/Contractor"} />
                    </Col>
                    <Col>
                        <SharedSelect
                            name="status"
                            options={statusops}
                            onChange={inputHandler}
                            error={error.status}
                            value={indata.status}
                            lable={"Status"} />
                    </Col>
                    <Col></Col>
                </Row>
                <Row className='mt-3'>
                    <Col>
                        <TextArea
                            name="about"
                            error={error.about}
                            onChange={inputHandler}
                            value={indata.about}
                            lable={"About"} />
                    </Col>
                </Row>
                <Row className="py-5">
                    <Col>
                        <SharedButton
                            variant={"primary"}
                            type="submit"
                            className={"UpdatedBtn w-100"}
                            title={"Save & Update"}
                        />
                    </Col>
                    <Col>
                        <SharedButton
                            href={"/add-people"}
                            variant={"outline"}
                            type={"button"}
                            className={"CancelBtn w-100"}
                            title={"Done With Staff/Instructor"}
                        />
                    </Col>
                </Row>
            </Form>
        </>
    )
}
