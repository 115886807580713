import React from "react";
import { useState } from "react";
import { Row, Col, Card, InputGroup, Carousel, Button } from "react-bootstrap";

import { FaLongArrowAltRight, FaLongArrowAltLeft } from "react-icons/fa";

export const CalenderPassEdit = (props) => {

  const cardDetail = props.pass ? props.pass : "";
  const logo = localStorage.getItem("logo");


  const [selectedPasses, setSelectedPasses] = useState([]);



  // Event handler when a pass is checked/unchecked
  const handlePassCheck = (status,id) => {
    // console.log("Status--<",status,"id--->",id);

    // setSelectedPasses((prevSelectedPasses) =>
    //   prevSelectedPasses.includes(passId)
    //     ? prevSelectedPasses.filter((id) => id !== passId)
    //     : [...prevSelectedPasses, passId]
    // );
  };

  const chunks = [];
  for (let i = 0; i < cardDetail.length; i += 3) {
    chunks.push(cardDetail.slice(i, i + 3));
  }

  const settings = {
    prevIcon: <FaLongArrowAltLeft />,
    nextIcon: <FaLongArrowAltRight />,
    interval: 3000,
    pauseOnHover: true,
    wrap: true,
    touch: true,
    keyboard: true,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const handleFormSubmit = () => {
    // Process the selected passes once the user finishes making their selections
    // console.log("Selected Passes:", selectedPasses);
    // Add your logic here to handle the selected passes, such as submitting the form or updating the state.
  };

  return (
    <>
    {chunks.length > 0 && (
        <Carousel {...settings}>
          {chunks.map((chunk, index) => (
            <Carousel.Item key={index}>
              <Row>
                {chunk.map((value, cardIndex) => (
                  <Col lg={4} md={4} sm={12} key={cardIndex}>
                    <Card className="blueback-pass">
                      <div className="cardImg">
                        <Card.Img variant="top" src={value.memberShipImg} />
                        <p>{value.validText}</p>
                      </div>
                      <Card.Body>
                        <Card.Title>{value.cardTitle} </Card.Title>
                        <Card.Text>
                          {value.price} <small>{value.tax}</small>
                        </Card.Text>
                      </Card.Body>
                      <InputGroup.Checkbox
                        className="membercheckbox"
                        aria-label="Checkbox for following text input"
                        checked={value.is_added=== 1 ? true : false}
                        onChange={(e)=>{
                            const status = e.target;
                            const pass_id = value.passId
                            handlePassCheck(status,pass_id)}}
                      />
                    </Card>
                  </Col>
                ))}
              </Row>
            </Carousel.Item>
          ))}
        </Carousel>
      )}
     
    </>
  );
};
