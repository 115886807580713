import moment from "moment";
import React from "react";
import { Carousel, Card, Row, Col } from "react-bootstrap";
import { FaLongArrowAltRight, FaLongArrowAltLeft } from 'react-icons/fa';

export const StaffCardCarousel = ({ pass, getPassId, checkedPassId }) => {

    const cardDetail = pass ? pass : [];
    const isChecked = checkedPassId || "";


    //   const chunks = [];
    //   for (let i = 0; i < cardDetail.length; i += 3) {
    //     chunks.push(cardDetail.slice(i, i + 3));
    //   }

    const settings = {
        prevIcon: <FaLongArrowAltLeft />,
        nextIcon: <FaLongArrowAltRight />,
        interval: 10000,
        pauseOnHover: true,
        wrap: true,
        touch: true,
        keyboard: true,
        slidesToShow: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <>
            {cardDetail.length > 0 && (
                <Carousel {...settings}>
                    {cardDetail.map((items, index) => (
                        <Carousel.Item key={index}>
                            <div>
                                <div>
                                    <div class="available-card">
                                        <div class="available-blue-card p-3 p-sm-4">
                                            <div class="row mx-0">

                                                <div class="col-4 pass-logo">
                                                    <img src={items.business_logo} class="img-fluid" alt='' />
                                                </div>
                                                <div class="col-8 flex-fill pr-4 pl-5 passTextwidth ">
                                                    <h5 class="white-color font-14 mb-2 mt-0"> {items.pass_name} </h5>
                                                    <h5 class="white-color font-14 mb-2 mt-0">$ {items.amount}  <span class="font-12"> Plus Tax
                                                    </span></h5>
                                                </div>
                                            </div>
                                            <div class="row mt-5 align-items-end">
                                                <div class="col-8 text-left pr-0">
                                                    {items.pass_type_category == 'PunchCard' &&
                                                        <p class="white-color paratitle">Remaining Classes :  {items.remaining_count}  of  {items.total_count} </p>
                                                    }
                                                    {items.pass_type_category == 'TimeFrame' || items.pass_type_category == 'Other' || items.pass_type_category == 'Recurring Membership' ?
                                                        <>
                                                            <p class="white-color paratitle">
                                                                Remaining Days :  {items.remaining_count}  of  {items.total_count} </p>
                                                        </>
                                                        : ""}
                                                    <p class="mb-0 white-color paratitle"> Start Date :  {moment.unix(items.start_date_utc).format('MMM DD YYYY')}  </p>
                                                    <p class="white-color paratitle">Expiration Date :{moment.unix(items.end_date_utc).format('MMM DD YYYY')}  </p>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="studio-card-select">
                                            <input
                                                type="radio"
                                                id={items.pass_id}
                                                value={items.pass_id}
                                                name="passid"
                                                checked={items.pass_id === isChecked}
                                                onClick={(event) => getPassId(event)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Carousel.Item>
                    ))}
                </Carousel>
            )}
        </>
    )
}
