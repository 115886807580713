import React from "react";
import Sidebar from "../../comman_page/Sidebar";
import { HeaderDashboard } from "../../comman_page/HeaderDashboard";
import { Container, Row, Col, Tabs } from "react-bootstrap";
import { DataTable } from "../../../../../sharedComponents/DataTable";
import SharedTabs from "../../../../../sharedComponents/SharedTabs";
import { Footer } from "../../../../../public/containers/footer/Footer";
import { LiveSearch } from "../../../../../sharedComponents/LiveSearch";
import { SharedButton } from "../../../../../sharedComponents/Button";
import { InputField } from "../../../../../sharedComponents/InputField";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import { list_shift } from "../../../../../Studio-Services/Studio_Services";
import { format } from "date-fns";
import { Shifttable } from "./Shifttable";

export default function Addstafshift() {
  return (
    <>
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col className="p-0 RightCol">
              <HeaderDashboard />
              <div className="RightSide">
                <section className="addClient innerContent">
                  <Container className="pt-5">
                    <h4>List Your Shifts</h4>
                    <p>
                      Shifts are required for clients to be able to book a
                      service appointment. Staff can also be set up for a shift
                      to work without offering appointments (non service) such
                      as working the front desk.
                    </p>
                    <div className="AddClientForm AddClientContent">
                      <Shifttable />
                    </div>
                  </Container>
                </section>
                <Footer />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
