import React from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { InputField } from './InputField'
import { SharedSelect } from './SharedSelect'
import { MyDatePicker } from './MyDatePicker'
import { SharedButton } from './Button'
import { useEffect } from 'react'
import { useState } from 'react'
import { get_countries } from '../Studio-Services/Studio_Services'
import { update_client } from '../Studio-Services/Studio_Services'
import logo from "../Images/logo_white_web.png"
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'


export const EditClientForm = ({value}) => {

  const navigate = useNavigate();
  const [error, setError] = useState({efname: "", elname: "", eemail: "", emobile: "", ecountry: "", egender: "", edob: "", });
  const [country, setCountry] = useState([]);

  const getcountry = async () => {
    const data = [];
    const resp = await get_countries();
    if (resp) {
      if (resp.data) {
        resp.data.forEach(element => {
          data.push({
            label: element.name,
            value: element.code,
          })
        });
      }
    }
    setCountry(data);
  }

  const changeHandler = (e) => {const { name, value } = e.target; setError((pre) => ({ ...pre,  efname: "", elname: "", eemail: "", emobile: "", ecountry: "", egender: "", edob: "", })); }

  const gender = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "other", label: "Other" }
  ];

  const convertDateFormat = (dateString) => {
    const parts = dateString.split('/');
    const convertedDate = parts.join('-');
    return convertedDate;
  };

  const submit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target); // Create a FormData object from the form
    const formValues = Object.fromEntries(formData.entries());
    let isValid = 1;

    const fname = formValues.fname;
    const lname = formValues.lname;
    const email = formValues.email;
    const mobile = formValues.mobile;
    const country = formValues.countycode;
    const gender = formValues.gender;
    const dob = formValues.dob;
    const ref = formValues.refrence;
    const clientid = formValues.id;

    // const convertedDate = convertDateFormat(dob);

    if (!fname) { setError((pre) => ({ ...pre, efname: "First Name is required" })); isValid = 0 };
    if (!lname) { setError((pre) => ({ ...pre, elname: "Last name is required" })); isValid = 0 };
    if (!email) { setError((pre) => ({ ...pre, eemail: "Email is required" })); isValid = 0 };
    if (!mobile) { setError((pre) => ({ ...pre, emobile: "Mobile Number is required" })); isValid = 0 };
    if (!country) { setError((pre) => ({ ...pre, ecountry: "Country Code is required" })); isValid = 0 };
    if (!dob) { setError((pre) => ({ ...pre, edob: "Date of birth is required" })); isValid = 0 };
    if (!gender) { setError((pre) => ({ ...pre, egender: "required" })); isValid = 0 };

    if (isValid === 1) {
    const finalData =  {
        "first_name": fname,
        "last_name": lname,
        "email": email,
        "mobile": mobile,
        "discount": "0",
        "referred_by": ref,
        "client_id": clientid,
        "country_code": country,
        "date_of_birth": dob,
        "gender": gender
      }

      const resp = await update_client(finalData);
   
      if (resp) {
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
        <h4 class="title text-uppercase text-center mt-4 textWhite">
        SUCCESS
      </h4>
      <p class="text-center my-4 textcolor">
      <small class="textWhite">
     ${resp.data.message} </small></p>
      `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            navigate('/list-clients'); // Replace '/other-page' with the desired route path
          }
        });
      }
    }

  }

  useEffect(() => {
    getcountry();
  }, [])


  return (
    <>
      <Form onSubmit={submit}>
        <Row className='mt-5'>
        <InputField type={"hidden"} value={(value && value.id) ? value.id : ""} name={"id"}  />
          <Col>
            <InputField type={"text"} lable={"First Name"} onChange={changeHandler} value={(value && value.first_name) ? value.first_name : ""} name={"fname"} error={error.efname} />
          </Col>
          <Col>
            <InputField type={"text"} lable={"Last Name"} value={(value && value.last_name) ? value.last_name : ""} name={"lname"} error={error.elname} />
          </Col>
          <Col>
            <InputField type={"email"} lable={"Email Address"} value={(value && value.email) ? value.email : ""} name={"email"} error={error.eemail} />
          </Col>
        </Row>
        <Row>
          <Col>
            <SharedSelect lable={"Country Code"} value={(value && value.contry_code) ? value.contry_code : ""} options={country ? country : ""} name={"countycode"} error={error.ecountry} />
          </Col>
          <Col>
            <InputField type="tel" lable="Mobile/Cell" value={(value && value.mobile) ? value.mobile : ""} maxLength={10} name={"mobile"} error={error.emobile} />
          </Col>
          <Col>
          <InputField type="date" lable="Date of birth" max={moment().format('YYYY-MM-DD')} value={(value && value.date_of_birth)?value.date_of_birth:""} onChange={changeHandler} name={"dob"} error={error.edob} />
            {/* <MyDatePicker DatepickerLabel={"Date of birth"} selectedDate={(value && value.date_of_birth)?value.date_of_birth:""} name={"dob"} error={error.edob} /> */}
          </Col>
        </Row>
        <Row>
          <Col>
            <SharedSelect lable={"Gender"} value={(value && value.gender) ? value.gender : ""} options={gender} name={"gender"} error={error.egender} />
            {/* <InputField type={"text"} lable="Gender"/> */}
          </Col>
          <Col>
            <InputField type={"test"} lable="Referred by" value={(value && value.referred_by) ? value.referred_by : ''} name={"refrence"} />
          </Col>
          <Col>
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col>
            <SharedButton type={"submit"} variant={"primary"} title={"Save"} className={"btnform w-100"} />
          </Col>
          <Col>
            <SharedButton  variant={"Outline"} title={"BACK TO CLIENT LIST"} className={"btnform w-100"} href={"/list-clients"} />
          </Col>
          <Col></Col>
        </Row>
      </Form>
    </>
  )
}
