import React from "react";
import { HeaderDashboard } from "../../comman_page/HeaderDashboard";
import { Col, Container, Row ,Spinner} from "react-bootstrap";
import { DataTable } from "../../../../../sharedComponents/DataTable";
import { Footer } from "../../../../../public/containers/footer/Footer";
import Sidebar from "../../comman_page/Sidebar";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { cart_list } from "../../../../../Studio-Services/Studio_Services";
import { Button } from "bootstrap";
import { Cart } from "../../../../../client-staff/Pages/cart/MyCart";
import { SharedButton } from "../../../../../sharedComponents/Button";
import { IncrementDecrementInput } from "../../../../../sharedComponents/IncrementDecrementInput";
import { InputField } from "../../../../../sharedComponents/InputField";
import { CartTable } from "./CartTable";

export const CheckOut = () => {
  const location = useLocation();
  const [client_id, setClient_id] = useState();
  const [family_member_id,setFamily_member_id] = useState();
  const [loder, setLoder] = useState(false);

  useEffect(() => {
    localStorage.removeItem('clientID');
    setClient_id(location.state.id);
    setFamily_member_id(location.state.family_member_id);
    localStorage.setItem('clientID',location.state.id);
  }, [location]);

 
  
  return (
    <>
    {loder ?
        <div className='MainLoader'>
          <Spinner animation="border" role="status" className='Loader'>
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
        : ""}
        <Row>
    <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col xl={11} lg={11} >
              <div className="RightSide">
                <HeaderDashboard />
                <section className="addClient innerContent">
                  <div className="AddClientContent p-1" style={{margin:"0px"}}>
                    <Row style={{justifyContent:"center"}}>
                        <Col  style={{maxWidth:"80rem" , overflow:'scroll'}}>
                          <CartTable client_id={family_member_id ? family_member_id : client_id} setLoder={setLoder} />
                          </Col>
                    </Row>
                  </div>
                </section>
                <Footer />
              </div>
            </Col>
          </Row>
        </div>
      </div>
      </Row>
    </>
  );
};
