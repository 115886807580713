import React from 'react'
import { InnerHeader } from '../../Components/inner-header/InnerHeader1'
import { SubHeader } from '../../Components/sub-header/SubHeader'
import { Innerfooter } from '../../Components/inner-footer/Innerfooter1'

import { useLocation } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { ClassCart } from '../../Components/CommanPages/ClassCart'
import { WorkShopCart } from '../../Components/CommanPages/WorkShopCart'
import { DashboardToSectionDetails } from '../../Components/CommanPages/DashboardToSectionDetails'
import { InputField } from '../../../sharedComponents/InputField'
import { Staff_class_list, Staff_class_list_New_API, staff_workshop_list } from '../../../Static_Services/Staff_Services'
import moment from 'moment'


export const StaffClasses = () => {
  const location = useLocation();
  const currentUrl = location.pathname;
  const [selectedDate, setSelectedDate] = useState();
  const [class_status, setClass_status] = useState(0)
  const [paginationData, setPaginationData] = useState();

  // User Detail Section
  const [userDetail, setUserDetail] = useState();
  const storedData = localStorage.getItem("userDetail");
  const parsedData = JSON.parse(storedData);
  useEffect(() => {
    setUserDetail(parsedData);
  }, [])

  const [businessDetail, setBusinessDetail] = useState();
  const bzDetails = localStorage.getItem('bzDetails');
  useEffect(() => {
    if (bzDetails) {
      setBusinessDetail(JSON.parse(bzDetails));
    }
  }, [bzDetails]);


  // Class Action
  const [classitem, setClassitems] = useState([]);
  const [workshopItem, setWorkshopItem] = useState([]);


  const classItemHandler = async (status = 0, dates = '', page = 1) => {

    if (businessDetail && businessDetail.business_id) {

      if (currentUrl === '/workshopss') {
        const WorkData = {
          "pageid": page,
          "business_id": businessDetail.business_id,
          "upcoming_date": dates,
          "workshop_status": status
        }
        const wrkresp = await staff_workshop_list(WorkData);
        if (wrkresp) {
          setWorkshopItem(wrkresp.data.data);
        } else {
          setWorkshopItem([]);
        }
      }
      if (currentUrl === '/classess') {
        const clsData = {
          "pageid": page,
          "business_id": businessDetail.business_id,
          "upcoming_date": dates,
          "class_status": status,
        }
        const clsresp = await Staff_class_list_New_API(clsData);
        if (clsresp) {
          if (clsresp && clsresp.data && clsresp.data.paginationInfo) {
            setPaginationData(clsresp.data.paginationInfo);
          }
          setClassitems([]);
          setClassitems(clsresp.data.data);
        } else {
          setClassitems([]);
        }
      }
    }
  }

  useEffect(() => {
    classItemHandler(class_status);
  }, [class_status, businessDetail, currentUrl])


  const dateChangeHandler = (e) => {
    let { name, value } = e.target;
    const timestampInSeconds = moment(value).unix();
    setSelectedDate(timestampInSeconds)
    classItemHandler(0, timestampInSeconds);
  }

  const classPageHandler = (page) => {
    classItemHandler(class_status, selectedDate, page);
  }

  const reset = () => {
    classItemHandler(0);
    setClass_status(0)
  }

  const selectHandler = (e) => {
    const value = e.target.value;
    setClass_status(value);
  }
  //  End Business Detail Section

  return (
    <>
      <InnerHeader />
      <div className="body-grey">
        <div className="studio-details">
          <div className="container">

            <DashboardToSectionDetails />

            {/* Studio Classes */}
            <div className="studio-details">
              <div className="text-right mb-3">
                <button type="button" className="btn btn-blue-grad px-2 text-uppercase " onClick={() => window.history.back()}  >Back </button>
              </div>
              <div className="studio-details-lower pb-5">
                <div className="row">
                  <div className="col-12">
                    <div className="white-bg border-radius6 box-shadow1 p-3">

                      {/* Form Section  */}
                      <div className='row'>
                        <div className="col-12 col-md-5">
                          <div className="centered-cover w-100">
                            <div className="flex-break">
                              <div className="std-detail-select position-relative w-100">
                                <select className="form-control brand-color1 border-radius6" onChange={selectHandler}>
                                  {currentUrl == '/classess' &&
                                    <>
                                      <option value="3" selected={class_status === 3} > All Future Classes </option>
                                      <option value="0" selected={class_status === 0} > My Future Classes </option>
                                      <option value="2" selected={class_status === 2} > My Cancelled Classes </option>
                                      <option value="1" selected={class_status === 1} > My Class History </option>
                                      <option value="4" selected={class_status === 4} > All Classes History </option>
                                    </>
                                  }
                                  {currentUrl == '/workshopss' &&
                                    <>
                                      <option value="3" selected={class_status === 3} > All Workshop </option>
                                      <option value="0" selected={class_status === 0} > My Future Workshops </option>
                                      <option value="2" selected={class_status === 2} > My Cancelled Workshops </option>
                                      <option value="1" selected={class_status === 1} > My Workshop History </option>
                                    </>
                                  }
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-12 col-md-5">
                          <div className="text-right w-100">
                            <div className="flex-break">
                              <div className="std-detail-select position-relative w-100">
                                <InputField
                                  type="date"
                                  minLength={moment().format('YYYY-MM-DD')}
                                  onChange={dateChangeHandler}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-2">
                          <div>
                            <button type="button" className="btn btn-blue-grad px-4 text-uppercase mb-0 mb-md-5"
                              onClick={() => reset()}>Reset </button>
                          </div>
                        </div>
                      </div>

                      {/* End Form Section */}

                      {currentUrl == '/classess' &&
                        <div className="box all-class">
                          <div className="row">
                            <ClassCart items={classitem} businessDetail={businessDetail} paginationData={paginationData} changePage={classPageHandler} />
                          </div>
                        </div>
                      }

                      {/* Work Shop */}
                      {currentUrl == '/workshopss' &&
                        <div className="box all-class mt-5">
                          <div className="row">
                            <WorkShopCart items={workshopItem} businessDetail={businessDetail} />
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div >
              </div >
            </div >
          </div >
          {/* Studio Classes End */}
        </div >
      </div >
      <Innerfooter />

    </>
  )
}
