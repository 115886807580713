import React from 'react'
import { InnerHeader } from '../../Components/inner-header/InnerHeader1'
import { Innerfooter } from '../../Components/inner-footer/Innerfooter'
import { useState } from 'react'
import { useEffect } from 'react'
import { Spinner } from 'react-bootstrap'
import { API_cardDelete, API_cardGet, API_clover_card_save } from '../../../Static_Services/Staff_Services'
import { successAlert } from '../../../Static_Services/Alertmsg'

export const ClientPayment = () => {
    const [businessDetail, setBusinessDetail] = useState([]);
    const [userInfo, setUserinfo] = useState();
    const [loder, setLoder] = useState(false);
    const [customerData, setCustomerData] = useState([]);
    const [cardType, setCardType] = useState();
    const [submitted, setSubmitted] = useState();
    const [paymentCard, setPaymentCard] = useState()
    const [savecard,setSavecard] = useState(0);

    useEffect(() => {
        if (JSON.parse(localStorage.getItem('w_BusinessDetail'))) {
            setBusinessDetail(JSON.parse(localStorage.getItem('w_BusinessDetail')))
        }
        if (JSON.parse(localStorage.getItem('w_loginDetail'))) {
            setUserinfo(JSON.parse(localStorage.getItem('w_loginDetail')));
        }
    }, [])

    const getSaveCardDetail = async () => {
        setLoder(true);
        let detail = { "userid": userInfo.id };
        const resp = await API_cardGet(detail);
        if (resp) {
            const response = resp.data;
            response.data.map((el) => {
                el.checked = true;
            })
            setCustomerData(response.data);
            setLoder(false);
        } else {
            setCustomerData([]);
            setLoder(false);
        }
    }

    const closed = () => {
        paymentCard.reset();
        setCardType('');
        setSubmitted(false);
    }

    const savecardDetail = async () => {
        setSubmitted(true);
        setLoder(true);
        let detail = {
            "userid": userInfo.id,
            "name": paymentCard.value.card_holder_name,
            "card_token": paymentCard.value.token,
            "number": paymentCard.value.card_number,
            "expiry_month": paymentCard.value.expiry_month,
            "expiry_year": paymentCard.value.expiry_year,
            "cvv": paymentCard.value.cvv_no,
            "business_id": businessDetail.business_id,
            "country_code": ""
        };
        // var url = 'superadmin/webservices/api/cardSave';
        // var url = 'superadmin/webservices/api/clover_card_save';
        const resp = await API_clover_card_save(detail);
        if (resp) {
            setLoder(false);
            const response = resp.data;
            successAlert(response.message);
            paymentCard.reset();
            setCardType();
            setSubmitted(false);
            getSaveCardDetail();
        } else {
            setLoder(false);
        }
    }

    const deletecardDetail = async (cardid) => {
        // setLoder(true);
      
        let detail = { "id": customerData[0].customer_code, "card_id": cardid, };
        const resp = await API_cardDelete(detail);
        if (resp) {
            const response = resp.data;
            successAlert(response.message);
            getSaveCardDetail();
            setLoder(false);
        } else {
            setLoder(false);
        }
    }

    const Savecard = (event) => {
        if (event.target.checked == true) {
            setSavecard(1);
        }
        else { setSavecard(0) }
    }

    const getCardType = (event) => {
        setCardType(event.target.id);
        let crData = customerData;
        crData.map((el) => {
            if (el.card_id == cardType) {
                el.checked = event.target.checked;
            }
        })
        setCustomerData(crData);
    }

    const payment = () => {
        //     var expired = this.commonservice.ExpiryCheck(this.paymentCard.value.expiry);
        //     if(expired)  {
        //       this.paymentCard.value.expiry_month = expired[0];
        //       this.paymentCard.value.expiry_year = expired[1];
        //     }  else{
        //       return false;
        //     }
        //     this.submitted = true;
        //     let card = this.paymentCard;
        //     if(card.valid){
        //       this.loading = true;       
        //       var detail = { "number": this.paymentCard.value.card_number,"expiry_month": this.paymentCard.value.expiry_month,
        //         "expiry_year": this.paymentCard.value.expiry_year,"cvd": this.paymentCard.value.cvv_no,
        //         "business_id":this.businessDetail.business_id,
        //         "country_code":""

        //       };
        //       //var url = 'superadmin/webservices/api/getbamboraPaymentToken';
        //       var url = 'superadmin/webservices/api/get_clover_payment_token';
        //       this.commonservice.PostApis(detail,url).subscribe(((response : any ) =>{
        //         if(response.status == 1){
        //           this.paymentCard.value.token = response.data.token;
        //           this.savecardDetail();
        //           this.loading = false;    
        //         }
        //         }))
        //   } else{
        //     this.loading = false;
        //     return false;
        //   }
    }

    useEffect(() => {
        if (userInfo) {
            getSaveCardDetail();
        }
    }, [userInfo])

    return (
        <>
            {loder ? <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div> : ""}
            <InnerHeader />

            <div className="body-grey">
                {/* <!-- My Studio Card Detials-Service Section --> */}
                <div class="client-payment">
                    <div class="container">

                        <div class="py-5">
                            <div class="row">
                                <div class="col-6 text-left">
                                    <h4 class="title black-color text-uppercase"> My payments </h4>
                                </div>

                            </div>

                            <div class="row">
                                <div class="col-12 col-lg-6 mt-5 mt-lg-0 px-0 pr-2">
                                    <div class="client-buy-process border-radius6 box-shadow1 white-bg">
                                        <div class="p-3 text-left light-bg border-t-lr-6">
                                            <h5 class="title black-color font-w-100 text-uppercase font-16 mb-0"> credit card / debit card </h5>
                                        </div>
                                        {cardType == 'new' &&
                                            <form>
                                                <div class="row py-4 px-3">
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <p class="brand-color3 text-left font-14 mb-2"> Name On Card </p>
                                                            <input type="text" class="form-control" autocomplete="off"
                                                                onkeyup="this.value=this.value.replace(/^\s+/g, '')" placeholder="Sophie Joe"
                                                                formControlName="card_holder_name"
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* // <!-- First Name --> */}

                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group client-buy-input-cardno">
                                                            <p class="brand-color3 text-left font-14 mb-2"> Card Number </p>
                                                            <input type="text" class="form-control" autocomplete="off"
                                                                onkeyup="this.value=this.value.replace(/^\s+/g, '')" placeholder="4121 4411 3131 8554"
                                                                pattern="\d{15,19}" onKeyPress="if(this.value.length >= 19) return false;"
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* <!-- Last Name --> */}

                                                    <div class="col-12 col-sm-6 mt-2 mt-md-3">
                                                        <div class="form-group">
                                                            <p class="brand-color3 text-left font-14 mb-2"> Valid Till </p>
                                                            <input type="text" class="form-control" autocomplete="off" placeholder="MM/YYYY"
                                                                formControlName="expiry"
                                                                maxlength="5"
                                                                onKeyPress="if(this.value.length == 2) return this.value=this.value.replace(this.value, this.value+'/');"
                                                                pattern="^\d{2}/\d{4}"
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* <!-- Email --> */}

                                                    <div class="col-12 col-sm-6 mt-2 mt-md-3">
                                                        <div class="form-group">
                                                            <p class="brand-color3 text-left font-14 mb-2"> CVV </p>
                                                            <input type="password" class="form-control" autocomplete="off"
                                                                onkeyup="this.value=this.value.replace(/^\s+/g, '')" placeholder="eg. 201" pattern="\d{3,4}"
                                                                onKeyPress="if(this.value.length >=4) return false;" formControlName="cvv_no"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div class="col-12 d-flex pt-5 text-center px-4">

                                                        <button type="button" class="btn btn-green-grad flex-fill text-uppercase mb-0 mb-md-5"
                                                            onClick={() => payment()}
                                                        > Submit </button>
                                                        <button type="button" class="btn btn-blue-grad flex-fill text-uppercase ml-3 mb-0 mb-md-5"
                                                            onClick={() => closed()}
                                                        >Close</button>
                                                    </div>
                                                </div>
                                            </form>
                                        }
                                        {cardType != 'new' &&
                                            <div class="client-payment-input border-radius6 box-shadow1 white-bg p-4">
                                                {customerData.length != 0 &&
                                                    <div class="row" >
                                                        {customerData.map((cardsave) => (
                                                            <div class="col-12 mb-3" >
                                                                <div class="payment-history-box white-bg border-radius6 box-shadow1 p-3 mb-3">
                                                                    <div class="row mx-0">
                                                                        <div className='col-12'>
                                                                            Studio Name :- {cardsave.business_name}
                                                                        <hr />
                                                                        </div>
                                                                       
                                                                        <div class="col-2 pl-0">
                                                                            {(cardsave.card_type == 'VISA' || cardsave.card_type == 'visa') &&
                                                                                <img src="clientassets/img/visa_card.png" class="img-fluid" />}
                                                                            {cardsave.card_type == 'MC' &&
                                                                                <img src="clientassets/img/master_card.png" class="img-fluid" />}
                                                                            {cardsave.card_type == 'AM' &&
                                                                                <img src="clientassets/img/american_express.png" class="img-fluid" />}
                                                                        </div>
                                                                        <div class="col-8 pl-0">
                                                                            <p class="mb-0 font-14 font-w-500 dark-color">{cardsave.business_name}</p>

                                                                            <p class="mb-0 font-14 font-w-500 dark-color">{cardsave.number}</p>
                                                                            <h5 class="mb-0 font-12 dark-color mt-2">Valid Till(MM/YYYY) </h5>
                                                                            <p class="mb-0 font-12 dark-color">{cardsave.expiry_month} / {cardsave.expiry_year}</p>
                                                                        </div>
                                                                        <div class="col-2 text-right">
                                                                            <a href="Javascript:void(0);"
                                                                                onClick={() => deletecardDetail(cardsave.card_id)}
                                                                            ><i class="fas fa-trash-alt"></i></a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                }
                                                {customerData.length == 0 &&
                                                    <div class="row">
                                                        <p class="text-center">No Record Found</p>
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Innerfooter />
        </>
    )
}
