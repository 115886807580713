import React, {useEffect, useState} from "react";
import { CalenderScheduler } from "../../../../../sharedComponents/CalenderScheduler";
import Sidebar from "../../comman_page/Sidebar";
import { HeaderDashboard } from "../../comman_page/HeaderDashboard";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { Footer } from "../../../../../public/containers/footer/Footer";
import { SharedSelect } from "../../../../../sharedComponents/SharedSelect";
import { SharedButton } from "../../../../../sharedComponents/Button";
import { BsSearch } from "react-icons/bs";
import { GrRefresh } from "react-icons/gr";
import { AiFillPlusCircle } from "react-icons/ai";
import { get_class_schedule_calendar, get_workshop_schedule_calendar } from "../../../../../Studio-Services/Studio_Services";
import moment from "moment";
import { WorkShopCalendar } from "./WorkShopCalendar";

export const Workshop = () => {

  const [resps, setResps] = useState([]);
  const [loder,setLoder] = useState(false);

  const [visibleRange, setVisibleRange] = useState(null);
  const [calendardates, setCalendardates] = useState({ 'start': moment().startOf('year').format('YYYY-MM-DD'), 'end': moment().endOf('year').format('YYYY-MM-DD') });

  const getworkshop = async () => {
    setLoder(true);
    const startOfMonth = moment().startOf('year').format('YYYY-MM-DD');
    const endOfMonth = moment().endOf('year').format('YYYY-MM-DD');
    const passData = {
      start: startOfMonth,
      end: endOfMonth,
    };
    const resp = await get_workshop_schedule_calendar(passData);
    if (resp) {
      setLoder(false);
      setResps(resp.data.data);
    }else{
      setResps([]);
      setLoder(false);
    }
    setLoder(false);
  };


  const calanderOnClhange = async (dateData) => {
    setLoder(true);
    const chDates = {...dateData};
    const startOfMonth = chDates.start;
    const endOfMonth = chDates.end;  
    const passData = {
      start: startOfMonth,
      end: endOfMonth,
    };
    const resp = await get_workshop_schedule_calendar(passData);
    if (resp) {
      setLoder(false);
      setResps(resp.data.data);
    }else{
      setResps([]);
      setLoder(false);
    }
    setLoder(false);
  };

  useEffect(()=>{
    calanderOnClhange(calendardates);
  },[calendardates])

  useEffect(() => {
    getworkshop();
  }, []);

  return (
    <>
        {loder && <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div> }
    <div className="MainDashboard">
    <div className="MainLayout">
      <Row>
        <Sidebar />
        <Col>
          {/* <div className="RightSide"> */}
          <HeaderDashboard />
          <div className="RightSide">
            <div className="innerContent">
              <div className="ListYourService">
                <Container>
                  <Row className="pt-0 pb-0">
                    <Col>
                      <h4>SCHEDULED WORKSHOP</h4>
                    </Col>
                    <Col>
                    </Col>
                    <Col>
                      <div className="appointmentPlus WorkshopPlus">
                        <div className="appointIcons">
                          <SharedButton
                            variant={"danger"}
                            className={"PlusIcon"}
                            iconNode={<AiFillPlusCircle />}
                            href={"/add-schedule-workshop"}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <WorkShopCalendar resps={resps} setVisibleRange={setVisibleRange} setCalendardates={setCalendardates} calendardates ={calendardates}/>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>

            <Footer />
          </div>
        </Col>
      </Row>
    </div>
  </div>
  </>

  )
}
