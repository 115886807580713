import React, { useState, useRef, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import * as bootstrap from "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";

export const WorkShopCalendar = ({ resps,setVisibleRange, setCalendardates,calendardates }) => {

    const [datas, setDatas] = useState([]);

    useEffect(() => {
        if (resps) {
            setDatas(resps);
        }
    }, [resps])

    const calendarRef = useRef(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const navigate = useNavigate();

    const list = () => {
        let Events = [];
        if (datas) {
            Events = datas.map((e, index) => {
                return {
                    id: index,
                    myid: { data: e },
                    title: `Name : ${e.name} |  Instructor: ${e.instructor_name}, | Location: ${e.location_name} | Status: ${e.status !='Cancel' ? e.status : e.status}` ,
                    description: ` 
            ${moment.unix(e.start).format("hh:mm A")} 
            - ${moment.unix(e.end).format("hh:mm A")},
            || 
            Name : ${e.name}
            ||
            Instructor: ${e.instructor_name}, || Location: ${e.location_name}
            || Status : ${e.status}
          `,
                    start: moment.unix(e.start).format("YYYY-MM-DDTHH:mm:ss"),
                    end: moment.unix(e.end).format("YYYY-MM-DDTHH:mm:ss"),
                };
            });
            setSelectedEvent(Events);
        } else {
            setSelectedEvent(Events);
        }
    };

    useEffect(() => {
        if (datas.length > 0) {
            list();
        }
    }, [datas]);

    const eventMouseEnter = (info) => {
        const description = info.event.extendedProps.description;
        const eventElement = info.el;
        const popover = new bootstrap.Popover(eventElement, {
            //   title: "Description",
            content: description,
            trigger: "hover",
            placement: "top",
        });
        popover.show();
    };

    const eventMouseLeave = (info) => {
        const eventElement = info.el;
        const popover = bootstrap.Popover.getInstance(eventElement);
        if (popover) {
            popover.dispose();
        }
    };

    const eventDidMount = (info) => {
        const description = info.event.extendedProps.description;
        const eventElement = info.el;
        const descriptionElement = document.createElement("div");
        descriptionElement.classList.add("fc-description");
        descriptionElement.textContent = `Description: ${description}`;
        descriptionElement.style.display = "none"; // Set initial display to "none"
        eventElement.appendChild(descriptionElement);

        eventElement.addEventListener("click", () => {
            if (descriptionElement.style.display === "none") {
                descriptionElement.style.display = "block";
            } else {
                descriptionElement.style.display = "none";
            }
        });
    };


    const datesSet = (info) => {
        let cld = { ...calendardates };
        cld.start=moment(info.startStr).format('YYYY-MM-DD');
        cld.end=moment(info.endStr).format('YYYY-MM-DD');
        setCalendardates(cld);
    
        setVisibleRange({
          start: info.startStr,
          end: info.endStr,
        });
      };


    const eventClick = (info) => {
        // Redirect to another page when an event is clicked
        const eventId = info.event.id;

        // Close the popover if it's currently open
        const eventElement = info.el;
        const popover = bootstrap.Popover.getInstance(eventElement);
        if (popover) {
            popover.dispose();
        }
        const data = info.event.extendedProps.myid;
        navigate("/WorkShopEdit", { state: { data: data } });

    };

    const calendarOptions = {
        plugins: [
            dayGridPlugin,
            timeGridPlugin,
            resourceTimeGridPlugin,
            interactionPlugin,
            listPlugin,
        ],
        schedulerLicenseKey: "GPL-My-Project-Is-Open-Source",
        headerToolbar: {
            left: "prev,today,next",
            center: "title",
            right: "timeGridDay,timeGridWeek,dayGridMonth,listWeek",
        },
        buttonText: {
            prev: "Previous",
            next: "Next",
            today: "Today",
            listWeek: "List",
        },
        eventClick: eventClick,
        slotMinTime: "06:00:00",
        slotMaxTime: "23:00:00",
        slotLabelInterval: { hours: 1 },
        initialView: "listWeek",
        expandRows: true,
        events: selectedEvent,
        height: "80vh",

        datesSet: datesSet,

        eventDidMount: eventDidMount,
        eventMouseEnter: eventMouseEnter, // Add eventMouseEnter callback
        eventMouseLeave: eventMouseLeave, // Add eventMouseLeave callback
    };

    return (
        <>
            <FullCalendar ref={calendarRef} {...calendarOptions} />
        </>
    );
};
