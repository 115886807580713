import React from "react";
import Sidebar from "../../comman_page/Sidebar";
import { HeaderDashboard } from "../../comman_page/HeaderDashboard";
import { Container, Row, Col, Spinner, Form } from "react-bootstrap";
import { SharedSelect } from "../../../../../sharedComponents/SharedSelect";
import { InputField } from "../../../../../sharedComponents/InputField";
import { Footer } from "../../../../../public/containers/footer/Footer";
import { useState } from "react";
import { useEffect } from "react";
import { InstructorList, get_instructor_chart, get_instructor_class } from "../../../../../Studio-Services/Studio_Services";
import { DateRange } from "../../../../../sharedComponents/DateRange";
import { SharedButton } from "../../../../../sharedComponents/Button";
import { BiSearchAlt } from "react-icons/bi";

export default function Staff() {
  const [loder, setLoder] = useState(true);
  const [instruct, setInstruct] = useState([]);
  const [classes, setClasses] = useState([]);
  const [startDate , setStartDate ] = useState(null);
  const [endDate, setEndDate] = useState(null);


  const inList = async () => {
    const fdata = { "pageid": 1 }
    const resp = await InstructorList(fdata);
    if (resp) {
      const inst = [];
      setLoder(false);
      (resp.data.data).forEach((e) => {
        if (e.status === "Approve") {
          inst.push({
            value: e.user_id, label: `${e.name}  ${e.lastname}`
          })
        }
      })
      setInstruct(inst);
    }
  }

  useEffect(() => {
    inList();
  }, [])

  const instructHandler = async (e) => {
    const ids = e.target.value;
    if (ids) {
      const fdata = { "userId": ids };
      const resp = await get_instructor_class(fdata);
      if (resp) {
        const cls = [];
        (resp.data.data).map((e) => (
          cls.push({ value: e.class_id, label: e.class_name })
        ))
        setClasses(cls);
      }
    } else setClasses([]);
  }


  const submitHandler = async (e) =>{
    e.preventDefault();
    const formData = new FormData(e.target);
    const formValue = Object.fromEntries(formData.entries());
    if(formValue.instruct_id && formValue.class_id && startDate && endDate){
     const fdata = {
        "start_date": startDate,
        "end_date": endDate,
        "instructorId": formValue.instruct_id,
        "class": formValue.class_id
      }
    const resp = await get_instructor_chart(fdata);
    if(resp){
    }
    }
  }
  return (
    <>
      {loder ? <div className="MainLoader"><Spinner animation="border" /> </div> : ""}
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col>
              <HeaderDashboard />
              <div className="RightSide">
                <div className="innerContent">
                  <section className="ClassAttendance">
                    <Container>
                      <Form onSubmit={submitHandler}>
                      <Row className="mt-3">
                        <Col>
                          <SharedSelect
                            name={"instruct_id"}
                            onChange={instructHandler}
                            options={instruct}
                            lable={"Instructor"}
                          />
                        </Col>
                        <Col>
                          <SharedSelect
                          name={"class_id"}
                            options={classes}
                            lable={"Classes"}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col>
                          <DateRange
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                            label={"Date Range"}
                          />
                        </Col>
                        <Col>
                          <SharedButton
                            type={"submit"}
                            iconNode={<BiSearchAlt />}
                            className={"btn btn-outline SearchButtonIcon"} />
                        </Col>
                      </Row>
                      </Form>
                    </Container>
                  </section>
                </div>
                <Footer />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
