import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Row, Col, Card, Form } from 'react-bootstrap'
import { SharedButton } from './Button';
import { useNavigate } from 'react-router-dom';
import { SharedSelect } from './SharedSelect';

export const ClientBox = ({ data, onClick, stateData, searchedKeyword, classID,radioHandler }) => {
  const [option, setOption] = useState('1');
  const [preData, setPreData] = useState([]);
  const navigate = useNavigate();
  const [familyID, setFamiltID] = useState();

  useEffect(() => {
    setPreData(data);
  }, [data]);


  const setPassPurchaseCondition = (value) => {
    localStorage.setItem('st_isPassPurchaseFlow', JSON.stringify('true'));
    localStorage.setItem('st_searchedClients', JSON.stringify(searchedKeyword));
    let finalData = { data: value, class_id: classID, schedule_id: localStorage.getItem("sch_id"), serchKey: searchedKeyword }
    localStorage.setItem('allsetData', JSON.stringify(finalData));
    navigate('/purchase-product', { state: { family_member_id: familyID, data: value, class_id: classID, schedule_id: localStorage.getItem("sch_id") } });
  }

  const options = [
    { value: "1", label: "Classes" },
    { value: "2", label: "Workshop" },
    { value: "3", label: "Appointment" },
  ];


  return (
    <>
    <Col md={12} className=''>
        {preData.length > 0 && preData.map((value, index) => (
            <Row>
          <Col key={index}>
            <Card style={{ height: 'unset' }} className=" ServiceCardBox m-0 p-0" key={index}>
              <Card.Body>
                <Row className='m-0 p-0'> 
                <Col md={1}>
                  <Form.Check type="radio" aria-label="radio 1" name='radio_box' onClick={()=>radioHandler(value)} className="RadioButton"/>
                  </Col>
                  <Col md={3}>
                    <div className="cardImg pr-2">
                      <Card.Img variant="top" src={value.profile_img} />
                    </div>
                  </Col>
                  <Col md={8}>
                    <Card.Title className='mb-0'>{value.name}</Card.Title>
                    <small className="m-0">{value.gender}</small>
                    <Card.Text>
                      <p className="m-0">{value.email}</p>
                    </Card.Text>
                  </Col>
                  
                </Row>
              </Card.Body>
            </Card>
          </Col>
          </Row>
        ))}
        </Col>
    </>
  )
}
