import React, { useEffect, useState } from 'react'
import { InnerHeader } from '../../Components/inner-header/InnerHeader1'
import { Innerfooter } from '../../Components/inner-footer/Innerfooter1'
import { useLocation, useNavigate } from 'react-router-dom'
import { successAlert } from '../../../Static_Services/Alertmsg'
import { favourite } from '../../../Static_Services/Staff_Services'

export const SearchResult = () => {
  const location = useLocation();
  const [searchDetail, setSearchDetail] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.state.SerchData) {
      setSearchDetail(location.state.SerchData);
    }
  }, [location])

  const addFav = async (id,status)=> {
    var detail ={  "service_type":"1",   "service_id":id,   "status":status};
    const resp = await favourite(detail);
    if (resp) {
        successAlert(resp.data.message);
        navigate('/my-home');
    }
}
const redirectUrl = (businessId)=>{
  localStorage.setItem('w_detailBack', '/search');
  // const id = businessId;
  localStorage.setItem('bzid',businessId);
  navigate('/detail', { state: { Id: businessId }});
  // navigate('/detail', { state: { id } });

  // navigate('/detail',{state:{Id : businessId}});
}
  return (
    <>

      <InnerHeader />
      <div className="body-grey">

        <div className="favourite py-5 internal-studio">
          <div className="studio-cards">
            <div className="container">
              <div className="row mb-4">
                <div className="col-6">
                  <div className="section-heading mt-3">
                    <h5 className="title black-color font-w-100 text-uppercase font-16"> {searchDetail && searchDetail.total_count} Studios Found </h5>
                  </div>
                </div>
                {/* <!-- end of section-heading --> */}

                <div className="col-6">
                  <div className="text-right">
                    <button type="button" className="btn btn-blue-grad px-4 text-uppercase mb-0 ml-2" onClick={()=>window.history.back()}  >Back </button>
                  </div>
                </div>
                {/* <!-- end of col-6 --> */}
              </div>
              {searchDetail && searchDetail.length > 0 ?
                <div className="row">
                  {searchDetail.map((result) => (
                    <div class="col-12 col-lg-3 mb-2 px-2">
                      <div class="studio-position-heart">
                        <div class="studio-card-newcustom gradient-studio-overlay box-shadow1" onClick={()=>redirectUrl(result.business_id)} >
                         
                          <div class="img-box" style={{ backgroundImage: `url(${result.logo})`,backgroundSize:'contain' }}>
                          </div>
                          <div class="studio-detail p-3">
                            <div class="row mx-0 align-items-start">
                              <div class="col-3 profile-image box-shadow1">
                                <img style={{objectFit:'contain'}} src={result.logo} class="img-fluid" />
                              </div>
                              <div class="col-9 studio-profile-detail flex-fill pl-4">
                                <h5 class="nametitle classname-title mb-1">{result.business_name}</h5>
                                {result.address.length <= 25 ?
                                  <p class="brand-color3 paratitle mb-2" >
                                    {result.address}</p>
                                  :
                                  <p class="brand-color3 paratitle mb-2">
                                    {result.address}</p>
                                }
                                {/* <p class="paratitle"><i class="fas fa-map-marker-alt mr-2 mb-0"></i>
                                  {result.distance}</p> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="studio-card-select">
                          {result.favourite == 0 &&
                            <i class="fas fa-heart brand-color3 font-22" onClick={()=>addFav(result.business_id,1)}
                            ></i>
                          }
                          {result.favourite == 1 &&
                            <i class="fas fa-heart brand-color2 font-22" onClick={()=>addFav(result.business_id,0)}
                            ></i>
                          }
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                :
                <div className="row">
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" >
                    <div className="text-center mt-1 mt-md-3 mt-lg-1">
                      <h4 className="title black-color font-w-100"> Studio Not Found </h4>
                    </div>
                  </div>

                </div>
              }
            </div>
          </div>
        </div>
        {/* <!-- End of studio-cards --> */}
        {/* <!-- </div>           --> */}
        {/* <!-- End of Favourite Section -->        */}

      </div >
      <Innerfooter />
      {/* <app-inner-footer></app-inner-footer> */}


    </>
  )
}
