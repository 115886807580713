import React, { useEffect, useState } from 'react'
import { InnerHeader } from '../../Components/inner-header/InnerHeader1'
import { SubHeader } from '../../Components/sub-header/SubHeader'
import { Innerfooter } from '../../Components/inner-footer/Innerfooter'
import { DashboardToSectionDetails } from '../../Components/CommanPages/DashboardToSectionDetails'
import { Spinner } from 'react-bootstrap'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { API_services_details, staff_services_details } from '../../../Static_Services/Staff_Services'

export const ClientServicesDetails = () => {
  const [loder, setLoder] = useState(false);
  const navigate = useNavigate();
  const [businessDetail, setBusinessDetail] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [userdetail, setUserdetail] = useState([]);
  const [selectedItemsList, setSelectedItemsList] = useState([]);
  const [currentUrl, setCurrentUrl] = useState();
  const location = useLocation();
  const [iD, setID] = useState();



  useEffect(() => {
    if (JSON.parse(localStorage.getItem('w_BusinessDetail'))) {
      setBusinessDetail(JSON.parse(localStorage.getItem('w_BusinessDetail')));
    }
    if (JSON.parse(localStorage.getItem('w_loginDetail'))) {
      setUserdetail(JSON.parse(localStorage.getItem('w_loginDetail')));
    }
    setCurrentUrl(location.pathname);

    const navData = location.state;
  
    if (navData && navData.Id) {
      localStorage.setItem('w_serviceId', JSON.stringify(navData.Id));
    }
    if (localStorage.getItem('w_serviceId')) {
      setID(JSON.parse(localStorage.getItem('w_serviceId')));
    }
  }, [])


  const getserviceDetails = async () => {
    setLoder(true);
    const detail = { "business_id": businessDetail.business_id, "service_id": iD };
    let resp = '';
    if (userdetail.role_id == 3) {
      resp = await staff_services_details(detail);
      // url = 'superadmin/webservices/api/services_details';
    } else {
      resp = await API_services_details(detail);
      // url = 'superadmin/webservices/instructor/services_details';
    }
    if (resp) {
      setLoder(false);
      const response = resp.data;
      setServiceData(response.data[0]);
      localStorage.setItem('w_serviceName', (response.data[0].service_name));
    } else {
      setLoder(false);
    }
  }

  const changeSelection = () => {
    setSelectedItemsList(serviceData.service_category.map((value, index) => {
      return value.isChecked
    }))
  }

  const redirectUrl = (id) => {
    localStorage.setItem('w_service_StaffDetail', JSON.stringify(id));
    navigate('/my-book-appointment');
  }

  const redirectUrl1 = (url, id) => {
    navigate(url, { state: { Id: id } });
  }

  useEffect(() => {
    if (businessDetail && iD) {
      getserviceDetails();
    }
  }, [businessDetail, iD])
  return (
    <>
      {loder ? <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div> : ""}
      <InnerHeader />
      <div className="body-grey">

        <div class="studio-details client-home-inner">
          <div class="container">
            <DashboardToSectionDetails />


            <div class="home-service home-service-offered pb-5">

              <div class="section-heading p-3 text-left white-bg border-radius6 box-shadow1 mb-5">
                <div class="row">
                  <div class="col-6">
                    <a href="Javascript:void(0);" class="black-color font-w-100 text-uppercase font-20"> service offered </a>
                    <div class="form-group profile-gender mb-0 mt-3">
                      <div class="service-button">
                        <div class="row">
                          <h4 class="title black-color mb-0 ml-3">Service Name : {serviceData.service_name} </h4>
                        </div>
                        {/* <!-- end of profile-gender-button --> */}
                      </div>
                    </div>
                    {/* <!-- end of form-group --> */}
                  </div>
                  <div class="col-6 text-right">
                    <button type="button" class="btn btn-blue-grad px-2 text-uppercase" onClick={() => window.history.back()} >Back </button>
                  </div>
                </div>
                {/* <!-- end of col-12 --> */}

              </div>
              {/* <!-- end of Heading(service) --> */}

              <div class="home-staff" >
                {serviceData && serviceData.instructor && serviceData.instructor.map((inst) => (
                  <div class="home-staff-details white-bg box-shadow1 border-radius6 px-3 py-4 mb-4">
                    <div class="row">
                      <div class="col-12 col-md-2 text-center">
                        <Link onClick={()=>redirectUrl1('/instructor-detail',inst.id)}>
                          <div class="home-staff-detail-img">
                            <img src={inst.profile_img} class="img-fluid" />
                          </div>
                        </Link>

                      </div>
                      {/* <!-- end of col-md-2 --> */}
                      <div class="col-12 col-md-10 pl-3 pl-md-0 mt-4 mt-md-0">
                        <div class="home-staff-detail-text">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="" >
                                <h4 class="title black-color font-w-100 font-16 mb-0">{inst.name + ' '}  {inst.lastname}  </h4>



                                <p class="black-color font-w-100 font-14 mb-1"> Experience:
                                  <span class="brand-color3 font-12"> {inst.experience}  Years </span>
                                </p>

                                <p class="black-color font-w-100 font-14 mb-1"> Appointment Fees :
                                  <span class="brand-color3 font-12">{serviceData.service_charge} </span>
                                </p>

                                <p class="black-color font-w-100 font-14 mb-0"> Duration of Service :
                                  <span class="brand-color3 font-12"> {serviceData.duration}  Min.</span>
                                </p>

                              </div>
                            </div>
                            <div class="col-md-6 pt-3">
                              <div class="home-service-offered-btn pt-0 pt-md-5 pr-0 pr-md-3">
                                <button type="button" class="btn btn-blue-grad px-4 text-uppercase"
                                  onClick={() => redirectUrl(inst)}
                                > book apointment </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>




        {/* <div className="modal fade bd-example-modal-xl" id="calenderModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div className="modal-dialog modal-xl modal-dialog-centered">
      <div className="modal-content border-radius20">
        <div className="modal-body p-0">
          <div className="card">
            <div className="card-body p-0">
              <div id="calendar" className="fc fc-bootstrap4 fc-ltr">

                <div className="fc-toolbar fc-header-toolbar row">
                  <div className="col-12 col-lg-4 fc-left">
                    <p className="black-color font-w-100 font-14 mb-0"> Select appointment date </p>
                  </div>

                  <div className="col-12 col-lg-4 fc-center text-center">
                    <div className="left-arrow"> <i className="fas fa-caret-left white-color"></i></div>
                    <h4 className="title black-color font-w-600 d-inline">January 2020</h4>
                    <div className="right-arrow"> <i className="fas fa-caret-right white-color"></i></div>
                  </div>
                  <div className="col-12 col-lg-4 fc-center"></div>
                  <div className="fc-clear"></div>
                </div>

                <div className="fc-view-container">
                  <div className="fc-view fc-month-view fc-basic-view" >
                    <table className="border-none">
                      <thead className="fc-head">
                        <tr>
                          <td className="fc-head-container ">
                            <div className="fc-row table-bordered">
                              <table className="table-bordered">
                                <thead>
                                  <tr>
                                    <th className="fc-day-header  fc-sun"><span>Sunday</span></th>
                                    <th className="fc-day-header  fc-mon"><span>Monday</span></th>
                                    <th className="fc-day-header  fc-tue"><span>Tuesday</span></th>
                                    <th className="fc-day-header  fc-wed"><span>Wednesday</span></th>
                                    <th className="fc-day-header  fc-thu"><span>Thursday</span></th>
                                    <th className="fc-day-header  fc-fri"><span>Friday</span></th>
                                    <th className="fc-day-header  fc-sat"><span>Saturday</span></th>
                                  </tr>
                                </thead>
                              </table>
                            </div>
                          </td>
                        </tr>
                      </thead>
                      <tbody className="fc-body">
                        <tr>
                          <td className="border-0">
                            <div className="fc-scroller fc-day-grid-container" style={{overflow: "hidden", height: "618px", border-bottom-right-radius: 20px; border-bottom-left-radius: 20px;">
                              <div className="fc-day-grid fc-unselectable">
                                <div className="fc-row fc-week table-bordered" style="height: 103px;">
                                  <div className="fc-bg">
                                    <table className="table-bordered">
                                      <tbody>
                                        <tr>
                                          <td className="fc-day  fc-sun fc-other-month fc-past" data-date="2020-01-26"></td>
                                          <td className="fc-day  fc-mon fc-other-month fc-past" data-date="2020-01-27"></td>
                                          <td className="fc-day  fc-tue fc-other-month fc-past" data-date="2020-01-28"></td>
                                          <td className="fc-day  fc-wed fc-other-month fc-past" data-date="2020-01-29"></td>
                                          <td className="fc-day  fc-thu fc-other-month fc-past" data-date="2020-01-30"></td>
                                          <td className="fc-day  fc-fri fc-other-month fc-past" data-date="2020-01-31"></td>
                                          <td className="fc-day  fc-sat fc-past" data-date="2020-02-01"></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <div className="fc-content-skeleton">
                                    <table>
                                      <thead>
                                        <tr>
                                          <td className="fc-day-top fc-sun fc-other-month fc-past" data-date="2020-01-26"><span className="fc-day-number">26</span></td>
                                          <td className="fc-day-top fc-mon fc-other-month fc-past" data-date="2020-01-27"><span className="fc-day-number">27</span></td>
                                          <td className="fc-day-top fc-tue fc-other-month fc-past" data-date="2020-01-28"><span className="fc-day-number">28</span></td>
                                          <td className="fc-day-top fc-wed fc-other-month fc-past" data-date="2020-01-29"><span className="fc-day-number">29</span></td>
                                          <td className="fc-day-top fc-thu fc-other-month fc-past" data-date="2020-01-30"><span className="fc-day-number">30</span></td>
                                          <td className="fc-day-top fc-fri fc-other-month fc-past" data-date="2020-01-31"><span className="fc-day-number">31</span></td>
                                          <td className="fc-day-top fc-sat fc-past" data-date="2020-02-01"><span className="fc-day-number">1</span></td>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>

                                <div className="fc-row fc-week table-bordered" style="height: 103px;">
                                  <div className="fc-bg">
                                    <table className="table-bordered">
                                      <tbody>
                                        <tr>
                                          <td className="fc-day  fc-sun fc-past" data-date="2020-02-02"></td>
                                          <td className="fc-day  fc-mon fc-past" data-date="2020-02-03"></td>
                                          <td className="fc-day  fc-tue fc-past" data-date="2020-02-04"></td>
                                          <td className="fc-day  fc-wed fc-past" data-date="2020-02-05"></td>
                                          <td className="fc-day  fc-thu fc-past" data-date="2020-02-06"></td>
                                          <td className="fc-day  fc-fri fc-future" data-date="2020-02-07"></td>
                                          <td className="fc-day  fc-sat fc-future" data-date="2020-02-08"></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <div className="fc-content-skeleton">
                                    <table>
                                      <thead>
                                        <tr>
                                          <td className="fc-day-top fc-sun fc-past" data-date="2020-02-02"><span className="fc-day-number">2</span></td>
                                          <td className="fc-day-top fc-mon fc-past" data-date="2020-02-03"><span className="fc-day-number">3</span></td>
                                          <td className="fc-day-top fc-tue fc-past" data-date="2020-02-04"><span className="fc-day-number">4</span></td>
                                          <td className="fc-day-top fc-wed fc-past" data-date="2020-02-05"><span className="fc-day-number">5</span></td>
                                          <td className="fc-day-top fc-thu fc-today" data-date="2020-02-06"><span className="fc-day-number">6</span></td>
                                          <td className="fc-day-top fc-fri fc-future" data-date="2020-02-07"><span className="fc-day-number">7</span></td>
                                          <td className="fc-day-top fc-sat fc-future" data-date="2020-02-08"><span className="fc-day-number">8</span></td>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>

                                <div className="fc-row fc-week table-bordered" style="height: 103px;">
                                  <div className="fc-bg">
                                    <table className="table-bordered">
                                      <tbody>
                                        <tr>
                                          <td className="fc-day  fc-sun fc-future" data-date="2020-02-09"></td>
                                          <td className="fc-day  fc-mon fc-future" data-date="2020-02-10"></td>
                                          <td className="fc-day  fc-tue fc-future" data-date="2020-02-11"></td>
                                          <td className="fc-day  fc-wed fc-future" data-date="2020-02-12"></td>
                                          <td className="fc-day  fc-thu fc-future" data-date="2020-02-13"></td>
                                          <td className="fc-day  fc-fri fc-future" data-date="2020-02-14"></td>
                                          <td className="fc-day  fc-sat fc-future" data-date="2020-02-15"></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <div className="fc-content-skeleton">
                                    <table>
                                      <thead>
                                        <tr>
                                          <td className="fc-day-top fc-sun fc-future" data-date="2020-02-09"><span className="fc-day-number">9</span></td>
                                          <td className="fc-day-top fc-mon fc-future" data-date="2020-02-10"><span className="fc-day-number">10</span></td>
                                          <td className="fc-day-top fc-tue fc-future" data-date="2020-02-11"><span className="fc-day-number">11</span></td>
                                          <td className="fc-day-top fc-wed fc-future" data-date="2020-02-12"><span className="fc-day-number">12</span></td>
                                          <td className="fc-day-top fc-thu fc-future" data-date="2020-02-13"><span className="fc-day-number">13</span></td>
                                          <td className="fc-day-top fc-fri fc-future" data-date="2020-02-14"><span className="fc-day-number">14</span></td>
                                          <td className="fc-day-top fc-sat fc-future" data-date="2020-02-15"><span className="fc-day-number">15</span></td>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>

                                <div className="fc-row fc-week table-bordered" style="height: 103px;">
                                  <div className="fc-bg">
                                    <table className="table-bordered">
                                      <tbody>
                                        <tr>
                                          <td className="fc-day  fc-sun fc-future" data-date="2020-02-16"></td>
                                          <td className="fc-day  fc-mon fc-future" data-date="2020-02-17"></td>
                                          <td className="fc-day  fc-tue fc-future" data-date="2020-02-18"></td>
                                          <td className="fc-day  fc-wed fc-future" data-date="2020-02-19"></td>
                                          <td className="fc-day  fc-thu fc-future" data-date="2020-02-20"></td>
                                          <td className="fc-day  fc-fri fc-future" data-date="2020-02-21"></td>
                                          <td className="fc-day  fc-sat fc-future" data-date="2020-02-22"></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <div className="fc-content-skeleton">
                                    <table>
                                      <thead>
                                        <tr>
                                          <td className="fc-day-top fc-sun fc-future" data-date="2020-02-16"><span className="fc-day-number">16</span></td>
                                          <td className="fc-day-top fc-mon fc-future" data-date="2020-02-17"><span className="fc-day-number">17</span></td>
                                          <td className="fc-day-top fc-tue fc-future" data-date="2020-02-18"><span className="fc-day-number">18</span></td>
                                          <td className="fc-day-top fc-wed fc-future" data-date="2020-02-19"><span className="fc-day-number">19</span></td>
                                          <td className="fc-day-top fc-thu fc-future" data-date="2020-02-20"><span className="fc-day-number">20</span></td>
                                          <td className="fc-day-top fc-fri fc-future" data-date="2020-02-21"><span className="fc-day-number">21</span></td>
                                          <td className="fc-day-top fc-sat fc-future" data-date="2020-02-22"><span className="fc-day-number">22</span></td>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>

                                <div className="fc-row fc-week table-bordered" style="height: 103px;">
                                  <div className="fc-bg">
                                    <table className="table-bordered">
                                      <tbody>
                                        <tr>
                                          <td className="fc-day  fc-sun fc-future" data-date="2020-02-23"></td>
                                          <td className="fc-day  fc-mon fc-future" data-date="2020-02-24"></td>
                                          <td className="fc-day  fc-tue fc-future" data-date="2020-02-25"></td>
                                          <td className="fc-day  fc-wed fc-today alert alert-info" data-date="2020-02-26" routerLink ="/book-appointment"></td>
                                          <td className="fc-day  fc-thu fc-future" data-date="2020-02-27"></td>
                                          <td className="fc-day  fc-fri fc-future" data-date="2020-02-28"></td>
                                          <td className="fc-day  fc-sat fc-future" data-date="2020-02-29"></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <div className="fc-content-skeleton">
                                    <table>
                                      <thead>
                                        <tr>
                                          <td className="fc-day-top fc-sun fc-future" data-date="2020-02-23"><span className="fc-day-number">23</span></td>
                                          <td className="fc-day-top fc-mon fc-future" data-date="2020-02-24"><span className="fc-day-number">24</span></td>
                                          <td className="fc-day-top fc-tue fc-future" data-date="2020-02-25"><span className="fc-day-number">25</span></td>
                                          <td className="fc-day-top fc-wed fc-today  alert alert-info" data-date="2020-02-26" routerLink ="/book-appointment"><span className="fc-day-number">26</span></td>
                                          <td className="fc-day-top fc-thu fc-future" data-date="2020-02-27"><span className="fc-day-number">27</span></td>
                                          <td className="fc-day-top fc-fri fc-future" data-date="2020-02-28"><span className="fc-day-number">28</span></td>
                                          <td className="fc-day-top fc-sat fc-future" data-date="2020-02-29"><span className="fc-day-number">29</span></td>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>

                                <div className="fc-row fc-week table-bordered" style="height: 103px;">
                                  <div className="fc-bg">
                                    <table className="table-bordered">
                                      <tbody>
                                        <tr>
                                          <td className="fc-day  fc-sun fc-other-month fc-future" data-date="2020-03-01"></td>
                                          <td className="fc-day  fc-mon fc-other-month fc-future" data-date="2020-03-02"></td>
                                          <td className="fc-day  fc-tue fc-other-month fc-future" data-date="2020-03-03"></td>
                                          <td className="fc-day  fc-wed fc-other-month fc-future" data-date="2020-03-04"></td>
                                          <td className="fc-day  fc-thu fc-other-month fc-future" data-date="2020-03-05"></td>
                                          <td className="fc-day  fc-fri fc-other-month fc-future" data-date="2020-03-06"></td>
                                          <td className="fc-day  fc-sat fc-other-month fc-future" data-date="2020-03-07"></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <div className="fc-content-skeleton">
                                    <table>
                                      <thead>
                                        <tr>
                                          <td className="fc-day-top fc-sun fc-other-month fc-future" data-date="2020-03-01"><span className="fc-day-number">1</span></td>
                                          <td className="fc-day-top fc-mon fc-other-month fc-future" data-date="2020-03-02"><span className="fc-day-number">2</span></td>
                                          <td className="fc-day-top fc-tue fc-other-month fc-future" data-date="2020-03-03"><span className="fc-day-number">3</span></td>
                                          <td className="fc-day-top fc-wed fc-other-month fc-future" data-date="2020-03-04"><span className="fc-day-number">4</span></td>
                                          <td className="fc-day-top fc-thu fc-other-month fc-future" data-date="2020-03-05"><span className="fc-day-number">5</span></td>
                                          <td className="fc-day-top fc-fri fc-other-month fc-future" data-date="2020-03-06"><span className="fc-day-number">6</span></td>
                                          <td className="fc-day-top fc-sat fc-other-month fc-future" data-date="2020-03-07"><span className="fc-day-number">7</span></td>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}
        {/* </div> */}

      </div>
      <Innerfooter />


    </>
  )
}
