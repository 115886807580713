import React, { useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useEffect } from "react";
import logo from '../../../../../Images/logo_white_web.png'

export const WorkshopMultiSelect = ({ labelText, options, name, setSkillsdata, skillsdata, id, value, error, setValuse, selectedOptions, setSelectedOptions }) => {

    const [preValue, setPreValue] = useState([]);


    let index = -1;
    if (selectedOptions > 0) {
        index = selectedOptions.findIndex((v, i) => {
            return v[i].id == id;
        });
    }


    const handleChange = (selected) => {
        if(selected.length===0){
            const data=[...selectedOptions];
            const newArray = data.filter((item, index) => index !== id);
            // const newData = data.splice(id, 1);
            // data[id].items=selected;
            // setSelectedOptions(data);
            // data[id]=undefined;
            setSelectedOptions(newArray);
        }else{
            const data=[...selectedOptions];
            if(data[id]!=undefined){
                data[id].items=selected;
                setSelectedOptions(data);
            }
        }
        // const selectedItem = selected[0] || {};
        // let prevData = [];
        // if (selectedOptions.length > 0) {
        //     prevData = [...selectedOptions];
        // }
        // const prevObj = prevData[id] != undefined ? prevData[id] : {};
        // prevObj.id = id;
        // const prevItems = prevObj.items || [];
        // let prevItemIndex = -1;
        // if (prevItems.length > 0) {
        //     prevItemIndex = prevItems.findIndex((v, i) => {
        //         return v.id == selectedItem.id;
        //     });
        // }
        // if (prevItemIndex == -1) {
        //     prevItems.push(selectedItem);
        // }
        // prevObj.items = prevItems;
        // prevData[id] = prevObj;
        // setSelectedOptions(prevData);

    };

    const handleSelectAll = () => {
        if (selectedOptions[id] && selectedOptions[id].items && selectedOptions[id].length === options.length) {
            const data = [...selectedOptions];
            if (data[id] !== undefined) {
                data[id] = undefined;
                setSelectedOptions(data);
            }
        } else {
            let data = [];
            data = [...selectedOptions];
            if (data[id] === undefined) {
                data[id] = { id: id, items: options };
                setSelectedOptions(data);
            }
        }
    };

    const handleClearAll = () => {
        const data = [...selectedOptions];
        if (data[id] !== undefined) {
            data[id] = undefined;
            setSelectedOptions(data);
        }
    };

    const handleOptionToggle = (selectedItem) => {
        let prevData = [];
        if (selectedOptions.length > 0) {
            prevData = [...selectedOptions];
        }
        const prevObj = prevData[id] != undefined ? prevData[id] : {};
        prevObj.id = id;
        const prevItems = prevObj.items || [];

        let prevItemIndex = -1;

        if (prevItems.length > 0) {
            prevItemIndex = prevItems.findIndex((v, i) => {
                return v.id == selectedItem.id;
            });
        }
        if (prevItemIndex == -1) {
            prevItems.push(selectedItem);
        }
        prevObj.items = prevItems;
        prevData[id] = prevObj;
        setSelectedOptions(prevData);
    };

    const isChecked = () => {
        let result = false;
        let data = [];

        if (selectedOptions.length > 0) {
            data = [...selectedOptions];
        }
        if (data.length === 0) {
            return false;
        }
        if (data[id] != undefined) {
            const items = data[id].items || [];
            if (items.length === 0) {
                return false;
            }

            const i = items.findIndex((v, i) => v.id == result.id);

            if (i == -1) {
                return false;
            } else {
                result = true;
            }
        }
        return result;
    }

  
    

    const renderMenu = (results, menuProps) => {
        if (!results.length) {
            return null;
        }

        return <div className="MultiSelected" {...menuProps}>
            <div>
                <input
                    type="checkbox"
                    checked={selectedOptions.length === options.length}
                    onChange={handleSelectAll}
                />
                <span>Select All</span>
                <hr className="mt-1 mb-0" />
            </div>
            <div className="MultiSelectedDrop">
                {results.map((result, index) => {
                    return <div key={index}>
                        <input
                            type="checkbox"
                            checked={isChecked()}
                            onChange={() => handleOptionToggle(result)}
                        />
                        <span><img src={result.image || logo} alt='' style={{ width: '30px', border: '1px solid #efefef', marginRight: '20px' }} />{result.label}</span>
                    </div>
                })}
            </div>
        </div>
    };

    const getSelectedItems = () => {
        if (selectedOptions[id] != undefined) {
            const dataItems = selectedOptions[id].items;
            return dataItems;
        }
        return [];
    }

    return (
        <>
            <label className="form-label inputLable">{labelText}</label>
            <Typeahead
                id="my-typeahead-id"
                multiple
                name={name}
                options={options}
                selected={getSelectedItems()}
                onChange={handleChange}
                placeholder="Select options"
                // labelKey={id}
                dropdownProps={{
                    style: { maxHeight: "200px", overflow: "auto" },
                }}
                renderMenu={renderMenu}
            />

            {selectedOptions[id] && selectedOptions[id].items && selectedOptions[id].items.length > 0 && (
                <button className="btn btn-sm btn-link mt-2" onClick={handleClearAll}>
                    X
                </button>
            )}

        </>
    );
};

