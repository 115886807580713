import React, { children } from "react";
import { Container } from "react-bootstrap";


export const StateBox = ({
  children
}) => {


  return (
    <>
      <div className="stateBox ">
        <Container>
          {children}
        </Container>
      </div>
    </>
  );
};
