import React from "react";
import { Link } from "react-router-dom";
import { Footer } from "../../../../public/containers/footer/Footer";
import { HeaderDashboard } from "../comman_page/HeaderDashboard";
import Sidebar from "../comman_page/Sidebar";
import { Row, Col } from "react-bootstrap";

export const AddItems = () => {
  return (
    <>
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col>
              <div className="RightSide">
                <HeaderDashboard />
                <section className="innerContent">
                  {/* Header Section */}
                <div id="mainpageWrapper" className="page-content-wrapper">
                  {/* <!-- =========== CONTENT ============ --> */}
                  <div className="page-content-inner bg-brand-dark py-3 px-5 relative">
                    {/* <!-- image layer --> */}
                    <div className="wrapp-back-layer">
                      <img src="assets/images/pictures/watermark_bg.png" />
                    </div>
                    {/* <!-- img layer --> */}

                    {/* <!-- step fill --> */}

                    <div className="stepsinfo-fill">
                      <ul className="steps">
                        <li className="completed-step"></li>
                        <li className="completed-step"></li>
                        <li></li>
                      </ul>
                    </div>
                    <section className="contentSections pl-5">
                      <div className="businessinner-sec bg-white border-radius10 py-5 relative">
                        <div className="container">
                          <h3 className="text-uppercase text-center title">
                            Time to set up your business details
                          </h3>
                          <p className="brand-light text-center">
                            Welcome to Studio Details. Click on a section to
                            add/update your company details, <br /> set up
                            scheduled activities such as classes and workshops,
                            add or manage staff,
                            <br /> clients, shifts, products, payments and more.
                          </p>
                          <div className="skip-head relative py-4">
                            <h3 className="title text-uppercase text-center">
                              Step 2 of 4: Add items to schedule
                            </h3>
                            <Link
                              to="/Home"
                              className="btn btn-brandwhite box-shadow-greenlight text-uppercase"
                            >
                              Leave Set Up
                            </Link>
                          </div>
                          <div className="row justify-content-center py-2">
                            <div className="col-md-6 col-lg-4">
                              <Link to="/list-class">
                                <div className="business-box p-3">
                                  <h5 className="title text-center">Classes</h5>
                                  <p className="text-center">
                                    Set up your list of classes which you can
                                    pick from when scheduling a class. Classes
                                    are group activities that can have one or
                                    more instructors.
                                  </p>
                                </div>
                              </Link>
                            </div>
                            <div className="col-md-6 col-lg-4 mb-4">
                              <Link to="/add-schedule-workshop">
                                {/* <!-- list-workshops --> */}
                                <div className="business-box p-3">
                                  <h5 className="title text-center">
                                    Workshops
                                  </h5>
                                  <p className="text-center">
                                    Set up your workshops here so when you are
                                    ready to schedule one, you will pick it from
                                    list and add the details such as dates,
                                    times, capacity, costs, and the pass
                                    required to sign up.
                                  </p>
                                </div>
                              </Link>
                            </div>
                            <div className="col-md-6 col-lg-4">
                              <Link to="/list-shifts">
                                <div className="business-box p-3">
                                  <h5 className="title text-center">Shifts</h5>
                                  <p className="text-center">
                                    In order to make sure you are staffed up
                                    properly, use shifts to set times where your
                                    staff are available for appointments or to
                                    work at the studio. Set your rules for
                                    appointments and shifts.
                                  </p>
                                </div>
                              </Link>
                            </div>
                            <div className="col-md-6 col-lg-4"></div>
                            <div className="col-md-6 col-lg-4">
                              <Link to="/business-location">
                                <div className="business-box p-3">
                                  <h5 className="title text-center">
                                    Add Room Location
                                  </h5>
                                  <p className="text-center">
                                    Set up the rooms where you will hold classes
                                    and workshops. Each room will have its own
                                    schedule.
                                  </p>
                                </div>
                              </Link>
                            </div>
                            <div className="col-md-6 col-lg-4"></div>
                          </div>
                          <Link
                            to="/add-things"
                            className="btn btn-brandbluegrad mx-auto mt-4"
                          >
                            Next
                          </Link>
                        </div>
                      </div>
                    </section>
                    {/* <!-- CONTENT SECTION END --> */}
                  </div>

                  <Footer />
                </div>
                </section>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
