import React, { useContext } from "react";
import { HeaderDashboard } from "../comman_page/HeaderDashboard";
import Sidebar from "../comman_page/Sidebar";
// import { Row, Col } from "react-bootstrap";
import { Footer } from "../../../../public/containers/footer/Footer";
import { HomePageContent } from "./homePageContent/HomePageContent";
import { Row, Col } from "react-bootstrap";
import { MyContext } from "../../../../App";
import { useEffect } from "react";
import { InstructorList, getProfile, getUserProfileInfo } from "../../../../Studio-Services/Studio_Services";
import { useState } from "react";

export const Home = () => {
 const {setUserData,instuctorNoti}= useContext(MyContext);
 const [userinfo, setUserinfo] = useState([]);
 const [businessData, setBusinessData] = useState([]);

 
 const getBusinessData = async () => {
  const resp = await getProfile();
  if (resp) {
    setBusinessData(resp.data.data);
  }
  const pageid = {pageid: 1,};
  const resp2 = await InstructorList(pageid);
  if(resp2){
  let count = 0;
  const data =resp2.data.data;
  if (data) {
    data.forEach((e) => {
      if (e.status === "Pending") {
        count += 1; // Increment count when the status is "Pending"
      }
    });
  }
  instuctorNoti(count); 
} 
};

const getUserData = async () => {
  const resp = await getUserProfileInfo();
  if (resp) {
    const useInfo = resp.data.data ? resp.data.data : '';
    setUserinfo(useInfo);
  }
};

useEffect(()=>{
  getBusinessData();
  getUserData();
},[])

 useEffect(()=>{
  setUserData(userinfo,businessData);
 },[userinfo,businessData])

  return (
    <>
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col className="p-0 RightCol">
              <div className="RightSide">
                <HeaderDashboard />
                <HomePageContent />
                <Footer />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
