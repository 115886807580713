import React from "react";
import {Container, Navbar, Nav} from 'react-bootstrap';
import { NavLink } from 'react-router-dom'
import { SharedButton } from "../../../sharedComponents/Button";



export const Header = () => {
  const navbarOptions=[
    {
      title:'Home',
      href:'www.google.com',
      className:'mt-10'

    },
    {
      title:'The Solution',
      href:'www.google.com',
      className:'mt-10'

    },
    {
      title:'The Apps',
      href:'www.google.com',
      className:'mt-10'

    },
    {
      title:'Pricing',
      href:'www.google.com',
      className:'mt-10'

    },
    {
      title:'Contact Us',
      href:'www.google.com',
      className:'mt-10'

    },
  ]
  return (
    <>
    
        {/* <!-- Navigation Section --> */}
<div className="header box-shadow1" id="myHeader">
    <div className="container">
      <nav className="navbar navbar-expand-lg navbar-light white-bg px-0">
        <a className="nav-link px-0" routerLink="/Home"> <img src="clientassets/img/logo_colored.png" className="img-fluid img-size" /> </a>
        <button className="navbar-toggler ml-auto my-3 my-lg-0" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        {/* <!-- buttons ends here --> */}

        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item " routerLinkActive="active" >
              <NavLink className="nav-link link header-css" to="/statichome"> Home </NavLink>
            </li>
            {/* <!-- Home --> */}
            <li className="nav-item" routerLinkActive="active">
              <NavLink className="nav-link link header-css" to="/solution" > The Solution </NavLink>
            </li>
            {/* <!-- Solution --> */}
            <li className="nav-item" routerLinkActive="active">
              <NavLink className="nav-link link header-css" to="/apps" > The Apps </NavLink>
            </li>
            {/* <!-- The App --> */}
            <li className="nav-item" routerLinkActive="active">
              <NavLink className="nav-link link header-css" to="/pricing" > Pricing </NavLink>
            </li>
            {/* <!-- Pricing --> */}
            <li className="nav-item" routerLinkActive="active">
              <NavLink className="nav-link link header-css" to="/contact-us" > Contact us </NavLink>
            </li>
            {/* <!-- Contatc us --> */}
            <li className="nav-item" routerLinkActive="active">
              <NavLink className="nav-link btn btn-blue-grad px-5 text-uppercase header-css" to="/demo" > Demo </NavLink>
            </li>
            {/* <!-- Demo --> */}
            <li className="nav-item" routerLinkActive="active">
              <NavLink className="nav-link btn btn-green-grad px-5 text-uppercase header-css" to="/login" > Login </NavLink>
            </li>
            {/* <!-- Login --> */}
          </ul>
        </div>
        {/* <!-- end of navbar-collapse --> */}
      </nav>
    </div>
    {/* <!-- end of container --> */}
  </div>
{/* <!-- End of Navigation Section --> */}
  
    {/* // <div>
    //   <Navbar bg="light" expand="lg">
    //     <Container>
    //       <Navbar.Brand href="#home"><img src="assets/images/logo_top_new.png" className="Navbar_Brand"/></Navbar.Brand>
    //       <Navbar.Toggle aria-controls="basic-navbar-nav" />
    //       <Navbar.Collapse id="basic-navbar-nav">
    //         <Nav className="ms-auto me-5">
    //           {navbarOptions?.map((element, index)=><Nav.Link href={element?.href} className={element?.className}>{element.title}</Nav.Link>)}
    //         </Nav>
    //         <SharedButton title={'Demo'} className={'btn'} type={'Link'}   variant="primary"/>
    //         <SharedButton title={'Login'} className={'btn'} type={'Link'}  variant="secondary"/>
    //       </Navbar.Collapse>
    //     </Container>
    //   </Navbar>
    // </div> */}
    </>
  );
};
