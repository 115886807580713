import React, { useEffect, useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import Sidebar from '../../comman_page/Sidebar';
import { HeaderDashboard } from '../../comman_page/HeaderDashboard';
import { Footer } from '../../../../../public/containers/footer/Footer';
import { useLocation } from 'react-router-dom';
import { SharedButton } from '../../../../../sharedComponents/Button';
import { monthMap } from '../../../../../sharedComponents/MyHelperFunction';


export const AttendanceChart = () => {

  const location = useLocation();
  const [month, setMonth] = useState([]);
  const [atendance, setAtendance] = useState([]);

  const [checkindata, setCheckindata] = useState([]);
  const [canceldata, setCanceldata] = useState([]);
  const [noshowdata, setNoshowdata] = useState([]);
  const [signupdata, setSignupdata] = useState([]);
  const [mylabels, setMylabels] = useState([]);



  useEffect(() => {
    if (location.state && location.state.mlist && location.state.mlist.length > 0) { setMonth(location.state.mlist); }
    if (location.state && location.state.atdCount && location.state.atdCount.length > 0) { setAtendance(location.state.atdCount); }
  }, [location.state])



  const setdatainarray = (atendance) => {
    if (atendance && atendance.length > 0) {



    
      let laabaal = [];
      let attas = [];
      let noshows = [];
      let cenchels = [];
      let upp = [];
      let mnt = '';


      atendance.forEach(at => {
        laabaal.push(at[0]['month']);
        mnt = at[0]['month'];

        let nos = 0;
        let cen = 0;
        let att = 0;
        let up = 0;

        at.forEach(f => {
          if (f.absent > 0 && mnt == f.month) {
            nos = f.absent;
          } else if (f.cancel > 0 && mnt == f.month) {
            cen = f.cancel;
          } else if (f.checkIn > 0 && mnt == f.month) {
            att = f.checkIn;
          } else if (f.singup > 0 && mnt == f.month) {
            up = f.singup
          }
        })

        noshows.push(nos);
        cenchels.push(cen)
        attas.push(att);
        upp.push(up);
      });

      laabaal.sort((a, b) => monthMap[a] - monthMap[b]);
      attas.sort((a, b) => monthMap[a.label] - monthMap[b.label]);
      noshows.sort((a, b) => monthMap[a.label] - monthMap[b.label]);
      cenchels.sort((a, b) => monthMap[a.label] - monthMap[b.label]);
      upp.sort((a, b) => monthMap[a.label] - monthMap[b.label]);

      laabaal.reverse();

      attas.reverse();
      noshows.reverse();
      cenchels.reverse();
      upp.reverse();

      if (attas && attas.length > 0) { setCheckindata(attas); } else { setCheckindata([]); }
      if (cenchels && cenchels.length > 0) { setCanceldata(cenchels); } else { setCanceldata([]); }
      if (noshows && noshows.length > 0) { setNoshowdata(noshows); } else { setNoshowdata([]); }
      if (upp && upp.length > 0) { setSignupdata(upp); } else { setSignupdata([]); }
      if (laabaal && laabaal.length > 0) { setMylabels(laabaal); }
    }
  }


  useEffect(() => { setdatainarray(atendance); }, [atendance])



  const options = {
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    plugins: {
      legend: {
        position: 'right',
      },
      title: {
        display: true,
        text: 'Grouped bar chart',
      },
    },
  };
  const data = {
    labels: mylabels,
    // labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [
      {
        label: 'Canceled',
        data: canceldata,
        // data: [5, 13, 20, 20, 25, 3, 5, 13, 20, 20, 25, 3, 5, 13, 20, 20, 25, 3, 5, 13, 20, 20, 25, 3, 5, 13, 20, 20, 25, 3, 5, 13, 20, 20, 25, 3],
        backgroundColor: '#ff0000',
      },
      {
        label: 'No Show',
        data: noshowdata,
        // data: [15, 20, 25, 40, 30, 14, 5, 13, 20, 20, 25, 3, 5, 13, 20, 20, 25, 3, 5, 13, 20, 20, 25, 3, 5, 13, 20, 20, 25, 3, 5, 13, 20, 20, 25, 3],
        backgroundColor: '#fbbc04',
      },
      {
        label: 'Checked-In',
        data: checkindata,
        // data: [8, 10, 15, 25, 25, 35, 5, 13, 20, 20, 25, 3, 5, 13, 20, 20, 25, 3, 5, 13, 20, 20, 25, 3, 5, 13, 20, 20, 25, 3, 5, 13, 20, 20, 25, 3],
        backgroundColor: '#6aa84f',
      },
      {
        label: 'Signed Up',
        data: signupdata,
        // data: [8, 10, 15, 25, 25, 35, 5, 13, 20, 20, 25, 3, 5, 13, 20, 20, 25, 3, 5, 13, 20, 20, 25, 3, 5, 13, 20, 20, 25, 3, 5, 13, 20, 20, 25, 3],
        backgroundColor: '#0000ff',
      },
    ],

  };

  return (
    <>
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col>
              <HeaderDashboard />
              <div className="RightSide">
                <div className="innerContent">
                  <section className="ClassAttendance">
                    <Container>
                      <Row className='mb-3 mt-3' >
                        <Col className='d-flex' style={{ justifyContent: 'space-between' }}>
                          <h4>Attendance by Status</h4>
                          <SharedButton
                            title={"Back"}
                            className={"ownbackground"}
                            type={"button"}
                            onClick={() => {
                              window.history.back();
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className='mb-5' style={{ maxWidth: "64rem" }}>
                        <Col>
                          <Bar
                            height={300} // Set height here
                            width={200}
                            options={{
                              options: options,
                              maintainAspectRatio: false // Set to false if you want to control the chart dimensions explicitly
                            }}
                            // options={options} 
                            data={data} />
                        </Col>
                      </Row>
                      {/* <Row className='mb-3 mt-3' > */}
                        {/* <Col className='d-flex' style={{ justifyContent: 'space-between' }}> */}
                          {/* <h4>Instructor</h4>
                        <h4>Class</h4> */}
                          {/* <h4>Date Range</h4> */}
                        {/* </Col> */}
                      {/* </Row> */}
                    </Container>
                  </section>
                </div>
                <Footer />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>

  );
};
