import React, { useEffect, useState } from 'react'
import { add_service_image, book_appointment } from '../../../../Studio-Services/Studio_Services';
import logo from '../../../../Images/logo_white_web.png'
import { errorAlert } from '../../../../Static_Services/Alertmsg';
import Swal from 'sweetalert2';


export default function AddServiceImage({ setLoder ,servicelistHndler,servlist}) {
    const business_id = localStorage.getItem("business_id");
    const [list, setList] = useState([]);
    
    useEffect(() => {
        setList(servlist); 
    }, [servlist]);



    const updateHandler = async (e, id) => {
        e.preventDefault();    
        let formData = new FormData();
        const image = e.target.image.files[0];
        if (!image) { errorAlert("Image Required") };
        if (id && image) {
            formData.append("image", image);
            formData.append("id", id);


            const resp = await add_service_image(formData);
            if (resp) {
                setLoder(false);
                servicelistHndler(business_id);
                Swal.fire({
                    html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" alt='' />
                <h4 class="title text-uppercase text-center mt-4 textWhite">
                SUCCESS
                </h4>
                <p class="text-center my-4 textcolor">
                <small class="textWhite">
                ${resp.data.message} </small></p> `,
                    showClass: {
                        popup: 'custom-dialog-container',
                    },
                    customClass: {
                        confirmButton: 'custom-button custom-button-size modalbtn',
                    },
                });
                e.target.reset();
                setLoder(false);
            } else
                setLoder(false)

        }
        // Your update logic here
        console.log("id", id);
    };


    return (
        <div className='p-4' style={{ border: "2px solid" }}>
            <table class="table table-striped table-hover" >
                <thead>
                    <tr>
                        <th scope="col">Sr No</th>
                        <th scope="col">Image</th>
                        <th scope="col">Service Name</th>
                        <th scope="col">Add New</th>
                        <th scope="col" style={{textAlign:'center'}}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {list && list.length > 0 && list.map((e, index) => (
                        <tr key={e.id}>
                            <th scope="row">{index + 1}</th>
                            <td>
                                <img
                                 className={e && e.image ? "img-fluid table_user" : "img-fluid table_user bggreenmy"} 
                                src={e && e.image ? e.image : logo}
                                style={{width: "100px", height: "50px",borderRadius: '3px'}} 
                             /></td>
                            <td style={{width:'330px'}}>{e.service_name}</td>
                            <td colspan="2">
                                <form onSubmit={(event) => updateHandler(event, e.id)}>
                                    <input type='file' name='image' />
                              
                                    <button className='btn btn-primary' type='submit' style={{ fontSize: '1rem', padding: "0.3rem 1rem" }}> 
                                     {!e.image ? 'Add' :'Update'}
                                     </button>
                         
                                </form>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div >
    )
}

