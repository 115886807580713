import React, { useEffect, useState } from "react";
import Sidebar from "../../comman_page/Sidebar";
import { HeaderDashboard } from "../../comman_page/HeaderDashboard";
import { Footer } from "antd/es/layout/layout";
import { Container, Row, Col, Form } from "react-bootstrap";
import { ProductCardList } from "../../../../../sharedComponents/ProductCardList";
import { InputField } from "../../../../../sharedComponents/InputField";
import { SharedSelect } from "../../../../../sharedComponents/SharedSelect";
import { SharedButton } from "../../../../../sharedComponents/Button";
import { TextArea } from "../../../../../sharedComponents/TextArea";
import { products_add, products_list } from "../../../../../Studio-Services/Studio_Services";
import { ProductCrt } from "./ProductCrt";
import Swal from "sweetalert2";
import logo from "../../../../../Images/logo_white_web.png"
import { InputTypeTax } from "../../../../../sharedComponents/InputTypeTax";

export const Listproduct = () => {
  const [tax1, setTax1] = useState();
  const [tax2, setTax2] = useState();
  const [productList, setProductList] = useState();
  const [base64Image, setBase64Image] = useState('');

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setError((pre) => ({ ...pre, product_images: "" }));
    }
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = reader.result;
      setBase64Image(base64String);
    };

    reader.readAsDataURL(file);
  };

  const [error, setError] = useState({
    product_name: "",
    product_price: "",
    product_desc: "",
    tax1: "",
    tax2: "",
    quantity: "",
    product_images: ""

  })

  const errorHandler = (e) => {
    const { name } = e.target;
    setError((pre) => ({ ...pre, [name]: "" }));
  }


  const pList = async () => {
    const fData = {
      "page_no": 1,
      "status": "view"
    }
    const resp = await products_list(fData);
    if (resp) {
      setProductList(resp.data.data);
    }
  }

  useEffect(() => {
    pList();
  }, [])


  const submitHandler = async (e) => {
    e.preventDefault();
    let isValid = 1;
    const formData = new FormData(e.target); // Create a FormData object from the form
    const formValues = Object.fromEntries(formData.entries());

    const product_name = formValues.product_name;
    const product_price = formValues.product_price;
    const product_desc = formValues.product_desc;
    const tax1 = formValues.tax1;
    const tax1_rate = formValues.tax1_rate ? formValues.tax1_rate : "";
    const tax2 = formValues.tax2;
    const tax2_rate = formValues.tax2_rate ? formValues.tax2_rate : "";
    const quantity = formValues.quantity;
    const product_images = e.target.product_images.files[0];


    if (!product_name) { setError((prev) => ({ ...prev, product_name: "Required" })); isValid = 2; };
    if (!product_price) { setError((pre) => ({ ...pre, product_price: "Required" })); isValid = 3 };
    if (!product_desc) { setError((pre) => ({ ...pre, product_desc: "Required" })); isValid = 4 };
    if (!tax1) { setError((pre) => ({ ...pre, tax1: "Required" })); isValid = 5 };
    if (!tax2) { setError((pre) => ({ ...pre, tax2: "Required" })); isValid = 6 };
    if (!quantity) { setError((pre) => ({ ...pre, quantity: "Required" })); isValid = 7 };
    if (!product_images) { setError((pre) => ({ ...pre, product_images: "Required" })); isValid = 8 };

   
    if (isValid === 1) {

      const finalData = new FormData();

      finalData.append('product_name', product_name);
      finalData.append('product_price', product_price);
      finalData.append('product_desc', product_desc);
      finalData.append('tax1', tax1);
      finalData.append('tax1_rate', tax1_rate);
      finalData.append('tax2', tax2);
      finalData.append('tax2_rate', tax2_rate);
      finalData.append('quantity', quantity);
      finalData.append('product_images[]', product_images);


   
      const resp = await products_add(finalData);
      if (resp) {
        pList();
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          SUCCESS
          </h4>
          <p class="text-center my-4 textcolor">
          <small class="textWhite">
          ${resp.data.message} </small></p>
          `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        })
      }
    }


  }




  const optionTax = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ]
  return (
    <>
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col className="p-0 RightCol">
              <HeaderDashboard />
              <div className="RightSide">
                <section className="addClient innerContent">
                  <Container className="pt-5">
                    <h4>List Your Products</h4>
                    <p>
                      Create a set of products you will offer your customer.
                      These are tied to appointments that a customer can make
                      with a staff/instructor, set your price and duration and
                      make them available or hidden when you want.
                    </p>
                    <div className="AddClientForm AddClientContent">
                      <ProductCrt data={productList} />
                      <div className="ListProductForm">
                        <Form onSubmit={submitHandler} encType="multipart/form-data">
                          <h3 className="my-5">List Product</h3>
                          <div className="ProfilePhoto">
                            <div className="ProfilePhoto">
                              <InputField
                                accept="image/x-png,image/gif,image/jpeg,image/png"
                                type="file"
                                name="product_images"
                                error={error.product_images}
                                onChange={handleImageUpload}
                                className={"FileTypeHide"} />
                              <img
                                src={base64Image ? base64Image : "assets/images/image_Holder.png"}
                                alt=""
                                className="img-fluid"
                              />
                              <p>Change Image</p>
                            </div>

                            <p>Product Image</p>
                          </div>
                          <Row className="mt-4">
                            <Col>
                              <InputField
                                type={"text"}
                                error={error.product_name}
                                name={"product_name"}
                                onChange={errorHandler}
                                lable={"Product Name"}
                              />
                            </Col>
                            <Col>
                              <InputField
                                type={"text"}
                                error={error.product_price}
                                name={"product_price"}
                                onChange={errorHandler}
                                step="0.01"
                                lable={"Unit Price"} />
                            </Col>
                            <Col>
                              <InputField
                                name={"quantity"}
                                type={"number"}
                                error={error.quantity}
                                onChange={errorHandler}
                                lable={"Quantity"} />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <SharedSelect
                                name="tax1"
                                error={error.tax1}
                                onChange={(e) => setTax1(e.target.value)} 
                                options={optionTax}
                                lable={"Tax 1 Applicable"} />
                            </Col>
                            {tax1 === "yes" ?
                              <Col>
                                <InputTypeTax
                                  name={"tax1_rate"} 
                                  type={"number"} 
                                  lable={"Tax 1 Rate %"} />
                              </Col>
                              : ""}
                            <Col>
                              <SharedSelect
                                error={error.tax2}
                                name="tax2" onChange={(e) => setTax2(e.target.value)} options={optionTax} lable={"Tax 2 Applicable"} />
                            </Col>
                            {tax2 === "yes" ?
                              <Col>
                                <InputTypeTax name="tax2_rate" type={"number"} lable={"Tax 2 Rate %"} />
                              </Col>
                              : ""}

                          </Row>
                          <Row className="mt-3">
                            <Col>
                              <TextArea
                                name={"product_desc"}
                                error={error.product_desc}
                                onChange={errorHandler}
                                lable={"Product Brief Description"} />
                            </Col>
                            <Col></Col>
                          </Row>
                          <Row>
                            <Col>
                              <SharedButton
                                title={"Save"}
                                className={"btn btn-primary w-100"}
                                variant={"primary"}
                                type={"submit"}
                              />
                            </Col>
                            <Col>
                              <SharedButton
                                title={"Done With Product"}
                                href={"/add-things"}
                                className={"btn btn-outline w-100"}
                                variant={"outline"}
                              />
                            </Col>
                            <Col></Col>
                          </Row>
                        </Form>
                      </div>
                    </div>
                  </Container>
                </section>
                <Footer />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
