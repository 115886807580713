import React from "react";
import Sidebar from "../../comman_page/Sidebar";
import { HeaderDashboard } from "../../comman_page/HeaderDashboard";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { Footer } from "../../../../../public/containers/footer/Footer";
import { MyDatePicker } from "../../../../../sharedComponents/MyDatePicker";
import { ProductCard } from "../../../../../sharedComponents/ProductCard";
import { SharedButton } from "../../../../../sharedComponents/Button";
import { DataTable } from "../../../../../sharedComponents/DataTable";
import { useEffect } from "react";
import moment from "moment";
import { useState } from "react";
import { get_product_report } from "../../../../../Studio-Services/Studio_Services";
import { NewMyDatePicker } from "../../../../../sharedComponents/NewMyDatePicker";
// import { SharedMutiSelect } from "../../../../../sharedComponents/SharedMutiSelect";
// import { SheredMutiSelect } from "../../../../../sharedComponents/SheredMutiSelect";

export const Product = () => {
  const [prodetail, setProdetail] = useState([]);
  const [loder, setLoder] = useState(true);
  const [dates,setDates]= useState();
  const [tableData, settableData] = useState([]);

  const getDetail = async (data) => {
    let currentDate = "";
    if (data) {
      currentDate = data;
    } else {
      currentDate = moment().format('YYYY-MM-DD');
    }
    setDates(currentDate);
    const fdata = {
      "start_date": currentDate,
      "end_date": currentDate
    }
    const resp = await get_product_report(fdata);
    if (resp) {
      setLoder(false);
      const array = [];
      (resp.data.data.cards).map((e) => (
        array.push({
          ProductHeading: e.name,
          ProductPrice: e.count,
        })
      ))
      setProdetail(array);
    }
  }
  const dateHandler = (data) => {
    const fDate = moment(data).format("YYYY-MM-DD");
    getDetail(fDate);
  }

  useEffect(() => {
    getDetail();
  }, [])
  const columns = [
    { dataField: "sr", text: "#" },
    { dataField: "Name", text: "Name" },
    { dataField: "Unit_Sold", text: "Unit Sold" },
    { dataField: "Amount", text: "Amount" },
    { dataField: "Discount", text: "Discount" },
    { dataField: "Tax_01_Amount", text: "Tax 01 Amount" },
    { dataField: "Tax_02_Amount", text: "Tax 02 Amount" },
    { dataField: "Total_Amount", text: "Total Amount" },
  ];

  const data = [];

  const productHandler = async (type) =>{
    let startDates="";
    let endDates= "";

    if (type == 4) {
      startDates = moment().subtract(180, "days").format("YYYY-MM-DD");
      endDates = moment().format("YYYY-MM-DD");
    } else if (type == 2) {
      startDates = moment().subtract(30, "days").format("YYYY-MM-DD");
      endDates = moment().format("YYYY-MM-DD");
    } else if (type == 3) {
      startDates = moment().subtract(90, "days").format("YYYY-MM-DD");
      endDates = moment().format("YYYY-MM-DD");
    }

    const finalData = {
      "start_date": startDates,
      "end_date": endDates
    }
    const resp = await get_product_report(finalData);
    if (resp) {
      const array = [];
    
      setLoder(false);
      (resp.data.data.cards).map((e) => (
        array.push({
          ProductHeading: e.name,
          ProductPrice: e.count,
        })
      ))
      setProdetail(array);

      // Table Data
      const td=[];
      const productResp = resp.data.data.product;
      if(productResp){
        productResp.map((e,index)=>(
          td.push({
            sr:index+1,
            Name:e.name,
            Unit_Sold:e.unit_sold,
            Amount : `$ ${e.amount}`,
            Discount:`$ ${e.discount}`,
            Tax_01_Amount:`$ ${e.tax1_amount}`,
            Tax_02_Amount:`$ ${e.tax2_amount}`,
            Total_Amount:`$ ${e.total_amount}`
          })
   
        ))
        settableData(td);
      }else  settableData([]);
      }
  }
  

  return (
    <>
      {loder ? <div className="MainLoader"><Spinner animation="border" /> </div> : ""}
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col>
              <HeaderDashboard />
              <div className="RightSide">
                <Container>
                  <div className="TotalProductCard">
                    <ProductCard prodetail={prodetail} />
                  </div>
                  <div className="ProductPanel">
                    <Row className="mt-3">
                      <Col md={4}>
                        <MyDatePicker
                          onChange={dateHandler}
                          value={dates}
                        />
                      </Col>
                      <Col md={8}>
                        <SharedButton
                          title={"01 Month"}
                          variant={"outline"}
                          className={"MonthBtn"}
                          onClick={()=>productHandler(2)}
                        />
                        <SharedButton
                          title={"03 Month"}
                          variant={"outline"}
                          className={"MonthBtn"}
                          onClick={()=>productHandler(3)}
                        />
                        <SharedButton
                          title={"06 Month"}
                          variant={"outline"}
                          className={"MonthBtn"}
                          onClick={()=>productHandler(4)}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-5">
                      <NewMyDatePicker columns={columns} data={tableData} />
                    </Row>
                  </div>
                </Container>
              </div>
              <Footer />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
