import React from "react";
import Sidebar from "../../comman_page/Sidebar";
import { HeaderDashboard } from "../../comman_page/HeaderDashboard";
import { Col, Container, Row } from "react-bootstrap";
import { SharedButton } from "../../../../../sharedComponents/Button";
import { Footer } from "../../../../../public/containers/footer/Footer";
import { FaDownload } from "react-icons/fa";
import { InputField } from "../../../../../sharedComponents/InputField";

export const Payroll = () => {
  return (
    <>
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col>
              <div className="RightSide">
                <HeaderDashboard />
                <section className="addClient innerContent">
                  <div className="AddClientContent" id="PayRoll">
                    <Container fluid>
                      <Row className="border-bottom">
                        <Col>
                          <h4>UPDATE PAYROLL</h4>
                        </Col>
                      </Row>

                      <Row className="mt-3">
                        <h5 className="mb-3">Class Standard Rates</h5>
                        <Col>
                          <InputField
                            type={"number"}
                            lable={"Class Hourly Rate"}
                            placeholder={"0"}
                            className={"PayrollField form-control"}
                          />
                        </Col>
                        <Col>
                          <InputField
                            type={"number"}
                            lable={"Class Flat Rate"}
                            placeholder={"0"}
                            className={"PayrollField form-control"}
                          />
                        </Col>
                        <Col>
                          <InputField
                            type={"number"}
                            lable={"Class Private Rate"}
                            placeholder={"0"}
                            className={"PayrollField form-control"}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col>
                          <InputField
                            type={"number"}
                            lable={"Class Semi Private Rate"}
                            placeholder={"0"}
                            className={"PayrollField form-control"}
                          />
                        </Col>
                        <Col>
                          <InputField
                            type={"number"}
                            lable={"Class Substitution Rate"}
                            placeholder={"0"}
                            className={"PayrollField form-control"}
                          />
                        </Col>
                        <Col>
                          <InputField
                            type={"number"}
                            lable={"Class Revenue Share Rate 1"}
                            placeholder={"0"}
                            className={"PayrollField form-control"}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col>
                          <InputField
                            type={"number"}
                            lable={"Class Revenue Share Rate 2"}
                            placeholder={"0"}
                            className={"PayrollField form-control"}
                          />
                        </Col>
                        <Col></Col>
                        <Col></Col>
                      </Row>

                      <Row className="mt-3  border-top">
                        <h5 className="my-4">Class Rates/Client</h5>
                        <Col>
                          <InputField
                            type={"number"}
                            lable={"Min Rate / Class"}
                            placeholder={"0"}
                            className={"PayrollField form-control"}
                          />
                        </Col>
                        <Col>
                          <InputField
                            type={"number"}
                            lable={"Tier 1 (set range of clients)"}
                            placeholder={"0"}
                            className={"PayrollField form-control"}
                          />
                        </Col>
                        <Col>
                          <InputField
                            type={"number"}
                            lable={"Tier 2 (set 2nd range of clients)"}
                            placeholder={"0"}
                            className={"PayrollField form-control"}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col>
                          <InputField
                            type={"number"}
                            lable={"Tier 3 (Set 3rd range of clients)"}
                            placeholder={"0"}
                            className={"PayrollField form-control"}
                          />
                        </Col>
                        <Col>
                          <InputField
                            type={"number"}
                            lable={"Max Pay / Client"}
                            placeholder={"0"}
                            className={"PayrollField form-control"}
                          />
                        </Col>
                        <Col></Col>
                      </Row>

                      <Row className="mt-3  border-top">
                        <h5 className="my-4">Service Payroll</h5>
                        <Col>
                          <InputField
                            type={"number"}
                            lable={"Non Service Shift Hourly Rate"}
                            placeholder={"0"}
                            className={"PayrollField form-control"}
                          />
                        </Col>
                        <Col>
                          <InputField
                            type={"number"}
                            lable={"Service Hourly Rate 1"}
                            placeholder={"0"}
                            className={"PayrollField form-control"}
                          />
                        </Col>
                        <Col>
                          <InputField
                            type={"number"}
                            lable={"Service Hourly Rate 2"}
                            placeholder={"0"}
                            className={"PayrollField form-control"}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col>
                          <InputField
                            type={"number"}
                            lable={"Service Flat Rate 1"}
                            placeholder={"0"}
                            className={"PayrollField form-control"}
                          />
                        </Col>
                        <Col>
                          <InputField
                            type={"number"}
                            lable={"Service Flat Rate 2"}
                            placeholder={"0"}
                            className={"PayrollField form-control"}
                          />
                        </Col>
                        <Col>
                          <InputField
                            type={"number"}
                            lable={"Service Flat Rate 3"}
                            placeholder={"0"}
                            className={"PayrollField form-control"}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col>
                          <InputField
                            type={"number"}
                            lable={"Service Group Rate 1"}
                            placeholder={"0"}
                            className={"PayrollField form-control"}
                          />
                        </Col>
                        <Col>
                          <InputField
                            type={"number"}
                            lable={"Service Group Rate 2"}
                            placeholder={"0"}
                            className={"PayrollField form-control"}
                          />
                        </Col>
                        <Col>
                          <InputField
                            type={"number"}
                            lable={"Service Group Rate 3"}
                            placeholder={"0"}
                            className={"PayrollField form-control"}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col>
                          <InputField
                            type={"number"}
                            lable={"Service % of Revenue Rate 1"}
                            placeholder={"0"}
                            className={"PayrollField form-control"}
                          />
                        </Col>
                        <Col>
                          <InputField
                            type={"number"}
                            lable={"Service % of Revenue Rate 2"}
                            placeholder={"0"}
                            className={"PayrollField form-control"}
                          />
                        </Col>
                        <Col></Col>
                      </Row>

                      <Row className="mt-3  border-top">
                        <h5 className="my-4">Workshop Payroll</h5>
                        <Col>
                          <InputField
                            type={"number"}
                            lable={"Workshop Hourly Rate 1"}
                            placeholder={"0"}
                            className={"PayrollField form-control"}
                          />
                        </Col>
                        <Col>
                          <InputField
                            type={"number"}
                            lable={"Workshop Hourly Rate 2"}
                            placeholder={"0"}
                            className={"PayrollField form-control"}
                          />
                        </Col>
                        <Col>
                          <InputField
                            type={"number"}
                            lable={"Workshop % of Revenue 1"}
                            placeholder={"0"}
                            className={"PayrollField form-control"}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col>
                          <InputField
                            type={"number"}
                            lable={"Workshop % of Revenue 2"}
                            placeholder={"0"}
                            className={"PayrollField form-control"}
                          />
                        </Col>
                        <Col>
                          <InputField
                            type={"number"}
                            lable={"Workshop Flat Rate 1"}
                            placeholder={"0"}
                            className={"PayrollField form-control"}
                          />
                        </Col>
                        <Col>
                          <InputField
                            type={"number"}
                            lable={"Workshop Flat Rate 2"}
                            placeholder={"0"}
                            className={"PayrollField form-control"}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col>
                          <InputField
                            type={"number"}
                            lable={"Workshop Sub Flat Rate 1"}
                            placeholder={"0"}
                            className={"PayrollField form-control"}
                          />
                        </Col>
                        <Col>
                          <InputField
                            type={"number"}
                            lable={"Workshop Sub Flat Rate 2"}
                            placeholder={"0"}
                            className={"PayrollField form-control"}
                          />
                        </Col>
                        <Col>
                          <InputField
                            type={"number"}
                            lable={"Workshop Sub Hourly Rate 1"}
                            placeholder={"0"}
                            className={"PayrollField form-control"}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col>
                          <InputField
                            type={"number"}
                            lable={"Workshop Sub Hourly Rate 2"}
                            placeholder={"0"}
                            className={"PayrollField form-control"}
                          />
                        </Col>
                        <Col></Col>
                        <Col></Col>
                      </Row>

                      <Row className="my-4">
                        <Col>
                          <SharedButton
                            title={"Save"}
                            variant={"primary"}
                            className={"primary w-100"}
                          />
                        </Col>
                        <Col>
                          <SharedButton
                            title={"Back"}
                            variant={"Outline"}
                            className={"primary w-100"}
                          />
                        </Col>
                        <Col></Col>
                      </Row>
                    </Container>
                  </div>
                </section>
                <Footer />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
