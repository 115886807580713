import React from "react";
import { Carousel, Card, Row, Col } from "react-bootstrap";
import { FaLongArrowAltRight, FaLongArrowAltLeft } from 'react-icons/fa';

export const ClassCardCarousel = (props) => {
  const cardDetail = props.pass ? props.pass : [];


  const chunks = [];
  for (let i = 0; i < cardDetail.length; i += 3) {
    chunks.push(cardDetail.slice(i, i + 3));
  }

  const settings = {
    prevIcon: <FaLongArrowAltLeft />,
    nextIcon: <FaLongArrowAltRight />,
    interval: 3000,
    pauseOnHover: true,
    wrap: true,
    touch: true,
    keyboard: true,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      {chunks.length > 0 && (
        <Carousel {...settings}>
          {chunks.map((chunk, index) => (
            <Carousel.Item key={index}>
              <Row>
                {chunk.map((value, cardIndex) => (
                  <Col lg={4} xs={12} key={cardIndex}>
                    <Card className="blueback-pass">
                      <div className="cardImg">
                        <Card.Img variant="top" src={value.memberShipImg} />
                        <p>Valid For {value.validText} Days</p>
                      </div>
                      <Card.Body>
                        <Card.Title>{value.cardTitle}</Card.Title>
                        <Card.Text>
                         $ {parseFloat(value.price).toFixed(2)} <small>{value.StringTax}</small>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Carousel.Item>
          ))}
        </Carousel>
      )}
    </>
  )
}
