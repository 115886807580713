import React, { useState, useRef, useEffect } from "react";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Instructor } from "../superadmin/private/dashboard/users/instructor/Instructor";
import listPlugin from "@fullcalendar/list";
import * as bootstrap from "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";

export const CalenderScheduler = ({ resp }) => {
  const calendarRef = useRef(null);
  const [selectedDate, setSelectedDate] = useState(null);
  // const [showExtraInfo, setShowExtraInfo] = useState(false);

  const [selectedEvent, setSelectedEvent] = useState(null);

  const Events = [];
  const list = () => {
    if(resp){
    resp.map((e, index) => {
      Events.push({
        id: index,
        title: e.class_name,
        description: "Meeting with client",
        start: moment.unix(e.from_time_utc).format("YYYY-MM-DDTHH:mm:ss"),
        end: moment.unix(e.to_time_utc).format("YYYY-MM-DDTHH:mm:ss"),
      })
    })
    setSelectedEvent(Events);
  }
  }

  useEffect(() => {
    if (resp.length > 0) {
      list();
    }
  }, [resp])

  // let Earray = [];
  // if (resp) {
  //   resp.forEach((element, index) => {
  //     Earray.push({
  //       id: index + 1,
  //       title: 1,
  //       start: element.scheduled_date,
  //       description: "Doctor's appointment",
  //       meeting:"Meatting",
  //       resourceId: "b", // start out in resource 'b'
  //       constraint: {
  //         resourceIds: ["a", "b", "c"], // constrain dragging to these
  //       },
  //     });
  //   });
  // }

  // const mergedArr = Earray.reduce((result, current) => {
  //   const existing = result.find((item) => item.start === current.start);
  //   if (existing) {
  //     existing.title += current.title;
  //   } else {
  //     result.push({
  //       id: current.id,
  //       title: current.title,
  //       meeting:"Meatting",
  //       description: "Doctor's appointment",
  //       start: current.start,
  //     });
  //   }

  //   return result;
  // }, []);

  // const handleEventClick = (arg) => {
  //   const event = arg.event;

  //   setSelectedEvent(event);
  // };

  // const closeDropdown = () => {
  //   setSelectedEvent(null);
  // };


  // const events = [
  //   {
  //     id: 1,
  //     title: "Meeting",
  //     description: "Meeting with client",
  //     start: "2023-07-04",
  //     end: "2023-07-04",
  //   },
  //   {
  //     id: 2,
  //     title: "Appointment",
  //     description: "Doctor's appointment",
  //     start: "2023-07-05",
  //     end: "2023-07-05",
  //   },
  // ];

  const eventDidMount = (info) => {
    const description = info.event.extendedProps.description;
    const eventElement = info.el;
    const descriptionElement = document.createElement("div");
    descriptionElement.classList.add("fc-description");
    descriptionElement.textContent = `Description: ${description}`;
    descriptionElement.style.display = "none"; // Set initial display to "none"
    eventElement.appendChild(descriptionElement);

    eventElement.addEventListener("click", () => {
      if (descriptionElement.style.display === "none") {
        descriptionElement.style.display = "block";
      } else {
        descriptionElement.style.display = "none";
      }
    });
  };


  const calendarOptions = {
    plugins: [
      dayGridPlugin,
      timeGridPlugin,
      resourceTimeGridPlugin,
      interactionPlugin,
      listPlugin  // Add listPlugin to the plugins array
    ],
    schedulerLicenseKey: "GPL-My-Project-Is-Open-Source",
    headerToolbar: {
      left: "prev,today,next",
      center: "title",
      right: "timeGridDay,timeGridWeek,dayGridMonth,listWeek",
    },
    buttonText: {
      prev: "Previous",
      next: "Next",
      today: "Today",
      listWeek: "List"
    },
    slotMinTime: "06:00:00",
    slotMaxTime: "21:00:00",
    slotLabelInterval: { hours: 1 },
    initialView: 'timeGridDay',
    expandRows: true,
    events:selectedEvent,
    height: "80vh",


    eventDidMount: eventDidMount,
  };

  return (
    <>
      <FullCalendar ref={calendarRef} {...calendarOptions} />
    </>
  );
};
