import React from 'react'
import { InnerHeader } from '../../Components/inner-header/InnerHeader1'
import { SubHeader } from '../../Components/sub-header/SubHeader'
import { Innerfooter } from '../../Components/inner-footer/Innerfooter'
import { Link, useLocation } from 'react-router-dom'
import { DashboardToSectionDetails } from '../../Components/CommanPages/DashboardToSectionDetails'
import { useState } from 'react'
import { useEffect } from 'react'
import { favourite, staff_passes_details } from '../../../Static_Services/Staff_Services'
import { successAlert } from '../../../Static_Services/Alertmsg'

export const PassDetail= () => {
  const location = useLocation();
  const [passDetail, setPassDetail] = useState();


  const getDetails = async () => {
    const fData = {
      "pass_id": location.state.items.id,
      "user_booking_id": location.state.items.user_booking_id
    }
    const resp = await staff_passes_details(fData);
    if (resp) {
      setPassDetail(resp.data.data);
    } else setPassDetail();
  }

  useEffect(() => {
    if (location.state.items.id) {
      getDetails();
    }
  }, [location])

  const addFav = async (id,status) =>{
    var detail ={  "service_type":"2",   "service_id":id,   "status":status};
  const resp = await favourite(detail);
  if(resp){
    successAlert(resp.data.message);
    getDetails();
  }
  }

  return (
    <>
      <InnerHeader />
      <div className="body-grey">
        <div className="studio-details">
          <div className="container">
            <DashboardToSectionDetails />
            <div className="pb-5">
              <div className="row">
                <div className="col-6 text-left">
                  <h4 className="title black-color text-uppercase"> Pass details </h4>
                </div>
                <div className="col-6 text-right mb-3">
                  <button type="button" className="btn btn-blue-grad px-2 text-uppercase" onClick={()=>window.history.back()}>Back </button>
                </div>
              </div>
              {/* <!-- End of H\eading --> */}

              <div className="s-d-appoint-block">
                <div className="row">
                  <div className="col-12 col-lg-7 mt-3 mt-lg-0">
                    <div className="home-inner-available border-radius6 box-shadow1 white-bg p-4">
                      {passDetail &&
                        <div class="" >
                          <div class="available-card">
                            <div class="available-blue-card p-3 p-sm-4">
                              <div class="row mx-0 ">
                                <div class="col-4 pass-logo">
                                  <img src={passDetail.business_logo} class="img-fluid" />
                                </div>
                                <div class="col-8 flex-fill pr-4 pl-5 passTextwidth">
                                  <h5 class="white-color font-14 mb-2 mt-0">{passDetail.pass_name}</h5>
                                  {passDetail.pass_type_name == 'Recurring Membership' ?
                                    <h5 class="white-color font-14 mb-2 mt-0">${ passDetail.full_price } <span class="font-12"> Plus Tax </span></h5>
                                    :
                                    <h5 class="white-color font-14 mb-2 mt-0">${ passDetail.amount } <span class="font-12"> Plus Tax </span></h5>
                                  }
                                </div>
                              </div>
                              <div class="row mt-2 align-items-end">
                                {(passDetail.pass_current_status == 0 && passDetail.is_one_time_purchase == 1) || (passDetail.pass_current_status == 0 && passDetail.is_one_time_purchase == 0) ?
                                  <div class="col-12 text-left pr-0">
                                    {passDetail.pass_type_category == 'PunchCard' &&
                                      <p class="mb-0 white-color paratitle" > Remaining Classes : {passDetail.remaining_count} of {passDetail.total_count} </p>
                                    }
                                    {passDetail.pass_type_category == 'TimeFrame' || passDetail.pass_type_category == 'Other' || passDetail.pass_type_category == 'Recurring Membership' ?
                                      <p class="mb-0 white-color paratitle" > Remaining Days : {passDetail.remaining_count} of {passDetail.total_count}</p>
                                      : ''}
                                  </div>
                                  : ''}
                                <div class="col-8 text-left pr-0">
                                  <p class="mb-0 white-color paratitle"> Valid For { passDetail.pass_validity }</p>
                                  {(passDetail.pass_current_status == 0 && passDetail.is_one_time_purchase == 1)  || (passDetail.pass_current_status == 0 && passDetail.is_one_time_purchase == 0) ?
                                  <p class="mb-0 white-color paratitle" > Start Date : { passDetail.start_date_utc_new } </p>
                                :""}
                              </div>
                            </div>
                          </div>
                          <div class="studio-card-select">
                            {passDetail.favourite == 0 ?
                            <i class="fas fa-heart brand-color3 font-22" 
                            onClick={()=>addFav(passDetail.pass_id,1)}
                            ></i>
                         :"" }
                         { passDetail.favourite == 1 ?
                          <i class="fas fa-heart brand-color2 font-22" 
                            onClick={()=>addFav(passDetail.pass_id,0)}
                            ></i>
                         :'' }

              </div>
                  </div>
                </div>
}
                {/* <!-- Card-3 --> */}

                <div className="row ml-2 mr-2 mt-2 d-block">
                  <h5 className="title black-color text-uppercase">Description </h5>
                  <p> {passDetail && passDetail.description ? passDetail.description : ""}</p>
                </div>

                <div className="row product-detail-section-btn px-2 pt-3 pb-1 d-none" >
                  <div className="col-6">
                    <button className="btn new-blue btn-blue-grad font-w-600 text-uppercase py-3" > Add to cart </button>
                    <Link to="/cart" className="btn new-blue btn-blue-grad font-w-600 text-uppercase mx-0 py-3" > Go to cart </Link>
                  </div>
                  <div className="col-6">

                    <button type="button" className="btn new-green btn-green-grad font-w-600 text-uppercase mx-0 py-3"> buy now </button>
                  </div>

                </div>
                {/* <!-- End of Button --> */}

                <div className="row product-detail-section-btn px-2 pt-3 pb-1 d-none" >
                  <div className="col-12">
                    <h5 className="title green-color text-center">Pass Already Purchased </h5>

                  </div>

                </div>
                {/* <!-- End of Button --> */}
              </div>

            </div>
            {/* <!-- End of Available Passes --> */}
            {passDetail &&
            <div className="col-12 col-lg-5 mt-5 mt-lg-0" >
              <div className="s-d-appoint-block-right border-radius6 box-shadow1 white-bg" >
                <div className="px-3 py-3 text-left light-bg border-t-lr-6">
                  <h5 className="title black-color font-w-100 text-uppercase font-16 mb-0"> payment details </h5>
                </div>
                {/* <!-- top heading --> */}

                <div className="px-3 pt-4 pb-2 border-bottom">
                  <div className="row mb-2">

                    <div className="col-6 text-left">
                      <p className="black-color font-w-100 font-18"> Full Price</p>
                      {/* <!-- (PassCount Item)  --> */}
                    </div>
                    <div className="col-6 text-right">
                      <p className="black-color font-w-100 font-18"> {passDetail.full_price} </p>
                    </div>
                    <div className="col-6 text-left">
                      <p className="black-color font-w-100 font-18"> Pro-Rated Amount</p>
                      {/* <!-- (PassCount Item) --> */}
                    </div>
                    <div className="col-6 text-right">
                      <p className="black-color font-w-100 font-18"> {passDetail.amount} </p>
                    </div>


                    <div className="col-6 text-left">
                      <p className="black-color font-w-100 font-18"> Price(PassCount Item) </p>
                    </div>
                    <div className="col-6 text-right">
                      <p className="black-color font-w-100 font-18"> {passDetail.amount} </p>
                    </div>

                  </div>

                  <div className="row">
                    <div className="col-6 text-left">
                      <p className="black-color font-w-100 font-18"> Tax 1 </p>
                    </div>
                    <div className="col-6 text-right">
                      <p className="black-color font-w-100 font-18"> {passDetail.tax1_rate} </p>
                    </div>
                    <div className="col-6 text-left">
                      <p className="black-color font-w-100 font-18"> Tax 2 </p>
                    </div>
                    <div className="col-6 text-right">
                      <p className="black-color font-w-100 font-18"> {passDetail.tax2_rate} </p>
                    </div>
                  </div>
                </div>
                {/* <!-- end of Appointment Fees: --> */}

                <div className="px-3 pt-4 pb-3 border-bottom">
                  <div className="row mb-2">
                    <div className="col-6 text-left">
                      <p className="title black-color font-w-100 font-18 mb-0" > Amount Payable </p>
                      {/* <p className="title black-color font-w-100 font-18 mb-0"> Total Paid </p> */}
                    </div>
                    <div className="col-6 text-right">
                      <p className="title black-color font-w-100 font-18 mb-0"> {passDetail.pass_total_price} </p>
                    </div>
                  </div>
                </div>
                {/* <!-- end of Amount Payable --> */}

                {/* <div className="px-3 py-3 text-center">
                  <div>

                    <input type="checkbox" className="mt-2 mb-2" /> policy_detail
                  </div>
                  <button type="button" className="btn btn-blue-grad px-4 w-50 text-uppercase mb-0 mb-md-5 mt-2"> checkout </button>
                  <p className="black-color font-w-100 font-18 mb-0 mb-3" >businessDetail.studio_message</p>
                </div> */}
                {/* <!--  data-toggle="modal" data-target="#clientCheckOut" end of button and view policy --> */}
              </div>
           

            </div>
                }
            {/* <!-- end of col-md-6 --> */}
          </div >
          {/* <!-- end of row --> */}
        </div >
        {/* <!-- end of s-d-appoint-block --> */}
      </div >
      {/* <!-- end of s-d-appoint --> */}
    </div >
      {/* <!-- end of container --> */ }
        </div >
  {/* <!-- End of My Studio Card Detials-Service Section --> */ }
      </div >
  <Innerfooter />


{/* <!-- CHECKOUT MODAL POPUP --> */ }
<div className="modal fade d-none" id="clientCheckOut" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered" role="document">
    <div className="modal-content">
      <div className="modal-body border-radius6 box-shadow1 p-5 white-bg">
        <div className="border-radius6 box-shadow-blue blue-gradient py-5 px-4 text-center">
          <img src="clientassets/img/logo_white.png" className="img-fluid" />
          <h4 className="title white-color text-uppercase my-4 font-w-600"> select checkout <br /> option </h4>
          <button data-dismiss="modal" type="button" className="btn btn-white2 text-uppercase w-100 mb-4"> pay now </button>
          <button data-dismiss="modal" type="button" className="btn btn-white2 text-uppercase w-100" > pay on pick up </button>
        </div>
      </div>
    </div>
  </div>
</div>
{/* <!-- END OF CHECKOUT MODAL POPUP --> */ }

{/* <!-- CHECKOUT MODAL POPUP --> */ }
<div className="modal fade d-none" id="busLocation" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered" role="document">
    <div className="modal-content">
      <button type="button" className="close text-right mr-2" data-dismiss="modal" aria-label="Close" >
        <span aria-hidden="true">&times;</span>
      </button>
      <div className="modal-body border-radius6 box-shadow1 p-5 light-bg">
        <div className="text-center">

          <h4 className="title black-color text-uppercase mt-4"> Terms & Conditions </h4>
        </div>
        <div className="border-radius6 box-shadow-blue blue-gradient p-4 text-center" >
        </div>
      </div>
    </div>
  </div>
</div>
{/* <!-- END OF CHECKOUT MODAL POPUP --> */ }

    </>
  )
}
