import React, { useEffect, useState } from 'react'
import { WorkshopForm } from './WorkshopForm'
import { Col, Row, Spinner } from 'react-bootstrap'
import Sidebar from "../../comman_page/Sidebar";
import { HeaderDashboard } from "../../comman_page/HeaderDashboard";
import { Footer } from "../../../../../public/containers/footer/Footer";
import { useLocation } from 'react-router-dom';


export const WorkShopEdit = () => {
  const location = useLocation();
  const [loder,setLoder] = useState(false);
  const [sDate,setSDate] = useState();
  const [preSelectedDate ,setPreSelectedDate] = useState();
  const [preLocalData,setPreLocalData] = useState([]);
  useEffect(()=>{
    if(location.state.data.data){
      localStorage.setItem('viewSchedule',JSON.stringify(location.state.data.data));
      setPreLocalData(location.state.data.data);
      setPreSelectedDate(location.state.selectedDate)
    }
  },[])

  const loderHandler = (data) =>{
    setLoder(data)
  }

  return (
    <>
    {loder && <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> </Spinner> </div> }
    <div className="MainDashboard">
      <div className="MainLayout">
        <Row>
          <Sidebar />
          <Col>
            <HeaderDashboard />
            <div className="RightSide">
              <div className="innerContent">
                <WorkshopForm  setLoder={loderHandler} preSelectedDate={preSelectedDate} preLocalData={preLocalData}/>
                {/* <ClassSchedulePreview/> */}
                <Footer />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
    </>
  )
}
