import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BsCalendarDateFill } from "react-icons/bs";

export const MyDatePicker = ({ DatepickerLabel="", value, name, error, readOnly, className, onChange }) => {
  const [date, setDate] = useState();

  const handleDateChange = (date) => {
    const mydate = moment(date).format('YYYY-MM-DD');
    setDate(date);
    if (onChange) {
      let target = {
        target: {
          name: name,  // Assuming "name" is a variable containing a name string
          value: mydate    // Assuming "date" is a variable containing a date value
        }
      };
      onChange(target);

    }
  }


  useEffect(() => {
    if (value !== undefined && value !== "") {   
      // const defaultDateString =value;
      const defaultDate = new Date(value);
      setDate(defaultDate);
    }
  }, [value])

  return (
    <>
    {DatepickerLabel &&
      <Form.Label className={className}>{DatepickerLabel}</Form.Label>
    }
      <div className="date-picker-container">
        <DatePicker
          readOnly={readOnly ? true : false}
          name={name}
          selected={date}
          onSelect={handleDateChange}
          dateFormat="dd-MM-yyyy"
          placeholderText="DD-MM-YYYY"
        // dateFormat="yyyy/MM/dd"
        // placeholderText="YYYY/MM/DD"
        />
        <BsCalendarDateFill className="calendar-icon" />
      </div>
      <small className="text-danger">{error}</small>
    </>
  );
};