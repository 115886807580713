import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BsCalendarDateFill } from "react-icons/bs";

export const NewMyDatePicker = ({ DatepickerLabel, value, onChange, name, error }) => {
  const [date, setDate] = useState();

  const handleDateChange = (date) => {
    setDate(date);
  }


  useEffect(() => {
    if (value !== undefined && value !== "") {
      // const defaultDateString =value;
      const defaultDate = new Date(value);
      setDate(defaultDate);
    }

  }, [value])

  return (
    <>
      <Form.Label>{DatepickerLabel}</Form.Label>
      <div className="date-picker-container">
        <DatePicker
          name={name}
          selected={date}
          onChange={(e) => { handleDateChange(e); onChange(e) }}
          dateFormat="yyyy/MM/dd"
          placeholderText="YYYY/MM/DD"
        />
        <BsCalendarDateFill className="calendar-icon" />
      </div>
      <small className="text-danger">{error}</small>
    </>
  );
};
