import React from "react";
import { Header } from "../../../../public/containers/header/Header";
import { Footer } from "../../../../public/containers/footer/Footer";
import { Form } from "react-bootstrap";
import { SharedButton } from "../../../../sharedComponents/Button";
import { InputField } from "../../../../sharedComponents/InputField";

export const ChangePassword = () => {
  return (
    <>
      <Header />
      <div className="middle-content-wrp">
      <div className="signwrapp-back-layer">
        <img
          src="assets/images/pictures/watermark_bg.png"
          alt="Watermark Background"
        />
      </div>
      <div className="container py-5 relative setminheight d-flex align-items-center">
        <div className="row justify-content-center flex-fill">
          <div className="col-md-6 col-lg-5 col-xl-4">
            <div className="middle-innerbox py-4 px-4">
              <h3 className="title text-center mt-4">Change Password</h3>
              <Form autoComplete="off">
                <InputField
                 
                  className={`inputField mb-2`}
                  type="password"
                  placeholder={`Enter Your Old Password`}
                  lable={"Old Password"}
                  required
                  />
                {/* <small className="text-danger">{invalidEmail}</small> */}
                <InputField
                  lable={"New Password"}
                  className={`inputField mb-3`}
                  type="password"
                  placeholder={`New Password`}
                  //   onChange={}
                  required
                  />
                <InputField
                  lable={"Confirm Password"}
                  text={"New Password"}
                  className={`inputField mb-3`}
                  type="password"
                  placeholder={`New Password`}
                  //   onChange={}
                  required
                />
                <SharedButton
                  title={"Update"}
                  className={"btn w-100 my-3"}
                  type={"submit"}
                  variant="primary"
                />
              </Form>
            </div>
          </div>
        </div>
      </div>
      </div>

      <Footer />
    </>
  );
};
