import React, { useEffect, useRef } from 'react';
import { Form } from 'react-bootstrap';

export const SharedSelect = ({ options, name, value, id, lable, onChange, SelectLabel, error, readOnly, className }) => {

  const selectRef = useRef();

  useEffect(() => {
    if (value !== undefined) {
      selectRef.current.value = value;
    }
  }, [value]);


  return (
    <>

      {lable ? <label htmlFor={id ? id : ''} className="form-label inputLable">{lable}</label> : ''}
      <select
        className={className ? className : "form-select"}
        ref={selectRef}
        name={name}
        onChange={onChange}
        disabled={readOnly ? true : false} aria-label="Default select example" >
        {value === undefined ? <option selected readOnly value="" >select</option> : ''}
        {options ? options.map((option, index) => (
          <option key={index} value={option.value ? option.value : ''}>
            {option.label ? option.label : ""}
          </option>
        )) : ''}
      </select>
      <small className='text-danger'>{error}</small>
    </>
  )
}
