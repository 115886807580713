import React from 'react'
import { InnerHeader } from '../../Components/inner-header/InnerHeader1'
import { Innerfooter } from '../../Components/inner-footer/Innerfooter1'
import { DashboardToSectionDetails } from '../../Components/CommanPages/DashboardToSectionDetails'
import { useLocation, useNavigate } from 'react-router-dom'
import { staff_add_client, staff_get_countries, staff_new_client_signup, staff_search_customer_list } from '../../../Static_Services/Staff_Services'
import { useState } from 'react'
import { useEffect } from 'react'
import { errorAlert, successAlert } from '../../../Static_Services/Alertmsg'
import { Modal } from 'react-bootstrap'
import { StaffCardCarousel } from '../../Components/CommanPages/StaffCardCarousel'

export const StaffClientSearch = () => {
  const business_id = localStorage.getItem('bzid');
  const location = useLocation();
  const [respData, setRespData] = useState([]);
  const navigate = useNavigate();
  const [serValue, setSerValue] = useState();
  const [modeChange, setModeChange] = useState(true);
  const [countryList, setCountryList] = useState();
  const [passModal, setPassModal] = useState(false);
  const [purchasedPassData, setPurchasedPassData] = useState([]);
  const [checkedPassId,setCheckedPassId] = useState();
  const [customerdata,setCustomerdata] = useState();

  const userDetail = JSON.parse(localStorage.getItem("userDetail"));

  const getCuontry = async () => {
    const resp = await staff_get_countries();
    if (resp) {
      setCountryList(resp.data.data);
    }
  }

  useEffect(() => {
    getCuontry();
  }, [])

  const getDetails = async (ser='') => {
   
    setModeChange(true);
    const fData = {
      "pageid": "1",
      "business_id": business_id,
      "class_id": location.state.class_id,
      "search_val": ser
    }
    const resp = await staff_search_customer_list(fData);
    if (resp) {
      setRespData(resp.data.data);
    } else setRespData([]);
  }

  const [error, setError] = useState({
    name: "",
    lastname: "",
    email: "",
    country_code: "",
    mobile: ""
  });

  const changeHandler = (e) => {
    const { name } = e.target;
    setError((pre) => ({
      ...pre,
      [name]: ""
    }));
  }

  const searchChange = (e) => {
    const value = e.target.value;
    setSerValue(value);
    getDetails(value);
  }
  const resetHandler = async () => {
    setSerValue('');
    const fData = {
      "pageid": "1",
      "business_id": business_id,
      "class_id": location.state.class_id,
      "search_val": ''
    }
    const resp = await staff_search_customer_list(fData);
    if (resp) {
      setRespData(resp.data.data);
    } else setRespData([]);
  }
  const submiteHandler = async (e) => {
    e.preventDefault();
    let isValid = 1;
    const fData = new FormData(e.target);
    const fValue = Object.fromEntries(fData.entries());
    const name = fValue.name;
    const lastname = fValue.lastname;
    const email = fValue.email;
    const country_code = fValue.country_code;
    const mobile = fValue.mobile;
    const refername = userDetail.name + ' ' + userDetail.lastname;


    // Regular expressions for email and phone number validation
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    const phoneRegex = /^\d{10}$/; // Adjust this regex for your specific phone number format

    if (!name) {
      setError((pre) => ({ ...pre, name: "First Name is required" }));
      isValid = 0;
    }
    if (!lastname) {
      setError((pre) => ({ ...pre, lastname: "Last Name is required" }));
      isValid = 0;
    }
    if (!email) {
      setError((pre) => ({ ...pre, email: "Email is required" }));
      isValid = 0;
    } else if (!emailRegex.test(email)) {
      setError((pre) => ({ ...pre, email: "Invalid email format" }));
      isValid = 0;
    }
    if (!country_code) {
      setError((pre) => ({ ...pre, country_code: "Country Code is required" }));
      isValid = 0;
    }
    if (!mobile) {
      setError((pre) => ({ ...pre, mobile: "Phone is required" }));
      isValid = 0;
    } else if (!phoneRegex.test(mobile)) {
      setError((pre) => ({ ...pre, mobile: "Invalid phone number format" }));
      isValid = 0;
    }

    if (isValid === 1) {
      var detail = {
        name: name,
        lastname: lastname,
        email: email,
        country_code: country_code,
        mobile: mobile,
        discount: '',
        referred_by: refername,
      };
      const resp = await staff_add_client(detail);
      if (resp) {
        e.target.reset();
        successAlert(resp.data.message);
      }
    }
  };


  const classSignup = (items) => {
    if (items.my_passes_details.length != 0) {
      const Customerdata = items;
      setCustomerdata(Customerdata);
      const PurchasePassData = items.my_passes_details;
      setPurchasedPassData(PurchasePassData);
      const firstData = items.my_passes_details;
      const PassID = firstData[0].pass_id;
      setCheckedPassId(PassID);
      setPassModal(true);
      // if(PurchasedPassData.length == 1){
      //   this.PassID = this.PurchasedPassData[0].pass_id;
      //   this.classSignupWithpassId(items);
      // } else{
      //  $('#passselect').trigger('click');
      // }
      // $('#btnpass').trigger ('click')  ;
      //this.makeActive();

    } else {
      errorAlert('Please purchase the pass');
      items.pass_buy = true;
      setRespData((pre) => {
        const prev = [...pre];
        return prev;
      });
    }
  }

  const getPassId = (event)=>{
  const PsID = event.target.id;
  setCheckedPassId(PsID);
  }

  const classSignupWithpassId = async (items) =>{
    const fData ={
      "user_id": items.id,
      "business_id": localStorage.getItem('bzid'),
      "class_id": location.state.class_id,
      "schedule_id": location.state.id,
      "instractor_id":  userDetail.id,
      "pass_id": checkedPassId
    }
   const resp = await staff_new_client_signup(fData);
   if(resp){
    successAlert(resp.data.message);
   }
  }

  return (
    <>
      <InnerHeader />
      <div className="body-grey">
        <section className="client-search-section">
          <div className="container py-5">
            <ul className="nav nav-tabs" id="myTab" role="tablist">

              <li className="nav-item active">
                <div className="formgroup searchbar">
                  <input type="text" className="form-control" value={serValue} onChange={(e) => searchChange(e)} />
                  <button onClick={() => getDetails(serValue)}><i className="fas fa-search"></i></button>
                </div>
              </li>
              <li className="nav-item px-3">
                {modeChange ?
                  <small className="nav-link" onClick={() => setModeChange(false)} >
                    New Client
                  </small>
                  :
                  <small className="nav-link" onClick={() => setModeChange(true)}>
                    Cancle
                  </small>
                }
              </li>
              <li className="nav-item">
                <button className="btn btn-blue-grad d-block ml-auto" onClick={() => window.history.back()}>Back</button>
              </li>
            </ul>
            <div className="tab-content bg-white border-radius6 p-3 mt-4" id="myTabContent">
              {modeChange &&
                <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                  <div className="row">
                    <div className="col-11"></div>
                    <div className="col-1">
                      <button className="btn btn-green" onClick={resetHandler}>Reset</button>
                      <button type="button" id="passselect" data-backdrop="static" data-keyboard="false"
                        data-toggle="modal" data-target="#PassSelectModal" style={{ display: "none" }} ></button>
                    </div>
                  </div>

                  {respData.length != 0 &&
                    <div class="row">
                      {respData.map((items) => (
                        <div class="col-lg-4 py-3 ">
                          <div class="activity-card box-shadow1 border-radius6 white-bg p-2">
                            <div class="client-slot-box d-flex " >
                              <div class="client-img">
                                <img src={items.profile_img} class="img-fluid" />
                              </div>
                              <div class="row client-detail pl-3">
                                <div class="col-12">
                                  <p class="black-color font-14 font-w-600 mb-0 d-flex">
                                    <span class="nametitle d-block">{items.name} {items.lastname}</span> </p>
                                  <span class="brand-color3 font-w-500 mt-2 d-block">{items.email}</span>
                                  <span class="brand-color3 font-w-500 mt-2 d-block">{items.country_code} {items.mobile}</span>
                                  {!items.pass_buy && <button onClick={() => classSignup(items)} class="btn btn-blue mt-2">Sign Up</button>}
                                  {items.pass_buy && <button onClick={() => { navigate('/customer-detail', { state: { client_id: items.id, stateData: location.state } }); }} class="btn btn-blue mt-2">Purchase</button>}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  }
                  {respData.length === 0 &&
                    <div class="row">
                      <div class="col-5"></div>
                      <div class="col-2">
                        <p class="font-awesome black-color font-18 d-inline mx-2"> No Client Available</p>
                      </div>
                      <div class="col-5"></div>
                    </div>
                  }
                </div >
              }
              {!modeChange &&
                <div className="add-newclient">
                  <form onSubmit={submiteHandler} autoComplete='off'>
                    <div className="row">
                      <div className="col-md-6 form-group py-3">
                        <label>First Name</label>
                        <input type="text" className="form-control-1 w-100" placeholder="Carl" name="name" onChange={changeHandler} />
                        <small className='text-danger'>{error.name}</small>
                      </div>
                      <div className="col-md-6 form-group py-3">
                        <label>Last Name</label>
                        <input type="text" className="form-control-1 w-100" placeholder="Oilver" name="lastname" onChange={changeHandler} />
                        <small className='text-danger'>{error.lastname}</small>
                      </div>
                      <div className="col-md-6 form-group py-3">
                        <label>Email</label>
                        <input type="email" className="form-control-1 w-100" placeholder="" name="email" onChange={changeHandler} />
                        <small className='text-danger'>{error.lastname}</small>
                      </div>
                      <div className="col-md-6 form-group py-3">
                        <label>Country Code</label>
                        <select className="form-control-1 w-100" name="country_code" onChange={changeHandler} >
                          {countryList && countryList.map((code, i) => (
                            <option key={i} selected={i === 0} value={`${code.code}`}>{`${code.name} (${code.code})`} </option>
                          ))}
                        </select>
                        <small className='text-danger'>{error.country_code}</small>
                      </div>
                      <div className="col-md-6 form-group py-3">
                        <label>Phone</label>
                        <input type="number" className="form-control-1 w-100 " placeholder="" name="mobile" onChange={changeHandler} />
                        <small className='text-danger'>{error.mobile}</small>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button className="btn btn-green-grad text-uppercase d-block " type="submit">Add Client</button></div>
                  </form>
                </div>
              }
            </div >
          </div >
        </section >

      </div >
      <Innerfooter />


      {/* <!-- Pass Select modal popup --> */}
      <Modal show={passModal} className="modal fade statusUpdate-modal modalwidth">
        <div class="modal-dialog modal-dialog-centered" id="ownModalWidth" role="document">
          <div class="modal-content">
            <div class="modal-body border-radius6 box-shadow1 p-5 white-bg">
              <div class="home-inner-available">
                <div class="mb-2 pb-1">
                  <p class="black-color font-w-100 text-uppercase font-20"> Purchased passes </p>
                </div>
                <div id="puchasePassmodal" class="carousel slide" data-ride="carousel">
              
                 <StaffCardCarousel pass={purchasedPassData} checkedPassId={checkedPassId} getPassId={getPassId} />
                </div>
       
                <div class="form-group px-3 pt-0 pt-sm-4 mb-0 mb-sm-2 d-flex buttonWidth justify-content-center">
                  <button type="button" data-dismiss="modal" id="btn_close11"
                    class="btn btn-green-grad text-uppercase mt-3 mr-3" data-backdrop="static" data-keyboard="false"
                  onClick={()=>classSignupWithpassId(customerdata)}
                  > Submit </button>
                  <button type="button" data-dismiss="modal" id="btn_close1" class="btn btn-blue-grad text-uppercase mt-3"
                    data-backdrop="static" data-keyboard="false"
                    onClick = {()=>setPassModal(false)}
                  > close </button >
                  <button type="button" id="btnpass" class="btn btn-blue-grad text-uppercase mt-3 d-none"
                  onClick = {()=>setPassModal(false)}
                  > close </button >
                </div >
              </div >
              {/* < !--end of home - inner - class -- > */}
            </div >
          </div >
        </div >
      </Modal >

      {/* <!-- END OF Pass Select modal popup --> */}
    </>
  )
}
