import React from 'react'
import { InnerHeader } from '../../Components/inner-header/InnerHeader1'
import { Innerfooter } from '../../Components/inner-footer/Innerfooter'
import { DashboardToSectionDetails } from '../../Components/CommanPages/DashboardToSectionDetails'
import { Spinner } from 'react-bootstrap'
import { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { API_class_list, API_workshop_list, toTimestamp } from '../../../Static_Services/Staff_Services'
import moment from 'moment'
import { InputField } from '../../../sharedComponents/InputField'

export const ClientClasses = () => {
    const location = useLocation();
    const [currentUrl, setCurrentUrl] = useState();
    const [loder, setLoder] = useState(false);
    const [currentStatus, setCurrentStatus] = useState(0);
    const [pageCount, setPageCount] = useState(1);
    const [minValue, setMinValue] = useState();
    const [classDetail, setClassDetail] = useState([]);
    const [dataCount, setDataCount] = useState();
    const [businessDetail, setBusinessDetail] = useState([])
    // const [currentDate, setCurrentDate] = useState(moment().unix());
    const [currentVal, setCurrentVal] = useState();
    const navigate = useNavigate();
    const [selectedDate, setSelectedDate] = useState();  //moment().format('YYYY-MM-DD')


    useEffect(() => {
        if (localStorage.getItem('w_BusinessDetail')) {
            setBusinessDetail(JSON.parse(localStorage.getItem('w_BusinessDetail')));
        }
        localStorage.removeItem('w_ClassId');
        if (location.pathname === '/my-classes') {
            setCurrentUrl('/classes');
        } else {
            setCurrentUrl(location.pathname);
        }
    }, [location.pathname])

    const getTodayClassDetail = async (datemy) => {
        setLoder(true);
        let detail = '';
        let send_date = '';
        let pageCount = 1;
        // setCurrentStatus(1);
        const ddd = moment(datemy).format('YYYY-MM-DD');
        setSelectedDate(ddd);
        if (currentUrl == "/workshops") {
            let new_date = moment(datemy, 'YYYY-MM-DD').unix();
            detail = { "pageid": 1, "business_id": businessDetail.business_id, "upcoming_date": new_date, "workshop_status": currentStatus };
            const resp = await API_workshop_list(detail);
            if (resp) {
                const response = resp.data;
                if (response.data.length != 0) {
                    response.data.map((el) => {
                        el.start_date_utc_new = moment.unix(el.schedule_date).format('MMM DD YYYY');
                        el.from_time_utc_new = moment.unix(el.start).format('hh mm A');
                        el.end_time_utc_new = moment.unix(el.end).format('hh mm A');
                    })
                }
                if (pageCount == 1) {
                    setClassDetail(response.data);
                } else {
                    setClassDetail((classDetail) => (classDetail.concat(response.data)))
                }
                setLoder(false);
            } else {
                setDataCount(0);
                if (pageCount == 1) {
                    setClassDetail([]);
                }
                setLoder(false);
            }
        } else {
            let new_date = moment(datemy, 'YYYY-MM-DD').unix();
            detail = { "pageid": 1, "business_id": businessDetail.business_id, "upcoming_date": new_date, "class_status": currentStatus, 'all': 1 };
            const resp = await API_class_list(detail);
            if (resp) {
                const response = resp.data;
                if (response.data.length != 0) {
                    response.data.map((el) => {
                        // Date Conversion UTC
                        el.start_date_utc_new = moment.unix(el.start_date_utc).format('MMM DD YYYY');
                        el.end_date_utc_new = moment.unix(el.end_date_utc).format('MMM DD YYYY');

                        //time conversion
                        el.from_time_utc_new = moment.unix(el.from_time_utc).format('hh mm A');
                    })
                }
                if (pageCount == 1) {
                    setClassDetail(response.data);
                } else {
                    setClassDetail((classDetail) => (classDetail.concat(response.data)));
                }
                setLoder(false);
            } else {
                setDataCount(0);
                if (pageCount == 1) {
                    setClassDetail([])
                }
                setLoder(false)
            }
        }
    }

    const getClassDetail = async (page, statusVal) => {
        setLoder(true);
        let detail = '';
        let new_date = '';
        let send_date = '';
        let pageCount = 1;
        // if (currentStatus != statusVal) {
        //     pageCount = page
        // }
        setCurrentStatus(statusVal);

        if (currentUrl == "/workshops") {
            if (!statusVal) { statusVal = 0; }
            if (selectedDate) { new_date = moment(selectedDate, 'YYYY-MM-DD').unix(); }
            //  else {
            //     new_date = moment().unix();
            // }
            if (statusVal === 0 && !selectedDate) { new_date = ''; }

            if (statusVal != 0 && !selectedDate) {
                const datesss = moment().format('YYYY-MM-DD');
                new_date = moment(datesss, 'YYYY-MM-DD').unix();
            }
            detail = { "pageid": 1, "business_id": businessDetail.business_id, "upcoming_date": new_date, "workshop_status": statusVal };
            const resp = await API_workshop_list(detail);
            if (resp) {
                const response = resp.data;
                if (response.data.length != 0) {
                    response.data.map((el) => {
                        el.start_date_utc_new = moment.unix(el.schedule_date).format('MMM DD YYYY');
                        el.from_time_utc_new = moment.unix(el.start).format('hh mm A');
                        el.end_time_utc_new = moment.unix(el.end).format('hh mm A');
                    })
                }
                if (pageCount == 1) {
                    setClassDetail(response.data);
                } else {
                    setClassDetail((classDetail) => (classDetail.concat(response.data)))
                }
                setLoder(false);
            } else {
                setDataCount(0);
                if (pageCount == 1) {
                    setClassDetail([]);
                }
                setLoder(false);
            }
        } else {
            let alls = 1;
            if (selectedDate) {
                new_date = moment(selectedDate, 'YYYY-MM-DD').unix();
            } else {
                const datesssss = moment().format('YYYY-MM-DD');
                new_date = moment(datesssss, 'YYYY-MM-DD').unix();
            }
            if (selectedDate != '' && selectedDate == new_date) {
                alls = 0;
            }
            detail = { "pageid": 1, "business_id": businessDetail.business_id, "upcoming_date": new_date, "class_status": statusVal, 'all': alls };
            const resp = await API_class_list(detail);
            if (resp) {
                const response = resp.data;
                if (response.data.length != 0) {
                    response.data.map((el) => {
                        // Date Conversion UTC
                        el.start_date_utc_new = moment.unix(el.start_date_utc).format('MMM DD YYYY');
                        el.end_date_utc_new = moment.unix(el.end_date_utc).format('MMM DD YYYY');

                        //time conversion
                        el.from_time_utc_new = moment.unix(el.from_time_utc).format('hh mm A');
                    })
                }
                if (pageCount == 1) {
                    setClassDetail(response.data);
                } else {
                    setClassDetail((classDetail) => (classDetail.concat(response.data)));
                }
                setLoder(false);
            } else {
                setDataCount(0);
                if (pageCount == 1) {
                    setClassDetail([])
                }
                setLoder(false)
            }
        }
    }

    useEffect(() => {
        if (businessDetail != '') {
            getClassDetail(pageCount, 0);
        }
    }, [businessDetail])

    // useEffect(() => {
    //     if(selectedDate !=''){
    //         getClassDetail(1,currentStatus);
    //     }
    // }, [selectedDate])


    const upcoming = () => {
        setCurrentVal(true);
        getClassDetail(1, currentStatus);
    }

    const reset = () => {
        setCurrentVal(false);
        setSelectedDate(moment().format('YYYY-MM-DD'));
        getClassDetail(1, 0);
    }
    const redirectUrl = (url, Id, scheId, date) => {

        if (currentUrl == "/workshops") {
            navigate(url, { state: { 'workshop_id': Id, 'currentdate': date, 'schedule_id': scheId, 'backurl': currentUrl } });
        } else {
            const detail = { 'id': Id, 'currentdate': date, 'schedule_id': scheId, 'backurl': currentUrl };

            navigate(url, { state: { Id: detail } });
        }

    }

    const getval = (event) => {
        setSelectedDate();
        setSelectedDate('');
        getClassDetail(1, event.target.value);
    }

    const getDayName = (date) => {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const dayIndex = new Date(date).getDay();
        return days[dayIndex];
    }
    return (
        <>
            {loder ? <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div> : ""}
            <InnerHeader />

            <div className="body-grey">
                <div class="studio-details">
                    <div class="container">
                        <DashboardToSectionDetails />
                        <div class="text-right mb-3">
                            <button type="button" class="btn btn-blue-grad px-2 text-uppercase" onClick={() => window.history.back()}>Back </button>
                        </div>
                        <div class="studio-details-lower pb-5">
                            <div class="row">
                                <div class="col-12">
                                    <div class="white-bg border-radius6 box-shadow1 p-3">
                                        <div class="row">
                                            <div class="col-12 col-md-6">
                                                {!currentVal &&
                                                    <div class="pt-3" >
                                                        {currentUrl == '/workshops' &&
                                                            <button type="button" class="btn btn-blue-grad px-4 w-50 text-uppercase mb-0 mb-md-5" onClick={() => setSelectedDate(moment().format('YYYY-MM-DD'))} >Today's Workshops </button>}
                                                        {currentUrl == '/classes' &&
                                                            <button type="button" class="btn btn-blue-grad px-4 w-50 text-uppercase mb-0 mb-md-5" onClick={() => getTodayClassDetail(moment().format('YYYY-MM-DD'))}>Today's Classes </button>}
                                                    </div>
                                                }
                                                {currentVal &&
                                                    <div class="pt-3" >
                                                        <button type="button" class="btn btn-blue-grad px-4 text-uppercase mb-0 mb-md-5" onClick={() => reset()}
                                                        >Reset </button>
                                                    </div>
                                                }
                                            </div>
                                            {/* <!-- end of col-md-4 --> */}
                                            <div class="col-12 col-md-6 text-right">
                                                <div class="centered-cover w-100">
                                                    <div class="flex-break">
                                                        <div class="std-detail-select position-relative w-100">
                                                            {/* {currentDate &&
                                                                <button class="colorchnage mr-2" onClick={() => "decreaseDate()"} ><i class="fas fa-chevron-left"></i></button>}
                                                            {!currentDate &&
                                                                <button class="colorchnage mr-2" ><i
                                                                    class="fas fa-chevron-left"></i></button>} */}
                                                            <InputField
                                                                type="date"
                                                                onChange={(e) => { getTodayClassDetail(e.target.value) }}
                                                                minLength={(moment().format('YYYY-MM-DD'))}
                                                                value={selectedDate}
                                                                required
                                                            />
                                                            {/*                                                         
                                                            {currentDate && <button class="colorchnage ml-2" onClick={() => "increaseDate()"} ><i class="fas fa-chevron-right"></i></button>}
                                                            {!currentDate && <button class="colorchnage ml-2" ><i class="fas fa-chevron-right"></i></button>} */}

                                                        </div>
                                                    </div>
                                                    {/* <!-- end of flex-break --> */}
                                                </div>
                                                {/* <!-- end of centered-cover --> */}
                                            </div>
                                        </div>
                                        {!currentVal &&
                                            <div class="row">
                                                <div class="col-12 col-md-4">
                                                    <div class="centered-cover w-100">
                                                        <div class="flex-break">
                                                            <div class="std-detail-select position-relative w-100">

                                                                <select class="form form-control brand-color1 border-radius6" onChange={(e) => getval(e)}>
                                                                    {currentUrl == '/classes' && <option value="0"> All Classes </option>}
                                                                    {currentUrl == '/classes' && <option value="1"> My Classes </option>}
                                                                    {currentUrl == '/workshops' && <option value="0"> All Workshops </option>}
                                                                    {currentUrl == '/workshops' && <option value="1"> My Workshops </option>}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        {/* !-- end of flex-break --> */}
                                                    </div>
                                                    {/* <!-- end of centered-cover --> */}
                                                </div>
                                                {/* <!-- end of col-md-4 --> */}
                                            </div>
                                        }
                                        {/* <!-- end of row --> */}

                                        <div class="box all-class">
                                            {!classDetail.length != 0}
                                            <div class="activity-card-section mt-4 pt-2"  >
                                                <div infinite-scroll
                                                    //   [infiniteScrollDistance]="scrollDistance"
                                                    // [infiniteScrollUpDistance]="scrollUpDistance" [infiniteScrollThrottle]="throttle"
                                                    // (scrolled)="onScroll()" 
                                                    alwaysCallback="true">

                                                    <div class="row">
                                                        {classDetail.map((items) => (
                                                            <div class="col-12 col-md-4 mb-4">
                                                                {currentUrl == '/classes' &&
                                                                    <div class="activity-card box-shadow1 border-radius6 white-bg p-2" style={{cursor:'pointer'}} onClick={() => redirectUrl('/classes-detail', items.class_id, items.schedule_id, items.start_date_utc)}>
                                                                        <div class="row p-2">
                                                                            <div class="col-2 col-sm-2 col-md-2 col-lg-2">
                                                                                <a href="Javascript:void(0);"
                                                                                    onClick={() => redirectUrl('/classes-detail', items.class_id, items.schedule_id, items.start_date_utc)}
                                                                                >
                                                                                    <div class="activity-card-logo">
                                                                                        <img src={businessDetail.logo} class="box-shadow1 new-log-img" />
                                                                                    </div>
                                                                                </a>
                                                                            </div>

                                                                            {/* <!-- end of col-3 --> */}
                                                                            <div className='col-10 col-sm-10 col-md-10 col-lg-10 p-3' style={{ border: '2px solid', borderRadius: '10px' }}>
                                                                                {/* <div class="pl-0 pl-sm-0 pl-md-3 pl-lg-0" > */}
                                                                                {/* <div> */}

                                                                                <a href="Javascript:void(0);"
                                                                                    onClick={() => redirectUrl('/classes-detail', items.class_id, items.schedule_id, items.start_date_utc)} >
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-7 col-md-12 col-lg-7 mt-0 mt-md-4 mt-lg-0 pl-0 pl-sm-0 pl-md-3 pl-lg-0">
                                                                                            <div class="activity-card-details text-left">
                                                                                                <h5 class="classname-title black-color mb-0">{items.class_name} </h5>
                                                                                                {items.instructor_details[0] &&
                                                                                                    <ul class="d-flex align-items-center py-2">
                                                                                                        <li>
                                                                                                            <div class="subinst-profileimg">
                                                                                                                <img src={items.instructor_details[0].profile_img} class="img-fluid" />
                                                                                                            </div>
                                                                                                        </li>
                                                                                                        <li>
                                                                                                            <h5 class="black-color mb-0 classname-title pl-2">
                                                                                                                {items.instructor_details[0].name}
                                                                                                                {items.instructor_details[0].lastname} </h5>
                                                                                                        </li>
                                                                                                    </ul>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                        {/* <!-- end of col-7 --> */}
                                                                                        <div class="col-5 col-md-12 col-lg-5 pr-1">
                                                                                            <div class="text-right pt-1">
                                                                                                <p class="black-color font-w-100  mr-1 mb-1 paratitle"> {getDayName(items.start_date_utc_new)}</p>
                                                                                                <p class="black-color font-w-100  mr-1 mb-1 paratitle">
                                                                                                    {items.start_date_utc_new}
                                                                                                </p>
                                                                                                {/* <div class="card-green-box text-center">
                                                                                                        <p class="white-color mb-0 paratitle"> {items.capacity_used} /
                                                                                                            {items.total_capacity}</p>
                                                                                                    </div> */}
                                                                                            </div>
                                                                                        </div>
                                                                                        {/* <!-- end of col-5 --> */}
                                                                                    </div>
                                                                                    {/* <!-- end of row --> */}
                                                                                </a>

                                                                                <div class="row pt-3">
                                                                                    <p class="black-color font-w-100 paratitle mb-0 ps-0">For Online Classes </p>
                                                                                    <h6 className='title black-color mb-0 ps-0' >Link Provided after Checking in</h6>
                                                                                    {/* {items.location_url &&
                                                                                        <h5 class="heading-titlenew ml-2 mb-0 black-color">
                                                                                            <Link to={items.location_url} target="_blank">{items.location}</Link></h5>
                                                                                    }
                                                                                    {!items.location_url &&
                                                                                        <h5 class="heading-titlenew ml-2 mb-0 black-color" > {items.location}</h5>
                                                                                    } */}
                                                                                </div>
                                                                                <a href="Javascript:void(0);"
                                                                                    onClick={() => redirectUrl('/classes-detail', items.class_id, items.schedule_id, items.start_date_utc)} >

                                                                                    <div class="row pt-2">
                                                                                        <div class="col-6 col-sm-6 col-md-6 col-lg-6 px-0 border-right">
                                                                                            <div class="text-left">
                                                                                                <p class="paratitle black-color mb-0"> Start Time </p>
                                                                                                <h5 class="classname-title black-color mb-0"> {items.from_time_utc_new}</h5>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-6 col-sm-6 col-md-6 col-lg-6 d-flex justify-content-end">
                                                                                            <div class="text-left">
                                                                                                <p class="paratitle black-color mb-0"> Duration </p>
                                                                                                <h5 class="classname-title black-color mb-0"> {items.duration}</h5>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                                {/* </div> */}
                                                                                {/* <!-- end of row --> */}
                                                                            </div>
                                                                            {/* <!-- end of col-9 --> */}
                                                                        </div>
                                                                        {/* <!-- end of row --> */}
                                                                    </div>}
                                                                {currentUrl == '/workshops' &&
                                                                    <div class="activity-card box-shadow1 border-radius6 white-bg p-2" >
                                                                        <div class="row">
                                                                            <div class="col-3 col-sm-3 col-md-12 col-lg-3">
                                                                                {/* New Change 19-12-2023 */}
                                                                                <a href="Javascript:void(0);"
                                                                                    onClick={() => redirectUrl('/workshop-detail', items.workshop_id, items.id, items.start_date_utc)}>
                                                                                    <div class="activity-card-logo">
                                                                                        <img src={businessDetail.logo} class="box-shadow1 new-log-img" />
                                                                                    </div>
                                                                                </a>
                                                                            </div>
                                                                            {/* <!-- end of col-3 --> */}

                                                                            <div class="col-9 col-sm-9 col-md-12 col-lg-9 pl-0 pl-sm-0 pl-md-3 pl-lg-0">
                                                                                {/* New Change 19-12-2023 */}
                                                                                <a href="Javascript:void(0);"
                                                                                    onClick={() => redirectUrl('/workshop-detail', items.workshop_id, items.id, items.start_date_utc)}>
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-7 col-md-12 col-lg-7 mt-0 mt-md-4 mt-lg-0 pl-0 pl-sm-0 pl-md-3 pl-lg-0">
                                                                                            <div class="activity-card-details text-left">
                                                                                                <h5 class="classname-title black-color mb-0">{items.workshop_name} </h5>
                                                                                                <ul class="d-flex align-items-center py-2">
                                                                                                    <li>
                                                                                                        <h5>Amount : {items.workshop_price} </h5>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                        {/* <!-- end of col-7 --> */}
                                                                                        <div class="col-5 col-md-12 col-lg-5 pr-1">
                                                                                            <div class="text-right pt-1">
                                                                                                <p class="black-color font-w-100 font-14 mr-1 mb-1 paratitle">
                                                                                                    {items.start_date_utc_new} </p>
                                                                                                <div class="card-green-box text-center">
                                                                                                    <p class="white-color mb-0 paratitle"> {items.capacity_used} /
                                                                                                        {items.total_capacity}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {/* <!-- end of col-5 --> */}
                                                                                    </div>
                                                                                    {/* <!-- end of row --> */}
                                                                                </a>
                                                                                <div class="row pt-3">
                                                                                    <p class="black-color font-w-100 paratitle mb-0">Location :
                                                                                    </p>
                                                                                    {items.location &&
                                                                                        <h5 class="heading-titlenew ml-2 mb-0 black-color">
                                                                                            {!items.location_url && <Link >{items.location}</Link>}
                                                                                            {items.location_url && <Link to={items.location_url} target="_blank">{items.location}</Link>}
                                                                                        </h5>
                                                                                    }
                                                                                    {items.address &&
                                                                                        <h5 class="heading-titlenew ml-2 mb-0 black-color">
                                                                                            <Link to={items.address} target="blank">Other</Link></h5>}
                                                                                </div>
                                                                                <a href="Javascript:void(0);"
                                                                                    onClick={() => redirectUrl('/workshop-detail', items.workshop_id, items.id, items.start_date_utc)}>
                                                                                    <div class="row pt-2">
                                                                                        <div class="col-6 col-sm-6 col-md-6 col-lg-6 px-0 border-right">
                                                                                            <div class="text-left">
                                                                                                <p class="paratitle black-color mb-0"> Start Time </p>
                                                                                                <h5 class="classname-title black-color mb-0"> {items.from_time_utc_new}</h5>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-6 col-sm-6 col-md-6 col-lg-6 d-flex justify-content-end">
                                                                                            <div class="text-left">
                                                                                                <p class="paratitle black-color mb-0"> End Time </p>
                                                                                                <h5 class="classname-title black-color mb-0"> {items.end_time_utc_new} </h5>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                                {/* <!-- end of row --> */}
                                                                            </div>
                                                                            {/* <!-- end of col-9 --> */}
                                                                        </div>
                                                                        {/* <!-- end of row --> */}
                                                                    </div>
                                                                }
                                                                {/* <!-- end of activity-card --> */}
                                                            </div>
                                                        ))}
                                                        {/* <!-- end of col-4 --> */}

                                                    </div>
                                                    {/* <!-- end of row --> */}
                                                </div>
                                            </div>
                                            {/* <!-- end of activity-card-section --> */}
                                            {classDetail.length == 0 &&
                                                <div class="activity-card-section mt-4 pt-2">
                                                    <div class="text-center">
                                                        {currentUrl == '/workshops' &&
                                                            <p class="font-awesome black-color font-18 d-inline mx-2 "> No Workshops Are scheduled</p>
                                                        }
                                                        {currentUrl == '/classes' &&
                                                            <p class="font-awesome black-color font-18 d-inline mx-2 "> No Classes Are scheduled</p>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        {/* <!-- end of my-classes --> */}
                                    </div>
                                    {/* <!-- end of studio-details-lower --> */}
                                </div>
                                {/* <!-- end of container --> */}
                            </div>
                            {/* <!-- End of My Studio Card Detials Section --> */}

                        </div>
                    </div>
                </div>
            </div>

            <Innerfooter />


        </>
    )
}
