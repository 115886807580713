import React from "react";
import {  Form, FormControl, Placeholder } from 'react-bootstrap';

export const LiveSearch = ({ value, onChange, onClick, type, Placeholder, className }) => {


  return (
    <>
      <Form className="Search_form">
        <FormControl
          type={type}
          placeholder={Placeholder}
          className="live_Search"
          value={value}
          onChange={onChange}
        />
      </Form>
    </>
  );
};
