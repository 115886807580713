import React, { useState } from "react";

import Select from "react-select";
import { error } from "toastr";

// const options = [
  // { value: "1", label: "One" },
  // { value: "2", label: "Two" },
  // { value: "3", label: "Three" },
// ];


const MultiSelect = ({ Label='',options=[],setSelectedOptions ='',selectedOptions='', error=''}) => {
  // const [selectedOptions, setSelectedOptions] = useState([]);

  const handleOptionChange = (selected) => {
    setSelectedOptions(selected);
  };

  const handleSelectAllChange = () => {
    if (selectedOptions.length === options.length) {
      setSelectedOptions([]);
    } else {
      setSelectedOptions(options);
    }
  };

  return (
    <div>
      <label htmlFor="" className="form-label inputLable">
        {Label}
      </label>
      <Select
        options={[{ value: "select-all", label: "Select All" }, ...options]}
        isMulti
        closeMenuOnSelect={false}
        onChange={(selected) => handleOptionChange(selected.map(option => option.value))}
        value={selectedOptions}
        components={{
          Option: (props) => (
            <div className="multiselect">
              {props.data.value === "select-all" ? (
                <input
                  type="checkbox"
                  checked={selectedOptions.length === options.length}
                  onChange={handleSelectAllChange}
                />
              ) : (
                <input
                  className="MultiCheckbox"
                  type="checkbox"
                  checked={selectedOptions.some(
                    (option) => option.value === props.data.value
                  )}
                  onChange={() => {
                    if (
                      selectedOptions.some(
                        (option) => option.value === props.data.value
                      )
                    ) {
                      setSelectedOptions(
                        selectedOptions.filter(
                          (option) => option.value !== props.data.value
                        )
                      );
                    } else {
                      setSelectedOptions([...selectedOptions, props.data]);
                    }
                  }}
                />
              )}
              {props.data.label}
            </div>
          ),
        }}
        placeholder="Select options"
        onInputChange={(inputValue) => {
          if (inputValue.toLowerCase() === "select all") {
            handleSelectAllChange();
          }
        }}
      />
      <small className="text-danger">{error}</small>
    </div>
  );
};

export default MultiSelect;
