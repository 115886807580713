import React from 'react';
import { Col, Row } from 'react-bootstrap';

export default function NewPagination({ data, changePage }) {
    // Determine the current page, if available
    const currentPage = data.current_page || 1;

    // Create pagination items
    const pages = [];
    for (let i = 1; i <= data.total_page; i++) {
        if(i >= currentPage && i <= currentPage+6 ){
        pages.push(
            <li key={i} className={`page-item ${i === currentPage ? 'active' : ''}`}>
                <button className="page-link" onClick={() => changePage(i)}>
                    {i}
                </button>
            </li>
        );
        }
    }

    return (
        <Row>
            <nav aria-label="Pagination" className='d-flex' style={{ justifyContent: "end" }}>
                <ul className="pagination box-shadow1">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => changePage(currentPage - 1)} aria-label="Previous">
                            <span aria-hidden="true">Previous</span>
                        </button>
                    </li>
                        {pages}
                    <li className={`page-item ${currentPage === data.total_page ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => changePage(currentPage + 1)} aria-label="Next">
                            <span aria-hidden="true">Next</span>
                        </button>
                    </li>

                </ul>
            </nav>
        </Row>

    );
}
