import React from "react";
import Sidebar from "../comman_page/Sidebar";
import { HeaderDashboard } from "../comman_page/HeaderDashboard";
import { Footer } from "../../../../public/containers/footer/Footer";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

export const AddThings = () => {
  return (
    <>
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
          <Sidebar />
            <Col>
              <div className="RightSide">
                <HeaderDashboard />

                <section className="innerContent">
                <div id="mainpageWrapper" className="page-content-wrapper">
                  {/* <!-- =========== CONTENT ============ --> */}

                  <div className="page-content-inner bg-brand-dark py-3 px-5 relative">
                    {/* <!-- image layer --> */}
                    <div className="wrapp-back-layer">
                      <img src="assets/images/pictures/watermark_bg.png" />
                    </div>
                    {/* <!-- img layer --> */}

                    {/* <!-- step fill --> */}
                    <div className="stepsinfo-fill">
                      <ul className="steps">
                        <li className="completed-step"></li>
                        <li className="completed-step"></li>
                        <li className="completed-step"></li>
                      </ul>
                    </div>
                    <section className="contentSections pl-5">
                      <div className="businessinner-sec bg-white border-radius10 py-5 relative ">
                        <div className="container">
                          <h3 className="text-uppercase text-center title">
                            Time to set up your business details
                          </h3>
                          <p className="brand-light text-center">
                            Welcome to Studio Details. Click on a section to
                            add/update your company details, <br /> set up
                            scheduled activities such as classes and workshops,
                            add or manage staff,
                            <br /> clients, shifts, products, payments and more.
                          </p>
                          <div className="skip-head relative py-4">
                            <h3 className="title text-uppercase text-center">
                              Step 3 of 4: Add things to sell
                            </h3>
                            <Link
                              to="/Home"
                              className="btn btn-brandwhite box-shadow-greenlight text-uppercase"
                            >
                              Leave Set Up
                            </Link>
                          </div>
                          <div className="row justify-content-center py-2">
                            <div className="col-md-6 col-lg-4">
                              <Link to="/list-pass">
                                <div className="business-box p-3">
                                  <h5 className="title text-center">Passes</h5>
                                  <p className="text-center">
                                    Every class or workshop requires a pass to
                                    reserve a space. Set up your list of passes
                                    and as you put a new class or workshop on
                                    the calendar you can pick which passes will
                                    be available for the customer to use for
                                    signing up.
                                  </p>
                                </div>
                              </Link>
                            </div>
                            <div className="col-md-6 col-lg-4 mb-3">
                              <Link to="/list-services">
                                <div className="business-box p-3">
                                  <h5 className="title text-center">
                                    Services
                                  </h5>
                                  <p className="text-center">
                                    Create a set of services you will offer your
                                    customers. These are tied to appointments
                                    that a customer can make with a
                                    staff/instructor, set your price and
                                    duration and make them available or hidden
                                    when you want.
                                  </p>
                                </div>
                              </Link>
                            </div>
                            <div className="col-md-6 col-lg-4">
                              <Link to="/list-products">
                                <div className="business-box p-3">
                                  <h5 className="title text-center">
                                    Products
                                  </h5>
                                  <p className="text-center">
                                    Set up each product here. You can make them
                                    active or inactive, set price, a description
                                    etc so that customers can purchase them at
                                    your front desk or right out of there mobile
                                    app.
                                  </p>
                                </div>
                              </Link>
                            </div>
                            <div className="col-md-6 col-lg-4"></div>
                            <div className="col-md-6 col-lg-4">
                              <Link to="/add-schedule-class">
                                <div className="business-box p-3">
                                  <h5 className="title text-center">
                                    Schedule Classes
                                  </h5>
                                  <p className="text-center">
                                    Now that you have your studio's information
                                    set up, you can put classes on the calendar.
                                  </p>
                                </div>
                              </Link>
                            </div>
                            <div className="col-md-6 col-lg-4"></div>
                          </div>
                          <Link
                            to="/additional-services"
                            className="btn btn-brandbluegrad mx-auto mt-4"
                          >
                            Next
                          </Link>
                        </div>
                      </div>
                    </section>
                    {/* <!-- CONTENT SECTION END --> */}
                  </div>
                  <Footer />
                </div>
                </section>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
