import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap'
import { SharedButton } from './Button';
import { useNavigate } from 'react-router-dom';
import { SharedSelect } from './SharedSelect';

export const ClinetSelect = ({ data, onClick, stateData, searchedKeyword, classID }) => {
    const [option, setOption] = useState('1');
    const [valid_class_ids, setValid_class_ids] = useState([]);
    const [preData, setPreData] = useState([]);
    const navigate = useNavigate();
    const [familyID, setFamiltID] = useState();

    useEffect(() => {
        setPreData(data);
    }, [data]);


    const setPassPurchaseCondition = (value, clield) => {
        localStorage.setItem('st_isPassPurchaseFlow', JSON.stringify('true'));
        localStorage.setItem('st_searchedClients', JSON.stringify(searchedKeyword));
        let finalData = { data: value, class_id: classID, schedule_id: localStorage.getItem("sch_id"), serchKey: searchedKeyword }
        localStorage.setItem('allsetData', JSON.stringify(finalData));
        const memId = clield && clield.memeber_id ? clield.memeber_id : '';
        navigate('/purchase-product', { state: { family_member_id: memId, data: value, class_id: classID, schedule_id: localStorage.getItem("sch_id") } });
    }

    //   const options = [
    //     { value: "1", label: "Classes" },
    //     { value: "2", label: "Workshop" },
    //     { value: "3", label: "Appointment" },
    //   ];

    useEffect(() => {
        if (preData && preData.my_passes_details) {
            let valid_class_ids = (preData.my_passes_details || []).flatMap(element => {
                if (element.valid_class_id && element.valid_class_id.length > 0) {
                    let abcd = element.valid_class_id.filter(ee => ee === classID);
                    return abcd;
                }
                return [];
            });
            setValid_class_ids(valid_class_ids);
        }
    }, [preData]);


    return (
        <>
            <Row>
                {preData &&
                    <Col md={4}>
                        <Card className="ServiceCardBox" >
                            <Card.Body>
                                <Row>
                                    <Col md={3}>
                                        <div className="cardImg pr-2">
                                            <Card.Img variant="top" src={preData.profile_img} />
                                        </div>
                                    </Col>
                                    <Col md={9}>
                                        <div className='CardParaText'>
                                            <Card.Title className='mb-0'>{preData.name}</Card.Title>
                                            <small className="m-0">{preData.gender}</small>
                                            <Card.Text>
                                                <p className='m-1'>Relation : {preData.relation ? preData.relation : 'Parent'} </p>
                                                <p className="m-0">{preData.email}</p>
                                            </Card.Text>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    {preData && preData.my_passes_details && preData.my_passes_details.length > 0 && preData.my_passes_details.valid_class_id != '' && valid_class_ids.length > 0 ?
                                        <>
                                            <Col>
                                                <SharedButton onClick={() => onClick(preData.my_passes_details, preData)} element={{ id: preData.data }} title={"Sign Up"} variant={"secondary"} className={"btn btn-secondary purchasePassBtn mt-4 w-100"} />
                                            </Col>
                                        </>
                                        :
                                        <Col>
                                            <SharedButton
                                                onClick={() => setPassPurchaseCondition(preData)}
                                                // href="/purchase-product" element={{id:value.data,baseURL:'/client-search',stateData:stateData}}
                                                title={"Purchase Pass"} variant={"secondary"} className={"btn btn-secondary purchasePassBtn mt-4 w-100"} />
                                        </Col>
                                    }
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                }
                {preData && preData.family_member && preData.family_member.map((data, index) => (
                    <>
                        <Col key={index} md={4}>
                            <Card style={{ height: 'unset' }} className="ServiceCardBox" key={index}>
                                <Card.Body>
                                    <Row>
                                        <Col md={3}>
                                            <div className="cardImg pr-2">
                                                <Card.Img variant="top" src={data.image} />
                                            </div>
                                        </Col>
                                        <Col md={9}>
                                            <Card.Title className='mb-0'>Name : {data.member_name}</Card.Title>
                                            <small className="m-0">Gender : {data.gender}</small>
                                            <Card.Text>
                                                <p className='m-1'>Relation : {data.relation ? data.relation : 'Non'} </p>
                                                <p className="m-0">Email : {data.email}</p>
                                            </Card.Text>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {data && data.my_passes_details && data.my_passes_details.length > 0 && data.my_passes_details.valid_class_id != '' ?
                                            <Col>
                                                <SharedButton onClick={() => onClick(data.my_passes_details, data,preData,'family')} element={{ id: data.data }} title={"Sign Up"} variant={"secondary"} className={"btn btn-secondary purchasePassBtn mt-4 w-100"} />
                                            </Col>
                                            :
                                            <Col>
                                                <SharedButton
                                                    onClick={() => setPassPurchaseCondition(preData, data)}
                                                    title={"Purchase Pass"} variant={"secondary"} className={"btn btn-secondary purchasePassBtn mt-4 w-100"} />
                                            </Col>
                                        }
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </>
                ))}
            </Row>
        </>
    )
}
