import React from 'react'
import { StaticsHeader } from '../../Components/header/StaticsHeader'
import { Link, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useState } from 'react'
import { api_register_studio, staff_get_skills } from '../../../Static_Services/Staff_Services'
import PlaceAutoCompleted from '../../Components/CommanPages/PlaceAutoCompleted'
import { InputField } from '../../../sharedComponents/InputField'
import { AddPassMultiSelect } from '../../../superadmin/private/dashboard/settings/add_new_pass_type/AddPassMultiSelect'
import { Spinner } from 'react-bootstrap'
import { errorAlert, successAlert } from '../../../Static_Services/Alertmsg'

export const StaffOtherDetails = () => {
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
    const [currentDate, setCurrentDate] = useState(new Date().getDate());
    const [registerData, setRegisterData] = useState();
    const [otherData, setOtherData] = useState();
    const [gender, setGender] = useState();
    const [loder, setLoder] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {

        // const maxDate = new Date(currentYear - 18, currentMonth, currentDate);

        if (localStorage.getItem('w_staffregistration')) {
            setRegisterData(JSON.parse(localStorage.getItem('w_staffregistration')));
        }
        if (localStorage.getItem('w_staffOther')) {
            setOtherData(JSON.parse(localStorage.getItem('w_staffOther')));
            const oth = JSON.parse(localStorage.getItem('w_staffOther'));
            setGender(oth.gender);
        }
        getskills();
    }, []);


    const getskills = async () => {
        const resp = await staff_get_skills();
        if (resp) {
            const cls = [];
            (resp.data.data).map((e) => { cls.push({ id: e.id, label: e.name }) })
            setClsOPT(cls);
        }
    }

    const [skillsdata, setSkillsdata] = useState();
    const [clsOPT, setClsOPT] = useState([]);
    const [clear, setClear] = useState(0);

    useEffect(() => { setError((pre) => ({ ...pre, skills: '' })); }, [skillsdata])

    const onchengeHandler = (e) => {
        const { name, value } = e.target;
        setInput((pre) => ({ ...pre, [name]: value }));
        setError((pre) => ({ ...pre, [name]: '' }));
    }

    const [cordinates, setCordinates] = useState();
    const [error, setError] = useState({ date_of_birth: '', address: '', experience: '', about: '', skills: '' })
    const [input, setInput] = useState({ radio: 'male', date_of_birth: '', address: '', city: '', state: '', country: '', zipcode: '', street: '', experience: '', about: '', lattitude: '', longitude: '' });
    useEffect(() => {
        if (cordinates && cordinates.getAddress) { setInput((pre) => ({ ...pre, address: cordinates.getAddress })); setError((pre) => ({ ...pre, address: '' })) }
        if (cordinates && cordinates.getCity) { setInput((pre) => ({ ...pre, city: cordinates.getCity })); }
        if (cordinates && cordinates.getState) { setInput((pre) => ({ ...pre, state: cordinates.getState })); }
        if (cordinates && cordinates.getCountry) { setInput((pre) => ({ ...pre, country: cordinates.getCountry })); }
        if (cordinates && cordinates.getZIP_Code) { setInput((pre) => ({ ...pre, zipcode: cordinates.getZIP_Code })); }
        if (cordinates && cordinates.getLocation_name) { setInput((pre) => ({ ...pre, street: cordinates.getLocation_name })); }
        if (cordinates && cordinates.getLat) { setInput((pre) => ({ ...pre, lattitude: cordinates.getLat })); }
        if (cordinates && cordinates.getLng) { setInput((pre) => ({ ...pre, longitude: cordinates.getLng })); }
    }, [cordinates]);


    const onregister1 = async () => {
        let isValid = 1;
        if (!skillsdata || skillsdata.length === 0) { isValid = 2; setError((pre) => ({ ...pre, skills: 'Skill is required' })) }
        if (!input.address) { isValid = 2; setError((pre) => ({ ...pre, address: 'Address is required' })) }
        if (!input.date_of_birth) { isValid = 2; setError((pre) => ({ ...pre, date_of_birth: 'Date of Birth is required' })) }
        if (!input.experience) { isValid = 2; setError((pre) => ({ ...pre, experience: 'Experience is required' })) }
        if (!input.about) { isValid = 2; setError((pre) => ({ ...pre, about: 'about yourself is required' })) }


        if (isValid === 1) {
            setLoder(true);
            let selsctedskill = '';
            if (skillsdata && skillsdata.length > 0) { selsctedskill = skillsdata.map((e) => e.id).join(','); }
            var formData = new FormData();
            formData.append('email', registerData.email);
            formData.append('mobile', registerData.mobile);
            formData.append('password', registerData.password);
            formData.append('name', registerData.name);
            formData.append('lastname', registerData.lastname);
            formData.append('country_code', registerData.country_code);

            formData.append('gender', input.radio);
            formData.append('date_of_birth', input.date_of_birth);
            formData.append('address', input.address);
            formData.append('city', input.city);
            formData.append('state', input.state);
            formData.append('country', input.country);
            formData.append('about', input.about);
            formData.append('zipcode', input.zipcode);

            formData.append('lat', input.lattitude);
            formData.append('lang', input.longitude);
            formData.append('street', input.street);

            formData.append('role', '4');
            formData.append('image', '');
            formData.append('device_type', '');
            formData.append('device_token', '');
            formData.append('referred_by', '');
            formData.append('emergency_contact_person', 'admin@gmail.com');
            formData.append('emergency_country_code', "+91");
            formData.append('emergency_contact_no', '8109956511');
            formData.append('singup_for', 'me');
            formData.append('skills', selsctedskill);
            formData.append('experience', input.experience);
            const resp = await api_register_studio(formData);

            //   var url = 'superadmin/webservices/api/register';

            //   this.commonservice.PostApis(formData,url).subscribe((response ) =>{
            if (resp) {
                setLoder(false);
                localStorage.setItem('w_UserID', JSON.stringify(resp.data.data.id));
                successAlert(resp.data.message);
                // var userSubject = response.data;
                // this.registerMessage = response.message;
                navigate('/staff-signin');
                localStorage.removeItem('w_staffOther');
                localStorage.removeItem('w_staffregistration');
            }
            else {
                localStorage.setItem('w_staffOther', JSON.stringify(registerData));
                setLoder(false);
                // this.registerMessage = response.message;
                // errorAlert(response.message);
            }

        }
    }


    return (
        <>
            {loder ?
                <div className='MainLoader'>
                    <Spinner animation="border" role="status" className='Loader'>
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
                : ""}
            <StaticsHeader />
            <div className="light-bg2">
                {/* <!-- Login Content Section --> */}
                <div className="background-before">
                    <div className="d-flex align-items-center justify-content-center staff-register-height p-4 p-sm-0">
                        <div className="align-item-center text-center wow animated slideInDown">
                            <div className="staff-login-box-owner white-bg box-shadow1 border-radius10 py-4 px-3 mt-3">

                                <div className="login-header text-center px-5">
                                    <h3 className="title dark-color mb-4"> Other Details </h3>
                                </div>
                                {/* <!-- end of login-header --> */}

                                <div className="logn-form mb-2">
                                    <form >
                                        <div className="form-group profile-gender mb-2">
                                            <p className="brand-color3 text-left font-14 mb-2"> Gender </p>
                                            <div className="profile-gender-button2 text-left">
                                                <input type='radio' checked={input.radio === 'male'} onChange={onchengeHandler} value="male" name='radio' />
                                                <label for='male'>Male</label>

                                                <input type='radio' id='female' checked={input.radio === 'female'} value="female" onChange={onchengeHandler} name='radio' />

                                                <label for='female'>Female</label>

                                                <input type='radio' id='other' checked={input.radio === 'other'} value="other" onChange={onchengeHandler} name='radio' />

                                                <label for='other'>Other</label>
                                            </div>
                                            {/* <!-- end of profile-gender-button --> */}
                                        </div>
                                        {/* <!-- Gender -->   */}

                                        <div className="form-group">
                                            <p className="brand-color3 text-left font-14 mb-2"> DOB </p>
                                            <InputField type="date" name={'date_of_birth'}  onChange={onchengeHandler} />
                                            <small className="text-danger">{error.date_of_birth}</small>
                                        </div>
                                        <div className="form-group">
                                            <p className="brand-color3 text-left font-14 mb-2"> Address </p>
                                            <PlaceAutoCompleted  setCordinates={setCordinates} />
                                            <small className="text-danger">{error.address}</small>
                                        </div>
                                        <div className="form-group">
                                            <p className="brand-color3 text-left font-14 mb-2"> Apartment, Street (optional) </p>
                                            <input type="text" name='street' className="form-control-1 w-100" placeholder="" formControlName="street" value={input.street} readOnly />
                                        </div>
                                        <div className="form-group">
                                            <p className="brand-color3 text-left font-14 mb-2"> Country </p>
                                            <input type="text" name='country' className="form-control-1 w-100" placeholder="" formControlName="country" value={input.country} readOnly />
                                            <div className="invalid-feedback">
                                                <div>Country is required</div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <p className="brand-color3 text-left font-14 mb-2"> State/Province </p>
                                            <input type="text" name='state' className="form-control-1 w-100" placeholder="" formControlName="state" value={input.state} readOnly />
                                            <div className="invalid-feedback">
                                                <div >State is required</div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <p className="brand-color3 text-left font-14 mb-2"> City </p>
                                            <input type="text" name='city' className="form-control-1 w-100" placeholder="" formControlName="city" value={input.city} readOnly />
                                            <div className="invalid-feedback">
                                                <div >City is required</div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <p className="brand-color3 text-left font-14 mb-2"> Experience </p>
                                            <input type="number" name='experience' onChange={onchengeHandler} min='0' className="form-control-1 w-100" placeholder="" formControlName="experience" />
                                            <small className='text-danger'>{error.experience}</small>
                                            <div className="invalid-feedback">
                                                <div >Experience is required</div>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <p className="brand-color3 text-left font-14 mb-2"> Skills </p>
                                            <div className="mb-4">
                                                <AddPassMultiSelect
                                                    // labelText="Class"
                                                    setSkillsdata={setSkillsdata}
                                                    options={clsOPT}
                                                    clear={clear}
                                                    setClear={setClear}
                                                />
                                                <small className='text-danger'>{error.skills}</small>


                                                {/* <div className="invalid-feedback">
                                                    <div>Skill is required</div>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <p className="brand-color3 text-left font-14 mb-2"> Write something about yourself </p>
                                            <input type="text" name='about' onChange={onchengeHandler} className="form-control-1 w-100" placeholder="" formControlName="about" />
                                            <small className='text-danger'>{error.about}</small>
                                        </div>
                                        <div className="py-3">
                                            <button className="btn btn-green-grad text-uppercase w-100" type="button" onClick={onregister1} >
                                                {/* <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> */}
                                                Next </button>
                                            <button type="button" className="btn btn-blue-grad text-uppercase w-100 mt-2" > Back </button>
                                        </div>
                                        <div className="text-center">
                                            <Link to="/terms-conditions" className="brand-color2 font-14"> Terms & Conditions </Link>
                                        </div>
                                    </form>
                                </div>
                                {/* <!-- end of logn-form --> */}

                            </div>
                            {/* <!-- end of login-box --> */}
                        </div>
                    </div>
                    {/* <!-- end of d-flex --> */}
                </div>
                {/* <!-- End of About Content Section --> */}
            </div>
        </>
    )
}
