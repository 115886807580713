import "./App.css";
import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";
import { createContext, useCallback, useEffect, useState } from "react";
import Auth from "./Studio-Services/Auth";
import 'react-calendar/dist/Calendar.css';
import 'toastr/build/toastr.min.css';


//  ********  Studio Services ***********  //
import { getUserProfileInfo, getProfile } from "./Studio-Services/Studio_Services";
import { StudioLogin } from "./public/containers/StudioLogin/StudioLogin";

// import { Login } from "./public/containers/login/Login";

//           Static Website Part Start      //

import { StaticHome } from './staticpagis/StaticPages/home/StaticHome'
import { StaticDemo } from "./staticpagis/StaticPages/demo/StaticDemo";
import { StaticApp } from "./staticpagis/StaticPages/apps/StaticApp";
import { StaticSolution } from "./staticpagis/StaticPages/solution/StaticSolution";

import { StaticPrising } from './staticpagis/StaticPages/pricing/StaticPrising'
import { StaticHelp } from './staticpagis/StaticPages/help/StaticHelp'
import { StaticLogin } from './staticpagis/StaticPages/login/StaticLogin'
import { Chooseplan } from './client-staff/Pages/chooseplan/Chooseplan'
import { StaffRegistration } from "./client-staff/Onboard/staff-registration/StaffRegistration";
import { ClientRegistration } from "./client-staff/Onboard/client-registration/ClientRegistration";


//  Static Footer  //
import { StaticAbout } from "./staticpagis/StaticPages/about-us/StaticAbout";
import { StaticPolicy } from "./staticpagis/StaticPages/privacy-policy/StaticPolicy";
import { StaticTermscondition } from "./staticpagis/StaticPages/terms-condition/StaticTermscondition";


//                      Studio Admin Part Start              //

import { Paycardsetup } from "./public/containers/paycard-setup/Paycardsetup";
import { Paymentsetup } from "./public/containers/payment-setup/Paymentsetup";
import { StudioHome } from "./public/containers/studio-home/StudioHome";
import { StudioRegistration } from "./public/containers/StudioRegistration/StudioRegistration";
//   ***********   Stuio Calendar  *********** //
import { Appointment } from './superadmin/private/dashboard/calendar/appointments/Appointment'
import { Class } from './superadmin/private/dashboard/calendar/class/Class'
import { Shift } from './superadmin/private/dashboard/calendar/shift/Shift'
import { Workshop } from './superadmin/private/dashboard/calendar/workshop/Workshop'
import { WorkShopEdit } from "./superadmin/private/dashboard/calendar/workshop/WorkShopEdit";

//  *************  Studio Cart **************  //
import { MakeApurchase } from "./superadmin/private/dashboard/cart/mack-a-purchase/MakeApurchase";
import { CalenderPassUpdate } from "./superadmin/private/dashboard/calendar/class/CalenderPassUpdate";


//   ************* Studio Graph ************ //
import { Cancel } from './superadmin/private/dashboard/graph/cancel/Cancel'
import { AttendanceClasses } from './superadmin/private/dashboard/graph/class_attendance/AttendanceClasses'
import { Product } from './superadmin/private/dashboard/graph/product/Product'
import { ServiceReport } from './superadmin/private/dashboard/graph/ServiceReport/ServiceReport'
import Staff from './superadmin/private/dashboard/graph/staff/Staff'
import { RecurringAmountList } from "./superadmin/private/dashboard/graph/recurring_amount/RecurringAmountList";
import { AttendanceChart } from "./superadmin/private/dashboard/graph/class_attendance/AttendanceChart";
// **************   Studio Home  *********** //

import { ClassSchedule } from "./superadmin/private/dashboard/home/classScheduleDetails/ClassSchedule";
import { ClassScheduleDetails } from "./superadmin/private/dashboard/home/classScheduleDetails/ClassScheduleDetails";
import { Home } from "./superadmin/private/dashboard/home/Home";

//  *********** Studio Profile   ************  //
// import { BusinessProfile } from "./superadmin/private/dashboard/profile/BusinessProfile";
import { ChangePassword } from "./superadmin/private/dashboard/profile/ChangePassword";
import { EditProfile } from "./superadmin/private/dashboard/profile/EditProfile";
import { UserProfile } from "./superadmin/private/dashboard/profile/UserProfile";


//  ************ Setting  *****************  //
import { Addclient } from "./superadmin/private/dashboard/settings/add_client/Addclient";
import { Addnewclass } from "./superadmin/private/dashboard/settings/add_new_class_type/Addnewclass";
import { Addnewpass } from "./superadmin/private/dashboard/settings/add_new_pass_type/Addnewpass";
import { Addroom } from "./superadmin/private/dashboard/settings/add_room/Addroom";
import { Addservice } from "./superadmin/private/dashboard/settings/add_service_type/Addservice";
import Addstaff from "./superadmin/private/dashboard/settings/add_staff/Addstaff";
import Addstafshift from "./superadmin/private/dashboard/settings/add_staff_shift/Addstafshift";
import { Hoursoperation } from "./superadmin/private/dashboard/settings/hours_operation/Hoursoperation";
import { ListClients } from "./superadmin/private/dashboard/settings/list_clients/ListClients";
import { Marketingservices } from "./superadmin/private/dashboard/settings/marketing_services/Marketingservices";
import { Merchantservices } from "./superadmin/private/dashboard/settings/merchant_services/Merchantservices";
import { Notification } from "./superadmin/private/dashboard/settings/notification/Notification";
import { Payroll } from "./superadmin/private/dashboard/settings/payroll/Payroll";
import { Sheduleappointment } from "./superadmin/private/dashboard/settings/schedule_appointment/Sheduleappointment";
import { Schedulenewclass } from "./superadmin/private/dashboard/settings/schedule_new_class/Schedulenewclass";
import { Scheduleworkshop } from "./superadmin/private/dashboard/settings/schedule_work_shop/Scheduleworkshop";
import { EditClinet } from "./superadmin/private/dashboard/settings/list_clients/EditClinet";
import { AdditionalServices } from "./superadmin/private/dashboard/settings/AdditionalServices";
import { EditInstructor } from "./superadmin/private/dashboard/settings/add_staff/EditInstructor";
import { PassUpdate } from "./superadmin/private/dashboard/settings/add_new_pass_type/PassUpdate";


//   *******************   Studio Users *********************  //
import { Clientlist } from "./superadmin/private/dashboard/users/client_list/Clientlist";
import { Instructor } from "./superadmin/private/dashboard/users/instructor/Instructor";
import { Transaction } from "./superadmin/private/dashboard/users/transaction_details/Transaction";
import { Transactionlist } from "./superadmin/private/dashboard/users/transaction_list/Transactionlist";
import { CustomerPaymentRequest } from "./superadmin/private/dashboard/users/transaction_details/CustomerPaymentRequest"
import { Clientoflist } from "./superadmin/private/dashboard/users/client_list/Clientoflist";
import { Addnotification } from "./superadmin/private/dashboard/settings/notification/Addnotification";
import { AddPeople } from "./superadmin/private/dashboard/settings/AddPeople";
import { AddItems } from "./superadmin/private/dashboard/settings/AddItems";
import { AddThings } from "./superadmin/private/dashboard/settings/AddThings";
import { get_countries } from "./Studio-Services/Studio_Services";


import { CalenderShedul } from "./superadmin/private/dashboard/calendar/class/CalenderShedul";


// *******************************   //


import { UpdateClassPass } from "./superadmin/private/dashboard/home/updateClassPass/UpdateClassPass";
import { Editclass } from "./superadmin/private/dashboard/settings/add_new_class_type/Editclass";
import { Addshiftlist } from "./superadmin/private/dashboard/settings/add_staff_shift/Addshiftlist";
import { Viewshift } from "./superadmin/private/dashboard/settings/add_staff_shift/Viewshift";
import { Listproduct } from "./superadmin/private/dashboard/settings/full_businees_setup/Listproduct";
import { Productupdate } from "./superadmin/private/dashboard/settings/full_businees_setup/Productupdate";
import { Viewservice } from "./superadmin/private/dashboard/settings/add_service_type/Viewservice";
import { PurchaseProduct } from "./superadmin/private/dashboard/cart/mack-a-purchase/PurchaseProduct";
import { CheckOut } from "./superadmin/private/dashboard/cart/mack-a-purchase/CheckOut";
import { Payment } from "./superadmin/private/dashboard/cart/mack-a-purchase/Payment";
import { ClassSchedulePreview } from "./superadmin/private/dashboard/home/classScheduleDetails/ClassSchedulePreview";

import { PageNoteFound } from "./sharedComponents/PageNoteFound";
// Staf Login 

import { StaffLogin } from "./client-staff/Onboard/staff-login/StaffLogin";
import { StaffForgot } from "./client-staff/Onboard/staff-forgot/StaffForgot";


// Clint Login
import { ClientLogin } from "./client-staff/Onboard/client-login/ClientLogin";
import { ClientForgot } from "./client-staff/Onboard/client-forgot/ClientForgot";
import { TransactionReport } from "./superadmin/private/dashboard/users/transaction_details/TransactionReport";
import { TransactionDetailReport } from "./superadmin/private/dashboard/users/transaction_details/TransactionDetailReport";
import { IndividualTransactionDetails } from "./superadmin/private/dashboard/users/transaction_details/IndividualTransactionDetails";

// Staff 
import { Studio } from "./client-staff/Pages/studio/Studio";
import { StudioDetail } from "./client-staff/Pages/studio-detail/StudioDetail";
import { DashboardDetail } from "./client-staff/Pages/dashboard-detail/DashboardDetail";
import { StaffClasses } from "./client-staff/Pages/staff-classes/StaffClasses";
import { AppointmentDetail } from "./superadmin/private/dashboard/calendar/appointments/AppointmentDetail";
import { PassPurchase } from "./superadmin/private/dashboard/graph/passPurchase/PassPurchase";
import { StaffAppointment } from "./client-staff/Pages/staff-appointment/StaffAppointment";
import { StaffClassesDetail } from "./client-staff/Pages/staff-classes-detail/StaffClassesDetail";
import { ClientWorkshopDetail } from "./client-staff/Pages/client-workshop-detail/ClientWorkshopDetail";
import { InstructorDetail } from "./client-staff/Pages/instructor-detail/InstructorDetail";
import { StaffClientSearch } from "./client-staff/Pages/staff-client-search/StaffClientSearch";
import { CostomerDetails } from "./client-staff/Pages/customer-detail-view/CostomerDetails";
import { PassDetail } from "./client-staff/Pages/pass-detail/PassDetail";
import { StaffProductDetail } from "./client-staff/Pages/staff-product-detail/StaffProductDetail";
import { Favourite } from "./client-staff/Pages/favourite/Favourite";
import { Prefrence } from "./client-staff/Pages/prefrence/Prefrence";
import { DashboardClient } from "./client-staff/Pages/dashboard/DashboardClient";
import { SearchResult } from "./client-staff/Pages/search-result/SearchResult";
import { MyProfile } from "./client-staff/Onboard/myprofile/MyProfile";
import { StaffCart } from "./client-staff/Pages/staff-cart/StaffCart";
import { StaffMyAppointmentBookingDetail } from "./client-staff/Pages/staff-my-appointment-booking-detail/StaffMyAppointmentBookingDetail";
import StaffAuth from "./Studio-Services/StaffAuth";
import { StaffAvailability } from "./client-staff/Pages/staff-availability/StaffAvailability";
import { ClientAll } from "./client-staff/Pages/client-all/ClientAll";
import { CustomerProfile } from "./client-staff/Pages/customer-profile/CustomerProfile";
import { StaffShift } from "./client-staff/Pages/staff-shift/StaffShift";
import { ClientServices } from "./client-staff/Pages/client-services/ClientServices";
import { Family } from "./client-staff/Pages/family/Family";
import { ClientPayment } from "./client-staff/Pages/payment/ClientPayment";
import { MyTransaction } from "./client-staff/Pages/my-transaction/MyTransaction";
import { MyCart } from "./client-staff/Pages/cart/MyCart";
import { ClientClasses } from "./client-staff/Pages/client-classes/ClientClasses";
import { InstructorAll } from "./client-staff/Pages/instructor-all/InstructorAll";
import { ProductAll } from "./client-staff/Pages/product-all/ProductAll";
import { ClientMyWaitlist } from "./client-staff/Pages/client-my-waitlist/ClientMyWaitlist";
import { ClientMyWorkshop } from "./client-staff/Pages/client-my-workshop/ClientMyWorkshop";
import { Pasesdetails } from "./client-staff/Pages/pases-details/Pasesdetails";
import { ProductDetail } from "./client-staff/Pages/product-detail/ProductDetail";
import { ProductPurchase } from "./client-staff/Pages/product-purchase/ProductPurchase";
import { ClientServicesDetails } from "./client-staff/Pages/client-services-detail/ClientServicesDetails";
import { StaffMyAppointmentBooking } from "./client-staff/Pages/staff-my-appointment-booking/StaffMyAppointmentBooking";
import { Profile } from "./client-staff/Onboard/profile/Profile";
import { ClientMyAppointment } from "./client-staff/Pages/client-my-appointment/ClientMyAppointment";
import { ClientMyAttendance } from "./client-staff/Pages/client-my-attendance/ClientMyAttendance";
import { ClientMyPurchase } from "./client-staff/Pages/client-my-purchase/ClientMyPurchase";
import { ClientclassNameisDetails } from "./client-staff/Pages/client-classes-detail/ClientClassisDetails";
import { StaffMyAppointmentPayment } from "./client-staff/Pages/staff-my-appointment-payment/StaffMyAppointmentPayment";
import LiveSearch from "./LiveSearch";
import { ClientOtherDetail } from "./client-staff/Onboard/client-other-detail/ClientOtherDetail";
import { MonthSchedule } from "./superadmin/private/dashboard/comman_page/MonthSchedule";
import { StudioCustomerProfile } from "./superadmin/private/dashboard/comman_page/StudioCustomerProfile";
import Test from "./superadmin/private/dashboard/comman_page/Test";
import BusinessHoursabc from "./superadmin/signup/BusinessHoursabc";
import { BusinessProfile } from "./superadmin/private/dashboard/profile/BusinessProfile";
import BusinessProfileName from "./superadmin/signup/BusinessProfileName";
import { StaffOtherDetails } from "./client-staff/Onboard/staff-other-detail/StaffOtherDetails";
import { StaffVerification } from "./client-staff/Onboard/staff-verification/StaffVerification";
import { ClientValidation } from "./client-staff/Onboard/client-verification/ClientValidation";
import Studio_Forget_Pass from "./superadmin/private/dashboard/comman_page/Studio_Forget_Pass";
import { PassUpdateNew } from "./superadmin/private/dashboard/settings/add_new_pass_type/PassUpdateNew";
import CreateWeb from "./superadmin/private/dashboard/webtemplate/CreateWeb";
import Businesswebsite from "./superadmin/private/dashboard/webtemplate/Businesswebsite";
import NewBusinessLocation from "./superadmin/signup/NewBusinessLocation";
import { FRT_Report } from "./superadmin/private/dashboard/users/transaction_details/FRT_Report";
import { TransactionDetailReportFaile } from "./superadmin/private/dashboard/users/transaction_details/TransactionDetailReportFaile";


const MyContext = createContext();
const ID = localStorage.getItem('id');

function App() {

  const [userinfo, setUserinfo] = useState([]);
  const [businessData, setBusinessData] = useState([]);
  const [localData, setLocaldata] = useState([]);
  const [planlist, setPlanlist] = useState([]);
  const [context, setContext] = useState();
  const [country, setCountry] = useState([]);

  // const []

  const [staffDetail, setStaffDetail] = useState([]);


  const getcountry = async () => {
    const data = [];
    if (!country) {
      const resp = await get_countries();
      if (resp) {
        if (resp.data) {
          resp.data.forEach(element => {
            data.push({
              label: element.name,
              value: element.code,
            })
          });
        }
      }
      setCountry(data);
    }
  }

  const getBusinessData = async () => {
    if (!businessData) {
      const resp = await getProfile();
      if (resp) {
        setBusinessData(resp.data.data);
      }
    }
  };

  const getUserData = async () => {
    if (!userinfo) {
      const resp = await getUserProfileInfo();
      if (resp) {
        const useInfo = resp.data.data ? resp.data.data : '';
        setUserinfo(useInfo);
      }
    }
  };


  useEffect(() => {
    const value = {
      uData: userinfo,
      bData: businessData,
      local: localData,
      planData: planlist,
      country: country
    }
    setContext(value);
  }, [planlist, localData, businessData, userinfo])


  useEffect(() => {
    if (ID) {
      getUserData();
      getBusinessData();
      getcountry();
    }
  }, [ID]);

  const updateContextValue = (data) => {
    setLocaldata(data);
  }
  const updatePlandata = (data) => {
    setPlanlist(data);
  }

  const setUserData = (user, business) => {
    setUserinfo(user);
    setBusinessData(business);
  }

  // Set Staff Login API Detail
  const setStaff = (data) => {
    setStaffDetail(data);
  }
  const [todos, setTodos] = useState([]);
  const [instNoti, setInstNoti] = useState(0);

  const addTodo = useCallback((data) => { setTodos(data); });
  const instuctorNoti = useCallback((num) => { setInstNoti(num); });


  return (
    <>
      <MyContext.Provider value={{ context, updateContextValue, updatePlandata, setUserData, setStaff, addTodo, todos, instuctorNoti, instNoti }}>
        <BrowserRouter>
          <Routes>
            <Route path="/LiveSearch" element={<LiveSearch />} />
            <Route path="/" exact element={<StaticHome />} />
            <Route path="*" element={<PageNoteFound />} />
            <Route path="/Test" element={<Test />} />
            <Route path="/business-profile" element={<BusinessProfileName />} />
            {/* <Route path="/business-locations" element={<BusinessLocation />} /> */}
            <Route path="/business-locations" element={<NewBusinessLocation />} />
            <Route path="/business-hours" element={<BusinessHoursabc />} />
            <Route path="/forgot-password" element={<Studio_Forget_Pass />} />

            {/* Staff */}
            <Route path="/staff-signin" element={<StaffLogin />} />
            <Route path="/staff-forgot" element={<StaffForgot />} />
            <Route path="/staff-registration" element={<StaffRegistration />} />
            <Route path="/staff-otherdetail" element={<StaffOtherDetails />} />
            <Route path="/staff-verification" element={<StaffVerification />} />
            {/* Client */}

            <Route path="/signin" element={<ClientLogin />} />
            <Route path="/verification" element={<ClientValidation />} />
            <Route path="/client-forgot" element={<ClientForgot />} />
            <Route path="/client-registration" element={<ClientRegistration />} />
            <Route path="/about-us" element={<StaticAbout />} />
            <Route path="/privacy-policy" element={<StaticPolicy />} />
            <Route path="/terms-conditions" element={<StaticTermscondition />} />
            <Route path="/studio-home" element={<StudioHome />} />
            <Route path="/studio-registration" element={<StudioRegistration />} />
            <Route path="/payment-setup" element={<Paymentsetup />} />
            <Route path="/paycard-setup" element={<Paycardsetup />} />
            <Route path="/statichome" element={<StaticHome />} />
            <Route path="/solution" element={<StaticSolution />} />
            <Route path="/apps" element={<StaticApp />} />
            <Route path="/pricing" element={<StaticPrising />} />
            <Route path="/demo" element={<StaticDemo />} />
            <Route path="/contact-us" element={<StaticHelp />} />
            <Route path="/login" element={<StaticLogin />} />
            <Route path="/studiologin" element={<StudioLogin />} />
            <Route path="/my-profile" element={<MyProfile />} />
            <Route path="/choose-plan" element={<Chooseplan />} />
            <Route path="/studioadmin/monthly-schedule" element={<MonthSchedule />} />
            <Route path="/otherdetail" element={<ClientOtherDetail />} />
            <Route path="/forgot" element={<ClientForgot />} />
            <Route path="/registration" element={<ClientRegistration />} />


            <Route element={<StaffAuth />} >
              {/*Staff After Login */}
              <Route path="/studio" element={<Studio />} />
              <Route path="/detail" element={<DashboardDetail />} />
              <Route path="/studio-detail" element={<StudioDetail />} />
              <Route path="/classess" element={<StaffClasses />} />
              <Route path="/workshopss" element={<StaffClasses />} />
              <Route path="/appointment" element={<StaffAppointment />} />
              <Route path="/my-appointment" element={<StaffAppointment />} />
              <Route path="/class-detail" element={<StaffClassesDetail />} />
              <Route path="/workshop-detail" element={<ClientWorkshopDetail />} />
              <Route path="/workshops-detail" element={<ClientWorkshopDetail />} />
              <Route path="/instructor-detail" element={<InstructorDetail />} />
              <Route path="/staff_client-search" element={<StaffClientSearch />} />
              <Route path="/customer-detail" element={<CostomerDetails />} />
              <Route path="/pass-detail" element={<PassDetail />} />
              <Route path="/product-detail" element={<StaffProductDetail />} />
              <Route path="/myfavourites" element={<Favourite />} />
              <Route path="/preference" element={<Prefrence />} />
              <Route path="/my-home" element={<DashboardClient />} />
              <Route path="/search" element={<SearchResult />} />
              <Route path="/customer-cart" element={<StaffCart />} />
              <Route path="/booking-detail" element={<StaffMyAppointmentBookingDetail />} />
              <Route path="/availability" element={<StaffAvailability />} />
              <Route path="/client" element={<ClientAll />} />
              <Route path="/customer-profile" element={<CustomerProfile />} />
              <Route path="/my-shift" element={<StaffShift />} />
              <Route path="/services" element={<ClientServices />} />

              {/*Staff After Login End */}

              {/* Start Client AFter LOgin */}

              <Route path="/family" element={<Family />} />
              <Route path="/my_payment" element={<ClientPayment />} />
              <Route path="/my-transaction" element={<MyTransaction />} />
              <Route path="/my-cart" element={<MyCart />} />
              <Route path="/my-classes" element={<ClientClasses />} />
              <Route path="/workshops" element={<ClientClasses />} />
              <Route path="/staff" element={<InstructorAll />} />
              <Route path="/allproduct" element={<ProductAll />} />
              <Route path="/my-classlist" element={<ClientMyWaitlist />} />
              <Route path="/my-workshop" element={<ClientMyWorkshop />} />
              <Route path="/passes-detail" element={<Pasesdetails />} />
              <Route path="/product" element={<ProductDetail />} />
              <Route path="/product-buy" element={<ProductPurchase />} />
              <Route path="/services-offered" element={<ClientServicesDetails />} />
              {/* <Route path="/my-cart" element={<MyCart />} /> */}
              <Route path="/my-book-appointment" element={<StaffMyAppointmentBooking />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/profiles" element={<Profile />} />
              <Route path="/appointments" element={<ClientMyAppointment />} />
              <Route path="/my-attendance" element={<ClientMyAttendance />} />
              <Route path="/my-waitlist" element={<ClientMyWaitlist />} />
              <Route path="/my-purchase" element={<ClientMyPurchase />} />
              <Route path="/classes-detail" element={<ClientclassNameisDetails />} />
              <Route path="/booking-payment" element={<StaffMyAppointmentPayment />} />


            </Route>
            {/* Clinet END */}
            <Route element={<Auth />}>
              <Route path="/Home" element={<Home />} />
              <Route path="/ClassSchedule" element={<ClassSchedule />} />
              <Route path="/client-search" element={<Clientlist />} />
              <Route path="/classschedulelist" element={<ClassSchedulePreview />} />
              <Route path="/create_web" element={<CreateWeb />} />
              <Route path="/businessweb" element={<Businesswebsite />} />
              {/* <Route path="/create_web_preview" element={<CreateWebPreview />} /> */}

              {/* ************ Studio Calendar ************** */}
              <Route path="/service-appointment" element={<Appointment />} />
              <Route path="/service-appointment-detail" element={<AppointmentDetail />} />
              <Route path="/classes" element={<Class />} />
              <Route path="/workshop-schedule" element={<Workshop />} />
              <Route path="/WorkShopEdit" element={<WorkShopEdit />} />
              <Route path="/shift-schedule" element={<Shift />} />
              <Route path="/update-schedule-class-pass" element={<UpdateClassPass />} />
              <Route path="/CalenderPassUpdate" element={<CalenderPassUpdate />} />
              <Route path="/CalenderShedul" element={<CalenderShedul />} />

              {/* ************** Studio Graph ****************** */}
              <Route path="/clsat-report" element={<AttendanceClasses />} />
              <Route path="/service-report" element={<ServiceReport />} />
              <Route path="/staff-report" element={<Staff />} />
              <Route path="/edit-instructor" element={<EditInstructor />} />
              <Route path="/product-report" element={<Product />} />
              <Route path="/cancel-className-report" element={<Cancel />} />
              <Route path="/list-recurring-amount" element={<RecurringAmountList />} />
              <Route path="/AttendanceChart" element={<AttendanceChart />} />
              {/* <Route path="/AttendanceChart" element={<NewAttendanceChart />} /> */}

              {/*  ************* Studio Cart ***************** */}
              <Route path="/make-a-purchase" element={<MakeApurchase />} />
              <Route path="/purchase-product" element={<PurchaseProduct />} />
              <Route path="/book-appointment" element={<Sheduleappointment />} />
              <Route path="/cart" element={<CheckOut />} />
              <Route path="/Payment" element={<Payment />} />

              {/*  ************** Studio Setting ************ */}
              <Route path="/add-client" element={<Addclient />} />
              <Route path='/add-schedule-class' element={<Schedulenewclass />} />
              <Route path='/add-schedule-workshop' element={<Scheduleworkshop />} />
              <Route path='/list-clients' element={<ListClients />} />
              <Route path="/list-instructor" element={<Addstaff />} />
              <Route path="/list-services" element={<Addservice />} />
              <Route path="/list-class" element={<Addnewclass />} />
              <Route path='/list-pass' element={<Addnewpass />} />
              <Route path="/list-shifts" element={<Addstafshift />} />
              <Route path="/add-shifts" element={<Addshiftlist />} />
              <Route path="/merchant-service" element={<Merchantservices />} />
              <Route path="/marketing" element={<Marketingservices />} />
              <Route path="/business-location" element={<Addroom />} />
              <Route path="/Notification" element={<Notification />} />
              <Route path="/add-notifiaction" element={<Addnotification />} />
              <Route path="/payroll" element={<Payroll />} />
              <Route path="/edit-client" element={<EditClinet />} />
              <Route path="/add-people" element={<AddPeople />} />
              <Route path="/add-items" element={<AddItems />} />
              <Route path="/add-things" element={<AddThings />} />
              <Route path="/additional-services" element={<AdditionalServices />} />
              <Route path="/studio-customer-profile" element={<StudioCustomerProfile />} />
              <Route path="/pass-update" element={<PassUpdateNew />} />
              <Route path="/pass-update_new" element={<PassUpdate />} />
              <Route path="/list-shift" element={<Viewshift />} />
              <Route path="/list-products" element={<Listproduct />} />
              <Route path="/product-update" element={<Productupdate />} />
              <Route path="/view-service" element={<Viewservice />} />
              <Route path="/hours-opreation" element={<Hoursoperation />} />
              <Route path="/pass-purchase-detail" element={<PassPurchase />} />

              {/* ********* Studio User *********** */}
              <Route path="/instructor-request-list" element={<Instructor />} />
              <Route path="/customer-request-list" element={<Clientoflist />} />
              <Route path="/transaction-list" element={<Transactionlist />} />
              <Route path="/customer-payment-request-list" element={<Transaction />} />
              <Route path="/transactionreport" element={<TransactionReport />} />
              <Route path="/transactiondetailreport" element={<TransactionDetailReport />} />
              <Route path="/t_d_r_f" element={<TransactionDetailReportFaile />} />
              <Route path="/FRT_Report" element={<FRT_Report />} />
              <Route path="/trindetail" element={<IndividualTransactionDetails />} />              
              <Route path="/customer-payment-request" element={<CustomerPaymentRequest />} />
              <Route path="/edit-class" element={<Editclass />} />

              {/* ************** Studio Comman Page */}
              <Route path="/ClassScheduleDetails" element={<ClassScheduleDetails />} />
              <Route path="/UserProfile" element={<UserProfile />} />
              <Route path="/EditProfile" element={<EditProfile />} />
              <Route path="/studioadmin/business-profile-update" element={<BusinessProfile />} />
              <Route path="/BusinessProfile" element={<BusinessProfile />} />

              <Route path="/ChangePassword" element={<ChangePassword />} />

              {/* ********************************************************* */}
              {/* <Route path="/studioadmin/monthly-schedule" element={<MonthSchedule />} /> */}


            </Route>
          </Routes>
        </BrowserRouter>
      </MyContext.Provider>
    </>
  );
}

export default App;
export { MyContext };