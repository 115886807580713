import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { SharedButton } from '../../../../../sharedComponents/Button'
import { Link, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { IoIosArrowBack } from 'react-icons/io'
import { RiEditFill } from 'react-icons/ri'
import { business_workshop_master_updateAPI, get_room_location, get_workshop_schedule_calendar, search_customer_for_workshop, workshop_appointment_cancel_API, workshop_scheduled_list, workshop_scheduling_cancel } from '../../../../../Studio-Services/Studio_Services'
import { InputField } from '../../../../../sharedComponents/InputField'
import { ClientBox } from '../../../../../sharedComponents/ClientBox'
import { WorkShopBox } from './WorkShopBox'
import { SharedSelect } from '../../../../../sharedComponents/SharedSelect'
import Swal from 'sweetalert2'
import logo from "../../../../../Images/logo_white_web.png"
import { FaEdit } from 'react-icons/fa'
import CapacityModal from './CapacityModal'
import { errorAlert } from '../../../../../Static_Services/Alertmsg'

export const WorkshopForm = ({ setLoder, preSelectedDate, preLocalData }) => {

  const [workData, setWorkData] = useState();
  const [clData, setClData] = useState([]);
  const [edit, setEdit] = useState(0);
  const [wrkst, setWrkst] = useState();
  const navigate = useNavigate();
  const [customer_detail, setCustomer_detail] = useState([]);
  const [serchvalue, setSerchvalue] = useState();
  const [currentMonth, setCurrentMonth] = useState(moment().format('MM'));
  const [currentYear, setCurrentYear] = useState(moment().format('YYYY'));
  const [currentDate, setCurrentDate] = useState(moment().format('MM-DD-YYYY'));
  const [custSelect, setCustSelect] = useState(0);
  const [locationss, setLocationss] = useState();

  const [nameEdit, setNameEdit] = useState(false);
  const [dEdit, setDEdit] = useState(false);
  const [cpshow, setCpshow] = useState(false);
  const [manualCapacity, setManualCapacity] = useState();
  const [preCapacity, setPreCapacity] = useState();

  const inputRef = useRef(null);

  useEffect(() => {
    // if (JSON.parse(localStorage.getItem('viewSchedule'))) {
    //   setWorkData(JSON.parse(localStorage.getItem('viewSchedule')));
    //   setWrkst((JSON.parse(localStorage.getItem('viewSchedule'))).status);
    //   if ((JSON.parse(localStorage.getItem('viewSchedule'))).customer_detail) {
    //     setCustomer_detail((JSON.parse(localStorage.getItem('viewSchedule'))).customer_detail);
    //   }
    // }
  }, [])

  useEffect(() => {
    if (preLocalData.length !== 0) {
      setWorkData(preLocalData);
      if (preLocalData && preLocalData.capacity) {
        setPreCapacity(preLocalData.capacity);
      }
      setWrkst(preLocalData.status);
      setCustomer_detail(preLocalData.customer_detail);
      getRoomLocation(preLocalData);
    } else {
      setCustomer_detail([]);
    }

  }, [preLocalData]);


  const getRoomLocation = async (workShopdata) => {
    const fData = {
      "business_id": localStorage.getItem("business_id")
    }
    const resp = await get_room_location(fData);
    if (resp) {
      const loc = [];
      (resp.data.data).forEach(e => {
        loc.push({
          value: e.location_id, label: e.location_name
        })
      })
      const wrkdata = { ...workShopdata };
      const sel = loc.filter((e) => e.label == wrkdata.location_name
      );
      if (sel.length != 0) {
        wrkdata.location_id = sel[0].value;
        setWorkData(wrkdata);
      }
      setLocationss(loc);
    }
  }


  const getProductPrice = (amount, tax) => {
    if (tax === 0) {
      return 0;
    } else {
      const taxAmount = (+tax / 100) * +amount;
      return taxAmount;
    }
  }

  const handleClick = () => {
    inputRef.current.removeAttribute('disabled');
    inputRef.current.focus();
  };


  const searchHandler = async (e) => {
    const data = e.target.value;
    setSerchvalue(data)
    if (data) {
      const fData = {
        "pageid": 1,
        "workshop_id": workData.workshop_id ? workData.workshop_id : "",
        "search_val": data
      }
      const resp = await search_customer_for_workshop(fData);
      if (resp) {
        const list = [];
        (resp.data.data).map((e) => {
          list.push({
            data: e,
            profile_img: e.profile_img,
            name: e.name,
            gender: e.gender,
            email: e.email,
          })
        })
        setClData(list);
      }
    } else {
      setClData();
    }
  }

  const workshoop = [
    { value: "Active", label: "Active" },
    { value: "Deactive", label: "In-Active" },
    { value: "Cancel", label: "Cancel" },
  ]

  const statusChange = async (e) => {
    const status = e.target.value;

    const finalData = {
      shceduleId: workData.id,
      workShopId: workData.workshop_id,
      status: status
    }
    Swal.fire({
      title: '<small>CONFIRMATION</small>',
      icon: 'info',
      html:
        'Are you sure to change status ?',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "YES",
      cancelButtonText: "NO"
    }).then(async (result) => {
      if (result.isConfirmed) {
        const resp = await workshop_scheduling_cancel(finalData);
        if (resp) {
          Swal.fire({
            html: `
        <img src=${logo} class="img-fluid d-block mx-auto mt-5" />
        <h4 class="title text-uppercase text-center mt-4 textWhite">Success</h4>
        <p class="text-center my-4 textcolor">
          <small class="textWhite">${resp.data.message}</small>
        </p>
      `,
            showClass: {
              popup: 'custom-dialog-container',
            },
            customClass: {
              confirmButton: 'custom-button custom-button-size modalbtn',
            },
            confirmButtonText: 'Continue',
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/workshop-schedule");
            }
          });
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        setWrkst(workData.status);
        Swal.fire('Changes are not saved', '', 'info')
      }
    })

  }

  const isVisibaleOption = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ]

  const visibilityChange = async (e) => {
    const myvalue = e.target.value;

    const finalData = {
      workShopId: workData.workshop_id,
      visibility: myvalue
    }
    Swal.fire({
      title: '<small>CONFIRMATION</small>',
      icon: 'info',
      html:
        'Are you sure to change status ?',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "YES",
      cancelButtonText: "NO"
    }).then(async (result) => {
      if (result.isConfirmed) {
        const resp = await business_workshop_master_updateAPI(finalData);
        if (resp) {
          Swal.fire({
            html: `
        <img src=${logo} class="img-fluid d-block mx-auto mt-5" />
        <h4 class="title text-uppercase text-center mt-4 textWhite">Success</h4>
        <p class="text-center my-4 textcolor">
          <small class="textWhite">${resp.data.message}</small>
        </p>
      `,
            showClass: {
              popup: 'custom-dialog-container',
            },
            customClass: {
              confirmButton: 'custom-button custom-button-size modalbtn',
            },
            confirmButtonText: 'Continue',
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/workshop-schedule");
            }
          });
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        setWrkst(workData.status);
        Swal.fire('Changes are not saved', '', 'info')
      }
    })

  }

  const workshopDataHandler = (data) => {
    setWorkData(data);
    setCustomer_detail(data.customer_detail);
  }

  const searchReset = () => {
    setClData();
  }
  const setSerchvalueHandler = () => {
    setSerchvalue();
  }

  const scheduleWorkshopList = async () => {
    const date = moment(preSelectedDate, 'YYYY-MM-DD').startOf('day');
    const timestamp = Math.floor(date.valueOf() / 1000);
    const requuestData = {
      pageid: '1',
      scheduleDate: timestamp
    };
    const resp = await workshop_scheduled_list(requuestData);
    if (resp) {
      const respwork = (resp.data.data).filter(opt => opt.id === workData.id);
      let data = workData;
      data = respwork[0];
      setWorkData(data);
    }
  }

  const nameChangeHandler = (e) => {
    const value = e.target.value;
    const wkData = { ...workData };
    wkData.name = value;
    setWorkData(wkData);
  }

  const nameUpdateHandler = () => {
    setCpshow(false);
    inputRef.current.setAttribute('disabled', true);
    const finalData = {
      workShopId: workData.workshop_id,
      name: workData.name,
      capacity: workData.capacity,
    }
    Swal.fire({
      title: '<small>CONFIRMATION</small>',
      icon: 'info',
      html:
        'Are you sure to change ?',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "YES",
      cancelButtonText: "NO"
    }).then(async (result) => {
      if (result.isConfirmed) {
        const resp = await business_workshop_master_updateAPI(finalData);
        if (resp) {
          Swal.fire({
            html: `
        <img src=${logo} class="img-fluid d-block mx-auto mt-5" />
        <h4 class="title text-uppercase text-center mt-4 textWhite">Success</h4>
        <p class="text-center my-4 textcolor">
          <small class="textWhite">${resp.data.message}</small>
        </p>
      `,
            showClass: {
              popup: 'custom-dialog-container',
            },
            customClass: {
              confirmButton: 'custom-button custom-button-size modalbtn',
            },
            confirmButtonText: 'Continue',
          }).then((result) => {
            if (result.isConfirmed) {
              // navigate("/workshop-schedule");
            }
          });
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        setWrkst(workData.status);
        Swal.fire('Changes are not saved', '', 'info')
      }
    })


  }


  const locationChange = (e) => {
    const locID = e.target.value;

    const finalData = {
      shceduleId: workData.id,
      workShopId: workData.workshop_id,
      location: locID
    }
    Swal.fire({
      title: '<small>CONFIRMATION</small>',
      icon: 'info',
      html:
        'Are you sure to change location',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "YES",
      cancelButtonText: "NO"
    }).then(async (result) => {
      if (result.isConfirmed) {
        const resp = await workshop_scheduling_cancel(finalData);
        if (resp) {
          Swal.fire({
            html: `
        <img src=${logo} class="img-fluid d-block mx-auto mt-5" />
        <h4 class="title text-uppercase text-center mt-4 textWhite">Success</h4>
        <p class="text-center my-4 textcolor">
          <small class="textWhite">${resp.data.message}</small>
        </p>
      `,
            showClass: {
              popup: 'custom-dialog-container',
            },
            customClass: {
              confirmButton: 'custom-button custom-button-size modalbtn',
            },
            confirmButtonText: 'Continue',
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/workshop-schedule");
            }
          });
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        setWrkst(workData.status);
        Swal.fire('Changes are not saved', '', 'info')
      }
    })

  }


  useEffect(() => {
    if (workData) {
      setDevalue(workData.description);
    }
  }, [workData])



  const [devalue, setDevalue] = useState("");



  const descriptionUpdate = () => {
    const finalData = { "workShopId": workData.workshop_id, "description": devalue, }
    Swal.fire({
      title: '<small>CONFIRMATION</small>',
      icon: 'info',
      html:
        'Are you sure to change ?',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "YES",
      cancelButtonText: "NO"
    }).then(async (result) => {
      if (result.isConfirmed) {
        const resp = await business_workshop_master_updateAPI(finalData);
        if (resp) {
          Swal.fire({
            html: `
        <img src=${logo} class="img-fluid d-block mx-auto mt-5" />
        <h4 class="title text-uppercase text-center mt-4 textWhite">Success</h4>
        <p class="text-center my-4 textcolor">
          <small class="textWhite">${resp.data.message}</small>
        </p>
      `,
            showClass: {
              popup: 'custom-dialog-container',
            },
            customClass: {
              confirmButton: 'custom-button custom-button-size modalbtn',
            },
            confirmButtonText: 'Continue',
          }).then((result) => {
            if (result.isConfirmed) {
            }
          });
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        setWrkst(workData.status);
        Swal.fire('Changes are not saved', '', 'info')
      }
    })
    console.log(devalue);

  }

  const capacityHandler = (data) => {
    const wkData = { ...workData };
    if (wkData) wkData.capacity = data;
    setWorkData(wkData);
  }

  const cpHandleClose = () => {
    const wkData = { ...workData };
    if (wkData) wkData.capacity = preCapacity;
    setWorkData(wkData);
    setCpshow(false);
  }


  const cancelHandler = async (trID) => {
    if (!trID) { errorAlert("The transaction ID is missing or invalid."); return; }
    Swal.fire({
      html: `
        <img src=${logo} class="img-fluid d-block mx-auto mt-5" />
        <h4 class="title text-uppercase text-center mt-4 textWhite">CONFIRMATION</h4>
        <p class="text-center my-4 textcolor">
          <small class="textWhite">Are you sure to cancel ?</small>
        </p>
      `,
      showClass: {
        popup: 'custom-dialog-container',
      },
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "YES",
      cancelButtonText: "NO"
    }).then(async (result) => {
      if (result.isConfirmed) {
        const fData = { 'transaction_id': trID }
        const resp = await workshop_appointment_cancel_API(fData);
        if (resp) {
          Swal.fire({
            html: `
          <img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">Success</h4>
          <p class="text-center my-4 textcolor">
            <small class="textWhite">${resp.data.message}</small>
          </p>
        `,
            showClass: {
              popup: 'custom-dialog-container',
            },
            customClass: {
              confirmButton: 'custom-button custom-button-size modalbtn',
            },
            confirmButtonText: 'Continue',
          }).then((result) => {
            if (result.isConfirmed) {
              window.history.back();
            }
          })
        }
      }
    })
  }


  const cancelHandlerAPN = (trID) => {
    if (!trID) { errorAlert("The transaction ID is missing or invalid."); return; }

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(async (result) => {
      if (result.isConfirmed) {
        const resp = await workshop_appointment_cancel_API();
        if (resp) {

        }
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success"
        });
      }
    });

  }
  return (
    <Container>
      <section className="ClassesDetails">
        <div className="ClassesDetails">
          <div className="ScheduleHeading">
            <h5 className="classesHeading">
              <Link to="/workshop-schedule"><IoIosArrowBack />WORKSHOP DETAILS</Link>
            </h5>
          </div>
          {workData ?
            <div className="DetailsBox">
              {/* Foem Seaction  */}

              {edit === 0 ?
                <>
                  <Row style={{ justifyContent: "center" }}>
                    <Row className="mt-3">
                      <Col>
                        <div className="TextDetail">
                          <h5>Name</h5>
                          <div className='input-group'>
                            <input type='text'
                              //  disabled={!nameEdit}
                              // disabled={!nameEdit ? true : false}
                              value={workData.name}
                              ref={inputRef}
                              onChange={nameChangeHandler}
                              className='form-control'
                            />
                            {!nameEdit ? (
                              <div
                                className='input-group-append'
                                onClick={() => { setNameEdit(true); handleClick(); }}
                                style={{ cursor: 'pointer' }}
                              >
                                <span className='input-group-text' style={{ background: '#02793f', color: "white" }}>
                                  Edit
                                  {/* <i className='fas fa-edit' style={{ color: "white" }} /> */}
                                </span>
                              </div>
                            ) : (
                              <div
                                className='input-group-append'
                                onClick={() => { setNameEdit(false); nameUpdateHandler(); }}
                                style={{ cursor: 'pointer' }}
                              >
                                <span className='input-group-text' style={{ background: '#02793f', color: "white" }}>
                                  Update
                                  {/* <i className='fa fa-check' style={{ color: 'white' }} /> */}
                                </span>
                              </div>
                            )}
                          </div>

                          {/* <input type='password' />
                          <p>{workData.name ? workData.name : ""}</p> */}
                        </div>
                      </Col>
                      <Col>
                        <div className="TextDetail">
                          <h5>Visible to client</h5>
                          <SharedSelect
                            onChange={visibilityChange}
                            options={isVisibaleOption}
                            value={workData.visibility}
                          />
                          {/* <p>{workData.visibility ? workData.visibility : ""}</p> */}
                        </div>
                      </Col>
                    </Row>
                    <Row className='mt-3'>
                      <Col>
                        <div className="TextDetail">
                          <h5>Date</h5>
                          <p>{workData.schedule_date ? moment.unix(workData.schedule_date).format("YYYY-MM-DD") : ""}</p>

                        </div>
                      </Col>

                      <Col>
                        <div className="TextDetail">
                          <h5>Time</h5>
                          <p>{workData.start ? moment.unix(workData.start).format("hh:mm A") : ""} - {workData.end ? moment.unix(workData.end).format("hh:mm A") : ""}</p>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <div className="TextDetail">
                          <div style={{ display: 'flex' }}>
                            <h5>Capacity</h5>
                            <span className='capacityEdit' style={{ background: '#02793f', color: "white" }} onClick={() => setCpshow(true)}>
                              <FaEdit />
                            </span>
                          </div>
                          <p>{workData.used ? workData.used : 0}/{workData.capacity ? workData.capacity : ''}

                          </p>



                        </div>
                      </Col>
                      <Col>
                        <div className="TextDetail">
                          <h5>Location</h5>
                          <SharedSelect
                            onChange={locationChange}
                            options={locationss}
                            value={workData.location_id}
                          />
                          {/* <Link target='blank' to={workData.address ? workData.address : "#"}>
                            <p>{workData.location_name ? workData.location_name : ""}</p>
                          </Link> */}
                        </div>
                      </Col>
                    </Row>
                    <Row className='mt-3'>
                      <Col>
                        <div className="TextDetail">
                          <h5>Price</h5>
                          <p>$ {workData.price ? workData.price : ""}</p>
                        </div>
                      </Col>
                      <Col>
                        <div className="TextDetail">
                          <h5>Tax 1 Amount</h5>
                          <p>$ {workData.tax1_rate ? parseFloat(getProductPrice(workData.price, workData.tax1_rate)).toFixed(2) : ""}</p>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <div className="TextDetail">
                          <h5>Tax 2 Amount</h5>
                          <p>$ {workData.tax2_rate ? parseFloat(getProductPrice(workData.price, workData.tax2_rate)).toFixed(2) : ""}</p>
                        </div>
                      </Col>
                      <Col>
                        <div className="TextDetail">
                          {/* <h5>Cancel Current Workshop</h5> */}
                          <h5>Change Status</h5>
                          <SharedSelect
                            onChange={statusChange}
                            options={workshoop}
                            value={wrkst}
                          />
                          {/* <p>{workData.status}</p> */}
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-4 mb-4">
                      <Col>
                        <div className="TextDetail">
                          <div className='d-flex' style={{ justifyContent: 'space-between' }}>
                            <h5>Description</h5>
                            <div>
                              {!dEdit ?
                                <span className='own-edit-class' htmlFor="description" onClick={() => { setDEdit(true); }}>Edit</span>
                                :
                                <span className='own-edit-class' onClick={() => { setDEdit(false); descriptionUpdate(); }} > Update </span>
                              }
                            </div>
                          </div>

                          <textarea
                            class="form-control"
                            id="description"
                            name='description'
                            value={devalue}
                            onChange={(e) => setDevalue(e.target.value)}
                            readOnly={!dEdit}
                            rows="4"
                          ></textarea>
                          {/* <p>{workData.description}</p> */}
                        </div>
                      </Col>
                    </Row>
                  </Row>
                </>
                :
                <Row>
                  <div className="HeadDetail">
                    <h5>WORKSHOP</h5>
                    <i className="fas fa-check" style={{ fontSize: "20px", cursor: "pointer" }} onClick={() => setEdit(0)}></i>
                  </div>
                  <Row>
                    <Col>
                      <InputField
                        name={"name"}
                        lable={"name"}
                        type={"text"}
                        value={workData.name}
                      />
                    </Col>
                    <Col>
                      <InputField
                        name={"visible"}
                        lable={"Visible to client"}
                        type={"text"}
                        value={workData.visibility}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <InputField
                        name={"date"}
                        lable={"Date"}
                        type={"date"}
                      // value={workData.name}
                      />
                    </Col>
                    <Col>
                      <InputField
                        name={"stime"}
                        lable={"Start Time"}
                        type={"text"}
                        value={workData.Time}
                      />
                    </Col>
                  </Row>

                </Row>
              }
              <div className="TopBorderBox">
                <Row>
                  <Col className="text-left">
                    <h5 className="InstrDetail">Instructor Details</h5>
                  </Col>
                </Row>
              </div>
              <div className="TopBorderBox">
                <Row>
                  {workData.instructorDetails ? workData.instructorDetails.map((e, index) => (
                    <Col className="text-left" key={index}>
                      <div className='instructorinfo-circle'>
                        <img
                          src={e.profile_img}
                          style={{ width: "100%", height: "unset" }}
                          height="100%"
                        />
                      </div>
                      <span className="InstrDetail">
                        {e.name} {e.lastname}
                      </span>

                    </Col>
                  )) : ""}

                </Row>
              </div>

              <div className="TopBorderBox">
                <Row>
                  <Col className="text-left">
                    <h5 className="InstrDetail">Customer Details</h5>
                  </Col>
                  {customer_detail && customer_detail.length > 0 ?
                    <div>
                      <div class="row">
                        {customer_detail.map((cus) => (
                          <>
                            <div class="col-md-4 form-group">
                              <div class="pb-2 d-flex align-items-center px-3">
                                <div class="instructorinfo-circle">
                                  <img src={cus.profile_img ? cus.profile_img : logo} alt='' style={{ width: '100%', height: '100%' }} />
                                </div>
                                <p class="font-w-500 pl-3 mb-0">{cus.name} {cus.lastname}</p>
                              </div>
                              <Button variant="danger" className='w-50' onClick={() => cancelHandler(cus.transaction_id)} > Cancel </Button>
                            </div>

                          </>
                        ))}
                      </div>
                    </div>
                    :
                    <p>No Customer Found</p>}
                </Row>
              </div>
              <div className="TopBorderBox">
                <Row>
                  <Col className="text-left">
                    <InputField
                      lable={"Add Customer"}
                      type="search"
                      placeholder="Search"
                      value={serchvalue}
                      onChange={searchHandler}
                    />
                  </Col>
                  <Col></Col>
                </Row>
                <Row>
                  {clData ?
                    <div className="SearchClientPanels mt-1">
                      <WorkShopBox data={clData} workData={workData} setLoder={setLoder}
                        setWorkData={workshopDataHandler}
                        searchReset={searchReset}
                        setSerchvalue={setSerchvalueHandler}
                        scheduleWorkshopList={scheduleWorkshopList} />
                    </div>
                    : <div className="SearchClientPanels mt-1">No Record Found</div>
                  }
                </Row>


              </div>
            </div>
            :
            <Row className='text-center'>
              <Col className='mt-5'>
                <h4>No Record Found</h4>
              </Col>
            </Row>
          }
        </div>
      </section >
      <CapacityModal show={cpshow} handleClose={() => cpHandleClose()} nameUpdateHandler={nameUpdateHandler} capacityHandler={capacityHandler} value={workData && workData.capacity} />
    </Container >

  )
}
