import Swal from "sweetalert2";
import http from "../../http-comman"
import logo from "../../Images/logo_white_web.png"


export const Aboutus = async () => {
    try {
        const responce = await http.get("/Welcome/web_content/about-us");
        if (responce) {
            return (responce.data);
        }

    } catch (error) {
        alert(error);
    }
}

export const PrivacyPolicy = async () => {
    try {
        const resp = await http.get("/Welcome/web_content/privacy-policies");
        if (resp) {
            return (resp.data);
        }
    } catch (error) {
        alert(error);
    }
}

export const termsCondition = async () => {
    try {
        const resp = await http.get("/Welcome/web_content/term-and-condition");
        if (resp) {
            return (resp.data);
        }
    } catch (error) {
        alert(error);
    }
}

export const help_contact = async (data) => {
    try {
        const resp = await http.post("/webservices/api/help_contact",data);
        if (resp.data.status===1) {
            return resp;
        }else{
            Swal.fire({
                html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
                <h4 class="title text-uppercase text-center mt-4 textWhite">
                Error !!!
              </h4>
              <p class="text-center my-4 textcolor">
              <small class="textWhite">
             ${resp.data.message} </small></p>
              `,
                showClass: {
                  popup: 'custom-dialog-container',
                },
                customClass: {
                  confirmButton: 'custom-button custom-button-size modalbtn',
                },
              });
        }
    } catch (error) {
        alert(error);
    }
}
// export const getProfile = async () => {
//   try {
//     const responce = await http.get("web/studio/get_business_profile");
//     if (responce.data.status == 1) {
//       return responce;
//     } else alert(responce.data.message);
//   } catch (error) {
//     alert(error);
//   }
// }

// export const getUserProfileInfo = async () => {
//   try {
//     const responce = await http.get("web/studio/get_profile");
//     if (responce.data.status == 1) {
//       return responce;
//     } else alert(responce.data.message);
//   } catch (error) {
//     alert(error);
//   }
// }


// export const getClass_list = async (data) => {
//   try {
//     const responce = await http.post("web/studio/today_class_list", data);
//     if(responce){
//       if (responce.data.status == 1) {
//         return responce;
//       } else alert(responce.data.message);
//     }
//   } catch (error) {
//     alert(error);
//   }
// }

// export const getClassDetails = async (data) => {
//   try {
//     const responce = await http.post("web/studio/class_details", data);
//     if (responce.data.status == 1) {
//       return responce;
//     } else alert(responce.data.message);
//   } catch (error) {
//     alert(error);
//   }
// }













