import React, { useState, useEffect } from 'react'
import { Row, Col, Card, InputGroup } from "react-bootstrap";

export const PurchaseMembershipCard = ({ pass, setValue }) => {
    const PurchasecardDetail = pass;
// console.log("Passes",pass);
    const [selectedPasses, setSelectedPasses] = useState([]);
  
    const handleCheckboxChange = (event, passId) => {
      const isChecked = event.target.checked;
      if (isChecked) {
        setSelectedPasses((prevSelectedPasses) => [
          ...prevSelectedPasses,
          passId,
        ]);
      } else {
        setSelectedPasses((prevSelectedPasses) =>
          prevSelectedPasses.filter((selectedPass) => selectedPass !== passId)
        );
      }
    };
  
    useEffect(() => {
      if (setValue) {
        setValue(selectedPasses);
      }
    }, [selectedPasses]);
  
  return (
    <>
     {PurchasecardDetail && PurchasecardDetail.length > 0 ? (
        <Row>
          {PurchasecardDetail.map((value, index) => (
            <Col lg={4} md={4} sm={12} key={index}>
              <Card className="blueback-pass" key={index}>
                <div className="cardImg">
                  <Card.Img variant="top" src={value.memberShipImg}  className='mb-0'/>
                  <p>{value.startDate}</p>
                  {/* <p>{value.validDate}</p> */}
                </div>
                <Card.Body>
                  <Card.Title>{value.cardTitle} </Card.Title>
                  <Card.Text>
                    {value.price} <small>{value.tax}</small>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      ) : (
        ""
      )}
    </>
  )
}
