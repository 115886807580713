import React from 'react'
import './Myprfile.css';
import { InnerHeader } from '../../Components/inner-header/InnerHeader1'

import { Innerfooter } from '../../Components/inner-footer/Innerfooter';
import { staff_changepassword, staff_get_profile, staff_get_skills, staff_profile_update } from '../../../Static_Services/Staff_Services';
import { useState } from 'react';
import { useEffect } from 'react';
import { InputField } from '../../../sharedComponents/InputField';
import { SharedMultiSelect } from '../../../sharedComponents/SharedMultiSelect';
import { errorAlert, successAlert } from '../../../Static_Services/Alertmsg';
import { Modal } from 'react-bootstrap';
import { MyDatePicker } from '../../../sharedComponents/MyDatePicker';
import moment from 'moment';


export const MyProfile = () => {
  const [profileDetail, setProfileDetail] = useState();
  const [skillsOpt, setSkillsOpt] = useState([]);
  const [inputData, setInputData] = useState({
    "name": '', "lastname": '', "email": '', "mobile": '', "date_of_birth": '', "address": '',
    "country": '', "state": '', "city": "", "experience": '', "registration": "", "about": "", "radio": "", "skill_id": ""
  })

  const [error, setError] = useState({
    "name": '', "lastname": '', "email": '', "mobile": '', "date_of_birth": '', "address": '',
    "country": '', "state": '', "city": "", "experience": '', "registration": "", "about": "", "radio": "",
  })

  const [passerror, setPasserror] = useState({ old_password: "", new_password: "", conf_password: "", });
  const [updatepass, setUpdatepassPass] = useState({ old_password: "", new_password: "", conf_password: "", })

  const [skillsdata, setSkillsdata] = useState();
  const [passModal, setPassModal] = useState(false);

  const skillHandler = (skils) => { setSkillsdata(skils); }



  const getSkill = async () => {
    const resp = await staff_get_skills();
    if (resp) {
      const array = resp.data.data;
      if (array.length > 0) {
        const opt = [];
        array.forEach(element => {
          opt.push({
            id: element.id, label: element.name
          })
          setSkillsOpt(opt);
        });
      }
    }
  }



  const getData = async () => {
    const resp = await staff_get_profile();
    if (resp) {
      const ProfileDetail = resp.data.data;
      setProfileDetail(resp.data.data);
      setInputData({
        name: ProfileDetail.name,
        lastname: ProfileDetail.lastname,
        email: ProfileDetail.email,
        mobile: ProfileDetail.mobile,
        date_of_birth: ProfileDetail.date_of_birth,
        address: ProfileDetail.address,
        country: ProfileDetail.country,
        state: ProfileDetail.state,
        city: ProfileDetail.city,
        experience: ProfileDetail.experience,
        registration: ProfileDetail.registration,
        about: ProfileDetail.about,
        radio: ProfileDetail.gender,
        skill_id: ProfileDetail.skill_id,
      })

    }
  }


  useEffect(() => { getSkill(); }, [])

  useEffect(() => { getData(); }, [skillsOpt])


  const [selectedFile, setSelectedFile] = useState(null);
  const [imgURL, setImgURL] = useState(null);


  const getUploadFileDetail = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      setSelectedFile(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        setImgURL(event.target.result);
      };
    }
  };

  // const getUploadFileDetail = (e) => {
  // alert('Hello');
  // const images = e.target.image.files[0];
  // console.log("images", e.target.files[0]);
  // }

  const updateProfile = async (e) => {
    e.preventDefault();
    let isValid = 1;
    //const fData = new FormData(e.target); // Create a FormData object from the form

    const fData = new FormData(e.target); // Create a FormData object from the form
    const formValues = Object.fromEntries(fData.entries());


    const name = inputData.name;
    const lastname = inputData.lastname;
    const email = inputData.email;
    const mobile = inputData.mobile;
    const date_of_birth = formValues.date_of_birth;

    const address = inputData.address;
    const country = inputData.country;
    const state = inputData.state;
    const city = inputData.city;
    const experience = inputData.experience;
    const registration = inputData.registration;
    const about = inputData.about;
    const gender = inputData.radio;
    const skills = skillsdata;

    let finalSkils = ''; // Initialize as an empty string
    if (skills.length > 0) {
      skills.forEach((element, index) => {
        if (index === 0) {
          finalSkils = element.id.toString();
        } else if (index === skills.length - 1) {
          finalSkils = finalSkils + ',' + element.id.toString();
        } else {
          finalSkils = finalSkils + ',' + element.id.toString();
        }
      });
    }

    if (!name) { setError((pre) => ({ ...pre, name: "First Name is required" })); isValid = 2 };
    if (!lastname) { setError((pre) => ({ ...pre, lastname: "Last Name is required" })); isValid = 3 };
    if (!email) { setError((pre) => ({ ...pre, email: "Required" })); isValid = 4 };
    if (!mobile) { setError((pre) => ({ ...pre, mobile: "Required" })); isValid = 5 };
    if (!date_of_birth) { setError((pre) => ({ ...pre, date_of_birth: "Date of Birth is required" })); isValid = 6 };
    if (!address) { setError((pre) => ({ ...pre, address: "Address is required" })); isValid = 7 };
    if (!country) { setError((pre) => ({ ...pre, country: "Country is required" })); isValid = 8 };
    if (!state) { setError((pre) => ({ ...pre, state: "State is required" })); isValid = 9 };
    if (!city) { setError((pre) => ({ ...pre, city: "City is required" })); isValid = 10 };
    if (!experience) { setError((pre) => ({ ...pre, experience: "Experience is required" })); isValid = 11 };
    // if (!registration) { setError((pre) => ({ ...pre, registration: "Required" })); isValid = 0 };
    if (!about) { setError((pre) => ({ ...pre, about: "About your self is required" })); isValid = 12 };
    if (!gender) { setError((pre) => ({ ...pre, gender: "Required" })); isValid = 13 };
    if (!finalSkils) { setError((pre) => ({ ...pre, finalSkils: "Required" })); errorAlert('Skills Required'); isValid = 14 };

    if (isValid === 1) {
      var formData = new FormData();
      formData.append('password', profileDetail.password);
      formData.append('name', name);
      formData.append('mobile', mobile);
      formData.append('lastname', lastname);
      formData.append('gender', gender);
      formData.append('date_of_birth', date_of_birth);
      formData.append('address', address);
      formData.append('city', city);
      formData.append('state', state);
      formData.append('country', country);
      formData.append('about', about);
      formData.append('zipcode', profileDetail.zipcode);
      formData.append('lat', profileDetail.lat);
      formData.append('lang', profileDetail.lang);
      formData.append('street', profileDetail.street);
      // formData.append('image', profileDetail.profile_img);
      formData.append('role_id', profileDetail.role_id);
      formData.append('device_type', '');
      formData.append('device_token', '');
      formData.append(
        'emergency_contact_person',
        profileDetail.emergency_contact_person
      );
      formData.append(
        'emergency_country_code',
        profileDetail.emergency_country_code
      );
      formData.append(
        'emergency_contact_no',
        profileDetail.emergency_contact_no
      );
      formData.append('skills', finalSkils);
      formData.append('experience', experience);
      formData.append('registration', registration);

      if (selectedFile) {
        formData.append('image', selectedFile);
      }

      const resp = await staff_profile_update(formData);
      if (resp) {
        successAlert(resp.data.message);
        localStorage.setItem("userDetail", JSON.stringify(resp.data.data));//w_ParentDetail
        localStorage.setItem('w_loginDetail', JSON.stringify(resp.data.data));
        getData();
      }


      // const product_name = formValues.product_name;
      // const product_price = formValues.product_price;
      // const product_desc = formValues.product_desc;
      // const tax1 = formValues.tax1;
      // const tax1_rate = formValues.tax1_rate ? formValues.tax1_rate : "";
      // const tax2 = formValues.tax2;
      // const tax2_rate = formValues.tax2_rate ? formValues.tax2_rate : "";
      // const quantity = formValues.quantity;
      // const product_images = e.target.image.files[0];
    }

  }

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setInputData((pre) => ({ ...pre, [name]: value }));
    setError((pre) => ({ ...pre, [name]: '' }));
  }


  const UpdateHandler = (e) => {
    const { name, value } = e.target;
    setUpdatepassPass((pre) => ({ ...pre, [name]: value }));
    setPasserror((pre) => ({ ...pre, [name]: '' }));
  }

  const passHandler = async (e) => {
    e.preventDefault();
    let isValid = 1;
    const old_password = updatepass.old_password;
    const new_password = updatepass.new_password;
    const conf_password = updatepass.conf_password;
    if (!old_password) {
      isValid = 2;
      setPasserror((pre) => ({ ...pre, old_password: 'Password is required' }));
    } else if (profileDetail.password !== old_password) {
      isValid = 3;
      errorAlert('Password not match');
    }

    if (!new_password) {
      isValid = 4;
      setPasserror((pre) => ({ ...pre, new_password: 'Password is required' }));
    }
    if (!conf_password) {
      isValid = 4;
      setPasserror((pre) => ({ ...pre, conf_password: 'Confirm Password is required' }));
    }

    if (new_password !== conf_password) {
      isValid = 5;
      setPasserror((pre) => ({ ...pre, conf_password: 'Password And Confirm Password not match' }));
    }

    if (isValid === 1) {
      var detail = {
        old_password: old_password,
        new_password: conf_password,
      };
      const resp = await staff_changepassword(detail);
      if (resp) {
        successAlert(resp.data.message);
        setPassModal(false);
      }
    }
  }

  return (
    <>

      <InnerHeader />
      {/* <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' ,fullScreenBackdrop:true}"></ngx-loading> */}
      <div className="body-grey">
        {/* <!-- Profile Section --> */}
        <div className="profile-section py-5">
          <div className="container">
            <div className="white-bg p-4 p-md-5 border-radius6 box-shadow1">
              {profileDetail &&
                <form onSubmit={updateProfile} autoComplete='off' encType="multipart/form-data" >
                  <div className="row">
                    <div className="col-12 pb-4">
                      <div className="profile-details">
                        <div className="row align-items-center">
                          <div className="col-12 col-md-3">


                            <div className="profile-detail-image">
                              <input type="file" id="file1" name="image" accept="image/*"
                                onChange={getUploadFileDetail}

                              />
                              {/* // *ngIf="!imgURL" */}

                              {imgURL &&
                                <div className="userprofile_img box-shadow1"><img src={imgURL}
                                  className="img-fluid border-radius100 w-100" style={{ height: "200px" }} />
                                  <a href="Javascript:void(0);" className="brand-color1 font-14 edit-profileimg box-shadow1"
                                    id="upfile1" style={{ cursor: "pointer" }}> <i className="fas fa-camera"></i>
                                  </a>
                                </div>
                              }

                              {!imgURL &&
                                <div className="userprofile_img box-shadow1"><img src={profileDetail.profile_img}
                                  className="img-fluid border-radius100 w-100" style={{ height: "200px" }} />
                                  <a href="Javascript:void(0);" className="brand-color1 font-14 edit-profileimg box-shadow1"
                                    id="upfile1" style={{ cursor: "pointer" }}> <i className="fas fa-camera"></i>
                                  </a>
                                </div>
                              }
                            </div>
                          </div>
                          <div className="col-12 col-md-9">
                            <div className="profile-detail-text">
                              <h2 className="title dark-color font-24 mb-2 text-capitalize">{profileDetail.name}
                                {profileDetail.lastname}</h2>
                              <p className="brand-color3"> {profileDetail.email}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- Client Details --> */}

                    <div className="col-12 col-sm-6 mt-2 mt-md-3">
                      <div className="form-group">
                        <p className="black-color font-w-900 text-left font-18 mb-1 pl-1">First Name </p>
                        <input id="" type="text" name="name" className="form-control text-capitalize" value={inputData.name}
                          placeholder="Enter your name" formControlName="name" onChange={changeHandler} />
                        <small className="text-danger">{error.name}</small>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 mt-2 mt-md-3">
                      <div className="form-group">
                        <p className="black-color font-w-900 text-left font-18 mb-1 pl-1">Last Name </p>
                        <input id="" type="text" name="lastname" className="form-control text-capitalize"
                          value={inputData.lastname} onChange={changeHandler}
                          placeholder="Enter your name" formControlName="lastname" />
                        <small className="text-danger">{error.lastname}</small>
                      </div>
                    </div>
                    {/* <!-- Name --> */}

                    <div className="col-12 col-sm-6 mt-2 mt-md-3">
                      <div className="form-group">
                        <p className="black-color font-w-900 text-left font-18 mb-1 pl-1"> Email </p>
                        <input id="" type="email" name="email" className="form-control" value={inputData.email} onChange={changeHandler}
                          formControlName="email" style={{ backgroundColor: "white" }} placeholder="Enter your e-mail" readonly />

                      </div>
                    </div>
                    {/* <!-- Email --> */}
                    <div className="col-12 col-sm-6 mt-2 mt-md-3">
                      <div className="form-group">
                        <p className="black-color font-w-900 text-left font-18 mb-1 pl-1"> Phone </p>
                        <input id="" type="text" name="mobile" className="form-control" value={inputData.mobile} onChange={changeHandler}
                          formControlName="mobile" style={{ backgroundColor: "white" }} placeholder="Enter your phone no."
                        />
                      </div>
                    </div>
                    {/* <!-- Phone --> */}
                    <div className="col-12 col-sm-6 mt-2 mt-md-3">
                      <div className="form-group">
                        <p className="black-color font-w-900 text-left font-18 mb-1 pl-1"> Date Of Birth </p>
{/* 
                        <MyDatePicker
                          name="date_of_birth"
                          error={error.date_of_birth}
                          onChange={changeHandler}
                         value={inputData && inputData.date_of_birth ? inputData.date_of_birth : ""}
                         DatepickerLabel={"Date Of Birth"}
                        /> */}

                        <input type="date" name="date_of_birth" className="form-control"
                          onChange={changeHandler}
                          value={inputData && inputData.date_of_birth}
                          style={{ backgroundColor: "white" }} placeholder="Enter your Date Of Birth" />
                        {/* <InputField
                          type="date"
                          name={'date_of_birth'}
                          onChange={changeHandler}
                          value={inputData.date_of_birth}
                          error={error.date_of_birth}
                          required
                        /> */}
                      </div>
                    </div>
                    {/* <!-- DOB --> */}

                    <div className="col-12 col-sm-6 mt-2 mt-md-3">
                      <div className="form-group">
                        <p className="black-color font-w-900 text-left font-18 mb-1 pl-1"> Address </p>
                        <input id="" name="address" className="form-control" value={inputData.address} onChange={changeHandler}
                          placeholder="Enter your address" autocomplete="off" ngx-google-places-autocomplete
                          formControlName="address" />
                        <small className="text-danger">{error.address}</small>
                      </div>
                    </div>
                    {/* <!-- Address --> */}

                    <div className="col-12 col-sm-6 mt-2 mt-md-3">
                      <div className="form-group">
                        <p className="black-color font-w-900 text-left font-18 mb-1 pl-1"> Country </p>
                        <input id="" name="country" className="form-control" value={inputData.country} onChange={changeHandler}
                          placeholder="Enter your country" formControlName="country" />
                        <small className="text-danger">{error.country}</small>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 mt-2 mt-md-3">
                      <div className="form-group">
                        <p className="black-color font-w-900 text-left font-18 mb-1 pl-1"> State/Province </p>
                        <input id="" name="state" className="form-control" value={inputData.state} onChange={changeHandler}
                          placeholder="Enter your state/province" formControlName="state" />
                        <small className="text-danger">{error.state}</small>
                      </div>
                    </div>
                    {/* <!-- State/Province --> */}
                    <div className="col-12 col-sm-6 mt-2 mt-md-3">
                      <div className="form-group">
                        <p className="black-color font-w-900 text-left font-18 mb-1 pl-1"> City </p>
                        <input id="" name="city" className="form-control" value={inputData.city} onChange={changeHandler}
                          placeholder="Enter your city" formControlName="city" />
                        <small className="text-danger">{error.city}</small>
                      </div>
                    </div>
                    {/* <!-- City --> */}
                    <div className="col-12 col-sm-6 mt-2 mt-md-3">
                      <div className="form-group">
                        <p className="black-color font-w-900 text-left font-18 mb-1 pl-1"> Experience </p>
                        <input type="text" value={inputData.experience} id="" name="experience" onChange={changeHandler}
                          className="form-control" placeholder="Enter your experience" />
                        <small className="text-danger">{error.experience}</small>
                      </div>
                    </div>

                    <div className="col-12 col-sm-6 mt-2 mt-md-3">
                      <div className="form-group">
                        <p className="black-color font-w-900 text-left font-18 mb-1 pl-1"> Registration No. </p>
                        <input type="text" name="registration" className="form-control" value={inputData.registration}
                          placeholder="Enter your registration" formControlName="registration" onChange={changeHandler} />
                        <small className="text-danger">{error.registration}</small>
                      </div>
                    </div>

                    <div className="col-12 col-sm-6 mt-2 mt-md-3">
                      <div className="form-group profile-gender mb-0">
                        <p className="black-color font-w-900 text-left font-18 mb-1 pl-1"> Gender </p>
                        <div className="profile-gender-button">
                          <input type='radio' id='male' value="male" name='radio' checked={inputData.radio === 'male'}
                            onChange={changeHandler} />
                          <label for='male'>Male</label>

                          <input type='radio' id='female' value="female" name='radio' checked={inputData.radio === 'female'}
                            onChange={changeHandler} />
                          <label for='female'>Female</label>

                          <input type='radio' id='other' value="other" name='radio' checked={inputData.radio === 'other'}
                            onChange={changeHandler} />
                          <label for='other'>Other</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 mt-2 mt-md-3">
                      <div className="form-group">
                        <p className="black-color font-w-900 text-left font-18 mb-1 pl-1"> Skills </p>
                        <SharedMultiSelect
                          name="skills"
                          options={skillsOpt}
                          setSkillsdata={skillHandler}
                          value={inputData && inputData.skill_id ? inputData.skill_id : ""}
                        />
                        <small className="text-danger">{error.skills}</small>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 mt-2 mt-md-3">
                      <div className="form-group">
                        <p className="black-color font-w-900 text-left font-18 mb-1 pl-1"> About Your Self </p>
                        <input type="text" name="about" className="form-control" value={inputData.about} onChange={changeHandler}
                          maxlength="250" placeholder="About your self" formControlName="about" />
                        <small className="text-danger">{error.about}</small>
                      </div>
                    </div>



                    <div className="col-12 col-sm-6 mt-2 mt-md-3 text-center password-align" style={{ background: "#e4e7ec", alignItems: 'center', justifyContent: "center", display: 'flex' }} onClick={() => setPassModal(true)}>
                      <a href="Javascript:void(0);" id="changepass" data-toggle="modal" data-target="#profileChangepass"
                        data-backdrop="static" data-keyboard="false" className="brand-color1 font-14"> Change Password? </a>
                    </div>
                    <div className="col-12 col-sm-6 mt-2 mt-md-3">
                      <button type="submit" className="btn btn-blue-grad text-uppercase w-100"> Update </button>
                    </div>
                    {/* <div className="col-12 col-sm-4 mt-2 mt-md-3"></div> */}
                  </div>
                </form>
              }
            </div>
          </div>
          {/* <!-- end of container --> */}
        </div>
        {/* <!-- End of Profile Section --> */}

      </div>
      <Innerfooter />



      {/* // <!-- Modal Popup (Change - Password ) --> */}
      <Modal show={passModal} className="modal fade">
        <div className="modal-dialog modal-dialog-centered" id="ownModalWidth" role="document">
          <div className="modal-content">

            <div className="modal-body white-bg box-shadow1 border-radius6 p-4 p-md-5">

              <div className="modal-logo text-center pb-3 pb-sm-5">
                <img src="clientassets/img/logo_green_small.png" />
              </div>

              <div className="modal-bottom-content blue-gradient border-radius6 py-3 py-sm-5 px-2">
                <form onSubmit={passHandler}>
                  <div className="form-group">
                    <p className="text-white text-left font-14 mb-0 ml-3"> Old Password </p>
                    <input id="password-field" type="password" className="form-control" name="old_password" value={updatepass.old_password}
                      formControlName="old_password" onChange={UpdateHandler} />
                    <span toggle="#password-field"
                      className="fa fa-fw fa-eye field-icon toggle-password brand-color3"></span>
                    <small className="text-danger">{passerror.old_password}</small>
                    {/* <div className="invalid-feedback">
                      <div >Old Password is required</div>
                      <div >Old Password length must be 8 with at least one special
                        character, one capital letter, one small letter and one number.</div>
                    </div> */}
                  </div>
                  {/* <!-- Old Password --> */}

                  <div className="form-group">
                    <input id="" type="password" value={updatepass.new_password} className="form-control" placeholder="New Password" name='new_password'
                      formControlName="new_password" onChange={UpdateHandler} />
                    <small className="text-danger">{passerror.new_password}</small>
                    {/* <div className="invalid-feedback">
                      <div>New Password is required</div>
                      <div>New Password length must be 8 with at least one special
                        character, one capital letter, one small letter and one number.</div>
                    </div> */}
                  </div>
                  {/* <!-- New Password --> */}

                  <div className="form-group">
                    <input id="" type="password" value={updatepass.conf_password} className="form-control" placeholder="Confirm Password"
                      formControlName="password" name='conf_password' onChange={UpdateHandler} />
                    <small className="text-danger">{passerror.conf_password}</small>
                    {/* <div className="invalid-feedback">
                      <div>Confirm Password is required</div>
                      <div>Confirm Password length must be 8 with at least one special
                        character, one capital letter, one small letter and one number.</div>
                    </div> */}
                  </div>
                  {/* !-- Confirm Password --> */}

                  <div className="form-group px-3 pt-0 pt-sm-4 mb-0 mb-sm-2">
                    <button type="submit" className="btn text-uppercase w-100 mt-3">  update </button>
                    <button type="button" data-dismiss="modal" id="btn_close" className="btn text-uppercase w-100 mt-3"
                      data-backdrop="static" data-keyboard="false" onClick={() => setPassModal(false)}> close </button>

                  </div>
                </form>

              </div>
              {/* <!-- modal-bottom-content --> */}

            </div>
            {/* <!-- end of modal-body --> */}

          </div>
        </div>
      </Modal>
      {/* <!-- End of Modal Popup (Change - Password ) --> */}

      {/* <!-- Modal Popup (Change - Password ) --> */}
      <Modal show={false} className="modal fade">
        <div className="modal-dialog modal-dialog-centered" id="ownModalWidth" role="document">
          <div className="modal-content">
            <button type="button" className="close text-right mr-2" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="modal-body white-bg box-shadow1 border-radius6 p-4 p-md-5">

              <div className="col-12 col-lg-12 mt-5 mb-3 mt-lg-0 px-0">
                <p className="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1 mb-2">Previous Requset Details</p>

                <div className="client-buy-process border-radius6 box-shadow1 white-bg" >

                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <th>Comment </th>
                        <th width="20%">Comment By</th>
                        <th width="30%">Date</th>
                      </thead>
                      <tr>
                        <td> {'items.comment'}</td>
                        <td className="text-capitalize" width="20%" >{'items.name'} {'items.lastname'}</td>
                        <td className="text-capitalize" width="20%" >{'items.name'} {'items.lastname'}
                          Admin</td>

                        <td width="30%">{'items.date_new'} , {'items.time'}</td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div className="client-buy-process border-radius6" >
                  <h3 className="text-center">No Privious Request</h3>
                </div>
              </div>
              <form >
                <div className="row mt-2">
                  <div className="col-12">
                    <div className="form-group">
                      <p className="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1"> Change Request </p>
                      <textarea className="form-control" placeholder="Reason for shift Change Request "
                        onkeyup="this.value=this.value.replace(/^\s+/g, '')" formControlName="changerequest"
                        maxlength="200"></textarea>
                      <div className="invalid-feedback">
                        <div >Change Request is required</div>
                        <div>Reason's max length is 200</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4"></div>
                  <div className="col-4 d-flex">
                    <button type="button" className="btn btn-blue-grad text-uppercase mt-3" > <span className="spinner-grow spinner-grow-sm" role="status"
                      aria-hidden="true"></span> Submit </button>
                    <button type="button" data-dismiss="modal" id="btn_close"
                      className="btn btn-blue-grad text-uppercase mt-3 ml-5" data-backdrop="static" data-keyboard="false"
                    > close </button>
                  </div>
                  <div className="col-4"></div>
                </div>
              </form>
            </div>
            {/* <!-- end of modal-body --> */}

          </div>
        </div>
      </Modal>
      {/* <!-- End of Modal Popup (Change - Password ) --> */}


      {/* <!-- shift view hide code --> */}

      {/* <!-- <div className="row">
  <div className="col-6">
    <p className="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1">Shift Details</p>
  </div>
  <div className="col-6 text-right">
    <div className="form-group">
      <div className="std-detail-select position-relative w-100">
        <button className="colorchnage mr-2" (click)="decreaseDate()"><i className="fas fa-chevron-left"></i></button>
        <mat-form-field >
        <input matInput [matDatepicker]="passdate"  [(ngModel)]="changeDate"  name="changeDate"  (click)="passdate.open()" (focus)="passdate.open()"  style="color: black;" readonly (dateChange)="upcoming_shift()" >
        <mat-datepicker-toggle matSuffix [for]="passdate" ></mat-datepicker-toggle>
        <mat-datepicker #passdate ></mat-datepicker>
    </mat-form-field>
    <button className="colorchnage ml-2" (click)="increaseDate()" ><i className="fas fa-chevron-right"></i></button>
      </div>

    </div>

  </div>
</div>
<div className="row" *ngIf="shiftDetail.length != 0">
  <div className="col-12 col-md-4 mb-4" *ngFor="let items of shiftDetail">
    <a href="Javascript:void(0);" (click)="changeSelection(items)"  >
      <div className="activity-card box-shadow1 border-radius6 white-bg p-2">
        <div className="row">
          <div className="col-3 col-sm-3 col-md-12 col-lg-3">
            <div className="activity-card-logo">
              <img src="{{items.business_image}}" className="box-shadow1 new-log-img">
            </div>
          </div>
          <div className="col-9 col-sm-9 col-md-12 col-lg-9 pl-0 pl-sm-0 pl-md-3 pl-lg-0">
            <div className="row">
              <div className="col-7 col-md-12 col-lg-7 mt-0 mt-md-4 mt-lg-0 pl-0 pl-sm-0 pl-md-3 pl-lg-0">
                <div className="activity-card-details text-left">
                  <h5 className="classNamename-title black-color mb-0">{{items.business_name}} </h5>
                  <ul  className="d-flex align-items-center py-2">
                    <li> <h5 className="black-color mb-0 classNamename-title">{{items.shift_name}}</h5> </li>
                  </ul>

                </div>
              </div>
              <div className="col-5 col-md-12 col-lg-5 pr-1">
                <div className="text-right pt-1">
                  <p className="black-color font-w-100  mr-2 mb-1 paratitle">{{items.week_name}} </p>
                </div>
                <div className="text-right pt-1">
                  <p className="black-color font-w-100  mr-2 mb-1 paratitle">{{items.shift_date_new}} </p>
                </div>
              </div>
            </div>

            <div className="row pt-2">
              <div className="col-5 col-sm-5 col-md-5 col-lg-5 px-0 border-right">
                <div className="text-left">
                  <p className="paratitle black-color mb-0"> Start Time </p>
                  <h5 className="classNamename-title black-color mb-0"> {{items.start_time_new}} </h5>
                </div>
              </div>
              <div className="col-5 col-sm-5 col-md-5 col-lg-5 px-3 border-right">
                <div className="text-left">
                  <p className="paratitle black-color mb-0"> End Time </p>
                  <h5 className="classNamename-title black-color mb-0"> {{items.end_time_new}} </h5>
                </div>
              </div>
              <div className="col-2 col-sm-2 col-md-2 col-lg-2 d-flex justify-content-end">
                <div className="text-left">
                 <a id="changerequest" data-toggle="modal" data-target="#shiftChange"  data-backdrop="static" data-keyboard="false" ><i className="fas fa-pencil-alt"></i> </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>
</div>
<div className="client-buy-process border-radius6" *ngIf="shiftDetail.length == 0">
  <h3 className="text-center">No Shift Assigned</h3>
</div> --> */}

    </>
  )
}
