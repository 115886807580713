import React, { useEffect, useState } from "react";
import Sidebar from "../../comman_page/Sidebar";
import { HeaderDashboard } from "../../comman_page/HeaderDashboard";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { Footer } from "../../../../../public/containers/footer/Footer";
import { DataTable } from "../../../../../sharedComponents/DataTable";
import SharedTabs from "../../../../../sharedComponents/SharedTabs";

import { UploadBulkData } from "../../../../../sharedComponents/UploadBulkData";
import { LiveSearch } from "../../../../../sharedComponents/LiveSearch";
import { client_list } from "../../../../../Studio-Services/Studio_Services";
import { Link } from "react-router-dom";

import { AddClientform } from "../../../../../sharedComponents/AddClientform";

export const ListClients = () => {
  const [table, setTable] = useState([]);
  const [tableList, setTableList] = useState([]);
  const [loder,setLoder] = useState(false);

  const get_clinet_List = async () => {
    const data = { pageid: 1 };
    const resp = await client_list(data);
    if (resp) {
      const array = [];
      (resp.data).forEach((element, index) => {
        array.push({
          key: index,
          fname: element.first_name,
          lname: element.last_name,
          email: element.email,
          phone: element.mobile,
          action: (
            <Link
              to="/edit-client"
              style={{ textDecoration: "none", color: "black" }}
              state={{ id: element.id }}
            >
              Edit
            </Link>
          ),
        });
      });
      setTable(array);
      setTableList(array);
    }
  };

  useEffect(() => {
    get_clinet_List();
  }, []);


  const tabs = [
    {
      eventKey: "home",
      title: "Client Profile",
      content: <AddClientform get_clinet_List={get_clinet_List} setLoder={setLoder} />,
    },
    {
      eventKey: "profile",
      title: "Upload Bulk Data",
      content: <UploadBulkData />,
    },
  ];

  const filterData = (data) => {
    const fdata = table.filter((item) => {
      return data.toLowerCase() === "" ? item : item.fname.toLowerCase().includes(data);
    });

    return fdata;
  };

  const searchHandler = (e) => {
    const value = e.target.value;
    if(value){
      const resp = filterData(value);
      setTableList(resp);
    }else{
      setTableList(table);
    }
  }

  const columns = [
    { dataField: "fname", text: "First Name" },
    { dataField: "lname", text: "Last Name" },
    { dataField: "email", text: "Email" },
    { dataField: "phone", text: "Cell" },
    { dataField: "action", text: "Action" },
  ];

  return (
    <>
     {loder && <div className="MainLoader"> <Spinner animation="border" /> </div>}
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col>
              <div className="RightSide">
                <HeaderDashboard />
                <section className="addClient innerContent">
                  <div className="AddClientContent">
                    <Container>
                      <h4>List Your Clients</h4>
                      <p>
                        Clients are your customers. You can add to your list
                        active and inactive clients here and they will sync with
                        our email marketing partner, show up in client list, and
                        allow them to see and sign up for classes, workshops, or
                        services.
                      </p>
                      <Row className="mt-3">
                        <Col>
                          <Row className="my-4 mx-3">
                            <Col>
                              <h5>CLIENT LIST</h5>
                            </Col>
                            <Col>
                              <LiveSearch
                                placeholder={
                                  "Search by first name or last name"
                                }
                                className={"w-100 instructorSearch"}
                                onChange={searchHandler}
                              />
                            </Col>
                          </Row>
                          <DataTable data={tableList} columns={columns} />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <SharedTabs tabs={tabs} />
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </section>
                <Footer />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
