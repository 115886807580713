import React from "react";
import Sidebar from "../../comman_page/Sidebar";
import { HeaderDashboard } from "../../comman_page/HeaderDashboard";
import { Footer } from "../../../../../public/containers/footer/Footer";
import { Row, Col, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export const Marketingservices = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col>
              <HeaderDashboard />
              <div className="RightSide">
               <Container>
               <section className="contentSections instructorclasses-sec bg-white py-0 relative mt-0">
                  <div className="marketingtab-sec">
                    <section className="banner-section d-flex align-items-center">
                      <div className="container relative">
                        <div className="col-lg-10 mx-auto">
                          <h1 className="text-center text-white">
                            Time to Market & Grow Your Business!
                          </h1>
                          <p className="text-center text-white mt-3">
                            To engage and grow your client base, you will need a
                            professional set of web, email, and social posting
                            tools. We have partnered with Constant Contact so
                            that you can have your contacts from Warrior
                            synchronize with your Constant Contact account.
                          </p>
                        </div>
                      </div>
                    </section>
                    <div className="pricing-section">
                      <div className="container py-5">
                        <div className="content-head">
                          <h3 className="text-center title font-w-500">
                            All Plans
                          </h3>
                          <p className="text-center">
                            You will get a 60-day Free Trial & you can choose
                            from three packages.
                          </p>
                        </div>
                        <div className="row mt-5">
                          <div className="col-md-3 col-sm-6 px-2">
                            <div className="planbox bg-white">
                              <div className="headwrp bg-light px-2 py-3">
                                <h5 className="font-w-500 text-center m-0">
                                  Free
                                </h5>
                              </div>
                              <div className="p-4">
                                <span className="text-center brand-light d-block mt-4">
                                  Starting from
                                </span>
                                <p className="text-center mb-4 font-w-600 brand-green mt-2">
                                  $0/Month
                                </p>
                              </div>
                              <a
                                href="https://www.constantcontact.com/in/price-plans?pn=warriorsms"
                                className="btn btn-brandgreengrad chooseplan mx-auto mt-3 w-100"
                                target="_blank"
                              >
                                Sign Up
                              </a>
                            </div>
                          </div>
                          <div className="col-md-3 col-sm-6 px-2">
                            <div className="planbox bg-white">
                              <div className="headwrp bg-light px-2 py-3">
                                <h5 className="font-w-500 text-center m-0">
                                  Website Builder
                                </h5>
                              </div>
                              <div className="p-4">
                                <span className="text-center brand-light d-block mt-4">
                                  Starting from
                                </span>
                                <p className="text-center mb-4 font-w-600 brand-green mt-2">
                                  $10/Month
                                </p>
                              </div>
                              <a
                                href="https://www.constantcontact.com/in/price-plans?pn=warriorsms"
                                className="btn btn-brandgreengrad chooseplan mx-auto mt-3 w-100"
                                target="_blank"
                              >
                                Sign Up
                              </a>
                            </div>
                          </div>
                          <div className="col-md-3 col-sm-6 px-2">
                            <div className="planbox bg-white">
                              <div className="headwrp bg-light px-2 py-3">
                                <h5 className="font-w-500 text-center m-0">
                                  Email
                                </h5>
                              </div>
                              <div className="p-4">
                                <span className="text-center brand-light d-block mt-4">
                                  Starting from
                                </span>
                                <p className="text-center mb-4 font-w-600 brand-green mt-2">
                                  $20/Month
                                </p>
                              </div>
                              <a
                                href="https://www.constantcontact.com/in/price-plans?pn=warriorsms"
                                className="btn btn-brandgreengrad chooseplan mx-auto mt-3 w-100"
                                target="_blank"
                              >
                                Sign Up
                              </a>
                            </div>
                          </div>
                          <div className="col-md-3 col-sm-6 px-2">
                            <div className="planbox bg-white">
                              <div className="headwrp bg-light px-2 py-3">
                                <h5 className="font-w-500 text-center m-0">
                                  Email Plus
                                </h5>
                              </div>
                              <div className="p-4">
                                <span className="text-center brand-light d-block mt-4">
                                  Starting from
                                </span>
                                <p className="text-center mb-4 font-w-600 brand-green mt-2">
                                  $45/Month
                                </p>
                              </div>
                              <a
                                href="https://www.constantcontact.com/in/price-plans?pn=warriorsms"
                                className="btn btn-brandgreengrad chooseplan mx-auto mt-3 w-100"
                                target="_blank"
                              >
                                Sign Up
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <section className="aboutsection py-lg-5">
                      <div className="container py-5">
                        <div className="row align-items-center">
                          <div className="col-lg-6">
                            <div className="pr-3">
                              <h3 className="font-w-500">
                                About Constant Contact
                              </h3>
                              <p className="mt-3">
                                Constant Contact is a leader in online marketing
                                with a mission to provide people with a smarter
                                way to market an idea, small business, or cause
                                online. Our platform has all the right tools,
                                all in one place—from a logo maker and
                                intelligent website builder to establish your
                                brand; to email, social, and search marketing
                                tools to help you find new customers and keep
                                them coming back. Paired with award-winning
                                marketing advisors who know marketing across
                                industries inside and out—and provide practical
                                advice at every step of the way—we offer the
                                right approach to online marketing to achieve
                                the results you want.
                              </p>
                              <a
                                target="_blank"
                                href="https://www.constantcontact.com/in/price-plans?pn=warriorsms"
                                className="btn btn-brandbluegrad mt-4 w-500"
                              >
                                {" "}
                                <img
                                  src="https://images.ctfassets.net/t21gix3kzulv/78gf1S3CjPrnl9rURf6Q8w/3c20fb510dd4d4653feddf86ece35e1a/ctct_ripple_logo_horizontal_white_orange.svg"
                                  width="150"
                                  alt="Constant Contact"
                                />
                              </a>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="content-wrp">
                              <img
                                src="assets/images/pictures/consantcontact.png"
                                className="img-fluid"
                                alt="Constant Contact"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="aboutsection pb-5 text-center">
                      <div className="container">
                        <button onClick={()=>{navigate("/additional-services")}}
                          className="btn btn-brandwhite ml-auto mr-auto box-shadow-greenlight text-uppercase"
                          style={{ width: "250px" }}
                        >
                          Done With Marketing
                        </button>
                      </div>
                    </section>
                  </div>
                </section>
               </Container>
                <Footer />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
