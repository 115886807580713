import React, { useState } from 'react'
import { Row, Col, Container, Form } from 'react-bootstrap'
import Sidebar from '../../comman_page/Sidebar'
import { HeaderDashboard } from '../../comman_page/HeaderDashboard'
import { SharedButton } from '../../../../../sharedComponents/Button'
import { Footer } from 'antd/es/layout/layout'
import { InputField } from '../../../../../sharedComponents/InputField'
import { SharedSelect } from '../../../../../sharedComponents/SharedSelect'
import { TextArea } from '../../../../../sharedComponents/TextArea'
import { search_customer_list, set_notification } from '../../../../../Studio-Services/Studio_Services'
import SearchSelectInput from '../../../../../sharedComponents/SearchSelectInput'
import NotificationSearch from './NotificationSearch'
import Swal from 'sweetalert2'
import logo from "../../../../../Images/logo_white_web.png"
import { useEffect } from 'react'
import { useFetcher } from 'react-router-dom'
// import { NotificationSearch } from './NotificationSearch'

export const Addnotification = () => {

  const [individual, setIndividual] = useState();
  const [type, setType] = useState();
  const [skillsdata, setSkillsdata] = useState();
  const [preskillsdata, setPreskillsdata] = useState([]);
  // setPreskillsdata(preData);
  // search_customer_list();

useEffect(()=>{
if(type){
  setError((pre) => ({ ...pre, type: "" }));
}
if(individual){
  setError((pre) => ({ ...pre, customer_id: "" }));
}
},[type,individual])


  const typeopt = [
    { value: "1", label: <>All active clients </> },
    { value: "2", label: <>All inactive clients </> },
    { value: "3", label: <>All active staff </> },
    { value: "4", label: <>All inactive staff </> },
    { value: "5", label: <>All active staff and clients </> },
    { value: "6", label: <>All inactive staff and clients </> },
    { value: "7", label: <>An individual </> },
  ]

  const [error, setError] = useState({
    title: "",
    description: "",
    type: "",
    customer_id: ""
  })

  const submitHandler = async (e) => {
    e.preventDefault();

    const fData = new FormData(e.target);
    const fValue = Object.fromEntries(fData.entries());

    const title = fValue.title;
    const description = fValue.description;
    const type = fValue.type;
    const customer_id = individual;
    let isValid = 1;

    if (!title) { setError((pre) => ({ ...pre, title: "*Required" })); isValid = 2 }
    if (!description) { setError((pre) => ({ ...pre, description: "*Required" })); isValid = 3 }
    if (!type) { setError((pre) => ({ ...pre, type: "*Required" })); isValid = 4 }

    if (type === "7") {
      if (!individual) { setError((pre) => ({ ...pre, customer_id: "*Required" })); isValid = 5 }
    }

    if (isValid === 1) {
      const finalData = {
        "title": title,
        "description": description,
        "type": type,
        "customer_id": customer_id ? customer_id : localStorage.getItem("id")
      }

      const resp = await set_notification(finalData);
      if (resp) {
        e.target.reset();

        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
        <h4 class="title text-uppercase text-center mt-4 textWhite">
              Success
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
        ${resp.data.message} </small></p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      }

    }

  }
  const errorHandler = (e) => {
    const { name } = e.target;
    setError((pre) => ({ ...pre, [name]: "" }));
  }



  return (
    <>
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col>
              <div className="RightSide">
                <HeaderDashboard />
                <section className="addClient innerContent">
                  <div className="AddClientContent">
                    <Container>

                      <div className="notify">
                        <h4>ADD NOTIFICATION</h4>
                        <SharedButton
                          title={"Back"}
                          variant={"outline"}
                          href={"/notification"}
                        />
                      </div>
                      <Form onSubmit={submitHandler}>
                        <Row className="mt-3">
                          <Col>
                            <InputField
                              lable={"Title"}
                              type={"text"}
                              name={"title"}
                              error={error.title}
                              onChange={errorHandler}
                              placeholder={"Title"} />
                          </Col>
                          <Col>
                            <SharedSelect
                              options={typeopt}
                              name={"type"}
                              onChange={(e) =>setType(e.target.value)}
                              error={error.type}
                              lable={"Type"} />
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col md={6}>
                            <TextArea
                              name={"description"}
                              lable={"Description"}
                              onChange={errorHandler}
                              error={error.description}
                            />
                          </Col>
                          {type === "7" ?
                            <Col>
                              <label className="form-label inputLable">Select individual</label>
                              <NotificationSearch
                                setIndividual={setIndividual}
                              />
                              <small className='text-danger'>{error.customer_id}</small>
                            </Col>
                            : ""}
                        </Row>
                        <Row>
                          <Col>
                            <SharedButton
                              title={"SUBMIT"}
                              type={"submit"}
                              variant={"primary"}

                            />
                          </Col>
                          <Col>
                          </Col>
                          <Col></Col>
                        </Row>
                      </Form>
                    </Container>
                  </div>
                </section>
                <Footer />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}
