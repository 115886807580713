import React from 'react';

export default function TextaresNew({ label, value, onChange, name, rows, readOnly = false }) {
    return (
        <div className="form-group">
            <label htmlFor="exampleFormControlTextarea1" className="form-label inputLable">{label}</label>
            <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                name={name}
                value={value}
                onChange={onChange}
                readOnly={readOnly} // Use readOnly directly as a boolean
                rows={rows ? rows : '2'} // Default rows to 2 if not provided
            ></textarea>
        </div>
    );
}
