import React, { useEffect, useState } from 'react'
import './StaffMyAppointment.css'
import { InnerHeader } from '../../Components/inner-header/InnerHeader1'
import { SubHeader } from '../../Components/sub-header/SubHeader'
import { Innerfooter } from '../../Components/inner-footer/Innerfooter1'
import { DashboardToSectionDetails } from '../../Components/CommanPages/DashboardToSectionDetails'
import { Spinner } from 'react-bootstrap'
import moment from 'moment'
import { API_getInstructorShiftDate, API_service_scheduling_time_slot, dateConvert, staff_service_scheduling_time_slot } from '../../../Static_Services/Staff_Services'
import { errorAlert } from '../../../Static_Services/Alertmsg'
import { useNavigate } from 'react-router-dom'
import Calendar from 'react-calendar'
export const StaffMyAppointmentBooking = () => {
  const [businessDetail, setBusinessDetail] = useState([]);
  const [serviceID, setServiceID] = useState([]);
  const [serviceName, setServiceName] = useState([]);
  const [staffDetail, setStaffDetail] = useState([]);
  const [userdetail, setUserdetail] = useState([]);
  const [loder, setLoder] = useState(false);
  const [bookingDeatil, setBookingDeatil] = useState([]);
  const [apicall, setApicall] = useState();
  const [staffDates, setStaffDates] = useState([]);
  const [selectedslot, setSelectedslot] = useState([]);
  const [serviceDetail, setServiceDetail] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const [currentDate,setCurrentDate] = useState();
  const navigate = useNavigate();
  const [markAsChecked,setMarkAsChecked] = useState();

  const [selectedDa,setSelectedDa] = useState();
  useEffect(() => {
    if (JSON.parse(localStorage.getItem('w_BusinessDetail'))) {
      setBusinessDetail(JSON.parse(localStorage.getItem('w_BusinessDetail')));
    }
    if (JSON.parse(localStorage.getItem('w_serviceId'))) {
      setServiceID(JSON.parse(localStorage.getItem('w_serviceId')));
    }
    if (localStorage.getItem('w_serviceName')) {
      setServiceName(localStorage.getItem('w_serviceName'));
    }
    if (JSON.parse(localStorage.getItem('w_service_StaffDetail'))) {
      setStaffDetail(JSON.parse(localStorage.getItem('w_service_StaffDetail')));
    }
    if (JSON.parse(localStorage.getItem('w_loginDetail'))) {
      setUserdetail(JSON.parse(localStorage.getItem('w_loginDetail')))
    }
  }, [])

  const getBookingSlot = async (newTime='') => {
    setLoder(true);
    let resp = '';
    let Datess='';
    if(newTime){
      Datess = newTime;
    }else {
      Datess = moment().unix();
    }    
    let detail = { "business_id": businessDetail.business_id, "service_id": serviceID, "instructor_id": staffDetail.id, "service_date": Datess }

    if (userdetail.role_id == 3) {
      resp = await API_service_scheduling_time_slot(detail);
    } else {
      resp = await staff_service_scheduling_time_slot(detail);
    }
    if (resp) {
      const response = resp.data;
      const data = response.data;
      for (let i = 0; i < data.length; i++) {
        const slot = data[i].slot;
        for (let j = 0; j < slot.length; j++) {
          slot[j].start_time_new = moment.unix(slot[j].start_time_unix).format('hh mm A');
          slot[j].end_time_new = moment.unix(slot[j].end_time_unix).format('hh mm A');
          slot[j].isChecked = false;
        }
      }
      setBookingDeatil(data);
      setLoder(false);
    } else {
      setLoder(false);
      setBookingDeatil([]);
    }
  }

  const toTimestamp = (strDate)=>{
    var datum = Date.parse(strDate);
    return datum/1000;
  }

  const getBookingDate = async () => {
    const detail = { "business_id": businessDetail.business_id, "service_id": serviceID, "instructor_id": staffDetail.id }
    const resp = await API_getInstructorShiftDate(detail);
    if (resp) {
      const response = resp.data;
      const data = response.data;
      let stf = [];
      for (let i = 0; i < data.length; i++) {
        data[i].shift_date_new = dateConvert(data[i].shift_date);
        var d = data[i].shift_date_str.split('-');
        d[1] = d[1].replace(/^0+/, '');
        d[2] = d[2].replace(/^0+/, '');
        data[i].shift_date_str_new = d[0] + '-' + d[1] + '-' + d[2];
        stf.push(data[i].shift_date_str_new);
      }
      setStaffDates(stf)
      setApicall(true);
      dateClass();
      setLoder(false);
    } else {
      setStaffDates([])
      setApicall(true);
      dateClass();
      setLoder(false);
    }
  }

  const changeSelection = (e,event, service) => {
    setMarkAsChecked(e.target.value);
    setSelectedslot(event);
    setServiceDetail(service);
  }

  const booking = () => {
    if (selectedslot.length != 0) {
      const newdate = dateConvert(selectedDate);
      const name = staffDetail.name + " " + staffDetail.lastname;
      const tax = parseFloat(serviceDetail.services_collection.tax1_rate) + parseFloat(serviceDetail.services_collection.tax2_rate);
      serviceDetail.service_location = serviceDetail.location_name + ', ' + serviceDetail.address;
      var detail = {
        'Converted_date': newdate, 'slot_date': selectedDate, 'slot_time_id': selectedslot.id, 'service_id': serviceDetail.services_collection.id, 'from_time': selectedslot.start_time_new, 'to_time': selectedslot.end_time_new, 'duration': serviceDetail.services_collection.duration, 'instructor_id': staffDetail.id, 'instrutor_name': name, 'service_charge': serviceDetail.services_collection.amount, 'totalAmount': serviceDetail.services_collection.service_total_price, 'tax1_rate': serviceDetail.services_collection.tax1_rate, 'tax2_rate': serviceDetail.services_collection.tax2_rate, 'service_tax': serviceDetail.services_collection.service_tax_price, 'skills': staffDetail.skill,
        'start_time_unix': selectedslot.start_time_unix, 'end_time_unix': selectedslot.end_time_unix, 'shift_date': selectedslot.shift_date, 'service_name': serviceDetail.services_collection.service_name, 'location_url': serviceDetail.location_url, 'service_location': serviceDetail.service_location, 'shift_id': serviceDetail.shift_id, 'cancel_policy': serviceDetail.services_collection.cancel_policy, 'shift_scheduling_id': serviceDetail.shift_scheduling_id
      }
      localStorage.setItem('w_BookedDeatil', JSON.stringify(detail));
      navigate('/booking-payment');
    } else {
      errorAlert('Please select slots');
    }
  }

  const dateClass = (staffDates) => {
    return (date) => {
      var d = date.getDate();
      var m = date.getMonth() + 1;
      var y = date.getFullYear();
      const prepareDate = `${y}-${m}-${d}`;

      if (staffDates.indexOf(prepareDate) !== -1) {
        return 'special-date';
      } else {
        // Return a default class name if needed
        // return 'special1-date';
      }
    };
  };

  useEffect(()=>{
    if(businessDetail && businessDetail.business_id){
    getBookingDate();
    getBookingSlot();
  }
  },[businessDetail])


  useEffect(() => {
    let abc=[];
    if (staffDates && staffDates.length > 0) {
      staffDates.map((e) => {
            abc.push(new Date(moment(e).format('ddd MMM D YYYY HH:mm:ss')));
        })
        setSelectedDa(abc);
    } else {
        setSelectedDa();
    }
}, [staffDates])



  const tileClassName = ({ date }) => {
    const formattedDate = date.toISOString().slice(0, 10);
    if (selectedDa && selectedDa.some(selectedDa =>
        selectedDa.toISOString().slice(0, 10) === formattedDate
    )) {
        return 'highlighted-date';
    }
    return null;
};



const onClick = async (e) => {
  const sltDate = moment(new Date(e), 'ddd MMM DD YYYY HH:mm:ss');
  const newTime = moment(sltDate).unix();
  setSelectedDate(newTime)
  getBookingSlot(newTime)
}
  return (
    <>

      {loder ? <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div> : ""}
      <InnerHeader />
      <div className="body-grey">
        {/* <!-- My Studio Card Detials Section --> */}
        <div class="studio-details client-home-inner">
          <div class="container">
            <DashboardToSectionDetails />
            <div class="home-service home-service-offered pb-5">

              <div class="section-heading p-3 text-left white-bg border-radius6 box-shadow1 mb-5" >
                <div class="row">
                  <div class="col-10">
                    <a href="Javascript:void(0);" class="black-color font-w-100 text-uppercase font-20">Select appointment date & time for the {serviceName} </a>

                  </div>
                  <div class="col-2 text-right">
                    <button type="button" class="btn btn-blue-grad px-2 text-uppercase" onClick={()=>window.history.back()} >Back </button>
                  </div>
                </div>
                <div class="row mt-4">
                  {apicall &&
                  <div class="col-4" >
                    <Calendar
                     tileClassName={tileClassName} 
                     onChange={onClick} />
                    {/* // <mat-calendar #calender [dateClass]="dateClass()" [(selected)]="currentDate" [(minDate)] = "minValue" [(maxDate)] = "maxval" (selectedChange)="getBookingSlot()"  >
                // </mat-calendar> */}
                  </div>
                  }
                </div>

              </div>
              <div class="row">
                {bookingDeatil.length !=0 &&
                <div class="col-12" >
                  <div class="section-heading p-3 text-left white-bg border-radius6 box-shadow1 mb-5">
                    <a href="Javascript:void(0);" class="black-color font-w-100 text-uppercase font-20"> choose time slot for appointment </a>
                    {bookingDeatil.map((shift)=>(
                    <div class="form-group profile-gender mb-2 mt-3 ">
                      <div class="profile-gender-button">
                      {shift.slot.map((item,i)=>(
                        <div style={{display:"inline"}}>
                          {item.is_available != 1 &&
                          <input type="radio" name="radio" id={shift.shift_id+i} value={shift.shift_id+i} checked={markAsChecked==shift.shift_id+i}
                          
                          onChange={(e)=>changeSelection(e,item,shift)}
                          />}
                          {item.is_available == 1 && 
                          <input type="radio" name="radio" id={shift.shift_id+i} value={shift.shift_id+i} checked={item.isChecked}
                            readonly disabled />}
                          {item.is_available != 1 &&
                          <label for={shift.shift_id+i}>{item.start_time_new} -  {item.end_time_new}  </label>}
                          {item.is_available == 1 &&
                          <label for={shift.shift_id+i} style={{background:"dimgray",color:"white"}} disabled="true">{item.start_time_new} -  {item.end_time_new}  </label>}
                        </div>
                        ))}
                      </div>
                    </div>
                    ))}
                    <div class="row">
                      <div class="col-10">

                      </div> <div class="col-2">
                        <button
                          onClick={()=>booking()}
                          type="button" class="btn btn-blue-grad px-5 text-uppercase text-right"> Next </button>
                      </div>

                    </div>
                  </div>
                </div>
              }
              {bookingDeatil.length ==0 &&
                <div class="col-12" >
                  <div class="text-center">
                    <p class="font-awesome black-color font-18 d-inline mx-2 "> No Slots Available</p>
                  </div>
                </div>
                }
              </div>
            </div>

          </div>
        </div>
        {/* <!-- End of My Studio Card Detials Section --> */}
      </div>
      <Innerfooter />

    </>
  )
}
