import React from 'react'
import { StaticsHeader } from '../../Components/header/StaticsHeader11'
import { Link, useNavigate } from 'react-router-dom'
import { Modal, Spinner } from 'react-bootstrap'
import { useState } from 'react'
import { API_forgot_password } from '../../../Static_Services/Staff_Services'
import { successAlert } from '../../../Static_Services/Alertmsg'

export const StaffForgot = () => {
  const [modalShow, setModalShow] = useState(false);
  const [loder, setLoder] = useState(false);
  const navigate = useNavigate();

  const [error, setError] = useState();

  const onHandlerChange = () => {
    setError('');
  }

  const onforgot = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const formValue = Object.fromEntries(formData.entries());
    const emailID = formValue.email;

    let isValid = 1;
    if (!emailID) {
      setError("Email is required");
      isValid = 2;
    }
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (emailID && !emailPattern.test(emailID)) {
      setError("Email must be a valid email address");
      isValid = 3;
    }
    if (isValid === 1) {
      setLoder(true);
      const detail = { email: emailID, role: '4' };
      const resp = await API_forgot_password(detail);
      if (resp) {
        successAlert(resp.data.message);
        setLoder(false);
        setModalShow(true);
      } else {
        setLoder(false);
      }
    } else {
      setLoder(false);
      return false;
    }
  }
  const okHandler = ()=>{
    setModalShow(false);
    navigate("/staff-signin")
  }
  return (
    <>
      {loder ? <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div> : ""}
      <StaticsHeader />
      <div className="light-bg2">
        {/* <!-- Login Content Section --> */}
        <div class="background-before">
          <div class="d-flex align-items-center justify-content-center login-height p-4 p-sm-0">
            <div class="align-item-center text-center wow animated slideInDown">
              <div class="login-box-owner white-bg box-shadow1 border-radius10 py-4 px-3">

                <div class="login-header text-center mt-4 px-5">
                  <h3 class="title dark-color mb-4"> Forgot Password!! </h3>
                  <p class="brand-color3 px-4 font-18"> Please enter email address to reset password... </p>
                </div>

                <div class="logn-form mb-2">
                  <form onSubmit={onforgot}>
                    <div class="form-group">
                      <p class="brand-color3 text-left font-14 mb-2"> Email </p>
                      <input class="form-control-1 w-100" placeholder="sophiejoe1012@gmial.com" name="email"
                        onChange={onHandlerChange}
                      />
                      <small className='text-danger'>{error}</small>
                    </div>
                    <div class="text-right mb-2">
                      <Link to="/staff-signin" class="brand-color1 font-14 header-css"> <i class="fas fa-long-arrow-alt-left"></i> Back </Link>
                    </div>
                    <div class="py-3">
                      <button class="btn btn-blue-grad text-uppercase w-100" type="submit" >submit </button>
                      <button data-target="#ClientforgotPopup" data-toggle="modal" type="button" id="forgot_button" data-backdrop="static" data-keyboard="false"></button>
                    </div>
                  </form>
                </div>

              </div>
            </div>
          </div>
        </div>
        {/* <!-- End of About Content Section --> */}


        {/* <!-- CHECKOUT MODAL POPUP --> */}
        <Modal show={modalShow} class="modal fade" id="ClientforgotPopup" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content w-75 mx-auto blue-gradient border-0">
              <div class="modal-body border-radius6 box-shadow1 p-0">
                <div class="border-radius6 box-shadow-blue blue-gradient p-4 text-center">
                  <img src="clientassets/img/logo_white.png" class="img-fluid mt-5 mb-3" />
                  <h3 class="title white-color text-uppercase my-4 font-w-600"> Success </h3>
                  <p class="white-color my-4 font-w-100 px-4 line-height25 font-18"> New password has been sent successfully to your register email. </p>
                  <button data-dismiss="modal" type="button" class="btn btn-white2 text-uppercase w-100" onClick={() =>okHandler()}> ok </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        {/* <!-- END OF CHECKOUT MODAL POPUP --> */}
      </div>
    </>
  )
}
