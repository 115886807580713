import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Row, Col, Card, InputGroup } from "react-bootstrap";

export const MemberShipCard = ({ pass, setValue }) => {
  const[cardDetail,setCardDetail]= useState([]);
  // const cardDetail = [...pass];

  const [selectedPasses, setSelectedPasses] = useState([]);

  const handleCheckboxChange = (event, passId) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedPasses((prevSelectedPasses) => [
        ...prevSelectedPasses, passId,
      ]);
    } else {
      let selPasses = [...selectedPasses];
      let Passdata = selPasses.filter((passids) => passids !== passId);
      setSelectedPasses(Passdata);
    }
  };

  useEffect(() => {
    if (pass && pass.length !== 0) {
      setCardDetail(pass);
      setSelectedPasses([]);
      pass.forEach(e => {
        if (e.is_added) {
          let data = {
            target: {
              checked: true
            }
          };
          handleCheckboxChange(data, e.pass_id);
          // console.log(data,e.pass_id);
        }
      });
    }else{
      setCardDetail([]);
      setSelectedPasses([]);
    }
  }, [pass])

  useEffect(() => {
      setValue(selectedPasses);
  }, [selectedPasses]);

  return (
    <>
      {cardDetail && cardDetail.length > 0 ? (
        <Row>
          {cardDetail.map((value, index) => (
            <Col lg={4} md={4} sm={12} key={index}>
              <Card className="blueback-pass" key={index}>
                <div className="cardImg">
                  <Card.Img variant="top" src={value.memberShipImg} alt="" />
                  <p>{value.validText}</p>
                </div>
                <Card.Body>
                  <Card.Title>{value.cardTitle} </Card.Title>
                  <Card.Text>
                    {parseFloat(value.price).toFixed(2)} <small>{value.tax}</small>
                  </Card.Text>
                </Card.Body>
                <InputGroup.Checkbox
                  name="pass[]"
                  className="membercheckbox"
                  value={value.pass_id}
                  checked={selectedPasses.includes(value.pass_id)}
                  aria-label="Checkbox for following text input"
                  onChange={(event) =>
                    handleCheckboxChange(event, value.pass_id)
                  }
                />
              </Card>
            </Col>
          ))}
        </Row>
      ) : (
        ""
      )}
    </>
  );
};
