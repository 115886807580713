import React from 'react'
import Pagination from './Pagination'

export default function RefundBotstrapTable({ th, tr, totpage, pageHandler, cpage, totalDetail,myrefund }) {
    return (
        <>
            <table className="table table-striped">
                <thead>
                    <tr>
                        {th && th.length > 0 && th.map((item, index) => <th key={index}>{item}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {tr && tr.length > 0 && tr.map((row, index) => <tr key={index}>
                        <td>{row.summary}</td>
                        <td>{row.transaction}</td>
                        <td>-$ {row.before_tax}</td>
                        <td>-$ {row.tax_one}</td>
                        <td>-$ {row.tax_two}</td>
                        <td>-$ {row.total}</td>
                    </tr>)}
                </tbody>
            </table>
        </>


    )
}
