import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { API_get_business_detail, favourite } from '../../../Static_Services/Staff_Services';
import { successAlert } from '../../../Static_Services/Alertmsg';
import { get_business_detail } from '../../../Static_Services/StaticServices';

export const DashboardToSectionDetails = () => {
    const [businessDetail, setBusinessDetail] = useState();
    const navigate = useNavigate();

    // Get Business Deatils From LocalStore
    const bzDetails = localStorage.getItem('bzDetails');
    useEffect(() => {
        if (bzDetails) {
            setBusinessDetail(JSON.parse(bzDetails));
        }
    }, [bzDetails]);

    // Get User Details From LocalStore
    const [userDetail, setUserDetail] = useState();
    const storedData = localStorage.getItem("userDetail");
    const parsedData = JSON.parse(storedData);
    useEffect(() => {
        setUserDetail(parsedData);
    }, [])
    //  End User Detail Section

    const getDetail = async () => {
        const bzid = localStorage.getItem('bzid');
        if (bzid != null) {
            const fData = {
                "business_id": bzid
            }
            // const resp = await get_business_detail(fData);
            const resp = await API_get_business_detail(fData);
            if (resp) {
                localStorage.setItem('bzDetails',JSON.stringify(''))
                setBusinessDetail(resp.data.data);
                localStorage.setItem('bzDetails', JSON.stringify(resp.data.data));
            }
        }
    }
    useEffect(() => {
        getDetail();
    }, [])
    const addFav = async (id, status, type) => {
        const fData = {
            "service_type": type,
            "service_id": id,
            "status": status
        }
        const resp = await favourite(fData);
        if (resp) {
            successAlert(resp.data.message);
            getDetail();
        }
    }

    return (
        <>
            {businessDetail &&
                <div className="studio-details-upper box-shadow1 white-bg mb-3">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className="std-details-upper-left d-flex align-items-center justify-content-center"
                                style={{ backgroundImage: `url(${businessDetail.business_img})`, backgroundSize: "contain", backgroundPosition: "center", minHeight: "406px",backgroundRepeat: "no-repeat" }}>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="std-details-upper-right text-center p-3">
                                <div className="">
                                    <img src={businessDetail.logo} className="mb-4 log-img" style={{objectFit:"contain"}} alt='' />
                                    <h4 className="title dark-color mb-2"> {businessDetail.business_name} </h4>
                                    {businessDetail.address.length <= 40 &&
                                        <p className="brand-color3 font-12 mb-0">{businessDetail.address} </p>
                                    }
                                    {businessDetail.address.length > 40 &&
                                        <p className="brand-color3 font-12 mb-0"> {businessDetail.address.substring(0, 40) + '...'} </p>
                                    }
                                    <ul className="studio-contact-details my-4">
                                        <li className="ml-0" onClick={() => navigate('/detail')} style={{ cursor: 'pointer' }}>
                                            <img src="clientassets/img/ic_home_green.png" alt='' />
                                            <span> Home </span>
                                        </li>

                                        <li>
                                             <Link to={`tel:${businessDetail.business_phone}`} target="blank">
                                                <img src="clientassets/img/ic_call_web.png" alt='' /> <span> Call </span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={`http://maps.google.com/maps?q=${businessDetail.latitude},${businessDetail.longitude},14z?hl=en@${businessDetail.address}`}
                                                target="blank">
                                                <img src="clientassets/img/ic_direction_web.png" alt='' /> <span> Directions </span>
                                            </Link> 
                                        </li>

                                        <li> 
                                            <Link to={`https://mail.google.com/mail/u/0/?view=cm&to=${businessDetail.email}&:&bcc&cc&fs=1&tf=1`}
                                                target="blank"> <img src="clientassets/img/ic_email_web.png" alt='' /> <span> Email </span>
                                            </Link> 
                                        </li>
                                        <li className="mr-0">
                                            {businessDetail.favourite == 0 &&
                                                <div style={{ cursor: 'pointer' }} onClick={() => addFav(businessDetail.business_id, 1, 1)}>
                                                    <img src="clientassets/img/ic_unfavourite_web.png" alt='' />
                                                    <span> Favourite </span>
                                                </div>
                                            }
                                            {businessDetail.favourite == 1 &&
                                                <div style={{ cursor: "pointer" }} onClick={() => addFav(businessDetail.business_id, 0, 1)}>
                                                    <img src="clientassets/img/ic_favourite_web.png" alt='' />
                                                    <span>Favourite </span>
                                                </div>
                                            }
                                        </li>
                                    </ul>
                                    {parseInt(userDetail.role_id) === 3 &&
                                        <ul className="studio-service-details">
                                            <li className="ml-md-0">
                                                <Link to={"/my-classes"}>
                                                    <img src="clientassets/img/ic_class.png" alt='' />
                                                    <span> Classes </span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={'/staff'}>
                                                    <img src="clientassets/img/ic_instructor.png" alt='' />
                                                    <span> Instructors </span> 
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={'/services'}>
                                                    <img src="clientassets/img/ic_service.png" alt='' />
                                                    <span> Services </span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={'/allproduct'}>
                                                    <img src="clientassets/img/ic_product_new.png" alt='' />
                                                    <span> Products </span>
                                                </Link>
                                            </li>
                                            <li className="mr-md-0">
                                                <Link to={'/workshops'}>
                                                    <img src="clientassets/img/ic_workshop.png" alt='' />
                                                    <span> Workshops </span>
                                                </Link>
                                            </li>
                                        </ul>
                                    }
                                    {parseInt(userDetail.role_id) === 4 &&
                                        <ul className="studio-service-details">
                                            <li className="ml-md-0">
                                                <Link to={'/classess'}>
                                                    <img src="clientassets/img/ic_class.png" alt=''/>
                                                    <span> Classes </span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={'/appointment'}>
                                                    <img src="clientassets/img/ic_appointment.png" alt='' />
                                                    <span> Appointment</span>
                                                </Link>
                                            </li>
                                            <li className="mr-md-0">
                                                <Link to={'/workshopss'}>
                                                    <img src="clientassets/img/ic_workshop.png" alt=''/>
                                                    <span> Workshops </span>
                                                </Link>
                                            </li>
                                        </ul>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
