import React, { useContext, useState } from "react";
import Sidebar from "../../comman_page/Sidebar";
import { HeaderDashboard } from "../../comman_page/HeaderDashboard";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { DataTable } from "../../../../../sharedComponents/DataTable";
import { Footer } from "../../../../../public/containers/footer/Footer";
import { SharedCheckboxTrue } from "../../../../../sharedComponents/SharedCheckboxTrue";
import SharedCheckboxFalse from "../../../../../sharedComponents/SharedCheckboxFalse";
import {
  InstructorList,
  InstructorChangeStatus,
} from "../../../../../Studio-Services/Studio_Services";
import { useEffect } from "react";
import Swal from "sweetalert2";
import logo from "../../../../../Images/logo_white_web.png"
import { MyContext } from "../../../../../App";



export const Instructor = () => {
  const {instuctorNoti} = useContext(MyContext);
 

  const [instructData, setInstructData] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);


  const getInstructor = async () => {
    const pageid = {
      pageid: 1,
    };
    const resp = await InstructorList(pageid);
    if (resp) {
      setInstructData(resp.data.data);
      let count = 0;
      const data =resp.data.data;
      if (data) {
        data.forEach((e) => {
          if (e.status === "Pending") {
            count += 1; // Increment count when the status is "Pending"
          }
        });
      }
      instuctorNoti(count);     
    }
  };

  useEffect(() => {
    getInstructor();
  }, []);

  const callActive = async (event) => {
    const data = {
      id: event.target.value,
      status: "Approve",
    };
    const resp = await InstructorChangeStatus(data);
    if (resp) {
      getInstructor();
      Swal.fire({
        html: `
          <img src=${logo} class="img-fluid d-block mx-auto mt-5" alt='' />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
            SUCCESS
          </h4>
          <p class="text-center my-4 textcolor">
            <small class="textWhite">
             ${resp.message}
            </small>
          </p>
        `,
        showClass: {
          popup: 'custom-dialog-container',
        },
        customClass: {
          confirmButton: 'custom-button custom-button-size modalbtn',
        },
      })
      // alert(resp.message);
    
    }
  };

  const callUnActive = async (event) => {
    const data = {
      id: event.target.value,
      status: "Reject",
    };
    const resp = await InstructorChangeStatus(data);
    if (resp) {
      getInstructor();
      Swal.fire({
        html: `
          <img src=${logo} class="img-fluid d-block mx-auto mt-5" alt='' />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
            SUCCESS
          </h4>
          <p class="text-center my-4 textcolor">
            <small class="textWhite">
             ${resp.message}
            </small>
          </p>
        `,
        showClass: {
          popup: 'custom-dialog-container',
        },
        customClass: {
          confirmButton: 'custom-button custom-button-size modalbtn',
        },
      })
    
    }
  };

  
  const calculateAge = (brdate) =>{
    const birthdate = new Date(brdate);
  
    const currentDate = new Date();
    const birthYear = birthdate.getFullYear();
    const currentYear = currentDate.getFullYear();
  
    // Calculate the age
    const calculatedAge = currentYear - birthYear;
  
    // Check if the birthday has occurred this year
    const hasBirthdayOccurred = (
      currentDate.getMonth() > birthdate.getMonth() ||
      (currentDate.getMonth() === birthdate.getMonth() &&
        currentDate.getDate() >= birthdate.getDate())
    );
  
    // Adjust the age if the birthday hasn't occurred yet this year
    return hasBirthdayOccurred ? calculatedAge : calculatedAge - 1;
  }

  const array = [];

  if (instructData) {
    instructData.forEach((element) => {
      array.push({
        Name: (
          <>
            <img className="img-fluid table_user" src={element.profile_img} alt=''/>
            <span className="ps-4">{element.name}</span>
            <span className="ps-2">{element.lastname}</span>
          </>
        ),
        Age: calculateAge(element.dob),
        Gender: element.gender === 'Undefined' ? 'Male' :element.gender,
        ID: element.instructor_id,
        Accept: (
          <SharedCheckboxTrue
            status={element.status}
            value={element.user_id}
            onClick={callActive}
            name={element.instructor_id}
          />
        ),
        Reject: (
          <SharedCheckboxFalse
            status={element.status}
            value={element.user_id}
            name={element.instructor_id}
            onClick={callUnActive}
          />
        ),

        // Add more properties as needed
      });
    });
  }





  const columns = [
    { dataField: "Name", text: "Instructor Name" },
    { dataField: "Age", text: "Age" },
    { dataField: "Gender", text: "Gender" },
    { dataField: "ID", text: "Instructor ID" },
    { dataField: "Accept", text: "Accept" },
    { dataField: "Reject", text: "Reject" },
  ];

  return (
    <>
    
     
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col>
              <div className="RightSide">
                <HeaderDashboard />
                <section className="addClient innerContent">
                  <div className="AddClientContent">
                    <Container>
                      <h4>INSTRUCTOR REQUEST LIST</h4>

                      <Row className="mt-3">
                        <Col>
                          <DataTable data={array} columns={columns} />
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </section>
                <Footer />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
