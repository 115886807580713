import React from 'react'
import { Link, NavLink } from 'react-router-dom'

export default function StaticNav() {
  return (
    <>
    {/* <!-- Navigation Section --> */}
<div className="header box-shadow1" id="myHeader">
    <div className="container">
      <nav className="navbar navbar-expand-lg navbar-light white-bg px-0">
        <Link className="nav-link px-0" to="/Home"> <img src="clientassets/img/logo_colored.png" className="img-fluid img-size" alt='' /> </Link>
        <button className="navbar-toggler ml-auto my-3 my-lg-0" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        {/* <!-- buttons ends here --> */}

        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav ml-auto">
            {/* <!-- Demo --> */}
            <li className="nav-item" >
              <NavLink className="nav-link px-4 header-css" to="/login" > Sign In </NavLink>
            </li>
            {/* <!-- Login --> */}
          </ul>
        </div>
        {/* <!-- end of navbar-collapse --> */}
      </nav>
    </div>
    {/* <!-- end of container --> */}
  </div>
{/* <!-- End of Navigation Section --> */}

    </>
  )
}
