import React from "react";
import Sidebar from "../../comman_page/Sidebar";
import { HeaderDashboard } from "../../comman_page/HeaderDashboard";
import { Col, Row } from "react-bootstrap";
import { Footer } from "../../../../../public/containers/footer/Footer";
import { Link, useNavigate } from "react-router-dom";
import { SharedButton } from "../../../../../sharedComponents/Button";

export const Merchantservices = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col>
              <HeaderDashboard />
              <div className="RightSide">
                <section className="Merchantservices">
                  <h2>Merchant Services</h2>
                  <p>New To Accepting Credit and Debit?</p>
                  <Row>
                    <Col>
                      <div className="merchantContent">
                        <h3>
                          ACCEPT VISA, MASTERCARD, AMERICAN EXPRESS - GREAT
                          RATES AVAILABLE!
                        </h3>
                        <p>
                          We have integrated payments throughout the Warrior
                          solution that you or your customers can complete a
                          purchase of a pass, a service, or a product easily
                          with affordable rates. We also offer the Clover line
                          of point of sale equipment for selling at your
                          business. Please contact us at sales@warriorsms.com so
                          we can set up a time to discuss how we can set up an
                          account to seamlessly allow customers to pay for your
                          services at the same rates or better.
                        </p>
                        <h3>CUSTOMER SERVICE</h3>
                        <p>
                          Do have a Customer Service issues with your account or
                          Clover terminal?
                        </p>
                        <p>
                          If you need assistance with the account you can
                          contact the help desk calling:
                        </p>
                        <p>1-888-263-1938 (Canada)</p>
                        <p>1-877-810-9315 (USA)</p>
                        <p>Additional Resources:</p>
                        <p>
                          Clover Home <Link to="">Canada</Link> /{" "}
                          <Link to="">USA</Link>
                        </p>
                        <p>
                          Clover Help <Link to="">Canada</Link> /{" "}
                          <Link to="">USA</Link>
                        </p>
                        <div className="visaCard my-4">
                          <img
                            src="assets/images/visa_card.png"
                            className="img-fluid"
                            alt=""
                          />
                          <img
                            src="assets/images/master_card.png"
                            className="img-fluid"
                            alt=""
                          />
                          <img
                            src="assets/images/american_express.png"
                            className="img-fluid"
                            alt=""
                          />
                          <img
                            src="assets/images/discover.png"
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <SharedButton
                        onClick={()=>{navigate("/additional-services")}}
                        className={"btn btn-outline"} 
                        title={"Done With Merchant Services"} 
                        type={"button"}/>
                      </div>
                    </Col>
                    <Col>
                      <img
                        src="assets/images/clover_img.png"
                        className="img-fluid mx-auto w-75"
                        alt=""
                      />
                    </Col>
                  </Row>
                </section>
                <Footer />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
