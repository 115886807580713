import React, { useEffect, useState } from "react";
import { SharedSelect } from "../../../../../sharedComponents/SharedSelect";
import { SharedButton } from "../../../../../sharedComponents/Button";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import Sidebar from "../../comman_page/Sidebar";
import { HeaderDashboard } from "../../comman_page/HeaderDashboard";
import { InputField } from "../../../../../sharedComponents/InputField";
import TextArea from "antd/es/input/TextArea";
import { Footer } from "antd/es/layout/layout";
import { AiFillDelete } from "react-icons/ai";
import { SharedMultiSelect } from "../../../../../sharedComponents/SharedMultiSelect";
import { useLoaderData, useLocation } from "react-router-dom";
import { InstructorList, book_appointment, remove_services_instructor, update_services_client_visibilty, update_services_instructor } from "../../../../../Studio-Services/Studio_Services";
import logo from "../../../../../Images/logo_white_web.png"
import Swal from "sweetalert2";
import { InputTypeTax } from "../../../../../sharedComponents/InputTypeTax";
import { errorAlert } from "../../../../../Static_Services/Alertmsg";
import TextaresNew from "../../../../../sharedComponents/TextaresNew";

export const Viewservice = () => {
  const location = useLocation();
  const [preData, setPreData] = useState([]);
  const [optionValue, setOptionValue] = useState();
  const [instructorOption, setInstructorOption] = useState([]);
  const [id, setId] = useState("");
  const [loder, setLoder] = useState(false);
  const [preInstId, setPreInstId] = useState('');


  const [skillsdata, setSkillsdata] = useState();

  const [indata, setIndata] = useState({ "description": "", "visible": "" });


  const getInstruct = async (data) => {
    const fdata = {
      "pageid": 1,
      "instructor": 1
    }
    const resp = await InstructorList(fdata);
    if (resp) {
      const op = [];

      (resp.data.data).forEach(e => {
        if (e.status === "Approve" && e.business_status === "Active") {
          if (preData && Array.isArray(preData.instructor)) {
            let preCheck = preData.instructor.find((myind) => myind.id === e.decodedId);
            if (!preCheck) {
              op.push({
                id: e.user_id, label: `${e.name} ${e.lastname}`
              });
            }
          }
        }
      });

      // (resp.data.data).forEach(e => {
      //   if (e.status === "Approve" && e.business_status === "Active") {
      //     let preChack = preData.instructor.find((myind)=>myind.id !=e.id);
      //     console.log('=====000===',preChack);
      //     // if (preData && preData.instructor) {
      //     //   (preData.instructor).forEach(i => {
      //     //     if (i.instructor_ids != e.id) {
      //           op.push({
      //             id: e.user_id, label: `${e.name} ${e.lastname}`
      //           })
      //     //     }
      //     //   })
      //     // }
      //   }
      // });
      setInstructorOption(op);
    }
  }


  const getServiceData = async () => {
    const fData = {
      "pageid": 1
    }
    const resp = await book_appointment(fData);
    if (resp) {
      (resp.data).forEach(e => {
        if (e.id === id) {
          setPreData(e);

          // if (e.instructor && Array.isArray(e.instructor)) {

          //   e.instructor.forEach((inst, index) => {
          //     if (index === e.instructor.length - e.instructor.length) {
          //       setPreInstId((pre) => pre + "{");
          //     }
          //     if (index === e.instructor.length - 1) {
          //       setPreInstId((pre) => pre + inst.id + "}");
          //     } else {
          //       setPreInstId((pre) => pre + inst.id + ",");
          //     }
          //   });
          // }
        }
      })

    }
  }
  useEffect(() => {
    getInstruct(preData);
    setIndata((pre) => ({ ...pre, "description": preData.description }));
    setIndata((pre) => ({ ...pre, "visible": preData.is_client_visible }));
  }, [preData])

  useEffect(() => {
    if (id != "") {
      // getInstruct();
      getServiceData();
    }
  }, [id])

  useEffect(() => {
    if (location && location.state.data) {
      setId((location.state.data).id);
    }
  }, [location])

  const option = [
    { value: "no", label: "No" },
    { value: "yes", label: "Yes" }
  ]

  const setOption = (e) => {
    const value = e.target.value;
    setOptionValue(value);
  }

  const inputHandler = (e) => {
    const { name, value } = e.target;
    setIndata((pre) => ({ ...pre, [name]: value }));
  }


  const clickHandler = async () => {
    const fData = {
      "id": preData.id,
      "visible": indata.visible,
      "description": indata.description
    }
    const resp = await update_services_client_visibilty(fData);

    if (resp) {
      Swal.fire({
        html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
        <h4 class="title text-uppercase text-center mt-4 textWhite">
        SUCCESS !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
        ${resp.message} </small></p>
        `,
        showClass: {
          popup: 'custom-dialog-container',
        },
        customClass: {
          confirmButton: 'custom-button custom-button-size modalbtn',
        },
      });
    }

  }



  const updateHandler = async () => {
    if (!preData.id) { errorAlert('Something wrong !'); return; }
    setLoder(true);
    if (skillsdata.length > 0) {
      let int = [];
      skillsdata.map((e) => {
        int.push(e.id)
      })
      const fData = {
        "id": preData.id,
        "instructor": int
      }
      const resp = await update_services_instructor(fData);
      if (resp) {
        setLoder(false);
        getServiceData();
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
      <h4 class="title text-uppercase text-center mt-4 textWhite">
      SUCCESS
      </h4>
      <p class="text-center my-4 textcolor">
      <small class="textWhite">
      ${resp.data.message} </small></p>
      `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        })
      }

    } else {
      setLoder(false);
      Swal.fire({
        html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
        <h4 class="title text-uppercase text-center mt-4 textWhite">
        Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
        Plese Select Instructor </small></p>
        `,
        showClass: {
          popup: 'custom-dialog-container',
        },
        customClass: {
          confirmButton: 'custom-button custom-button-size modalbtn',
        },
      });
    }
    setLoder(false);
  }


  const deleteHandler = async (instId) => {
    if (!preData.id) { errorAlert('Something wrong !'); return; }

    Swal.fire({
      title: '<small>CONFIRMATION</small>',
      icon: 'info',
      html:
        'Are you sure to change status ?',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "YES",
      cancelButtonText: "NO"
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoder(true);
        const fdata = { instructor_id: instId, service_id: preData.id }
        const resp = await remove_services_instructor(fdata);
        if (resp) {
          getServiceData();
          setLoder(false);
          Swal.fire({
            html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
    <h4 class="title text-uppercase text-center mt-4 textWhite">
    SUCCESS
    </h4>
    <p class="text-center my-4 textcolor">
    <small class="textWhite">
    ${resp.data.message} </small></p>
    `,
            showClass: {
              popup: 'custom-dialog-container',
            },
            customClass: {
              confirmButton: 'custom-button custom-button-size modalbtn',
            },
          })
        }
      }
    })
    setLoder(false);

  }



  return (
    <>
      {loder &&
        <div className='MainLoader'>
          <Spinner animation="border" role="status" className='Loader'>
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      }
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col className="p-0 RightCol">
              <HeaderDashboard />
              <div className="RightSide">
                <section className="addClient innerContent">
                  <Container className="pt-5">
                    <h4>List Your Shifts</h4>
                    <p>
                      Shifts are required for clients to be able to book a
                      service appointment. Staff can also be set up for a shift
                      to work without offering appointments (non service) such
                      as working the front desk.
                    </p>
                    <div className="AddClientContent ViewShift">
                      <h3 className="my-4">VIEW SERVICE</h3>
                      <Row>
                        <Col>
                          <InputField
                            type={"text"}
                            value={preData.service_name}
                            readOnly
                            lable={"Name"}
                          />
                        </Col>
                        <Col>
                          <InputField
                            type={"number"}
                            value={preData.duration}
                            readOnly
                            lable={"Duration [Minutes]"}
                          />
                        </Col>
                        <Col>
                          <InputTypeTax
                            type={"number"}
                            value={preData.amount}
                            readOnly
                            lable={"Retail Price"}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <SharedSelect
                            value={indata.visible}
                            options={option}
                            name={'visible'}
                            onChange={inputHandler}
                            lable={"Visible to client"} />
                        </Col>
                        <Col>
                          <InputField
                            type={"text"}
                            readOnly
                            value={preData.tax1}
                            lable={"Tax1"}
                          />
                        </Col>
                        {preData.tax1 === "Yes" ?
                          <Col>
                            <InputTypeTax
                              type={"text"}
                              readOnly
                              value={preData.tax1_rate}
                              lable={"Tax1 %"}
                            />
                          </Col>
                          : ""}
                      </Row>
                      <Row>
                        <Col>
                          <InputTypeTax
                            type={"text"}
                            readOnly
                            value={preData.tax2}
                            lable={"Tax 2"}
                          />
                        </Col>
                        {preData.tax2 === "Yes" ?
                          <Col>
                            <InputField
                              type={"number"}
                              readOnly
                              value={preData.tax2_rate}
                              lable={"tax 2 %"}
                            />
                          </Col>
                          : ""}
                        <Col>
                          <InputField
                            type={"text"}
                            readOnly
                            value={preData.tip_option}
                            lable={"Tip Prompt"}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <InputField
                            type={"text"}
                            readOnly
                            value={preData.time_needed}
                            lable={
                              "Time needed before the next service can start (min)"
                            }
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <TextaresNew
                            label={"Cancel Policy"}
                            readOnly={true}
                            value={preData.cancel_policy}

                          />
                          {/* <TextArea
                            lable={"Cancel Policy"}
                            readOnly
                            value={preData.cancel_policy} /> */}
                        </Col>
                        <Col>
                          <TextaresNew
                            label={"Description"}
                            name={"description"}
                            value={indata.description}
                            onChange={inputHandler}
                          // value={preData.description}
                          />
                          {/* <TextArea
                            lable={"Description"}
                            readOnly
                            value={preData.description} /> */}
                        </Col>
                      </Row>
                      <SharedButton
                        title={"UPDATE VISIBILITY"}
                        onClick={clickHandler}
                        type={"button"}
                        className={"my-3"}
                      />
                      <Row className="p-5"> <hr /></Row>
                      <Row>
                        <Col>

                          <SharedMultiSelect
                            labelText={"Update Instructor"}
                            options={instructorOption}
                            setSkillsdata={setSkillsdata}
                          />
                        </Col>
                        <Col className="mt-4">
                          <SharedButton
                            onClick={updateHandler}
                            title={"UPDATE"} />
                        </Col>
                      </Row>
                      <hr />
                      <div className="Updateinstructor">
                        <h3>Instructor</h3>
                        {preData && preData.instructor &&
                          <Row >
                            {(preData.instructor).map((e, index) => (
                              <Col key={index} lg={4} xm={12}>
                                <div className="instructorDetail">
                                  <img
                                    src={e.profile}
                                    alt=""
                                    className="img-fluid instructorProfile"
                                    style={{ width: '50px', height: '50px', objectFit: 'contain' }}
                                  />
                                  <h5 >{`${e.name} ${e.lastname}`}</h5>
                                  <i class="fa fa-trash" style={{ color: "red", margin: "0rem,0.5rem", cursor: 'pointer' }} onClick={() => deleteHandler(e.id)} ></i>

                                </div>
                              </Col>
                            ))}
                          </Row>
                        }

                      </div>
                    </div>
                    <div className="AddClientContent ViewShift">
                      <Row className="my-2">
                        <Col>
                          <SharedButton
                            title={"BACK TO LIST"}
                            href={"/list-services"}
                            type={"button"}
                            className={"btn btn-outline"}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Container>
                </section>
                <Footer />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
