import React from 'react'
import {Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { IoMdInformationCircleOutline } from "react-icons/io";

export default function Tooltips({info}) {
  return (
    <>
     {['top'].map((placement) => (
        <OverlayTrigger
          key={placement}
          placement='top'
          overlay={
            <Tooltip id={`tooltip-${placement}`}>
              <strong>{info} </strong>
            </Tooltip>
          }
        >
          <Button className="InfoIcon" style={{margin:'0px'}} variant="secondary"><IoMdInformationCircleOutline /></Button>
        </OverlayTrigger>
      ))}
    </>
  )
}
