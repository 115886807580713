import React, { useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import Sidebar from "../../comman_page/Sidebar";
import { HeaderDashboard } from "../../comman_page/HeaderDashboard";
import { Footer } from "../../../../../public/containers/footer/Footer";
import { useLocation } from "react-router-dom";
import { get_passes_according_to_category, update_class_passes } from "../../../../../Studio-Services/Studio_Services";
import { UpdateCard } from "./UpdateCard";
import Swal from "sweetalert2";
import logo from "../../../../../Images/logo_white_web.png";

export const UpdateClassPass = (props) => {
  const [clsId, setClsId] = useState();
  const location = useLocation();
  const [loder, setLoder] = useState(false);


  useEffect(() => {
    if (location.state) {
      setClsId(location.state);
    }
  }, [location.state])


  const classDetail = props.pass ? props.pass : [];


  const [passlist, setPasslist] = useState([]);


  const [timepass, setTimepass] = useState([]);
  const [recurrigpass, setRecurrigpass] = useState([]);
  const [punchpass, setPunchpass] = useState();

  const getPass = async () => {
    const fdata = {
      "service_id": location.state,
      "service_type": "1"
    }
    const resp = await get_passes_according_to_category(fdata);
    if (resp) {
      setPasslist(resp.data.data);
    }
  }


  useEffect(() => {
    if (location && location.state) {
      getPass();
    }
  }, [location])

  useEffect(() => {
    if (classDetail && classDetail.passes_details) {
      setPasslist(classDetail.passes_details);
    }
  }, [classDetail])

  const handleCheckboxChange = async (event, passId) => {

    const isChecked = event.target.checked;
    if (isChecked) {
      setLoder(true);
      const fData = {
        "class_id": clsId,
        "pass_id": [passId],
        "action_type": 1
      }
      const resp = await update_class_passes(fData);
      if (resp) {
        getPass();
        setLoder(false);
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
              <h4 class="title text-uppercase text-center mt-4 textWhite">
              SUCCESS
            </h4>
            <p class="text-center my-4 textcolor">
            <small class="textWhite">
           ${resp.data.message} </small></p>
            `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        })
      } else setLoder(false);
    } else {
      setLoder(true);
      const fData = {
        "class_id": clsId,
        "pass_id": [passId],
        "action_type": 2
      }
      const resp = await update_class_passes(fData);
      if (resp) {
        getPass();
        setLoder(false);
        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
              <h4 class="title text-uppercase text-center mt-4 textWhite">
              SUCCESS
            </h4>
            <p class="text-center my-4 textcolor">
            <small class="textWhite">
           ${resp.data.message} </small></p>
            `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        })
      } else setLoder(false);
    }

  }

  const setPasse = () => {
    const timep = [];
    const recuring = [];
    const punch = [];
    if (passlist.length > 0) {
      const logo = localStorage.getItem("logo");
      passlist.forEach((value) => {
        if (value.pass_type === "Time Frame") {
          timep.push({
            pass_id: value.pass_id,
            memberShipImg: logo ? logo : "https://staging.warriorsms.com/superadmin/uploads/business/1664736345WarriorMan_icon2_CMYK.png",
            validText: value.pass_days,
            cardTitle: value.pass_name,
            price: value.amount,
            tax: "Plus Tax",
            is_added: value.is_added
          })
        } else if (value.pass_type === "Recurring Membership") {
          recuring.push({
            pass_id: value.pass_id,
            memberShipImg: logo ? logo : "https://staging.warriorsms.com/superadmin/uploads/business/1664736345WarriorMan_icon2_CMYK.png",
            validText: value.pass_days,
            cardTitle: value.pass_name,
            price: value.amount,
            is_added: value.is_added,
            tax: "Plus Tax"
          })
        } else if (value.pass_type === "Punch Card") {
          punch.push({
            pass_id: value.pass_id,
            memberShipImg: logo ? logo : "https://staging.warriorsms.com/superadmin/uploads/business/1664736345WarriorMan_icon2_CMYK.png",
            validText: value.pass_days,
            cardTitle: value.pass_name,
            price: value.amount,
            is_added: value.is_added,
            tax: "Plus Tax"
          })
        }
      });
      if (timep.length > 0) {
        setTimepass(timep);
      }
      if (recuring.length > 0) {
        setRecurrigpass(recuring);
      }
      if (punch.length > 0) {
        setPunchpass(punch);
      }
    }
  }

  useEffect(() => {
    if (passlist.length > 0) {
      setPasse();
    }

  }, [passlist])

  // useEffect(() => {
  //   if (classDetail.passes_details) {
  //     let passlist = [];
  //     passlist = classDetail.passes_details;

  //     const timep = [];
  //     const recuring = [];
  //     const punch = [];

  //     if (passlist.length > 0) {
  //       const logo = localStorage.getItem("logo");
  //       passlist.forEach((value) => {
  //         if (value.pass_type === "Time Frame") {
  //           timep.push({
  //             memberShipImg: logo ? logo : "https://staging.warriorsms.com/superadmin/uploads/business/1664736345WarriorMan_icon2_CMYK.png",
  //             validText: value.pass_days,
  //             cardTitle: value.pass_name,
  //             price: `$ ${value.amount}`,
  //             tax: "Plus Tax"
  //           })
  //         } else if (value.pass_type === "Recurring Membership") {
  //           recuring.push({
  //             memberShipImg: logo ? logo : "https://staging.warriorsms.com/superadmin/uploads/business/1664736345WarriorMan_icon2_CMYK.png",
  //             validText: value.pass_days,
  //             cardTitle: value.pass_name,
  //             price: `$ ${value.amount}`,
  //             tax: "Plus Tax"
  //           })
  //         } else if (value.pass_type === "Punch Card") {
  //           punch.push({
  //             memberShipImg: logo ? logo : "https://staging.warriorsms.com/superadmin/uploads/business/1664736345WarriorMan_icon2_CMYK.png",
  //             validText: value.pass_days,
  //             cardTitle: value.pass_name,
  //             price: `$ ${value.amount}`,
  //             tax: "Plus Tax"
  //           })
  //         }
  //       });
  //       if (timep.length > 0) {
  //         setTimepass(timep);
  //       }
  //       if (recuring.length > 0) {
  //         setRecurrigpass(recuring);
  //       }
  //       if (punch.length > 0) {
  //         setPunchpass(punch);
  //       }
  //     }

  //   }
  // },);



  return (
    <>
      {loder ?
        <div className='MainLoader'>
          <Spinner animation="border" role="status" className='Loader'>
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
        : ""}
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col className="p-0 RightCol">
              <HeaderDashboard />
              <div className="RightSide">
                <div className="innerContent">
                  <section className="ClassAttendance">
                    <Container>
                      <div className="row">
                        <div className="col-8">
                        <h3>UPDATE SCHEDULE CLASS PASS</h3>
                        <p>Select Passes</p>
                        </div>
                   
                        <div className="col-4 text-right">
                          <button type="button" className="btn btn-blue-grad px-2 text-uppercase" onClick={() => window.history.back()}  >Back </button>
                        </div>
                      </div>
                      <Row>
                        <Col>
                          <div className="RecurringMembership">
                            <div className="CardCarousel mt-5">
                              <h5>Recurring Membership</h5>

                              <UpdateCard pass={recurrigpass} handleCheckboxChange={handleCheckboxChange} />
                            </div>
                            <div className="TimeCard mt-5">
                              <h5>Time Frame</h5>
                              <UpdateCard pass={timepass} handleCheckboxChange={handleCheckboxChange} />
                            </div>
                            <div className="PunchCard my-5">
                              <h5>Punch Card</h5>
                              <UpdateCard pass={punchpass} handleCheckboxChange={handleCheckboxChange} />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </section>
                  <Footer />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
