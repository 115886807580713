import React, { useEffect, useState } from 'react'
import { Alert, Col, Row, Spinner } from 'react-bootstrap';
import Calendar from 'react-calendar';
// import { DatePicker } from 'react-responsive-datepicker'
// import 'react-responsive-datepicker/dist/index.css'

import Swal from 'sweetalert2';
import { service_scheduling_time_slot } from '../../../../../Studio-Services/Studio_Services';
import moment from 'moment';
import logo from "../../../../../Images/logo_white_web.png";



export const StepThree = ({ cardDetail, setStep, apiData, onClick, userSelectedDate, sloteHandler,shiftHandler, selectedSlote }) => {
    const [selectedDa, setSelectedDa] = useState();
    const abc = [];
    useEffect(() => {
        if (apiData && apiData.schedule.length > 0) {
            apiData.schedule.map((e) => {
                abc.push(new Date(moment(e.shift_date_str).format('ddd MMM D YYYY HH:mm:ss')));
            })
            setSelectedDa(abc);
        } else {
            setSelectedDa();
        }
    }, [apiData])


    const tileClassName = ({ date }) => {
        const formattedDate = date.toISOString().slice(0, 10);
        if (selectedDa && selectedDa.some(selectedDa =>
            selectedDa.toISOString().slice(0, 10) === formattedDate
        )) {
            return 'highlighted-date';
        }
        return null;
    };

    const changeSelectionHandler = (slot,shift) => {
        if (slot.is_available == 1) {
            Swal.fire({
                html: `
                  <img src=${logo} class="img-fluid d-block mx-auto mt-5" />
                  <h4 class="title text-uppercase text-center mt-4 textWhite">
                  Sorry
                  </h4>
                  <p class="text-center my-4 textcolor">
                    <small class="textWhite">
                    Slot already booked
                    </small>
                  </p>
                `,
                showClass: {
                    popup: 'custom-dialog-container',
                },
                customClass: {
                    confirmButton: 'custom-button custom-button-size modalbtn',
                },
            })
        } else {
            sloteHandler(slot);
            shiftHandler(shift)
        }
    }

    const nextStepHandler = () => {
        if (!userSelectedDate) {
            Swal.fire({
                html: `
                  <img src=${logo} class="img-fluid d-block mx-auto mt-5" />
                  <h4 class="title text-uppercase text-center mt-4" style="color:red">
                  Error 
                  </h4>
                  <p class="text-center my-4 textcolor">
                    <small class="textWhite">
                      Please select Date
                    </small>
                  </p>
                `,
                showClass: {
                    popup: 'custom-dialog-container',
                },
                customClass: {
                    confirmButton: 'custom-button custom-button-size modalbtn',
                },
            })
        } else if (!selectedSlote) {
            Swal.fire({
                html: `
                  <img src=${logo} class="img-fluid d-block mx-auto mt-5" />
                  <h4 class="title text-uppercase text-center mt-4" style="color:red">
                  Error 
                  </h4>
                  <p class="text-center my-4 textcolor">
                    <small class="textWhite">
                      Please select Slote Time
                    </small>
                  </p>
                `,
                showClass: {
                    popup: 'custom-dialog-container',
                },
                customClass: {
                    confirmButton: 'custom-button custom-button-size modalbtn',
                },
            })
        } else {
            setStep(3);
        }
    }

    return (
        <>
            <div className="section-heading statbox-heading p-3 text-left statbox m-3" style={{ minHeight: "0px" }}>
                <div className="row">
                    <div className="col-6">
                        <div className="form-group profile-gender mb-0 mt-1">
                            <div className="service-button">
                                <div className="row">
                                    {/* <h1>STEP 3</h1> */}
                                    <h4 className="title black-color mb-0 ml-3">Service Chosen :{cardDetail && cardDetail.sData.service_name ? cardDetail.sData.service_name : ""} </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 text-right">
                        <button type="button" className="btn btn-blue-grad px-2 text-uppercase" onClick={() => setStep(0)}>Back </button>
                    </div>
                </div>
            </div>
            <div className='section-heading statbox-heading p-3 m-3 text-left statbox'>
                <Calendar tileClassName={tileClassName} onChange={onClick} />
            </div>

            <div className='section-heading statbox-heading p-3 m-3 text-left statbox'>
                <div className="row">
                    <div className="col-12">
                        < div className="section-heading statbox-heading p-4 text-left mt-3 mb-3" >
                            <h5 className="black-color text-uppercase pb-3 mb-0">
                                choose time slot for appointment
                            </h5>
                            {apiData && apiData.data && apiData.data[0].slot && apiData.data[0].slot.length > 0 ?
                                <>
                                    <Row>
                                        {apiData.data.map((slotData, index) => (
                                            slotData.slot.map((slt, i) => (
                                                <Col md={3}>
                                                    <div className="slot-selector mb-0 pb-3" key={index} >
                                                        <div className="profile-gender-button">
                                                            <div style={{ display: "inline" }}>
                                                                <input type="radio" name="radio" id={slotData.shift_id + i}
                                                                    onClick={() => changeSelectionHandler(slt,slotData)}
                                                                    disabled={slt.is_available === 1 ? true : false}
                                                                />
                                                                <label style={
                                                                    slt.is_available === 1
                                                                        ? {
                                                                            backgroundColor: "#777777",
                                                                            borderColor: "#777777",
                                                                            color: "#fff"
                                                                        }
                                                                        : {}
                                                                } key={i} for={slotData.shift_id + i} >{slt.slot} </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            ))
                                        ))}
                                    </Row>
                                    <div className="slot-selector mb-0 pb-3">
                                        <button onClick={() => nextStepHandler()} type="button" className="btn btn-blue-grad px-5 text-uppercase"> Next </button>
                                    </div>
                                </>
                                :
                                <div className="text-center p-4" >
                                    <h5 className="black-color text-uppercase font-18 d-inline"> No Slots Available</h5>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
