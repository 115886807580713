import React, { useContext } from 'react'
import './StudioDetail.css'
import { InnerHeader } from '../../Components/inner-header/InnerHeader1'
import { Innerfooter } from '../../Components/inner-footer/Innerfooter1'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useState } from 'react'
import { get_business_detail } from '../../../Static_Services/StaticServices'
import { favourite } from '../../../Static_Services/Staff_Services'
import { successAlert } from '../../../Static_Services/Alertmsg'
import { DashboardToSectionDetails } from '../../Components/CommanPages/DashboardToSectionDetails'


export const StudioDetail = () => {
    

    const location = useLocation();
    const [b_id, setB_id] = useState();
    const [businessDetail, setBusinessDetail] = useState();
    const [userDetail, setUserDetail] = useState();
    const [memberDetail,setMemberDetail] = useState();
    const [parentDetail,setParentDetail] = useState();

    const navigate = useNavigate();
    const storedData = localStorage.getItem("userDetail");
    const parsedData = JSON.parse(storedData);

    //Get Business Details From LocalStore
 
    const bzDetails = localStorage.getItem('bzDetails');
    useEffect(()=>{
        if(bzDetails){
            setBusinessDetail(JSON.parse(bzDetails));
        }
    },[bzDetails]);

    useEffect(() => {
        if(JSON.parse(localStorage.getItem('w_loginDetail'))){
            setUserDetail(JSON.parse(localStorage.getItem('w_loginDetail')))
        }
        if(JSON.parse(localStorage.getItem('w_MemberDetail'))){
            setMemberDetail(JSON.parse(localStorage.getItem('w_MemberDetail')));
        }
        if(JSON.parse(localStorage.getItem('w_ParentDetail'))){
            setParentDetail(JSON.parse(localStorage.getItem('w_ParentDetail')));
        }
    }, [])

    useEffect(() => {
        if (location && location.state && location.state.id) {
            setB_id(location.state.id);
        }
    }, [location])

    const getDetail = async () => {
        if (b_id != null) {
            const fData = {
                "business_id": b_id
            }
            const resp = await get_business_detail(fData);
            if (resp) {
                setBusinessDetail(resp.data.data);
            }
        }
    }
    useEffect(() => {
        getDetail();
    }, [b_id])

    const addFav = async (id, status, type) => {
      
        const fData = {
            "service_type": type,
            "service_id": id,
            "status": status
        }
        const resp = await favourite(fData);
        if (resp) {
            successAlert(resp.data.message);
            getDetail();
        }
    }

    return (
        <>
            <InnerHeader />
            {businessDetail &&
                <div className="body-grey">
                    {/* <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' ,fullScreenBackdrop:true}"></ngx-loading> */}

                    <div className="studio-details client-home-inner">
                        <div className="container">
                         
                         <DashboardToSectionDetails />

                            <div className="text-right mb-2">
                                <button type="button" className="btn btn-blue-grad px-2 text-uppercase" onClick={() => window.history.back()}>Back
                                </button>
                            </div>

                            {parseInt(userDetail.role_id) === 3 &&
                                <div className="home-staff" >

                                    <div className="row staff-studio-details">
                                        <div className="col-md-6">
                                            <a href="javascript:void(0)" onClick={() => navigate('/my-classlist')}>
                                                <div className="tabbox box-shadow1 border-radius6 px-4 py-3 d-flex align-items-center bg-white">
                                                    <h4 className="black-color font-w-400 font-18 mb-0">My Classes</h4>
                                                    <i className="fas fa-chevron-right ml-auto black-color font-w-600"></i>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-md-6">
                                            <a href="javascript:void(0)" onClick={() => navigate('/appointments')}>
                                                <div className="tabbox box-shadow1 border-radius6 px-4 py-3 d-flex align-items-center bg-white">
                                                    <h4 className="black-color font-w-400 font-18 mb-0">My Appointments</h4>
                                                    <i className="fas fa-chevron-right ml-auto black-color font-w-600"></i>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-md-6">
                                            <a href="javascript:void(0)" onClick={() => navigate('/my-workshop')}>
                                                <div className="tabbox box-shadow1 border-radius6 px-4 py-3 d-flex align-items-center bg-white">
                                                    <h4 className="black-color font-w-400 font-18 mb-0">My Workshops</h4>
                                                    <i className="fas fa-chevron-right ml-auto black-color font-w-600"></i>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-md-6">
                                            <a href="javascript:void(0)" onClick={() => navigate('/my-waitlist')}>
                                                <div className="tabbox box-shadow1 border-radius6 px-4 py-3 d-flex align-items-center bg-white">
                                                    <h4 className="black-color font-w-400 font-18 mb-0">My Waitlists</h4>
                                                    <i className="fas fa-chevron-right ml-auto black-color font-w-600"></i>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-md-6">
                                            <a href="javascript:void(0)" onClick={() => navigate('/my-attendance')}>
                                                <div className="tabbox box-shadow1 border-radius6 px-4 py-3 d-flex align-items-center bg-white">
                                                    <h4 className="black-color font-w-400 font-18 mb-0">My Attendance</h4>
                                                    <i className="fas fa-chevron-right ml-auto black-color font-w-600"></i>
                                                </div>
                                            </a>
                                        </div >
                                        <div className="col-md-6">
                                            <a href="javascript:void(0)" onClick={() => navigate('/my-purchase')}>
                                                <div className="tabbox box-shadow1 border-radius6 px-4 py-3 d-flex align-items-center bg-white">
                                                    <h4 className="black-color font-w-400 font-18 mb-0">My Purchases</h4>
                                                    <i className="fas fa-chevron-right ml-auto black-color font-w-600"></i>
                                                </div>
                                            </a>
                                        </div >
                                    </div >
                                </div >
                            }
                            {/* //   < !--end of home - staff-- > */}
                         
                            {parseInt(userDetail.role_id) === 4 &&

                                <div className="home-staff" >
                                    <div className="row staff-studio-details">
                                        <div className="col-md-6">
                                            <a href="javascript:void(0)" onClick={() => navigate('/availability')}>
                                                <div className="tabbox box-shadow1 border-radius6 px-4 py-3 d-flex align-items-center bg-white">
                                                    <h4 className="black-color font-w-400 font-18 mb-0">My Availability</h4>
                                                    <i className="fas fa-chevron-right ml-auto black-color font-w-600"></i>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-md-6">
                                            <a href="javascript:void(0)" onClick={() => navigate('/classess')}>
                                                <div className="tabbox box-shadow1 border-radius6 px-4 py-3 d-flex align-items-center bg-white">
                                                    <h4 className="black-color font-w-400 font-18 mb-0">My Classes</h4>
                                                    <i className="fas fa-chevron-right ml-auto black-color font-w-600"></i>
                                                </div>
                                            </a>
                                        </div >
                                        <div className="col-md-6">
                                            <a href="javascript:void(0)" onClick={() => navigate('/workshopss')}>
                                                <div className="tabbox box-shadow1 border-radius6 px-4 py-3 d-flex align-items-center bg-white">
                                                    <h4 className="black-color font-w-400 font-18 mb-0">My Workshops</h4>
                                                    <i className="fas fa-chevron-right ml-auto black-color font-w-600"></i>
                                                </div>
                                            </a>
                                        </div >
                                        <div className="col-md-6">
                                            <a href="javascript:void(0)" 
                                            onClick={() => navigate('/my-appointment')}
                                            >
                                                <div className="tabbox box-shadow1 border-radius6 px-4 py-3 d-flex align-items-center bg-white">
                                                    <h4 className="black-color font-w-400 font-18 mb-0">My Appointments</h4>
                                                    <i className="fas fa-chevron-right ml-auto black-color font-w-600"></i>
                                                </div>
                                            </a>
                                        </div >
                                        <div className="col-md-6">
                                            <a href="javascript:void(0)" 
                                            onClick={() => navigate('/client')}
                                            >
                                                <div className="tabbox box-shadow1 border-radius6 px-4 py-3 d-flex align-items-center bg-white">
                                                    <h4 className="black-color font-w-400 font-18 mb-0">My Clients</h4>
                                                    <i className="fas fa-chevron-right ml-auto black-color font-w-600"></i>
                                                </div>
                                            </a>
                                        </div >
                                        <div className="col-md-6">
                                            <a href="javascript:void(0)" 
                                            onClick={() => navigate('/my-shift')}
                                            >
                                                <div className="tabbox box-shadow1 border-radius6 px-4 py-3 d-flex align-items-center bg-white">
                                                    <h4 className="black-color font-w-400 font-18 mb-0">My Shifts</h4>
                                                    <i className="fas fa-chevron-right ml-auto black-color font-w-600"></i>
                                                </div>
                                            </a>
                                        </div >
                                    </div >
                                </div >
                            }

                        </div >

                        {/* // < !--end of container-- > */}
                    </div >

                    {/* < !--End of My Studio Card Detials Section-- > */}
                </div >
            }

            <Innerfooter />



        </>
    )
}
