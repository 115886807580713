import React from 'react'
import { InnerHeader } from '../../Components/inner-header/InnerHeader1'
import { SubHeader } from '../../Components/sub-header/SubHeader'
import { Innerfooter } from '../../Components/inner-footer/Innerfooter'
import { DashboardToSectionDetails } from '../../Components/CommanPages/DashboardToSectionDetails'
import { Spinner } from 'react-bootstrap'
import { useState } from 'react'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { ageCalculation, client_my_book_services_list } from '../../../Static_Services/Staff_Services'

export const ClientMyAppointment = () => {
    const [loder, setLoder] = useState(false);
    const [businessDetail, setBusinessDetail] = useState();
    const [currentUrl, setCurrentUrl] = useState();
    const location = useLocation();
    const [currentDate, setCurrentDate] = useState();
    const [bookedserviceList, setBookedserviceList] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (JSON.parse(localStorage.getItem('w_BusinessDetail'))) {
            setBusinessDetail(JSON.parse(localStorage.getItem('w_BusinessDetail')));
            setCurrentUrl(location.pathname);
            localStorage.setItem('w_AppointUrl', location.pathname);
        }
    }, [])

    const getList = async (status) => {
        var today = new Date();
        setLoder(true);
        var currYear = today.getFullYear();
        var searchdate;
        if (currentDate) {
            searchdate = moment(currentDate, 'YYYY-MM-DD').unix();
        }
        const detail = { 'pageid': '1', 'search_dt': searchdate, "business_id": businessDetail.business_id, 'transaction_status': status };
        const resp = await client_my_book_services_list(detail);
        if (resp) {
            const response = resp.data;
            if (response.data.length != 0) {

                response.data.map((el) => {
                    el.start_date_utc_new = moment.unix(el.shift_date).format('MMM DD YYYY');
                    el.start_time_new = moment.unix(el.start_time).format('hh mm A');
                    el.end_time_new = moment.unix(el.end_time).format('hh mm A');
                    el.age = ageCalculation(el.date_of_birth);
                    el.Name = el.name + ' ' + el.lastname;
                    if (el.family_user_id != 0) {
                        el.age = ageCalculation(el.family_dob);
                        el.Name = el.family_member_name;
                        el.gender = el.family_gender;
                        el.profile_img = el.family_profile_img;
                    }
                })
            }
            setBookedserviceList(response.data);
            setLoder(false)
        } else {
            setBookedserviceList([])
            setLoder(false)
        }
    }
    const showHide = (event) => {
        getList(event.target.value);
    }
    const redirectUrl = (id)=>{
        localStorage.setItem('w_Booking_detail', JSON.stringify(id));
        navigate('/booking-detail');
      }

    useEffect(() => {
        if (businessDetail) {
            getList('0');
        }
    }, [businessDetail])
    return (
        <>
            {loder ? <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div> : ""}
            <InnerHeader />

            <div className="studio-details">
                <div className="container">
                    <DashboardToSectionDetails />
                    <div className="text-right mb-3">
                        <button type="button" className="btn btn-blue-grad px-2 text-uppercase" onClick={()=>window.history.back()}  >Back </button>
                    </div>
                    <div className="studio-details-lower pb-5">
                        <div className="row">
                            <div className="col-12">
                                <div className="white-bg border-radius6 box-shadow1 p-3">
                                    <div className="col-4 std-detail-select" >
                                        <select className="form form-control brand-color1 border-radius6"
                                            onChange={(e) => showHide(e)}
                                        >
                                            <option value="0"> My Future Appointments </option>
                                            <option value="1" > My Past Appointments </option>
                                        </select>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="w-100">
                                                <div className="flex-break">
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 text-right" >
                                        </div>
                                    </div>
                                    <div className="box all-class">
                                        {bookedserviceList.length != 0 &&
                                            <div className="activity-card-section mt-4 pt-2">
                                                <div className="row">
                                                    {bookedserviceList.map((items)=>(
                                                    <div className="col-12 col-md-4 mb-4">
                                                        <a href="Javascript:void(0);"
                                                        onClick ={()=>redirectUrl(items)}
                                                        >
                                                            <div className="activity-card box-shadow1 border-radius6 white-bg p-2">
                                                                <div className="row  pb-1">
                                                                    <div className="col-12 text-right">
                                                                        {items.booking_status == 'Success'  &&
                                                                        <a className="black-color font-w-100  mr-1 mb-1 paratitle">Paid </a>}
                                                                        {items.booking_status == 'Completed' &&
                                                                        <a className="black-color font-w-100  mr-1 mb-1 paratitle" >Payment Pending </a>}
                                                                        {items.booking_status == 'Confirm' &&
                                                                        <a className="black-color font-w-100  mr-1 mb-1 paratitle" >Confirmed </a>}
                                                                        {items.booking_status == 'Cancel' &&
                                                                        <a className="black-color font-w-100  mr-1 mb-1 paratitle">Cancelled </a>}
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-3 col-sm-3 col-md-12 col-lg-3">
                                                                        <div className="activity-card-logo">
                                                                            <img src={businessDetail.logo} className="box-shadow1 new-log-img" />
                                                                        </div>
                                                                    </div>
                                                                    {/* <!-- end of col-3 --> */}

                                                                    <div className="col-9 col-sm-9 col-md-12 col-lg-9 pl-0 pl-sm-0 pl-md-3 pl-lg-0">
                                                                        <div className="row">
                                                                            <div className="col-7 col-md-12 col-lg-7 mt-0 mt-md-4 mt-lg-0 pl-0 pl-sm-0 pl-md-3 pl-lg-0">
                                                                                <div className="activity-card-details text-left">
                                                                                    <h5 className="classname-title black-color mb-0">{ items.service_name }  </h5>
                                                                                    <ul className="d-flex align-items-center py-2">
                                                                                        <li> <div className="subinst-profileimg"><img src={items.instructor_profile_img} className="img-fluid" /> </div></li>
                                                                                        <li> <h5 className="black-color mb-0 classname-title pl-2">{ items.instructor_name } { items.instructor_lastname } </h5> </li>
                                                                                    </ul>

                                                                                </div>
                                                                            </div>
                                                                            {/* <!-- end of col-7 --> */}
                                                                            <div className="col-5 col-md-12 col-lg-5 pr-1">
                                                                                <div className="text-right">
                                                                                    <p className="black-color font-w-100  mr-1 mb-1 paratitle">{ items.start_date_utc_new } </p>
                                                                                    <div className="card-green-box text-center">
                                                                                        <p className="white-color mb-0 paratitle"> { items.duration } Min.</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="row pt-3">
                                                                            <p className="black-color font-w-100 paratitle mb-0">Location :
                                                                            </p>
                                                                            {items.location_url &&
                                                                            <h5 className="heading-titlenew ml-2 mb-0 black-color" ><a href={items.location_url} target="_blank">{ items.location_name }</a></h5>}
                                                                            {!items.location_url &&
                                                                            <h5 className="heading-titlenew ml-2 mb-0 black-color" >{ items.location_name }</h5>}
                                                                        </div>

                                                                        <div className="row pt-2">
                                                                            <div className="col-6 col-sm-6 col-md-6 col-lg-6 px-0 border-right">
                                                                                <div className="text-left">
                                                                                    <p className="paratitle black-color mb-0"> Start Time </p>
                                                                                    <h5 className="classname-title black-color mb-0"> { items.start_time_new } </h5>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-6 col-sm-6 col-md-6 col-lg-6 d-flex justify-content-end">
                                                                                <div className="text-left">
                                                                                    <p className="paratitle black-color mb-0"> End Time </p>
                                                                                    <h5 className="classname-title black-color mb-0"> { items.end_time_new } </h5>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    ))}
                                                </div>
                                            </div>
                                        }
                                        {bookedserviceList.length == 0 &&
                                            <div className="activity-card-section mt-4 pt-2">
                                                <div className="text-center">
                                                    <p className="font-awesome black-color font-18 d-inline mx-2 " > No Appointment Are scheduled</p>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Innerfooter />

        </>
    )
}
