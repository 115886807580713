import React from 'react'
import { Link } from 'react-router-dom'

export default function StaticFooter() {
  return (
<>
{/* <!-- Footer --> */}
<footer>
    <div className="container">
      <div className="footer-top py-3">
        <div className="row">
          <div className="col-12 col-md-8 col-lg-8">
            <div className="footer-left">
              <ul>
                <li> <Link to="/about-us" className="header-css" style={{textDecoration:'none'}}> About us </Link> </li>
                <li> <Link to="/privacy-policy" className="header-css" style={{textDecoration:'none'}}> Privacy Policy </Link> </li>
                <li> <Link to="/terms-conditions" className="header-css" style={{textDecoration:'none'}}> Terms & Conditions </Link> </li>
                <li>
                  {/* <!-- <Link to="/faq" className="header-css"> FAQ </Link> --> */}
                  <Link to="https://www.youtube.com/channel/UCEKl3ls1vXLmOZXCiAYsq-g" target="_blank" style={{textDecoration:'none'}} className="header-css"> FAQ </Link>
                </li>
                {/* <!-- <li> <Link to="/help"> Help </Link> </li> --> */}
                {/* <li> <Link to="/contact-us" className="header-css" style={{textDecoration:'none'}}> Help </Link> </li> */}
                <li> <Link to="/contact-us" className="header-css" style={{textDecoration:'none'}}> Contact us </Link> </li>
              </ul>
            </div>
          </div>
          {/* <!-- end of footer-left --> */}
          <div className="col-12 col-md-4 col-lg-4">
            <div className="footer-right">
              <ul>
                <li>
                  <Link href="https://www.instagram.com/warriorsms/?hl=en" target="_blank">
                    <div className="clearfix bshadow0 pbs">
                      <span className="icon-instagram font-12"></span>
                    </div>
                  </Link>
                </li>
    
                <li>
                  <Link href="https://www.facebook.com/WarriorStudioManagementSoftware" target="_blank">
                    <div className="clearfix bshadow0 pbs">
                      <span className="icon-facebook font-12"></span>
                    </div>
                  </Link>
                </li>
           
                <li>
                  <Link href="https://twitter.com/warrior_sms?lang=en" target="_blank">
                    <div className="clearfix bshadow0 pbs">
                      <span className="icon-twitter font-12"></span>
                    </div>
                  </Link>
                </li>
                {/* <!-- twitter --> */}
              </ul>
            </div>
          </div>
          {/* <!-- end of footer-right --> */}
        </div>
      </div>
      {/* <!-- end of footer-top --> */}
      <div className="footer-bottom pt-3">
        <div className="row">
          <div className="col-12">
            <div className="footer-copyright">
              <p className="brand-color3 mb-2"> Copyright &#169; 2020 Warrior SMS. All rights reserved. </p>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- end of footer-bottom --> */}
    </div>
    {/* <!-- end of container --> */}
  </footer>
{/* <!-- End of Footer Section --> */}
</>
  )
}
