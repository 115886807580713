import React, { useEffect, useState } from "react";
import Sidebar from "../../comman_page/Sidebar";
import { HeaderDashboard } from "../../comman_page/HeaderDashboard";
import { Col, Container, Row } from "react-bootstrap";
import { DataTable } from "../../../../../sharedComponents/DataTable";
import { Footer } from "../../../../../public/containers/footer/Footer";
import { SharedButton } from "../../../../../sharedComponents/Button";
import { getNotifiation } from "../../../../../Studio-Services/Studio_Services";
import { TimestamoToDate } from "../../../../../sharedComponents/TimestamoToDate";

export const Notification = () => {
  const [notification, setNotification] = useState([]);

  const UpodateDate = (dates) => {
    const monthArray = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var new_date = new Date(dates * 1000);
    let date = new_date.getDate();
    const day = date < 10 ? "0" + date : date;
    var month = new_date.getMonth();
    const monthName = monthArray[month];
    var year = new_date.getFullYear();
    var updateDate = day + " " + monthName + " " + year;
    return updateDate;
  };

  const showType = (type) => {
    return type === "1"
      ? "Active Clients"
      : type === "2"
      ? "Inactive clients"
      : type === "3"
      ? "Active Staff"
      : type === "4"
      ? "Inactive Staff"
      : type === "5"
      ? "Active Staff and Clients"
      : type === "6"
      ? "Inactive Staff & Clients"
      : "Individual";
  };

  const getNotification = async () => {
    const resp = await getNotifiation();
    if (resp) {
    
      setNotification(resp.data.data);
    }
  };

  useEffect(() => {
    getNotification();
  }, []);

  const array = [];

  if (notification) {
    notification.forEach((element) => {
      array.push({
        Title: element.title,
        Type: showType(element.messageFor),
        Description: element.description,
        Date: UpodateDate(element.create_dt),
      });
    });
  }
  const data = [
    // { Title: notification, Type: "John Doe", Description: 25, Date: "New York" },
    // Add more data objects as needed
  ];

  const columns = [
    { dataField: "Title", text: "Title" },
    { dataField: "Type", text: "Type" },
    { dataField: "Description", text: "Description" },
    { dataField: "Date", text: "Date" },
  ];

  return (
    <>
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
          <Sidebar />
            <Col>
              <div className="RightSide">
                <HeaderDashboard />
                <section className="addClient innerContent">
                  <div className="AddClientContent">
                    <Container>
                      <div className="notify">
                        <h4>NOTIFICATION LIST</h4>
                        <SharedButton
                          title={"Add New"}
                          variant={"primary"}
                          href={"/add-notifiaction"}
                        />
                      </div>

                      <Row className="mt-3">
                        <Col>
                          <DataTable data={array} columns={columns} />
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </section>
                <Footer />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
