import React, { useState } from 'react'
import { Col, Row, Form } from 'react-bootstrap';

import { InputField } from '../../../../../sharedComponents/InputField';
import { SharedButton } from '../../../../../sharedComponents/Button';
import { SharedSelect } from '../../../../../sharedComponents/SharedSelect';
import { MyDatePicker } from '../../../../../sharedComponents/MyDatePicker';
import { TextArea } from '../../../../../sharedComponents/TextArea';
import logo from "../../../../../Images/logo_white_web.png"
import Swal from 'sweetalert2';
import { MyContext } from '../../../../../App';
import { useCol } from 'react-bootstrap/esm/Col';
import { useContext } from 'react';
import { useEffect } from 'react';
import { SharedMultiSelect } from '../../../../../sharedComponents/SharedMultiSelect';
// import { SharedMultiSelect } from '../../../../../sharedComponents/SharedMutiSelect';
import { Sharedmanyselectecheckbox } from '../../../../../sharedComponents/Sharedmanyselectecheckbox';
import { SharedMultiSelectorChekBox } from '../../../../../sharedComponents/SharedMultiSelectorChekBox';
import PlacesAutocomplete, { geocodeByAddress, getLatLng, } from 'react-places-autocomplete';

import { add_instructor, get_countries } from '../../../../../Studio-Services/Studio_Services';
import { get_skills } from '../../../../../Studio-Services/Studio_Services';
import moment from 'moment';
import PlaceAutoCompleted from '../../../../../client-staff/Components/CommanPages/PlaceAutoCompleted';
import { useNavigate } from 'react-router-dom';

export const Addinstructorform = ({ staffList, topandler, setLoder }) => {

    const navigate = useNavigate();

    const [skillsdata, setSkillsdata] = useState();
    const [preskillsdata, setPreskillsdata] = useState([]);
    const [profileImg, setProfileImg] = useState();


    const [base64Image, setBase64Image] = useState('');
    
    const [cordinates, setCordinates] = useState();

    const [addr, setAddr] = useState({ address: '', street: '', city: '', state: '', country: '', zip_code: '' });

    useEffect(() => {
        if (cordinates) {
            setAddr({ street: cordinates.getAddress, city: cordinates.getCity, state: cordinates.getState, country: cordinates.getCountry, zip_code: cordinates.getZIP_Code });
        }
    }, [cordinates])

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setError((pre) => ({ ...pre, profile_img: "" }));
        }
        const reader = new FileReader();

        reader.onloadend = () => {
            const base64String = reader.result;
            setBase64Image(base64String);
        };

        reader.readAsDataURL(file);
    };

    const [checkid, setCheckid] = useState([]);
    const [state, setState] = useState({
        address: "",
        eAddress: ""
    })

    const constext = useContext(MyContext);
    const [country, setCountry] = useState([]);
    // useEffect(() => {
    //     if (constext.context && constext.context.country != "") {
    //         setCountry(constext.context.country);
    //     }
    // }, [constext.context])

    useEffect(() => {
        getCountryCode();
    }, [])

    const getCountryCode = async () => {
        const resp = await get_countries();
        let code = [];
        if (resp) {
            if (resp.data && resp.data.length > 0) {

                (resp.data).forEach(e => {
                    code.push({ value: e.code, label: e.name });
                });
                setCountry(code);
            } else setCountry([]);
        }
    }

    const gander = [
        { value: "male", label: "Male" },
        { value: "female", label: "Female" },
        { value: "other", label: "Other" },
    ];

    const statusops = [
        { value: "Active", label: "Active" },
        { value: "Deactive", label: "Inactive" }
    ]

    const employeeContractorOps = [
        { value: "Employee", label: "Employee" },
        { value: "Contractor", label: "Contractor" },
        { value: "Volunteer", label: "Volunteer" },
    ];

    const handleChange = (address) => {
        setState({ address: address, eAddress: "", });
    };

    const handleSelect = async (address) => {
        setState({ address: address });
    };

    const [error, setError] = useState({
        fname: "", lname: "", email: "", reg_number: "",
        countycode: "", mobile: "", gander: "", experience: "",
        rate_hourly: "", rate_flat_wage: "", start_date: "",
        dob: "", address: "", street: "", city: "",
        state: "", country: "", zip_code: "", status: "",
        employee_id: "", employee_contractor: "", about: "", profile_img: "",
    });

    const changeHandler = (e) => {
        const { name, value } = e.target;
        setError((pre) => ({ ...pre, [name]: "" }));
    }

    const isSubmit = async (event) => {
        event.preventDefault();
        let isValid = 1;

        const finalSkils = [];
        if (skillsdata) { skillsdata.map((e) => (finalSkils.push(e.id,))) }

        const formData = new FormData(event.target); // Create a FormData object from the form
        const formValues = Object.fromEntries(formData.entries());

        //   const profile_img = formValues.profile_img;
        const fname = formValues.fname;
        const lname = formValues.lname;
        const email = formValues.email;
        const reg_number = formValues.reg_number;
        const countycode = formValues.countycode;
        const mobile = formValues.mobile;
        const gander = formValues.gander;
        const experience = formValues.experience;
        const rate_hourly = formValues.rate_hourly;
        const rate_flat_wage = formValues.rate_flat_wage;
        // const start_date = new Date(formValues.start_date).getTime();
        const start_date = moment(formValues.start_date, "YYYY-MM-DD").unix();
        // const dob = new Date(formValues.dob).getTime();
        const dob = moment(formValues.dob, 'YYYY-MM-DD').unix();
        const address = cordinates.getAddress;
        const street = formValues.street;
        const city = formValues.city;
        const state = formValues.state;
        const country = formValues.country;
        const zip_code = formValues.zip_code;
        const status = formValues.state;
        const employee_id = formValues.employee_id;
        const employee_contractor = formValues.employee;
        const about = formValues.aboutUs;


        const profile_img = event.target.profile_img.files[0];   //formValues.profile_img[0];
        // const profile_img = formValues.profile_img.files[0];
        // const profile_img="";


        if (!fname) { setError((pre) => ({ ...pre, fname: "Required" })); isValid = 0 };
        if (!lname) { setError((pre) => ({ ...pre, lname: "Required" })); isValid = 3 };
        if (!email) { setError((pre) => ({ ...pre, email: "Required" })); isValid = 4 };
        // if (!reg_number) { setError((pre) => ({ ...pre, reg_number: "Required" })); isValid = 5 };
        if (!countycode) { setError((pre) => ({ ...pre, countycode: "Required" })); isValid = 6 };
        if (!mobile) { setError((pre) => ({ ...pre, mobile: "Required" })); isValid = 7 };
        // if (!gander) { setError((pre) => ({ ...pre, gander: "Required" })); isValid = 8 };
        // if (!experience) { setError((pre) => ({ ...pre, experience: "Required" })); isValid = 9 };
        // if (!rate_hourly) { setError((pre) => ({ ...pre, rate_hourly: "Required" })); isValid = 10 };
        // if (!rate_flat_wage) { setError((pre) => ({ ...pre, rate_flat_wage: "Required" })); isValid = 11 };
        if (!start_date) { setError((pre) => ({ ...pre, start_date: "Required" })); isValid = 12 };
        // if (!dob) { setError((pre) => ({ ...pre, dob: "Required" })); isValid = 13 };
        if (!address) { setError((pre) => ({ ...pre, address: "Required" })); isValid = 14 };
        if (!street) { setError((pre) => ({ ...pre, street: "Required" })); isValid = 15 };
        if (!city) { setError((pre) => ({ ...pre, city: "Required" })); isValid = 16 };
        if (!state) { setError((pre) => ({ ...pre, state: "Required" })); isValid = 17 };
        if (!country) { setError((pre) => ({ ...pre, country: "Required" })); isValid = 18 };
        if (!zip_code) { setError((pre) => ({ ...pre, zip_code: "Required" })); isValid = 19 };
        if (!status) { setError((pre) => ({ ...pre, status: "Required" })); isValid = 20 };
        // if (!employee_id) { setError((pre) => ({ ...pre, employee_id: "Required" })); isValid = 21 };
        if (!employee_contractor) { setError((pre) => ({ ...pre, employee_contractor: "Required" })); isValid = 22 };
        // if (!about) { setError((pre) => ({ ...pre, about: "Required" })); isValid = 23 };

        // if(!profile_img){setError ((pre) => ({...pre ,profile_img :"Required" })); isValid = 0 }; 



        if (isValid === 1) {
            setLoder(true);
            // event.target.reset();
            const finalData = new FormData();

            finalData.append("name", fname);
            finalData.append("lastname", lname);
            finalData.append("email", email);
            finalData.append("mobile", mobile);
            finalData.append("dob", dob);
            finalData.append("gender", gander);
            finalData.append("country_code", countycode);
            finalData.append("role", '4');
            finalData.append("skills", finalSkils);
            finalData.append("experience", experience);
            finalData.append("appointment_fees", rate_hourly);
            finalData.append("appointment_fees_type", rate_flat_wage);
            finalData.append("start_date", start_date);
            finalData.append("status", status);
            finalData.append("employee_contractor", employee_contractor);
            finalData.append("sin_no", "");
            finalData.append("image", profile_img);
            finalData.append("employee_id", employee_id);
            finalData.append("about", about);
            finalData.append("registration", reg_number);
            finalData.append("city", city);
            finalData.append("country", country);
            finalData.append("state", state);
            finalData.append("zipcode", zip_code);
            finalData.append("address", address);
            finalData.append("street", street);
            finalData.append("lattitude", cordinates.getLat);
            finalData.append("longitude", cordinates.getLng);

            const resp = await add_instructor(finalData);
            if (resp) {
                setLoder(false);
                Swal.fire({
                    html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
                    <h4 class="title text-uppercase text-center mt-4 textWhite">
                    SUCCESS
                    </h4>
                    <p class="text-center my-4 textcolor">
                    <small class="textWhite">
                    ${resp.data.message} </small></p> `,
                    showClass: {
                        popup: 'custom-dialog-container',
                    },
                    customClass: {
                        confirmButton: 'custom-button custom-button-size modalbtn',
                    },
                });
                event.target.reset();
                setSkillsdata();
                staffList();
                topandler();
            }
            setLoder(false);
        }
        setLoder(false);
    }

    const getskillsData = async () => {
        const preData = [];
        const resp = await get_skills();
        if (resp) {
            if (resp.data.data) {
                const data = resp.data.data;
                data.map((e) => (
                    preData.push({
                        id: e.id, label: e.name
                    })
                ))
                setPreskillsdata(preData);
            }
        }
    }

    useEffect(() => {
        getskillsData();
    }, [])

    return (
        <>
            <h4>STAFF/INSTRUCTOR PROFILE</h4>
            <Form autoComplete="off" onSubmit={isSubmit} encType="multipart/form-data">
                <Row>
                    <Col>
                        <div className="ProfilePhoto">
                            <InputField
                                accept="image/x-png,image/gif,image/jpeg,image/png"
                                type="file"
                                name="profile_img"
                                error={error.profile_img}
                                onChange={handleImageUpload}
                                className={"FileTypeHide"} />
                            <img
                                src={base64Image ? base64Image : "assets/images/image_Holder.png"}
                                alt=""
                                className="img-fluid"
                            />
                            <p>Change Image</p>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <InputField
                            type={"text"}
                            lable={"First Name"}
                            error={error.fname}
                            onChange={changeHandler}
                            placeholder={"Enter First Name"}
                            name="fname"
                        />
                    </Col>
                    <Col>
                        <InputField
                            type={"text"}
                            lable={"Last Name"}
                            name="lname"
                            error={error.lname}
                            onChange={changeHandler}
                            // value={pdata.address?pdata.address:''}
                            placeholder={"Enter Last Name"}
                        />
                    </Col>
                    <Col>
                        <InputField
                            type={"text"}
                            lable={"Email"}
                            error={error.email}
                            name="email"
                            onChange={changeHandler}
                            // value={pdata.owner_details?pdata.owner_details:''}
                            placeholder={"Enter Email"}
                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <InputField
                            type={"text"}
                            error={error.reg_number}
                            lable={"Registration Number"}
                            onChange={changeHandler}
                            name="reg_number"
                            // value={pdata.business_phone?pdata.business_phone:''}
                            placeholder={"Registration Number"}
                        />
                    </Col>
                    <Col>
                        <SharedSelect
                            lable={"Country Code"}
                            onChange={changeHandler}
                            options={country ? country : ""}
                            name={"countycode"}
                            error={error.country} />
                    </Col>
                    <Col>
                        <InputField
                            type={"number"}
                            lable={"Mobile/Cell"}
                            name="mobile"
                            maxLength={"10"}
                            error={error.mobile}
                            onChange={changeHandler}
                            // value={pdata.website?pdata.website:''}
                            placeholder={"Enter Number"}
                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <SharedSelect
                            lable={"Gender"}
                            name="gander"
                            error={error.gander}
                            options={gander}
                        />
                    </Col>
                    <Col>
                        <SharedMultiSelect
                            labelText="Skills/Specialisation"
                            setSkillsdata={setSkillsdata}
                            name="skills"
                            options={preskillsdata}
                        />
                    </Col>
                    <Col>
                        <InputField
                            type={"number"}
                            name="experience"
                            minLength={"0"}
                            error={error.experience}
                                onChange={changeHandler}
                            lable={"Years of Experience"}
                            placeholder={"Years of Experience"}
                        />
                    </Col>
                </Row>

                <Row className="mt-3">
                    <Col>
                        <InputField
                            type={"number"}
                            name="rate_hourly"
                            error={error.rate_hourly}
                            lable={"Hourly Wage Rate"}
                            placeholder={"Enter Rate"}
                        />
                    </Col>
                    <Col>
                        <InputField
                            type={"number"}
                            name="rate_flat_wage"
                            error={error.rate_flat_wage}
                            lable={"Flat Wage Rate"}
                            placeholder={"Enter Rate"}
                        />
                    </Col>
                    <Col>
                        <InputField type="date" lable="Start Date" onChange={changeHandler} name={"start_date"} error={error.start_date} />
                        {/* <MyDatePicker
                            name="start_date"
                            error={error.start_date}
                            DatepickerLabel={"Start Date"} /> */}
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <InputField type="date" lable="Date Of Birth" max={moment().format('YYYY-MM-DD')} onChange={changeHandler} name={"dob"} error={error.dob} />
                        {/* <MyDatePicker
                            name="dob"
                            error={error.dob}
                            DatepickerLabel={"Date Of Birth"} /> */}
                    </Col>
                    <Col>
                        <div className="form-group">
                            <label className="form-label inputLable" htmlFor="name">Address</label>
                            <PlaceAutoCompleted
                                //   label={'Head Quarters Address'}
                                setCordinates={setCordinates}
                            />
                            {/* <PlacesAutocomplete
                                name="address"
                                value={state.address}

                                onChange={handleChange}
                                onSelect={handleSelect}
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div>
                                        <input name="address" {...getInputProps({ placeholder: 'Search Places ...', className: ' form-control location-search-input', })} />
                                        <span className="text-danger"><small>{state.eAddress ? state.eAddress : ""}</small></span>
                                        <div className="autocomplete-dropdown-container m-0 position-absolute " style={{ zIndex: "2200", }}>
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map((suggestion, i) => {

                                                const className = suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item';
                                                // #fafafa
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                return (
                                                    <div key={i}
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style,
                                                        })}
                                                    >
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete> */}
                            <small>{error.address}</small>
                        </div>
                    </Col>
                    <Col>
                        <InputField
                            type={"text"}
                            lable={"Street"}
                            name="street"
                            value={addr.street ? addr.street : ''}
                            error={error.street}
                            // value={pdata.website?pdata.website:''}
                            placeholder={"Enter Street"}
                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <InputField
                            type={"text"}
                            lable={"City"}
                            name="city"
                            value={addr.city ? addr.city : ''}
                            error={error.city}
                            // value={pdata.business_phone?pdata.business_phone:''}
                            placeholder={"Enter City"}
                        />
                    </Col>
                    <Col>
                        <InputField
                            type={"text"}
                            lable={"Province State"}
                            name="state"
                            value={addr.state ? addr.state : ''}
                            error={error.state}
                            // value={pdata.business_phone?pdata.business_phone:''}
                            placeholder={"Enter State"}
                        />
                    </Col>
                    <Col>
                        <InputField
                            type={"text"}
                            lable={"Country"}
                            name="country"
                            error={error.country}
                            value={addr.country ? addr.country : ''}
                            // value={pdata.website?pdata.website:''}
                            placeholder={"Country"}
                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <InputField
                            type={"text"}
                            name="zip_code"
                            lable={"Postal/Zip Code"}
                            value={addr.zip_code ? addr.zip_code : ''}
                            error={error.zip_code}
                            // value={pdata.business_phone?pdata.business_phone:''}
                            placeholder={"Enter Zip Code"}
                        />
                    </Col>
                    <Col>
                        <SharedSelect
                            name="status"
                            options={statusops}
                            error={error.status}
                            lable={"Status"} />
                    </Col>
                    <Col>
                        <InputField
                            type={"text"}
                            lable={"Employee Id"}
                            name="employee_id"
                            error={error.employee_id}
                            // value={pdata.website?pdata.website:''}
                            placeholder={"Enter Employee Id"}
                        />
                    </Col>
                </Row>

                <Row className="mt-3">
                    <Col>
                        <SharedSelect
                            name="employee"
                            error={error.employee_contractor}
                            options={employeeContractorOps}
                            lable={"Employee/Contractor"} />
                    </Col>
                    <Col></Col>
                    <Col></Col>
                </Row>
                <Row className='mt-3'>
                    <Col>
                        <TextArea
                            name="aboutUs"
                            error={error.about}
                            lable={"About"} />
                    </Col>
                </Row>
                <Row className="py-5">
                    <Col>
                        <SharedButton
                            variant={"primary"}
                            type="submit"
                            className={"UpdatedBtn w-100"}
                            title={"Save & Update"}
                        />
                    </Col>
                    <Col>
                        <SharedButton
                            href={"/add-people"}
                            variant={"outline"}
                            type={"button"}
                            className={"CancelBtn w-100"}
                            title={"Done With Staff/Instructor"}
                        />
                    </Col>
                </Row>
            </Form>
        </>
    )
}
