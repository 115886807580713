import React, { useState, useEffect, useRef } from "react";
import moment from 'moment';

export const ServiceTime = (props) => {
  const [count, setCount] = useState(null);
  const [start, setStart] = useState();

  const counter_timer = useRef();
  const [shDate, setShDate] = useState();
  const [toTime, setToTime] = useState();
  const [from_time, setFrom_time] = useState();
  const [crstatus, setCrstatus] = useState();

  useEffect(() => {
    setShDate(props.shduleData);
    setToTime(props.toTime);
    setFrom_time(props.fromTime);
    setCrstatus(props.currentStatus);
  }, [props])



  const CounterChange = () => {
    // var x = setInterval(function () {
    const currentDate = moment().format('DD/MM/YYYY');
    const now = moment().format('HH:mm:ss');
    const then = moment.unix(from_time).format('YYYY-MM-DD HH:mm:ss');

    // Validation End Time
    const exthen = moment.unix(toTime).format('YYYY-MM-DD HH:mm:ss');
    const exTime = moment(exthen);

    var endTime = moment(then);
    const currentTime = moment();
    let counterData = '00:00:00';

    if (shDate >= currentDate) {
      if (exTime.isAfter(currentTime)) {
        if (endTime.isAfter(currentTime)) {
          const startTime = currentDate + ' ' + now;
          //const endTime = currentDate + ' ' + then;
          var counter = moment(endTime, "DD/MM/YYYY HH:mm:ss").diff(moment(startTime, "DD/MM/YYYY HH:mm:ss"));
          var d = moment.duration(counter);
          var days = (Math.floor(d.asDays()) < 10 ? '0' + Math.floor(d.asDays()) : Math.floor(d.asDays()));
          var hours = Math.floor(d.asHours()) - Math.floor(d.asDays()) * 24;
          var hours1 = (hours < 10 ? '0' + hours : hours);
          var s = days + ":" + hours1 + moment.utc(counter).format(":mm");
          counterData = s.toString();
          setCount(counterData);
          // counter_timer=(counterData);
        } else {
          setCount(null);
          setStart('Started');
        }
      } else {
        setCount(null);
        setStart('Completed');
      }
    } else {
      setCount(null);
      setStart('Completed');
    }

    //   if (counterData == '00:00:00') {
    //     clearInterval(x);
    //   }
    // }, 6000);
    // return toTime;
  }


  useEffect(() => {
    CounterChange();
  }, [shDate, toTime, from_time]);


  return (
    <>
      {count ?
        <>
          {/* <small>Starts in (DD:HH:MM):</small>
          <span className="dateTime"><b><h5>{'          ' + count ? count : ""}</h5></b></span> */}
        </>
        :
        <>
          {crstatus === 'Confirm' ? 
              <span><b><p style={{ color: "#77787B" }}></p></b></span>
           : crstatus === 'Completed' ? 
              <span><b><p style={{ color: "#77787B" }}>{'          ' + ("Pending Payment")}</p></b></span>
           : 
            <span><b><p style={{ color: "#77787B" }}>{'          ' + ("Completed")}</p></b></span>
          }

        </>
      }
    </>
  );
};
