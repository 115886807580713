import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Sidebar from "../comman_page/Sidebar";
import { HeaderDashboard } from "../comman_page/HeaderDashboard";
import { Footer } from "../../../../public/containers/footer/Footer";

import { Editprofileform } from "./Editprofileform";

export const EditProfile = () => {


  return (
    <>
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col className="p-0 RightCol">
              <HeaderDashboard />
              <div className="RightSide">
                <section className="addClient innerContent">
                  <Container className="pt-5">
                    <h4>PLEASE UPDATE YOUR PROFILE IT'S BEEN A LONG TIME...</h4>
                    <h6>UPLOAD NEW PHOTO</h6>
                    <div className='AddClientForm AddClientContent'>
                      <Editprofileform />
                    </div>
                  </Container>
                </section>
                <Footer />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
