import React from 'react'
import { InnerHeader } from '../../Components/inner-header/InnerHeader1'
import { SubHeader } from '../../Components/sub-header/SubHeader'
import { Innerfooter } from '../../Components/inner-footer/Innerfooter1'
import './StaffClassesDetail.css';
import { DashboardToSectionDetails } from '../../Components/CommanPages/DashboardToSectionDetails';
import { staff_business_workshop_details, staff_class_details, staff_getUserQuestionnaire, staff_passes_status_change } from '../../../Static_Services/Staff_Services';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import moment from 'moment';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { errorAlert, successAlert } from '../../../Static_Services/Alertmsg';

export const StaffClassesDetail = () => {
  const location = useLocation();
  const [stateData, setStateData] = useState();
  const [detailData, setDetailData] = useState();
  const currentUrl = location.pathname;
  const currYear = new Date().getFullYear();
  const navigate = useNavigate();

  const [covidinfo, setCovidinfo] = useState([]);

  const [gender, setGender] = useState('');
  const [checkType, setCheckType] = useState('');
  const [checkStatus, setCheckStatus] = useState('');
  const [checkStatusval, setCheckStatusval] = useState('');

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [covidModal, setCovidModal] = useState(false);
  const covidModalHandlerOpen = () => setCovidModal(true);
  const covidModalHandlerClose = () => setCovidModal(false);

  const [statusModal, setStatusModal] = useState(false);
  const statusModalhandleClose = () => setStatusModal(false);


  const getvalue = (event, name) => {
    if (name === 'gender') {
      setGender(event.target.value);
    } else if (name === 'check') {
      setCheckType(event.target.value);
    } else {
      setCheckStatus(event.target.value);
      setCheckStatusval(event.target.id);
    }
  }

  const applyModal = async () => {
    const fData = {
      "pageid": "1",
      "business_id": businessDetail.business_id,
      "class_id": stateData.class_id,
      "schedule_id": stateData.id,
      "customer_type": gender || '',
      "checkedin_type": checkType || '',
      "select_dt": stateData.scheduled_date
    }
    const resp = await staff_class_details(fData);
    if (resp) {
      setShow(false);
      setDetailData(resp.data.data);
    } else setDetailData();
  }

  const [businessDetail, setBusinessDetail] = useState();
  const bzDetails = localStorage.getItem('bzDetails');
  useEffect(() => {
    if (bzDetails) {
      setBusinessDetail(JSON.parse(bzDetails));
    }
  }, [bzDetails]);

  useEffect(() => {
    if (location && location.state) {
      setStateData(location.state);
    }
  }, [location])




  const calculateAge = (dob) => {
    if (typeof dob === 'string' && dob.match(/^\d{4}-\d{2}-\d{2}$/)) {
      const birthyear = dob.split('-');
      const age = currYear - birthyear[0];
      return age;
    } else {
      // Handle invalid or undefined dob here
      return 'Invalid date of birth';
    }
  };

  const [itemsMData, setItemsMData] = useState({
    checkStatus: '',
    customerId: '',
    attandancceId: '',
    PurchasedPassData: '',
  })

  const getStatusDetail = (items) => {
    const class_status = items.class_status;
    const customerId = items.id;
    const attandancceId = items.attendance_id;
    const PurchasedPassData = items.my_passes_details;

    setCheckStatus(class_status);
    setItemsMData({
      checkStatus: class_status,
      customerId: customerId,
      attandancceId: attandancceId,
      PurchasedPassData: PurchasedPassData,
    })
    setStatusModal(true);
  }


  const getDetils = async () => {
    if (currentUrl === '/class-detail') {
      const fData = {
        "pageid": "1",
        "business_id": businessDetail.business_id,
        "class_id": stateData.class_id,
        "schedule_id": stateData.id,
        "customer_type": "",
        "checkedin_type": "",
        "select_dt": stateData.scheduled_date
      }
      const resp = await staff_class_details(fData);
      if (resp) {
        setDetailData(resp.data.data);
      } else setDetailData();
    }
    // if(currentUrl === '/workshop-detail'){
    //   const fData={
    //     "business_id": businessDetail.business_id,
    //     "workshop_id": stateData.workshop_id,
    //     "schedule_id": stateData.id
    //   }
    //   const resp = await staff_business_workshop_details(fData);
    //   if (resp) {
    //     setDetailData(resp.data.data);
    //   } else setDetailData();
    // }

  }
  const ChangePass = async (type, id, userid, status, attandancceId) => {
    const fData = {
      "service_type": type,
      "service_id": id,
      "user_id": userid,
      "passes_status": status,
      "schedule_id": stateData.id,
      'attendance_id': attandancceId,
    }
    const resp = await staff_passes_status_change(fData);
    if (resp) {
      setStatusModal(false);
      successAlert(resp.data.message);
      getDetils();
    }
  }

  useEffect(() => {
    if (stateData) {
      getDetils();
    }
  }, [stateData])

  const getCovidInfo = async (id) => {
    const detail = {
      "userid": id,
      "business_id": businessDetail.business_id,
      "class_id": stateData.class_id
    };
    const resp = await staff_getUserQuestionnaire(detail);
    if (resp) {
      setCovidinfo(resp.data.data);
      setCovidModal(true);
    }
  }

const redirectUrl = () =>{
  // navigate(url,state:{})
}
 
  return (
    <>
      <InnerHeader />

      <div className="body-grey">
        <div className="studio-details client-home-inner">
          <div className="container">
            <DashboardToSectionDetails />

            <div className="text-right mb-3">
             <Link to={'/staff_client-search'} state={stateData && stateData ? stateData :''}> <button className="btn btn-blue-grad text-uppercase font-w-600 mr-2">Add Client</button></Link>
              <button type="button" className="btn btn-blue-grad px-2 text-uppercase" onClick={()=>window.history.back()} >Back </button>
            </div>
            {detailData &&
              <div className="home-staff pb-5">
                <div className="row staff-studio-details">
                  <div className="col-lg-4">
                    <h3 className="black-color font-w-500 font-18 mb-0 font-arialround mb-3 text-uppercase">Details</h3>
                    {currentUrl === '/workshop-detail' &&
                      <div className="class-detail-slot box-shadow1 border-radius6 p-4 bg-white">
                        <div className="d-flex detail-head">
                          <div className="class-img box-shadow1">
                            <img src={businessDetail && businessDetail.logo ? businessDetail.logo : ""} className="img-fluid" />
                          </div>
                          <h5 className="black-color classname-title mb-0 pl-2 font-arialround">
                            {detailData && detailData.workshop_name ? detailData.workshop_name : ""}
                          </h5>
                        </div>
                        <div className="form-group mt-4 col-10 offset-1 mb-2">
                          {detailData && detailData.class_end_status === '1' &&
                            <button type="button" className="btn btn-blue-grad px-3 text-uppercase" > In-Progress </button>
                          }
                          {detailData && detailData.class_end_status === '2' &&
                            <button type="button" className="btn btn-blue px-3 text-uppercase">Completed</button>
                          }
                        </div>
                      </div>
                    }
                    {currentUrl === '/class-detail' &&
                      <div className="class-detail-slot box-shadow1 border-radius6 p-4 bg-white" >
                        <div className="row">
                          <div className="col-2 mb-3 ">
                            <img src={businessDetail && businessDetail.logo ? businessDetail.logo : ''} className="new-log-img img-fluid" />
                          </div>
                          <div className="col-10 text-left mb-3">
                            <p className="black-color font-w-100 text-uppercase font-20 ml-2 ">{detailData && detailData.class_name ? detailData.class_name : ''}  </p>
                          </div>
                        </div>
                        <div className="form-group mt-4 col-10 offset-1 mb-2 text-center">
                          {detailData && detailData.class_end_status === '1' &&
                            <button type="button" className="btn btn-blue-grad px-3 text-uppercase" > In-Progress </button>
                          }
                          {detailData && detailData.class_end_status === '2' &&
                            <button type="button" className="btn btn-blue px-3 text-uppercase">Completed </button>
                          }
                        </div>
                      </div>
                    }
                  </div>
                  <div className="col-lg-4">
                    <h3 className="black-color font-w-500 font-18 font-arialround mb-3 text-uppercase">Other
                      Details</h3>
                    <div className="class-detail-slot box-shadow1 border-radius6 p-4 bg-white">
                      <div className="row">
                        <div className="col-6 border-right">
                          <p className="black-color mb-0 paratitle">Date</p>
                          <h5 className="black-color heading-titlenew mb-0">{detailData && detailData.scheduled_date ? moment.unix(detailData.scheduled_date).format('MMM DD YYYY') : ''}</h5>

                          <p className="black-color mb-0 mt-3 paratitle">Duration</p>
                          <h4 className="black-color mb-0">{detailData && detailData.duration ? detailData.duration : ''}</h4>
                        </div>

                        <div className="col-6">
                          <p className="black-color mb-0 paratitle">Start Time</p>
                          <h5 className="black-color heading-titlenew mb-0">{detailData && detailData.from_time_utc ? moment.unix(detailData.from_time_utc).format('hh:mm A') : ''}</h5>

                          <p className="black-color mb-0 mt-3 paratitle">Capacity</p>
                          <h5 className="black-color heading-titlenew mb-0">
                            {detailData && detailData.capacity_used ? detailData.capacity_used : ''}
                            /
                            {detailData && detailData.total_capacity ? detailData.total_capacity : ''}</h5>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-12">
                          <div className="text-left">
                            <p className="black-color mb-0 mt-3">Location</p>
                            {detailData && detailData.location_url ?
                              <h5 className="heading-titlenew black-color mb-0"><Link to={detailData.location_url} target="_blank" title={detailData.location_url}>{detailData && detailData.location ? detailData.location : ''}</Link></h5>
                              :
                              <h5 className="heading-titlenew black-color mb-0" >{detailData && detailData.location ? detailData.location : ''}</h5>
                            }
                          </div></div>

                        <div className="col-12 col-md-12">
                          <div className="text-left">
                            <p className="black-color mb-0 mt-3 paratitle"> Web Link </p>
                            {detailData && detailData.web_link ?
                              <h5 className="heading-titlenew black-color mb-0">
                                <Link to={detailData.web_link} target="_blank">
                                  <i className="fa fa-link" aria-hidden="true" title={detailData.web_link}></i>
                                </Link>
                              </h5>
                              :
                              <h5 className="heading-titlenew black-color mb-0">N/A </h5>
                            }
                          </div>  </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">

                    <h3 className="black-color font-w-500 font-18 font-arialround mb-3 text-uppercase">Description</h3>
                    <div className="class-detail-slot box-shadow1 border-radius6 p-4 bg-white ">
                      <h5 className="black-color heading-titlenew">Description</h5>
                      <p className="black-color paratitle font-arialround mb-0">{detailData && detailData.description ? detailData.description : ''}</p>
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <div className="row">
                    <div className="col-12">
                      <div className="white-bg box-shadow1 border-radius6 p-4">
                        <div className="row">
                          <div className="col-6">
                            <div className="section-heading pt-2 pb-4 text-left">
                              <p className="black-color font-w-100 text-uppercase font-20"> staff / instructors </p>
                            </div>
                          </div>
                          {/* <!-- end of col-6 --> */}

                          <div className="col-6">
                            <div className="section-heading pt-2 pb-3 text-right">
                            </div>
                          </div>
                          {/* <!-- end of col-6 --> */}
                        </div>
                        {/* <!-- end of row --> */}

                        <div className="row">
                          {detailData && detailData.timeframe && detailData.timeframe.map((itemss) => (
                            <div className="col-12 col-md-3 mb-4" >
                              <div className="activity-card box-shadow1 border-radius6 white-bg p-4">
                                <div className="col-12 mt-0 mt-md-4 mt-lg-0">
                                  <div className="activity-card-details text-left">
                                  <a href="Javascript:void(0);" onClick={() => redirectUrl(itemss.instructor_id)} >
                                      <div className="text-center">
                                        <div className="img-height box-shadow1 mx-auto">
                                          <img src={itemss.profile_img} className="img-fluid" />
                                        </div>
                                        <p className="black-color font-w-100 font-16 mt-3 mb-2">{`${itemss.name}  ${itemss.lastname}`} </p>
                                      </div>
                                    </a>
                                  </div>
                                </div>
                                {/* <!-- end of col-7 --> */}
                              </div>
                              {/* <!-- end of activity-card --> */}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                {/* <!-- </div> --> */}
                <div className="mt-4">
                  <div className="row py-4 align-items-center">
                    <div className="col-8">
                      <h5 className="title black-color font-w-500 text-uppercase font-16 mb-0"> Customer Details </h5>
                    </div>
                    <div className="col-4 text-right">
                      <small onClick={handleShow}>
                        <img src="clientassets/img/ic_filter.png" className="img-fluid box-shadow1" style={{ height: "46px", width: '56px' }} />
                      </small>
                      <button type="button" className="btn btn-blue-grad px-4 text-uppercase mb-0 ml-2">Reset </button>
                    </div>
                  </div>
                  {detailData.customer_details.length != 0 &&
                    <div className="row  mt-3" >
                      {detailData.customer_details && detailData.customer_details.map((items) => (
                        <div className="col-md-4 mb-4">
                          <div className="class-detail-slot-box box-shadow1 border-radius6 p-3 bg-white">
                            <div className="row">
                              <div className="col-3">
                                <a href="javascript:void(0)" >
                                  <div className="profile-img box-shadow1">
                                    <img src={items.profile_img} className="img-fluid" />
                                  </div>
                                </a>
                              </div>
                              <div className="col-7">
                                <h5 className="brand-color3 heading-titlenew mb-1">{items.name + ' ' + items.lastname}
                                </h5>
                                <ul className="d-flex">
                                  <li className="flex-fill">
                                    <p className="black-color paratitle font-w-400 font-14">{calculateAge(items.date_of_birth)},
                                      {items.gender}</p>
                                  </li>
                                </ul>
                                <p className="black-color paratitle font-14">Id: {items.pass_purchase_id}</p>

                                <div>
                                  {currentUrl == '/workshop-detail' &&
                                    <div className="d-flex align-items-center">
                                      {items.class_status === 3 ?
                                        (<p className="brand-color3 font-15 font-w-500 mb-2">
                                          <button className="btn btn-green-grad"></button>Completed
                                        </p>)
                                        : items.class_status === '4' ?
                                          (<p className="brand-color3 font-15 font-w-500 mb-2"> <button className="btn btn-green-grad"></button>Waiting</p>)
                                          : items.class_status === '5' ?
                                            (<p className="brand-color3 font-15 font-w-500 mb-2"> <button className="btn btn-green-grad"></button>Cancelled</p>)
                                            : items.class_status === '2' ?
                                              (<p className="brand-color3 font-15 font-w-500 mb-2"> <button className="btn btn-green-grad"></button>Checked-In</p>)
                                              : items.class_status === '6' ?
                                                (<p className="brand-color3 font-15 font-w-500 mb-2"> <button className="btn btn-green-grad"></button>Absent</p>)
                                                : items.class_status === '1' ?
                                                  (<button className="btn btn-green-grad flex-fill">
                                                    <button className="btn btn-green-grad"></button>
                                                    Signed-up
                                                  </button>)
                                                  : ''}

                                      <p>
                                        <a href="javascript:void(0);" className="text-success ml-2 mb-1" data-toggle="modal" data-target="#statusUpdate" data-backdrop="static" data-keyboard="false">
                                          <i className="far fa-edit"></i></a>
                                      </p>
                                    </div>
                                  }
                                  {currentUrl == '/class-detail' &&
                                    <div className="d-flex align-items-center" >
                                      {items.class_status === '3' ?
                                        (<p className="brand-color3 font-15 font-w-500 mb-2">
                                          <button className="btn btn-green-grad">Completed</button>
                                        </p>)
                                        : items.class_status === '4' ?
                                          (<p className="brand-color3 font-15 font-w-500 mb-2"> <button className="btn btn-green-grad">Waiting</button></p>)
                                          : items.class_status === '5' ?
                                            (<p className="brand-color3 font-15 font-w-500 mb-2" onClick={() => getStatusDetail(items)} > <button className="btn btn-green-grad">Cancelled</button></p>)
                                            : items.class_status === '2' ?

                                              (<p className="brand-color3 font-15 font-w-500 mb-2" onClick={() => getStatusDetail(items)} > <button className="btn btn-green-grad">Checked-In</button></p>)
                                              : items.class_status === '6' ?
                                                (<p className="brand-color3 font-15 font-w-500 mb-2" onClick={() => getStatusDetail(items)} > <button className="btn btn-green-grad">Absent</button></p>)
                                                : items.class_status === '1' ?
                                                  (<p className="brand-color3 font-15 font-w-500 mb-2" onClick={() => getStatusDetail(items)} > <button className="btn btn-green-grad">Signed-up</button></p>)
                                                  : ''}
                                    </div>
                                  }
                                </div>
                              </div>
                              <div className="col-1 text-right">
                                {/* {items.covid_info === 1 ?
                                  <div>
                                    {items.covid_status === 0 ?
                                      <button type="button" className="infotab text-success" onClick={() => getCovidInfo(items.id)} ><i
                                        className="fas fa-info-circle"></i></button>
                                      : items.covid_status === 1 ?
                                        <button type="button" className="infotab text-danger" onClick={() => getCovidInfo(items.id)}><i
                                          className="fas fa-info-circle"></i></button>
                                        : ''}
                                  </div>
                                  : ""} */}
                                <button data-toggle="modal" data-target="#Infocovid19" id="Covid19" style={{ display: "none" }}></button>
                              </div>

                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  }
                </div>
                {detailData.customer_details.length === 0 &&
                  <div className="row mt-3" >
                    <div className="col-4">
                    </div>
                    <div className="col-4">
                      <div className="text-center">
                        <p className="font-awesome black-color font-18 d-inline mx-2 "> No Customer Available</p>
                      </div>
                    </div>
                    <div className="col-4">
                    </div>
                  </div>
                }

              </div>
            }
          </div>
        </div>
      </div >
      <Innerfooter />


      {/* <!-- modal --> */}
      {/* <!-- Pass Select modal popup --> */}
      <Modal show={show} className="modal fade addfilter-modal">
        <div className="modal-dialog modal-dialog-centered" id="ownModalWidth" role="document">
          <div className="modal-content">
            <div className="modal-body light-bg box-shadow1 border-radius6 px-4 pb-4 px-md-5 pb-md-5" >
              <button type="button" className="close modalclose" style={{ cursor: 'pointer' }} onClick={handleClose}>
                <span aria-hidden="true"> <img src="clientassets/img/ic_close.png" className="img-fluid" /> </span>
              </button>
              <div className="form-group profile-gender mb-0 mt-5">
                <h4 className="brand-color3 text-left font-14 mb-3 font-w-500"> Show Only </h4>
                <div className="profile-gender-button">
                  <input type='radio' id='male' value="male" name='radio' onChange={(e) => getvalue(e, 'gender')} checked={gender === 'male'} />
                  <label for='male'>Male Customer</label>
                  <input type='radio' id='female' value="female" name='radio' onChange={(e) => getvalue(e, 'gender')} checked={gender === 'female'} />
                  <label for='female'>Female Customer</label>
                </div>
              </div>
              <div className="form-group profile-gender mb-0 mt-5">
                <h4 className="brand-color3 text-left font-14 mb-3 font-w-500"> Checked in type</h4>
                <div className="profile-gender-button">
                  <input type='radio' id="1" value="1" name='radio1' onChange={(e) => getvalue(e, 'check')} checked={checkType === '1'} />
                  <label for="1">Checked In</label>
                  <input type="radio" id="2" value="2" name="radio1" onChange={(e) => getvalue(e, 'check')} checked={checkType === '2'} />
                  <label for="2">Not Checked In</label>
                </div>
              </div>
              <div className="form-group mt-4">
                <button className="btn btn-green-grad text-uppercase d-block mx-auto px-5" onClick={applyModal}
                  data-dismiss="modal">Apply Filter</button>
              </div>
            </div>
            {/* <!-- end of modal-body --> */}
          </div>
        </div>
      </Modal>
      {/*  */}

      <Modal show={statusModal} className="modal fade statusUpdate-modal">
        <div className="modal-dialog modal-dialog-centered" id="ownModalWidth" role="document">
          <div className="modal-content">
            <div className="modal-body light-bg box-shadow1 border-radius6 px-4 pb-4 px-md-5 pb-md-5">
              <button type="button" className="close modalclose" onClick={statusModalhandleClose}>
                <span aria-hidden="true"> <img src="clientassets/img/ic_close.png" className="img-fluid" /> </span>
              </button>

              <div className="form-group profile-gender mb-0 mt-5">
                <h4 className="brand-color3 text-left font-14 mb-3 font-w-500">Do you want to change the status? </h4>
                <div className="profile-gender-button">
                  <input type='radio' id='notcheckin' value="1" name='radio3' onChange={(e) => getvalue(e, 'checkStatus')} checked={checkStatus === '1'} />
                  <label for='notcheckin'>Sign Up</label>
                  <input type='radio' id='checkin' value="2" name='radio3' onChange={(e) => getvalue(e, 'checkStatus')} checked={checkStatus === '2'} />
                  <label for='checkin'>Check-In</label>
                  <input type='radio' id='noshow' value="6" name='radio3' onChange={(e) => getvalue(e, 'checkStatus')} checked={checkStatus === '6'} />
                  <label for='noshow'>No show</label>
                  <input type='radio' id='cancel' value="5" name='radio3' onChange={(e) => getvalue(e, 'checkStatus')} checked={checkStatus === '5'} />
                  <label for='cancel'>Cancel</label>

                </div>
              </div>
              <div className="form-group mt-4">
                <button className="btn btn-green-grad text-uppercase d-block mx-auto px-5 w-50 mt-2" onClick={() => ChangePass(1, stateData.class_id, itemsMData.customerId, checkStatusval, itemsMData.attandancceId)}>Update</button>
                <button className="btn btn-blue-grad text-uppercase d-block mx-auto px-5 w-50 mt-2" onClick={() => setStatusModal(false)}>cancel</button>
                <button type="button" id="passselect" data-backdrop="static" data-keyboard="false"
                  data-toggle="modal" data-target="#PassSelectModal" style={{ display: "none" }}  ></button></div>

            </div>
            {/* <!-- end of modal-body --> */}

          </div>
        </div>
      </Modal>

      {/* <!-- Modal Popup (Change - Password ) --> */}


      {/* Covid Modal */}
      <Modal show={covidModal} className="modal fade">
        <div class="modal-dialog modal-dialog-centered" id="ownModalWidth" role="document">
          <div class="modal-content">
            <div class="modal-body white-bg box-shadow1 border-radius6 p-4">
              <h4> COVID 19 Pre-Screening Questionnaire
                Answer each of the following questions before entering the building:</h4>
      
              <form>
                {covidinfo.length > 0 && covidinfo.map((qq, i) => (
                  <div class="form-group profile-change-password">
                    <p class="text-left font-14 "> {qq.question_text}  </p>
                    {i !== 3 &&
                      <div class="d-flex flex-wrap form group">
                        <div class="profile-gender-button covid-positive">
                          <input type='radio' id={`${qq.id}1`} value="1" name={`radio${qq.id}`}
                            onChange={(e) => getvalue(e, '')} />
                          <label for='{{qq.id}}1'>Yes</label>
                        </div>
                        <div class="profile-gender-button">
                          <input type='radio' id="{{qq.id}}2" value="0" name='radio{{qq.id}}'
                            onChange={(e) => getvalue(e, '')}
                          />
                          <label for='{{qq.id}}2'>No</label>
                        </div>
                      </div>
                    }
                    {i === 3 &&
                      qq.question_answer.map((qqq) => (
                        <div class="d-flex flex-wrap form group symptoms">
                          <div class="checkboxtypebtn" >
                            <input type="checkbox" class="form-control" name="checkbox" id="{{qqq.id}}1" value="{{qqq.id}}"
                              checked="{{qqq.checked}}"
                              // [(ngModel)]="qqq.checked" [ngModelOptions]="{standalone: true}"
                              readOnly
                              disabled />
                            <label for="{{qqq.id}}1"><span>{qqq.text}</span></label>
                          </div>
                        </div>
                      )) }
                  </div>
                ))}

                <div class="form-group px-3 pt-0 pt-sm-4 mb-0 mb-sm-2">
                  <button type="button" data-dismiss="modal" id="btn_close1"
                    class="btn btn-blue-grad text-uppercase w-100 mt-3" data-backdrop="static" data-keyboard="false"
                    onClick={() => setCovidModal(false)}> close </button>
                </div >

              </form >
              <h5>
                Note:- If you are not feeling well, stay home and contact us over the phone or by email to cancel your visit.

              </h5>
            </div >
            {/* < !--end of modal - body-- > */}
            <div>
            </div>
          </div >
        </div >
      </Modal >





      <div className="modal fade modalwidth show" id="PassSelectModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body border-radius6 box-shadow1 p-5 white-bg">
              <div className="home-inner-available">
                <div className="mb-2 pb-1">
                  <p className="black-color font-w-100 text-uppercase font-20"> Purchased passes </p>
                </div>
                {/* <!-- end of Heading --> */}

                <div id="puchasePassmodal" className="carousel slide" data-ride="carousel" >
                  {/* <!-- Indicators --> */}
                  <ul className="carousel-indicators" >

                    <li data-target="#puchasePassmodal"></li>
                  </ul>

                  {/* <!-- The slideshow --> */}
                  <div className="carousel-inner" id="purchasePassPopupSlide">
                    <div className="carousel-item" >
                      <div className="available-card">
                        <div className="available-blue-card p-3 p-sm-4">

                          <div className="row mx-0 ">
                            <div className="pass-logo">
                              <img src="items.business_logo" className="img-fluid" />
                            </div>
                            <div className="flex-fill pr-4 pl-5 passTextwidth">
                              <h5 className="white-color font-14 mb-2 mt-0">items.pass_name</h5>

                              <h5 className="white-color font-14 mb-2 mt-0">$items.amount <span className="font-12"> Plus Tax </span></h5>
                            </div>
                          </div>
                          <div className="row mt-5 align-items-end">
                            <div className="col-8 text-left pr-0">
                              <p className="white-color paratitle">Remaining Classes : items.remaining_count of items.total_count</p>
                              <p className="white-color paratitle">Remaining Days : items.remaining_count of items.total_count</p>

                              <p className="mb-0 white-color paratitle"> Start Date : items.start_date_utc_new </p>
                              <p className="white-color paratitle">Expiration Date :items.end_date_utc_new</p>

                            </div>

                          </div>
                        </div>
                        <div className="studio-card-select">
                          <input type="radio" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row" >
                  <div className="col-4"></div>
                  <div className="col-4">
                    <div className="text-center">
                      <p className="font-awesome black-color font-18 d-inline mx-2 " > No Pass Purchased</p>
                    </div>
                  </div>
                  <div className="col-4"></div>

                </div>
                {/* <!-- end of home-inner-class --> */}
                <div className="form-group px-3 pt-0 pt-sm-4 mb-0 mb-sm-2 d-flex buttonWidth justify-content-center">
                  <button type="button" data-dismiss="modal" id="btn_close11" className="btn btn-green-grad text-uppercase mt-3 mr-3" data-backdrop="static" data-keyboard="false" > Submit </button>
                  <button type="button" data-dismiss="modal" id="btn_close1"
                    className="btn btn-blue-grad text-uppercase mt-3" data-backdrop="static" data-keyboard="false"> close </button>

                </div>
              </div>
              {/* <!-- end of home-inner-class --> */}

            </div>
          </div>
        </div>
      </div>


    </>
  )
}
