import React, { useState } from 'react'
import { InnerHeader } from '../../Components/inner-header/InnerHeader1'
import { SubHeader } from '../../Components/sub-header/SubHeader'
import { Innerfooter } from '../../Components/inner-footer/Innerfooter'
import { DashboardToSectionDetails } from '../../Components/CommanPages/DashboardToSectionDetails'
import { useEffect } from 'react'
import { Spinner } from 'react-bootstrap'
import { API_my_attendance, API_my_classes_attandance } from '../../../Static_Services/Staff_Services'
import moment from 'moment'
import { Link, useNavigate } from 'react-router-dom'

export const ClientMyAttendance = () => {
    const [loder, setLoder] = useState(false);
    const navigate = useNavigate();
    const [userDetail, setUserDetail] = useState();
    const [businessDetail, setBusinessDetail] = useState();
    const [attandanceDetail, setAttandanceDetail] = useState([]);
    const [summaryData, setSummaryData] = useState();

    useEffect(() => {
        if (JSON.parse(localStorage.getItem('w_loginDetail'))) {
            setUserDetail(JSON.parse(localStorage.getItem('w_loginDetail')));
        }
        if (JSON.parse(localStorage.getItem('w_BusinessDetail'))) {
            setBusinessDetail(JSON.parse(localStorage.getItem('w_BusinessDetail')));
        }
    }, [])

    const attandanceList = async (status) => {
        setLoder(true);
        const detail = { "pageid": "1", "business_id": businessDetail.business_id, 'attendance_for': status };
        const resp = await API_my_classes_attandance(detail);
        if (resp) {
            const response = resp.data;
            if (response.data.length != 0) {
                response.data.map((el) => {
                    if (el.attendance_for == 2) {
                        // Date Conversion UTC
                        el.start_date_utc_new = moment.unix(el.shift_date).format('MMM DD YYYY');
                        //time conversion
                        el.from_time_utc_new = moment.unix(el.start_time).format('hh mm A');
                        el.to_time_utc_new = moment.unix(el.end_time).format('hh mm A');
                    } else {
                        // Date Conversion UTC
                        el.start_date_utc_new = moment.unix(el.start_date_utc).format('MMM DD YYYY');
                        el.end_date_utc_new = moment.unix(el.end_date_utc).format('MMM DD YYYY');
                        //time conversion
                        el.from_time_utc_new = moment.unix(el.from_time_utc).format('hh mm A');
                    }

                })
            }
            setAttandanceDetail(response.data);
            setLoder(false);
        } else {
            setAttandanceDetail([]);
            setLoder(false);
        }
    }

    const reset = () => {
        navigate('/studio-detail');
    }
    const redirectUrl = (url, Id, scheId, current) => {
        const detail = { 'id': Id, 'currentdate': current, 'schedule_id': scheId, 'backurl': '/my-attendance' };
        navigate(url, { state: { Id: detail } });
    }

    const showHide = (event) => {
        attandanceList(event.target.value);
    }

    const getSummary = async () => {
        setLoder(true);
        const detail = { 'business_id': businessDetail.business_id };
        const resp = await API_my_attendance(detail);
        if (resp) {
            const response = resp.data;
            setSummaryData(response.data);
            setLoder(false)
        } else {
            setLoder(false)
        }
    }
    useEffect(() => {
        if (businessDetail) {
            getSummary();
        }
    }, [businessDetail])




    return (
        <>
            {loder ? <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div> : ""}
            <InnerHeader />
            <div className="body-grey">
                <div class="studio-details">
                    <div class="container">
                        <DashboardToSectionDetails />
                        <div class="studio-details-lower pb-5">
                            <div class="row mt-2">
                                <div class="col-sm-4 col-lg-8">
                                    <h4 class="black-color font-w-400 font-18 mb-0 text-uppercase font-arialrouded">My Attendance</h4>
                                </div>
                                <div class="col-md-4 col-sm-6 text-right mb-3">
                                    <button type="button" class="btn btn-blue-grad px-2 text-uppercase"
                                        onClick={() => reset()}
                                    >Back </button>

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="white-bg border-radius6 box-shadow1 p-3">
                                        <div class="col-12">
                                            <h4 class="black-color font-w-400 font-18 mb-0 text-uppercase">Last 30 day Vs Prior 30 days Attendance
                                                is :
                                                {summaryData && summaryData.attendance_status == 1 &&
                                                    <span class="green-color font-18" >
                                                        {summaryData.description}</span>}
                                                {summaryData && summaryData.attendance_status == 0 &&
                                                    <span class="red-color font-18" >
                                                        {summaryData.description}</span>}
                                            </h4>
                                            <h4 class="black-color font-w-400 font-18 mb-0 text-uppercase">Summary: </h4>
                                            <p class="black-color  font-16">{summaryData && summaryData.current_month}</p>
                                            <p class="black-color  font-16"> {summaryData && summaryData.previous_month} </p>
                                        </div>
                                        {attandanceDetail.length != 0 &&
                                            <div class="centered-cover" >
                                                <div class="flex-break">
                                                    <div class="box my-attendence pt-2 ">
                                                        <div class="activity-card-section pt-3">
                                                            <div class="row">
                                                                {attandanceDetail.map((items) => (
                                                                    <div class="col-12 col-md-4 mb-4" >
                                                                        {items.attendance_for == 1 &&
                                                                            <div class="activity-card box-shadow1 border-radius6 white-bg p-2" >
                                                                                <div class="row">
                                                                                    <div class="col-3 col-sm-3 col-md-12 col-lg-3">
                                                                                        <div class="activity-card-logo">
                                                                                            <img src={businessDetail.logo} class="box-shadow1 new-log-img" />
                                                                                        </div>
                                                                                    </div>
                                                                                    {/* <!-- end of col-3 --> */}
                                                                                    <div class="col-9 col-sm-9 col-md-12 col-lg-9 pl-0 pl-sm-0 pl-md-3 pl-lg-0">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-7 col-md-12 col-lg-7 mt-0 mt-md-4 mt-lg-0 pl-0 pl-sm-0 pl-md-3 pl-lg-0">
                                                                                                <div class="activity-card-details text-left">
                                                                                                    <h5 class="classname-title black-color mb-0"> {items.class_name}</h5>
                                                                                                    {items.instructor_details[0] &&
                                                                                                        <ul class="client-inner-align">
                                                                                                            <li>
                                                                                                                <div class="subinst-profileimg"> <img
                                                                                                                    src={items.instructor_details[0].profile_img} class="img-fluid" />
                                                                                                                </div>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                <h5 class="black-color mb-0 classname-title pl-2">
                                                                                                                    {items.instructor_details[0].name}
                                                                                                                    {items.instructor_details[0].lastname} </h5>
                                                                                                            </li>
                                                                                                        </ul>
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-5 col-md-12 col-lg-5 pr-1">
                                                                                                <div class="text-right pt-1">
                                                                                                    <p class="black-color font-w-100 font-14 mr-1 mb-1"> {items.start_date_utc_new}
                                                                                                    </p>
                                                                                                    <div class="card-green-box text-center pt-2">
                                                                                                        <p class="white-color mb-0 mt-1"> {items.capacity_used}  /
                                                                                                            {items.total_capacity} </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="row pt-2">
                                                                                            <p class="black-color font-w-100 paratitle mb-0">Location :
                                                                                            </p>
                                                                                            {items.location_url &&
                                                                                                <h5 class="heading-titlenew ml-2 mb-0 black-color">
                                                                                                    <Link to={items.location_url} target="_blank">{items.location}</Link></h5>}
                                                                                            {!items.location_url &&
                                                                                                <h5 class="heading-titlenew ml-2 mb-0 black-color">
                                                                                                    {items.location}</h5>}

                                                                                        </div>

                                                                                        <div class="row pt-2">
                                                                                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 px-0 border-right">
                                                                                                <div class="text-left">
                                                                                                    <p class="paratitle black-color mb-1"> Start Time </p>
                                                                                                    <h5 class="classname-title black-color mb-0"> {items.from_time_utc_new} </h5>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 d-flex justify-content-end">
                                                                                                <div class="text-left">
                                                                                                    <p class="paratitle black-color mb-1"> Duration </p>
                                                                                                    <h5 class="classname-title black-color mb-0"> {items.duration} </h5>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        {items.attendance_for == 3 &&
                                                                            <div class="activity-card box-shadow1 border-radius6 white-bg p-2">
                                                                                <div class="row">
                                                                                    <div class="col-3 col-sm-3 col-md-12 col-lg-3">
                                                                                        <div class="activity-card-logo">
                                                                                            <img src={businessDetail.logo} class="box-shadow1 new-log-img" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-9 col-sm-9 col-md-12 col-lg-9 pl-0 pl-sm-0 pl-md-3 pl-lg-0">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-7 col-md-12 col-lg-7 mt-0 mt-md-4 mt-lg-0 pl-0 pl-sm-0 pl-md-3 pl-lg-0">
                                                                                                <div class="activity-card-details text-left">
                                                                                                    <h5 class="classname-title black-color mb-0"> {items.class_name}</h5>
                                                                                                    {items.instructor_details[0] &&
                                                                                                        <ul class="client-inner-align" >
                                                                                                            <li>
                                                                                                                <div class="subinst-profileimg"> <img
                                                                                                                    src={items.instructor_details[0].profile_img} class="img-fluid" />
                                                                                                                </div>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                <h5 class="black-color mb-0 classname-title pl-2">
                                                                                                                    {items.instructor_details[0].name}
                                                                                                                    {items.instructor_details[0].lastname}</h5>
                                                                                                            </li>
                                                                                                        </ul>
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-5 col-md-12 col-lg-5 pr-1">
                                                                                                <div class="text-right pt-1">
                                                                                                    <p class="black-color font-w-100 font-14 mr-1 mb-1"> {items.start_date_utc_new}
                                                                                                    </p>
                                                                                                    <div class="card-green-box text-center pt-2">
                                                                                                        <p class="white-color mb-0 mt-1"> {items.capacity_used}  /
                                                                                                            {items.total_capacity}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="row pt-2">
                                                                                            <p class="black-color font-w-100 paratitle mb-0">Location :
                                                                                            </p>
                                                                                            {items.location_url &&
                                                                                                <h5 class="heading-titlenew ml-2 mb-0 black-color"><a
                                                                                                    href="items.location_url" target="_blank">{items.location}</a></h5>}
                                                                                            {!items.location_url &&
                                                                                                <h5 class="heading-titlenew ml-2 mb-0 black-color">
                                                                                                    {items.location}</h5>}
                                                                                        </div>

                                                                                        <div class="row pt-2">
                                                                                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 px-0 border-right">
                                                                                                <div class="text-left">
                                                                                                    <p class="paratitle black-color mb-1"> Start Time </p>
                                                                                                    <h5 class="classname-title black-color mb-0"> {items.from_time_utc_new} </h5>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 d-flex justify-content-end">
                                                                                                <div class="text-left">
                                                                                                    <p class="paratitle black-color mb-1"> Duration </p>
                                                                                                    <h5 class="classname-title black-color mb-0"> {items.duration} </h5>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        {items.attendance_for == 2 &&
                                                                            <div class="activity-card box-shadow1 border-radius6 white-bg p-2">
                                                                                <div class="row">
                                                                                    <div class="col-3 col-sm-3 col-md-12 col-lg-3">
                                                                                        <div class="activity-card-logo">
                                                                                            <img src={businessDetail.logo} class="box-shadow1 new-log-img" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-9 col-sm-9 col-md-12 col-lg-9 pl-0 pl-sm-0 pl-md-3 pl-lg-0">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-7 col-md-12 col-lg-7 mt-0 mt-md-4 mt-lg-0 pl-0 pl-sm-0 pl-md-3 pl-lg-0">
                                                                                                <div class="activity-card-details text-left">
                                                                                                    <h5 class="classname-title black-color mb-0"> {items.service_name}</h5>
                                                                                                    <ul class="client-inner-align">
                                                                                                        <li>
                                                                                                            <div class="subinst-profileimg"> <img src={items.instructor_profile_img}
                                                                                                                class="img-fluid" /> </div>
                                                                                                        </li>
                                                                                                        <li>
                                                                                                            <h5 class="black-color mb-0 classname-title pl-2"> {items.instructor_name}
                                                                                                                {items.instructor_lastname} </h5>
                                                                                                        </li>
                                                                                                    </ul>

                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-5 col-md-12 col-lg-5 pr-1">
                                                                                                <div class="text-right pt-1">
                                                                                                    <p class="black-color font-w-100 font-14 mr-1 mb-1"> {items.start_date_utc_new}
                                                                                                    </p>
                                                                                                    <div class="card-green-box text-center pt-2">
                                                                                                        <p class="white-color mb-0 mt-1">{items.duration} Min.</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="row pt-4">
                                                                                            <p class="black-color font-w-100 paratitle mb-1">
                                                                                            </p>
                                                                                            <h5 class="heading-titlenew ml-2 mb-0 black-color"></h5>
                                                                                        </div>

                                                                                        <div class="row pt-2">
                                                                                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 px-0 border-right">
                                                                                                <div class="text-left">
                                                                                                    <p class="paratitle black-color mb-1"> Start Time </p>
                                                                                                    <h5 class="classname-title black-color mb-0"> {items.from_time_utc_new}</h5>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 d-flex justify-content-end">
                                                                                                <div class="text-left">
                                                                                                    <p class="paratitle black-color mb-1"> End Time </p>
                                                                                                    <h5 class="classname-title black-color mb-0"> {items.to_time_utc_new} </h5>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Innerfooter />

        </>
    )
}
