import React, { useState } from "react";
import { useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";

export const ClassesCard = (props) => {
  const [finalData,setFinalData] = useState([]);
  const data = props.data ? props.data : "";
  const upcomingCount = props.upcommingCount ? props.upcommingCount : "";
  const fromTime = props.fromTime ? props.fromTime : "";
  const toTime = props.toTime ? props.toTime : "";

  const getTime = (timestamp) => {
    const date = new Date(parseInt(timestamp) * 1000);
    const options = {
      timeZone: 'Asia/Kolkata',
      hour: 'numeric',
      minute: 'numeric',
    };
    const formattedTime = date.toLocaleString('en-US', options);
    return formattedTime;
  };

  useEffect(() => {
    const classCardDetails = [];
    if (data!="") {
      data.forEach((value) => {
        classCardDetails.push({
          classesDate: value.scheduled_date,
          ClassesTime: `${getTime(fromTime)} to ${getTime(toTime)}`,
        })
      });
      setFinalData(classCardDetails);
    }
  }, [data]);



  //   {
  //     classesDate: "20/06/2023",
  //     ClassesTime: `${getTime(fromTime)} to ${getTime(toTime)}`
  //   },
  //   {
  //     classesDate: "20/06/2023",
  //     ClassesTime: "08:00 to 09:00 Am"
  //   },
  //   {
  //     classesDate: "20/06/2023",
  //     ClassesTime: "08:00 to 09:00 Am"
  //   },
  //   {
  //     classesDate: "20/06/2023",
  //     ClassesTime: "08:00 to 09:00 Am"
  //   },
  //   {
  //     classesDate: "20/06/2023",
  //     ClassesTime: "08:00 to 09:00 Am"
  //   },
  //   {
  //     classesDate: "20/06/2023",
  //     ClassesTime: "08:00 to 09:00 Am"
  //   },
  // ];
  return (
    <>
      <div className="classesCards mt-5">
        <Row>
          {finalData.map((value, index) => (
            <Col md={3} className="mb-3" key={index}>
              <Card key={index}>
                <Card.Body>
                  <Card.Title>{value.classesDate}</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    {value.ClassesTime}
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
};