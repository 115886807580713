import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Spinner } from "react-bootstrap";
import { SharedSelect } from "../../../../../sharedComponents/SharedSelect";
import { SharedButton } from "../../../../../sharedComponents/Button";
import { InputField } from "../../../../../sharedComponents/InputField";
import { TextArea } from "../../../../../sharedComponents/TextArea";
import { Footer } from "../../../../../public/containers/footer/Footer";
import { HeaderDashboard } from "../../comman_page/HeaderDashboard";
import { SharedMultiSelect } from "../../../../../sharedComponents/SharedMultiSelect";
import { DataTable } from "../../../../../sharedComponents/DataTable";
import Sidebar from "../../comman_page/Sidebar";
import { InstructorList, add_services, book_appointment } from "../../../../../Studio-Services/Studio_Services";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import logo from "../../../../../Images/logo_white_web.png"
import { InputTypeTax } from "../../../../../sharedComponents/InputTypeTax";


export const Addservice = () => {

  const [loder, setLoder] = useState(false);
  const [tabledata, setTabledata] = useState([]);
  const [optiontax1, setOptiontax1] = useState();
  const [optiontax2, setOptiontax2] = useState();
  const [instOption, setInstOption] = useState([]);
  const [skillsdata, setSkillsdata] = useState();

  const columns = [
    { dataField: "sr", text: "S/N" },
    { dataField: "name", text: "Name" },
    { dataField: "rate_price", text: "Retail Price" },
    { dataField: "tax01", text: "Tax1" },
    { dataField: "tax02", text: "Tax2" },
    { dataField: "action", text: "Action" },
  ];


  const getService = async () => {
    const fData = { "pageid": 1 }
    const resp = await book_appointment(fData);
    if (resp) {
      const td = [];
      (resp.data).map((e, index) => {
        td.push({
          sr: index + 1, name: e.service_name, rate_price: e.amount, tax01: e.tax1, tax02: e.tax2, action: <Link to="/view-service" state={{ data: e }} ><i _ngcontent-gft-c168="" className="fa fa-eye"></i></Link>,
        })
      })
      setTabledata(td);
    }
  }

  const intructorList = async () => {
    const fData = {
      "pageid": 1
    }
    const resp = await InstructorList(fData);
    if (resp) {
      const instOpt = [];
      (resp.data.data).forEach(e => {
        if (e.business_status === "Active" && e.status === "Approve") {
          instOpt.push({
            value: e.user_id, label: `${e.name} ${e.lastname}`
          })
        }
      })
      setInstOption(instOpt);
    }
  }

  useEffect(() => {
    intructorList();
    getService();
  }, [])

  const Optiontax1Handler = (e) => {
    const value = e.target.value;
    setOptiontax1(value);
  }

  const Optiontax2Handler = (e) => {
    const value = e.target.value;
    setOptiontax2(value);
  }

  const option = [
    { value: "no", label: "No" },
    { value: "yes", label: "Yes" }
  ]

  const timeOption = [
    { value: "0", label: <>0</>, },
    { value: "10", label: <>10</>, },
    { value: "15", label: <>15</>, },
    { value: "30", label: <>30</>, },
    { value: "45", label: <>45</>, },
    { value: "60", label: <>60</>, },
  ]


  const [error, setError] = useState({
    service_name: "",
    duration: "",
    cancel_policy: "",
    amount: "",
    tax1: "",
    tax2: "",
    tip_option: "",
    description: "",
    time_needed: "",
    instructor: "",
    is_client_visible: "",
  })

  const subMitHandler = async (e) => {
    e.preventDefault();
    const intIds = [];
    if (skillsdata.length > 0) {
      skillsdata.forEach((e) => {
        intIds.push(e.value);
      })
    }


    const formData = new FormData(e.target);
    const formValue = Object.fromEntries(formData.entries());
    let isValid = 1;

    const service_name = formValue.service_name;
    const duration = formValue.duration;
    const cancel_policy = formValue.cancel_policy;
    const amount = formValue.amount;
    const tax1 = formValue.tax1;
    const tax2 = formValue.tax2;
    const tip_option = formValue.tip_option;
    const description = formValue.description;
    const time_needed = formValue.time_needed;
    const instructorIds = intIds;
    const is_client_visible = formValue.is_client_visible;
    const tax1_rate = formValue.tax1_rate ? formValue.tax1_rate : 0;
    const tax2_rate = formValue.tax2_rate ? formValue.tax2_rate : 0;



    if (!service_name) { setError((pre) => ({ ...pre, service_name: "* Required" })); isValid = 2; }
    if (!duration) { setError((pre) => ({ ...pre, duration: "* Required" })); isValid = 3; }
    if (!cancel_policy) { setError((pre) => ({ ...pre, cancel_policy: "* Required" })); isValid = 4; }
    if (!amount) { setError((pre) => ({ ...pre, amount: "* Required" })); isValid = 5; }
    if (!tax1) { setError((pre) => ({ ...pre, tax1: "* Required" })); isValid = 6; }
    if (!tax2) { setError((pre) => ({ ...pre, tax2: "* Required" })); isValid = 7; }
    if (!tip_option) { setError((pre) => ({ ...pre, tip_option: "* Required" })); isValid = 8; }
    if (!description) { setError((pre) => ({ ...pre, description: "* Required" })); isValid = 9; }
    if (!time_needed) { setError((pre) => ({ ...pre, time_needed: "* Required" })); isValid = 10; }
    if (instructorIds.length === 0) { setError((pre) => ({ ...pre, instructor: "* Required" })); isValid = 11; }
    if (!is_client_visible) { setError((pre) => ({ ...pre, is_client_visible: "* Required" })); isValid = 12; }

    if (tax1 == 'yes') {
      if (tax1_rate >= 100) {
        alert('Please enter valid tax percentage');
        isValid = 13;
      }
    }
    if (tax2 == 'yes') {
      if (tax2_rate >= 100) {
        alert('Please enter valid tax percentage');
        isValid = 14;
      }
    }
    if (isValid === 1) {
      setLoder(true);
      const fData = {
        "service_name": formValue.service_name,
        "duration": formValue.duration,
        "cancel_policy": formValue.cancel_policy,
        "amount": formValue.amount,
        "tax1": formValue.tax1,
        "tax2": formValue.tax2,
        "tip_option": formValue.tip_option,
        "description": formValue.description,
        "time_needed": formValue.time_needed,     
        "instructor" : instructorIds,
        "tax1_rate": formValue.tax1_rate ? formValue.tax1_rate : 0,
        "tax1_label": "",
        "tax2_rate": formValue.tax2_rate ? formValue.tax2_rate : 0,
        "tax2_label": "",
        "is_client_visible": formValue.is_client_visible
      }

      const resp = await add_services(fData);
      if (resp) {
        setLoder(false);
        // Reset the form after processing
        e.target.reset();

        Swal.fire({
          html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
                Success
          </h4>
          <p class="text-center my-4 textcolor">
          <small class="textWhite">
          ${resp.data.message} </small></p>
          `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        });
      } else {
        setLoder(false);
      }

    }

  }

  const errorHandler = (e) => {
    const { name } = e.target;
    setError((pre) => ({ ...pre, [name]: "" }))
  }


  return (
    <>
      {loder ? <div className="MainLoader"><Spinner animation="border" /> </div> : ""}
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col>
              <HeaderDashboard />
              <div className="RightSide">
                <div className="innerContent">
                  <section className="ListYourService ">
                    <Container>
                      <h4 className="mt-4">List Your Service</h4>
                      <p>
                        Create a set of services you will offer your customers.  These are tied to appointments a customer can make with the appropriate staff and book a time during one of their shifts.
                      </p>

                      <div className="ServiceList">
                        <Row>
                          <Col>
                            <h6>Service List</h6>
                            <DataTable data={tabledata} columns={columns} />
                          </Col>
                        </Row>
                        <Form onSubmit={subMitHandler}>
                          <Row>
                            <Col>
                              <InputField
                                type={"text"}
                                error={error.service_name}
                                onChange={errorHandler}
                                name="service_name"
                                lable={"Service Offered"}
                              />
                            </Col>
                            <Col>
                              <InputField
                                type={"number"}
                                name="duration"
                                lable={"Duration [Minutes]"}
                                onChange={errorHandler}
                                error={error.duration}
                              />
                            </Col>
                            <Col>
                              <SharedMultiSelect
                                labelText="Instructor"
                                setSkillsdata={setSkillsdata}
                                name="instructor_id"
                                options={instOption}
                                error={error.instructor}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <InputTypeTax
                                type={"number"}
                                name="amount"
                                error={error.amount}
                                onChange={errorHandler}
                                lable={"Retail Price"}
                              />
                            </Col>
                            <Col>
                              <SharedSelect
                                name="is_client_visible"
                                options={option}
                                error={error.is_client_visible}
                                onChange={errorHandler}
                                lable={"Visible to client"} />
                            </Col>
                            <Col>
                              <SharedSelect
                                name="tip_option"
                                error={error.tip_option}
                                options={option}
                                lable={"Tip Prompt"}
                              />
                            </Col>
                          </Row>
                          <Row>
                          <Col>
                                <SharedSelect
                                  name="tax1"
                                  options={option}
                                  onChange={Optiontax1Handler}
                                  error={error.tax1}
                                  lable={"Tax 1 Applicable"} />
                              </Col>
                            {optiontax1 === "yes" ?
                              <Col>
                                <InputTypeTax
                                  type={"number"}
                                  name="tax1_rate"
                                  required
                                  lable={"Tax 1 Rate %"}
                                />
                              </Col>
                              : ""}
                            <Col>
                              <SharedSelect
                                name="tax2"
                                options={option}
                                onChange={Optiontax2Handler}
                                error={error.tax2}
                                lable={"Tax 2 Applicable"} />
                            </Col>
                            {optiontax2 === "yes" ?
                              <Col>
                                <InputTypeTax
                                  type={"number"}
                                  name="tax2_rate"
                                  required
                                  lable={"Tax 2 Rate %"}
                                />
                              </Col>
                              : ""}
                         
                          </Row>
                          <Row className="mt-2 mb-2">
                            <Col>
                              <SharedSelect
                                name="time_needed"
                                error={error.time_needed}
                                options={timeOption}
                                onChange={errorHandler}
                                lable={"Time needed before the next service can start (min)"}
                              />
                            </Col>
                            <Col></Col>
                          </Row>
                          <Row>
                            <Col>
                              <TextArea
                                lable={"Cancel Policy"}
                                error={error.cancel_policy}
                                onChange={errorHandler}
                                name="cancel_policy"
                              />
                            </Col>
                            <Col>
                              <TextArea
                                lable={"Description"}
                                error={error.description}
                                onChange={errorHandler}
                                name="description"

                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Row className="mt-3">
                                <Col>
                                  <SharedButton
                                    title="Save"
                                    className="btn btn-primary w-100"
                                    type="submit"
                                    variant="primary"
                                  />
                                </Col>
                                <Col>
                                  <SharedButton
                                    title="Done with Service"
                                    className="btn btn-outline w-100"
                                    href="/add-things"
                                    type="button"
                                    variant="outline"
                                  />
                                </Col>
                                <Col></Col>
                              </Row>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    </Container>
                  </section>
                </div>
                <Footer />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
