import moment from "moment";
import React from "react";
import { Carousel, Card, Row, Col } from "react-bootstrap";
import { FaLongArrowAltRight, FaLongArrowAltLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export const ClinetProductDetailSlider = ({ pass, getPassId, checkedPassId, businessDetail, redirectUrl }) => {
    const navigate = useNavigate();
    const cardDetail = pass ? pass : [];
    const isChecked = checkedPassId || "";

    const chunks = [];
    for (let i = 0; i < cardDetail.length; i += 2) {
        chunks.push(cardDetail.slice(i, i + 2));
    }

    const settings = {
        prevIcon: <FaLongArrowAltLeft />,
        nextIcon: <FaLongArrowAltRight />,
        interval: 3000,
        pauseOnHover: true,
        wrap: true,
        touch: true,
        keyboard: true,
        slidesToShow: 2,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <>
            {chunks.length > 0 && (
                <Carousel {...settings} className="staffSlider">
                    {chunks.map((chunk, index) => (
                        <Carousel.Item key={index}>
                            <Row className="mb-5 staffSlider">
                                {chunk.map((images, cardIndex) => (
                                    <Col lg={6} md={6} xs={12} key={cardIndex}>
                                        <div class="border-radius6 box-shadow1 white-bg p-3" >
                                            <img src={images && images.image_name ? images.image_name :''} class="img-fluid w-100" style="height: 221px;" />
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </Carousel.Item>
                    ))}
                </Carousel>
            )}
        </>
    );
};
