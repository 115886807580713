import React from 'react'
import { InnerHeader } from '../../Components/inner-header/InnerHeader1'
import { Innerfooter } from '../../Components/inner-footer/Innerfooter1'
import './StaffCart.css'
import { ExpiryCheck, staff_cart_list, staff_pay_at_desk, staff_remove_cart } from '../../../Static_Services/Staff_Services'
import { cardGet, clover_pay_checkout_single } from '../../../Studio-Services/Studio_Services'
import { useLocation, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useEffect } from 'react'
import { Spinner } from 'react-bootstrap'
import { successAlert } from '../../../Static_Services/Alertmsg'
import { InputField } from '../../../sharedComponents/InputField'



export const StaffCart = () => {
  const location = useLocation();
  const [client_id, setClient_id] = useState();
  const navigate = useNavigate();
  const [cartItem, setCartItem] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [businessDetail, setbusinessDetail] = useState();
  const [paymentMode, setPaymentMode] = useState();
  const [cardval, setCardval] = useState();
  const [cardType, setCardType] = useState()
  const [checkedCardId, setCheckedCardId] = useState();
  const [loder, setLoder] = useState(false);
  const [payment_note, setPayment_note] = useState();
  const [referenceid, setReferenceid] = useState();
  const [error, setError] = useState({
    payment_note: '',
    referenceid: ''
  })

  useEffect(() => {
    if (payment_note) {
      setError((pre) => ({ ...pre, payment_note: '' }));
    }
    if (referenceid) {
      setError((pre) => ({ ...pre, referenceid: '' }));
    }
  }, [payment_note, referenceid])

  const bzDetails = JSON.parse(localStorage.getItem('bzDetails'));
  useEffect(() => {
    if (bzDetails) {
      setbusinessDetail(bzDetails);
    }
  }, [bzDetails])


  useEffect(() => {
    if (location.state.id) {
      setClient_id(location.state.id);
    }

  }, [location])


  const getSaveCardDetail = async () => {
    var detail = { "userid": client_id };
    const resp = await cardGet(detail);
    if (resp) {
      const finalresp = resp.data.data.filter((el) => {
        el.checked = false;
      })
      setCustomerData();
    }
  }

  const getcart = async () => {
    setLoder(true);
    var detail = { "pageid": "1", "client_id": client_id, "business_id": localStorage.getItem('bzid') };
    const resp = await staff_cart_list(detail);
    if (resp) {
      setLoder(false)
      setCartItem(resp.data.data);

    } else {
      setLoder(false);
      navigate('/customer-detail')
    }
  }

  useEffect(() => {
    if (client_id) {
      getcart();
      getSaveCardDetail();
    }
  }, [client_id])

  const RemoveCartDetail = async (data) => {
    setLoder(true);
    const detail = { "client_id": client_id, "remove_cart_type": '0', "cart_id": data.cart_id, "business_id": businessDetail.business_id }
    const resp = await staff_remove_cart(detail);

    if (resp) {
      successAlert(resp.data.message);
      getcart();
      setLoder(false);
    } else {
      setLoder(false);
    }
  }

  const updateCount = (value, data) => {
    //   this.loading = true;
    //   if(value == '+'){
    //       data.quantity = parseInt(data.quantity) + 1;
    //       this.updateCartDetail(data);
    //   } else{
    //     if(data.quantity > 1){
    //       data.quantity = parseInt(data.quantity) - 1;
    //       this.updateCartDetail(data);
    //   } else{
    //    this.RemoveCartDetail(data);
    //     this.loading = false;
    //     return false;

    //   }
    //  }
  }





  const getpaymentMode = (event) => {
    setPaymentMode(event.target.id);
    setCardval(event.target.value);
  }
  const getCardType = (event) => {

    setCardType(event.target.id);
    setCheckedCardId(event.target.value);
    // var self= this;
    // self.cardType = event.target.id;
    // self.customerData.filter(function (el){
    //   if(el.card_id == self.cardType){
    //     el.checked = event.target.checked;
    //   }
    // })
  }

  const [cvvNo, setCvvNo] = useState('');
  const cvvhandleChange = (event) => {
    // Remove leading white spaces
    const newValue = event.target.value.replace(/^\s+/g, '');
    setCvvNo(newValue);
  };


  const savePayment = async (card) => {

    setLoder(true);
    var datadetail = [],
      businessid = businessDetail.business_id;
    cartItem.business_details.map((ell) => {
      ell.cart_details.map(function (el) {
        datadetail.push({ "service_id": el.service_id, "service_type": el.service_type, "business_id": businessid, "quantity": el.quantity, "amount": el.amount, "tax": el.tax, 'discount': el.discount })
      })
    });

    var detail = {
      "client_id": client_id,
      "savecard": "0",
      "data": datadetail,
      "card_id": card.card_id,
      "cvd": card.cvv_no,
      "business_id": cartItem.business_details[0].business_id,
      "country_code": "",
      "token": card.card_token,
    }
    const resp = await clover_pay_checkout_single(detail);
    if (resp) {
      successAlert(resp.data.message);
      setLoder(false);
      navigate('/customer-detail');
    } else {
      setLoder(false)
    }
    setLoder(false);
  }


  const [expirydate, setExpiryDate] = useState('');
  const [carderror, setCardError] = useState({
    cardName: "",
    cardNumber: "",
    valid: "",
    cvv: "",
    mode: "",
    transactionid: "",
    notes: "",
  });
  const CarderrorHandler = (e) => {
    const { name, value } = e.target;
    setCardError((pre) => ({
      ...pre,
      [name]: "",
    }));
  };
  const validHandler = (e) => {
    setCardError((pre) => ({ ...pre, valid: '' }))
    const inputValue = e.target.value;
    if (e.nativeEvent.inputType === "deleteContentBackward") {
      if (inputValue.endsWith('/')) {
        setExpiryDate(inputValue.slice(0, -1));
      } else {
        setExpiryDate(inputValue);
      }
    } else {
      if (inputValue.length === 2 && inputValue.charAt(2) !== '/') {
        setExpiryDate(inputValue + '/');
      } else if (inputValue.length === 8) {
        return;
      } else {
        setExpiryDate(inputValue);
      }
    }
  };

  const payment = async (e) => {
    e.preventDefault();
    let isValid = 1;
    let resp = '';
    const fData = new FormData(e.target);
    const fValue = Object.fromEntries(fData.entries());


    const cardName = fValue.cardName ? fValue.cardName : "";
    const cardNumber = fValue.cardNumber ? fValue.cardNumber : "";
    const valid = expirydate ? expirydate : "";
    const cvv = fValue.cvv ? fValue.cvv : "";

    let expiry_month = "";
    let expiry_year = "";

    if (!cardName) { setCardError((pre) => ({ ...pre, cardName: "*Required" })); isValid = 2 }
    if (!cardNumber) { setCardError((pre) => ({ ...pre, cardNumber: "*Required" })); isValid = 4 }
    if (!valid) { setCardError((pre) => ({ ...pre, valid: "*Required" })); isValid = 5 }
    if (!cvv) { setCardError((pre) => ({ ...pre, cvv: "*Required" })); isValid = 6 }

    var expired = ExpiryCheck(valid);
    if (expired) {
      expiry_month = expired[0];
      expiry_year = expired[1];
    } else {
      return false;
    }
    if (isValid === 1) {
      setLoder(true);
      let datadetail = [];
      const businessid = businessDetail.business_id;
      cartItem.business_details.map((ell) => {
        ell.cart_details.map(function (el) {
          datadetail.push({ "service_id": el.service_id, "service_type": el.service_type, "business_id": businessid, "quantity": el.quantity, "amount": el.amount, "tax": el.tax, 'discount': el.discount })
        })
      });
      const detail = {
        "client_id": client_id,
        "token": '',
        "savecard": "0",
        "card_id": "",
        "data": datadetail,
        "number": cardNumber,
        "expiry_month": expiry_month,
        "expiry_year": expiry_year,
        "cvd": cvv,
        "business_id": cartItem.business_details[0].business_id,
        "country_code": "",
        "customer_name": cardName,
      }
      const resp = await clover_pay_checkout_single(detail);
      if (resp) {
        successAlert(resp.data.message);
        setLoder(false);
        e.target.reset();
        navigate('/customer-detail');
      } else {
        setLoder(false);
      }
    }
  }

  const PayAtStudio = async () => {

    let datadetail = [];
    let isValid = 1;
    let businessid = businessDetail.business_id;
    cartItem.business_details.map((ell) => {
      ell.cart_details.filter(function (el) {
        datadetail.push({ "service_id": el.service_id, "service_type": el.service_type, "business_id": businessid, "quantity": el.quantity, "amount": el.amount, "tax": el.tax, 'discount': el.discount })
      })
    });

    if (!payment_note) { setError((pre) => ({ ...pre, payment_note: "Required" })); isValid = 2 }
    if (!referenceid) { setError((pre) => ({ ...pre, referenceid: "Required" })); isValid = 3 }


    if (isValid === 1) {
      setLoder(true);

      const detail = {
        "client_id": client_id, 'reference_id': referenceid,
        'payment_mode': cardval,
        'payment_note': payment_note,
        "data": datadetail
      };

      const resp = await staff_pay_at_desk(detail);
      if (resp) {
        successAlert(resp.data.message);
        this.ReferenceDetail.reset();
        setLoder(false);
        navigate('/customer-detail');
      } else setLoder(false);
      setLoder(false);
    }
  }

  return (
    <>
      {loder ?
        <div className='MainLoader'>
          <Spinner animation="border" role="status" className='Loader'>
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
        : ""}

      <InnerHeader />

      <div className="body-grey">


        {/* <!-- My Studio Card Detials-Service Section --> */}
        <div className="studio-details">
          <div className="container">

            <div className="pb-5">
              <div className="row">
                <div className="col-12 text-left">
                  <h4 className="title black-color text-uppercase mt-5"> Items in your Cart </h4>
                </div>
              </div>
              {/* <!-- End of Heading --> */}

              <div className="s-d-appoint-block">
                <div className="row">
                  {cartItem &&
                    <div className="col-12 col-lg-6 mt-3 mt-lg-0">
                      {cartItem.business_details && cartItem.business_details.map((ITEMS) => (
                        <div className="client-buy-input border-radius6 box-shadow1 white-bg p-4">
                          <div className="row pb-2">
                            <div className="col-3 col-sm-2">
                              <div className="cart-logo">
                                <img src={ITEMS.logo} className="img-fluid" />
                              </div>
                            </div>
                            <div className="col-9 col-sm-9 pl-0 pt-2">
                              <div className="text-left mt-1 mt-md-3 mt-lg-1">
                                <h4 className="title black-color font-w-100"> {ITEMS.business_name} </h4>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 ">
                              {ITEMS.cart_details.map((SubITEMS) => (
                                <div className="white-bg border-radius6 box-shadow1 p-4 mb-2 mt-2">
                                  <div className="row mb-0" >
                                    <div className="col-3 pr-0">
                                      <div className="border-radius6 box-shadow1 p-3">
                                        {SubITEMS.item_image != '' && <img src={SubITEMS.item_image} className="img-fluid w-10" />}
                                        {SubITEMS.item_image == '' && <img src="clientassets/img/logo_green_web.png" className="img-fluid w-10" />}
                                      </div>
                                    </div>
                                    <div className="col-9">
                                      <div className="">
                                        <div className="row">
                                          <div className="col-10">
                                            <h5 className="title black-color mb-0">{SubITEMS.item_name}</h5>

                                          </div>
                                          <div className="col-2 text-right ">
                                            <span className="delete d-inline-block border-radius6"
                                              onClick={() => RemoveCartDetail(SubITEMS)}
                                            > <a href="Javascript:void(0);" > <i className="fas fa-trash-alt"></i> </a> </span>

                                          </div>

                                        </div>
                                        {SubITEMS.item_decription.length <= 40 &&
                                          <p className="brand-color3 font-12 my-2" >{SubITEMS.item_decription} </p>}
                                        {SubITEMS.item_decription.length > 40 &&
                                          <p className="brand-color3 font-12 my-2" >{SubITEMS.item_decription}</p>}
                                        {SubITEMS.service_type == '1' &&
                                          <div className="row" >
                                            <div className="col-6"><h5 className="brand-color3 mb-0">Valid For {SubITEMS.pass_validity} </h5>
                                            </div>

                                          </div>
                                        }

                                        <div className="row">
                                          <div className="col-12">
                                            <h5 className="title brand-color3 mb-0">Amount :  {SubITEMS.amount} </h5>

                                          </div>
                                          <div className="col-6">
                                            <h5 className="title brand-color3 mb-0"> Tax 1 :  {SubITEMS.tax1_rate}  </h5>
                                          </div>
                                          <div className="col-6">
                                            <h5 className="title brand-color3 mb-0"> Tax 2 :  {SubITEMS.tax2_rate}  </h5>
                                          </div>
                                        </div>


                                      </div>
                                      <div className="row">
                                        {SubITEMS.service_type != '1' &&
                                          <div className="col-5 pt-2 w-40">
                                            <div id="input_div" className="counter-box d-flex align-items-center" >
                                              <input type="button" value="+" id="plus" className="brand-color2 font-w-600 font-28 mr-1 functionplus" onClick={() => updateCount('+', SubITEMS)}
                                                style={{ fontFamily: "Arial" }} />
                                              <input type="text" id="count" className="inputWidth brand-color1 flex-fill" value={SubITEMS.quantity}

                                                name="ProductCount" />
                                              <input type="button" value="-" id="moins" className="brand-color2 font-w-600 font-28 mr-1 functionplus" onClick={() => updateCount('-', SubITEMS)}
                                                style={{ fontFamily: "Arial" }} />
                                            </div>
                                          </div>
                                        }
                                        <div className="col-7 pt-2">
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  }

                  {cartItem.length == 0 &&
                    <div className="col-12 col-lg-6 mt-3 mt-lg-0"  >
                      <div className="client-buy-input border-radius6 box-shadow1 white-bg p-4">
                        <div className="row pb-2">
                          <div className="col-3 col-sm-2">
                          </div>
                          {/* <!-- end of col-3 --> */}
                          <div className="col-9 col-sm-9 pl-0 pt-2">
                            <div className="text-center mt-1 mt-md-3 mt-lg-1">
                              <h4 className="title black-color font-w-100"> No item added in cart </h4>
                            </div>
                          </div>
                          {/* <!-- end of col-10 -->                   */}
                        </div>
                        {/* <!-- end of row -->   */}

                      </div>
                    </div>
                  }
                  <div className="col-12 col-lg-6 mt-5 mt-lg-0" >
                    <div className="s-d-appoint-block-right border-radius6 box-shadow1 white-bg">
                      <div className="px-3 py-3 text-left light-bg border-t-lr-6">
                        <h5 className="title black-color font-w-100 text-uppercase font-16 mb-0"> payment details </h5>
                      </div>
                      {/* <!-- top heading --> */}

                      <div className="px-3 pt-4 pb-2 border-bottom">
                        <div className="row mb-2">
                          <div className="col-6 text-left">
                            <p className="black-color font-w-100 font-18"> Price {cartItem.total_item} Item </p>
                          </div>
                          <div className="col-6 text-right">
                            <p className="black-color font-w-100 font-18">   {cartItem.total_amount} </p>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-6 text-left">
                            <p className="black-color font-w-100 font-18"> Discount </p>
                          </div>
                          <div className="col-6 text-right">
                            <p className="black-color font-w-100 font-18">   {cartItem.total_discount} </p>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-6 text-left">
                            <p className="black-color font-w-100 font-18"> Tax 1 </p>
                          </div>
                          <div className="col-6 text-right">
                            <p className="black-color font-w-100 font-18">    {cartItem.tax1_rate}  </p>
                          </div>
                          <div className="col-6 text-left">
                            <p className="black-color font-w-100 font-18"> Tax 2 </p>
                          </div>
                          <div className="col-6 text-right">
                            <p className="black-color font-w-100 font-18">    {cartItem.tax2_rate}  </p>
                          </div>
                        </div>
                      </div>
                      {/* <!-- end of Appointment Fees: --> */}

                      <div className="px-3 pt-4 pb-3 border-bottom">
                        <div className="row mb-2">
                          <div className="col-6 text-left">
                            <p className="title black-color font-w-100 font-18 mb-0"> Amount Payable </p>
                          </div>
                          <div className="col-6 text-right">
                            <p className="title black-color font-w-100 font-18 mb-0">   {cartItem.grand_total} </p>
                          </div>
                        </div>
                      </div>
                      {/* <!-- end of Amount Payable --> */}


                      {cartItem.length != 0 &&
                        <div className="mt-4 ml-3" >
                          <p className="brand-color3 font-14 mb-2">Payment Mode</p>
                          <div className="d-flex flex-wrap" >
                            <div className="radiotype-btn">
                              <input type="radio" className="customradio" id="1" value="cash" name="paymentMethod"
                                onChange={(e) => getpaymentMode(e)}
                              />
                              <label>Cash/Check</label>
                            </div>
                            {businessDetail.isCloverPurchase &&
                              <div className="radiotype-btn ml-3" >
                                <input type="radio" className="customradio" id="3" value="card" name="paymentMethod" onChange={(e) => getpaymentMode(e)} />
                                <label>Card</label>
                              </div>
                            }
                          </div>
                        </div>
                      }
                      {/* <!-- Gender -->   */}
                      {paymentMode == '3' &&
                        <div className="card-details"  >
                          <div className="client-payment-input border-radius6 box-shadow1 white-bg p-4">
                            <div className="row">
                              {customerData && customerData.length > 0 && customerData.map((cardsave) => (
                                <div className="col-12 mb-3" >
                                  {cardsave.length != 0 &&
                                    <div className="client-card-img white-bg border-radius6 box-shadow1"  >
                                      <label className="select-card select-card-img select-card-cvv mb-2">
                                        {(cardsave.card_type == 'VISA' || cardsave.card_type == 'visa') &&
                                          <img src="clientassets/img/visa_card.png" className="img-fluid payment_img" />
                                        }
                                        {cardsave.card_type == 'MC' &&
                                          <img src="clientassets/img/master_card.png" className="img-fluid payment_img" />}
                                        {cardsave.card_type == 'AM' &&
                                          <img src="clientassets/img/american_express.png" className="img-fluid payment_img" />}
                                        <p className="mb-0 font-12 dark-color mt-2"> {cardsave.number}  </p>
                                        <h5 className="mb-0 font-12 dark-color mt-2">Valid Till(MM/YYYY) </h5>
                                        <p className="mb-0 font-12 dark-color mt-2"> {cardsave.expiry_month}  /  {cardsave.expiry_year} </p>
                                        <input
                                          type="radio"
                                          name="radio1"
                                          id={cardsave.card_id}
                                          value={cardsave.card_id}
                                          checked={cardsave.card_id === checkedCardId}
                                          onChange={(e) => getCardType(e)}
                                        />
                                        <span className="checkmark"></span>
                                      </label>
                                      {cardsave.checked == true && cardType == cardsave.card_id &&
                                        <form >
                                          <div className="text-center d-flex">
                                            <input
                                              type="password"
                                              autoComplete="off"
                                              className="form-control w-25 ml-5 mr-5"
                                              placeholder="CVV"
                                              pattern="\d{3,4}"
                                              maxLength={3}
                                              onChange={cvvhandleChange}
                                              value={cvvNo}
                                            />

                                            <button type="button" className="btn btn-blue-grad px-4 w-50 text-uppercase mb-0 mb-md-3"
                                              onClick={() => savePayment(cardsave)}
                                            > Proceed to pay </button>
                                          </div>
                                        </form>
                                      }
                                    </div>
                                  }
                                </div>
                              ))}
                              {/* <!-- end of Master Card -->  */}
                              <div className="col-12 mb-3">
                                <div className="white-bg border-radius6 box-shadow1">
                                  <label className="select-card"> New Card
                                    <input type="radio" checked={cardType == 'new'} name="radio1" id="new" value="new" onChange={(e) => getCardType(e)} />
                                    <span className="checkmark"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          {cardType == 'new' &&
                            <form onSubmit={payment} autoComplete='off'>
                              <div class="row py-4 px-3">
                                <div class="col-12 col-sm-6">
                                  <div class="form-group">
                                    <InputField
                                      name={"cardName"}
                                      error={carderror.cardName}
                                      lable={"Name on Card"}
                                      onChange={CarderrorHandler}
                                    />
                                  </div >
                                </div >

                                <div class="col-12 col-sm-6">
                                  <div class="form-group client-buy-input-cardno">
                                    <InputField
                                      name={"cardNumber"}
                                      type={"number"}
                                      error={carderror.cardNumber}
                                      lable={"Card Number"}
                                      onChange={CarderrorHandler}
                                    />
                                  </div >
                                </div >

                                <div class="col-12 col-sm-6 mt-2 mt-md-3">
                                  <div class="form-group">
                                    <p class="brand-color3 text-left font-14 mb-2"> Valid Till </p>
                                    <input
                                      type="text"
                                      name={'valid'}
                                      className="form-control"
                                      placeholder="MM/YYYY"
                                      value={expirydate}
                                      onChange={validHandler}
                                      pattern="^[0-9]{2}/[0-9]{4}"
                                      autoComplete="new-password"
                                      lable={"Valid Through"}
                                    />
                                    <small className="text-danger">{carderror.valid}</small>
                                  </div >
                                </div >

                                <div class="col-12 col-sm-6 mt-2 mt-md-3">
                                  <div class="form-group">
                                    <InputField
                                      type={"password"}
                                      name={"cvv"}
                                      error={carderror.cvv}
                                      lable={"CVV"}
                                      onChange={CarderrorHandler}
                                    />
                                  </div >
                                </div >

                                <div class="w-100 py-5 text-center">
                                  <button type="submit" class="btn btn-blue-grad px-4 w-50 text-uppercase mb-0 mb-md-5"
                                  > Proceed to pay </button>
                                </div >

                              </div >
                            </form >
                          }
                        </div>
                      }
                      {paymentMode == '1' &&
                        <div className="card-details" >
                          <form>
                            <div className="d-flex flex-wrap py-3">
                              <div className="col-md-12 form-group">
                                <label>Transaction Number</label>
                                <input type="text" className="form-control" formControlName="referenceid" onChange={(e) => setReferenceid(e.target.value)} />
                                <small className='text-danger'>{error.referenceid}</small>
                              </div>
                              <div className="col-md-12 form-group">
                                <label>Notes</label>
                                <input type="text" className="form-control" formControlName="payment_note" onChange={(e) => setPayment_note(e.target.value)} />
                                <small className='text-danger'>{error.payment_note}</small>
                              </div>
                            </div>
                            <div className="w-100 text-center" >
                              <button type="button" className="btn btn-blue-grad px-4 mb-2 w-50 text-uppercase " onClick={() => PayAtStudio()} > Proceed to pay </button>
                            </div>
                          </form>
                        </div>
                      }
                      <div className="px-3 py-2 text-center" >
                        <button type="button" className="btn btn-blue-grad px-4 w-30 text-uppercase ml-3" onClick={() => window.history.back()}> back</button>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>



          </div>

        </div>


      </div>
      <Innerfooter />


    </>
  )
}
