import React from 'react'
import StaticHeader from '../../StaticHeader'
import StaticFooter from '../../StaticFooter'
import { Link } from 'react-router-dom';

export const StaticHome = () => {
    const trailDays = 30;
  return (
<>
<StaticHeader />


{/* <!-- Top Banner Section --> */}
<div className="top-container d-flex align-items-end justify-content-center pb-5">
  <div className="align-item-center text-center pb-5 wow animated slideInDown">
    <h1 className="title white-color font-w-900 mb-5 pt-4 px-5 px-lg-0"> Warrior Studio Management Software </h1>
    <h2 className="title white-color font-w-900 mb-3 mt-4"> One solution for your studio to manage booking, <br/> members, staff, products, payments & more. </h2>
    <p className="white-color font-w-100 font-16"> Grow your business with our marketing, payments, and accounting partnerships.</p>
  </div>
</div>
{/* <!-- End of Top Banner Section --> */}


{/* <!-- Demo Section --> */}
<div className="demo-section p-0 p-lg-5">
  <div className="container-fluid">
    <div className="row">
      <div className="col-0 col-xl-1"></div>
      {/* <!-- empty --> */}
      <div className="col-12 col-sm-6 col-xl-5 wow animated slideInLeft">
        <Link to="/demo" className="">
          <div className="demo-box d-flex align-items-center justify-content-center">
            <h2 className="title dark-color font-w-900"> Request a Demo </h2>
          </div>
        </Link>
      </div>
      {/* <!-- end of col-5 --> */}
      <div className="col-12 col-sm-6 col-xl-5 mt-3 mt-sm-0 wow animated slideInRight">
        <Link to="/choose-plan" style={{textDecoration:"none"}}>
          {/* <!-- <a href="https://warriorsms.com/studioadmin/home"> --> */}

          <div className="demo-box d-flex align-items-center justify-content-center">
            <div>
              <h2 className="title dark-color font-w-900"> Start a {trailDays} Day Free Trial </h2>
            </div>
          </div>
        </Link>
      </div>
      {/* <!-- end of col-5 --> */}
    </div>
  </div>
  {/* <!-- end of container-fluid --> */}
</div>
{/* <!-- End of Demo Section --> */}


{/* <!-- Promotion Section - 1 --> */}
<div className="promotion-content-right promotion-slider">
  <div className="container-fluid">
    <div className="row">
      <div className="col-12 col-lg-7 px-0">
        <div className="promotion-img-animation">
          <img src="clientassets/img/home_img1.png" className="img-fluid w-100" />
        </div>
      </div>
      {/* <!-- end of col-md-7 --> */}
      <div className="col-12 col-lg-5 px-0">
        <div className="blue-gradient h-100 d-flex align-items-center justify-content-start py-5 py-xl-0">

          <div id="homeslidermodal" className="carousel slide" data-ride="carousel" >
            {/* <!-- Indicators --> */}
            <ul className="carousel-indicators">
              <li data-target="#homeslidermodal" data-slide-to="0" className="active"></li>
              <li data-target="#homeslidermodal" data-slide-to="1"></li>
            </ul>
            {/* <!-- The slideshow --> */}
            <div className="carousel-inner" >
              <div className="carousel-item active">
                <div className="px-4 px-lg-5">
                  <h2 className="title font-w-900 white-color pr-0 pr-xl-5 font-36 line-height50 letter-space"> Schedule Classes, Workshops, and Appointments </h2>
                </div>
              </div>
              <div className="carousel-item">
                <div className="px-4 px-lg-5">
                  <h2 className="title font-w-900 white-color pr-0 pr-xl-5 font-36 line-height50 letter-space"> Sell Passes, Services, and Products </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- end of col-md-5 --> */}
    </div>
  </div>
  {/* <!-- end of container-fluid --> */}
</div>
{/* <!-- End of Promotion Section - 1 --> */}


{/* <!-- Promotion Section - 2 --> */}
<div className="promotion-content-left">
  <div className="container-fluid d-flex flex-nowrap">
    <div className="row">
      <div className="col-12 col-lg-5 px-0 order-2 order-lg-1">
        <div className="green-gradient h-100 d-flex align-items-center justify-content-start py-5 py-xl-0">
          <div className="px-4 px-lg-5 wow animated slideInLeft">
            <h2 className="title font-w-900 white-color font-36 line-height50 mb-4 mb-lg-5 pr-0 pr-xl-5 letter-space"> Engage clients and staff with dedicated mobile apps </h2>
            <p className="white-color font-18 line-height30 pr-0 pr-xl-5 mb-0 "> Grow your business with marketing and payment add-ons services. </p>
          </div>
        </div>
      </div>
      {/* <!-- end of col-md-5 --> */}
      <div className="col-12 col-lg-7 px-0 order-1 order-lg-2">
        <div className="promotion-img-animation">
          <img src="clientassets/img/home_img2.png" className="img-fluid w-100" />
        </div>
      </div>
      {/* <!-- end of col-md-7 --> */}
    </div>
  </div>
  {/* <!-- end of container-fluid --> */}
</div>
{/* <!-- End of Promotion Section - 2 --> */}


{/* <!-- Foundation Laptop Section --> */}
<div className="foundation-section pt-5">
  <div className="container-fluid">
    <div className="row">
      <div className="col-12">
        <div className="text-center">
          <img src="clientassets/img/logo_white.png" className="mt-5" />
          <h2 className="title white-color font-w-900 text-capitalize my-5"> Warrior will help you grow your business </h2>
          <img src="clientassets/img/home_foundation_device1.png" className="img-fluid" />
         <Link to="/solution"><button className="btn btn-white d-block mx-auto my-5"> Learn More </button></Link>
        </div>
      </div>
    </div>
  </div>
</div>
{/* <!-- End of Foundation Laptop Section --> */}

<StaticFooter/>
</>
  )
}
