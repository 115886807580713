import React from 'react'
import StaticHeader from '../../StaticHeader'
import StaticFooter from '../../StaticFooter'

export const StaticApp = () => {
  return (
  <>
  <StaticHeader />
  {/* <!-- App Features Content Section --> */}
<div className="app-second-section">
  <div className="app-get-background">
    <div className="container">
      <div className="row pb-5">
        <div className="col-12 col-md-5 wow animated slideInLeft order-2 order-md-1">
          <div className="customer-right d-flex align-items-center justify-content-center h-100">
            <div className="align-item-center">
              <div className="customer-right-appoint px-4 py-3 mb-4">
                <img src="clientassets/img/ic_downloads.png" className="d-inline mr-1 mr-lg-3 ml-0 ml-lg-2 img-fluid" />
                <p className="dark-color font-20 d-inline align-middle"> Get the app for free now </p>
              </div>
              {/* <!-- end of customer-right-appoint --> */}
              <div className="text-left">
                <h2 className="dark-color font-w-900 pb-2 pr-3"> Warrior’s client and staff mobile apps are available on the Apple App Store and Google Play For Free </h2>
                <p className="pb-3 mb-3 brand-color3 font-16 line-height25 font-w-500 border-bottom"> Once your account has been activated you will send your current staff and clients a welcome email that make it easy for them to find, download, complete their profiles and have your studio in the “My Studio” list.  From their they select your studio and they can begin to use the app. </p>
                 <ul>
                  <li> <a href="https://play.google.com/store/apps/details?id=com.warriorsms.user&pcampaignid=web_share&pli=1" target='blank'> <img src="clientassets/img/google_play_btn.png" /></a> </li>
                  <li> <a href="https://apps.apple.com/us/app/warrior-sms-client/id1532481648" target='blank'> <img src="clientassets/img/app_store_btn.png" /></a> </li>
                </ul> 
              </div>
                {/* !-- end of customer-right-content --> */}
            </div>
          </div>
          {/* <!-- end of customer-right --> */}
        </div>
        {/* <!-- end of customer-Left --> */}

        <div className="col-12 col-md-7 wow animated slideInRight order-1 order-md-2"> 
          <div className="customer-left mt-5 mt-md-0">
            <img src="clientassets/img/app_img2.png" className="img-fluid" />
          </div>
        </div>
        {/* <!-- end of customer-Right --> */}
      </div>
      {/* <!-- end of row --> */}
    </div>
    {/* <!-- end of container --> */}
  </div>
  {/* <!-- end of app-get-app-back --> */}
</div>
{/* <!-- End of App Features Content Section --> */}
{/* <!-- About App Content Section --> */}



<div className="about-back-image">
  <div className="pt-0 pt-md-0">
    <div className="container">
      <div className="text-center mt-5 pt-2">
        <div className="wow animated slideInDown">
          <h2 className="dark-color font-w-900 pb-4"> Client And Staff Mobile Apps  </h2>
          <p className="pb-3 brand-color3 font-16 line-height25 font-w-600 px-0 px-md-5"> Warrior has two separate mobile apps developed to help you grow and manage your business easier. </p>
        </div>
        {/* <!-- end of About App content part --> */}

        <div className="row">
          <div className="col-12 col-md-7 wow animated slideInLeft"> 
            <div className="customer-left">
              <img src="clientassets/img/app_img1.png" className="img-fluid" />
            </div>
          </div>
          {/* <!-- end of customer-left --> */}
          <div className="col-12 col-md-5 wow animated slideInRight">
            <div className="customer-right d-flex align-items-center justify-content-center h-100">
              <div className="align-item-center">
                {/* <div className="customer-right-appoint px-4 py-3 mb-4 text-left">
                  <img src="clientassets/img/ic_app.png" className="d-inline mr-1 mr-lg-3 ml-0 ml-lg-2 img-fluid" />
                  <p className="dark-color font-20 d-inline align-middle"> Best app for appointment </p>
                </div>  */}
                {/* <!-- end of customer-right-appoint --> */}
                <div className="text-left">
                  <h2 className="dark-color font-w-900 pb-2 pr-3"> Client Mobile App </h2>
                  <p className="pb-3 mb-3 brand-color3 font-16 line-height25 font-w-500 border-bottom"> The Warrior client mobile app is available for Android and Apple..  We built the solution to allow your clients to easily find, register, and check-in for a className or workshop.  They can book an appointment and purchase a product, pass, or service in seconds. </p>
                  <a href="#" className="brand-color1 font-w-900 font-25"> Get the app </a>
                  <ul>
                    <li> <a href="https://play.google.com/store/apps/details?id=com.warriorsms.user&hl=en-GB&ah=rkMVvfpr-j_NuZhcdKB1TOMunbg" target='blank'> <img src="clientassets/img/google_play_btn.png" /></a> </li>
                    <li> <a href="https://apps.apple.com/us/app/warrior-sms-client/id1532481648" target='blank'> <img src="clientassets/img/app_store_btn.png" /></a> </li>
                  </ul>
                </div>
                {/* <!-- end of customer-right-content --> */}
              </div>
            </div>
            {/* <!-- end of customer-right --> */}
          </div>
          {/* <!-- end of customer-right --> */}
        </div>
        {/* <!-- end of row --> */}
      </div>
      {/* <!-- about app section --> */}
    </div>
    {/* <!-- end of container(About App) --> */}
  </div>
</div>
{/* <!-- End of About App Content Section --> */}


{/* <!-- About App Content Section --> */}
<div className="about-back-image">
  <div className="pt-0 pt-md-0 pb-5 pb-md-0">
    <div className="container">
        <div className="row">
         
          <div className="col-12 col-md-5 wow animated slideInLeft">
            <div className="customer-right d-flex align-items-center justify-content-center h-100">
              <div className="align-item-center">
                 {/* <div className="customer-right-appoint px-4 py-3 mb-4 text-left">
                  <img src="clientassets/img/ic_app.png" className="d-inline mr-1 mr-lg-3 ml-0 ml-lg-2 img-fluid" />
                  <p className="dark-color font-20 d-inline align-middle"> Best app for appointment </p>
                </div>  */}
                {/* <!-- end of customer-right-appoint --> */}
                <div className="text-left">
                  <h2 className="dark-color font-w-900 pb-2 pr-3"> Staff/Instructor Mobile App </h2>
                  <p className="pb-3 mb-3 brand-color3 font-16 line-height25 font-w-500 border-bottom"> The Warrior staff/instructor mobile app is built to allow your staff to check in clients for a className without needing to be behind the desk, book an appointment on the go, and update their schedule availability. </p>
                  {/* <!-- <a href="#" className="brand-color1 font-w-900 font-25"> Get the app > </a> --> */}
                  <ul>
                  {/* https://play.google.com/store/apps/details?id=com.warriorsms.staff&hl=en-GB&ah=rkMVvfpr-j_NuZhcdKB1TOMunbg */}
                    <li> <a href="https://play.google.com/store/apps/details?id=com.warriorsms.instructor" target='blank'> <img src="clientassets/img/google_play_btn.png" /></a> </li>
                    <li> <a href="https://apps.apple.com/us/app/warrior-sms-instructor-staff/id1535363819" target='blank'> <img src="clientassets/img/app_store_btn.png" /></a> </li>
                  </ul>
                </div>
                {/* <!-- end of customer-right-content --> */}
              </div>
            </div>
            {/* <!-- end of customer-right --> */}
          </div>
          {/* <!-- end of customer-right --> */}

          <div className="col-12 col-md-7 wow animated  slideInRight"> 
            <div className="customer-left">
              <img src="clientassets/img/app_img1.png" className="img-fluid" />
            </div>
          </div>
          {/* <!-- end of customer-left --> */}
        </div>
        {/* <!-- end of row --> */}
      </div>
      {/* <!-- about app section --> */}
    </div>
    {/* <!-- end of container(About App) --> */}
  </div>

{/* <!-- End of About App Content Section --> */}
  <StaticFooter />
  </>
  )
}
