import React from 'react'
import StaticHeader from '../../StaticHeader'
import StaticFooter from '../../StaticFooter'
import { termsCondition } from '../../static-service/StaticServices'
import { useEffect } from 'react'
import { useState } from 'react'

export const StaticTermscondition = () => {
  const [content, setContent] = useState();

  const getTerms = async () => {
    const resp = await termsCondition();
    setContent(resp.content);
  }

  useEffect(() => {
    getTerms();
  }, [])
  return (
    <>
    <StaticHeader />
    {/* <!-- Terms & Conditions Content Section --> */}
  <section className="">
    <div className="background-before">
      <div className="py-4 mt-2">
        <div className="container">
          <div className="text-left wow animated slideInLeft">
            <h5 className="dark-color font-w-700 pb-5 mb-0 text-center"> Warrior Studio Management Software End-User License Agreement
            </h5>
            {/* <!-- <h5 className="new-title dark-color font-w-900 pb-4 text-center"> Terms and Conditions </h5>
            <p className="pb-3 brand-color3 font-20 line-height25 text-justify"> The following terms and conditions (the “Terms and Conditions”) govern the use of the on demand offering (the “Service”) developed, operated and maintained by Warrior SMS Inc. (“Warrior”). Any use of the Service by an individual or entity (each, a “Customer”) is subject to the terms and conditions contained herein. </p> --> */}
            <div dangerouslySetInnerHTML={{ __html:content}} ></div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* <!-- End of Terms & Conditions Content Section --> */}

    <StaticFooter />
    
    </>
  )
}
