import React, { useState } from 'react'
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { HeaderDashboard } from '../../comman_page/HeaderDashboard'
import { Footer } from '../../../../../public/containers/footer/Footer'
import { Addshift } from './Addshift'
import Sidebar from '../../comman_page/Sidebar'


export const Addshiftlist = () => {
  const [loder, setLoder] = useState(false);

  const loders = (data) => {
    setLoder(data);
  }

  return (
    <>
      {loder ? <div className="MainLoader"><Spinner animation="border" /> </div> : ""}
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col className="p-0 RightCol">
              <HeaderDashboard />
              <div className="RightSide">
                <section className="addClient innerContent">
                  <Container className="pt-5">
                    <h4>List Your Shifts</h4>
                    <p>
                      Shifts are required for clients to be able to book a
                      service appointment. Staff can also be set up for a shift
                      to work without offering appointments (non service) such
                      as working the front desk.
                    </p>
                    <div className="AddClientForm AddClientContent">
                      <Addshift loders={loders} />
                    </div>
                  </Container>
                </section>
                <Footer />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}
