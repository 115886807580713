import React from "react";
import { InnerHeader } from "../../Components/inner-header/InnerHeader1";
import { SubHeader } from "../../Components/sub-header/SubHeader";
import { Innerfooter } from "../../Components/inner-footer/Innerfooter";
import { DashboardToSectionDetails } from "../../Components/CommanPages/DashboardToSectionDetails";
import { useState } from "react";
import {
  API_services_list,
  Instructor_services_list,
  ageCalculation,
  staff_search_customer_for_services,
} from "../../../Static_Services/Staff_Services";
import { Spinner } from "react-bootstrap";
import { useEffect } from "react";
import { errorAlert } from "../../../Static_Services/Alertmsg";
import { useNavigate } from "react-router-dom";
import LiveSearch from "../../../LiveSearch";

export const ClientServices = () => {
  const [pageCount, setPageCount] = useState(1);
  const [userdetail, setUserdetail] = useState();
  const [loder, setLoder] = useState(false);
  const [serviceDetail, setServiceDetail] = useState([]);
  const [dataCount, setDataCount] = useState();
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState();

  const [businessDetail, setBusinessDetail] = useState();
  useEffect(() => {
    localStorage.removeItem('w_slectedCustomer');
    if (JSON.parse(localStorage.getItem("bzDetails"))) {
      setBusinessDetail(JSON.parse(localStorage.getItem("bzDetails")));
    }
    if (JSON.parse(localStorage.getItem("w_loginDetail"))) {
      setUserdetail(JSON.parse(localStorage.getItem("w_loginDetail")));
    }
  }, []);

  const getServicetList = async () => {
    setLoder(true);
    const detail = {
      pageid: pageCount,
      business_id: businessDetail.business_id,
    };
    let resp = "";
    if (userdetail.role_id == 3) {
      resp = await API_services_list(detail);
    } else {
      resp = await Instructor_services_list(detail);
    }
    if (resp) {
      if (pageCount == 1) {
        setServiceDetail(resp.data.data);
      } else {
        setServiceDetail((serviceDetail) =>
          serviceDetail.concat(resp.data.data)
        );
      }
      setLoder(false);
    } else {
      setDataCount(0);
      setLoder(false);
    }
  };

  useEffect(() => {
    if (businessDetail) {
      getServicetList();
    }
  }, [businessDetail]);

  const selectEvent = (item) => {
    // this.customerList.filter(function (el){
    //   if(el.id == item.id){
    //     localStorage.setItem('w_slectedCustomer',<any>JSON.stringify(el));
    //   }
    // })
    // // do something with selected item
  };


  const getcustomerlist = async (e) => {
    // let { value } = e.target;
    // setSearchTerm(value);
    // setLoder(true);
    // let detail = { "pageid": 1, "business_id": businessDetail.business_id, "search_val": value };
    // const resp = await staff_search_customer_for_services(detail);
    // if (resp) {
    //   let response = resp.data;
    //   response.data.map((el) => {
    //     if (el.date_of_birth) {
    //       el.age = ageCalculation(el.date_of_birth);
    //     }
    //   })
    //   setCustomerList(response.data);
    //   setLoder(false);
    // } else {
    //   this.customerList = [];
    //   setLoder(false);
    // }

  }

  const redirectUrl = (url, Id) => {
    if (userdetail.role_id == 4) {
      if (localStorage.getItem("w_slectedCustomer")) {
        navigate(url, { state: { Id: Id } });
      } else {
        errorAlert("Please select the customer");
      }
    } else {
      navigate(url, { state: { Id: Id } });
    }
  };

  return (
    <>
      {loder && <div className="MainLoader"><Spinner animation="border" role="status" className="Loader"><span className="visually-hidden">Loading...</span>{" "}
      </Spinner></div>}
    
      <InnerHeader />
      <div className="body-grey">
        <div className="studio-details client-home-inner">
          <div className="container">
            <DashboardToSectionDetails />
            <div class="home-service home-inner-service pb-5">
              <div class="row">
                {userdetail && userdetail.role_id == 4 && (
                   <div className="row mb-3">
                   <div class="col-lg-6">
                     <LiveSearch businessDetail={businessDetail}/>
                   </div>
                   <div className="col-lg-6"></div>
                 </div>
                )}
               
                <div class="col-12">
                  <div class="section-heading pt-2 pb-4 text-left">
                    <a
                      href="Javascript:void(0);"
                      class="black-color font-w-100 text-uppercase font-20"
                    >
                      {" "}
                      service{" "}
                    </a>
                  </div>
                </div>
              </div>
              <div
              //  infinite-scroll  [infiniteScrollDistance]="scrollDistance"  [infiniteScrollUpDistance]="scrollUpDistance"  [infiniteScrollThrottle]="throttle"  (scrolled)="onScroll()" alwaysCallback="true"
              >
                {serviceDetail.length != 0 && (
                  <div class="row">
                    {serviceDetail.map((serve) => (
                      <div class="col-12 col-md-4 col-lg-3 mb-4">
                        <a
                          href="Javascript:void(0);"
                          onClick={() =>
                            redirectUrl("/services-offered", serve.id)
                          }
                        >
                          <div class="inner-service-card box-shadow1 border-radius6 white-bg text-center px-3 px-sm-1 py-3">
                            <div class="row">
                              <div class="col-12 mb-4 mb-lg-0">
                                <div class="text-center">
                                  <img
                                    src={
                                      businessDetail && businessDetail.logo
                                        ? businessDetail.logo
                                        : ""
                                    }
                                    class="img-fluid mx-auto"
                                  />
                                </div>
                              </div>
                              <div class="col-12">
                                <div class="std-purchase-card-details mt-2">
                                  <h5 class="title black-color">
                                    {" "}
                                    {serve.service_name}{" "}
                                  </h5>
                                  <h5 class="black-color">
                                    {" "}
                                    {serve.service_category}{" "}
                                  </h5>
                                </div>
                              </div>

                              <div class="col-12">
                                <p class="black-color">
                                  Service Charge : {serve.amount}{" "}
                                </p>
                              </div>
                              <div class="col-12">
                                <p class="black-color">
                                  Service Duration : {serve.duration} Min.{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {serviceDetail.length == 0 && (
                <div>
                  <div class="text-center">
                    <p class="font-awesome black-color font-18 d-inline mx-2 ">
                      {" "}
                      No Services Are scheduled
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Innerfooter />
    </>
  );
};
