import React from 'react'
import { StaticsHeader } from '../../Components/header/StaticsHeader'
import { SubHeader } from '../../Components/sub-header/SubHeader'
import { Innerfooter } from '../../Components/inner-footer/Innerfooter'
import { Link, useNavigate } from 'react-router-dom'
import { DashboardToSectionDetails } from '../../Components/CommanPages/DashboardToSectionDetails'
import { useState } from 'react'
import { ageCalculation, staff_client_list } from '../../../Static_Services/Staff_Services'
import { useEffect } from 'react'
import { Spinner } from 'react-bootstrap'

export const ClientAll = () => {
   
    const [search_text, setSearch_text] = useState();
    const [clientDetail, setClientDetail] = useState([]);
    const [loder, setLoder] = useState(false);
    const navigate = useNavigate();
    
    const [businessDetail, setBusinessDetail] = useState();
    useEffect(() => {
        const bzDetails = JSON.parse(localStorage.getItem('bzDetails'));
        if (bzDetails) {
            setBusinessDetail(bzDetails);
        }
    }, []);

    const ClientList = async () => {
        setLoder(true);
        var detail = { "pageid": "1", "business_id": businessDetail.business_id, "search_val": search_text }
        const resp = await staff_client_list(detail);
        if (resp) {
            const response = resp.data;
            response.data.filter(function (el) {
                el.age = ageCalculation(el.date_of_birth);
            })
            setClientDetail(response.data);
            setLoder(false);

        } else {
            setClientDetail([]);
            setLoder(false);
        }

    }

    useEffect(() => {
        if (businessDetail) {
            ClientList();
        }
    }, [businessDetail])

    const redirectUrl = (Id) => {
        navigate('/customer-profile', { state: { Id: Id, url: '/client' } });
    }
    const reset = async () => {
        setSearch_text('');
        setLoder(true);
        var detail = { "pageid": "1", "business_id": businessDetail.business_id, "search_val": '' }
        const resp = await staff_client_list(detail);
        if (resp) {
            const response = resp.data;
            response.data.filter(function (el) {
                el.age = ageCalculation(el.date_of_birth);
            })
            setClientDetail(response.data);
            setLoder(false);

        } else {
            setClientDetail([]);
            setLoder(false);
        }
    }
    const handleInputChange = (event) => {
        const cleanedValue = event.target.value.replace(/^\s+/g, '');
        setSearch_text(cleanedValue);
    };
    return (
        <>
         
            {loder ? <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div> : ""}
            <StaticsHeader />
            <div className="body-grey">
                {/* <!-- My Studio Card Detials Section --> */}
                <div className="studio-details client-home-inner">
                    <div className="container">
                        <DashboardToSectionDetails />

                        <div className="home-staff pb-2">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="section-heading pt-2 pb-4 text-left">
                                        <a href="Javascript:void(0);" className="black-color font-w-100 text-uppercase font-20"> Clients </a>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="section-search">
                                        <div className="form-group has-search pl-0 pl-md-3" >
                                            <a href="Javascript:void(0);" >
                                                <span className="fas fa-search form-control-feedback"
                                                    onClick={() => ClientList()}
                                                    style={{ bottom: "7px", top: 'unset', pointerEvents: 'initial' }}></span>
                                            </a>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search"
                                                value={search_text}
                                                onChange={handleInputChange}
                                            />
                                        </div>

                                    </div>
                                </div>
                                {/* <!-- end of col-6 --> */}
                                <div className="col-12 col-md-1">
                                    <button type="button" className="btn btn-blue-grad px-2 text-uppercase mb-0 mb-md-5"
                                        onClick={() => reset()}
                                    >Reset </button>
                                </div>
                                <div className="col-12 col-md-1">
                                    <button type="button" className="btn btn-blue-grad px-2 text-uppercase mb-0 mb-md-5" onClick={()=>window.history.back()}>Back </button>
                                </div>
                            </div>

                            {clientDetail.length != 0 &&
                                <div className="row staff-studio-details" >
                                    {clientDetail.map((items) => (
                                        <div className="col-md-6 col-lg-4 py-3">
                                            <div className="appointment-slot-box box-shadow1 border-radius6 p-3 bg-white">
                                                <a href="Javascript:void(0);"
                                                    onClick={() => redirectUrl(items.id)}
                                                >
                                                    <div className="row p-2">
                                                        <div className="col-2 px-1">
                                                            <div className="profile-img box-shadow1">
                                                                <img src={items.profile_img} className="img-fluid" />
                                                            </div>
                                                        </div>
                                                        <div className="col-10">
                                                            <div className="row">
                                                                <div className="col-7 px-1">
                                                                    <p className="black-color font-14 font-w-600 mb-0 d-flex">
                                                                        <span className="nametitle d-block"> {items.name + ' '}   {items.lastname} </span>
                                                                    </p>
                                                                    <p>
                                                                        <span className="ml-2 brand-color3 font-w-500"> {items.age} ,  {items.gender} </span>
                                                                    </p>
                                                                </div>
                                                                <div className="col-5 px-1">
                                                                    <span className="mr-2"> <Link to={`tel:'+'${items.country_code}${items.mobile}`} target="blank" > <img src="clientassets/img/ic_call_web.png" /> </Link> </span>
                                                                    <span> <Link to={`https://mail.google.com/mail/u/0/?view=cm&to=${items.email}&:&bcc&cc&fs=1&tf=1`} target="blank"> <img src="clientassets/img/ic_email_web.png" /> </Link> </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    ))}
                                </div >
                            }
                            {clientDetail.length == 0 &&
                                <div className="home-staff-details white-bg box-shadow1 border-radius6 px-3 py-4 mb-4" >
                                    <div className="text-center">
                                        <p className="font-awesome black-color font-18 d-inline mx-2 "> No Clients Available</p>
                                    </div>
                                </div >
                            }

                        </div >
                        {/* < !--end of home - staff-- > */}
                    </div >
                    {/* <!-- end of container --> */}
                </div >
                {/* <!-- End of My Studio Card Detials Section --> */}
            </div >
            <Innerfooter />
        </>
    )
}
